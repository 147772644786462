import React from "react";
import { colors } from "../../assets/themes";
import { Block, Body16, List } from "../../components";
import { LIST } from "../../components/Styles/variants";

const SummaryItem = ({
  value,
  title,
  children,
  RightComponent,
  positive,
  onClick,
  withGutters,
  size = LIST.SIZE.COMPACT,
  ...rest
}) => (
  <List.Item
    withGutters={withGutters}
    divider={false}
    size={size}
    onClick={onClick}
    {...rest}
    RightComponent={() => (
      <Body16 color={positive ? colors.success : colors.body}>
        {positive && <span>-</span>}
        {typeof value === "function" ? value() : value}
      </Body16>
    )}
  >
    <Block display="flex" alignItems="center">
      <List.Elem.Title>{title}</List.Elem.Title>
      {children}
    </Block>
  </List.Item>
);

export default SummaryItem;
