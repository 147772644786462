import React from "react";
import polyglot from "@/utils/polyglot";
import { artwork } from "../../assets/categories";
import { spacings } from "../../assets/themes";
import { List, Shape } from "../../components";
import { SHAPE } from "../../components/Styles/variants";
import { useBreakpoints } from "../../modules/hooks";
import { getCategoryFormUrlById } from "../../utils";

const MemoizedList = React.memo(
  ({ children, ...props }) => <List.Item {...props}>{children}</List.Item>,
  () => true
);

const SearchItem = ({ elem, url_params, onClick }) => {
  const breakpoints = useBreakpoints();
  return (
    <MemoizedList
      key={`${elem.id}-search-item`}
      chevron
      withGutters
      css={breakpoints.get({
        xs: undefined,
        sm: `
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        `,
      })}
      LeftComponent={() => (
        <Shape.Small
          shape={SHAPE.SHAPE.ROUND}
          backgroundColor="transparent"
          size={breakpoints.get({
            xs: SHAPE.SIZE.MEDIUM,
            md: SHAPE.SIZE.LARGE,
          })}
          css={`
            overflow: hidden;
            margin: ${spacings.xs} 0;
          `}
        >
          <img
            src={artwork[elem.id]}
            alt={polyglot.t(`categories.${elem.id}.name`)}
            css={`
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: right bottom;
              transform: scale(1);
              transition: 0.1s ease-in-out;
            `}
          />
        </Shape.Small>
      )}
      onClick={elem.id < 1000 ? onClick : undefined}
      href={
        elem.id >= 1000
          ? getCategoryFormUrlById(elem.id, url_params)
          : undefined
      }
    >
      <List.Elem.Title
        className={breakpoints.get({
          xs: "",
          sm: "font-size-sm-4",
        })}
      >
        {polyglot.t(`categories.${elem.id}.name`)?.split(":").pop()}
      </List.Elem.Title>
    </MemoizedList>
  );
};

export default SearchItem;
