import { sizes, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import * as TruckImages from "../../assets/trucks";
import * as FurnitureQuantitiesImage from "../../assets/furniture-quantities";
import * as HedgeTrimmingImage from "../../assets/hedge-trimming";

export const INFORMATIONS_PATH = "informations";
export const CONCIERGE_PATH = "concierge";
export const SAV_PATH = "sav";
export const CURRENT_PASSWORD_PATH = "current-password";
export const FIRST_NAME_PATH = "first-name";
export const LAST_NAME_PATH = "last-name";
export const NB_HOURS_PATH = "nb-hours";
export const NB_JOBBERS_PATH = "nb-jobbers";
export const START_HOUR_PATH = "start-hour";
export const DATE_PATH = "date";
export const EMAIL_PATH = "email";
export const PASSWORD_PATH = "password";
export const PHOTOS_PATH = "photos";
export const DETAILS_PATH = "details";
export const FLOOR_PATH = "floor";
export const LIFT_PATH = "lift";
export const PRIVATE_JOBBER_PATH = "private-jobber";
export const DESTINATION_FLOOR_PATH = "destination_floor";
export const DESTINATION_LIFT_PATH = "destination_lift";
export const START_ADDRESS_PATH = "start-address";
export const ADDRESS_PATH = "address";
export const DESTINATION_PROPERTY_TYPE_PATH = "destination_property_type";
export const PROPERTY_TYPE_PATH = "property_type";
export const DESTINATION_ADDRESS_PATH = "destination-address";
export const CATEGORY_DATE_FORMAT = "YYYY-MM-DD";
export const URGENT_CH_PRICE_PER_HOUR_RATE = 1.1;
export const URGENT_FR_PRICE_PER_HOUR_RATE = 1.2;
export const MIN_NB_HOURS = 1;
export const MAX_NB_HOURS = 105;
export const MIN_NB_JOBBERS = 1;
export const MAX_NB_JOBBERS = 5;
export const MAX_DESCRIPTION_LENGTH = 2000;
export const MAX_TITLE_LENGTH = 80;
export const ANALYTICS_NAVIGATE_TO = "navigate_to";
export const NAVBAR_HEIGHT = `calc(${sizes.size54} + (${spacings.s} * 2) + env(safe-area-inset-bottom))`;
export const LOADINGS_PATH = "/form-loading";
export const DISTANCE_QUERY = "DISTANCE_QUERY";

export const APARTMENT = "apartment";
export const HOUSE = "house";
export const STORAGE = "storage";
export const OTHER = "other";

export const PROPERTY_TYPE_OPTIONS = [
  {
    label: polyglot.t("common.apartment"),
    value: APARTMENT,
  },
  {
    label: polyglot.t("common.house"),
    value: HOUSE,
  },
  {
    label: polyglot.t("common.furniture_storage"),
    value: STORAGE,
  },
  {
    label: polyglot.t("common.other"),
    value: OTHER,
  },
];

export const NO_MATCH_VERSION_ALLOWED_FIELDS = [
  "previous_job_id",
  "title",
  "description",
  "nb_jobbers",
  "nb_hours",
  "date",
  "end_date",
  "start_hour",
  "end_hour",
  "address_id",
  "address",
  "destination_address_id",
  "destination_address",
  "private_jobber_id",
];

export const PICKUP = "pickup";
export const VAN = "van";
export const SMALL_VAN = "small_van";
export const LARGE_VAN = "large_van";
export const SMALL_TRUCK = "small_truck";
export const LARGE_TRUCK = "large_truck";

export const TRUCKS = [
  {
    value: PICKUP,
    label: polyglot.t(`common.trucks.${PICKUP}`),
    subtitle: polyglot.t(`common.trucks.${PICKUP}_description`),
    image: TruckImages[PICKUP],
    range: [0, 4],
    additionnalNbHours: 1,
  },
  {
    value: VAN,
    label: polyglot.t(`common.trucks.${VAN}`),
    subtitle: polyglot.t(`common.trucks.${VAN}_description`),
    image: TruckImages[VAN],
    range: [5, 9],
    additionnalNbHours: 1.5,
  },
  {
    value: SMALL_VAN,
    label: polyglot.t(`common.trucks.${SMALL_VAN}`),
    subtitle: polyglot.t(`common.trucks.${SMALL_VAN}_description`),
    image: TruckImages[SMALL_VAN],
    range: [10, 15],
    additionnalNbHours: 2.5,
  },
  {
    value: LARGE_VAN,
    label: polyglot.t(`common.trucks.${LARGE_VAN}`),
    subtitle: polyglot.t(`common.trucks.${LARGE_VAN}_description`),
    image: TruckImages[LARGE_VAN],
    range: [16, 20],
    additionnalNbHours: 3.5,
  },
  {
    value: SMALL_TRUCK,
    label: polyglot.t(`common.trucks.${SMALL_TRUCK}`),
    subtitle: polyglot.t(`common.trucks.${SMALL_TRUCK}_description`),
    image: TruckImages[SMALL_TRUCK],
    range: [21, 29],
    additionnalNbHours: 4,
  },
  {
    value: LARGE_TRUCK,
    label: polyglot.t(`common.trucks.${LARGE_TRUCK}`),
    subtitle: polyglot.t(`common.trucks.${LARGE_TRUCK}_description`),
    image: TruckImages[LARGE_TRUCK],
    range: [30, 9999],
    additionnalNbHours: 5,
  },
];

export const FURNITURE_QUANTITIES = [
  {
    value: 1,
    label: polyglot.t("categories.furnitureQuantities.minimalist"),
    subtitle: polyglot.t(
      "categories.furnitureQuantities.minimalist_description"
    ),
    image: FurnitureQuantitiesImage.size1,
    nbHoursAccumulator: 0.25,
  },
  {
    value: 2,
    label: polyglot.t("categories.furnitureQuantities.furnished"),
    subtitle: polyglot.t(
      "categories.furnitureQuantities.furnished_description"
    ),
    image: FurnitureQuantitiesImage.size2,
    nbHoursAccumulator: 0.35,
  },
  {
    value: 3,
    label: polyglot.t("categories.furnitureQuantities.over_equipped"),
    subtitle: polyglot.t(
      "categories.furnitureQuantities.over_equipped_description"
    ),
    image: FurnitureQuantitiesImage.size3,
    nbHoursAccumulator: 0.5,
  },
];

export const SURFACE_OPTIONS = [
  { value: 10, label: "10 m²" },
  { value: 15, label: "15 m²" },
  { value: 25, label: "25 m²" },
  { value: 30, label: "30 m²" },
  { value: 35, label: "35 m²" },
  { value: 40, label: "40 m²" },
  { value: 45, label: "45 m²" },
  { value: 50, label: "50 m²" },
  { value: 55, label: "55 m²" },
  { value: 60, label: "60 m²" },
  { value: 65, label: "65 m²" },
  { value: 70, label: "70 m²" },
  { value: 75, label: "75 m²" },
  { value: 80, label: "80 m²" },
  { value: 85, label: "85 m²" },
  { value: 90, label: "90 m²" },
  { value: 95, label: "95 m²" },
  { value: 100, label: "100 m²" },
  { value: 105, label: "105 m²" },
  { value: 110, label: "110 m²" },
  { value: 115, label: "115 m²" },
  { value: 120, label: "120 m²" },
  { value: 125, label: "125 m²" },
  { value: 130, label: "130 m²" },
  { value: 135, label: "135 m²" },
  { value: 140, label: "140 m²" },
  { value: 145, label: "145 m²" },
  { value: 150, label: "150 m²" },
  { value: 155, label: "155 m²" },
  { value: 160, label: "160 m²" },
  { value: 165, label: "165 m²" },
  { value: 170, label: "170 m²" },
  { value: 175, label: "175 m²" },
  { value: 180, label: "180 m²" },
  { value: 185, label: "185 m²" },
  { value: 190, label: "190 m²" },
  { value: 195, label: "195 m²" },
  { value: 200, label: "200 m²" },
];

export const FLOORS = [
  {
    value: -1,
    label: polyglot.t("common.floor.n1"),
    nbHoursAccumulator: 0.2,
  },
  {
    value: 0,
    label: polyglot.t("common.floor.zero"),
    nbHoursAccumulator: 0.0,
  },
  {
    value: 1,
    label: polyglot.t("common.floor.first"),
    nbHoursAccumulator: 0.2,
  },
  {
    value: 2,
    label: polyglot.t("common.floor.second"),
    nbHoursAccumulator: 0.3,
  },
  {
    value: 3,
    label: polyglot.t("common.floor.third"),
    nbHoursAccumulator: 0.4,
  },
  {
    value: 4,
    label: polyglot.t("common.floor.fourth"),
    nbHoursAccumulator: 0.5,
  },
  {
    value: 5,
    label: polyglot.t("common.floor.fifth_or_more"),
    nbHoursAccumulator: 0.6,
  },
];

export const getTruckByValue = (value) => {
  const truck = TRUCKS.find(
    ({ range }) => value.toFixed(0) >= range[0] && value.toFixed(0) <= range[1]
  );
  // return last truck if value dont match any range
  return truck || TRUCKS[TRUCKS.length - 1];
};

export const HEDGE_TRIMMING_WIDTH_OPTIONS = [
  { value: 1.0, label: polyglot.t("common.n_meters", { n: 10 }) },
  { value: 2.0, label: polyglot.t("common.n_meters", { n: 20 }) },
  { value: 3.0, label: polyglot.t("common.n_meters", { n: 30 }) },
  { value: 4.0, label: polyglot.t("common.n_meters", { n: 40 }) },
  { value: 5.0, label: polyglot.t("common.n_meters", { n: 50 }) },
  { value: 6.0, label: polyglot.t("common.n_meters", { n: 60 }) },
  { value: 7.0, label: polyglot.t("common.n_meters", { n: 70 }) },
  { value: 8.0, label: polyglot.t("common.n_meters", { n: 80 }) },
  { value: 9.0, label: polyglot.t("common.n_meters", { n: 90 }) },
  { value: 10.0, label: polyglot.t("common.n_meters", { n: 100 }) },
];

export const HEDGE_TRIMMING_HEIGHT = [
  {
    value: 1,
    label: polyglot.t("categories.small_hedge"),
    subtitle: polyglot.t("categories.small_hedge_description"),
    image: HedgeTrimmingImage.size1,
    nbHoursAccumulator: 1,
  },
  {
    value: 2,
    label: polyglot.t("categories.medium_hedge"),
    subtitle: polyglot.t("categories.medium_hedge_description"),
    image: HedgeTrimmingImage.size2,
    nbHoursAccumulator: 1.5,
  },
  {
    value: 3,
    label: polyglot.t("categories.medium_hedge"),
    subtitle: polyglot.t("categories.medium_hedge_description"),
    image: HedgeTrimmingImage.size3,
    nbHoursAccumulator: 2,
  },
];
