import React from "react";
import BaseApp from "../modules/BaseApp";
import { DashboardContext } from "../modules/contexts";
import HeaderMenu from "../pages/HeaderMenu";

const HeaderMenuApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <HeaderMenu {...props} />
    </BaseApp>
  );

export default HeaderMenuApp;
