import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1008() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 4,
            subtitle: polyglot.t("categories.one_bedroom_wall"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: 6,
            subtitle: polyglot.t("categories.entire_bedroom"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 8,
            subtitle: polyglot.t("categories.entire_large_living_room"),
          },
        ],
      },
    },
  ];
  return {
    pages,
    initialValues: { nb_hours: 6 },
    version: 1.1,
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
    },
  };
}
