import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  TICKETS_NEW_PATH,
  TICKETS_PATH,
} from "../../pages/dashboard/config/routes";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";
import { useToast } from "../hooks";
import useFetch from "../hooks/useFetch";

export const useGetNewTicket = () => useFetch(TICKETS_NEW_PATH);

export const usePostNewTicket = () => {
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  return useMutation((values) => api.post(TICKETS_PATH, values), {
    onSuccess: ({ data }) => {
      queryClient.fetchQuery([TICKETS_PATH]).then(() => {
        history.replace(`${TICKETS_PATH}/${data.data.ticket_id}`);
        toast.success(polyglot.t("tickets.toast_success"));
      });
    },
    onError: () => toast.danger(polyglot.t("common.problem_has_occurred")),
  });
};
