import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import {
  FormCheckboxes,
  FormRadios,
  StyledFormControl,
} from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = { forceSummaryColumn: true };

const MORE_HDD_SPACE = "more_hdd_space";
const REMOVE_SPYWARES = "remove_spywares";
const FULL_CHECKUP = "full_checkup";
const SW_UPDATE = "sw_update";
const BACKUP = "backup";

const validationSchema = yup.object().shape({
  nh_specific: yup.string().required(),
});

export function useCategory6012() {
  const initialValues = {
    nh_specific: "",
    options: { option: [] },
  };

  const formatInitialValues = (arr) => {
    if (arr.options?.length > 0) {
      const newArr = { ...arr };
      newArr.options = { option: newArr.options?.map((item) => item.option) };
      return newArr;
    }
    return arr;
  };

  const getNbHours = (values) => {
    let res = 0;
    if (values.nh_specific) {
      if (values.nh_specific === MORE_HDD_SPACE) {
        res = 2;
      }
      if (values.nh_specific === REMOVE_SPYWARES) {
        res = 4;
      }
      if (values.nh_specific === FULL_CHECKUP) {
        res = 6;
      }
    }
    if (values.options?.option) {
      if (values.options?.option.includes(SW_UPDATE)) {
        res += 1;
      }
      if (values.options?.option.includes(BACKUP)) {
        res += 2;
      }
    }
    return res;
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.computer_cleaning_type"),
      options: [
        {
          value: MORE_HDD_SPACE,
          label: polyglot.t("categories.more_hdd_space"),
          subtitle: polyglot.t("categories.more_hdd_space_description"),
        },
        {
          value: REMOVE_SPYWARES,
          label: polyglot.t("categories.remove_spyware"),
          subtitle: polyglot.t("categories.remove_spyware_description"),
        },
        {
          value: FULL_CHECKUP,
          label: polyglot.t("categories.full_checkup"),
          subtitle: polyglot.t("categories.full_checkup_description"),
        },
      ],
    },
    "options.option": {
      label: polyglot.t("categories.additionnal_needs"),
      options: [
        {
          value: SW_UPDATE,
          label: polyglot.t("categories.update_installation"),
        },
        { value: BACKUP, label: polyglot.t("categories.data_saving") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl label={nhConfig.nh_specific.label}>
            <FormRadios
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
          <StyledFormControl label={nhConfig["options.option"].label}>
            <FormCheckboxes
              name="options.option"
              options={nhConfig["options.option"].options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    config,
    formatInitialValues,
  };
}
