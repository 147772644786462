import moment from "moment";
import { artwork } from "@/assets/categories";
import { colors, sizes, spacings } from "@/assets/themes";
import { Block, Body14, Body16, Divider, List } from "@/components";
import { CardBase } from "@/components/Styles/Base";
import UserPreview from "@/pages-admin/UserPreview";
import { JOB } from "@/pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";

const JobberProfileJobs = ({ data }) => (
  <CardBase
    light
    flat
    css={`
      overflow: hidden;
    `}
  >
    <Block paddingBottom={spacings.xs}>
      <Block
        paddingX={spacings.m}
        paddingTop={spacings.sm}
        paddingBottom={spacings.s}
      >
        <Body16 strong>
          {polyglot.t("jobber_profile.ask_again_first_name", {
            first_name: data.jobber.first_name,
          })}
        </Body16>
      </Block>
      <Divider.Cell />
      {data.rebooking_jobs.map((job) => (
        <UserPreview id={job.id} kind={JOB} key={`job-ask-again-${job.id}`}>
          <List.Group>
            <List.Item
              chevron
              withGutters
              divider={false}
              href={job.url}
              LeftComponent={() => (
                <img
                  alt={job.title}
                  src={artwork[job.category]}
                  css={`
                    width: ${sizes.size54};
                    height: ${sizes.size54};
                    object-fit: cover;
                    object-position: bottom right;
                    border-radius: ${spacings.sm};
                  `}
                />
              )}
            >
              <Body16 strong>{job.title}</Body16>
              <Body14 color={colors.muted}>
                {polyglot.t("jobber_profile.job_ended_since", {
                  date: moment(job.date).fromNow(),
                })}
              </Body14>
            </List.Item>
          </List.Group>
        </UserPreview>
      ))}
    </Block>
  </CardBase>
);

export default JobberProfileJobs;
