import { Field, useFormikContext } from "formik";
import { spacings } from "../../../../assets/themes";
import { Block, Checkbox, Divider } from "../../../../components";
import PasswordChecker from "../../../../components/password/PasswordChecker";
import polyglot from "../../../../utils/polyglot";
import SignUpTerms from "../../../loginOrRegister/SignUpTerms";
import { FormInput, StyledFormControl } from "../fields";
import StepTitle from "../StepTitle";

const NewPassword = () => {
  const { values } = useFormikContext();

  return (
    <>
      <StepTitle>{polyglot.t("categories.choose_a_password")}</StepTitle>
      <StyledFormControl>
        <input
          value={values.user?.email}
          readOnly
          type="email"
          autoComplete="email"
          hidden
        />
        <FormInput
          name="user.password"
          autoComplete="new-password"
          type="password"
          placeholder={polyglot.t("common.placeholder.password")}
        />
      </StyledFormControl>
      <PasswordChecker value={values.user?.password} />
      <Block marginY={spacings.m}>
        <Divider.Cell />
      </Block>
      <Block marginY={spacings.s}>
        <Field name="alert_setting.optin" type="checkbox">
          {({ field }) => (
            <Checkbox {...field}>{polyglot.t("optin.news_gift_tips")}</Checkbox>
          )}
        </Field>
      </Block>
      <Block marginY={spacings.s}>
        <Field name="alert_setting.coreg" type="checkbox">
          {({ field }) => (
            <Checkbox {...field}>{polyglot.t("optin.partner")}</Checkbox>
          )}
        </Field>
      </Block>
      <SignUpTerms isJobber={false} />
    </>
  );
};

export default NewPassword;
