import { useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow, List, Radio } from '../../../components';
import Selectable from '../../../components/Selectable';

const Informations = ({ goNext, title }) => {
  const { isValid, setFieldValue, values } = useFormikContext();

  return (
    <Container.Small>
      <h1>{title}</h1>
      <div className="form-group mt-4">
        <label className="form-label">Avez-vous déjà un contrat de travail ?</label>
        <div className="row">
          <div className="col-6">
            <Selectable.Medium
              name="new_contract"
              title="Non"
              type="radio"
              checked={values.new_contract === true}
              onChange={() => {
                setFieldValue('new_contract', true);
              }}
            />
          </div>
          <div className="col-6">
            <Selectable.Medium
              name="new_contract"
              title="Oui"
              type="radio"
              checked={values.new_contract === false}
              onChange={() => {
                setFieldValue('new_contract', false);
              }}
            />
          </div>
        </div>
      </div>
      <List.Header title="Durée des services" />
      <List.Item
        onClick={() => setFieldValue('duration', 'fixed')}
        RightComponent={() => <Radio name="duration" checked={values.duration === 'fixed'} />}>
        <List.Elem.Title className="font-weight-medium">Nombre d'heures fixe</List.Elem.Title>
        <List.Elem.Subtitle>
          Votre prestataire et vous-même vous engagez un nombre d’heures fixe par semaine.
        </List.Elem.Subtitle>
      </List.Item>
      <List.Item
        onClick={() => setFieldValue('duration', 'variable')}
        RightComponent={() => <Radio name="duration" checked={values.duration === 'variable'} />}>
        <List.Elem.Title className="font-weight-medium">Nombre d’heures variable</List.Elem.Title>
        <List.Elem.Subtitle>
          En fonction de l’activité et de vos besoins, le nombre d’heures varie d’une semaine sur
          l’autre.
        </List.Elem.Subtitle>
      </List.Item>
      <List.Header title="Type de contrat" />
      <List.Item
        onClick={() => setFieldValue('kind', 'cdi')}
        RightComponent={() => <Radio name="kind" value="cdi" checked={values.kind === 'cdi'} />}>
        <List.Elem.Title className="font-weight-medium">
          Contrat à durée indéterminée (CDI)
        </List.Elem.Title>
        <List.Elem.Subtitle>
          Contrat le plus adapté sauf en cas de besoin ponctuel.
        </List.Elem.Subtitle>
      </List.Item>
      <Flow.Item.Navigation isValid={isValid} goNext={goNext} />
    </Container.Small>
  );
};

export default Informations;
