import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { Button, FormControl, Input, Modal } from "../../components";
import polyglot from "../../utils/polyglot";

const VoucherCodeModal = ({ isOpen, onClose, onChange, isLoading, error }) => (
  <Modal.XSmall isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
    <Modal.Item.Header
      onClose={onClose}
      title={polyglot.t("common.add_voucher_code")}
    />

    <Formik
      onSubmit={(values) => {
        onChange({ code: values?.code?.toUpperCase() });
      }}
      validateOnMount
      initialValues={{ code: "" }}
      validationSchema={yup.object().shape({ code: yup.string().required() })}
    >
      {({ isValid, dirty }) => (
        <Form>
          <Modal.Item.Wrapper>
            <Field name="code">
              {({ field }) => (
                <FormControl
                  error={error}
                  css={`
                    margin-bottom: 0;
                  `}
                >
                  <Input
                    placeholder={polyglot.t("common.voucher_code")}
                    autoComplete="prevent-autocomplete"
                    {...field}
                  />
                </FormControl>
              )}
            </Field>
          </Modal.Item.Wrapper>
          <Modal.Item.Footer column>
            <Button.Large
              block
              disabled={!isValid && !dirty}
              type="submit"
              isLoading={isLoading}
            >
              {polyglot.t("common.save")}
            </Button.Large>
            <Button.Large block kind="minimal" onClick={onClose}>
              {polyglot.t("common.cancel")}
            </Button.Large>
          </Modal.Item.Footer>
        </Form>
      )}
    </Formik>
  </Modal.XSmall>
);

export default VoucherCodeModal;
