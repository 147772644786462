import { spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import { BUTTON } from "../Styles/variants";

const PendingActions = ({ offer, unavailable }) => (
  <Block display="flex" flexDirection="column">
    <Block marginBottom={spacings.s}>
      <Button.Medium
        block
        href={!unavailable && `/offers/${offer.id}`}
        disabled={unavailable}
        RightComponent={() => <Icon.Large name="arrow-right" />}
      >
        {polyglot.t("job.continue_with_first_name", {
          first_name: offer.jobber.first_name,
        })}
      </Button.Medium>
    </Block>
    <Block flex="1">
      <Button.Medium href={offer.jobber.url} kind={BUTTON.KIND.TERTIARY} block>
        {polyglot.t("job.see_profile")}
      </Button.Medium>
    </Block>
  </Block>
);
export default PendingActions;
