import { useState } from "react";
import styled from "styled-components";
import { Button, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import TicketNewJobItem from "./TicketNewJobItem";
import TicketNewJobModal from "./TicketNewJobModal";
import polyglot from "../../../utils/polyglot";
import { colors, radius, spacings } from "../../../assets/themes";

const StyledGrayWrapper = styled.div`
  background-color: ${colors.backgroundLight};
  border-radius: ${radius.m};
  padding: 0 ${spacings.m} ${spacings.m} ${spacings.m};
`;

const TicketNewJobInput = ({ value, jobs, onChange }) => {
  const [jobModalIsOpen, setJobModalIsOpen] = useState(false);

  const handleJobModalClose = () => {
    setJobModalIsOpen(false);
  };

  const handleJobModalOpen = () => {
    setJobModalIsOpen(true);
  };

  const getJob = (id) => jobs?.find((job) => job.id === id);

  return (
    <div>
      {!value ? (
        <Button.Medium
          block
          disabled={!jobs}
          kind={BUTTON.KIND.SECONDARY}
          onClick={handleJobModalOpen}
          LeftComponent={() => <Icon.Large name="plus" />}
        >
          {polyglot.t("tickets.select_a_job")}
        </Button.Medium>
      ) : (
        <StyledGrayWrapper>
          <TicketNewJobItem job={getJob(value)} divider={false} />
          <Button.Small
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.PILL}
            onClick={() => onChange(null)}
            RightComponent={() => <Icon.Medium name="trash" />}
          >
            {polyglot.t("common.remove")}
          </Button.Small>
        </StyledGrayWrapper>
      )}
      {jobs && (
        <TicketNewJobModal
          jobs={jobs}
          isOpen={jobModalIsOpen}
          onChange={(job) => {
            onChange(job.id);
          }}
          onClose={handleJobModalClose}
        />
      )}
    </div>
  );
};
export default TicketNewJobInput;
