import React from "react";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Body18,
  Divider,
  H5,
  Icon,
  List,
  Modal,
} from "../../../components";
import { ALERT } from "../../../components/Styles/variants";
import { formatDate } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { getCesuState } from "../dashboard-utils";

const CesuDetailsModal = ({
  onClose,
  amount,
  credited_amount,
  fee,
  date,
  ecesu,
  number,
  secure_code,
  state,
  isOpen,
  billing_url,
  refused_reason,
}) => (
  <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>{polyglot.t("cesu.added_ticket")}</Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <List.Item
        divider={false}
        LeftComponent={() => (
          <Icon.Large name="ticket" color={colors.primary} />
        )}
      >
        <List.Elem.Title>{polyglot.t("common.full_number")}</List.Elem.Title>
        <List.Elem.Subtitle>
          <Body18 strong color={colors.body}>
            {ecesu ? number : number.match(/.{1,4}/g).join(" ")}
          </Body18>
        </List.Elem.Subtitle>
      </List.Item>
      <Divider.Cell />
      {!ecesu && (
        <List.Item
          RightComponent={() => (
            <List.Elem.Label> {secure_code}</List.Elem.Label>
          )}
        >
          <List.Elem.Title>{polyglot.t("common.secret_code")}</List.Elem.Title>
        </List.Item>
      )}
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{getCesuState(state)}</List.Elem.Label>
        )}
      >
        <List.Elem.Title>{polyglot.t("common.status")}</List.Elem.Title>
      </List.Item>
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{formatDate(date, "DD/MM/YYYY")}</List.Elem.Label>
        )}
      >
        <List.Elem.Title>{polyglot.t("common.added_date")}</List.Elem.Title>
      </List.Item>
      <List.Item
        RightComponent={() => (
          <List.Elem.Label>{polyglot.toSmartCurrency(amount)}</List.Elem.Label>
        )}
      >
        <List.Elem.Title>{polyglot.t("cesu.ticket_amount")}</List.Elem.Title>
      </List.Item>
      {!!fee && (
        <>
          <List.Item
            RightComponent={() => (
              <List.Elem.Label>
                -{polyglot.toSmartCurrency(fee)}
              </List.Elem.Label>
            )}
          >
            <List.Elem.Title>
              {polyglot.t("cesu.issuer_commission_fees")}
            </List.Elem.Title>
          </List.Item>
          <List.Item
            RightComponent={() => (
              <List.Elem.Label>
                {polyglot.toSmartCurrency(credited_amount)}
              </List.Elem.Label>
            )}
          >
            <List.Elem.Title>
              {polyglot.t("cesu.usable_amount")}
            </List.Elem.Title>
          </List.Item>
        </>
      )}
      {billing_url && (
        <>
          <List.Header as={H5}>{polyglot.t("routes.billings")}</List.Header>
          <List.Item
            href={billing_url}
            target="_blank"
            LeftComponent={() => (
              <Icon.Large name="download" color={colors.primary} />
            )}
          >
            <List.Elem.Title strong color={colors.primary}>
              {polyglot.t("cesu.issuer_commission_fees_billing")}
            </List.Elem.Title>
          </List.Item>
        </>
      )}
      {refused_reason && (
        <Alert.Low
          kind={ALERT.KIND.DANGER}
          css={`
            margin-top: ${spacings.s};
          `}
        >
          {refused_reason}
        </Alert.Low>
      )}
    </Modal.Item.Wrapper>
  </Modal.Small>
);

const areEqual = (p, n) => p.isOpen === n.isOpen;
export default React.memo(CesuDetailsModal, areEqual);
