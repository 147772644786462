import { useFormikContext } from "formik";
import { useState } from "react";
import { colors } from "@/assets/themes";
import { Body16, Button, H3, Icon, List, Modal, Shape } from "@/components";
import { BUTTON, LIST, SHAPE } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import { StyledFormControl } from "./fields";
import FormDatePickerPrivate from "./fields/FormDatePickerPrivate";
import StepTitle from "./StepTitle";

const ConfirmModal = ({ onClose, isOpen, onSubmit }) => (
  <Modal.XSmall isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title as={H3}>
        {polyglot.t("categories.get_all_availabilities_confirm")}
      </Modal.Item.Title>
      <Modal.Item.Subtitle>
        {polyglot.t("categories.get_all_availabilities_confirm_description")}
      </Modal.Item.Subtitle>
    </Modal.Item.Header>
    <Modal.Item.Footer>
      <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={onClose}>
        {polyglot.t("common.cancel")}
      </Button.Medium>
      <Button.Medium onClick={onSubmit}>
        {polyglot.t("common.confirm")}
      </Button.Medium>
    </Modal.Item.Footer>
  </Modal.XSmall>
);

const JobberDateTime = ({ title }) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const { setFieldValue } = useFormikContext();
  const handleConfirm = () => {
    setFieldValue("private_jobber_id", null);
    setConfirmModalIsOpen(false);
  };

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <FormDatePickerPrivate names={["date", "start_hour"]} />
      </StyledFormControl>
      <StyledFormControl>
        <List.Item
          shape={LIST.SHAPE.ROUND}
          chevron
          withGutters
          divider={false}
          css={`
            background-color: ${colors.backgroundLight};
          `}
          onClick={() => {
            setConfirmModalIsOpen(true);
          }}
          LeftComponent={() => (
            <Shape.Small
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.muted}
              color={colors.onColor}
            >
              <Icon.Large name="calendar" />
            </Shape.Small>
          )}
        >
          <Body16 strong>
            {polyglot.t("categories.other_jobber_time_slots")}
          </Body16>
          <Body16>
            {polyglot.t("categories.other_jobber_time_slots_description")}
          </Body16>
        </List.Item>
      </StyledFormControl>
      <ConfirmModal
        isOpen={confirmModalIsOpen}
        onClose={() => setConfirmModalIsOpen(false)}
        onSubmit={handleConfirm}
      />
    </>
  );
};

export default JobberDateTime;
