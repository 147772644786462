import { useFormikContext } from "formik";
import { useEffect } from "react";
import { FormLargeStepper, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Level = ({ title, name }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!values[name] || values[name] < 1) {
      setFieldValue(name, 1);
    }
  }, []);

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <FormLargeStepper name={name} min={1} max={5} />
      </StyledFormControl>
    </>
  );
};
export default Level;
