import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useState } from "react";
import { colors, spacings } from "../../../../assets/themes";
import {
  Alert,
  Block,
  Body14,
  Button,
  FormControl,
  Icon,
  Input,
  Link,
  Modal,
} from "../../../../components";
import { useFbConfirm } from "../../../../modules/routes/sign-routes";
import polyglot from "../../../../utils/polyglot";
import SignUpTerms from "../../SignUpTerms";
import WrongCountryModal from "../WrongCountryModal";
import { ALERT } from "../../../../components/Styles/variants";

const validationSchema = yup.object().shape({
  user: yup.object().shape({
    email: yup
      .string()
      .email(polyglot.t("common.errors.email_invalid"))
      .required(polyglot.t("common.errors.field_required")),
    password: yup.string().required(polyglot.t("common.errors.field_required")),
  }),
});

const ConfirmUser = ({ modal, onClose, onSuccess, params, alertMessage }) => {
  const [wrongCountryModalIsOpen, setWrongCountryModalIsOpen] = useState(false);
  const history = useHistory();
  const {
    authResponse: { user, accessToken },
  } = history.location?.state;
  const fbConfirm = useFbConfirm();

  const formik = useFormik({
    initialValues: {
      user: { email: user.email, password: "", id: user.id },
      access_token: accessToken,
      ...params,
    },
    validateOnMount: true,
    validationSchema,
    onSubmit: (values) => {
      formik.setStatus(null);
      fbConfirm.mutate(values, {
        onSuccess: ({ data }) => onSuccess(data),
        onError: ({ response }) => {
          if (
            response.data.user_country_code &&
            response.data.domain_country_code
          ) {
            setWrongCountryModalIsOpen(true);
          } else {
            formik.setStatus(response.data.errorMessage);
          }
        },
      });
    },
  });

  const handleSignUp = () => {
    history.push("/signup");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal.Item.Header
        onClose={modal && onClose}
        onBack={modal && history.goBack}
        title={polyglot.t("login.account_existing")}
        subtitle={polyglot.t("login.link_account_to_facebook")}
      />
      <Modal.Item.Wrapper>
        {formik.status && (
          <Block marginBottom={spacings.m}>
            <Alert.Low kind={ALERT.KIND.DANGER}>{formik.status}</Alert.Low>
          </Block>
        )}
        <FormControl
          error={formik.submitCount > 0 && formik.errors.user?.email}
        >
          <Input
            value={formik.values.user?.email}
            name="user[email]"
            type="email"
            autoFocus
            readOnly
            placeholder={polyglot.t("common.email")}
            onChange={formik.handleChange}
            LeftComponent={() => (
              <Icon.Large name="envelope" color={colors.mutedLight} />
            )}
          />
        </FormControl>
        <FormControl
          error={formik.submitCount > 0 && formik.errors.user?.password}
        >
          <Input
            value={formik.values.user?.password}
            name="user[password]"
            type="password"
            placeholder={polyglot.t("common.password")}
            onChange={formik.handleChange}
            LeftComponent={() => (
              <Icon.Large name="lock" color={colors.mutedLight} />
            )}
          />
        </FormControl>
        <Block
          display="flex"
          justifyContent="flex-end"
          marginBottom={spacings.s}
        >
          <Link href="/password/new?context=user">
            <Body14>{polyglot.t("login.forgot_password")}</Body14>
          </Link>
        </Block>
        {alertMessage && (
          <Block marginBottom={spacings.s}>
            <Alert.Low>{alertMessage}</Alert.Low>
          </Block>
        )}
        <Button.Large
          isLoading={fbConfirm.isLoading || fbConfirm.isSuccess}
          type="submit"
        >
          {polyglot.t("login.link_account")}
        </Button.Large>
        <SignUpTerms />
        {wrongCountryModalIsOpen && (
          <WrongCountryModal
            userEmail={formik.values?.user?.email}
            originCountryCode={
              fbConfirm.error?.response?.data?.user_country_code
            }
            domainCountryCode={
              fbConfirm.error?.response?.data?.domain_country_code
            }
            isOpen={wrongCountryModalIsOpen}
            onSignUp={handleSignUp}
            onRedirect={() => onSuccess(fbConfirm.error?.response?.data)}
            onClose={() => {
              setWrongCountryModalIsOpen(false);
            }}
          />
        )}
      </Modal.Item.Wrapper>
    </form>
  );
};

export default ConfirmUser;
