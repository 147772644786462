import React from "react";
import styled, { css } from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "../assets/themes";
import Icon from "./Icon";
import { CheckboxBase, CheckboxLabelBase } from "./Styles/Base";
import { CHECKBOX } from "./Styles/variants";
import { Body16 } from "./Text";
import { uuid } from "../utils";

const StyledInput = styled.input.attrs({ type: "checkbox" })`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
`;

const StyledText = styled(Body16)`
  ${({ disabled }) => css`
    user-select: none;
    opacity: ${disabled ? 0.5 : 1};
    margin-left: ${spacings.s};
  `}
`;

const EnhancedCheckboxBase = styled(CheckboxBase)`
  ${({ checked }) => css`
    overflow: hidden;
    box-sizing: border-box;
    border: none;
    &:before {
      transition: all 0.1s ease-in-out;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-style: solid;
      border-radius: ${radius.xs};
      border-width: ${checked ? `calc(${sizes.size20} / 2)` : borderWidth.ml};
      border-color: ${checked ? colors.primary : colors.mutedLight};
    }
  `}
`;

const Checkbox = ({
  className,
  name,
  children,
  disabled,
  checked,
  onChange,
  readOnly,
  ...rest
}) => (
  <CheckboxLabelBase
    disabled={disabled}
    checked={checked}
    readOnly={readOnly || !onChange}
    className={className}
    aria-checked={checked}
  >
    <EnhancedCheckboxBase
      shape={CHECKBOX.SHAPE.DEFAULT}
      checked={checked}
      disabled={disabled}
    >
      <StyledInput
        name={name}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        readOnly={readOnly || !onChange}
        value
        {...rest}
      />
      {checked && (
        <Icon.Medium
          name="check"
          color={colors.background}
          css={`
            position: relative;
          `}
        />
      )}
    </EnhancedCheckboxBase>
    {children && <StyledText disabled={disabled}>{children}</StyledText>}
  </CheckboxLabelBase>
);

export default Checkbox;
