import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { spacings } from "../../../assets/themes";
import {
  Block,
  Breadcrumb,
  Button,
  FormControl,
  Icon,
  Input,
  Link,
  Spinner,
} from "../../../components";
import {
  useGetAccountCompany,
  usePutCompany,
} from "../../../modules/routes/dashboard-routes";
import { DASHBOARD_SMALL_CONTAINER } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import AddressModal from "../../common/AddressModal";
import PageTitle from "../PageTitle";
import { ACCOUNT_PATH } from "../config/routes";

const validationSchema = yup.object().shape({
  company_name: yup.string().required(),
  company_vat: yup.string().notRequired(),
  address: yup.string().required(),
});

const Business = ({ config }) => {
  const { isLoading, data } = useGetAccountCompany();
  const putBusiness = usePutCompany();
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);

  const handleSubmit = (values, { resetForm }) => {
    putBusiness.mutate({ values, resetForm });
  };

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <PageTitle>{config.title}</PageTitle>
      {!isLoading && data ? (
        <Formik
          validationSchema={validationSchema}
          validateOnMount
          initialValues={{
            address: data?.street_address || "",
            company_vat: data?.company_vat || "",
            company_name: data?.company_name || "",
          }}
          onSubmit={handleSubmit}
        >
          {({ isValid, dirty }) => (
            <Form>
              <FormControl label={polyglot.t("common.company_name")}>
                <Field name="company_name">
                  {({ field }) => <Input {...field} />}
                </Field>
              </FormControl>
              <FormControl label={polyglot.t("common.billing_address")}>
                <Field name="address">
                  {({ field }) => (
                    <Input
                      onClick={() => setAddressModalIsOpen(true)}
                      value={field.value}
                      placeholder={polyglot.t("common.placeholder.address")}
                      RightComponent={() => <Icon.Medium name="dropdown" />}
                    />
                  )}
                </Field>
                <AddressModal
                  isOpen={addressModalIsOpen}
                  onClose={() => setAddressModalIsOpen(false)}
                />
              </FormControl>
              <FormControl
                label={`${polyglot.t("common.vat_number")} (${polyglot.t(
                  "common.not_required"
                )})`}
              >
                <Field name="company_vat">
                  {({ field }) => (
                    <Input
                      {...field}
                      placeholder={`${polyglot.country.toUpperCase()} •••••••••`}
                    />
                  )}
                </Field>
              </FormControl>
              <Button.Large
                type="submit"
                block
                isLoading={putBusiness.isLoading}
                disabled={!isValid || !dirty}
              >
                {polyglot.t("common.save")}
              </Button.Large>
            </Form>
          )}
        </Formik>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={spacings.l}
        >
          <Spinner.Medium />
        </Block>
      )}
    </Block>
  );
};

export default Business;
