import React, { Fragment } from "react";
import polyglot from "../../utils/polyglot";

const WizardAlert = ({ type, text }) => {
  const renderIcon = () => {
    switch (type) {
      case "danger":
        return <i className="icon-exclamation-circle" />;
      case "success":
        return <i className="icon-check" />;
    }
  };
  const renderTitle = () => {
    switch (type) {
      case "danger":
        return polyglot.t("common.errors.title");
      case "success":
        return polyglot.t("common.success.title");
    }
  };
  return (
    <>
      {text ? (
        <div
          className={`wizard-alert ${
            type === "danger" ? "wizard-alert--danger" : ""
          }`}
        >
          <div className="wizard-alert-icon-wrapper">
            <div className="wizard-alert-icon">{renderIcon()}</div>
          </div>
          <div className="wizard-content">
            <span className="alert-title">{renderTitle()}</span>
            <p>{text}</p>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default WizardAlert;
