import React, { useEffect, useState } from "react";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Body14,
  Button,
  Divider,
  List,
  Spinner,
} from "../../../components";
import { getTotalPricing } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const SimulateTaxCredit = ({
  isLoading,
  deduction,
  employee_part,
  employer_part,
  nb_hours,
  urssaf,
  deduction_term,
  price_per_hour,
  selectedJobber,
  frequency,
  noAlert,
  title = "Simulation de vos paiements",
  renderTitle,
  showTaxCredit = true,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const totalPricing = getTotalPricing({
    urssaf,
    employee_part,
    employer_part,
    deduction,
    price_per_hour,
    nb_hours,
  });
  const taxes = employer_part + employee_part;
  const taxesReduction = totalPricing / 2;
  const getFrequency = () => {
    if (frequency) {
      return frequency === "monthly" ? "mensuel" : "hebdomadaire";
    }
    return "";
  };
  useEffect(() => {
    setShowDetails(false);
  }, [urssaf]);

  return (
    <div className={`border pb-2 px-3 radius-l ${!title ? "pt-3" : ""}`}>
      {title && (
        <List.Header
          title="Simulation de vos paiements"
          style={{ minHeight: 0 }}
          className="mb-2 px-2"
        />
      )}
      {!isLoading ? (
        <>
          {renderTitle && (
            <div className="px-2">
              {renderTitle({ taxesReduction, totalPricing })}
            </div>
          )}
          <List.Item
            className="px-2"
            divider={false}
            style={{ minHeight: 54 }}
            RightComponent={() => (
              <div className="d-flex flex-column">
                <List.Elem.Label className="text-dark">
                  {polyglot.toSmartCurrency(nb_hours * price_per_hour)}
                </List.Elem.Label>
                <List.Elem.Label className="font-size-2">
                  {polyglot.toSmartCurrency(price_per_hour)}/h x{" "}
                  {polyglot.t("common.hours_plural", {
                    count: nb_hours,
                  })}
                </List.Elem.Label>
              </div>
            )}
          >
            <List.Elem.Title>
              Salaire {getFrequency() || "net"}{" "}
              {selectedJobber?.first_name && `de ${selectedJobber?.first_name}`}
            </List.Elem.Title>
          </List.Item>
          {urssaf && (
            <List.Item
              className="px-2"
              divider={false}
              style={{ minHeight: 54 }}
              RightComponent={() => (
                <List.Elem.Label className="text-dark">
                  {polyglot.toSmartCurrency(taxes)}
                </List.Elem.Label>
              )}
            >
              <List.Elem.Title>
                Montant des charges sociales
                <Button.XSmall
                  kind="tertiary"
                  className="ml-2"
                  RightComponent={() => (
                    <i
                      className={`icon-angle-down icon-rotate-${
                        showDetails ? 180 : 0
                      }`}
                    />
                  )}
                  onClick={() => setShowDetails((s) => !s)}
                >
                  Détails
                </Button.XSmall>
              </List.Elem.Title>
            </List.Item>
          )}

          {/* -------------------- */}
          {showDetails && (
            <div className="rounded border px-3 py-1 mb-2">
              <List.Item
                divider={false}
                style={{ minHeight: 54 }}
                RightComponent={() => (
                  <List.Elem.Label className="text-dark">
                    {polyglot.toSmartCurrency(employee_part)}
                  </List.Elem.Label>
                )}
              >
                <List.Elem.Title>Charges salariales</List.Elem.Title>
              </List.Item>
              <List.Item
                divider={false}
                style={{ minHeight: 54 }}
                RightComponent={() => (
                  <List.Elem.Label className="text-dark">
                    {polyglot.toSmartCurrency(employer_part)}
                  </List.Elem.Label>
                )}
              >
                <List.Elem.Title>Charges patronales</List.Elem.Title>
              </List.Item>
            </div>
          )}
          {/* -------------------- */}
          {urssaf && (
            <List.Item
              className="bg-success-light px-2"
              divider={false}
              RightComponent={() => (
                <div className="d-flex flex-column">
                  <List.Elem.Label className="text-success">
                    {polyglot.toSmartCurrency(-deduction)}
                  </List.Elem.Label>
                </div>
              )}
            >
              <List.Elem.Title>Abattement sur les charges</List.Elem.Title>
              {!!deduction_term && (
                <List.Elem.Subtitle>{deduction_term}</List.Elem.Subtitle>
              )}
            </List.Item>
          )}
          <List.Item
            className="px-2"
            divider={false}
            style={{ minHeight: 54 }}
            RightComponent={() => (
              <List.Elem.Label
                className={`${!urssaf ? "font-weight-medium" : ""} text-dark`}
              >
                {polyglot.toSmartCurrency(totalPricing)}
              </List.Elem.Label>
            )}
          >
            <List.Elem.Title
              className={`${!urssaf ? "font-weight-medium" : ""}`}
            >
              Montant {getFrequency()} total
            </List.Elem.Title>
          </List.Item>
          {urssaf && showTaxCredit && <Divider.Section className="mt-2" />}
          {urssaf && showTaxCredit && (
            <List.Item
              className="px-2"
              divider={false}
              RightComponent={() => (
                <List.Elem.Label className="text-dark font-weight-medium font-size-4">
                  {polyglot.toSmartCurrency(totalPricing - taxesReduction)}
                </List.Elem.Label>
              )}
            >
              <List.Elem.Title className="font-weight-medium">
                Montant {getFrequency()} après déductions fiscales
              </List.Elem.Title>
            </List.Item>
          )}
          {urssaf && !noAlert && (
            <Alert.Low kind="primary" className="mb-2">
              Selon votre éligibilité, vous bénéficierez d'un crédit d'impôt de
              50% soit {polyglot.toSmartCurrency(taxesReduction)}, qui sera
              automatiquement déduit sur votre prochaine fiche d'imposition.
            </Alert.Low>
          )}
        </>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center mt-3 my-5">
          <Spinner.Medium />
          <Body14
            color={colors.muted}
            css={`
              margin-top: ${spacings.s};
            `}
          >
            Calcul en cours
          </Body14>
        </div>
      )}
    </div>
  );
};

export default SimulateTaxCredit;
