import React from "react";
import {
  radius,
  shadows,
  sizes,
  spacings,
  colors,
  borderWidth,
} from "../../../assets/themes";
import { Block, Col, Row, Skeleton } from "../../../components";

const JobsSkeleton = () => (
  <Skeleton.Group>
    {new Array(3).fill("").map((_, i) => (
      <Block
        key={`job-skel-${i}`}
        marginY={spacings.l}
        borderRadius={radius.m}
        boxShadow={shadows.xs}
        border={`solid ${borderWidth.s} ${colors.border}`}
        padding={spacings.l}
      >
        <Row gutter={[spacings.m]}>
          <Col size={{ xs: 12, xl: 6 }}>
            <Skeleton height={sizes.size128} width="100%" sb />
            <Skeleton height={sizes.size18} width="60%" sb />
            <Skeleton height={sizes.size16} width="80%" sb />
            <Skeleton height={sizes.size54} width="100%" sb />
          </Col>
          <Col size={{ xs: 12, xl: 6 }}>
            <Skeleton height={sizes.size48} width="100" sb rows={3} />
          </Col>
        </Row>
      </Block>
    ))}
  </Skeleton.Group>
);

export default JobsSkeleton;
