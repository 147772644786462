export const POPOVER = {
  TOOLTIP_MAX_WIDTH: 300,
  MENU_MAX_WIDTH: 300,
  TRIGGER_TYPE: { CLICK: "click", HOVER: "hover", FOCUS: "focus" },
  STRATEGY: { ABSOLUTE: "absolute", FIXED: "fixed" },
  POSITIONS: {
    TOP: "top",
    TOP_LEFT: "top-start",
    TOP_RIGHT: "top-end",
    RIGHT: "right",
    BOTTOM: "bottom",
    LEFT: "left",
    BOTTOM_LEFT: "bottom-start",
    BOTTOM_RIGHT: "bottom-end",
  },
};

export const MOBILE_HEADER = {
  KIND: { DEFAULT: "default", FLOATING: "floating" },
};
export const BANNER = {
  SIZE: { LARGE: "large", MEDIUM: "medium" },
};

export const INFO = {
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
};

export const STEPPER = {
  SIZE: { LARGE: "large", MEDIUM: "medium" },
};

export const CALENDAR = {
  SIZE: { COMPACT: "compact", DEFAULT: "default" },
};

export const CHECKBOX = {
  SHAPE: { CIRCLE: "circle", DEFAULT: "default" },
};

export const STEPS = {
  SIZE: { COMPACT: "compact", DEFAULT: "default" },
  ORIENTATION: { HORIZONTAL: "horizontal", VERTICAL: "vertical" },
};

export const BUTTON = {
  SHAPE: { CIRCLE: "circle", PILL: "pill", DEFAULT: "default" },
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
  ACCENT_COLOR: {
    PRIMARY: "primary",
    DANGER: "danger",
    SUCCESS: "success",
    BODY: "body",
  },
  KIND: {
    PRIMARY: "primary",
    SECONDARY: "secondary",
    TERTIARY: "tertiary",
    MINIMAL: "minimal",
    LINK: "link",
  },
};

export const SKELETON = {
  SHAPE: {
    CIRCLE: "circle",
    ROUND: "round",
    SQUARE: "square",
    DEFAULT: "default",
  },
};

export const TOAST = {
  KIND: {
    DANGER: "danger",
    SUCCESS: "success",
    INFO: "primary",
  },
};

export const LIST = {
  SHAPE: { ROUND: "round", DEFAULT: "default" },
  SIZE: { LARGE: "large", COMPACT: "compact", DEFAULT: "default" },
};

export const LIST_HEADER = {
  KIND: { HEADING: "heading", SUBTITLE: "subtitle" },
};

export const SHAPE = {
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
  SHAPE: { ROUND: "round", CIRCLE: "circle" },
};

export const TAG = {
  KIND: {
    SUCCESS: "success",
    DANGER: "danger",
    WARNING: "warning",
    PRIMARY: "primary",
    MUTED: "muted",
    WHITE: "white",
    PINK: "pink",
    PURPLE: "purple",
  },
  SIZE: {
    MEDIUM: "medium",
    SMALL: "small",
  },
};

export const ALERT = {
  KIND: {
    SUCCESS: "success",
    DANGER: "danger",
    WARNING: "warning",
    PRIMARY: "primary",
  },
};

export const DIVIDER = {
  ORIENTATION: { HORIZONTAL: "horizontal", VERTICAL: "vertical" },
  SIZE: { SECTION: "section", CELL: "cell", SEMI: "semi", GROUP: "group" },
};

export const INPUT = {
  SHAPE: { CIRCLE: "circle", DEFAULT: "default" },
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
};

export const CROPPER = {
  SHAPE: { CIRCLE: "circle", SQUARE: "SQUARE" },
};

export const BADGE = {
  POSITION: {
    TOP_RIGHT: "topRight",
    TOP_LEFT: "topLeft",
    BOTTOM_RIGHT: "bottomRight",
    BOTTOM_LEFT: "bottomLeft",
  },
};

export const SELECT = {
  POSITION: {
    TOP: "top-start",
    BOTTOM: "bottom-start",
  },
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
};

export const SELECTABLE = {
  SIZE: { LARGE: "large", MEDIUM: "medium", SMALL: "small" },
  SHAPE: { CIRCLE: "circle", DEFAULT: "default" },
};

export const PROGRESS = {
  SHAPE: { ROUND: "round", DEFAULT: "default" },
};

export const ICON = {
  SIZE: { XLARGE: "xlarge", LARGE: "large", MEDIUM: "medium", SMALL: "small" },
};
