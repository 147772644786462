import React from "react";
import styled, { css } from "styled-components";
import {
  Button,
  ConditionnalWrapper,
  Icon,
  ImageGallery,
  List,
  Shape,
  Spinner,
} from "@/components";
import { BUTTON, LIST, SHAPE } from "@/components/Styles/variants";
import { formatFileSize } from "@/utils";
import polyglot from "@/utils/polyglot";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import Avatar from "../../components/avatar";
import Block from "../../components/Block";
import { Body14, Body16, Caption } from "../../components/Text";
import linkify from "../../utils/linkify";
import { dateToString } from "../../utils/moment";
import ActionJobberHasCanceled from "./ActionJobberHasCanceled";
import ActionJobberIsUnavailable from "./ActionJobberIsUnavailable";
import AudioPlayer from "./AudioPlayer";
import ChatDateTimeChange from "./ChatDateTimeChange";
import VideoWrapper from "./VideoWrapper";

const StyledWidgetWrapper = styled.div`
  width: 100%;
  max-width: 400px;
  margin: ${spacings.m} auto;
`;

const StyledEdgeToEdgeWrapper = styled.div`
  width: calc(100% + ${spacings.ml});
  border-radius: ${radius.ml};
  margin: calc(${spacings.xs} * -1) calc(${spacings.sm} * -1) 0
    calc(${spacings.sm} * -1);
  overflow: hidden;
`;

const setMargin = ({ first, last }) => {
  if (first) {
    return css`
      margin-top: ${spacings.m};
    `;
  }
  if (last) {
    return css`
      margin-bottom: 0;
    `;
  }
  return null;
};

const StyledWrapper = styled.div`
  width: 100%;
  ${({ own, stacked }) => css`
    display: flex;
    align-items: flex-end;
    flex-direction: ${own ? "row-reverse" : "row"};
    justify-content: ${own ? null : "flex-start"};
    margin-top: 2px;
    margin-bottom: 2px;
    ${setMargin(stacked)};
  `}
  ${({ stacked }) =>
    stacked.middle &&
    css`
      margin-top: 2px;
    `};
  ${({ stacked }) =>
    stacked.last &&
    css`
      margin-top: 2px;
      margin-bottom: ${spacings.m};
    `}
  ${({ stacked }) =>
    stacked.first &&
    css`
      margin-top: ${spacings.m};
      margin-bottom: 2px;
    `}
`;

const StyledMessageContainer = styled.div`
  ${({ own }) => css`
    display: flex;
    align-items: ${own ? "flex-end" : "flex-start"};
    flex-direction: column;
    margin-left: ${!own && spacings.s};
    width: 70%;
  `}
`;

const StyledMessage = styled.div`
  ${({ own, isError }) => css`
    display: flex;
    align-items: ${own ? "flex-end" : "flex-start"};
    flex-direction: column;
    background: ${own
      ? isError
        ? colors.danger
        : colors.primary
      : colors.backgroundGray};
    color: ${own ? colors.onColor : colors.body};
    a {
      color: ${own ? colors.onColor : colors.body};
      &:hover {
        color: ${own ? colors.onColor : colors.body};
      }
      text-decoration: underline;
    }
    > * {
      word-break: break-word;
      white-space: break-spaces;
    }
    border-radius: ${radius.l};
    padding: ${spacings.s} ${spacings.m};
    position: relative;
    [aria-label="timestamp"] {
      color: ${own ? colors.primary100 : colors.muted};
    }
  `}
`;

const ChatMessage = ({
  kind,
  user,
  body,
  isLast,
  offerId,
  action,
  timestamp,
  forceMainTimestamp,
  date_time_change,
  discussable_id,
  discussable_url,
  id,
  current_user,
  own,
  date,
  hour,
  stacked,
  attachment,
  gallery,
  message_kind,
  isSeen,
  isLoading,
  isError,
  retry,
}) => (
  <Block display="flex" flexDirection="column">
    <Block display="flex" alignItems="center" justifyContent="center">
      {kind === "information" && (
        <Block width="100%" margin={spacings.s}>
          <Body14 align="center" color={colors.muted}>
            {body}
          </Body14>
        </Block>
      )}
      {kind === "date_time_change" && (
        <StyledWidgetWrapper>
          <ChatDateTimeChange
            date_time_change={date_time_change}
            body={body}
            user={user}
            discussable_id={discussable_id}
            current_user={current_user}
            id={id}
          />
        </StyledWidgetWrapper>
      )}
    </Block>
    <StyledWrapper own={own} stacked={stacked} isError={isError}>
      {kind === "message" && (
        <Block width="100%">
          <Block
            display="flex"
            justifyContent={own ? "flex-end" : "flex-start"}
          >
            {!user?.is_current_user && (
              <Block width={sizes.size48} position="relative">
                {(stacked?.last || !stacked) && (
                  <Block position="absolute" bottom="0" right="0">
                    <Avatar
                      src={user?.avatar}
                      size={sizes.size48}
                      name={user?.first_name}
                    />
                  </Block>
                )}
              </Block>
            )}
            <StyledMessageContainer own={own} stacked={stacked}>
              <StyledMessage own={own} stacked={stacked} isError={isError}>
                {!own && (
                  <Block marginBottom={spacings.xs}>
                    <Body14 strong>{user.first_name}</Body14>
                  </Block>
                )}
                {body && <Body16>{linkify(body)}</Body16>}
                {attachment &&
                  (attachment.kind === "image_message" ||
                    attachment.kind === "video_message") && (
                    <ImageGallery>
                      {gallery?.map((galleryItem) => (
                        <div
                          key={attachment.id}
                          srcSet={galleryItem.url}
                          hidden={attachment.id !== galleryItem.id}
                          disabled={isLoading}
                        >
                          <ConditionnalWrapper
                            condition={attachment.kind === "video_message"}
                            wrapper={(children) => (
                              <VideoWrapper>{children}</VideoWrapper>
                            )}
                          >
                            <StyledEdgeToEdgeWrapper
                              as="img"
                              id={`chat-image-${attachment.id}`}
                              onError={(e) => {
                                // monkey-patch image loading error for no reason.
                                e.target.src = attachment.image_preview_url;
                              }}
                              src={attachment.image_preview_url}
                              srcSet={attachment.image_preview_url}
                              alt={attachment.filename}
                              width={attachment.image_preview_width}
                              height={attachment.image_preview_height}
                              css={`
                                aspect-ratio: ${`${attachment.image_preview_width}/${attachment.image_preview_height}`};
                                max-height: 300px;
                                max-width: 300px;
                                height: auto;
                                object-fit: cover;
                              `}
                            />
                          </ConditionnalWrapper>
                        </div>
                      ))}
                    </ImageGallery>
                  )}
                {attachment && attachment.kind === "audio_message" && (
                  <AudioPlayer
                    {...attachment}
                    buttonColor={own ? colors.onColor : colors.body}
                    activeBarColor={!own ? colors.body : colors.onColor}
                    barColor={
                      !own ? "rgba(0, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.5)"
                    }
                  />
                )}
                {attachment && attachment.kind === "document_message" && (
                  <StyledEdgeToEdgeWrapper>
                    <List.Item
                      target="_blank"
                      withGutters
                      divider={false}
                      shape={LIST.SHAPE.ROUND}
                      size={LIST.SIZE.COMPACT}
                      href={attachment.url}
                      css={`
                        background-color: ${own
                          ? colors.overlayBackdrop
                          : colors.backgroundGray}!important;
                      `}
                      LeftComponent={() => (
                        <Shape.Small
                          shape={SHAPE.SHAPE.ROUND}
                          backgroundColor={colors.orange}
                        >
                          <Icon.Large name="file" color={colors.onColor} />
                        </Shape.Small>
                      )}
                    >
                      <Body16
                        numberOfLines={1}
                        strong
                        color={own ? colors.onColor : colors.body}
                      >
                        {attachment.filename}
                      </Body16>
                      <Body14
                        aria-label="timestamp"
                        color={own ? colors.onColor : colors.body}
                      >
                        {formatFileSize(attachment.file_size)}&nbsp;•&nbsp;
                        {
                          attachment.filename.split(".")[
                            attachment.filename.split(".").length - 1
                          ]
                        }
                      </Body14>
                    </List.Item>
                  </StyledEdgeToEdgeWrapper>
                )}
                <Block
                  marginTop={spacings.xs}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Caption align="right" aria-label="timestamp" />
                  <Caption align="right" aria-label="timestamp">
                    {isSeen && own && (
                      <>{polyglot.t("chat.seen")}&nbsp;•&nbsp;</>
                    )}
                    {hour}
                  </Caption>
                </Block>
              </StyledMessage>
            </StyledMessageContainer>
            {isError && (
              <Block
                display="flex"
                width={sizes.size48}
                alignItems="center"
                marginLeft={spacings.s}
              >
                <Button.Small
                  shape={BUTTON.SHAPE.CIRCLE}
                  kind={BUTTON.KIND.PRIMARY}
                  accentColor={BUTTON.ACCENT_COLOR.DANGER}
                  onClick={() => retry()}
                >
                  <Icon.Medium name="retry" />
                </Button.Small>
              </Block>
            )}
            {isLoading && (
              <Block
                width={sizes.size48}
                display="flex"
                alignItems="center"
                marginLeft={spacings.s}
              >
                <Spinner.Small brand={false} color={colors.muted} />
              </Block>
            )}
          </Block>
          {action === "jobber_is_unavailable" && (
            <StyledWidgetWrapper>
              <ActionJobberIsUnavailable offerId={offerId} user={user} />
            </StyledWidgetWrapper>
          )}
          {action === "jobber_has_canceled" && (
            <StyledWidgetWrapper>
              <ActionJobberHasCanceled
                discussable_url={discussable_url}
                user={user}
              />
            </StyledWidgetWrapper>
          )}
        </Block>
      )}
    </StyledWrapper>
    <Block order="-2">
      {forceMainTimestamp && (
        <Block
          marginTop={stacked ? spacings.l : spacings.m}
          marginBottom={spacings.s}
        >
          <Body14 color={colors.muted} align="center">
            {dateToString(date)}
          </Body14>
        </Block>
      )}
    </Block>
  </Block>
);

const areEqual = (p, n) =>
  p.timestamp === n.timestamp &&
  p.isLast === n.isLast &&
  p.id === n.id &&
  p.attachment?.preview_url === n.attachment?.preview_url &&
  p.stacked === n.stacked &&
  p.isSeen === n.isSeen;
export default React.memo(ChatMessage, areEqual);
