import React, { classNames } from 'react';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import { getEventsFromObject } from '../../utils/functions';

const WizardInput = props => {
  const {
    label,
    type,
    name,
    mask,
    onKeyUp,
    onChange,
    onBlur,
    onFocus,
    guide,
    errors,
    touched,
    placeholder,
    maxLength,
    autoFocus,
    values,
    tabIndex,
    submitOnValid,
    uppercase,
    isValid,
    customClass = { input: '', label: '' },
    handleSubmit,
    className,
    setSubmitting,
    disabled,
    customError,
  } = props;
  const eventsHandler = getEventsFromObject(props);
  const hasError = customError ? true : errors[name] && touched[name];
  // const inputId = name + Math.floor(Math.random() * (999 - 100 + 1) + 100);
  return (
    <div className={`wizard-input ${className}`}>
      {label ? (
        <label htmlFor={name} className={`wizard-input-label ${customClass.label}`}>
          {label}
        </label>
      ) : null}
      <div className={`wizard-input-wrapper ${disabled && submitOnValid ? 'is-loading' : ''}`}>
        {mask ? (
          <Field type={type} name={name}>
            {({ field }) => (
              <MaskedInput
                autoFocus={autoFocus}
                guide={guide}
                {...eventsHandler}
                placeholder={placeholder}
                mask={mask}
                maxLength={maxLength}
                id={name}
                disabled={disabled}
                tabIndex={tabIndex}
                className={hasError ? `has-error ${customClass.input}` : customClass.input}
                {...field}
              />
            )}
          </Field>
        ) : (
          <Field
            type={type}
            placeholder={placeholder}
            name={name}
            id={name}
            tabIndex={tabIndex}
            maxLength={maxLength}
            disabled={disabled}
            autoFocus={autoFocus}
            className={hasError ? `has-error ${customClass.input}` : customClass.input}
            {...eventsHandler}
          />
        )}
      </div>
      <div className="input-error">
        {hasError ? (
          <div className="input-error-content">
            <i className="icon-exclamation-circle" />
            <p>{customError || errors[name]}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default WizardInput;
