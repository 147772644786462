import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = {
  multipleJobbersAllowed: true,
  isCustom: true,
  initialTitleIsEmpty: true,
};

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory1102() {
  const initialValues = {
    nh_specific: "",
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.dispose_of_waste"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 0.5, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl
            label={polyglot.t("categories.jobber_get_rid_of_waste")}
          >
            <FormSelectables
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    config,
  };
}
