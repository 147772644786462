import { FastField, Formik } from "formik";
import React, { useState } from "react";
import { Icon, Input } from "../components";
import { setAddressInitialValues } from "../utils";
import polyglot from "../utils/polyglot";
import { AddressModal } from "./common";

const AddressInput = ({
  placeholder = polyglot.t("common.placeholder.address"),
  value: _value,
  initialValues,
  ...rest
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <>
      <Formik initialValues={setAddressInitialValues(initialValues)}>
        <div>
          <AddressModal
            onClose={() => setModalIsOpen(false)}
            isOpen={modalIsOpen}
            {...rest}
          />
          <FastField name="address">
            {({ field }) => (
              <Input
                onClick={() => setModalIsOpen(true)}
                value={field.value}
                readOnly
                placeholder={placeholder}
                RightComponent={() => <Icon.Medium name="dropdown" />}
              />
            )}
          </FastField>
          {/* field.value || "" -> Addresschange set some field to null causing uncrolled warning */}
          <FastField name="city_id">
            {({ field }) => (
              <input {...field} value={field.value || ""} readOnly hidden />
            )}
          </FastField>
          <FastField name="address_id" hidden readOnly>
            {({ field }) => (
              <input {...field} value={field.value || ""} readOnly hidden />
            )}
          </FastField>
          <FastField name="address_name" hidden readOnly>
            {({ field }) => (
              <input {...field} value={field.value || ""} readOnly hidden />
            )}
          </FastField>
          <FastField name="place_id" hidden readOnly>
            {({ field }) => (
              <input {...field} value={field.value || ""} readOnly hidden />
            )}
          </FastField>
        </div>
      </Formik>
    </>
  );
};

export default AddressInput;
