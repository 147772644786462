import { useCallback } from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, H3 } from "../../components";
import CubeSchema from "../MovingVolumeCalculator/CubeSchema";
import polyglot from "@/utils/polyglot";

const Result = ({ width, height, long }) => {
  const getResult = useCallback(() => {
    const rawVolume = long * width * height;
    return (Math.round(rawVolume * 100) / 100)
      .toFixed(2)
      .toString()
      .replace(".", ",")
      .padEnd(4, "0");
  }, [long, height, width]);

  return (
    <Block
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      margin={spacings.m}
    >
      <Body16 color={colors.muted}>
        {polyglot.t("moving_boxes.total_volume")}
      </Body16>
      <Block display="flex" alignItems="center">
        <Block width={40} marginRight={spacings.xs}>
          <CubeSchema
            width={width}
            height={height}
            long={long}
            strokeWidth={35}
            color={colors.body}
          />
        </Block>
        <H3>{getResult()} m³</H3>
      </Block>
    </Block>
  );
};
export default Result;
