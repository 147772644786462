import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnMount } from '../modules/hooks';

const Toast = ({
  title,
  body,
  timestamp,
  type = 'default',
  autoHide = 4000,
  icon,
  isVisible: isVisibleProps,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [toastClass, setToastClass] = useState('toast-enter');
  const toast = useRef();
  const hide = () => {
    setToastClass('');
    setTimeout(() => {
      setToastClass('toast-leave');
    }, 0);
    setTimeout(() => {
      setIsVisible(false);
    }, 400);
  };

  useOnMount(() => {
    setTimeout(() => {
      setIsVisible(isVisibleProps);
    }, 100);
  });

  useEffect(() => {
    const to = setTimeout(() => {
      hide();
    }, autoHide);
    isVisible && autoHide && to;
    return () => {
      hide();
      to && clearTimeout(to);
    };
  }, [isVisibleProps]);

  const el = (
    <div style={{ position: 'fixed', top: 100, right: 16, zIndex: 9999 }}>
      <div
        ref={toast}
        className={`toast ${toastClass} ${isVisible ? 'show' : ''}`}
        role="alert"
        aria-live="assertive"
        aria-atomic="true">
        <div className={`toast-header ${type !== 'default' ? `bg-${type} text-white` : ''}`}>
          <i className={`icon-${icon} mr-2`} />
          <strong className="mr-auto">{title}</strong>
          {timestamp && <small>{timestamp}</small>}
          <button
            type="button"
            style={{ color: 'inherit' }}
            className="ml-2 mb-0 close"
            data-dismiss="toast"
            aria-label="Close"
            onClick={() => {
              setIsVisible(false);
            }}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body">{body}</div>
      </div>
    </div>
  );
  return ReactDOM.createPortal(el, document.body);
};

export default Toast;
