import { FastField } from "formik";
import React from "react";
import { FormControl, Input } from "../../../../components";
import polyglot from "../../../../utils/polyglot";
import { CREDIT_CARD_EXP_DATE } from "../constants";

const ExpirationDateField = () => (
  <FastField name="cardExpirationDate">
    {({ field }) => (
      <FormControl label={polyglot.t("payment_methods.expiration_date")}>
        <Input
          inputMode="numeric"
          autoComplete="cc-exp"
          placeholder="12/24"
          mask={CREDIT_CARD_EXP_DATE}
          {...field}
        />
      </FormControl>
    )}
  </FastField>
);

export default ExpirationDateField;
