import { FastField, Formik } from "formik";
import React from "react";
import { setPaymentInitialValues } from "../utils";
import PaymentMethodsUpdated from "./common/payment-methods";

const PaymentMethodsInput = ({ initialValues, ...rest }) => (
  <Formik initialValues={{ ...setPaymentInitialValues(...initialValues) }}>
    <>
      <PaymentMethodsUpdated.A {...rest} />
      <FastField name="token" hidden />
      <FastField name="payment_method" hidden />
    </>
  </Formik>
);

export default PaymentMethodsInput;
