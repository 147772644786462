import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = { multipleJobbersAllowed: true, isCustom: true };

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory3015() {
  const initialValues = { nh_specific: "" };

  const getNbHours = () => 2;

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.truck_needed"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 0.1, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl
            label={polyglot.t("categories.jobber_bring_own_truck")}
          >
            <FormSelectables
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
