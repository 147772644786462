import polyglot from "../../../utils/polyglot";
import { NB_JOBBERS_PATH } from "../constants";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1105() {
  const DEFAULT_NB_HOURS = 3;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };

  return {
    version: 1,
    initialValues,
    config: {
      isCustom: true,
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
      initialTitleIsEmpty: true,
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbJobbersIndex = newPages.findIndex(
          (p) => p.path === NB_JOBBERS_PATH
        );
        newPages.splice(nbJobbersIndex + 1, 0, {
          ...NB_HOURS_SELECTION_PAGE,
          customProps: {
            options: [
              {
                label: polyglot.t("categories.short"),
                value: 1.5,
                subtitle: polyglot.t("categories.hang_some_paintings"),
              },
              {
                label: polyglot.t("categories.classic"),
                value: DEFAULT_NB_HOURS,
                subtitle: polyglot.t("categories.hang_some_wall_decorations"),
                popular: true,
              },
              {
                label: polyglot.t("categories.long"),
                value: 4.5,
                subtitle: polyglot.t(
                  "categories.hang_curtain_rods_and_decorations"
                ),
              },
            ],
          },
        });
        return newPages;
      },
    },
  };
}
