import { useHistory, useParams } from "react-router-dom";
import { Modal } from "@/components";
import JobberTaxes from "@/pages/dashboard/taxesCredit/JobberTaxes";
import { ACCOUNT_TAXES_PATH } from "../config/routes";

const JobberTaxesModal = () => {
  const history = useHistory();
  const { id } = useParams();

  const handleClose = () => {
    if (!history?.location?.key) {
      history?.replace(ACCOUNT_TAXES_PATH);
    } else {
      history?.goBack();
    }
  };
  return (
    <Modal.Small isOpen onClose={handleClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={handleClose} />
      <Modal.Item.Wrapper>
        <JobberTaxes id={id} onClose={handleClose} />
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};
export default JobberTaxesModal;
