import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import { useCategoryForm } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";
import InstantBookingLoading from "./InstantBooking/InstantBookingLoading";
import { useCategoryFormLocalStorage } from "./useCategoryFormLocalStorage";

const CategoryFormsRedirection = () => {
  const { values } = useFormikContext();
  const isPrivate = values?.private_jobber_id;
  const [animationEnd, setAnimationEnd] = useState(false);
  const { id, postForm, jobbers } = useCategoryForm();
  const history = useHistory();
  const categoryFormLocalStorage = useCategoryFormLocalStorage(id);

  const getPrivateJobberInformation = () => {
    if (isPrivate) {
      const selectedJobber = jobbers.find(
        (jobber) => jobber.id === values?.private_jobber_id
      );
      if (selectedJobber) return selectedJobber;
    }
    return null;
  };

  const DEFAULT_STEPS = isPrivate
    ? [
        polyglot.t(
          "categories.loading_availabilities_verification_first_name",
          {
            first_name: getPrivateJobberInformation()?.first_name,
          }
        ),
        polyglot.t("categories.loading_send_to_first_name", {
          first_name: getPrivateJobberInformation()?.first_name,
        }),
      ]
    : [
        polyglot.t("categories.loading_best_jobbers_selection"),
        polyglot.t("categories.loading_availabilities_verification"),
      ];
  const [steps] = useState(DEFAULT_STEPS);

  useEffect(() => {
    if (values) {
      postForm.mutate(values);
    }
  }, []);

  const handleOffersAnimationEnd = () => {
    setAnimationEnd(true);
  };

  useEffect(() => {
    if (animationEnd && postForm.isSuccess) {
      window.location.href = postForm.data.data.data.redirect_url;
      categoryFormLocalStorage.remove();
    }
  }, [animationEnd, postForm.isSuccess]);

  useEffect(() => {
    if (postForm.isError) {
      history.go(-1);
    }
  }, [postForm.isError]);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={window.location.pathname}
          render={() => (
            <InstantBookingLoading
              steps={steps}
              onAnimationEnd={() => handleOffersAnimationEnd()}
            />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default CategoryFormsRedirection;
