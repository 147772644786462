import React from 'react';
import JobberSelectionTutorial from '../pages/JobberSelectionTutorial';
import ErrorBoundary from '../utils/ErrorBoundary';

const JobberSelectionTutorialApp = (props, rails) => () => (
  <ErrorBoundary>
    <JobberSelectionTutorial {...props} />
  </ErrorBoundary>
);

export default JobberSelectionTutorialApp;
