import React, { useState, useEffect } from "react";
import { spacings } from "../../assets/themes";
import { Block, Button, List, Modal, Stepper } from "../../components";
import { putEditRelation } from "../../modules/routes";
import polyglot from "../../utils/polyglot";

const EditPricePerHourModal = ({
  onClose,
  price_per_hour,
  isOpen,
  price_per_hour_min,
  price_per_hour_max,
  updateData,
  id,
}) => {
  const [currValue, setCurrValue] = useState(price_per_hour);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrValue(price_per_hour);
  }, [price_per_hour]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = putEditRelation({ ...values, id });
    res
      .then(({ data }) => {
        updateData();
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header
        onClose={onClose}
        title="Taux horaire du prestataire"
        subtitle="Indiquez la rémunération nette que doit percevoir le prestataire à chaque heure travaillée."
      />

      <Modal.Item.Wrapper>
        <List.Item
          divider={false}
          title="Taux horaire du prestataire"
          RightComponent={() => (
            <Block marginY={spacings.s}>
              <Stepper
                value={currValue}
                min={price_per_hour_min}
                max={price_per_hour_max}
                hint="Par heure"
                suffix={polyglot.currency}
                onChange={(v) => setCurrValue(v)}
              />
            </Block>
          )}
        />
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large
          block
          disabled={currValue === price_per_hour}
          onClick={() => handleSubmit({ price_per_hour: currValue })}
          isLoading={isLoading}
        >
          Enregistrer
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.Small>
  );
};

export default EditPricePerHourModal;
