import { useFormikContext } from "formik";
import * as yup from "yup";
import ChildAge1 from "@/assets/images/baby-sitting/child-age1.svg";
import ChildAge2 from "@/assets/images/baby-sitting/child-age2.svg";
import ChildAge3 from "@/assets/images/baby-sitting/child-age3.svg";
import ChildAge4 from "@/assets/images/baby-sitting/child-age4.svg";
import Child1 from "@/assets/images/baby-sitting/child1.svg";
import Child2 from "@/assets/images/baby-sitting/child2.svg";
import Child3 from "@/assets/images/baby-sitting/child3.svg";
import Child4 from "@/assets/images/baby-sitting/child4.svg";
import { colors, sizes } from "@/assets/themes";
import polyglot from "@/utils/polyglot";
import StepTitle from "../../common/StepTitle";
import {
  FormChildCareDays,
  FormHorizontalCardCheckboxes,
  FormHorizontalCardRadios,
  FormSelectables,
  StyledFormControl,
} from "../../common/fields";
import { INFORMATIONS_PATH } from "../../constants";
import { moveIndexPosition } from "../../utils";
import { Body14, Body16 } from "@/components";

export const NH_CONFIG = {
  "nh_specific2[0]": {
    label: polyglot.t("categories.where_child_care_place"),
    options: [
      { value: 0, label: polyglot.t("categories.jobber_home") },
      { value: 1, label: polyglot.t("categories.customer_home") },
    ],
  },
  "nh_specific2[1]": {
    label: polyglot.t("categories.how_many_childs_look_after"),
    options: [
      { value: 1, label: polyglot.t("categories.child_1"), image: Child1 },
      { value: 2, label: polyglot.t("categories.child_2"), image: Child2 },
      { value: 3, label: polyglot.t("categories.child_3"), image: Child3 },
      { value: 4, label: polyglot.t("categories.child_4"), image: Child4 },
    ],
  },
  kids_information: {
    label: polyglot.t("categories.child_how_old"),
    options: [
      {
        value: ["toddlers"],
        label: polyglot.t("categories.child_age_1"),
        image: ChildAge1,
      },
      {
        value: ["preschoolers"],
        label: polyglot.t("categories.child_age_2"),
        image: ChildAge2,
      },
      {
        value: ["school_age_children"],
        label: polyglot.t("categories.child_age_3"),
        image: ChildAge3,
      },
      {
        value: ["preteens"],
        label: polyglot.t("categories.child_age_4"),
        image: ChildAge4,
      },
    ],
  },
};

export const CONFIG = {
  isTimeRange: true,
  IsNbHoursReadOnly: true,
  withFrequency: false,
  hidePhotos: true,
  minHour: 0,
  maxHour: 23.5,
  renderPages: (pages) => {
    let newPages = [...pages];
    const datePageIndex = pages.findIndex((p) => p.path === "date");
    const timePageIndex = pages.findIndex((p) => p.path === "start-hour");
    const addressPageIndex = pages.findIndex((p) => p.path === "address");
    const informationsPageIndex = pages.findIndex(
      (p) => p.path === INFORMATIONS_PATH
    );
    if (informationsPageIndex >= 0) {
      newPages[informationsPageIndex].title = polyglot.t(
        "categories.who_do_you_want_to_sit"
      );
    }
    newPages[addressPageIndex].title = polyglot.t(
      "categories.what_is_your_address"
    );
    newPages[datePageIndex].title = polyglot.t(
      "categories.when_you_need_sitting"
    );
    newPages = moveIndexPosition(pages, datePageIndex, 0);
    if (timePageIndex >= 0) {
      newPages = moveIndexPosition(pages, timePageIndex, 1);
    }

    return newPages;
  },
};

export const CHILD_PAGE = {
  path: "child-ages",
  validationSchema: yup.object().shape({
    kids_information: yup
      .array()
      .test(
        "kids_add_ages",
        "",
        (value, context) =>
          value.length > 0 && value.length <= context.parent.nh_specific2[1]
      ),
  }),
  View: () => {
    const { values, setFieldValue } = useFormikContext();

    const isMultiple = values.nh_specific2?.[1] > 1;

    const getTitle = () => {
      if (isMultiple) {
        return polyglot.t("categories.multiple_childs_how_old");
      }
      return polyglot.t("categories.child_how_old");
    };

    const handleChange = (value) => {
      if (value.length > values.nh_specific2[1]) {
        let newV = [...value];
        newV = newV.slice(0, -1);
        setFieldValue("kids_information", newV);
      }
    };

    return (
      <>
        <StepTitle>{getTitle()}</StepTitle>
        <StyledFormControl>
          {isMultiple ? (
            <FormHorizontalCardCheckboxes
              imageSize={sizes.size48}
              name="kids_information"
              // Remove array value for checkbox [""] -> ""
              options={NH_CONFIG.kids_information.options.map((e) => ({
                ...e,
                value: e.value[0],
              }))}
              onChange={handleChange}
            />
          ) : (
            <FormHorizontalCardRadios
              imageSize={sizes.size48}
              name="kids_information"
              options={NH_CONFIG.kids_information.options}
            />
          )}
        </StyledFormControl>
        {isMultiple && (
          <Body16 color={colors.muted}>
            {polyglot.t("categories.n_maximal_choices", {
              n: values.nh_specific2?.[1],
            })}
          </Body16>
        )}
      </>
    );
  },
};

export const CHILD_CARE_LOCATION = {
  path: "child-care-location",
  title: NH_CONFIG["nh_specific2[0]"].label,
  validationSchema: yup.object().shape({
    nh_specific2: yup
      .array()
      .test("nh2-0-exist", (value) => value?.[0]?.toString()),
  }),
  View: () => (
    <>
      <StepTitle>{NH_CONFIG["nh_specific2[0]"].label}</StepTitle>
      <StyledFormControl>
        <FormSelectables
          name="nh_specific2[0]"
          options={NH_CONFIG["nh_specific2[0]"].options}
        />
      </StyledFormControl>
    </>
  ),
};

export const CHILDREN_AMOUNT = {
  path: "childrens",
  title: NH_CONFIG["nh_specific2[1]"].label,
  validationSchema: yup.object().shape({
    nh_specific2: yup
      .array()
      .test("nh2-1-exist", (value) => value?.[1]?.toString()),
  }),
  View: () => {
    const { setFieldValue } = useFormikContext();
    const handleChange = () => {
      setFieldValue("kids_information", null);
    };

    return (
      <>
        <StepTitle>{NH_CONFIG["nh_specific2[1]"].label}</StepTitle>
        <StyledFormControl>
          <FormHorizontalCardRadios
            imageSize={sizes.size48}
            name="nh_specific2[1]"
            options={NH_CONFIG["nh_specific2[1]"].options}
            onChange={handleChange}
          />
        </StyledFormControl>
      </>
    );
  },
};

export const CHILD_CARE_DAYS = {
  path: "child-care-days",
  title: polyglot.t("categories.what_child_care_days"),
  validationSchema: yup.object().shape({
    days_information: yup
      .object()
      .test("is-not-empty", "", (value) => Object.keys(value || {}).length > 0)
      .test("valid-days", "", (value) =>
        Object.values(value || {}).every(
          (day) => Array.isArray(day) && day.length > 0
        )
      ),
  }),
  View: () => (
    <>
      <StepTitle>{polyglot.t("categories.what_child_care_days")}</StepTitle>
      <StyledFormControl>
        <FormChildCareDays name="days_information" />
      </StyledFormControl>
    </>
  ),
};
