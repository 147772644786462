import React, { useEffect } from "react";
import { MemoryRouter, useHistory, useLocation } from "react-router-dom";
import { Button, Container } from "../../components";
import Home from "./payments/Home";

const Payments = ({ id, updateData }) => {
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container.Medium className="my-5">
      <Button.Link onClick={() => history.push("/")}>
        <i className="icon-arrow-left mr-2" />
        Retour
      </Button.Link>
      <h1 className="d-block mt-4">Détails des paiements</h1>
      <MemoryRouter initialEntries={["/", "/subscription"]}>
        <Home id={id} updateData={updateData} />
      </MemoryRouter>
    </Container.Medium>
  );
};

export default Payments;
