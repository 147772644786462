import { useState } from "react";
import { colors } from "../../assets/themes";
import { Icon, List, Shape } from "../../components";
import { SHAPE } from "../../components/Styles/variants";
import { useCreateDateTimeRequest } from "../../modules/routes/dashboard-routes";
import EditDateTime from "../EditDateTime";
import polyglot from "@/utils/polyglot";

const CancellationDateTime = ({ id }) => {
  const [editDateTimeModalIsOpen, setEditDateTimeModalIsOpen] = useState(false);
  const createDateTime = useCreateDateTimeRequest({
    redirect: true,
    id,
  });
  return (
    <>
      <List.Item
        chevron
        onClick={() => setEditDateTimeModalIsOpen(true)}
        LeftComponent={() => (
          <Shape.Large
            shape={SHAPE.SHAPE.CIRCLE}
            backgroundColor={colors.primaryLight}
            color={colors.primary}
          >
            <Icon.XLarge name="calendar-edit" />
          </Shape.Large>
        )}
      >
        <List.Elem.Title strong>
          {polyglot.t("job.edit_datetime")}
        </List.Elem.Title>
        <List.Elem.Subtitle>
          {polyglot.t("job.edit_datetime_description")}
        </List.Elem.Subtitle>
      </List.Item>
      <EditDateTime
        discussable_id={id}
        isBooked
        query={createDateTime}
        isOpen={editDateTimeModalIsOpen}
        onClose={() => setEditDateTimeModalIsOpen(false)}
      />
    </>
  );
};
export default CancellationDateTime;
