import ReactOnRails from "react-on-rails";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import ErrorBoundary from "../utils/ErrorBoundary";
import { useOutlineEnhancer } from "./hooks";
import { ToastProvider } from "./providers";
import AskServiceProvider from "./providers/AskServiceProvider";
import LoginProvider from "./providers/LoginProvider";
import * as themes from "../assets/themes";

const queryClient = new QueryClient();
const GlobalStyle = createGlobalStyle`
  *[type="button"]{
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
  }
  body.mousedown {
    button:focus, button:active {
      outline-width: 0!important;
      outline-color: transparent!important;
    }
  }
  body:not(.mousedown){
    button:active,
    button:focus,
    textarea:active,
    textarea:focus,
    select:active,
    select:focus,
    fieldset:active,
    fieldset:focus,
    a:active,
    a:focus,
    details:active,
    details:focus,
    summary:active,
    summary:focus,
    [tabindex]:active,
    [tabindex]:focus,
    [contenteditable="true"]:active,
    [contenteditable="true"]:focus,
    a:not(input[type="text"]):not(input[type="search"]):focus,
    input:not(input[type="text"]):not(input[type="search"]):active + label,
    input:not(input[type="text"]):not(input[type="search"]):focus + label {      
      outline: solid ${themes.borderWidth.m} ${themes.colors.primary};
      outline-offset: ${themes.borderWidth.m};
    }
  }
`;

const ConditionalWrapper = ({ enabled, wrapper, children }) =>
  enabled ? wrapper(children) : children;

const BaseApp = ({ exclude = [], children, ...rest }) => {
  useOutlineEnhancer();
  const appStore = ReactOnRails.getStore("appStore");
  return (
    <ErrorBoundary>
      <Provider store={appStore}>
        <ConditionalWrapper
          wrapper={(child) => (
            <QueryClientProvider client={queryClient}>
              {child}
            </QueryClientProvider>
          )}
          enabled={!exclude.includes("QueryClient")}
        >
          <ConditionalWrapper
            wrapper={(c) => (
              <ToastProvider
                initial={{ success: rest.notice, danger: rest.alert }}
              >
                {c}
              </ToastProvider>
            )}
            enabled={!exclude.includes("ToastProvider")}
          >
            <ConditionalWrapper
              wrapper={(c) => <ThemeProvider theme={themes}>{c}</ThemeProvider>}
              enabled={!exclude.includes("ThemeProvider")}
            >
              <GlobalStyle />
              <ConditionalWrapper
                wrapper={(c) => <AskServiceProvider>{c}</AskServiceProvider>}
                enabled={!exclude.includes("AskService")}
              >
                <ConditionalWrapper
                  wrapper={(c) => <LoginProvider>{c}</LoginProvider>}
                  enabled={!exclude.includes("Login")}
                >
                  {children}
                </ConditionalWrapper>
              </ConditionalWrapper>
            </ConditionalWrapper>
          </ConditionalWrapper>
          {!exclude.includes("QueryClient") &&
            !exclude.includes("ReactQueryDevtools") && (
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-left"
              />
            )}
        </ConditionalWrapper>
      </Provider>
    </ErrorBoundary>
  );
};

export default BaseApp;
