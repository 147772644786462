import { useWindowSize } from ".";
import { BREAKPOINTS } from "../../utils";

export default function useBreakpoints() {
  const { width } = useWindowSize();

  const withBreakpoints = (val, width) => {
    if (typeof val === "object") {
      if (val.xl !== undefined && width >= BREAKPOINTS.xl) return val.xl;
      if (val.lg !== undefined && width >= BREAKPOINTS.lg) return val.lg;
      if (val.md !== undefined && width >= BREAKPOINTS.md) return val.md;
      if (val.sm !== undefined && width >= BREAKPOINTS.sm) return val.sm;
      return val.xs;
    }
    return val;
  };

  return { get: (val) => withBreakpoints(val, width) };
}
