import { Field, useField } from "formik";
import { useCallback } from "react";
import { spacings } from "../../../../assets/themes";
import { Block, Stepper } from "../../../../components";
import { STEPPER } from "../../../../components/Styles/variants";

const FormLargeStepper = ({
  name,
  min,
  max,
  hint,
  suffix,
  prefix,
  step,
  children,
  FieldChildren,
  onChange,
  options,
  disabled,
  size = STEPPER.SIZE.LARGE,
}) => {
  const [field] = useField(name);

  const handleChange = (v, form) => {
    if (onChange) onChange(v, form);
    if (options) form.setFieldValue(field.name, options[v]?.value);
    else form.setFieldValue(field.name, v);
    form.setFieldTouched(field.name, true);
  };

  const getValue = useCallback(() => {
    if (options) return options.findIndex((opt) => opt.value === field.value);
    return field.value;
  }, [field.value]);

  const renderChild = () => {
    if (options) return options[getValue()]?.label;
    return typeof FieldChildren === "function"
      ? FieldChildren(field)
      : FieldChildren;
  };

  return (
    <Block
      display="flex"
      justifyContent="center"
      marginY={size === STEPPER.SIZE.LARGE ? spacings.l : null}
    >
      <Field name={name}>
        {({ form }) => (
          <>
            <Stepper
              value={getValue()}
              onChange={(v) => handleChange(v, form)}
              max={options?.length - 1 || max}
              min={min}
              hint={hint}
              size={size}
              suffix={suffix}
              prefix={prefix}
              step={step}
              disabled={disabled}
            >
              {renderChild()}
            </Stepper>
            {children}
          </>
        )}
      </Field>
    </Block>
  );
};

export default FormLargeStepper;
