import { Field, useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow, Input } from '../../components';

const SetJobberFirstName = ({ goNext, goBack }) => {
  const { isValid, setFieldValue } = useFormikContext();
  const handleNext = () => {
    setFieldValue('jobber_id', null);
    goNext();
  };
  return (
    <Container.Small>
      <Flow.Item.Title>Quel est le prénom de votre employé de maison ?</Flow.Item.Title>
      <Field name="external_jobber[first_name]">
        {({ field }) => (
          <Input
            autoFocus
            placeholder="Saisissez son prénom..."
            {...field}
            hint="Votre employé de maison sera invité à rejoindre Yoojo"
          />
        )}
      </Field>
      <Flow.Item.Navigation isValid={isValid} goNext={handleNext} goBack={goBack} />
    </Container.Small>
  );
};

export default SetJobberFirstName;
