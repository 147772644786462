import { Body16, Button, Modal } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import Countries from "../../../components/phoneInput/Countries";
import polyglot from "../../../utils/polyglot";

const WrongCountryModal = ({
  originCountryCode,
  domainCountryCode,
  userEmail,
  isOpen,
  onClose,
  onSignUp,
  onRedirect,
}) => {
  const getCountry = (code) => {
    // use phone country dictionnary
    const country = Countries.find((c) => c.code.toLowerCase() === code);
    return {
      ...country,
      name: polyglot.t(`country.${country.code.toLowerCase()}`),
    };
  };

  return (
    <Modal.XSmall isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>
          {polyglot.t("wrong_country.welcome_on_country_name", {
            country_name: getCountry(domainCountryCode).name,
          })}
        </Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Body16>
          {polyglot.t("wrong_country.description_1")}
          <strong>{userEmail}</strong>
          {polyglot.t("wrong_country.description_2", {
            origin_country_code: originCountryCode,
            domain_country: getCountry(domainCountryCode).name,
            domain_country_code: domainCountryCode,
          })}
        </Body16>
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Medium onClick={onRedirect} block>
          {polyglot.t("wrong_country.continue_on_country", {
            country_code: originCountryCode,
          })}{" "}
          {getCountry(originCountryCode).emoji}
        </Button.Medium>
        <Button.Medium block kind={BUTTON.KIND.SECONDARY} onClick={onSignUp}>
          {polyglot.t("wrong_country.create_account_on_country", {
            country_code: domainCountryCode,
          })}{" "}
          {getCountry(domainCountryCode).emoji}
        </Button.Medium>
      </Modal.Item.Footer>
    </Modal.XSmall>
  );
};
export default WrongCountryModal;
