const browserInfo = {
  JavaEnabled: navigator.javaEnabled(),
  Language: navigator.language || navigator.userLanguage,
  ColorDepth: window.screen.colorDepth,
  ScreenHeight: window.screen.height,
  ScreenWidth: window.screen.width,
  TimeZoneOffset: new Date().getTimezoneOffset().toString(),
  UserAgent: navigator.userAgent,
};

export { browserInfo };
