import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import CloseModal from './CloseModal';

const BottomModal = React.forwardRef(
  ({ isOpen, render, hasCloseButton, onClose, shouldCloseOnOverlayClick }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(isOpen);
    useEffect(() => {
      setModalIsOpen(isOpen);
    }, [isOpen]);
    const handleClose = () => {
      onClose();
      setModalIsOpen(false);
    };
    return (
      <Modal
        ref={ref}
        isOpen={modalIsOpen}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        onRequestClose={handleClose}
        ariaHideApp={false}
        overlayClassName="react-modal--bottom">
        {hasCloseButton && <CloseModal handleClose={handleClose} />}
        {render()}
      </Modal>
    );
  },
);

export default BottomModal;
