import { FastField, Form, Formik } from "formik";
import moment from "moment";
import React from "react";
import { Button, DatePicker, FormControl, Modal } from "../../../components";
import { isSame } from "../../../utils";
import polyglot from "@/utils/polyglot";

const FirstPaymentDateModal = ({
  isOpen,
  onClose,
  onChange,
  frequency,
  weekly_payment_day,
  monthly_payment_day,
  first_payment_date,
  selectedJobber,
}) => {
  const tomorrow = moment().add(1, "days");

  const getDisabledDate = (current) => {
    if (frequency === "monthly") {
      return !isSame(
        current,
        moment(current).date(monthly_payment_day).toDate()
      );
    }
    if (frequency === "weekly") {
      return [1, 2, 3, 4, 5, 6, 7].filter(
        (d) =>
          d !==
          moment(current)
            .locale("en")
            .isoWeekday(weekly_payment_day)
            .isoWeekday()
      );
    }
    return false;
  };

  const getDisabledWeekDays = () => {
    if (frequency === "weekly") {
      return [1, 2, 3, 4, 5, 6, 7].filter(
        (d) =>
          d !==
          moment().locale("en").isoWeekday(weekly_payment_day).isoWeekday()
      );
    }
    return null;
  };
  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} style={{ minHeight: 700 }}>
      <Modal.Item.Header
        isOpen={isOpen}
        onClose={onClose}
        title={`Quand voulez-vous commencer à payer ${selectedJobber?.first_name} ?`}
      />
      <Modal.Item.Wrapper>
        <Formik onSubmit={onChange} initialValues={{ first_payment_date }}>
          {({ values }) => (
            <Form className="flex-grow-1 d-flex flex-column">
              <FormControl
                label={polyglot.t("yoojo_direct.please_select_available_date")}
              >
                <FastField>
                  {({ form }) => (
                    <DatePicker.Calendar
                      name="first_payment_date"
                      format="YYYY-MM-DD"
                      disabledWeekDays={getDisabledWeekDays()}
                      disabledDate={getDisabledDate}
                      minDate={tomorrow}
                      onChange={(date) =>
                        form.setFieldValue("first_payment_date", date)
                      }
                      value={values.first_payment_date}
                    />
                  )}
                </FastField>
              </FormControl>
              <Button.Large
                type="submit"
                block
                disabled={!values.first_payment_date}
                className="mt-auto"
              >
                {polyglot.t("common.save")}
              </Button.Large>
              <Button.Large
                block
                kind="minimal"
                className="mt-2"
                onClick={onClose}
              >
                {polyglot.t("common.cancel")}
              </Button.Large>
            </Form>
          )}
        </Formik>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default FirstPaymentDateModal;
