import { colors, radius, spacings } from "../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Icon,
  Link,
  List,
  Modal,
  Shape,
} from "../../components";
import { CardBase } from "../../components/Styles/Base";
import { LIST_HEADER, SHAPE } from "../../components/Styles/variants";
import { useSupportPhoneNumber } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import { SUPPORT_LINES, formatPhone } from "../../utils";
import polyglot from "../../utils/polyglot";

const HelpBoxContent = ({ title }) => {
  const { data, public_pages } = useJob();
  const { phone } = useSupportPhoneNumber(SUPPORT_LINES.CANCELLATION);
  return (
    <>
      {title && <List.Header withGutters>{title}</List.Header>}
      <List.Header
        withGutters
        kind={LIST_HEADER.KIND.SUBTITLE}
        RightComponent={() => (
          <Link href={public_pages.faq} target="_blank">
            {polyglot.t("common.see_more")}
          </Link>
        )}
      >
        {polyglot.t("common.frequent_questions")}
      </List.Header>
      <List.Group>
        {data.zendesk_articles?.map((article, i) => (
          <List.Item
            key={`referral-zendesk-article-${i}`}
            chevron
            withGutters
            href={article.url}
            target="_blank"
          >
            {article.title}
          </List.Item>
        ))}
      </List.Group>
      <List.Header withGutters kind={LIST_HEADER.KIND.SUBTITLE}>
        {polyglot.t("common.contact_us_alt")}
      </List.Header>
      <List.Group>
        <List.Item
          withGutters
          href={`${public_pages.contact}?from_job=${data.id}`}
          LeftComponent={() => (
            <Shape.Small
              backgroundColor={colors.primaryLight}
              color={colors.primary}
              shape={SHAPE.SHAPE.CIRCLE}
            >
              <Icon.Medium name="envelope" />
            </Shape.Small>
          )}
        >
          <Body16 strong color={colors.primary}>
            {polyglot.t("tickets.new")}
          </Body16>
        </List.Item>
        {data.show_phone_sav && (
          <List.Item
            withGutters
            href={`tel:${phone}`}
            LeftComponent={() => (
              <Shape.Small
                backgroundColor={colors.primaryLight}
                color={colors.primary}
                shape={SHAPE.SHAPE.CIRCLE}
              >
                <Icon.Medium name="phone" />
              </Shape.Small>
            )}
          >
            <Body16 color={colors.muted}>
              {polyglot.t("tickets.call_support")}
            </Body16>
            <Body16 strong color={colors.primary}>
              {formatPhone(phone)}
            </Body16>
            <Body14 color={colors.muted}>
              {polyglot.t("common.phone_assistance_schedule")}
            </Body14>
          </List.Item>
        )}
      </List.Group>
    </>
  );
};

const JobHelpBox = () => (
  <>
    <CardBase flat>
      <HelpBoxContent title={polyglot.t("common.need_help")} />
    </CardBase>
  </>
);

const HelpModal = ({ isOpen, onClose }) => (
  <Modal.FullScreen onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Title>{polyglot.t("common.need_help")}</Modal.Item.Title>
    </Modal.Item.Header>
    <HelpBoxContent />
  </Modal.FullScreen>
);

JobHelpBox.Modal = HelpModal;
export default JobHelpBox;
