import React from "react";
import { ReadMore } from "../components";
import ErrorBoundary from "../utils/ErrorBoundary";

const ReadMoreApp = (props, rails) => () =>
  (
    <ErrorBoundary>
      <ReadMore {...props}>{props.text}</ReadMore>
    </ErrorBoundary>
  );

export default ReadMoreApp;
