import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { colors, radius, spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Button,
  Cropper,
  Modal,
  Spinner,
} from "../../../components";
import { ALERT, BUTTON } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";

const AvatarCropModal = ({ isOpen, onClose, isLoading, progress, error }) => {
  const { submitForm, setFieldValue, values } = useFormikContext();
  const [src, setSrc] = useState(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (values.avatar && isReady) {
      const reader = new FileReader();
      reader.readAsDataURL(values.avatar);
      reader.onload = function () {
        setSrc(reader.result);
      };
    } else {
      setSrc(null);
    }
  }, [isReady, values.avatar]);

  const handleCrop = (e) => {
    setFieldValue("crop", e.detail);
  };

  const handleAnimationComplete = () => {
    setIsReady(true);
  };

  const srcIsLoading = values.avatar && isReady;

  return (
    <Modal.Small
      isOpen={isOpen}
      onClose={onClose}
      onAnimationComplete={handleAnimationComplete}
    >
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title align="center">
          {polyglot.t("account.crop_photo")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Block
          position="relative"
          borderRadius={radius.m}
          overflow="hidden"
          display="flex"
          alignItems="center"
          justifyContent="center"
          minHeight={400}
          width="100%"
          background={!srcIsLoading && colors.body}
        >
          {srcIsLoading ? (
            <Cropper onChange={handleCrop} value={src} />
          ) : (
            <Spinner.Medium color={colors.border} />
          )}
        </Block>
        {error && (
          <Block marginTop={spacings.m}>
            <Alert.Low kind={ALERT.KIND.DANGER}>
              {polyglot.t("common.errors.avatar_upload")}
            </Alert.Low>
          </Block>
        )}
        <Block marginTop={spacings.m}>
          <Button.Large block disabled={isLoading} onClick={submitForm}>
            {!isLoading
              ? polyglot.t("common.save")
              : `${polyglot.t("common.saving")}... (${progress || 0} %)`}
          </Button.Large>
          <Button.Large
            css={`
              margin-top: ${spacings.s};
            `}
            block
            kind={BUTTON.KIND.MINIMAL}
            onClick={onClose}
          >
            {polyglot.t("common.cancel")}
          </Button.Large>
        </Block>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default AvatarCropModal;
