import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Skeleton, Block, Modal } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const TransactionDetailsModalSkeleton = () => (
  <Modal.Item.Wrapper>
    <Skeleton.Group>
      <Block display="flex" alignItems="center" flexDirection="column">
        <Block
          paddingBottom={spacings.l}
          width="100%"
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <Skeleton
            sb
            shape={SKELETON.SHAPE.CIRCLE}
            height={sizes.size96}
            width={sizes.size96}
          />
          <Skeleton height={sizes.size20} width="40%" sb />
          <Skeleton height={sizes.size48} width="50%" sb />
        </Block>
        <Block width="100%">
          <Skeleton height={sizes.size16} width="50%" sb />
          <Skeleton height={sizes.size48} width="100%" rows={7} />
        </Block>
      </Block>
    </Skeleton.Group>
  </Modal.Item.Wrapper>
);

export default TransactionDetailsModalSkeleton;
