/* eslint-disable no-console */
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import HistoryScrollToTop from "../HistoryScrollToTop";
import Progress from "./Item/Progress";

const Router = React.forwardRef(
  ({ routes, initialStep = 0, renderProgress, onChange, children }, ref) => {
    const [currIndex, setCurrIndex] = useState(initialStep);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if (onChange) {
        onChange(currIndex);
      }
    }, [currIndex]);

    useEffect(() => {
      const historyListener = history.listen(({ pathname }) => {
        const index = routes
          .map((e) => e.path)
          .indexOf(pathname.substring(pathname.lastIndexOf("/") + 1));
        setCurrIndex(index);
      });
      return () => {
        historyListener();
      };
    }, [routes]);

    const go = (index, params = {}) => {
      history.go(index, params);
    };

    const goByPath = (path, params = {}) => {
      const routeIndex = routes.findIndex((route) => route.path === path);
      if (routes[routeIndex]?.path) {
        history.push(routes[routeIndex].path, params);
      } else {
        console.error(`Route with index ${routeIndex} does not exist`);
      }
    };

    const goTo = (index, params = {}) => {
      if (routes[index]?.path) {
        history.push(routes[index].path, params);
      } else {
        console.error(`Route with index ${index} does not exist`);
      }
    };

    const goNext = () => {
      if (routes[currIndex + 1]?.path) {
        history.push(routes[currIndex + 1].path);
      } else {
        console.error(`Route with index ${currIndex + 1} does not exist`);
      }
    };

    const goBack = () => {
      console.log("goBack");
      if (initialStep === 0) {
        history.go(-1);
      } else if (routes[currIndex - 1]?.path) {
        console.log("currIndex :>> ", currIndex);
        console.log("routes :>> ", routes);
        console.log("newIndex :>> ", routes[currIndex - 1].path);
        history.push(routes[currIndex - 1].path);
      } else {
        console.error(`Route with index ${currIndex - 1} does not exist`);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        goBack,
        goNext,
        goTo,
        goByPath,
        go,
      }),
      [currIndex]
    );

    useEffect(() => {
      if (currIndex > 0) {
        const currentPath = location.pathname?.replace("/", "");
        const index = routes.findIndex((route) => route.path === currentPath);
        if (index !== -1) {
          setCurrIndex(index);
        }
      }
    }, [routes]);

    return (
      <div>
        {!renderProgress && (
          <Progress
            onClick={goTo}
            activeIndex={currIndex}
            pages={routes.map((step) => ({ title: step.title }))}
          />
        )}
        <div>
          {renderProgress &&
            renderProgress({ activeIndex: currIndex, goTo, routes })}
          <HistoryScrollToTop />
          <AnimatePresence exitBeforeEnter initial={false}>
            <Switch
              location={location}
              key={location.pathname}
              action={history.action}
            >
              <Redirect
                from="/"
                exact
                to={{
                  pathname: `/${
                    currIndex >= 0 ? routes[currIndex]?.path : routes[0]?.path
                  }`,
                  state: history.location?.state,
                }}
              />
              {routes.map((step, index) => (
                <Route
                  id={`route-${index}`}
                  key={`route-${index}`}
                  path={`/${step.path}`}
                >
                  <motion.div
                    initial={{
                      x: history?.action === "POP" ? -100 : 100,
                      opacity: 0,
                    }}
                    transition={{ duration: 0.2, ease: [1, 0, 0, 1] }}
                    animate={{
                      x: 0,
                      opacity: 1,
                    }}
                  >
                    <step.View
                      title={step.title}
                      goNext={goNext}
                      goBack={goBack}
                      go={go}
                      goTo={goTo}
                      goByPath={goByPath}
                      initialStep={initialStep}
                      index={index}
                      {...step.customProps}
                    />
                  </motion.div>
                </Route>
              ))}
            </Switch>
          </AnimatePresence>
        </div>
        {typeof children === "function"
          ? children({
              goTo,
              goNext,
              goBack,
              goByPath,
              current: routes[currIndex],
              active: currIndex,
            })
          : children}
      </div>
    );
  }
);

export default Router;
