import BaseApp from "../modules/BaseApp";
import MovingVolumeCalculator from "../pages/MovingVolumeCalculator";

const MovingVolumeCalculatorApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <MovingVolumeCalculator {...props} />
    </BaseApp>
  );

export default MovingVolumeCalculatorApp;
