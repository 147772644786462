import { useState, useEffect } from "react";
import moment from "moment";

const useElapsedTime = (startTime) => {
  const [elapsedTime, setElapsedTime] = useState("00:00");

  useEffect(() => {
    if (!startTime) {
      setElapsedTime("00:00");
      return;
    }

    const updateElapsedTime = () => {
      const now = moment();
      const duration = moment.duration(now.diff(startTime));
      const minutes = String(Math.floor(duration.asMinutes())).padStart(2, "0");
      const seconds = String(duration.seconds()).padStart(2, "0");
      setElapsedTime(`${minutes}:${seconds}`);
    };

    updateElapsedTime();
    const intervalId = setInterval(updateElapsedTime, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [startTime]);

  return elapsedTime;
};

export default useElapsedTime;
