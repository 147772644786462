import { useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow } from '../../components';
import Plan from './plans/Plan';

const Plans = ({ goNext, title, goBack }) => {
  const { setFieldValue } = useFormikContext();
  const handleClick = value => {
    setFieldValue('plan', value);
    goNext();
  };
  return (
    <Container.Medium>
      <Flow.Item.Title>{title}</Flow.Item.Title>
      <div className="row">
        <div className="col-md-6">
          <Plan kind="direct_basic" onClick={handleClick} />
        </div>
        <div className="col-md-6">
          <Plan kind="direct_plus" onClick={handleClick} />
        </div>
      </div>
      <Flow.Item.Navigation goBack={goBack} />
    </Container.Medium>
  );
};

export default Plans;
