import { AMEX, MAESTRO, VISA_MASTERCARD } from "../constants";

export function getCreditCardType(value) {
  const cc = value?.replace(/\s/g, "");
  const amex = new RegExp("(?:3[47][0-9]{13})");
  const visa = new RegExp("(?:4[0-9]{12})(?:[0-9]{3})?");
  const mastercard = new RegExp(
    "(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})"
  );
  const maestro = new RegExp(
    "(?:(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{8,15})"
  );

  if (visa.test(cc)) {
    return VISA_MASTERCARD;
  }
  if (amex.test(cc)) {
    return AMEX;
  }
  if (mastercard.test(cc)) {
    return VISA_MASTERCARD;
  }
  if (maestro.test(cc)) {
    return MAESTRO;
  }
  return "";
}
