import { colors } from "../../../assets/themes";
import { Body16, Button, List, Modal, NoResult } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { JOB } from "../../../pages-admin/UserPreview/config";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import { formatDate } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const ReferredUserHistoryModal = ({ onClose, isOpen, referral_rewards }) => (
  <Modal.XSmall onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>{polyglot.t("common.history")}</Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      {referral_rewards?.length > 0 ? (
        <List.Group>
          {referral_rewards?.map((reward, i) => (
            <UserPreview
              id={reward.job_id}
              kind={JOB}
              key={`user-referral-history-${i}`}
            >
              <List.Item
                RightComponent={() => (
                  <Body16 strong color={colors.success}>
                    +{polyglot.toSmartCurrency(reward.earned_amount)}
                  </Body16>
                )}
              >
                {formatDate(reward.date, "DD/MM/YYYY")}
              </List.Item>
            </UserPreview>
          ))}
        </List.Group>
      ) : (
        <NoResult
          title={polyglot.t("referral.empty_referee_history")}
          subtitle={polyglot.t("referral.empty_referee_history_description")}
        />
      )}
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large kind={BUTTON.KIND.SECONDARY} block onClick={onClose}>
        {polyglot.t("common.close")}
      </Button.Large>
    </Modal.Item.Footer>
  </Modal.XSmall>
);

export default ReferredUserHistoryModal;
