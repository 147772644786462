import React from "react";
import { useSelector } from "react-redux";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Body14,
  Body18,
  List,
  StatusEnhancer,
  Link,
  Tag,
  Block,
  Icon,
} from "../../components";
import { TAG } from "../../components/Styles/variants";
import QrcodeGenerator from "../../pages/QrcodeGenerator";
import polyglot from "../../utils/polyglot";

const COMPANY = "company";
const CONCIERGE = "concierge";

const PreviewElemCustomerHead = ({
  avatar,
  url,
  last_name,
  first_name,
  paid_jobs_count,
  is_vip,
  token,
  kind,
  ...rest
}) => {
  const { current_admin } = useSelector((state) => state.rails);
  return (
    <List.Item
      divider={false}
      withGutters
      RightComponent={() => (
        <QrcodeGenerator
          size={64}
          user_token={token}
          admin_id={current_admin.id}
        />
      )}
      LeftComponent={() => (
        <Link href={url} target="_blank">
          <Avatar src={avatar} size={sizes.size48} name={first_name} />
        </Link>
      )}
      {...rest}
    >
      <StatusEnhancer isVip={is_vip}>
        <Link href={url} color={colors.body} target="_blank">
          <Body18 strong numberOfLines={1}>
            {first_name} {last_name}
          </Body18>
        </Link>
      </StatusEnhancer>
      <Body14 color={colors.muted}>
        {polyglot.t("admin.paid_jobs_plural", { count: paid_jobs_count })}
      </Body14>
      {kind === CONCIERGE && (
        <Block marginTop={spacings.xs}>
          <Tag.Small
            kind={TAG.KIND.WHITE}
            LeftComponent={() => <Icon.Small name="user-solid" />}
          >
            {polyglot.t(`common.concierge`)}
          </Tag.Small>
        </Block>
      )}
      {kind === COMPANY && (
        <Block marginTop={spacings.xs}>
          <Tag.Small
            kind={TAG.KIND.WHITE}
            LeftComponent={() => <Icon.Small name="building" />}
          >
            {polyglot.t(`common.company`)}
          </Tag.Small>
        </Block>
      )}
    </List.Item>
  );
};

export default PreviewElemCustomerHead;
