import styled from "styled-components";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body16, Button } from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import polyglot from "../../../utils/polyglot";

const StyledCardBase = styled(CardBase)`
  position: relative;
  background-color: ${colors.background};
  width: 100%;
`;

const CesuBtn = ({ TopComponent, children, title, onClick }) => (
  <StyledCardBase
    as="button"
    type="button"
    onClick={onClick}
    className="cesu-buttons-svg-hover"
  >
    <Block
      minHeight={{ xs: "inherit", md: "300px" }}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      padding={spacings.m}
    >
      <Block
        position="absolute"
        top={`calc(${spacings.m} * -1)`}
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {TopComponent && TopComponent()}
      </Block>
      {children}
      <Body16 strong>{title}</Body16>
      {/* FAKE BUTTON FOR ABSOLUTE GAP */}
      <Block visibility="hidden">
        <Block marginTop={spacings.s}>
          <Button.Medium> </Button.Medium>
        </Block>
      </Block>
      {/* END */}
      <Block
        position="absolute"
        bottom={spacings.m}
        left="0"
        right="0"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Block marginTop={spacings.s}>
          <Button.Medium>{polyglot.t("common.continue")}</Button.Medium>
        </Block>
      </Block>
    </Block>
  </StyledCardBase>
);
export default CesuBtn;
