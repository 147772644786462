import { colors, sizes } from "../../../assets/themes";
import { Avatar, Body16, List, Modal } from "../../../components";
import { JOBBER } from "../../../pages-admin/UserPreview/config";
import UserPreview from "../../../pages-admin/UserPreview";
import polyglot from "../../../utils/polyglot";
import { getCancelOfferUrl, getSelectedOffers } from "../../Job/utils";
import useJob from "../../../modules/hooks/useJob";

const MultipleCloseJobModal = ({ isOpen, onClose }) => {
  const { data } = useJob();
  return (
    <Modal.XSmall isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("jobs.cancel_job")}</Modal.Item.Title>
        <Modal.Item.Subtitle>
          {polyglot.t("jobs.cancel_jobbers_before_close_job")}
        </Modal.Item.Subtitle>
      </Modal.Item.Header>
      {getSelectedOffers(data.accepted_offers).map((offer) => (
        <UserPreview id={offer.jobber.id} kind={JOBBER}>
          <List.Item
            withGutters
            href={getCancelOfferUrl(offer.id)}
            LeftComponent={() => (
              <Avatar
                size={sizes.size36}
                src={offer.jobber.avatar}
                name={offer.jobber.first_name}
              />
            )}
          >
            <Body16 strong color={colors.danger}>
              {polyglot.t("jobs.change_or_cancel_first_name", {
                first_name: offer.jobber.first_name,
              })}
            </Body16>
          </List.Item>
        </UserPreview>
      ))}
    </Modal.XSmall>
  );
};
export default MultipleCloseJobModal;
