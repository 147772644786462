import React from "react";
import styled, { css } from "styled-components";
import { colors, sizes, spacings } from "../../assets/themes";
import { STEPS } from "../Styles/variants";
import { Body16 } from "../Text";
import Artwork from "./Artwork";

const getMinHeight = (SIZE) => {
  if (SIZE === STEPS.SIZE.COMPACT) {
    return sizes.size36;
  }

  return sizes.size48;
};

const getPadding = (SIZE) => {
  if (SIZE === STEPS.SIZE.COMPACT) {
    return spacings.s;
  }

  return spacings.m;
};

const setDirectionStyle = (orientation, size) => {
  if (orientation === STEPS.ORIENTATION.HORIZONTAL) {
    return css`
      align-items: center;
      flex-direction: column;
    `;
  }
  if (orientation === STEPS.ORIENTATION.VERTICAL) {
    return css`
      height: 100%;
      min-height: ${getMinHeight(size)};
      justify-content: center;
      flex-direction: row;
    `;
  }
  return null;
};

const StyledStepWrapper = styled.div`
  ${({ orientation, size }) => css`
    display: flex;
    width: 100%;
    ${setDirectionStyle(orientation, size)}
  `}
`;

const StyledChildrenWrapper = styled.div`
  ${({ orientation, size }) =>
    orientation === STEPS.ORIENTATION.VERTICAL &&
    css`
      padding-top: ${getPadding(size)};
      width: 100%;
      padding-bottom: ${getPadding(size)};
    `}
`;

const Step = ({
  title,
  RightComponent,
  LeftComponent,
  Icon,
  index,
  active,
  orientation,
  isLast,
  done,
  isFirst,
  children,
  dashed,
  render,
  renderLineColor,
  size,
  ...rest
}) => {
  const renderChildren = () => {
    if (orientation === STEPS.ORIENTATION.VERTICAL) return children;
    return null;
  };
  return (
    <StyledStepWrapper orientation={orientation} size={size} {...rest}>
      <Artwork
        orientation={orientation}
        active={active}
        isLast={isLast}
        done={done}
        dashed={dashed}
        renderLineColor={renderLineColor}
        isFirst={isFirst}
        index={index}
        render={render}
      >
        {index}
      </Artwork>
      <StyledChildrenWrapper orientation={orientation} size={size}>
        <Body16
          strong
          color={active ? colors.body : colors.mutedLight}
          align={orientation === STEPS.ORIENTATION.HORIZONTAL && "center"}
        >
          {title}
        </Body16>
        {renderChildren()}
      </StyledChildrenWrapper>
    </StyledStepWrapper>
  );
};

export default Step;
