import styled, { css } from "styled-components";
import { breakpointsEnhancer } from "./Styles/Helper";

/**
 * Styled component that provides a flexible container for UI design.
 *
 * @component
 * @example
 * <Block margin="10px" backgroundColor="lightblue">
 *   Your content here
 * </Block>
 *
 * @param {string} [margin] - Sets all margins. Accepts standard CSS margin values.
 * @param {string} [marginTop] - Sets top margin. Accepts standard CSS margin values.
 * @param {string} [marginRight] - Sets right margin. Accepts standard CSS margin values.
 * @param {string} [marginBottom] - Sets bottom margin. Accepts standard CSS margin values.
 * @param {string} [marginLeft] - Sets left margin. Accepts standard CSS margin values.
 * @param {string} [marginX] - Sets left and right margins. Accepts standard CSS margin values.
 * @param {string} [marginY] - Sets top and bottom margins. Accepts standard CSS margin values.
 * @param {string} [padding] - Sets all paddings. Accepts standard CSS padding values.
 * @param {string} [paddingTop] - Sets top padding. Accepts standard CSS padding values.
 * @param {string} [paddingRight] - Sets right padding. Accepts standard CSS padding values.
 * @param {string} [paddingBottom] - Sets bottom padding. Accepts standard CSS padding values.
 * @param {string} [paddingLeft] - Sets left padding. Accepts standard CSS padding values.
 * @param {string} [paddingX] - Sets left and right paddings. Accepts standard CSS padding values.
 * @param {string} [paddingY] - Sets top and bottom paddings. Accepts standard CSS padding values.
 * @param {string} [width] - Sets the width. Accepts standard CSS width values.
 * @param {string} [minWidth] - Sets the minimum width. Accepts standard CSS minWidth values.
 * @param {string} [maxWidth] - Sets the maximum width. Accepts standard CSS maxWidth values.
 * @param {string} [height] - Sets the height. Accepts standard CSS height values.
 * @param {string} [minHeight] - Sets the minimum height. Accepts standard CSS minHeight values.
 * @param {string} [maxHeight] - Sets the maximum height. Accepts standard CSS maxHeight values.
 * @param {string} [backgroundColor] - Sets the background color. Accepts standard CSS color values.
 * @param {string} [background] - Sets shorthand for background properties.
 * @param {string} [backgroundImage] - Sets the background image. Accepts URLs or gradient values.
 * @param {string} [backgroundSize] - Sets the size of the background image. Accepts standard CSS background-size values.
 * @param {string} [backgroundPosition] - Sets the starting position of a background image. Accepts standard CSS background-position values.
 * @param {string} [backgroundRepeat] - Sets how background images are repeated. Accepts standard CSS background-repeat values.
 * @param {string} [borderRadius] - Sets the border radius. Accepts standard CSS border-radius values.
 * @param {string} [boxShadow] - Sets the box shadow. Accepts standard CSS box-shadow values.
 * @param {string} [position] - Sets the CSS position property. Accepts standard CSS position values.
 * @param {string} [border] - Sets the border. Accepts standard CSS border values.
 * @param {string} [top] - Sets the top position. Accepts standard CSS top values.
 * @param {string} [right] - Sets the right position. Accepts standard CSS right values.
 * @param {string} [bottom] - Sets the bottom position. Accepts standard CSS bottom values.
 * @param {string} [left] - Sets the left position. Accepts standard CSS left values.
 * @param {string} [display] - Sets the display property. Accepts standard CSS display values.
 * @param {string} [alignItems] - Sets the align-items property for flexbox containers. Accepts standard CSS align-items values.
 * @param {string} [justifyContent] - Sets the justify-content property for flexbox containers. Accepts standard CSS justify-content values.
 * @param {string} [flexWrap] - Sets the flex-wrap property for flexbox containers. Accepts standard CSS flex-wrap values.
 * @param {string} [flexDirection] - Sets the flex-direction property for flexbox containers. Accepts standard CSS flex-direction values.
 * @param {string} [flex] - Sets the flex property. Accepts standard CSS flex values.
 * @param {string} [alignSelf] - Sets the align-self property for flex items. Accepts standard CSS align-self values.
 * @param {string} [overflow] - Sets how to handle content that is too large for its container. Accepts standard CSS overflow values.
 * @param {string} [overflowX] - Sets how to handle content that is too wide for its container. Accepts standard CSS overflow-x values.
 * @param {string} [overflowY] - Sets how to handle content that is too tall for its container. Accepts standard CSS overflow-y values.
 * @param {string} [zIndex] - Sets the stack order of an element. Accepts standard CSS z-index values.
 * @param {string} [color] - Sets the text color. Accepts standard CSS color values.
 * @param {string} [boxSizing] - Sets the box-sizing property. Affects how the total width and height of an element is calculated. Accepts 'border-box' or 'content-box'.
 * @param {string} [pointerEvents] - Specifies under what circumstances (if any) a particular graphic element can become the target of pointer events. Accepts standard CSS pointer-events values.
 * @param {string} [visibility] - Sets the visibility property. Accepts 'visible', 'hidden', or 'collapse'.
 * @param {string} [transition] - Sets the transition property for smooth transitions between states. Accepts standard CSS transition values.
 * @param {string} [order] - Sets the order in which a flex item appears in a flex container. Accepts a number as value.
 * @param {string} [gap] - Sets the gap property for flexbox and grid layouts. Accepts standard CSS gap values.
 * @param {string} [gridCols]
 * @param {string} [gridRows]
 * @param {string} [spaceX]
 * @param {string} [spaceY]
 * @param {string} [backdropBlur]
 * @param {string} [backdropBrightness]
 * @param {string} [backdropContrast]
 * @param {string} [backdropGrayscale]
 * @param {string} [backdropHueRotate]
 * @param {string} [backdropInvert]
 * @param {string} [backdropOpacity]
 * @param {string} [backdropSaturate]
 * @param {string} [backdropSepia]
 * @param {string} [translateX]
 * @param {string} [translateY]
 * @param {string} [rotate]
 * @param {string} [skewX]
 * @param {string} [skewY]
 * @param {string} [scaleX]
 * @param {string} [scaleY]
 */

const Block = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![
      "margin",
      "marginTop",
      "marginRight",
      "marginBottom",
      "marginLeft",
      "marginX",
      "marginY",
      "padding",
      "paddingTop",
      "paddingRight",
      "paddingBottom",
      "paddingLeft",
      "paddingX",
      "paddingY",
      "width",
      "minWidth",
      "maxWidth",
      "height",
      "minHeight",
      "maxHeight",
      "backgroundColor",
      "background",
      "backgroundImage",
      "backgroundSize",
      "backgroundPosition",
      "backgroundRepeat",
      "borderRadius",
      "boxShadow",
      "position",
      "border",
      "borderRight",
      "borderLeft",
      "borderTop",
      "borderBottom",
      "borderTopLeftRadius",
      "borderTopRightRadius",
      "borderBottomRightRadius",
      "borderBottomLeftRadius",
      "top",
      "right",
      "bottom",
      "left",
      "display",
      "alignItems",
      "justifyContent",
      "flexWrap",
      "flexDirection",
      "flex",
      "alignSelf",
      "overflow",
      "overflowX",
      "overflowY",
      "zIndex",
      "order",
      "color",
      "boxSizing",
      "visibility",
      "transition",
      "gap",
      "gridCols",
      "gridRows",
      "spaceX",
      "spaceY",
      "backdropBlur",
      "backdropBrightness",
      "backdropContrast",
      "backdropGrayscale",
      "backdropHueRotate",
      "backdropInvert",
      "backdropOpacity",
      "backdropSaturate",
      "backdropSepia",
      "translateX",
      "translateY",
      "rotate",
      "skewX",
      "skewY",
      "scaleX",
      "scaleY",
    ].includes(prop) && defaultValidatorFn(prop),
})`
  ${({
    margin,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
    marginX,
    marginY,
    padding,
    paddingTop,
    paddingRight,
    paddingBottom,
    paddingLeft,
    paddingX,
    paddingY,
    width,
    minWidth,
    maxWidth,
    height,
    minHeight,
    maxHeight,
    backgroundColor,
    background,
    backgroundImage,
    backgroundPosition,
    backgroundSize,
    borderRadius,
    boxShadow,
    position,
    border,
    borderRight,
    borderLeft,
    borderTop,
    borderBottom,
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
    top,
    right,
    bottom,
    left,
    display,
    alignItems,
    justifyContent,
    flexWrap,
    flexDirection,
    flex,
    alignSelf,
    overflow,
    overflowX,
    overflowY,
    zIndex,
    color,
    boxSizing,
    pointerEvents,
    backgroundRepeat,
    visibility,
    transition,
    order,
    gap,
    gridCols,
    gridRows,
    spaceX,
    spaceY,
    backdropBlur,
    backdropContrast,
    backdropGrayscale,
    backdropHueRotate,
    backdropInvert,
    backdropOpacity,
    backdropSaturate,
    backdropSepia,
    translateX,
    translateY,
    rotate,
    skewX,
    skewY,
    scaleX,
    scaleY,
  }) => css`
    ${breakpointsEnhancer("margin", margin)};
    ${breakpointsEnhancer("margin-top", marginTop)};
    ${breakpointsEnhancer("margin-right", marginRight)};
    ${breakpointsEnhancer("margin-bottom", marginBottom)};
    ${breakpointsEnhancer("margin-left", marginLeft)};
    ${breakpointsEnhancer("padding", padding)};
    ${breakpointsEnhancer("padding-top", paddingTop)};
    ${breakpointsEnhancer("padding-right", paddingRight)};
    ${breakpointsEnhancer("padding-bottom", paddingBottom)};
    ${breakpointsEnhancer("padding-left", paddingLeft)};
    ${breakpointsEnhancer("width", width)};
    ${breakpointsEnhancer("min-width", minWidth)};
    ${breakpointsEnhancer("max-width", maxWidth)};
    ${breakpointsEnhancer("height", height)};
    ${breakpointsEnhancer("min-height", minHeight)};
    ${breakpointsEnhancer("max-height", maxHeight)};
    ${breakpointsEnhancer("background-color", backgroundColor)};
    ${breakpointsEnhancer("background-image", backgroundImage)};
    ${breakpointsEnhancer("background-size", backgroundSize)};
    ${breakpointsEnhancer("background-position", backgroundPosition)};
    ${breakpointsEnhancer("background-repeat", backgroundRepeat)};
    ${breakpointsEnhancer("box-sizing", boxSizing)};
    ${breakpointsEnhancer("background", background)};
    ${breakpointsEnhancer("border-radius", borderRadius)};
    ${breakpointsEnhancer("box-shadow", boxShadow)};
    ${breakpointsEnhancer("position", position)};
    ${breakpointsEnhancer("border", border)};
    ${breakpointsEnhancer("border-right", borderRight)};
    ${breakpointsEnhancer("border-left", borderLeft)};
    ${breakpointsEnhancer("border-top", borderTop)};
    ${breakpointsEnhancer("border-bottom", borderBottom)};
    ${breakpointsEnhancer("border-top-left-radius", borderTopLeftRadius)};
    ${breakpointsEnhancer("border-top-right-radius", borderTopRightRadius)};
    ${breakpointsEnhancer(
      "border-bottom-right-radius",
      borderBottomRightRadius
    )};
    ${breakpointsEnhancer("border-bottom-left-radius", borderBottomLeftRadius)};
    ${breakpointsEnhancer("top", top)};
    ${breakpointsEnhancer("right", right)};
    ${breakpointsEnhancer("bottom", bottom)};
    ${breakpointsEnhancer("left", left)};
    ${breakpointsEnhancer("display", display)};
    ${breakpointsEnhancer("align-items", alignItems)};
    ${breakpointsEnhancer("justify-content", justifyContent)};
    ${breakpointsEnhancer("flex-wrap", flexWrap)};
    ${breakpointsEnhancer("flex", flex)};
    ${breakpointsEnhancer("align-self", alignSelf)};
    ${breakpointsEnhancer("overflow", overflow)};
    ${breakpointsEnhancer("overflow-x", overflowX)};
    ${breakpointsEnhancer("overflow-y", overflowY)};
    ${breakpointsEnhancer("z-index", zIndex)};
    ${breakpointsEnhancer("order", order)};
    ${breakpointsEnhancer("color", color)};
    ${breakpointsEnhancer("flex-direction", flexDirection)};
    ${breakpointsEnhancer("margin-left", marginX)};
    ${breakpointsEnhancer("margin-right", marginX)};
    ${breakpointsEnhancer("padding-left", paddingX)};
    ${breakpointsEnhancer("padding-right", paddingX)};
    ${breakpointsEnhancer("margin-top", marginY)};
    ${breakpointsEnhancer("margin-bottom", marginY)};
    ${breakpointsEnhancer("padding-top", paddingY)};
    ${breakpointsEnhancer("padding-bottom", paddingY)};
    ${breakpointsEnhancer("pointer-events", pointerEvents)};
    ${breakpointsEnhancer("visibility", visibility)};
    ${breakpointsEnhancer("transition", transition)};
    ${breakpointsEnhancer("backdrop-filter", backdropBlur, "blur(%value)")};
    ${breakpointsEnhancer(
      "backdrop-filter",
      backdropContrast,
      "contrast(%value)"
    )};
    ${breakpointsEnhancer(
      "backdrop-filter",
      backdropGrayscale,
      "grayscale(%value)"
    )};
    ${breakpointsEnhancer(
      "backdrop-filter",
      backdropHueRotate,
      "hue-rotate(%value)"
    )};
    ${breakpointsEnhancer("backdrop-filter", backdropInvert, "invert(%value)")};
    ${breakpointsEnhancer(
      "backdrop-filter",
      backdropOpacity,
      "opacity(%value)"
    )};
    ${breakpointsEnhancer(
      "backdrop-filter",
      backdropSaturate,
      "saturate(%value)"
    )};
    ${breakpointsEnhancer("backdrop-filter", backdropSepia, "sepia(%value)")};
    ${breakpointsEnhancer("gap", gap)};
    ${gridCols &&
    breakpointsEnhancer(
      "grid-template-columns",
      gridCols,
      typeof gridCols === "number" ||
        (typeof gridCols === "object" &&
          Object.values(gridCols).every((val) => typeof val === "number"))
        ? `repeat(%value, minmax(0, 1fr))`
        : "%value"
    )};
    ${gridRows &&
    breakpointsEnhancer(
      "grid-template-rows",
      gridRows,
      typeof gridRows === "number" ||
        (typeof gridRows === "object" &&
          Object.values(gridRows).every((val) => typeof val === "number"))
        ? `repeat(%value, minmax(0, 1fr))`
        : "%value"
    )};
    ${spaceX &&
    css`
      & > *:not(:first-child):not([style*="display: none"]) {
        ${breakpointsEnhancer("margin-left", spaceX)};
      }
    `}

    ${spaceY &&
    css`
      & > *:not(:first-child):not([style*="display: none"]) {
        ${breakpointsEnhancer("margin-top", spaceY)};
      }
    `}

    ${breakpointsEnhancer("--translate-x", translateX)};
    ${breakpointsEnhancer("--translate-y", translateY)};
    ${breakpointsEnhancer("--rotate", rotate)};
    ${breakpointsEnhancer("--skew-x", skewX)};
    ${breakpointsEnhancer("--skew-y", skewY)};
    ${breakpointsEnhancer("--scale-x", scaleX)};
    ${breakpointsEnhancer("--scale-y", scaleY)};
    ${(translateX ||
      translateY ||
      rotate ||
      skewX ||
      skewY ||
      scaleX ||
      scaleY) &&
    css`
      transform: translate(var(--translate-x, 0), var(--translate-y, 0))
        rotate(var(--rotate, 0)) skewX(var(--skew-x, 0)) skewY(var(--skew-y, 0))
        scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    `}
  `};
`;

export default Block;
