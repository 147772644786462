import styled, { css } from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "../assets/themes";
import Block from "./Block";
import { TAG } from "./Styles/variants";
import { Body14, Body16 } from "./Text";

const setSize = ({ size }) => {
  if (size === TAG.SIZE.MEDIUM) {
    return css`
      height: ${sizes.size32};
      padding: 0 ${spacings.sm};
    `;
  }
  if (size === TAG.SIZE.SMALL) {
    return css`
      height: ${sizes.size24};
      padding: 0 ${spacings.s};
    `;
  }
  return null;
};

const setKind = ({ kind }) => {
  if (kind === TAG.KIND.PRIMARY) {
    return css`
      background-color: ${colors.primary};
      color: ${colors.onColor};
    `;
  }
  if (kind === TAG.KIND.PINK) {
    return css`
      background-color: ${colors.pink500};
      color: ${colors.onColor};
    `;
  }
  if (kind === TAG.KIND.DANGER) {
    return css`
      background-color: ${colors.danger};
      color: ${colors.onColor};
    `;
  }
  if (kind === TAG.KIND.MUTED) {
    return css`
      background-color: ${colors.backgroundGray};
      color: ${colors.body};
    `;
  }
  if (kind === TAG.KIND.SUCCESS) {
    return css`
      background-color: ${colors.success};
      color: ${colors.onColor};
    `;
  }
  if (kind === TAG.KIND.WARNING) {
    return css`
      background-color: ${colors.warning};
      color: ${colors.warning900};
    `;
  }
  if (kind === TAG.KIND.WHITE) {
    return css`
      background-color: ${colors.background};
      color: ${colors.body};
      border: solid ${borderWidth.s} ${colors.border};
    `;
  }
  if (kind === TAG.KIND.PURPLE) {
    return css`
      background-color: ${colors.purple};
      color: ${colors.onColor};
    `;
  }
  return null;
};

const StyledTag = styled.div`
  ${({ size, kind }) => css`
    ${setSize({ size })};
    ${setKind({ kind })};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${radius.circle};
    cursor: default;
  `}
`;

const Tag = ({
  children,
  kind,
  size,
  LeftComponent,
  RightComponent,
  ...rest
}) => (
  <StyledTag kind={kind} size={size} {...rest}>
    {LeftComponent && (
      <Block
        marginRight={spacings.xs}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        {LeftComponent()}
      </Block>
    )}
    {size === TAG.SIZE.MEDIUM && (
      <Body16 strong numberOfLines={1}>
        {children}
      </Body16>
    )}
    {size === TAG.SIZE.SMALL && (
      <Body14 strong numberOfLines={1}>
        {children}
      </Body14>
    )}
    {RightComponent && (
      <Block
        marginLeft={spacings.xs}
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100%"
      >
        {RightComponent()}
      </Block>
    )}
  </StyledTag>
);

const Medium = ({ ...rest }) => <Tag size={TAG.SIZE.MEDIUM} {...rest} />;
const Small = ({ ...rest }) => <Tag size={TAG.SIZE.SMALL} {...rest} />;

export default { Medium, Small };
