import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { borderWidth, colors, spacings } from "../../assets/themes";
import {
  Block,
  Breadcrumb,
  Button,
  Container,
  FormControl,
  H1,
  Icon,
  Link,
  List,
  Select,
  Shape,
  Spinner,
} from "../../components";
import { BUTTON, LIST, SHAPE } from "../../components/Styles/variants";
import {
  useCancelReview,
  useGetCancelReview,
} from "../../modules/routes/job-routes";
import polyglot from "../../utils/polyglot";
import { DASHBOARD_PATH } from "../dashboard/config/routes";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import ReviewFooter from "../jobberReview/ReviewFooter";
import CancelConfirmModal from "./CancelConfirmModal";
import CancellationDateTime from "./CancellationDateTime";
import ChangeConfirmDateTime from "./ChangeConfirmDateTime";
import ChangeConfirmModal from "./ChangeConfirmModal";
import {
  CANCELLATION_ACTION_CANCEL_JOB,
  CANCELLATION_ACTION_CANCEL_JOB_BUTTON,
  CANCELLATION_ACTION_CHANGE_JOBBER,
  CANCELLATION_ACTION_EDIT_DATETIME,
  CANCELLATION_REASONS,
  JOB_NOT_REPOSTABLE,
  JOB_REOPENABLE,
  JOB_REPOSTABLE,
  POSTER_NEEDS_NEW_DATE,
} from "./constants";

const JobberCancellation = ({ offer_id, job, current_admin }) => {
  const { data, isLoading } = useGetCancelReview({ id: offer_id });
  const [cancelConfirmIsOpen, setCancelConfirmIsOpen] = useState(false);
  const [changeConfirmIsOpen, setChangeConfirmIsOpen] = useState(false);
  const isRepostable = data?.jobber_changed_kind === JOB_REPOSTABLE;
  const isNotRepostable = data?.jobber_changed_kind === JOB_NOT_REPOSTABLE;
  const isReopenable = data?.jobber_changed_kind === JOB_REOPENABLE;
  const cancelReview = useCancelReview();

  const handleSubmit = (values) => {
    cancelReview.mutate({ id: offer_id, ...values });
  };

  const formik = useFormik({
    initialValues: {
      cancel_reason: "",
      cancel_kind: "",
      jobber_changed_kind: data?.jobber_changed_kind,
      start_hour: "",
      date: "",
    },
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: () =>
      yup.object().shape({
        ...(data?.can_edit_datetime &&
        (isRepostable ||
          (isReopenable &&
            formik.values.cancel_reason === POSTER_NEEDS_NEW_DATE))
          ? {
              date: yup.string().required(),
              start_hour: yup.string().required(),
            }
          : {}),
      }),
    onSubmit: handleSubmit,
  });

  const getAction = () =>
    CANCELLATION_REASONS.find((r) => r.value === formik.values.cancel_reason);

  const getChangeJobberSubtitle = () => {
    if (isRepostable) {
      return polyglot.t("cancel_review.select_new_date");
    }
    if (isNotRepostable) {
      return polyglot.t("cancel_review.republish_your_job");
    }
    if (isReopenable) {
      return polyglot.t("cancel_review.book_another_offer");
    }
    return "";
  };

  const handleCloseChangeConfirm = () => {
    setChangeConfirmIsOpen(false);
    formik.setFieldValue("date", "");
    formik.setFieldValue("start_hour", "");
    // prevent error show up by reseting the touched
    formik.setFieldTouched("start_hour", false);
  };

  const handleChangeKind = (kind) => {
    formik.setFieldValue("cancel_kind", kind);
  };

  return (
    <div>
      <Container.Small>
        <Block paddingY={spacings.l}>
          <Block display={{ xs: "none", sm: "block" }}>
            <Breadcrumb>
              <Link href={DASHBOARD_PATH}>{polyglot.t("routes.jobs")}</Link>
              <Link href={job.url}>{job.title}</Link>
              <span>{polyglot.t("cancel_review.change_or_cancel")}</span>
            </Breadcrumb>
          </Block>
          <Block display={{ xs: "block", sm: "none" }}>
            <Button.Link
              css={`
                padding-left: 0;
                padding-right: 0;
              `}
              href={job.url}
              LeftComponent={() => <Icon.Medium name="arrow-left" />}
            >
              {job.title}
            </Button.Link>
          </Block>
          <H1
            css={`
              margin-top: ${spacings.l};
              margin-bottom: ${spacings.m};
            `}
          >
            {polyglot.t("cancel_review.change_or_cancel")}
          </H1>
          {!isLoading ? (
            <>
              <JobberOfferItem
                price={data.offer.price}
                state="selected"
                shape={LIST.SHAPE.ROUND}
                withGutters
                jobber={data.offer.jobber}
                divider={false}
                css={`
                  border: solid ${borderWidth.s} ${colors.border};
                `}
              />
              <form onSubmit={formik.handleSubmit}>
                <List.Header>
                  {polyglot.t("cancel_review.what_is_happening")}
                </List.Header>
                <FormControl
                  label={polyglot.t("cancel_review.select_reason_for", {
                    first_name: data.offer.jobber.first_name,
                  })}
                >
                  <Select
                    value={formik.values.cancel_reason}
                    onChange={(v) =>
                      formik.setFieldValue("cancel_reason", v.value)
                    }
                    placeholder={polyglot.t("cancel_review.select_reason")}
                    options={CANCELLATION_REASONS.filter((reason) => {
                      if (
                        !data.allow_date_change &&
                        reason.value === POSTER_NEEDS_NEW_DATE
                      ) {
                        return false;
                      }
                      return !reason.isAdmin || current_admin;
                    })}
                  />
                </FormControl>
                {!!formik.values.cancel_reason && (
                  <>
                    {getAction().actions.includes(
                      CANCELLATION_ACTION_CANCEL_JOB_BUTTON
                    ) && (
                      <Button.Large
                        block
                        onClick={() => {
                          handleChangeKind("jobber_canceled");
                          setCancelConfirmIsOpen(true);
                        }}
                        accentColor={BUTTON.ACCENT_COLOR.DANGER}
                      >
                        {polyglot.t("cancel_review.cancel_definitively")}
                      </Button.Large>
                    )}
                    {getAction().actions?.length > 1 && (
                      <List.Header>
                        {polyglot.t("cancel_review.what_do_you_want_to_do")}
                      </List.Header>
                    )}
                    {getAction().actions.includes(
                      CANCELLATION_ACTION_EDIT_DATETIME
                    ) &&
                      data.can_edit_datetime && (
                        <CancellationDateTime id={data.job.id} />
                      )}
                    {getAction().actions.includes(
                      CANCELLATION_ACTION_CHANGE_JOBBER
                    ) && (
                      <List.Item
                        chevron
                        onClick={() => {
                          handleChangeKind("jobber_changed");
                          setChangeConfirmIsOpen(true);
                        }}
                        LeftComponent={() => (
                          <Shape.Large
                            shape={SHAPE.SHAPE.CIRCLE}
                            color={colors.primary}
                            backgroundColor={colors.primaryLight}
                          >
                            <Icon.XLarge name="double-arrow" />
                          </Shape.Large>
                        )}
                      >
                        <List.Elem.Title strong>
                          {polyglot.t("cancel_review.change_jobber")}
                        </List.Elem.Title>
                        <List.Elem.Subtitle>
                          {polyglot.t(
                            "cancel_review.you_can_book_another_jobber"
                          )}
                        </List.Elem.Subtitle>
                      </List.Item>
                    )}
                    {getAction().actions.includes(
                      CANCELLATION_ACTION_CANCEL_JOB
                    ) && (
                      <List.Item
                        chevron
                        onClick={() => {
                          handleChangeKind("jobber_canceled");
                          setCancelConfirmIsOpen(true);
                        }}
                        LeftComponent={() => (
                          <Shape.Large
                            shape={SHAPE.SHAPE.CIRCLE}
                            color={colors.danger}
                            backgroundColor={colors.dangerLight}
                          >
                            <Icon.XLarge name="user-times" />
                          </Shape.Large>
                        )}
                      >
                        <List.Elem.Title strong>
                          {polyglot.t("cancel_review.cancel_definitively")}
                        </List.Elem.Title>
                        <List.Elem.Subtitle>
                          {polyglot.t("cancel_review.your_job_will_be_cancel")}
                        </List.Elem.Subtitle>
                      </List.Item>
                    )}
                  </>
                )}
                <ChangeConfirmModal
                  disabled={!formik.isValid}
                  subtitle={getChangeJobberSubtitle()}
                  isLoading={cancelReview.isLoading || cancelReview.isSuccess}
                  isOpen={changeConfirmIsOpen}
                  onSubmit={formik.handleSubmit}
                  onClose={handleCloseChangeConfirm}
                >
                  {data?.can_edit_datetime &&
                    (isRepostable ||
                      (isReopenable &&
                        formik.values.cancel_reason ===
                          POSTER_NEEDS_NEW_DATE)) && (
                      <ChangeConfirmDateTime
                        isLoading={isLoading}
                        formik={formik}
                      />
                    )}
                </ChangeConfirmModal>
                <CancelConfirmModal
                  isLoading={cancelReview.isLoading || cancelReview.isSuccess}
                  isOpen={cancelConfirmIsOpen}
                  nbHours={data?.offer.nb_hours}
                  // force submit function without use the formik one (prevent check if isValid)
                  onSubmit={() => handleSubmit(formik.values)}
                  onClose={() => setCancelConfirmIsOpen(false)}
                />
              </form>
            </>
          ) : (
            <Block
              marginTop={spacings.xl}
              display="flex"
              justifyContent="center"
            >
              <Spinner.Medium />
            </Block>
          )}
        </Block>
      </Container.Small>

      <ReviewFooter />
    </div>
  );
};

export default JobberCancellation;
