import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { colors, radius, shadows, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Block,
  Body18,
  Button,
  H3,
  Icon,
  Map,
  Modal,
  Rating,
  Shape,
} from "../../components";
import { SHAPE } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const StyledPointer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 999;
  justify-content: center;
  margin: auto;
`;

const StyledWave = styled(motion.div)`
  position: absolute;
  border-radius: ${radius.circle};
  width: ${sizes.size54};
  height: ${sizes.size54};
  background-color: ${colors.primary};
`;

const StyledJobberMarker = styled(motion.div)`
  ${({ left, right }) => css`
    position: absolute;
    z-index: 99;
    left: ${left}%;
    top: ${right}%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `}
`;

const StyledRatingWrapper = styled.div`
  border-radius: ${radius.circle};
  background-color: ${colors.background};
  margin-top: -8px;
  padding: ${spacings.xs} ${spacings.s};
`;

const setWaveVariants = (i) => ({
  animate: {
    scale: [1, 12],
    opacity: [0.5, 0],
  },
  transition: {
    delay: 0 + i * 0.4,
    ease: "easeInOut",
    duration: 1.5,
  },
});

const PointerMarker = () => {
  const [animationIsComplete, setAnimationIsComplete] = useState(false);

  return (
    <StyledPointer>
      <AnimatePresence initial={false}>
        {!animationIsComplete && (
          <motion.div
            animate={{ scale: 1 }}
            initial={{ scale: 0.5 }}
            exit={{ scale: 0.5 }}
            style={{ position: "absolute", zIndex: 999 }}
          >
            <Shape.Large
              shape={SHAPE.SHAPE.CIRCLE}
              color={colors.onColor}
              backgroundColor={colors.primary}
            >
              <Icon.XLarge name="home" />
            </Shape.Large>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {animationIsComplete && (
          <motion.div
            animate={{ scale: 1, zIndex: 99 }}
            initial={{ scale: 0.5 }}
            exit={{ scale: 0.5 }}
            style={{ position: "absolute" }}
          >
            <Shape.Large
              shape={SHAPE.SHAPE.CIRCLE}
              color={colors.onColor}
              backgroundColor={colors.primary}
            >
              <Icon.XLarge name="check" />
            </Shape.Large>
          </motion.div>
        )}
      </AnimatePresence>
      <StyledWave {...setWaveVariants(1)} />
      <StyledWave {...setWaveVariants(2)} />
      <StyledWave {...setWaveVariants(3)} />
      <StyledWave {...setWaveVariants(4)} />
      <StyledWave
        {...setWaveVariants(5)}
        onAnimationComplete={() => setAnimationIsComplete(true)}
      />
    </StyledPointer>
  );
};

const JobberMarker = ({ avatar, rate, index }) => {
  const [left] = useState(Math.random() * Math.floor(95));
  const [right] = useState(Math.random() * Math.floor(95));

  return (
    <StyledJobberMarker
      left={left}
      right={right}
      initial={{ display: "none", scale: 0.5 }}
      animate={{ display: "flex", scale: 1.2 }}
      transition={{ delay: index / 4 }}
    >
      <Avatar
        src={avatar}
        css={`
          box-shadow: ${shadows.s};
        `}
      />
      <StyledRatingWrapper>
        <Rating rate={rate} type="compact" />
      </StyledRatingWrapper>
    </StyledJobberMarker>
  );
};

const JobSuccessModal = ({
  avg_time_to_first_offer,
  jobbers = [],
  location,
  private_jobber,
  onClose,
  isOpen,
}) => {
  const { current_user } = useSelector((s) => s.rails);

  const getJobbers = () => {
    const res = jobbers;
    if (private_jobber) return [private_jobber];
    return res;
  };

  const renderJobbers = () =>
    getJobbers()?.map((jobber, index) => (
      <JobberMarker
        key={`${index}-map-marker`}
        index={index}
        avatar={jobber.avatar}
        first_name={jobber.first_name}
        rate={jobber.rate}
      />
    ));

  return (
    <Modal.Small isOpen={isOpen} fullScreenOnMobile onClose={onClose}>
      <Block
        height={360}
        width="100%"
        position="relative"
        borderTopRightRadius={radius.ml}
        borderTopLeftRadius={radius.ml}
        overflow="hidden"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Map
          zoomControl={false}
          gestureHandling="none"
          disableDefaultUI
          defaultCenter={{ lat: location.latitude, lng: location.longitude }}
          defaultZoom={15}
        />
        <PointerMarker />
        {renderJobbers()}
      </Block>
      <Modal.Item.Wrapper>
        <Block marginTop={spacings.l} marginBottom={spacings.s}>
          {!private_jobber ? (
            <>
              <H3
                css={`
                  margin-bottom: ${spacings.s};
                `}
              >
                {polyglot.t(
                  "categories.success.congratulation_first_name_job_posted",
                  {
                    first_name: current_user.first_name,
                  }
                )}
              </H3>
              <Body18>
                {polyglot.t("categories.success.first_offers_in")}{" "}
                <strong
                  css={`
                    color: ${colors.primary};
                  `}
                >
                  {avg_time_to_first_offer}
                </strong>{" "}
                🎉
              </Body18>
            </>
          ) : (
            <>
              <H3
                css={`
                  margin-bottom: ${spacings.s};
                `}
              >
                {polyglot.t(
                  "categories.success.congratulation_first_name_job_posted",
                  {
                    first_name: current_user.first_name,
                  }
                )}
              </H3>
              <Body18>
                {polyglot.t("categories.success.posted_only_for_first_name", {
                  first_name: private_jobber.first_name,
                })}
              </Body18>
            </>
          )}
        </Block>
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large block onClick={onClose}>
          {polyglot.t("jobs.see_my_job")}
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.Small>
  );
};

export default JobSuccessModal;
