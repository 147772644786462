import React from "react";

const Wrapper = ({ children, className, style }) => (
  <div
    style={{ display: "flex", flex: 1, flexDirection: "column", ...style }}
    className={`mx-3 ${className}`}
  >
    {children}
  </div>
);

export default Wrapper;
