import React from "react";
import { colors } from "../../assets/themes";
import { Body16, Icon, List } from "../../components";

const ActionItem = ({ icon, title, ...rest }) => (
  <List.Item
    withGutters
    chevron
    LeftComponent={() => <Icon.Large name={icon} color={colors.primary} />}
    {...rest}
  >
    {typeof title === "function" ? title() : <Body16>{title}</Body16>}
  </List.Item>
);

export default ActionItem;
