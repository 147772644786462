import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

const config = {
  multipleJobbersAllowed: true,
  IsNbHoursReadOnly: true,
};

export function useCategory1050() {
  const DEFAULT_NB_HOURS = 5;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 2.5,
            subtitle: polyglot.t("categories.one_to_two_furniture"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.three_to_four_furniture"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 7.5,
            subtitle: polyglot.t("categories.five_or_more_furniture"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1,
    initialValues,
    config,
  };
}
