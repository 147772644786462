import React, { useState } from "react";
import styled from "styled-components";
import { Body16 } from "./Text";
import Block from "./Block";
import { colors, spacings } from "../assets/themes";

const StyledLabel = styled(Body16)`
  color: ${colors.body};
  margin-top: ${spacings.s};
  margin-bottom: ${spacings.s};
  font-weight: var(--font-weight-regular);
`;

const FormControl = ({ label, hint, children, htmlFor, error, ...rest }) => {
  const [childProps] = useState(React.Children.toArray(children)[0]?.props);
  const inputHtmlFor = childProps?.id || childProps?.name;

  const enhancedChildren = () =>
    React.Children.map(children, (child) =>
      child
        ? React.cloneElement(child, {
            id: inputHtmlFor,
            error: child?.props?.error || error,
          })
        : child
    );

  return (
    <Block marginBottom={spacings.m} {...rest}>
      {label && (
        <StyledLabel forwardedAs="label" htmlFor={htmlFor || inputHtmlFor}>
          {typeof label === "function" && label()}
          {typeof label === "string" && label}
        </StyledLabel>
      )}
      {enhancedChildren()}
      {hint && (
        <Block marginTop={spacings.s}>
          <Body16 color={colors.muted}>
            {typeof hint === "function" && hint()}
            {typeof hint === "string" && hint}
          </Body16>
        </Block>
      )}
      {error && (
        <Block marginTop={spacings.s}>
          <Body16 color={colors.danger}>{error}</Body16>
        </Block>
      )}
    </Block>
  );
};

export default FormControl;
