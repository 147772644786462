import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, List, Pagination, Spinner } from "../../components";
import { getHistory } from "../../modules/routes";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";

const History = ({ id }) => {
  const history = useHistory();
  const [historyData, setHistoryData] = useState(null);

  const loadMore = (e, page) => {
    const res = getHistory({ id, page });
    res
      .then(({ data: apiData }) => {
        setHistoryData(apiData);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    loadMore(null, 1);
  }, []);

  return (
    <Container.Medium className="my-5">
      {historyData ? (
        <div className="">
          <Button.Link onClick={() => history.push("")}>
            <i className="icon-arrow-left mr-2" />
            Retour
          </Button.Link>
          <div>
            <h1 className="d-block mt-4">Historique des actions</h1>
            <div className="">
              {historyData.direct_actions.map((d, index) => (
                <List.Item
                  key={`direct_action-${index}`}
                  chevron={!!d.url}
                  className="px-0"
                  href={d.url || null}
                  target={d.url ? "_blank" : null}
                  RightComponent={() =>
                    d.price && (
                      <List.Elem.Label>
                        {polyglot.toSmartCurrency(d.price)}
                      </List.Elem.Label>
                    )
                  }
                >
                  <List.Elem.Title className="font-weight-medium">
                    {d.title}
                  </List.Elem.Title>
                  <List.Elem.Subtitle className="font-weight-medium">
                    {capitalize(d.date_time)}
                  </List.Elem.Subtitle>
                </List.Item>
              ))}
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Pagination
                  totalPages={historyData.total_pages}
                  currentPage={historyData.page}
                  onChange={loadMore}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center my-3">
          <Spinner.Large className="mx-auto text-muted" />
        </div>
      )}
    </Container.Medium>
  );
};

export default History;
