import polyglot from "../../../utils/polyglot";
import { NB_HOURS_PATH } from "../constants";

const config = {
  isCustom: true,
  multipleJobbersAllowed: true,
  initialTitleIsEmpty: true,
  renderPages: (pages) => {
    const newPages = [...pages];
    const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
    newPages[nbHoursIndex].title = polyglot.t(
      "categories.how_many_hours_of_service_do_you_need"
    );
    return newPages;
  },
};

export function useCategory1106() {
  return {
    initialValues: { nb_hours: 2 },
    config,
  };
}
