import React from "react";
import { sizes } from "../assets/themes";
import Input from "./Input";

const Textarea = React.forwardRef(
  ({ rows = 4, style, resize = "none", ...rest }, ref) => (
    <Input
      as="textarea"
      style={{
        resize,
        minHeight: resize !== "none" && sizes.size128,
        ...style,
      }}
      rows={rows}
      ref={ref}
      {...rest}
    />
  )
);

export default Textarea;
