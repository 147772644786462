import axios from "axios";

const api = axios.create({
  baseURL: "/",
  responseType: "json",
  timeout: 30000,
});

api.interceptors.request.use(async (config) => {
  const csrfToken = document.querySelector('[name="csrf-token"]').content;
  const newConfig = config;
  newConfig.headers.common["X-CSRF-Token"] = csrfToken;
  newConfig.headers.Accept = "application/json";
  newConfig.headers["Content-Type"] = "application/json";
  return newConfig;
});

export { api };
