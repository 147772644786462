import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useCategoryForm, useGoogleAnalytics } from "../../modules/hooks";
import {
  getCategoryFormsOpenExperimentEvent,
  getCategoryFormsSubmitExperimentEvent,
} from "../../utils/analytics-events";

function useCategoryFormUrlParams() {
  const { isSubmitting } = useFormikContext();
  const { id } = useCategoryForm();
  const PARAMS_NAME = "event";
  const urlParams = new URLSearchParams(window.location.search);
  const [cookies] = useCookies([urlParams.get(PARAMS_NAME)]);
  const name = urlParams.get(PARAMS_NAME);

  const { sendEvent } = useGoogleAnalytics();

  useEffect(() => {
    if (name) {
      const form = document.getElementById("flow-router");
      form.addEventListener("submit", () => {
        sendEvent(
          getCategoryFormsSubmitExperimentEvent({
            id,
            name,
            variantId: cookies[urlParams.get(PARAMS_NAME)],
          })
        );
      });
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (name) {
      sendEvent(
        getCategoryFormsOpenExperimentEvent({
          id,
          name,
          variantId: cookies[urlParams.get(PARAMS_NAME)],
        })
      );
    }
  }, []);
}

export default useCategoryFormUrlParams;
