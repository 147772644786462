import Fuse from "fuse.js";
import React, { useEffect, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled, { css } from "styled-components";
import { colors, sizes, spacings } from "../assets/themes";
import { useOnClickOutside } from "../modules/hooks";
import polyglot from "../utils/polyglot";
import Block from "./Block";
import Button from "./Button";
import Icon from "./Icon";
import Input from "./Input";
import List from "./List";
import Popover from "./popover/Popover";
import PortalContent from "./popover/PortalContent";
import { numberToPx } from "./Styles/Helper";
import { BUTTON, INPUT, LIST, SELECT, TAG } from "./Styles/variants";
import { Body14 } from "./Text";
import Tag from "./Tag";

const sameWidth = {
  name: "sameWidth",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    const newState = state;
    newState.styles.popper.width = `${newState.rects.reference.width}px`;
  },
  effect: ({ state }) => {
    const newState = state;
    newState.elements.popper.style.width = `${newState.elements.reference.offsetWidth}px`;
  },
};

const MAX_MENU_HEIGHT = "278px";

const StyledSelect = styled.div`
  position: relative;
`;

const StyledInputWrapper = styled.div`
  ${({ canType }) =>
    !canType &&
    css`
      > div,
      input {
        cursor: pointer;
      }
    `}
`;

const StyledItems = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledMenuItem = styled(List.Item)`
  * {
    font-size: var(--font-size-body14);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.5;
    `};
  ${({ active, isHover, disabled }) =>
    css`
      background: ${(isHover || active) && colors.gray50};
      * {
        font-weight: ${active && "var(--font-weight-medium)"};
      }
      &:hover {
        background: ${!isHover && !disabled && "none"};
      }
    `}
`;

const MemoStyledMenuItem = React.memo(
  StyledMenuItem,
  (p, n) =>
    p.value === n.value &&
    p.label === n.label &&
    p.isHover === n.isHover &&
    p.active === n.active &&
    p.disabled === n.disabled
);

const removeDuplicates = (arr) =>
  arr.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.value === value.value)
  );

const getSingleDefaultValue = (value, options) => {
  if (typeof value === "object") {
    return value;
  }
  // .toString() fix unactive value when value === 0
  if (value?.toString() && options) {
    return options.find((o) => o.value === value);
  }
  return null;
};

const getMultiDefaultValue = (values, options) => {
  if (Array.isArray(values)) {
    return values.map((value) => getSingleDefaultValue(value, options));
  }
  return [];
};

const getDefaultValue = (newValue, opts, { multi }) => {
  if (multi) {
    return getMultiDefaultValue(newValue, opts);
  }
  return getSingleDefaultValue(newValue, opts);
};

const setIndexLimit = (n, options) => {
  if (options) {
    const min = options.findIndex((opt) => typeof opt === "object");
    const max = options.length - 1;
    if (n >= max) {
      return max;
    }
    if (n <= min) {
      return min;
    }
    return n;
  }
  return n;
};

const getIndexFromValue = (value, options) => {
  const formatedVal = typeof value === "object" ? value.value : value;
  if (formatedVal?.toString() && options)
    return options.findIndex((o) => o.value === formatedVal);
  return setIndexLimit(0, options);
};

const EnhancedMenu = React.forwardRef(({ width, children, height }, ref) => (
  <Popover.Elem.Menu
    ref={ref}
    css={`
      max-height: ${numberToPx(height)};
      width: ${numberToPx(width)};
      overflow-y: auto;
    `}
  >
    {children}
  </Popover.Elem.Menu>
));

const DropdownIcon = ({ size }) => {
  if (size === INPUT.SIZE.SMALL) {
    return <Icon.Medium name="dropdown" />;
  }
  return <Icon.Large name="dropdown" />;
};

const formatOptions = (obj) =>
  typeof obj === "object" && !Array.isArray(obj) && obj !== null
    ? Object.keys(obj).reduce((r, k) => r.concat(k, obj[k]), [])
    : obj;

const getSearchableOptions = (obj) => obj?.filter((o) => o.label);

const Select = ({
  options: defaultOptions = [],
  name,
  value,
  placeholder = "",
  onInputChange,
  LeftComponent,
  noOptionsMessage = polyglot.t("common.no_result"),
  onChange,
  isLoading,
  position = SELECT.POSITION.BOTTOM,
  error,
  displayValue,
  dropdownRender,
  disabled,
  dropdownWidth,
  dropdownHeight = MAX_MENU_HEIGHT,
  searchable,
  onClose,
  inputMode,
  onFocus,
  onBlur,
  clearable,
  pattern,
  id,
  size,
  required,
  noPortal = true,
  multi = false,
  ...rest
}) => {
  const selectInput = useRef();
  const wrapperRef = useRef();
  const menuRef = useRef();
  const options = formatOptions(defaultOptions);
  const [filteredOptions, setFilteredOptions] = useState(null);
  const [selectedValue, setSelectedValue] = useState(
    getDefaultValue(value, options, { multi })
  );
  const [isFocus, setIsFocus] = useState(false);
  const [focusedItem, setFocusedItem] = useState(
    getIndexFromValue(value, options)
  );
  const [inputValue, setInputValue] = useState(
    multi ? "" : getDefaultValue(value, options, { multi })?.label || ""
  );
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      ...sameWidth,
      {
        name: "flip",
        options: {
          padding: 16,
          flipVariations: true,
        },
      },
      {
        name: "preventOverflow",
        options: {
          altBoundary: false,
          padding: 16,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleFocusIndex = (n) => {
    setFocusedItem(setIndexLimit(n, filteredOptions || options));
  };

  useEffect(() => {
    setFilteredOptions(null);
    handleFocusIndex(0);
  }, [menuIsOpen]);

  const menuIsVisible =
    menuIsOpen && (options?.length > 0 || inputValue?.length > 0) && !isLoading;

  const fuse =
    searchable &&
    new Fuse(getSearchableOptions(formatOptions(defaultOptions)), {
      keys: ["label", "value", "tags"],
    });

  const handleSearch = (value) => {
    if (value?.length > 0) {
      const res = fuse.search(value);
      setFilteredOptions(removeDuplicates(res.map((r) => r.item)));
      handleFocusIndex(0);
      if (menuRef.current) menuRef.current.scrollTop = 0;
    } else {
      setFilteredOptions(null);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
    if (selectedValue?.label !== inputValue && !isFocus) {
      // handle autocomplete auto remove field if not focus
      setInputValue(selectedValue?.label);
    }
    setMenuIsOpen(false);
    handleFocusIndex(getIndexFromValue(value, options));
  };

  const handleOpen = () => {
    if (!disabled) {
      setMenuIsOpen(true);
      setIsFocus(true);
    }
  };

  const handleFocus = (e) => {
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
    if (!menuIsVisible) {
      setIsFocus(false);
    }
  };

  const handleCloseMenu = (e) => {
    const isOutside =
      !popperElement?.contains(e?.target) &&
      !referenceElement?.contains(e?.target);
    if (isOutside) {
      if (selectedValue && selectedValue.label !== inputValue) {
        setInputValue(selectedValue.label);
      }
      setIsFocus(false);
      handleClose();
    }
  };

  useOnClickOutside({ current: popperElement }, handleCloseMenu);
  useOnClickOutside({ current: referenceElement }, handleCloseMenu);

  useEffect(() => {
    // update value only if a value change
    if (selectedValue) {
      if (value !== selectedValue?.value) {
        const selectionOption = getDefaultValue(value, options, { multi });
        setSelectedValue(selectionOption);
        if (multi) {
          setInputValue("");
        } else {
          setInputValue(selectionOption?.label || "");
        }
      }
    }
  }, [value]);

  const handleChange = (option) => {
    let formatedReturnedValue = option;
    if (multi && option?.value) {
      // return array of object when multi
      const existingValueIndex = selectedValue.findIndex(
        (s) => s.value === option.value
      );
      formatedReturnedValue = selectedValue || [];
      // remove if already exist
      if (existingValueIndex >= 0) {
        formatedReturnedValue.splice(existingValueIndex, 1);
      } else {
        formatedReturnedValue.push(option);
      }
      // clear input on tag add
      setInputValue("");
    } else {
      setInputValue(option?.label);
    }
    setSelectedValue(formatedReturnedValue);
    if (onChange) onChange(formatedReturnedValue);
    selectInput.current?.focus();
    handleClose();
  };

  const handleInputChange = (e) => {
    if (onInputChange || searchable) {
      setInputValue(e.target.value);
      if (!menuIsOpen) {
        setMenuIsOpen(true);
      }
      if (searchable) handleSearch(e.target.value);
      if (onInputChange) onInputChange(e);
    }
  };

  const handleKeyDown = (e) => {
    const currOptionsArray = filteredOptions || options;
    if (e.key === "Tab" && menuIsVisible) {
      handleCloseMenu();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      handleCloseMenu();
    }
    if (e.code === "Space" && !menuIsVisible && !onInputChange) {
      e.preventDefault();
      handleOpen();
    }
    if (e.key === "Enter") {
      if (document.activeElement === selectInput.current) {
        e.preventDefault();
      }
      if (menuIsVisible) {
        e.preventDefault();
        if (focusedItem?.toString() && currOptionsArray[focusedItem]) {
          e.stopPropagation();
          handleChange(currOptionsArray[focusedItem]);
        }
      } else {
        handleOpen();
      }
    }
    if (e.key === "ArrowDown" && focusedItem < currOptionsArray?.length - 1) {
      e.preventDefault();
      e.stopPropagation();
      if (currOptionsArray[focusedItem + 1]?.value) {
        handleFocusIndex(focusedItem + 1);
      } else {
        handleFocusIndex(focusedItem + 2);
      }
    }
    if (e.key === "ArrowUp" && focusedItem > 0) {
      e.preventDefault();
      e.stopPropagation();
      if (currOptionsArray[focusedItem - 1]?.value) {
        handleFocusIndex(focusedItem - 1);
      } else {
        handleFocusIndex(focusedItem - 2);
      }
    }
  };

  const handleMouseEnter = (i) => {
    handleFocusIndex(i);
  };

  const handleMouseLeave = (i) => {
    handleFocusIndex(i);
  };

  const handleTagClick = (tagIndex) => {
    const newValues = [...selectedValue];
    newValues.splice(tagIndex, 1);
    setSelectedValue(newValues);
    if (onChange) onChange(newValues);
    handleClose();
  };

  const renderMenu = () =>
    (filteredOptions || options)?.map((option, index) => {
      if (typeof option === "string") {
        return (
          <MemoStyledMenuItem
            key={`select-menu-group-${index}-${option}`}
            forwardedAs="li"
            withGutters
            divider={false}
            size={LIST.SIZE.COMPACT}
          >
            <Body14 strong>{option}</Body14>
          </MemoStyledMenuItem>
        );
      }
      const multiIsSelectedIndex = multi
        ? selectedValue?.findIndex((s) => s?.value === option?.value)
        : -1;

      return (
        <MemoStyledMenuItem
          key={`select-menu-item-${option.value}-${index}`}
          forwardedAs="li"
          role="option"
          active={
            (multi && multiIsSelectedIndex >= 0) ||
            option?.value === selectedValue?.value
          }
          isHover={focusedItem === index && !option.disabled}
          focusedItem={focusedItem}
          index={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
          disabled={option.disabled}
          RightComponent={
            multiIsSelectedIndex >= 0
              ? () => (
                  <>
                    {option.RightComponent}
                    <Icon.Medium name="check" color={colors.muted} />
                  </>
                )
              : option.RightComponent
          }
          LeftComponent={option.LeftComponent}
          withGutters
          divider={false}
          onClick={() => !option.disabled && handleChange(option)}
          size={LIST.SIZE.COMPACT}
        >
          {option.label}
        </MemoStyledMenuItem>
      );
    });

  const handleClick = () => {
    if (menuIsOpen) {
      handleClose();
    } else {
      handleOpen();
    }
  };

  const handleClear = (e) => {
    e.stopPropagation();
    handleChange(multi ? [] : {});
    setInputValue("");
  };

  const selectValue =
    selectedValue?.label === inputValue && displayValue
      ? displayValue(selectedValue) || inputValue || ""
      : inputValue || "";

  return (
    <StyledSelect ref={wrapperRef} {...rest} onKeyDown={handleKeyDown}>
      <StyledInputWrapper
        ref={setReferenceElement}
        canType={searchable || onInputChange}
        onClick={handleClick}
      >
        <Input
          readOnly={!(onInputChange || searchable)}
          name={name}
          error={error}
          onChange={handleInputChange}
          placeholder={placeholder}
          value={selectValue}
          pattern={pattern}
          id={id}
          size={size}
          autoComplete="off"
          role="combobox"
          isFocus={isFocus}
          aria-autocomplete="list"
          inputMode={inputMode}
          required={required}
          InputComponent={
            multi
              ? ({ InputComponent, innerProps }) => (
                  <>
                    <Block
                      display="flex"
                      flexWrap="wrap"
                      width="100%"
                      alignItems="center"
                    >
                      {selectedValue?.length > 0 &&
                        selectedValue?.map((option, index) => (
                          <Tag.Small
                            kind={TAG.KIND.WHITE}
                            css={`
                              margin: ${spacings.xs};
                              display: inline-flex;
                              max-width: ${sizes.size128};
                              cursor: pointer;
                            `}
                            RightComponent={() => <Icon.Small name="times" />}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleTagClick(index);
                            }}
                          >
                            <Body14 numberOfLines={1}>{option?.label}</Body14>
                          </Tag.Small>
                        ))}
                      <InputComponent
                        {...innerProps}
                        style={{
                          flexGrow: 1,
                        }}
                      />
                    </Block>
                  </>
                )
              : null
          }
          LeftComponent={LeftComponent}
          RightComponent={() => (
            <>
              {(multi ? selectedValue.length > 0 : selectValue) &&
                clearable &&
                !isLoading && (
                  <Button.Small
                    kind={BUTTON.KIND.MINIMAL}
                    shape={BUTTON.SHAPE.CIRCLE}
                    style={{ background: "none" }}
                    onClick={handleClear}
                  >
                    <Icon.Large
                      name="times-circle-solid"
                      color={colors.muted}
                    />
                  </Button.Small>
                )}
              <DropdownIcon size={size} />
            </>
          )}
          isLoading={isLoading}
          ref={selectInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </StyledInputWrapper>
      <PortalContent
        position={state?.placement || position}
        isVisible={menuIsVisible}
        styles={styles}
        ref={setPopperElement}
        noPortal={noPortal}
      >
        <EnhancedMenu
          ref={menuRef}
          width={dropdownWidth}
          height={dropdownHeight}
        >
          <StyledItems>
            {dropdownRender ? dropdownRender(renderMenu()) : renderMenu()}
          </StyledItems>
          {!(filteredOptions || options)?.length > 0 && inputValue?.length > 0 && (
            <Block marginY={spacings.m}>
              <Body14 color={colors.muted} align="center">
                {typeof noOptionsMessage === "function"
                  ? noOptionsMessage({ inputValue })
                  : noOptionsMessage}
              </Body14>
            </Block>
          )}
        </EnhancedMenu>
      </PortalContent>
    </StyledSelect>
  );
};

export default Select;
