import React from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Body16, List } from "../../components";
import { LIST } from "../../components/Styles/variants";

const NoteItem = ({ email, formatted_created_at, note, ...rest }) => (
  <List.Item size={LIST.SIZE.LARGE} {...rest}>
    <Block
      display="flex"
      alignItems="center"
      marginBottom={spacings.xs}
      width="100%"
    >
      <Block flex="1">
        <Body16 strong>{email}</Body16>
      </Block>
      <Body14 color={colors.muted}>{formatted_created_at}</Body14>
    </Block>
    <Body16
      css={`
        word-break: break-word;
        white-space: break-spaces;
      `}
    >
      {note}
    </Body16>
  </List.Item>
);

export default NoteItem;
