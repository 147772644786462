import { useFormikContext } from "formik";
import { APPLE_PAY, GOOGLE_PAY } from "./constants";
import { ApplePayButton, GooglePayButton } from "../../../components";

const PayButtonEnhancer = ({
  children,
  amount,
  lineItems,
  textType,
  block,
  disabled,
  onPaymentAuthorized,
  className,
  isLoading,
}) => {
  const { values } = useFormikContext();

  const renderPaymentButton = () => {
    if (amount > 0) {
      if (values.payment_method === APPLE_PAY) {
        return (
          <ApplePayButton.Large
            block={block}
            textType={textType}
            disabled={disabled}
            amount={amount}
            lineItems={lineItems}
            className={className}
            onPaymentAuthorized={onPaymentAuthorized}
          />
        );
      }
      if (values.payment_method === GOOGLE_PAY) {
        return (
          <GooglePayButton.Large
            block={block}
            textType={textType}
            disabled={disabled}
            amount={amount}
            lineItems={lineItems}
            className={className}
            isLoading={isLoading}
            onPaymentAuthorized={onPaymentAuthorized}
          />
        );
      }
    }
    return typeof children === "function"
      ? children({
          children,
          amount,
          lineItems,
          textType,
          block,
          disabled,
          className,
        })
      : children;
  };
  return renderPaymentButton();
};
export default PayButtonEnhancer;
