import React from 'react';
import QrcodeGenerator from '../pages/QrcodeGenerator';
import ErrorBoundary from '../utils/ErrorBoundary';

const QrcodeGeneratorApp = (props, rails) => () => (
  <ErrorBoundary>
    <QrcodeGenerator {...props} />
  </ErrorBoundary>
);

export default QrcodeGeneratorApp;
