import React, { useState } from "react";
import styled, { css, withTheme } from "styled-components";
import { Button } from "../../../components";
import { formatDate, renderStatusBagde } from "../../../utils";
import capitalize from "../../../utils/capitalize";
import polyglot from "../../../utils/polyglot";
import EditPaymentModal from "./EditPaymentModal";

const StyledCard = styled.div`
  ${({ editable, theme }) => css`
    border: ${editable ? `dashed 2px ${theme.colors.gray300}` : "inherit"};
    background-color: ${editable ? theme.colors.onColor : theme.colors.gray100};
    width: 100%;
    height: 100%;
    min-height: 150px;
  `}
`;

const Card = ({
  editable,
  id,
  payment_date,
  state: type,
  total_price,
  nb_hours,
  price_per_hour,
  updateData,
  relId,
}) => {
  const [editPaymentIsOpen, setEditPaymentIsOpen] = useState(false);

  return (
    <StyledCard
      className="d-block rounded p-3 d-flex flex-column justify-content-between"
      editable={editable}
    >
      <div className="mb-4">
        {editable && <span className="text-muted">Prochain paiement</span>}
        <p className="mb-0">
          {capitalize(formatDate(payment_date, "dddd DD MMMM"))}
        </p>
      </div>
      <div className="d-flex align-items-end justify-content-between flex-wrap">
        <h2 className="mb-0">{polyglot.toSmartCurrency(total_price)}</h2>
        {editable ? (
          <Button.XSmall
            kind="tertiary"
            className="mt-2"
            onClick={() => {
              setEditPaymentIsOpen(true);
            }}
          >
            Modifier
          </Button.XSmall>
        ) : (
          <div className="my-2">{renderStatusBagde(type)}</div>
        )}
      </div>
      {editable && (
        <EditPaymentModal
          id={relId}
          payment_id={id}
          isOpen={editPaymentIsOpen}
          onClose={() => {
            setEditPaymentIsOpen(false);
          }}
          nb_hours={nb_hours}
          price_per_hour={price_per_hour}
          payment_date={payment_date}
          updateData={updateData}
        />
      )}
    </StyledCard>
  );
};

export default withTheme(Card);
