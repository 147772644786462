export const getCategoryFormsNavigateEvent = ({ path, id }) => ({
  action: `job_form_navigate_to_${path}`,
  label: id,
});

export const getCategoryWarrantyOpenEvent = ({ id }) => ({
  action: `job_form_open_warranties`,
  label: id,
});

export const getCategoryFormsSubmitEvent = ({ id }) => ({
  action: "job_submit_form",
  label: id,
});

export const getCategoryFormsOpenEvent = ({ id }) => ({
  action: "job_open_form",
  label: id,
});

export const getCategoryFormsSubmitExperimentEvent = ({ id, variantId }) => ({
  action: `job_submit_form_${variantId}`,
  label: id,
});

export const getCategoryFormsOpenExperimentEvent = ({ id, variantId }) => ({
  action: `job_open_form_${variantId}`,
  label: id,
});

export const getBookingSubmitEvent = ({ kpi_kind }) => ({
  action: "booking_submit",
  label: kpi_kind,
});

export const getBookingOpenEvent = ({ kpi_kind }) => ({
  action: "booking_open",
  label: kpi_kind,
});

export const getInstantBookingChooseJobber = () => ({
  action: `instant_booking_choose_jobber`,
});

export const getInstantBookingOpenPage = () => ({
  action: `instant_booking_open_page`,
});

export const getInstantBookingShowJobberProfile = () => ({
  action: `instant_booking_show_jobber_profile`,
});

export const getInstantBookingShowJob = () => ({
  action: `instant_booking_show_job`,
});

export const getReferralShareClickEvent = () => ({
  action: `referral_press_share`,
});

export const getReferralMenuClickEvent = () => ({
  action: `referral_menu_referral_press`,
});

export const getReferralCopyLinkEvent = () => ({
  action: `referral_copy_press`,
});

export const getSortPressEvent = ({ value }) => ({
  action: `offer_filters_${value}_click`,
});

export const getHomeBannerPressEvent = ({ value }) => ({
  action: `${value}_press`,
});

export const getApplePayButtonPressEvent = () => ({
  action: `apple_pay_button_press`,
});

export const getApplePayButtonAvailableEvent = () => ({
  action: `apple_pay_button_available`,
});

export const getGooglePayButtonPressEvent = () => ({
  action: `google_pay_button_press`,
});

export const getGooglePayButtonAvailableEvent = () => ({
  action: `google_pay_button_available`,
});

export const getGooglePayButtonPaymentAuthorizedEvent = ({ data }) => ({
  action: `google_pay_button_authorized`,
  label: data,
});
