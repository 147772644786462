import { colors } from "../../assets/themes";
import polyglot from "../../utils/polyglot";

export const getCesuState = (state) => {
  if (state === "pending") {
    return (
      <span
        css={`
          color: ${colors.muted};
        `}
      >
        {polyglot.t("common.validation_in_progress")}
      </span>
    );
  }
  if (state === "refused") {
    return (
      <strong
        css={`
          color: ${colors.danger};
        `}
      >
        {polyglot.t("common.refused")}
      </strong>
    );
  }
  if (state === "approved") {
    return (
      <strong
        css={`
          color: ${colors.success};
        `}
      >
        {polyglot.t("common.validated")}
      </strong>
    );
  }
  return null;
};
