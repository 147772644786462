import img1 from "../../assets/images/svg-icons/jobber-selection/1.svg";
import img2 from "../../assets/images/svg-icons/jobber-selection/2.svg";
import img3 from "../../assets/images/svg-icons/jobber-selection/3.svg";
import polyglot from "../../utils/polyglot";

export default [
  {
    img: img1,
    title: polyglot.t("booking.onboarding.jobber_booking"),
    text: polyglot.t("booking.onboarding.jobber_booking_description"),
  },
  {
    img: img2,
    title: polyglot.t("booking.onboarding.warranties"),
    text: polyglot.t("booking.onboarding.warranties_description"),
  },
  {
    img: img3,
    title: polyglot.t("booking.onboarding.confirm_job"),
    text: polyglot.t("booking.onboarding.confirm_job_description"),
  },
];
