import React from "react";
import { Button, Divider } from "../../../components";
import { DIRECT_PLANS } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const Plan = ({ kind, onClick }) => (
  <div className="border rounded p-3">
    <div className="d-flex align-items-start justify-content-between p-">
      <div className="d-flex flex-column align-items-start">
        <p className="h2 font-weight-medium mb-0">{DIRECT_PLANS[kind].title}</p>
        <div className="my-2">
          <span className="font-size-4 text-muted d-block">
            <span className="font-weight-medium text-dark">
              {polyglot.toSmartCurrency(DIRECT_PLANS[kind].price)}
            </span>{" "}
            / mois
          </span>
          <span className="text-muted font-size-2">
            {polyglot.t("yoojo_direct.no_engagement")}
          </span>
        </div>
        <span className="badge badge-pill bg-orange-marketing text-white p-2 d-block">
          {polyglot.t("yoojo_direct.one_month_free")}
        </span>
      </div>
      <img src={DIRECT_PLANS[kind].artwork} alt="" width={70} height={70} />
    </div>
    <Divider.Cell className="my-3" />
    {DIRECT_PLANS[kind].advantages.map((advantage) => (
      <div
        key={`advantage-index-${Math.random()}`}
        className="d-flex align-items-start my-3 font-size-4"
      >
        <i
          style={{ borderRadius: 99, minWidth: 24, minHeight: 24 }}
          className={`${
            DIRECT_PLANS.direct_basic.advantages.find(
              (a) => a.title === advantage.title
            )
              ? "icon-check"
              : "icon-plus"
          }  text-success mr-2 mt-1 d-flex align-items-center justify-content-center`}
        />
        <div className="d-flex flex-column">
          <span>{advantage.title}</span>
          <span className="text-muted font-size-3">{advantage.subtitle}</span>
        </div>
      </div>
    ))}
    {onClick && (
      <Button.Large block className="mt-3" onClick={() => onClick(kind)}>
        {polyglot.t("yoojo_direct.select_plan", {
          plan: DIRECT_PLANS[kind].title,
        })}
      </Button.Large>
    )}
  </div>
);

export default Plan;
