import { Field } from "formik";
import React from "react";
import { Select } from "../../../../components";

const FormSelect = ({
  name,
  options,
  onChange,
  placeholder,
  position,
  ...rest
}) => (
  <Field name={name}>
    {({ field, form }) => (
      <Select
        {...rest}
        position={position}
        value={field.value}
        placeholder={placeholder || options[0]?.label}
        options={options}
        onChange={({ value }) => {
          if (onChange) onChange(value, form);
          form.setFieldTouched(field.name, true);
          form.setFieldValue(field.name, value);
        }}
      />
    )}
  </Field>
);

export default FormSelect;
