import GooglePayButtonPlugin from "@google-pay/button-react/dist/index";
import * as Sentry from "@sentry/react";
import styled, { css } from "styled-components";
import { radius, sizes } from "../assets/themes";
import {
  GOOGLE_PAY_MERCHAND_ID,
  IS_PROD,
  IS_STAGING,
  MANGOPAY_CLIENT_ID,
} from "../utils";
import polyglot from "../utils/polyglot";
import { BUTTON } from "./Styles/variants";
import { useGoogleAnalytics } from "../modules/hooks";
import {
  getGooglePayButtonPaymentAuthorizedEvent,
  getGooglePayButtonPressEvent,
} from "../utils/analytics-events";

const setHeight = ({ size }) => {
  switch (size) {
    case BUTTON.SIZE.LARGE:
      return sizes.size54;
    case BUTTON.SIZE.MEDIUM:
      return sizes.size48;
    default:
      return sizes.size36;
  }
};

const setShape = ({ shape }) => {
  switch (shape) {
    case BUTTON.SHAPE.CIRCLE:
      return radius.circle;
    case BUTTON.SHAPE.PILL:
      return radius.circle;
    default:
      return radius.m;
  }
};

const setPadding = ({ size }) => {
  switch (size) {
    case BUTTON.SIZE.LARGE:
      return "14px";
    case BUTTON.SIZE.MEDIUM:
      return "10px";
    default:
      return "6px";
  }
};

const StyledGooglePayWrapper = styled("div")``;

const GooglePayButton = ({
  block,
  textType = "pay",
  shape,
  size,
  amount,
  onPaymentAuthorized,
  isLoading,
}) => {
  const { sendEvent } = useGoogleAnalytics();
  return (
    <StyledGooglePayWrapper
      isLoading={isLoading}
      css={`
        width: ${block ? "100%" : null};
        ${({ isLoading }) =>
          isLoading &&
          css`
            opacity: 0.5;
            cursor: wait;
            &:active,
            &:hover {
              &:after: {
                content: none;
              }
            }
            button {
              opacity: 0.5;
              background-image: none !important;
              &:active,
              &:hover {
                &:after: {
                  content: none;
                }
              }
              pointer-events: none;
              color: transparent !important;
              position: relative;
              &:after {
                content: "";
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                position: absolute !important;
                border: 2px solid #dbdbdb;
                border-radius: 100px;
                border-right-color: transparent;
                border-top-color: transparent;
                height: 1em;
                width: 1em;
                animation: spinAround 0.5s infinite linear;
              }
            }
          `}
        button {
          padding: ${setPadding({ size })}!important;
          border-radius: ${setShape({ shape })};
        }
      `}
    >
      <GooglePayButtonPlugin
        environment={IS_PROD && !IS_STAGING ? "PRODUCTION" : "TEST"}
        buttonColor="black"
        buttonType={textType}
        buttonSizeMode="fill"
        buttonLocale={polyglot.locale}
        onError={(error) => {
          Sentry.captureException(error);
        }}
        onCancel={(error) => {
          Sentry.captureException(error);
        }}
        onClick={(e) => {
          sendEvent(getGooglePayButtonPressEvent());
          if (isLoading) e.preventDefault();
        }}
        style={{
          width: block ? "100%" : null,
          height: setHeight({ size }),
        }}
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: "CARD",
              parameters: {
                allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
                allowedCardNetworks: ["MASTERCARD", "VISA"],
              },
              tokenizationSpecification: {
                type: "PAYMENT_GATEWAY",
                parameters: {
                  gateway: "whenthen",
                  gatewayMerchantId: MANGOPAY_CLIENT_ID,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: GOOGLE_PAY_MERCHAND_ID,
            merchantName: polyglot.t("common.brand"),
          },
          transactionInfo: {
            totalPriceStatus: "FINAL",
            totalPrice: amount?.toString(),
            currencyCode: polyglot.currencyLong,
            countryCode: polyglot.country.toUpperCase(),
          },
        }}
        onLoadPaymentData={(paymentRequest) => {
          if (onPaymentAuthorized) {
            sendEvent(
              getGooglePayButtonPaymentAuthorizedEvent({
                data: paymentRequest.paymentMethodData.tokenizationData.token,
              })
            );
            onPaymentAuthorized({
              token: paymentRequest.paymentMethodData.tokenizationData.token,
            });
          }
        }}
      />
    </StyledGooglePayWrapper>
  );
};

const Small = (props) => (
  <GooglePayButton size={BUTTON.SIZE.SMALL} {...props} />
);
const Medium = (props) => (
  <GooglePayButton size={BUTTON.SIZE.MEDIUM} {...props} />
);
const Large = (props) => (
  <GooglePayButton size={BUTTON.SIZE.LARGE} {...props} />
);

export default { Small, Medium, Large };
