import React from "react";
import { radius, sizes, spacings } from "../../../assets/themes";
import { Block, FilePicker } from "../../../components";

const UploadItem = ({ onRemove, progressAmount, name, token, file }) => (
  <Block marginBottom={spacings.s} marginRight={spacings.s}>
    <FilePicker
      name={name}
      value={!!token && file}
      progressAmount={progressAmount}
      onRemove={onRemove}
      css={`
        label {
          border-radius: ${radius.l};
        }
        overflow: hidden;
        width: ${sizes.size128};
      `}
    />
  </Block>
);

const TicketChatInputUploads = ({ progress, uploads, onRemove }) => (
  <Block
    display="flex"
    flexWrap="wrap"
    css={`
      margin: ${spacings.s} ${spacings.xs} 0 ${spacings.s};
    `}
  >
    {uploads.map((upload, index) => (
      <UploadItem
        progressAmount={progress || 1}
        key={`file-attachment-${index}`}
        token={upload.token}
        file={upload.file}
        name={`file-attachment-${index}`}
        onRemove={() => onRemove(upload.token)}
      />
    ))}
  </Block>
);

export default TicketChatInputUploads;
