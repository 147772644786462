import polyglot from "../../../utils/polyglot";
import { NB_HOURS_PATH } from "../constants";

const config = {
  multipleJobbersAllowed: true,
  renderPages: (pages) =>
    pages.map((p) => {
      if (p.path === NB_HOURS_PATH) {
        p.title = polyglot.t("categories.how_many_hours_needed");
        return p;
      }
      return p;
    }),
};

export function useCategory3019() {
  return {
    config,
  };
}
