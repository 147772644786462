import { useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow, Stepper } from '../../components';
import polyglot from '../../utils/polyglot';

const SetPricePerHour = ({ goNext, goBack, price_per_hour_min, price_per_hour_max }) => {
  const { values, setFieldValue, isValid } = useFormikContext();
  const selectedJobber = !values.jobber_id ? values.external_jobber : values.jobber;

  return (
    <Container.Small>
      <Flow.Item.Title>
        Quelle rémunération horaire donnez-vous à {selectedJobber?.first_name} ?
      </Flow.Item.Title>
      <div className="d-flex justify-content-center">
        <div style={{ transform: 'scale(1.5)', transformOrigin: 'center' }} className="py-4">
          <Stepper
            name="price_per_hour"
            min={price_per_hour_min}
            max={price_per_hour_max}
            onChange={value => setFieldValue('price_per_hour', value)}
            suffix={polyglot.currency}
            hint="Par heure"
            value={values.price_per_hour}
          />
        </div>
      </div>
      <Flow.Item.Navigation goNext={goNext} goBack={goBack} isValid={isValid} />
    </Container.Small>
  );
};

export default SetPricePerHour;
