import { useFormikContext } from "formik";
import styled from "styled-components";
import { colors, spacings } from "../../../assets/themes";
import { Body16, Icon, List } from "../../../components";
import { LIST } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";
import {
  MAX_NB_HOURS,
  MAX_NB_JOBBERS,
  MIN_NB_HOURS,
  MIN_NB_JOBBERS,
} from "../constants";
import { FormStepper, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const StyledListItem = styled(List.Item)`
  background-color: ${colors.backgroundLight};
  margin-bottom: ${spacings.s};
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Recommendation = ({
  distanceInMeters,
  truck,
  items = [],
  title,
  nbHoursTitle = polyglot.t("categories.job_duration"),
}) => {
  const { values } = useFormikContext();

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <List.Group>
          <FormStepper
            max={MAX_NB_JOBBERS}
            min={MIN_NB_JOBBERS}
            name="nb_jobbers"
            size={LIST.SIZE.LARGE}
          >
            <Body16>{polyglot.t("categories.jobbers_to_book")}</Body16>
          </FormStepper>
          <FormStepper
            name="nb_hours"
            max={MAX_NB_HOURS}
            min={MIN_NB_HOURS}
            step={0.5}
            FieldChildren={polyglot.toDuration(values.nb_hours, true)}
            size={LIST.SIZE.LARGE}
          >
            <Body16>{nbHoursTitle}</Body16>
          </FormStepper>
        </List.Group>
      </StyledFormControl>
      <StyledFormControl>
        {items?.length > 0 &&
          items.map(({ label, value, icon }, i) => (
            <StyledListItem
              key={`recommendation-item-${icon}-${i}`}
              withGutters
              divider={false}
              shape={LIST.SHAPE.ROUND}
              LeftComponent={() => (
                <Icon.Large name={icon} color={colors.primary} />
              )}
              RightComponent={() => (
                <Body16 color={colors.muted}>{value}</Body16>
              )}
            >
              {label}
            </StyledListItem>
          ))}
        {typeof distanceInMeters === "number" && distanceInMeters >= 0 && (
          <StyledListItem
            withGutters
            divider={false}
            shape={LIST.SHAPE.ROUND}
            LeftComponent={() => (
              <Icon.Large name="route" color={colors.primary} />
            )}
            RightComponent={() => (
              <Body16 color={colors.muted}>
                {polyglot.toDistance(distanceInMeters)}
              </Body16>
            )}
          >
            {polyglot.t("categories.move_distance")}
          </StyledListItem>
        )}
        {(truck === true || truck === false) && (
          <StyledListItem
            withGutters
            divider={false}
            shape={LIST.SHAPE.ROUND}
            LeftComponent={() => (
              <Icon.Large name="truck" color={colors.primary} />
            )}
            RightComponent={() => (
              <Body16 color={colors.muted}>
                {truck ? polyglot.t("common.yes") : polyglot.t("common.no")}
              </Body16>
            )}
          >
            {polyglot.t("categories.with_truck")}
          </StyledListItem>
        )}
      </StyledFormControl>
    </>
  );
};
export default Recommendation;
