import { useState } from "react";
import { borderWidth, colors } from "../../../assets/themes";
import { Body16, Tag } from "../../../components";
import { TAG } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";
import DownloadAppScan from "../useSmartphone/DownloadAppScan";
import CesuBtn from "./CesuBtn";

const icon = (
  <svg
    className="svg-scan-barcode"
    width="180"
    height="180"
    viewBox="0 0 180 180"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <rect id="path-1" x="0.7" width="5" height="38.4" rx="2.5" />
    </defs>
    <g id="CRCESU.icon.useSmartphone" fill="none" fillRule="evenodd">
      <g id="CRCESU.ticket" transform="rotate(90 65.5 65.5)">
        <g id="CRCESU">
          <rect
            id="bg"
            fill="#DCEBFF"
            x="3"
            y="3"
            width="144"
            height="76"
            rx="4"
          />
          <path
            d="M7,0.5 L143,0.5 C146.589851,0.5 149.5,3.41014913 149.5,7 L149.5,75 C149.5,78.5898509 146.589851,81.5 143,81.5 L7,81.5 C3.41014913,81.5 0.5,78.5898509 0.5,75 L0.5,7 C0.5,3.41014913 3.41014913,0.5 7,0.5 Z M7,5.5 C6.17157288,5.5 5.5,6.17157288 5.5,7 L5.5,75 C5.5,75.8284271 6.17157288,76.5 7,76.5 L143,76.5 C143.828427,76.5 144.5,75.8284271 144.5,75 L144.5,7 C144.5,6.17157288 143.828427,5.5 143,5.5 L7,5.5 Z"
            id="stroke"
            fill="#0078FA"
            fillRule="nonzero"
          />
          <path
            d="M68,18.5 C68,19.8807119 66.8807119,21 65.5,21 L19.5,21 C18.1192881,21 17,19.8807119 17,18.5 C17,17.1192881 18.1192881,16 19.5,16 L65.5,16 C66.8807119,16 68,17.1192881 68,18.5 Z M49,28.5 C49,29.8807119 47.8807119,31 46.5,31 L19.5,31 C18.1192881,31 17,29.8807119 17,28.5 C17,27.1192881 18.1192881,26 19.5,26 L46.5,26 C47.8807119,26 49,27.1192881 49,28.5 Z M77.5,53.5809649 L95.5,53.5809649 C96.8807119,53.5809649 98,54.700253 98,56.0809649 L98,61.0809649 C98,62.4616768 96.8807119,63.5809649 95.5,63.5809649 L77.5,63.5809649 C76.1192881,63.5809649 75,62.4616768 75,61.0809649 L75,56.0809649 C75,54.700253 76.1192881,53.5809649 77.5,53.5809649 Z"
            id="Shape"
            fill="#0078FA"
          />
          <path
            d="M117,64 L134,64 L134,70 L117,70 L117,64 Z M117,47 L134,47 L134,53 L117,53 L117,47 Z M117,23 L134,23 L134,28 L117,28 L117,23 Z M117,59 L134,59 L134,61 L117,61 L117,59 Z M117,55 L134,55 L134,57 L117,57 L117,55 Z M117,30 L134,30 L134,32 L117,32 L117,30 Z M117,17 L134,17 L134,19 L117,19 L117,17 Z M117,12 L134,12 L134,15 L117,15 L117,12 Z M117,41 L134,41 L134,43 L117,43 L117,41 Z M117,35 L134,35 L134,38 L117,38 L117,35 Z"
            id="Shape"
            fill="#0078FA"
          />
        </g>
      </g>
      <g id="phone" transform="translate(44.5 39)">
        <rect
          id="screen-filter"
          fill="#FFF"
          opacity="0.504"
          y="4.116"
          width="90"
          height="135.051"
        />
        <path
          d="M90,127.442721 C90.0030133,127.493963 90.0045411,127.545604 90.0045411,127.597603 C90.0045411,129.032219 88.8415547,130.195206 87.4069383,130.195206 C86.0019159,130.195206 84.8574325,129.079706 84.8108124,127.68603 L84.8076923,127.68603 L84.8076923,11.9242361 C84.8076923,8.17359931 81.7113595,5.1122449 77.8918393,5.1122449 L65.7234192,5.1122449 C65.5451091,5.65822469 65.4523393,6.56222768 65.4523393,8.05826419 C65.4523393,9.51307707 64.1995803,12.8461538 61.6668362,12.8461538 L29.4718958,12.8461538 C27.0284826,12.8461538 25.6863927,10.4630316 25.6863927,8.05826419 C25.6863927,6.64871697 25.5270763,5.70411001 25.2247135,5.1122449 L12.1081607,5.1122449 C8.30167131,5.1122449 5.19230769,8.16207727 5.19230769,11.9242361 L5.19230769,127.47381 C5.19423239,127.514832 5.19520553,127.556104 5.19520553,127.597603 C5.19520553,129.032219 4.03221916,130.195206 2.59760276,130.195206 C1.19258035,130.195206 0.0480969055,129.079706 0.00147684911,127.68603 L0,127.68603 L0,11.9432087 C0,5.34715667 5.4272231,5.68434189e-14 12.1144277,5.68434189e-14 L77.8855723,5.68434189e-14 C84.576186,5.68434189e-14 90,5.34381374 90,11.9432087 L90,127.442721 L90,127.442721 Z"
          id="phone-stroke"
          fill="#252932"
          fillRule="nonzero"
        />
      </g>
      <g id="crosshair-box">
        <g transform="translate(51.3 105.8)">
          <g id="scanning" transform="translate(1)">
            <mask id="mask-2" fill="#fff">
              <use xlinkHref="#path-1" />
            </mask>
            <use id="scanner" fill="#0078FA" xlinkHref="#path-1" />
          </g>
          <g id="crosshair" fill="#252932" fillRule="nonzero">
            <path
              d="M4.89220992,4.985 L4.89220992,12.4 L4.26325641e-14,12.4 L4.26325641e-14,4.735 C4.26325641e-14,2.19596407 1.84765008,4.26325641e-14 4.33290964,4.26325641e-14 L13.4,4.26325641e-14 L13.4,4.985 L4.89220992,4.985 L4.89220992,4.985 Z M4.5081171,4.35887289 C4.50206124,4.36607038 4.43456653,4.4 4.33290964,4.4 L4.47914687,4.4 C4.48836545,4.38456035 4.49808058,4.37080146 4.5081171,4.35887289 Z M64,4.89220992 L64,7.2475359e-13 L71.665,7.2475359e-13 C74.2040359,7.2475359e-13 76.4,1.84765008 76.4,4.33290964 L76.4,13.4 L71.415,13.4 L71.415,4.89220992 L64,4.89220992 Z M72,4.47914687 C72.0154396,4.48836545 72.0291985,4.49808058 72.0411271,4.5081171 C72.0339296,4.50206124 72,4.43456653 72,4.33290964 L72,4.47914687 Z M4.89220992,33.415 L4.33290964,33.415 C4.7340623,33.415 4.89220992,33.6029612 4.89220992,33.665 L4.89220992,33.415 L4.89220992,33.415 Z M13.4,33.415 L13.4,38.4 L4.33290964,38.4 C1.84765008,38.4 4.26325641e-14,36.2040359 4.26325641e-14,33.665 L4.26325641e-14,26 L4.89220992,26 L4.89220992,33.415 L13.4,33.415 Z M4.33290964,34 C4.43456653,34 4.50206124,34.0339296 4.5081171,34.0411271 C4.49808058,34.0291985 4.48836545,34.0154396 4.47914687,34 L4.33290964,34 Z M71.415,34.0670904 C71.415,33.6659377 71.6029612,33.5077901 71.665,33.5077901 L71.415,33.5077901 L71.415,34.0670904 Z M71.415,25 L76.4,25 L76.4,34.0670904 C76.4,36.5523499 74.2040359,38.4 71.665,38.4 L64,38.4 L64,33.5077901 L71.415,33.5077901 L71.415,25 Z M72,34.0670904 C72,33.9654335 72.0339296,33.8979388 72.0411271,33.8918829 C72.0291985,33.9019194 72.0154396,33.9116346 72,33.9208531 L72,34.0670904 Z"
              id="shape"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const UseSmartphone = ({ app_store_url, google_play_url }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <CesuBtn
      onClick={() => setModalIsOpen(true)}
      TopComponent={() => (
        <Tag.Medium
          kind={TAG.KIND.WARNING}
          css={`
            border: solid ${borderWidth.m} ${colors.background};
          `}
        >
          {polyglot.t("common.recommended")}
        </Tag.Medium>
      )}
    >
      {icon}
      <Body16 strong>{polyglot.t("cesu.scan_with_smartphone")}</Body16>
      <DownloadAppScan
        onClose={() => setModalIsOpen(false)}
        isOpen={modalIsOpen}
        app_store_url={app_store_url}
        google_play_url={google_play_url}
      />
    </CesuBtn>
  );
};
export default UseSmartphone;
