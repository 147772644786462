import { useLayoutEffect, useRef, useState } from "react";
import styled from "styled-components";
import polyglot from "@/utils/polyglot";
import Block from "./Block";
import Button from "./Button";
import { useWindowSize } from "@/modules/hooks";

const StyledText = styled.p`
  margin-bottom: 0;
  word-break: break-word;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: ${({ numberOfLines }) => numberOfLines};
`;

const HiddenText = styled.p`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  white-space: break-spaces;
  word-break: break-word;
  display: block;
  opacity: 0;
`;

const ReadMore = ({ children, numberOfLines = undefined, className }) => {
  const { width } = useWindowSize();
  const [isReadMore, setIsReadMore] = useState(true);
  const [textIsTooLarge, setTextIsTooLarge] = useState(false);
  const textRef = useRef(null);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  useLayoutEffect(() => {
    if (textRef.current) {
      const totalHeight = textRef.current.scrollHeight;
      const lineHeight = parseFloat(
        getComputedStyle(textRef.current).lineHeight
      );
      const maxHeight = lineHeight * numberOfLines;
      setTextIsTooLarge(totalHeight > maxHeight);
    }
  }, [children, numberOfLines, textRef.current?.scrollHeight]);

  return (
    <Block position="relative" className={className}>
      <HiddenText ref={textRef}>{children}</HiddenText>
      <StyledText
        isReadMore={isReadMore}
        numberOfLines={isReadMore ? numberOfLines : undefined}
      >
        {children}
      </StyledText>
      {textIsTooLarge && (
        <Button.Link
          onClick={toggleReadMore}
          css={`
            padding-left: 0;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;
            min-height: 0;
          `}
        >
          {isReadMore
            ? polyglot.t("common.see_more")
            : polyglot.t("common.see_less")}
        </Button.Link>
      )}
    </Block>
  );
};

export default ReadMore;
