import { H1, H2 } from "../../components";
import { useBreakpoints } from "../../modules/hooks";

const PageTitle = ({ children }) => {
  const breakpoint = useBreakpoints();

  return breakpoint.get({
    xs: <H2>{children}</H2>,
    md: <H1>{children}</H1>,
  });
};
export default PageTitle;
