import React, { useState } from "react";
import { colors, sizes, spacings } from "../../../assets/themes";
import { Avatar, List, Block, Icon, Divider } from "../../../components";
import { LIST } from "../../../components/Styles/variants";
import { useToast } from "../../../modules/hooks";
import { formatPhone } from "../../../utils";
import polyglot from "@/utils/polyglot";

const ConciergeItem = ({ name, email, phone, address }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toast = useToast();
  const handleCopy = (phrase, value) => {
    navigator.clipboard.writeText(value);
    toast.remove();
    toast.success(phrase);
  };

  return (
    <div>
      <List.Item
        size={LIST.SIZE.COMPACT}
        RightComponent={() => (
          <Icon.Medium
            name={!isExpanded ? "plus" : "minus"}
            color={colors.muted}
          />
        )}
        css="background-color: transparent!important"
        onClick={() => setIsExpanded((s) => !s)}
        divider={false}
        LeftComponent={() => <Avatar size={sizes.size36} name={name} />}
      >
        <List.Elem.Title strong>{name}</List.Elem.Title>
      </List.Item>
      {isExpanded && (
        <Block marginTop={spacings.s}>
          <List.Item
            shape={LIST.SHAPE.ROUND}
            divider={false}
            onClick={() => {
              handleCopy(polyglot.t("clipboard_copy.email"), email);
            }}
            LeftComponent={() => (
              <Icon.Medium name="copy" color={colors.muted} />
            )}
            size={LIST.SIZE.COMPACT}
          >
            <List.Elem.Title>{email}</List.Elem.Title>
          </List.Item>
          <List.Item
            shape={LIST.SHAPE.ROUND}
            divider={false}
            href={`tel:${phone}`}
            LeftComponent={() => (
              <Icon.Medium name="phone" color={colors.muted} />
            )}
            size={LIST.SIZE.COMPACT}
          >
            <List.Elem.Title>{formatPhone(phone)}</List.Elem.Title>
          </List.Item>
          <List.Item
            shape={LIST.SHAPE.ROUND}
            divider={false}
            onClick={() => {
              handleCopy(polyglot.t("clipboard_copy.address"), address);
            }}
            LeftComponent={() => (
              <Icon.Medium name="copy" color={colors.muted} />
            )}
            size={LIST.SIZE.COMPACT}
          >
            <List.Elem.Title>{address}</List.Elem.Title>
          </List.Item>
        </Block>
      )}
    </div>
  );
};

export default ConciergeItem;
