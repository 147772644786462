import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Body14,
  Body16,
  Button,
  Modal,
  PinCode,
  Spinner,
} from "../../../components";
import { ALERT } from "../../../components/Styles/variants";
import { api } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const EmailVerificationModal = ({
  isOpen,
  onClose,
  email,
  onSubmit,
  name = "email",
  timer: defaultTimer = 60,
  user,
}) => {
  const pinName = "secureCode";
  const [timer, setTimer] = useState(defaultTimer);
  const createSecureCode = useMutation(() => api.post("/secure_codes"));

  useEffect(() => {
    createSecureCode.mutate();
    if (timer > 0) {
      setInterval(() => {
        setTimer((s) => s - 1);
      }, 1000);
    }
  }, []);

  const verifySecureCode = useMutation(
    (params) =>
      api.get(`/secure_codes/${params[pinName].join("")}/verify`, params),
    {
      onSuccess: (_, variables) => {
        onSubmit(name, { email, secure_code: variables[pinName].join("") });
      },
    }
  );

  const handleSubmit = (values) => {
    verifySecureCode.mutate(values);
  };

  const handleChange = (v, bag) => {
    bag.setFieldValue(pinName, v);
    if (bag.initialValues[pinName].length === v.join("").length) {
      bag.submitForm();
    }
  };
  const handleRetry = () => {
    setTimer(defaultTimer);
  };

  return (
    <Modal.XSmall fullScreenOnMobile isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title align="center">
          {polyglot.t("confirm_email.title")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      {!createSecureCode.isLoading ? (
        <Formik
          initialValues={{ [pinName]: ["", "", "", ""] }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, initialValues, submitForm }) => (
            <Form>
              <Modal.Item.Wrapper
                as={Block}
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Body16 align="center">
                  {polyglot.t("confirm_email.body")}&nbsp;
                  <strong>{user.email}</strong>
                </Body16>
                <Block marginY={spacings.ml}>
                  <PinCode
                    values={values[pinName]}
                    autoFocus
                    onChange={(v) =>
                      handleChange(v, {
                        initialValues,
                        submitForm,
                        setFieldValue,
                      })
                    }
                  />
                </Block>
                <Body14 color={colors.muted} align="center">
                  {polyglot.t("confirm_email.havent_received_anything")}
                </Body14>
                <Button.Link disabled={timer > 0} onClick={handleRetry}>
                  {polyglot.t("confirm_email.resend_email")}{" "}
                  {timer > 0 && `(${timer}s)`}
                </Button.Link>

                {verifySecureCode.isError && (
                  <Block marginTop={spacings.m}>
                    <Alert.Low kind={ALERT.KIND.DANGER}>
                      {verifySecureCode.error?.response?.data?.error_message}
                    </Alert.Low>
                  </Block>
                )}

                <Block marginTop={spacings.m}>
                  <Button.Medium
                    type="submit"
                    isLoading={verifySecureCode.isLoading}
                    block
                    disabled={
                      values[pinName]?.join("").length !==
                      initialValues[pinName]?.length
                    }
                  >
                    {polyglot.t("common.continue")}
                  </Button.Medium>
                </Block>
              </Modal.Item.Wrapper>
            </Form>
          )}
        </Formik>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={spacings.m}
        >
          <Spinner.Medium />
        </Block>
      )}
    </Modal.XSmall>
  );
};

export default EmailVerificationModal;
