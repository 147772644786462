import { useState, useRef, useEffect, useCallback } from "react";
import polyglot from "@/utils/polyglot";

const TOTAL_AMPLITUDES = 35;
const NOISE_THRESHOLD = 0.01;
const AMPLIFICATION_FACTOR = 1.2;

const useMediaRecorder = (onStop) => {
  const [isRecording, setIsRecording] = useState(false);
  const [amplitude, setAmplitude] = useState(0);
  const [amplitudes, setAmplitudes] = useState([]);
  const startTimeRef = useRef();
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const shouldSubmitRef = useRef(true);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const dataArrayRef = useRef(null);
  const animationIdRef = useRef(null);

  const calculateRMS = (dataArray) => {
    let sum = 0;
    for (let i = 0; i < dataArray.length; i += 1) {
      const value = dataArray[i] / 128 - 1; // Normalize to [-1, 1]
      sum += value * value;
    }
    const rms = Math.sqrt(sum / dataArray.length);
    return rms;
  };

  const normalizeAmplitude = (rms) => {
    const adjustedRMS = Math.max(0, rms - NOISE_THRESHOLD);
    // Convert RMS to dB, and normalize to [0, 1]
    const db = 20 * Math.log10(adjustedRMS + 1e-6); // Add small value to avoid log(0)
    const normalizedDb = (db + 60) / 60; // Assume -60dB as noise floor
    // Apply amplification and use an exponential factor to emphasize louder sounds
    const amplifiedDb =
      normalizedDb >= 0 ? (normalizedDb * AMPLIFICATION_FACTOR) ** 1.5 : 0;
    return Math.min(Math.max(amplifiedDb, 0), 1); // Limit to [0, 1]
  };

  const calculateAmplitude = () => {
    analyserRef.current.getByteTimeDomainData(dataArrayRef.current);
    const rms = calculateRMS(dataArrayRef.current);
    const normalizedAmplitude = normalizeAmplitude(rms);
    setAmplitude(normalizedAmplitude);
    setAmplitudes((prev) => [...prev, normalizedAmplitude]);
    animationIdRef.current = requestAnimationFrame(calculateAmplitude);
  };

  // Create refs for state variables
  const amplitudesRef = useRef([]);

  // Update refs whenever state changes
  useEffect(() => {
    amplitudesRef.current = amplitudes;
  }, [amplitudes]);

  const getAmplitudes = useCallback(() => {
    const total = TOTAL_AMPLITUDES;
    const amps = amplitudesRef.current;
    const ampLength = amps.length;

    if (ampLength === 0) {
      // If no amplitudes recorded, return an array of zeros
      return new Array(total).fill(0);
    }

    const result = [];

    if (ampLength < total) {
      // Need to increase the number of amplitudes
      for (let i = 0; i < total; i += 1) {
        const index = (i / (total - 1)) * (ampLength - 1);
        const lowerIndex = Math.floor(index);
        const upperIndex = Math.ceil(index);

        if (lowerIndex === upperIndex) {
          result.push(amps[lowerIndex]);
        } else {
          // Linear interpolation between amplitudes
          const weight = index - lowerIndex;
          const value =
            amps[lowerIndex] * (1 - weight) + amps[upperIndex] * weight;
          result.push(value);
        }
      }
    } else if (ampLength > total) {
      // Need to reduce the number of amplitudes
      const factor = (ampLength - 1) / (total - 1);
      for (let i = 0; i < total; i += 1) {
        const index = i * factor;
        const lowerIndex = Math.floor(index);
        result.push(amps[lowerIndex]);
      }
    } else {
      // ampLength === total
      return amps;
    }

    return result;
  }, []);

  const handleStop = useCallback(() => {
    if (shouldSubmitRef.current) {
      const audioBlob = new Blob(audioChunksRef.current, {
        type: mediaRecorderRef.current.mimeType,
      });
      const currentStartTime = startTimeRef.current;
      const duration = (Date.now() - currentStartTime) / 1000;
      onStop(audioBlob, {
        duration,
        amplitudes: getAmplitudes(),
      });
    }

    mediaRecorderRef.current.stream
      .getTracks()
      .forEach((track) => track.stop());

    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    cancelAnimationFrame(animationIdRef.current);
    setAmplitude(0);

    // Reset startTime after using it
    startTimeRef.current = null;
  }, [getAmplitudes, onStop]);

  const startAmplitudeAnimation = (stream) => {
    audioContextRef.current = new (window.AudioContext ||
      window.webkitAudioContext)();
    const source = audioContextRef.current.createMediaStreamSource(stream);
    analyserRef.current = audioContextRef.current.createAnalyser();
    analyserRef.current.fftSize = 2048;
    source.connect(analyserRef.current);
    const bufferLength = analyserRef.current.fftSize;
    dataArrayRef.current = new Uint8Array(bufferLength);
    calculateAmplitude(bufferLength);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false,
      });
      let mimeType = "audio/mp4";
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = "audio/ogg";
      }
      if (!MediaRecorder.isTypeSupported(mimeType)) {
        mimeType = "audio/webm";
      }
      mediaRecorderRef.current = new MediaRecorder(stream, { mimeType });
      await mediaRecorderRef.current.start();
      startTimeRef.current = Date.now();
      setIsRecording(true);
      setAmplitudes([]);

      audioChunksRef.current = [];
      shouldSubmitRef.current = true;

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = handleStop;

      startAmplitudeAnimation(stream);
    } catch (err) {
      alert(polyglot.t("chat.microphone_is_required"));
    }
  };

  const stopRecording = (shouldSubmit = true) => {
    if (mediaRecorderRef.current) {
      shouldSubmitRef.current = shouldSubmit;
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
    // Do not reset startTime here
  };

  useEffect(
    () => () => {
      if (
        mediaRecorderRef.current &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        mediaRecorderRef.current.stop();
      }

      if (audioContextRef.current) {
        audioContextRef.current.close();
        audioContextRef.current = null;
      }
      cancelAnimationFrame(animationIdRef.current);
    },
    []
  );

  return {
    isRecording,
    amplitude,
    startTime: startTimeRef.current,
    startRecording,
    stopRecording,
    getAmplitudes,
  };
};

export default useMediaRecorder;
