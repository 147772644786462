import React from 'react';
import Main from './Main';

const Field = ({ value, label, children, className, placeholder, ...rest }) => (
  <Main {...rest} style={{ minHeight: 72, ...rest.style }} className={`py-3 ${className}`}>
    {children || (
      <React.Fragment>
        {value ? (
          <React.Fragment>
            <span className="d-block text-muted">{label}</span>
            <p className="mb-0 mt-2">{value}</p>
          </React.Fragment>
        ) : (
          <span className="d-block text-muted">{placeholder}</span>
        )}
      </React.Fragment>
    )}
  </Main>
);

export default Field;
