import React from "react";
import BaseApp from "../modules/BaseApp";
import PaymentMethodsInput from "../pages/PaymentMethodsInput";

const PaymentMethodsApp = (props, rails) => () =>
  (
    <BaseApp exclude={["AskService"]}>
      <PaymentMethodsInput {...props} />
    </BaseApp>
  );

export default PaymentMethodsApp;
