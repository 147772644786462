import { useMutation } from "@tanstack/react-query";
import { api } from "../../utils";

export const getTaxCredit = ({ price_per_hour, nb_hours }) =>
  api.get(`/cesu/simulate_taxes`, { params: { price_per_hour, nb_hours } });

export const useGetTaxCredit = () =>
  useMutation((values) =>
    api.get(`/cesu/simulate_taxes`, {
      params: {
        price_per_hour: values.price_per_hour,
        nb_hours: values.nb_hours,
      },
    })
  );
