import React from "react";
import BaseApp from "../modules/BaseApp";
import ZipCodeInput from "../pages/ZipCodeInput";

const ZipCodeApp = (props, rails) => () =>
  (
    <BaseApp>
      <ZipCodeInput {...props} />
    </BaseApp>
  );

export default ZipCodeApp;
