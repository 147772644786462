import { useHistory } from "react-router-dom";
import { Block, Button } from "@/components";
import JobberItem from "./JobberItem";
import polyglot from "@/utils/polyglot";
import { BUTTON } from "@/components/Styles/variants";
import { spacings } from "@/assets/themes";

const JobbersGrid = ({ block, hideJobbersSeeMore = false }) => {
  const history = useHistory();

  const handleClick = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("category", block.category_id);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Block spaceY={spacings.s}>
      <Block
        display="grid"
        gridCols={{ xs: 2, xl: 4 }}
        gap={{ xs: spacings.s, md: spacings.m }}
      >
        {block.data?.map((jobber, i) => (
          <JobberItem
            {...jobber}
            key={`jobber-grid-${block.category_id}-${i}`}
          />
        ))}
      </Block>
      {!hideJobbersSeeMore && block?.category_id && (
        <Button.Small kind={BUTTON.KIND.SECONDARY} onClick={handleClick}>
          {polyglot.t("common.see_more")}
        </Button.Small>
      )}
    </Block>
  );
};

export default JobbersGrid;
