import { Field, useField, useFormikContext } from "formik";
import styled from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import { Block, Checkbox, List } from "@/components";
import { LIST } from "@/components/Styles/variants";

const StyledListItem = styled(List.Item)`
  border: solid ${borderWidth.m}
    ${({ checked }) => (checked ? colors.primary : colors.border)};
`;

const useCheckboxValue = (name) => {
  const { setFieldValue } = useFormikContext();
  const field = useField(name);
  return (value) => {
    const currValue = field[0]?.value || [];
    const checked = currValue?.includes(value) || false;
    let newValue;
    if (!checked) {
      newValue = [...currValue, value];
    } else {
      newValue = currValue?.filter((v) => v !== value);
    }
    setFieldValue(name, newValue);
    return newValue;
  };
};

const FormHorizontalCardCheckboxes = ({
  name,
  options,
  children,
  onChange,
  imageSize = sizes.size96,
}) => {
  const setCheckboxValue = useCheckboxValue(name);

  return (
    <>
      {options.map(({ value, label, subtitle, image }) => (
        <Field
          name={name}
          type="checkbox"
          value={value}
          key={`option-key-${name}-${value}`}
        >
          {({ field, form }) => (
            <Block marginBottom={spacings.s}>
              <StyledListItem
                shape={LIST.SHAPE.ROUND}
                checked={field.checked}
                withGutters
                divider={false}
                LeftComponent={() => (
                  <img
                    alt=""
                    src={image}
                    style={{
                      objectFit: "cover",
                      width: imageSize,
                      height: imageSize,
                      borderRadius: radius.m,
                    }}
                  />
                )}
                onClick={() => {
                  const newValues = setCheckboxValue(value);
                  if (onChange) onChange(newValues);
                  form.setFieldTouched(name, true);
                }}
                RightComponent={() => (
                  <Checkbox
                    tabIndex={-1}
                    {...field}
                    css={`
                      pointer-events: none;
                    `}
                  />
                )}
              >
                <List.Elem.Title strong>{label}</List.Elem.Title>
                <List.Elem.Subtitle>{subtitle}</List.Elem.Subtitle>
                {children}
              </StyledListItem>
            </Block>
          )}
        </Field>
      ))}
    </>
  );
};

export default FormHorizontalCardCheckboxes;
