import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import TaxesBG from "@/assets/images/taxes/background.svg";
import { colors, radius, sizes, spacings } from "@/assets/themes";
import {
  Alert,
  Avatar,
  Block,
  Body14,
  Body16,
  Button,
  Col,
  ConditionnalWrapper,
  Divider,
  H1,
  H5,
  Icon,
  Info,
  Link,
  List,
  NoResult,
  Row,
  Tag,
  Toggle,
} from "@/components";
import { ALERT, LIST, TAG } from "@/components/Styles/variants";
import { DashboardContext } from "@/modules/contexts";
import { useBreakpoints } from "@/modules/hooks";
import {
  useGetTaxes,
  usePutAutomaticDeclarative,
} from "@/modules/routes/dashboard-routes";
import UserPreview from "@/pages-admin/UserPreview";
import { JOB, JOBBER } from "@/pages-admin/UserPreview/config";
import { formatDate } from "@/utils";
import polyglot from "@/utils/polyglot";
import StepTitle from "../categoryForms/common/StepTitle";
import { ACCOUNT_TAXES_PAY_PATH } from "./config/routes";
import TaxesCreditSkeleton from "./skeletons/TaxesCreditSkeleton";
import TaxesModal from "./taxesCredit/EditTaxesAccountModal";

const StyledTaxesBox = styled.div`
  border-radius: ${radius.m};
  background-color: ${colors.backgroundLight};
  padding: ${spacings.m};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(${TaxesBG});
  background-position: center;
  background-size: cover;
  min-height: ${sizes.size128};
  justify-content: center;
`;

const StyledProfileBox = styled.div`
  border-radius: ${radius.m};
  background-color: ${colors.backgroundLight};
  padding: ${spacings.m} 0 ${spacings.s} 0;
`;

const StyledJobberItemBox = styled.div`
  border-radius: ${radius.m};
  background-color: ${colors.backgroundLight};
  padding-bottom: ${spacings.m};
`;

const TaxesCredit = () => {
  const { isLoading, data, isSuccess } = useGetTaxes();
  const putAutomaticDeclartive = usePutAutomaticDeclarative();
  const { public_pages } = useContext(DashboardContext);
  const [declarative, setDeclarative] = useState(null);
  const [taxesAccountModalIsOpen, setTaxesAccountModalIsOpen] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const missingData =
    !data?.user?.sex ||
    !data?.user?.first_name ||
    !data?.user?.last_name ||
    !data?.user?.date_of_birth ||
    !data?.user?.address;

  useEffect(() => {
    if (isSuccess) {
      setDeclarative(data.automatic_declarative);
    }
  }, [isSuccess]);

  const handleTaxesAccountModal = (isOpen) => {
    setTaxesAccountModalIsOpen(isOpen);
  };

  const handleDeclarative = () => {
    putAutomaticDeclartive.mutate({ automatic_declarative: !declarative });
    setDeclarative(!declarative);
  };

  const handleTaxPay = (id) => {
    history.push(ACCOUNT_TAXES_PAY_PATH.replace(":id", id));
  };

  return (
    <div>
      {!isLoading && data ? (
        <div>
          <apple-pay-button role="button" />
          <Block marginBottom={{ xs: spacings.m, lg: 0 }}>
            <StepTitle>{polyglot.t("routes.tax_credit")}</StepTitle>
            <Body16>{polyglot.t("taxes.subtitle")}</Body16>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link href={public_pages.tax_credit}>
              {polyglot.t("taxes.simulate_tax_credit")}
            </Link>
          </Block>
          <Row gutter={{ xs: 0, lg: spacings.l }}>
            <Col size={{ xs: 12, lg: 5 }} order={{ xs: "0", lg: "1" }}>
              {breakpoints.get({ xs: false, lg: true }) && (
                <List.Header>&nbsp;</List.Header>
              )}
              <Block marginBottom={spacings.m}>
                <StyledTaxesBox>
                  <H5 align="center">
                    {polyglot.t("taxes.tax_credit_year", {
                      year: new Date().getFullYear(),
                    })}
                  </H5>

                  <ConditionnalWrapper
                    condition={data.tax_credit_current_year_pdf}
                    wrapper={(children) => (
                      <Link
                        color={colors.body}
                        href={data.tax_credit_current_year_pdf}
                        target="_blank"
                      >
                        {children}
                      </Link>
                    )}
                  >
                    <Block
                      display="inline-flex"
                      alignItems="center"
                      gap={spacings.s}
                    >
                      <H1 align="center">
                        +
                        {polyglot.toSmartCurrency(
                          data.tax_credit_current_year,
                          {
                            noDecimal: true,
                          }
                        )}
                      </H1>
                      {data.tax_credit_current_year_pdf && (
                        <Icon.Large name="download" />
                      )}
                    </Block>
                  </ConditionnalWrapper>

                  <Block
                    marginTop={spacings.s}
                    display="inline-flex"
                    alignItems="center"
                    flexDirection="column"
                  >
                    <Body16 align="center" color={colors.muted}>
                      {polyglot.t("taxes.year_n", {
                        n: new Date().getFullYear() - 1,
                      })}
                    </Body16>

                    <ConditionnalWrapper
                      condition={data.tax_credit_last_year_pdf}
                      wrapper={(children) => (
                        <Link
                          color={colors.muted}
                          href={data.tax_credit_last_year_pdf}
                          target="_blank"
                        >
                          {children}
                        </Link>
                      )}
                    >
                      <Block
                        display="inline-flex"
                        alignItems="center"
                        gap={spacings.xs}
                        color={colors.muted}
                      >
                        <Body16 strong align="center">
                          +
                          {polyglot.toSmartCurrency(data.tax_credit_last_year, {
                            noDecimal: true,
                          })}
                        </Body16>

                        {data.tax_credit_last_year_pdf && (
                          <Icon.Medium name="download" />
                        )}
                      </Block>
                    </ConditionnalWrapper>
                  </Block>
                </StyledTaxesBox>
              </Block>

              <Block marginBottom={spacings.m}>
                {missingData && (
                  <Block marginBottom={spacings.m}>
                    <Alert.Low kind={ALERT.KIND.WARNING}>
                      {polyglot.t("taxes.profile_not_completed")}
                    </Alert.Low>
                  </Block>
                )}

                <List.Item
                  divider={false}
                  shape={LIST.SHAPE.ROUND}
                  withGutters
                  onClick={missingData ? null : () => handleDeclarative()}
                  css={`
                    background-color: ${colors.backgroundLight};
                  `}
                  RightComponent={() => (
                    <Toggle disabled={missingData} checked={declarative} />
                  )}
                >
                  <Body16 strong>{polyglot.t("taxes.one_click_tax")}</Body16>
                  <Body14 color={colors.muted}>
                    {polyglot.t("taxes.one_click_tax_descripion")}
                  </Body14>
                </List.Item>
              </Block>
              <Block marginBottom={spacings.m}>
                <StyledProfileBox>
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    gap={spacings.s}
                    paddingX={spacings.m}
                  >
                    <H5>{polyglot.t("common.contact_details")}</H5>
                    <Button.Link
                      onClick={() => handleTaxesAccountModal(true)}
                      css={`
                        padding-left: 0;
                        padding-right: 0;
                      `}
                    >
                      {polyglot.t("common.edit")}
                    </Button.Link>
                    <TaxesModal
                      initialValues={data.user}
                      isOpen={taxesAccountModalIsOpen}
                      onClose={() => handleTaxesAccountModal(false)}
                    />
                  </Block>
                  <List.Group>
                    <List.Item
                      size={LIST.SIZE.COMPACT}
                      withGutters
                      RightComponent={() => (
                        <Body16 color={colors.muted} align="right">
                          {!data.user.sex === 1 && "-"}
                          {data.user.sex === 1 && polyglot.t("common.sir")}
                          {data.user.sex === 2 && polyglot.t("common.madam")}
                        </Body16>
                      )}
                    >
                      {polyglot.t("common.sex")}
                    </List.Item>
                    <List.Item
                      size={LIST.SIZE.COMPACT}
                      withGutters
                      RightComponent={() => (
                        <Body16 color={colors.muted} align="right">
                          {data.user.first_name || "-"}
                        </Body16>
                      )}
                    >
                      {polyglot.t("common.first_name")}
                    </List.Item>
                    <List.Item
                      size={LIST.SIZE.COMPACT}
                      withGutters
                      RightComponent={() => (
                        <Body16 color={colors.muted} align="right">
                          {data.user.last_name || "-"}
                        </Body16>
                      )}
                    >
                      {polyglot.t("common.last_name")}
                    </List.Item>
                    <List.Item
                      size={LIST.SIZE.COMPACT}
                      withGutters
                      RightComponent={() => (
                        <Body16 color={colors.muted} align="right">
                          {data.user.date_of_birth
                            ? formatDate(data.user.date_of_birth, "DD/MM/YYYY")
                            : "-"}
                        </Body16>
                      )}
                    >
                      {polyglot.t("common.date_of_birth")}
                    </List.Item>
                    <List.Item
                      size={LIST.SIZE.COMPACT}
                      withGutters
                      RightComponent={() => (
                        <Body16 color={colors.muted} align="right">
                          {data.user.address || "-"}
                        </Body16>
                      )}
                    >
                      {polyglot.t("common.address")}
                    </List.Item>
                  </List.Group>
                </StyledProfileBox>
              </Block>
              <StyledProfileBox
                css={`
                  padding-bottom: ${spacings.xs};
                  position: sticky;
                  top: calc(var(--nav-height) + ${spacings.m});
                `}
              >
                <Block
                  display="flex"
                  justifyContent="space-between"
                  gap={spacings.s}
                  paddingX={spacings.m}
                >
                  <H5>{polyglot.t("common.need_help")}</H5>
                  <Link href={public_pages.faq} target="_blank">
                    {polyglot.t("common.see_more")}
                  </Link>
                </Block>
                <List.Group>
                  {data.zendesk_articles.map((article, i) => (
                    <List.Item
                      key={`referral-zendesk-article-${i}`}
                      chevron
                      withGutters
                      href={article.url}
                      target="_blank"
                    >
                      {article.title}
                    </List.Item>
                  ))}
                </List.Group>
              </StyledProfileBox>
            </Col>
            <Col size={{ xs: 12, lg: 7 }}>
              <List.Header>
                <Block display="flex" alignItems="center">
                  {polyglot.t("taxes.eligible_job")}
                  <Info>{polyglot.t("taxes.eligible_job_description")}</Info>
                </Block>
              </List.Header>
              <Block display="flex" flexDirection="column" gap={spacings.m}>
                {data.offers.length > 0 ? (
                  <>
                    {data.offers.map((offer) => (
                      <StyledJobberItemBox>
                        <List.Item
                          withGutters
                          LeftComponent={() => (
                            <UserPreview kind={JOBBER} id={offer.jobber.id}>
                              <Link href={offer.jobber.url}>
                                <Avatar src={offer.jobber.avatar} />
                              </Link>
                            </UserPreview>
                          )}
                          divider={false}
                          RightComponent={() => (
                            <Body16 color={colors.muted}>
                              {polyglot.toSmartCurrency(offer.price, {
                                noDecimal: true,
                              })}
                            </Body16>
                          )}
                        >
                          <Block
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                          >
                            <UserPreview kind={JOBBER} id={offer.jobber.id}>
                              <Link color={colors.body} href={offer.jobber.url}>
                                <Body16 strong>
                                  {offer.jobber.first_name}
                                </Body16>
                              </Link>
                            </UserPreview>
                            <UserPreview kind={JOB} id={offer.job.id}>
                              <Link
                                href={offer.job.url}
                                color={colors.muted}
                                css={`
                                  margin-bottom: ${spacings.xs};
                                `}
                              >
                                <Body14
                                  css={`
                                    font-weight: 400;
                                  `}
                                >
                                  {offer.job.title}&nbsp;•&nbsp;
                                  {formatDate(offer.job.date, "DD/MM/YYYY")}
                                </Body14>
                              </Link>
                            </UserPreview>
                            {!offer.declarable && offer.declared_at && (
                              <Block marginTop={spacings.xs}>
                                <Tag.Small kind={TAG.KIND.SUCCESS}>
                                  {polyglot.t("common.declared")}
                                </Tag.Small>
                              </Block>
                            )}
                            {!offer.declarable && !offer.declared_at && (
                              <Block marginTop={spacings.xs}>
                                <Tag.Small kind={TAG.KIND.MUTED}>
                                  {polyglot.t("common.expired_f")}
                                </Tag.Small>
                              </Block>
                            )}
                          </Block>
                        </List.Item>
                        <Block
                          marginBottom={spacings.sm}
                          // marginTop={spacings.xs}
                          width="100%"
                        >
                          <Divider.Cell />
                        </Block>
                        <Block
                          display="flex"
                          flexDirection="column"
                          alignItems="stretch"
                          gap={spacings.s}
                          paddingX={spacings.m}
                        >
                          {offer.declarable ? (
                            <>
                              <Button.Medium
                                onClick={() => handleTaxPay(offer.id)}
                                disabled={missingData}
                              >
                                {polyglot.t("taxes.declare_my_jobber")}
                              </Button.Medium>
                              <Body14 color={colors.muted} align="center">
                                {polyglot.t("taxes.declare_online_until_date", {
                                  date: formatDate(
                                    offer.declarable_last_date,
                                    "DD/MM/YYYY"
                                  ),
                                })}
                              </Body14>
                            </>
                          ) : (
                            <Body16 color={colors.muted} align="center">
                              {offer.declared_at &&
                                polyglot.t("taxes.declared_on_date", {
                                  date: formatDate(
                                    offer.declared_at,
                                    "DD/MM/YYYY"
                                  ),
                                })}
                              &nbsp;•&nbsp;
                              <Link
                                css={`
                                  display: inline-block;
                                `}
                                to={{
                                  pathname: `/account/wallet/${offer.id}/details_offer`,
                                  state: { background: location },
                                }}
                              >
                                {polyglot.t("jobs.see_transaction_details")}
                              </Link>
                            </Body16>
                          )}
                        </Block>
                      </StyledJobberItemBox>
                    ))}
                  </>
                ) : (
                  <NoResult title="Aucune demande éligible" />
                )}
              </Block>
            </Col>
          </Row>
        </div>
      ) : (
        <TaxesCreditSkeleton />
      )}
    </div>
  );
};
export default TaxesCredit;
