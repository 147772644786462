import { useLayoutEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { colors, radius, spacings } from "@/assets/themes";
import { Block, Body14, Icon, Progress } from "@/components";

const activeStyle = css`
  &:before {
    content: "";
    z-index: -1;
    position: absolute;
    left: calc(${spacings.s} * -1);
    right: calc(${spacings.s} * -1);
    border-radius: ${radius.s};
    bottom: 0;
    top: 0;
    background-color: ${colors.backgroundLight};
  }
  &:active:before {
    opacity: 0.5;
  }
`;

const RateWrapper = styled.div`
  position: relative;
  &:hover {
    ${activeStyle};
  }
  ${({ active }) => active && activeStyle}
`;

const RatesProgression = ({ rates_count_for_rate, activeRate, onChange }) => {
  const [maxWidth, setMaxWidth] = useState(0);
  const refsArray = useRef([]);

  const totalRates = Object.values(rates_count_for_rate).reduce(
    (acc, rate) => acc + rate,
    0
  );

  useLayoutEffect(() => {
    const calculateMaxWidth = () => {
      const widths = refsArray.current.map((ref) => {
        if (ref) {
          return ref.getBoundingClientRect().width;
        }
        return 0;
      });
      const max = Math.max(...widths);
      setMaxWidth(max);
    };

    calculateMaxWidth();
  }, [rates_count_for_rate]);

  const handleClick = (rate) => {
    if (activeRate === rate) {
      if (onChange) onChange({ rate: null });
    } else if (onChange) onChange({ rate });
  };

  return (
    <div>
      {Object.entries(rates_count_for_rate).map(([key, value], i, arr) => (
        <RateWrapper
          type="button"
          key={`rate-progress-wrapper-${key}`}
          active={activeRate === (i - arr.length) * -1}
          onClick={() => handleClick((i - arr.length) * -1)}
        >
          <Block
            key={`rate-progress-index-${key}`}
            display="flex"
            alignItems="center"
            gap={spacings.xs}
            paddingY={spacings.xs}
          >
            <Body14 strong>
              {(i - arr.length) * -1}
              <Icon.Small
                name="star-solid"
                css={`
                  margin-left: ${spacings.xxs};
                `}
              />
            </Body14>
            <Block flex="1">
              <Progress.Bar
                value={(value / totalRates) * 100}
                css={`
                  width: 100%;
                  border-radius: 99px;
                  &[role="progressbar"] {
                    &:before {
                      background-color: ${colors.body};
                    }
                  }
                `}
              />
            </Block>
            <Body14
              strong
              ref={(el) => {
                refsArray.current[i] = el;
              }}
              color={colors.muted}
              css={`
                width: ${maxWidth ? `${maxWidth}px` : "inherit"};
              `}
            >
              {value}
            </Body14>
          </Block>
        </RateWrapper>
      ))}
    </div>
  );
};

export default RatesProgression;
