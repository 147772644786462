import moment from "moment";
import capitalize from "./capitalize";

moment.locale("fr");
export const getWeeklyOptions = () =>
  moment.weekdays(true).map((key, index) => ({
    value: moment()
      .locale("en")
      .weekday(index + 1)
      .format("dddd")
      .toLowerCase(),
    label: `Tous les ${capitalize(key)}s`,
  }));

export const getMonthlyOptions = () =>
  new Array(31).fill("").map((_, index) => ({
    value: index + 1,
    label: `Tous les ${index + 1} du mois`,
  }));

export const getTotalPricing = ({
  urssaf,
  employer_part,
  employee_part,
  deduction,
  nb_hours,
  price_per_hour,
}) =>
  urssaf
    ? employer_part + employee_part - deduction + nb_hours * price_per_hour
    : nb_hours * price_per_hour;

export default {
  direct: { getMonthlyOptions, getWeeklyOptions, getTotalPricing },
};
