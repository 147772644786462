import React, { useEffect, useState, useRef } from "react";
import { colors } from "../../assets/themes";
import { Body16, Icon, Input } from "../../components";
import { useDebounce, useOnClickOutside } from "../../modules/hooks";
import { useGetUserData } from "../../modules/routes/user-preview-routes";
import UserPreview from "../UserPreview";
import polyglot from "@/utils/polyglot";

const MIN_TERM_LENGTH = 5;

const AdminVerifyInput = ({
  name = "",
  error,
  onChange,
  value: initialValue = "",
  kind,
  placeholder = `${polyglot.t("admin.please_enter_id")}...`,
}) => {
  const inputRef = useRef();
  const [popoverRef, setPopoverRef] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [term, setTerm] = useState("");
  const debouncedTerm = useDebounce(term);
  const popoverElement = popoverRef?.state?.elements.popper;
  const searchData = useGetUserData(
    { id: debouncedTerm, kind },
    {
      retry: false,
      shadow: true,
      params: { is_offseted: true },
      enabled: false,
    }
  );
  const successId = searchData.isSuccess ? searchData.data?.id : null;

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (onChange) onChange(val);
    setTerm(val);
  };

  const handleFocus = () => {
    if (initialValue) setTerm(initialValue);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickOutside = (e) => {
    if (popoverElement) {
      const isOutside =
        !popoverElement?.contains(e.target) &&
        !inputRef.current.contains(e.target);
      if (isOutside) {
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  useOnClickOutside({ current: popoverElement }, handleClickOutside);

  const handleInputKeyDown = (e) => {
    if (e.key === "Tab") {
      handleClose();
    }
  };

  const handleBlur = () => {
    // prevent open when popover dont exist and we blur the input
    if (!popoverElement) {
      handleClose();
    }
  };

  useEffect(() => {
    if (debouncedTerm?.length >= MIN_TERM_LENGTH && isOpen) {
      searchData.refetch();
    }
  }, [debouncedTerm]);

  return (
    <UserPreview
      kind={kind}
      id={successId}
      ref={setPopoverRef}
      hideAdminCursor
      onClose={handleClose} // when trigger popper instantClose close only
      isOpen={successId && isOpen}
    >
      <Input
        onChange={handleInputChange}
        placeholder={placeholder}
        ref={inputRef}
        name={name}
        error={error}
        onKeyDown={handleInputKeyDown}
        onFocus={handleFocus}
        onBlur={handleBlur}
        value={term || initialValue || ""}
        RightComponent={() =>
          searchData.isSuccess ? (
            <Icon.Large name="check-circle-solid" color={colors.success} />
          ) : (
            <Icon.Large name="search" color={colors.muted} />
          )
        }
        isLoading={searchData.isFetching}
      />
      {searchData.isError && (
        <Body16 color={colors.danger}>{debouncedTerm} n'existe pas</Body16>
      )}
    </UserPreview>
  );
};

export default AdminVerifyInput;
