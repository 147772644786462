import polyglot from "../../../../utils/polyglot";

export const getPaymentSummaryItems = (
  {
    price,
    fees,
    taxes,
    fees_discount = 0,
    wallet,
    cesu,
    voucher,
    voucher_code,
  },
  label = polyglot.t("transaction_details.jobber_price")
) => [
  {
    label,
    amount: price,
  },
  {
    label: polyglot.t("common.fees"),
    amount: fees,
  },

  ...(taxes > 0
    ? {
        label: polyglot.t("common.taxes"),
        amount: taxes,
      }
    : []),

  ...(wallet + fees_discount > 0
    ? {
        label: polyglot.t("wallet.pot"),
        amount: -(wallet + fees_discount),
      }
    : []),

  ...(cesu > 0
    ? {
        label: polyglot.t("wallet.cesu"),
        amount: -cesu,
      }
    : []),

  ...(voucher_code
    ? {
        label: voucher_code,
        amount: -voucher,
      }
    : []),
];
