import { useSelector } from "react-redux";
import { colors, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Body18,
  Caption,
  Divider,
  Icon,
  Info,
  Link,
  List,
  Popover,
  Rating,
  StatusEnhancer,
  Tag,
} from "../../../components";
import { ButtonBase } from "../../../components/Styles/Base";
import { LIST, TAG } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";

const renderOfferState = (state) => {
  if (state === "paid") {
    return (
      <Block height={23}>
        <Tag.Small
          kind={TAG.KIND.SUCCESS}
          LeftComponent={() => <Icon.Small name="check-circle-solid" />}
        >
          {polyglot.t("common.paid")}
        </Tag.Small>
      </Block>
    );
  }
  if (state === "canceled") {
    return (
      <Block height={23}>
        <Tag.Small
          kind={TAG.KIND.DANGER}
          LeftComponent={() => <Icon.Small name="user-times-solid" />}
        >
          {polyglot.t("common.canceled")}
        </Tag.Small>
      </Block>
    );
  }
  if (state === "selected") {
    return (
      <Block height={23}>
        <Tag.Small
          kind={TAG.KIND.MUTED}
          LeftComponent={() => <Icon.Small name="calendar-solid" />}
        >
          {polyglot.t("common.booked")}
        </Tag.Small>
      </Block>
    );
  }
  return null;
};

const JobberOfferItemContent = ({
  jobber,
  id,
  price,
  price_per_hour,
  state,
  paid_at,
  urlTargetBlank,
  canceled_at,
  hideDetails,
  RightComponent,
  currency,
  auto_postulated,
  fees,
  urssaf,
  nb_hours,
  ...rest
}) => {
  const { current_admin } = useSelector((state) => state.rails);
  return (
    <List.Item
      divider={false}
      {...rest}
      LeftComponent={() => (
        <Link href={jobber.url} target={urlTargetBlank ? "_blank" : null}>
          <Avatar
            src={jobber.avatar}
            size={sizes.size48}
            name={jobber.first_name}
            isVerified
            isTopJobber={jobber.is_top_jobber}
          />
        </Link>
      )}
      RightComponent={
        RightComponent ||
        (() => (
          <Block
            height="100%"
            display="flex"
            alignItems="flex-end"
            flexDirection="column-reverse"
          >
            {renderOfferState(state)}
            <Block display="flex" flexDirection="column" alignItems="flex-end">
              {(price || price_per_hour) && (
                <Body16 strong align="right">
                  {polyglot.toSmartCurrency(price || price_per_hour, {
                    noDecimal: true,
                    pricePerHour: !price,
                    currency,
                  })}
                </Body16>
              )}
              {current_admin && price_per_hour && (
                <Block display="flex" alignItems="center">
                  {fees && (
                    <>
                      <Info
                        noPortal
                        PopoverContainer={Popover.Elem.Menu}
                        render={() => (
                          <ButtonBase type="button">
                            <Block display="flex" alignItems="center">
                              <Caption>
                                {`${polyglot.toSmartCurrency(price_per_hour, {
                                  currency,
                                })}/h`}
                              </Caption>
                              <Icon.Small
                                color={colors.muted}
                                name="question-circle-solid"
                                css={`
                                  margin-left: ${spacings.xs};
                                `}
                              />
                            </Block>
                          </ButtonBase>
                        )}
                      >
                        <Block minWidth={300}>
                          {urssaf > 0 && (
                            <List.Item
                              withGutters
                              size={LIST.SIZE.COMPACT}
                              divider={false}
                              RightComponent={() => (
                                <Body14>
                                  {polyglot.toSmartCurrency(price)}
                                </Body14>
                              )}
                            >
                              <Body14 strong>
                                {polyglot.t("common.jobber_remuneration")}
                              </Body14>
                            </List.Item>
                          )}
                          <List.Item
                            withGutters
                            size={LIST.SIZE.COMPACT}
                            divider={false}
                            RightComponent={() => (
                              <Body14>{polyglot.toSmartCurrency(fees)}</Body14>
                            )}
                          >
                            <Body14 strong>{polyglot.t("common.fees")}</Body14>
                          </List.Item>

                          {urssaf > 0 && (
                            <List.Item
                              withGutters
                              size={LIST.SIZE.COMPACT}
                              divider={false}
                              RightComponent={() => (
                                <Body14>
                                  {polyglot.toSmartCurrency(urssaf)}
                                </Body14>
                              )}
                            >
                              <Body14 strong>
                                {polyglot.t("common.taxes")}
                              </Body14>
                            </List.Item>
                          )}
                          <Divider.Cell
                            css={`
                              margin: ${spacings.xs} ${spacings.m};
                            `}
                          />
                          <List.Item
                            withGutters
                            size={LIST.SIZE.COMPACT}
                            divider={false}
                            RightComponent={() => (
                              <Body14 strong>
                                {polyglot.toSmartCurrency(
                                  price + fees + urssaf
                                )}
                              </Body14>
                            )}
                          >
                            <Body14 strong>
                              {polyglot.toSmartCurrency(
                                price || 0 + fees || 0 + urssaf || 0
                              )}
                            </Body14>
                          </List.Item>
                        </Block>
                      </Info>
                    </>
                  )}
                </Block>
              )}
            </Block>
          </Block>
        ))
      }
    >
      <StatusEnhancer isPro={jobber.is_pro}>
        <Link
          href={jobber.url}
          color={colors.body}
          target={urlTargetBlank ? "_blank" : null}
        >
          <Body18 strong numberOfLines={1}>
            {jobber.first_name} {jobber.last_name ? jobber.last_name : ""}
          </Body18>
        </Link>
      </StatusEnhancer>
      <Rating type="compact" rate={jobber.rate} count={jobber.rates_count} />
      {auto_postulated && (
        <Block color={colors.purple} display="flex" alignItems="center">
          <Icon.Medium
            name="bolt"
            css={`
              margin-right: ${spacings.xxs};
            `}
          />
          <Body14 strong numberOfLines={1}>
            {polyglot.t("common.instant_booking")}
          </Body14>
        </Block>
      )}
      {jobber.is_top_jobber && (
        <Block marginTop={spacings.xs}>
          <Info
            render={() => (
              <Tag.Small
                kind={TAG.KIND.PURPLE}
                LeftComponent={() => <Icon.Medium name="love-solid" />}
                RightComponent={() => <Icon.Medium name="info-circle" />}
              >
                <Body14 numberOfLines={1}>
                  {polyglot.t("common.top_jobber")}
                </Body14>
              </Tag.Small>
            )}
          >
            {polyglot.t("common.top_jobber_description")}
          </Info>
        </Block>
      )}
      {urssaf > 0 && (
        <Block
          display="flex"
          alignItems="center"
          marginTop={spacings.xs}
          color={colors.muted}
        >
          <Icon.Small
            name="check-circle"
            css={`
              margin-right: ${spacings.xs};
            `}
          />
          <Body14>{polyglot.t("common.declaration")}</Body14>
        </Block>
      )}
    </List.Item>
  );
};

export default JobberOfferItemContent;
