import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useState, useMemo } from "react";
import { formatDate } from "@/utils";
import useFileThumbnail from "./useFileThumbnail";
import { usePostChatMessage } from "../routes/dashboard-routes";

const useChatTempMessage = (id) => {
  const [tempMessages, setTempMessages] = useState({});
  const fileThumbnail = useFileThumbnail();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData([`/inboxes/${id}`]);
  const [messages, setMessages] = useState([]);
  const createMessage = usePostChatMessage();

  useEffect(() => {
    setMessages(data?.messages || []);
  }, [data?.messages]);

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe((event) => {
      if (event.action?.type === "error") {
        const variables = event.mutation?.state?.variables;
        const tempId = variables?.get("temp_id");

        if (tempId) {
          setTempMessages((prevTempMessages) => {
            const updatedMessages = { ...prevTempMessages };
            if (updatedMessages[id]) {
              updatedMessages[id] = updatedMessages[id].map((msg) =>
                msg.temp_id === tempId
                  ? {
                      ...msg,
                      isError: true,
                      retry: () => {
                        setTempMessages((prev) => ({
                          ...prev,
                          [id]: prev[id].map((m) =>
                            m.temp_id === tempId
                              ? { ...m, isLoading: true, isError: false }
                              : m
                          ),
                        }));
                        createMessage.mutate(variables);
                      },
                      isLoading: false,
                    }
                  : msg
              );
            }
            return updatedMessages;
          });
        }
      } else if (event.action?.type === "success") {
        const tempId = event.action.data?.config?.data?.get("temp_id");
        const currId = event.action.data?.data?.data?.id;
        if (tempId && currId) {
          setTempMessages((prevTempMessages) => {
            const updatedMessages = { ...prevTempMessages };
            if (updatedMessages[currId]) {
              updatedMessages[currId] = updatedMessages[currId].filter(
                (msg) => msg.temp_id !== tempId
              );
            }
            return updatedMessages;
          });
          setMessages(event.action.data?.data?.data.messages);
        }
      }
    });
    return () => {
      unsubscribe();
    };
  }, [queryClient]);

  const createTempMessage = useCallback(
    (data, tempId, upload_timestamp) => ({
      ...data,
      isLoading: true,
      date: formatDate(moment(), "YYYY-MM-DD"),
      kind: "message",
      user: { is_current_user: true },
      temp_id: tempId,
      id: tempId,
      upload_timestamp,
      hour: formatDate(moment(), "HH:mm"),
      timestamp: new Date().toISOString(),
    }),
    []
  );

  useEffect(() => {
    // prevent quitting the page if there are unfinished uploads
    const handleBeforeUnload = (event) => {
      const hasTempMessages = Object.values(tempMessages).some(
        (messages) => messages.length > 0
      );
      if (hasTempMessages) {
        event.preventDefault();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [tempMessages]);

  const set = useCallback(
    async (formData) => {
      const {
        file,
        body,
        temp_id: tempId,
        audio_duration,
        audio_amplitudes,
        upload_timestamp,
      } = Object.fromEntries(formData.entries());

      let tempMessage = null;
      if (file) {
        const { src, fileType, width, height } = await fileThumbnail.get(file);
        tempMessage = createTempMessage(
          {
            attachment: {
              id: tempId,
              image_preview_url: src,
              url: src,
              image_preview_width: width,
              image_preview_height: height,
              filename: file.name,
              file_size: file.size,
              kind: `${fileType}_message`,
              ...(audio_duration
                ? {
                    duration: parseFloat(audio_duration),
                    amplitudes: JSON.parse(audio_amplitudes),
                  }
                : {}),
            },
          },
          tempId,
          upload_timestamp
        );
      } else if (body) {
        tempMessage = createTempMessage({ body }, tempId, upload_timestamp);
      }
      if (tempMessage) {
        setTempMessages((prevTempMessages) => {
          const newIndex =
            messages.length + (prevTempMessages[id]?.length || 0);
          return {
            ...prevTempMessages,
            [id]: [
              ...(prevTempMessages[id] || []),
              { ...tempMessage, index: newIndex },
            ],
          };
        });
      }
    },
    [id, queryClient, createTempMessage, fileThumbnail, messages]
  );

  const get = useCallback(() => {
    const currentMessages = [...(messages || [])];
    const tempMessagesForId = tempMessages[id] || [];
    tempMessagesForId.forEach((tempMessage) => {
      currentMessages.splice(
        currentMessages.length - tempMessage.index,
        0,
        tempMessage
      );
    });
    return currentMessages;
  }, [messages, tempMessages, id]);

  const memoizedGet = useMemo(() => get, [get]);

  return {
    set,
    get: memoizedGet,
  };
};

export default useChatTempMessage;
