import MapStandalone from "@/pages/MapStandalone";
import BaseApp from "../modules/BaseApp";

const MapStandaloneApp = (props, rails) => () =>
  (
    <BaseApp>
      <MapStandalone {...props} />
    </BaseApp>
  );

export default MapStandaloneApp;
