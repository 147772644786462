import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Skeleton, Block, Row, Col } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const AccountTile = () => (
  <Col size={{ xs: 12, sm: 6, xl: 4 }}>
    <Skeleton
      height={sizes.size24}
      width={sizes.size24}
      shape={SKELETON.SHAPE.CIRCLE}
      sb
    />
    <Skeleton height={sizes.size24} sb width="60%" />
    <Skeleton height={sizes.size16} rows={3} />
  </Col>
);

const AccountSkeleton = () => (
  <Skeleton.Group>
    <Block display="flex" alignItems="center" width="100%">
      <Skeleton
        width={sizes.size96}
        height={sizes.size96}
        shape={SKELETON.SHAPE.CIRCLE}
        sr
      />
      <Block>
        <Skeleton height={sizes.size24} width={150} sb />
        <Skeleton height={sizes.size16} width={75} />
      </Block>
    </Block>
    <Block marginY={spacings.l}>
      <Skeleton height={sizes.size128} />
    </Block>
    <Block marginY={spacings.xl}>
      <Skeleton height={sizes.size24} width={150} sy />
      <Row gutter={[spacings.m]}>
        <AccountTile />
        <AccountTile />
        <AccountTile />
        <AccountTile />
        <AccountTile />
      </Row>
    </Block>
    <Block marginY={spacings.xl}>
      <Skeleton height={sizes.size24} width={150} sy />
      <Row gutter={[spacings.m]}>
        <AccountTile />
        <AccountTile />
        <AccountTile />
      </Row>
    </Block>
  </Skeleton.Group>
);

export default AccountSkeleton;
