import { useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "@/assets/themes";
import { Block, Body16, Button, Icon } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import {
  useBreakpoints,
  useElapsedTime,
  useMediaRecorder,
} from "@/modules/hooks";

const AmplitudeBar = styled.div.attrs((props) => ({
  style: {
    height: `${props.height}px`,
  },
}))`
  width: ${spacings.xs};
  background-color: ${(props) => props.barColor};
  margin-right: 1px;
  border-radius: ${radius.circle};
  min-height: ${spacings.xs};
  max-height: ${sizes.size36};
`;

const Microphone = () => {
  const breakpoints = useBreakpoints();
  const formik = useFormikContext();
  const [shouldSubmit, setShouldSubmit] = useState(true);
  const { isRecording, startTime, startRecording, stopRecording, amplitude } =
    useMediaRecorder(async (audioBlob, { duration, amplitudes }) => {
      if (shouldSubmit) {
        const originalName = audioBlob.name || "recording";
        const mimeType = audioBlob.type;
        const extensionMap = [
          { label: "audio/ogg", value: "ogg" },
          { label: "audio/webm", value: "webm" },
          { label: "audio/wav", value: "wav" },
          { label: "audio/mp4", value: "m4a" },
        ];
        const extension = extensionMap.find((ext) =>
          mimeType.includes(ext.label)
        ).value;
        const fileWithExtension = new File(
          [audioBlob],
          `${originalName}.${extension}`,
          { type: mimeType }
        );
        await formik.setFieldValue("file", fileWithExtension);
        await formik.setFieldValue("audio_amplitudes", amplitudes);
        await formik.setFieldValue("audio_duration", duration);
        await formik.submitForm();
      }
    });

  const elapsedTime = useElapsedTime(startTime);

  const handleStopRecording = (submit) => {
    setShouldSubmit(submit);
    stopRecording(submit);
  };

  const handleInteraction = (e) => {
    e.preventDefault();
    if (isRecording) handleStopRecording(true);
    else startRecording();
  };

  return (
    <Block display="flex" alignItems="center" justifyContent="center">
      {isRecording && (
        <Block
          backgroundColor={colors.backgroundGray}
          height={`calc(${sizes.size48} + (${borderWidth.m} * 2))`}
          borderRadius={radius.xl}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={spacings.m}
          position="absolute"
          right="0px"
          marginX={spacings.m}
          left="0px"
        >
          <Block display="flex" alignItems="center" paddingLeft={spacings.m}>
            <Icon.Large name="circle-small-solid" color={colors.danger} />
            <Body16 color={colors.muted}>{elapsedTime}</Body16>
            <Block marginLeft={spacings.s} display="flex" alignItems="center">
              <AmplitudeBar
                height={amplitude * 24}
                barColor={colors.mutedLight}
              />
              <AmplitudeBar
                height={amplitude * 36}
                barColor={colors.mutedLight}
              />
              <AmplitudeBar
                height={amplitude * 16}
                barColor={colors.mutedLight}
              />
            </Block>
          </Block>
          <Block
            marginRight={sizes.size48}
            position="relative"
            zIndex="999"
            paddingRight={spacings.s}
          >
            <Button.Small
              shape={BUTTON.SHAPE.CIRCLE}
              kind={BUTTON.KIND.SECONDARY}
              accentColor={BUTTON.ACCENT_COLOR.DANGER}
              onClick={() => handleStopRecording(false)}
            >
              <Icon.Medium name="trash" />
            </Button.Small>
          </Block>
        </Block>
      )}
      <Button.Medium
        shape={BUTTON.SHAPE.CIRCLE}
        disabled={isRecording ? elapsedTime === "00:00" : false}
        onTouchStart={breakpoints.get({ xs: handleInteraction, md: null })}
        onClick={breakpoints.get({ xs: null, md: handleInteraction })}
      >
        <Icon.Large name={isRecording ? "arrow-up" : "microphone"} />
      </Button.Medium>
    </Block>
  );
};

export default Microphone;
