import { useLayoutEffect, useRef } from "react";
import ReactDOM from "react-dom";
import ReactTyped from "react-typed";
import { shuffle } from "../utils";

const AnimatedSelectorPhrase = ({ selector, phrases, className, style }) => {
  const selectorRef = useRef(null);

  useLayoutEffect(() => {
    const el = document.querySelectorAll(selector)[0];
    selectorRef.current = el;
  }, [selector]);

  const portalContainerRef = useRef(document.createElement("div"));

  useLayoutEffect(() => {
    selectorRef.current.style.display = "none";
    selectorRef.current.parentNode.insertBefore(
      portalContainerRef.current,
      selectorRef.current.nextSibling
    );
  }, [selectorRef, portalContainerRef]);

  const Elem = (
    <ReactTyped
      loop
      typeSpeed={50}
      backSpeed={15}
      backDelay={1300}
      strings={shuffle(phrases)}
      className={className}
      style={style}
    />
  );

  return ReactDOM.createPortal(Elem, portalContainerRef.current);
};
export default AnimatedSelectorPhrase;
