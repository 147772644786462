/* eslint-disable no-restricted-properties */
/* eslint-disable no-plusplus */
import {
  CATEGORIES,
  CATEGORIES_PATH,
  CUSTOM_CATEGORIES_SEARCH_EXCLUDES,
} from "../../config/categories";
import polyglot from "../../utils/polyglot";

const levenshteinDistance = (a, b) => {
  if (a.length === 0) return b.length;
  if (b.length === 0) return a.length;

  const matrix = [];

  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1,
          matrix[i][j - 1] + 1,
          matrix[i - 1][j] + 1
        );
      }
    }
  }

  return matrix[b.length][a.length];
};

const similarity = (a, b) => {
  const distance = levenshteinDistance(a, b);
  const maxLength = Math.max(a.length, b.length);

  return (maxLength - distance) / maxLength;
};

const flattenCategories = (categories) =>
  categories.flatMap(({ id, children, ...rest }) => [
    { id, ...rest },
    ...(children ? flattenCategories(children) : []),
  ]);

export const launchSearch = (string, customCategories = CATEGORIES_PATH) => {
  const STOP_WORDS = ["les", "des", "une", "pour", "faire", "veux"];
  const searchWords = string
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .replaceAll("'", " ")
    .split(" ")
    .filter((elem) => !STOP_WORDS.includes(elem) && elem.length > 2);
  const results = [];
  const results2 = [];

  flattenCategories(customCategories).forEach((categorie) => {
    const terms = polyglot.t(`categories.${categorie.id}.terms`);
    if (!CUSTOM_CATEGORIES_SEARCH_EXCLUDES.includes(categorie.id)) {
      let weight = 0;
      const categoryNames = polyglot
        .t(`categories.${categorie.id}.name`)
        .toLowerCase()
        .split(/\s+/);
      searchWords.forEach((elem) => {
        for (let i = 0; i < terms.length; i++) {
          const jaccard = similarity(
            elem,
            terms[i]
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );

          if (jaccard >= 0.8) {
            weight += Math.pow(jaccard, 10);

            for (let j = 0; j < categoryNames.length; j++) {
              const jaccard2 = similarity(
                elem,
                categoryNames[j]
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              );

              if (jaccard2 >= 0.8) {
                weight += Math.pow(jaccard2, 10);
              }
            }
          } else {
            const indexOf1 = terms[i].indexOf(elem);
            const indexOf11 = elem.indexOf(terms[i]);

            const getItem = ({ weight }) => ({
              ...categorie,
              n: polyglot.t(`categories.${categorie.id}.name`),
              id: categorie.id,
              weight,
            });

            if (indexOf1 !== -1) {
              results2.push({
                item: getItem({ weight: indexOf1 }),
              });
            } else if (indexOf11 !== -1) {
              results2.push({
                item: getItem({ weight: indexOf11 }),
              });
            }

            for (let j = 0; j < categoryNames.length; j++) {
              const indexOf2 = categoryNames[j].indexOf(elem);
              const indexOf22 = elem.indexOf(categoryNames[j]);

              if (indexOf2 !== -1) {
                results2.push({
                  item: getItem({ weight: indexOf2 }),
                });
              } else if (indexOf22 !== -1) {
                results2.push({
                  item: getItem({ weight: indexOf22 }),
                });
              }
            }
          }
        }
      });

      if (weight > 0) {
        results.push({
          item: {
            n: polyglot.t(`categories.${categorie.id}.name`),
            id: categorie.id,
            weight,
          },
        });
      }
    }
  });

  const resultsFinal = results.sort((a, b) => b.item.weight - a.item.weight);
  return resultsFinal?.length > 0
    ? resultsFinal
    : results2
        .filter(
          (item, index) =>
            results2.findIndex((elem) => elem.item.id === item.item.id) ===
            index
        )
        .sort((a, b) => a.item.weight - b.item.weight);
};
