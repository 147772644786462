import { useFormik } from "formik";
import { colors, spacings } from "../../assets/themes";
import { Block, FormControl, Slider } from "../../components";
import Result from "./Result";

const MIN = 5;
const MAX = 200;

const MovingBoxesCalculator = () => {
  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      surface: MAX / 2,
    },
  });

  return (
    <Block
      borderRadius={spacings.l}
      backgroundColor={colors.backgroundLight}
      paddingX={spacings.m}
      paddingTop={spacings.m}
      width="100%"
      maxWidth={500}
    >
      <form>
        <Block backgroundColor={colors.background} borderRadius={spacings.m}>
          <Block padding={spacings.m}>
            <FormControl name="surface" label="Surface du logement">
              <Slider
                persisentThumb
                min={MIN}
                max={MAX}
                step={2}
                ticks={[`${MIN} m²`, `${MAX} m²`]}
                value={formik.values.surface}
                onChange={(v) => {
                  formik.setFieldValue("surface", v);
                }}
                formatValue={(v) => `${v} m²`}
              />
            </FormControl>
          </Block>
        </Block>
        <Block
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Result surface={formik.values.surface} />
        </Block>
      </form>
    </Block>
  );
};
export default MovingBoxesCalculator;
