import { useFormikContext } from "formik";
import { useEffect } from "react";
import { spacings } from "../../../../assets/themes";
import { Block } from "../../../../components";
import { useCategoryForm, useDebounce } from "../../../../modules/hooks";
import polyglot from "../../../../utils/polyglot";
import {
  CURRENT_PASSWORD_PATH,
  FIRST_NAME_PATH,
  LAST_NAME_PATH,
  PASSWORD_PATH,
} from "../../constants";
import { LOGIN_PAGES, SIGNUP_PAGES } from "../../defaults";
import { FormInput, StyledFormControl } from "../fields";
import StepTitle from "../StepTitle";

const filterArray = [
  CURRENT_PASSWORD_PATH,
  FIRST_NAME_PATH,
  LAST_NAME_PATH,
  PASSWORD_PATH,
];

const Email = ({ title }) => {
  const { isValid, values, errors, touched } = useFormikContext();
  const { setPages, pages, flowControl, checkEmail } = useCategoryForm();
  const debouncedTerm = useDebounce(values.user?.email || "");

  const handleChange = () => {
    flowControl.setNextIsLoading(true);
    // prevent access native next page browser during loading.
    checkEmail.reset();
    setPages(pages);
  };

  useEffect(() => {
    if (isValid && values.user?.email?.length > 0) {
      checkEmail.mutate(
        { email: values.user.email },
        {
          onSuccess: ({ data }) => {
            setPages([
              ...pages.filter((p) => !filterArray.includes(p.path)),
              ...(data.data.redirection === "login"
                ? LOGIN_PAGES
                : SIGNUP_PAGES),
            ]);
          },
        }
      );
    } else {
      flowControl.setNextIsLoading(false);
    }
  }, [debouncedTerm]);

  useEffect(() => {
    flowControl.setNextIsLoading(false);
  }, [pages]);

  return (
    <>
      <Block marginRight={{ xs: 0, md: spacings.m }}>
        <StepTitle>{title}</StepTitle>
      </Block>
      <StyledFormControl
        error={touched.user?.email && errors.user?.email}
        hint={polyglot.t("categories.what_is_your_email_hint")}
      >
        <FormInput
          type="mail"
          name="user.email"
          autoComplete="email"
          onChange={handleChange}
          placeholder={polyglot.t("common.placeholder.email")}
        />
      </StyledFormControl>
      <input hidden name="password" type="password" autoComplete="password" />
    </>
  );
};

export default Email;
