const palette = {
  gray900: "var(--color-gray900)",
  gray800: "var(--color-gray800)",
  gray700: "var(--color-gray700)",
  gray600: "var(--color-gray600)",
  gray500: "var(--color-gray500)",
  gray400: "var(--color-gray400)",
  gray300: "var(--color-gray300)",
  gray200: "var(--color-gray200)",
  gray100: "var(--color-gray100)",
  gray50: "var(--color-gray50)",
  white: "var(--color-white)",
  pink900: "var(--color-pink900)",
  pink800: "var(--color-pink800)",
  pink700: "var(--color-pink700)",
  pink600: "var(--color-pink600)",
  pink500: "var(--color-pink500)",
  pink400: "var(--color-pink400)",
  pink300: "var(--color-pink300)",
  pink200: "var(--color-pink200)",
  pink100: "var(--color-pink100)",
  pink50: "var(--color-pink50)",
  danger900: "var(--color-danger900)",
  danger800: "var(--color-danger800)",
  danger700: "var(--color-danger700)",
  danger600: "var(--color-danger600)",
  danger500: "var(--color-danger500)",
  danger400: "var(--color-danger400)",
  danger300: "var(--color-danger300)",
  danger200: "var(--color-danger200)",
  danger100: "var(--color-danger100)",
  danger50: "var(--color-danger50)",

  orange900: "var(--color-orange900)",
  orange800: "var(--color-orange800)",
  orange700: "var(--color-orange700)",
  orange600: "var(--color-orange600)",
  orange500: "var(--color-orange500)",
  orange400: "var(--color-orange400)",
  orange300: "var(--color-orange300)",
  orange200: "var(--color-orange200)",
  orange100: "var(--color-orange100)",
  orange50: "var(--color-orange50)",

  orangered900: "var(--color-orangered900)",
  orangered800: "var(--color-orangered800)",
  orangered700: "var(--color-orangered700)",
  orangered600: "var(--color-orangered600)",
  orangered500: "var(--color-orangered500)",
  orangered400: "var(--color-orangered400)",
  orangered300: "var(--color-orangered300)",
  orangered200: "var(--color-orangered200)",
  orangered100: "var(--color-orangered100)",
  orangered50: "var(--color-orangered50)",

  purple900: "var(--color-purple900)",
  purple800: "var(--color-purple800)",
  purple700: "var(--color-purple700)",
  purple600: "var(--color-purple600)",
  purple500: "var(--color-purple500)",
  purple400: "var(--color-purple400)",
  purple300: "var(--color-purple300)",
  purple200: "var(--color-purple200)",
  purple100: "var(--color-purple100)",
  purple50: "var(--color-purple50)",
  primary900: "var(--color-primary900)",
  primary800: "var(--color-primary800)",
  primary700: "var(--color-primary700)",
  primary600: "var(--color-primary600)",
  primary500: "var(--color-primary500)",
  primary400: "var(--color-primary400)",
  primary300: "var(--color-primary300)",
  primary200: "var(--color-primary200)",
  primary100: "var(--color-primary100)",
  primary50: "var(--color-primary50)",
  turquoise900: "var(--color-turquoise900)",
  turquoise800: "var(--color-turquoise800)",
  turquoise700: "var(--color-turquoise700)",
  turquoise600: "var(--color-turquoise600)",
  turquoise500: "var(--color-turquoise500)",
  turquoise400: "var(--color-turquoise400)",
  turquoise300: "var(--color-turquoise300)",
  turquoise200: "var(--color-turquoise200)",
  turquoise100: "var(--color-turquoise100)",
  turquoise50: "var(--color-turquoise50)",
  success900: "var(--color-success900)",
  success800: "var(--color-success800)",
  success700: "var(--color-success700)",
  success600: "var(--color-success600)",
  success500: "var(--color-success500)",
  success400: "var(--color-success400)",
  success300: "var(--color-success300)",
  success200: "var(--color-success200)",
  success100: "var(--color-success100)",
  success50: "var(--color-success50)",
  warning900: "var(--color-warning900)",
  warning800: "var(--color-warning800)",
  warning700: "var(--color-warning700)",
  warning600: "var(--color-warning600)",
  warning500: "var(--color-warning500)",
  warning400: "var(--color-warning400)",
  warning300: "var(--color-warning300)",
  warning200: "var(--color-warning200)",
  warning100: "var(--color-warning100)",
  warning50: "var(--color-warning50)",
  brown900: "var(--color-brown900)",
  brown800: "var(--color-brown800)",
  brown700: "var(--color-brown700)",
  brown600: "var(--color-brown600)",
  brown500: "var(--color-brown500)",
  brown400: "var(--color-brown400)",
  brown300: "var(--color-brown300)",
  brown200: "var(--color-brown200)",
  brown100: "var(--color-brown100)",
  brown50: "var(--color-brown50)",
  transparent: "var(--color-transparent)",
};

const alias = {
  primaryDark: palette.primary900,
  primary: palette.primary500,
  primaryLight: palette.primary50,
  orangered: palette.orangered500,
  orangeredLight: palette.orangered50,
  white: palette.white,
  border: palette.gray100,
  light: palette.gray50,
  muted: palette.gray600,
  mutedLight: palette.gray500,
  body: palette.gray900,
  dangerDark: palette.danger900,
  danger: palette.danger500,
  dangerLight: palette.danger50,
  warningDark: palette.warning900,
  warning: palette.warning500,
  warningLight: palette.warning50,
  purpleDark: palette.purple900,
  purple: palette.purple500,
  purpleLight: palette.purple50,
  successDark: palette.success900,
  success: palette.success500,
  successLight: palette.success50,
  orangeDark: palette.orange900,
  orange: palette.orange500,
  orangeLight: palette.orange50,
  background: palette.white,
  backgroundGray: palette.gray100,
  backgroundLight: palette.gray50,
  backgroundDark: palette.gray900,
  onColor: palette.white,
  overlayBackdropDark: "var(--color-overlay-backdrop-dark)",
  overlayBackdrop: "var(--color-overlay-backdrop)",
  overlayDark: "var(--color-overlay-dark)",
  overlayLight: "var(--color-overlay-light)",
  backgroundBlur: "var(--color-background-blur)",
};

const colors = { ...alias, ...palette };

const spacings = {
  xxs: "var(--spacing-xxs)",
  xs: "var(--spacing-xs)",
  s: "var(--spacing-s)",
  sm: "var(--spacing-sm)",
  m: "var(--spacing-m)",
  ml: "var(--spacing-ml)",
  l: "var(--spacing-l)",
  xl: "var(--spacing-xl)",
  xxl: "var(--spacing-xxl)",
};

const radius = {
  xs: "var(--radius-xs)",
  s: "var(--radius-s)",
  ml: "var(--radius-ml)",
  m: "var(--radius-m)",
  l: "var(--radius-l)",
  xl: "var(--radius-xl)",
  circle: "var(--radius-circle)",
};

const shadows = {
  xs: "var(--shadow-xs)",
  s: "var(--shadow-s)",
  m: "var(--shadow-m)",
  l: "var(--shadow-l)",
  uXs: "var(--shadow-up-xs)",
  uS: "var(--shadow-up-s)",
  uM: "var(--shadow-up-m)",
  uL: "var(--shadow-up-l)",
};

const sizes = {
  size8: "var(--size-8)",
  size12: "var(--size-12)",
  size14: "var(--size-14)",
  size16: "var(--size-16)",
  size18: "var(--size-18)",
  size20: "var(--size-20)",
  size24: "var(--size-24)",
  size32: "var(--size-32)",
  size36: "var(--size-s)",
  size48: "var(--size-m)",
  size54: "var(--size-l)",
  size64: "var(--size-64)",
  size72: "var(--size-72)",
  size96: "var(--size-96)",
  size128: "var(--size-128)",
  size136: "var(--size-136)",
};

const borderWidth = {
  s: "var(--border-width-s)",
  m: "var(--border-width-m)",
  ml: "var(--border-width-ml)",
  l: "var(--border-width-l)",
  xl: "var(--border-width-xl)",
  xxl: "var(--border-width-xxl)",
};

const googleMapStyle = [
  {
    featureType: "administrative",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#444444",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f2f2f2",
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
      {
        lightness: "0",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        visibility: "on",
      },
      {
        hue: "#00ff53",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified",
      },
      {
        weight: "0.01",
      },
      {
        color: "#8f9db5",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
      {
        hue: "#0079ff",
      },
      {
        saturation: "-100",
      },
      {
        lightness: "0",
      },
      {
        gamma: "1.33",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "all",
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "all",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels",
    stylers: [
      {
        hue: "#0079ff",
      },
      {
        saturation: "-100",
      },
      {
        gamma: "1.52",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.text",
    stylers: [
      {
        lightness: "0",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [
      {
        lightness: "0",
      },
      {
        saturation: "-100",
      },
      {
        gamma: "1.52",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "all",
    stylers: [
      {
        color: "#c0e4f3",
      },
      {
        visibility: "on",
      },
    ],
  },
];

export {
  colors,
  radius,
  sizes,
  spacings,
  shadows,
  borderWidth,
  googleMapStyle,
};
