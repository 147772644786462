import moment from "moment";
import React, { useEffect, useImperativeHandle, useState } from "react";
import styled, { css } from "styled-components";
import { colors, spacings } from "../../assets/themes";
import Button from "../Button";
import Icon from "../Icon";
import Select from "../Select";
import { InputBase } from "../Styles/Base";
import { BUTTON, CALENDAR, SELECT } from "../Styles/variants";
import { Body14, Body18 } from "../Text";
import Day from "./Day";
import {
  addMonths,
  getCalendarArr,
  getCalendarTitle,
  getDayIsDisabled,
  getEnhancedDates,
  getFirstIsoWeekDayOfMonth,
  getInitialValue,
  getMonth,
  getMonths,
  getWeekDays,
  getYear,
  getYears,
  isAfter,
  isBetweenOrSame,
  isSame,
  setDate,
  getOutputFormat,
} from "./utils";

const StyledSelect = styled(Select)`
  ${InputBase} {
    background: ${colors.background};
    input {
      font-weight: var(--font-weight-medium);
    }
  }
`;

const StyledDays = styled.div`
  ${({ startIsoWeek }) => css`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    row-gap: ${spacings.xxs};
    > button:first-child {
      grid-column: ${startIsoWeek};
    }
  `}
`;

const StyledCalendarWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  padding-bottom: ${spacings.sm};
  > div {
    width: 100%;
    margin-right: ${spacings.m};
    &:last-of-type {
      margin-right: 0;
    }
  }
`;

const StyledWeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: ${spacings.s};
`;

const StyledCalendarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${spacings.m};
  padding-bottom: ${spacings.m};
  padding-left: ${spacings.s};
  padding-right: ${spacings.s};
`;

const CalendarHeader = ({
  onChange,
  active,
  hidePrev,
  hideNext,
  prevDisabled,
  nextDisabled,
  onMonthChange,
  monthOptions,
  yearOptions,
  onYearChange,
  canSwitchDate,
}) => (
  <StyledCalendarHeader>
    {!hidePrev && (
      <Button.Small
        kind={BUTTON.KIND.SECONDARY}
        shape={BUTTON.SHAPE.CIRCLE}
        onClick={() => onChange(-1)}
        disabled={prevDisabled}
      >
        <Icon.Medium name="arrow-left" />
      </Button.Small>
    )}
    {!canSwitchDate && (
      <Body18 color={colors.muted}>{getCalendarTitle(active)}</Body18>
    )}
    {canSwitchDate && (
      <>
        <StyledSelect
          css={`
            width: 136px;
          `}
          dropdownHeight={205}
          size={SELECT.SIZE.SMALL}
          value={getMonth(active)}
          options={monthOptions}
          name="calendar_month"
          onChange={onMonthChange}
        />
        <StyledSelect
          css={`
            width: 100px;
          `}
          dropdownHeight={205}
          size={SELECT.SIZE.SMALL}
          value={getYear(active)}
          options={yearOptions}
          name="calendar_year"
          onChange={onYearChange}
        />
      </>
    )}
    {!hideNext && (
      <Button.Small
        kind={BUTTON.KIND.SECONDARY}
        shape={BUTTON.SHAPE.CIRCLE}
        onClick={() => onChange(1)}
        disabled={nextDisabled}
      >
        <Icon.Medium name="arrow-right" />
      </Button.Small>
    )}
  </StyledCalendarHeader>
);

const Calendar = React.forwardRef(
  (
    {
      onChange,
      onMonthChange,
      onYearChange,
      range,
      monthsShown = 1,
      value,
      format = "YYYY-MM-DD",
      disabledDate,
      disabledWeekDays,
      minRange,
      minDate,
      maxDate,
      canSwitchDate,
      activeMonth: _activeMonth,
      size = CALENDAR.SIZE.DEFAULT,
    },
    ref
  ) => {
    const [dates, setDates] = useState(getInitialValue(value, format));
    const [hoveredDate, setHoveredDate] = useState(null);
    const [activeMonth, setActiveMonth] = useState(
      _activeMonth || getInitialValue(value, format)[0] || new Date()
    );

    useEffect(() => {
      const formatedVal = getInitialValue(value, format);
      setDates(formatedVal);
      if (formatedVal[0] && !isSame(formatedVal, activeMonth, "months")) {
        setActiveMonth(formatedVal[0]);
      }
    }, [value]);

    useImperativeHandle(ref, () => ({ dates, setDates }), [
      JSON.stringify(dates),
    ]);

    const handleNavigation = (val) => {
      const newMonth = addMonths(activeMonth, val);
      setActiveMonth(newMonth);
      if (onMonthChange) onMonthChange(newMonth);
    };

    const handleDayClick = (selectDate) => {
      const newDate = getEnhancedDates(selectDate, dates, range, format);
      setDates(newDate);
      const formatedDate = range
        ? newDate.map((v) => getOutputFormat(v, format))
        : getOutputFormat(newDate[0], format);
      if (onChange) {
        onChange(formatedDate);
      }
    };

    const getDayIsActive = (d) => {
      if (range) {
        const from = dates[0];
        const to = dates[1] || hoveredDate;
        return (from && isSame(d, from)) || (to && isSame(d, to));
      }
      return dates[0] && isSame(d, dates[0]);
    };

    const getDayIsBetweenOrSame = (d, datesRange) => {
      if (range && datesRange.from && datesRange.to) {
        return isBetweenOrSame(d, { from: datesRange.from, to: datesRange.to });
      }
      return false;
    };

    const handleMouseEnter = (d) => {
      if (range && dates[0] && isAfter(d, dates[0])) {
        setHoveredDate(d);
      }
    };

    const handleMouseLeave = () => {
      setHoveredDate(null);
    };

    const getIsStartDay = (d) => isSame(d, dates[0]);

    const getIsEndDay = (d) => isSame(d, dates[1] || hoveredDate);

    const prevDisabled =
      minDate && moment(activeMonth).isSame(moment(minDate), "month");
    const nextDisabled =
      maxDate && moment(activeMonth).isSame(moment(maxDate), "month");

    const handleChangeMonth = ({ value }) => {
      setActiveMonth((s) => setDate(s, "month", value));
      if (onMonthChange) onMonthChange(value);
    };

    const handleChangeYear = ({ value }) => {
      setActiveMonth((s) => setDate(s, "year", value));
      if (onYearChange) onYearChange(value);
    };

    const renderDays = (acc) =>
      getCalendarArr(activeMonth, acc).map((value, i) => (
        <Day
          onClick={handleDayClick}
          isToday={isSame(value, new Date())}
          value={value}
          label={i + 1}
          size={size}
          disabled={getDayIsDisabled(value, {
            minRange,
            minDate,
            maxDate,
            disabledDate,
            disabledWeekDays,
            value: dates,
          })}
          active={getDayIsActive(value)}
          isStartDay={getIsStartDay(value)}
          isEndDay={getIsEndDay(value)}
          onMouseEnter={() => handleMouseEnter(value)}
          onMouseLeave={() => handleMouseLeave(value)}
          isBetweenOrSame={getDayIsBetweenOrSame(value, {
            from: dates[0],
            to: dates[1] || hoveredDate,
          })}
          key={`calendar-day-item-${i}-${acc}`}
        >
          {value}
        </Day>
      ));

    return (
      <StyledCalendarWrapper>
        {new Array(monthsShown).fill("").map((_, acc) => (
          <div key={`datepicker-months-${acc}`}>
            <CalendarHeader
              canSwitchDate={canSwitchDate}
              active={addMonths(activeMonth, acc)}
              onChange={handleNavigation}
              monthOptions={getMonths(activeMonth, { minDate, maxDate })}
              yearOptions={getYears(activeMonth, { minDate, maxDate })}
              onMonthChange={handleChangeMonth}
              onYearChange={handleChangeYear}
              prevDisabled={prevDisabled}
              nextDisabled={nextDisabled}
              hidePrev={acc > 0}
              hideNext={acc < monthsShown - 1}
            />
            <StyledWeekDays>
              {getWeekDays().map((day) => (
                <Body14
                  align="center"
                  color={colors.muted}
                  key={`weekdays-${day}-${acc}`}
                >
                  {day}
                </Body14>
              ))}
            </StyledWeekDays>
            <StyledDays
              startIsoWeek={getFirstIsoWeekDayOfMonth(activeMonth, acc)}
            >
              {renderDays(acc)}
            </StyledDays>
          </div>
        ))}
      </StyledCalendarWrapper>
    );
  }
);

export default Calendar;
