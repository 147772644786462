import { useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body16, Button, Divider, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { useAdminPreview, useToast } from "../../../modules/hooks";
import { formatPhone } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import ActionItem from "../ActionItem";
import {
  addFromParam,
  getMangopayUrlById,
  getQueryKey,
  JOBBER,
} from "../config";
import InformationItem from "../InformationItem";
import PreviewElemJobberScore from "../PreviewElemJobberScore";
import AvailabilitiesPreview from "../AvailabilitiesPreview";
import PreviewElemJobberCommentReport from "../PreviewElemJobberCommentReport";

const useJobberConfig = ({ id }) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { data, dataUpdateCount } = queryClient.getQueryState([
    getQueryKey({ kind: JOBBER, id }),
  ]);
  const adminPreview = useAdminPreview();

  const handleCopy = (phrase, value) => {
    navigator.clipboard.writeText(value);
    toast.remove();
    toast.success(phrase);
  };

  const informations = useCallback(
    () =>
      data && (
        <>
          {[
            {
              title: "ID",
              icon: "copy",
              onClick: () => {
                handleCopy(
                  "L'id a été copié dans le presse papier",
                  data.offseted_id
                );
              },
              RightComponent: () => <Body16>{data.offseted_id}</Body16>,
            },
            {
              title: "E-mail",
              icon: "copy",
              onClick: () => {
                handleCopy(
                  "L'email a été copié dans le presse papier",
                  data.email
                );
              },
              RightComponent: () => <Body16>{data.email}</Body16>,
            },
            {
              title: "Téléphone",
              href: `tel:${data.phone}`,
              AfterComponent: () => (
                <Block>
                  <Block marginRight={6}>
                    <Button.Small
                      kind={BUTTON.KIND.MINIMAL}
                      shape={BUTTON.SHAPE.CIRCLE}
                      onClick={() =>
                        handleCopy(
                          "Le numéro de téléphone a été copié dans le presse papier",
                          data.phone
                        )
                      }
                    >
                      <Icon.Medium name="copy" color={colors.primary} />
                    </Button.Small>
                  </Block>
                  <Divider.Cell />
                </Block>
              ),
              RightComponent: () => (
                <Block
                  marginRight={`calc(${spacings.m} * -1)`}
                  display="flex"
                  alignItems="center"
                >
                  <Block marginRight={spacings.xs}>
                    <Icon.Medium name="phone" color={colors.primary} />
                  </Block>
                  <Body16 color={colors.primary} strong>
                    {formatPhone(data.phone, { international: true })}
                  </Body16>
                </Block>
              ),
            },

            ...(data.ssn
              ? {
                  title: "Num. sécurité sociale",
                  icon: "copy",
                  value: data.ssn,
                  onClick: () => {
                    handleCopy(
                      "Le numéro de sécurité sociale a été copié dans le presse papier",
                      data.ssn
                    );
                  },
                }
              : []),
            {
              title: "Zone d'intervention",
              icon: "copy",
              value: `${data.radius} km • ${data.city}`,
              onClick: () => {
                handleCopy(
                  "La zone d'intervention a été copié dans le presse papier",
                  `${data.radius}km • ${data.city}`
                );
              },
            },
            ...(data.address
              ? {
                  title: "Adresse",
                  icon: "copy",
                  value: data.address,
                  onClick: () => {
                    handleCopy(
                      "L'adresse a été copié dans le presse papier",
                      data.address
                    );
                  },
                }
              : []),
            {
              title: "Réservation instantanée",
              RightComponent: () => (
                <Block
                  display="flex"
                  alignItems="center"
                  color={
                    data.has_auto_postulation
                      ? colors.purple
                      : colors.mutedLight
                  }
                >
                  <Body16
                    strong
                    css={`
                      margin-left: ${spacings.xs};
                    `}
                  >
                    {data.has_auto_postulation
                      ? polyglot.t("common.activated_f")
                      : polyglot.t("common.disabled_f")}
                  </Body16>
                </Block>
              ),
            },
          ].map((config, index) => (
            <InformationItem
              key={`preview-option-jobber-${id}-${index}`}
              {...config}
            />
          ))}
          <PreviewElemJobberScore score={data.jobber_reliability_score} />
          <PreviewElemJobberCommentReport
            count={data.jobber_refused_comment_count}
            total={data.jobber_refused_comment_total_count}
          />
        </>
      ),
    [dataUpdateCount]
  );

  const tabs = [
    { title: "Liens utiles" },
    { title: "Actions" },
    {
      title: "Horaires par défaut",
      disabled: !data?.schedules || data?.schedules?.length === 0,
    },
  ];

  const menu = useCallback(
    () =>
      data && [
        [
          [
            {
              title: "Voir les jobs",
              href: `/kiss/jobbers/${data.offseted_id}`,
              icon: "calendar",
            },
            {
              title: `Voir les notes admin (${data.admin_notes_count})`,
              onClick: () =>
                adminPreview.notes.show({ user: data, id: data.id }),
              icon: "exclamation-circle",
            },
            {
              title: "Voir le profil Mangopay",
              href: getMangopayUrlById(data.mangopay_id),
              target: "_blank",
              icon: "wallet",
            },
            {
              title: "Voir les documents d'identités",
              href: `/kiss/search/documents?search%5Bsearch_string%5D=${data.offseted_id}`,
              icon: "picture",
            },
            {
              title: "Voir les sinistres",
              href: `/kiss/damages?search_user=${data.offseted_id}`,
              icon: "calendar-times",
            },
            {
              title: "Voir les tickets BO",
              href: `/kiss/search/tickets?search%5Buser_id_offseted%5D=${data.offseted_id}`,
              icon: "tag",
            },
            {
              title: "Voir les tickets Zendesk",
              href: `https://youpijob.zendesk.com/agent/search/1?q=${data.email}`,
              target: "_blank",
              icon: "question-circle",
            },
          ].map((config, index) => (
            <ActionItem
              key={`preview-option-jobber-${data.id}-${index}`}
              {...config}
            />
          )),
        ],
        [
          [
            {
              title: "Modifier le profil",
              href: addFromParam(`/kiss/users/${data.offseted_id}/edit`),
              icon: "user-edit",
            },
            {
              title: "Modifier la photo",
              href: addFromParam(`/kiss/avatars/${data.offseted_id}/edit`),
              icon: "camera",
            },
            {
              title: "Modifier le mot de passe",
              href: addFromParam(
                `/kiss/users/${data.offseted_id}/edit_password`
              ),
              icon: "lock",
            },
            {
              title: "Soumettre un document d'identité",
              href: addFromParam(
                `/kiss/jobbers/${data.offseted_id}/edit_id_card`
              ),
              icon: "download",
            },
            {
              title: "Soumettre une carte vitale",
              href: addFromParam(
                `/kiss/jobbers/${data.offseted_id}/edit_vital_card`
              ),
              icon: "download",
            },
            {
              title: "Gérer la cagnotte",
              href: `/kiss/jobbers/${data.offseted_id}/wallet`,
              RightComponent: () => (
                <Body16 color={colors.muted}>
                  {polyglot.toSmartCurrency(data.wallet, {
                    currency: data.currency,
                  })}
                </Body16>
              ),
              icon: "wallet",
            },
            {
              title: "Gel de compte",
              href: `/kiss/penalties/new_penalty_temporary?search=${data.offseted_id}`,
              icon: "user-times",
            },
            {
              title: "Faire postuler à un job",
              href: `/kiss/offers/new?jobber_id_offseted=${data.offseted_id}`,
              icon: "calendar-check",
            },
            {
              title: "Générer une fiche de paie",
              href: `/kiss/users/payslip?id=${data.offseted_id}`,
              icon: "document",
            },
            {
              title: "Générer une attestation Assedic",
              href: `/kiss/users/assedic?id=${data.offseted_id}`,
              icon: "file",
            },
          ].map((config, index) => (
            <ActionItem
              key={`preview-option-jobber-${data.id}-${index}`}
              {...config}
            />
          )),
        ],
        [
          data?.schedules
            ? [
                <Block margin={spacings.m}>
                  <AvailabilitiesPreview schedules={data.schedules} />
                </Block>,
              ]
            : [],
        ],
      ],
    [dataUpdateCount]
  );

  return { informations, menu, tabs };
};

export default useJobberConfig;
