import { DatePicker, FormControl, Select } from "../../components";
import { useJobAvailableHours } from "../../modules/hooks";
import { getMinDate } from "../../utils/job-date-time";
import polyglot from "../../utils/polyglot";

const ChangeConfirmDateTime = ({
  isLoading,
  formik: {
    errors,
    touched,
    values,
    setFieldTouched,
    setFieldValue,
    setFieldError,
  },
}) => {
  const jobAvailableHours = useJobAvailableHours();

  const getOptsHours = (date) =>
    jobAvailableHours.getOptions({
      date,
    });

  const handleDayClick = (date) => {
    setFieldValue("date", date);
    if (
      values.start_hour?.toString() &&
      !getOptsHours(date).find((o) => o.value === values.start_hour)
    ) {
      setFieldValue("start_hour", "", false);
      setTimeout(() => {
        setFieldError("start_hour", polyglot.t("common.errors.field_required"));
      }, 0);
    }
  };

  return (
    <>
      <FormControl label={polyglot.t("new_date_time_change.modal.select_date")}>
        <DatePicker.Calendar
          name="date"
          format="DD/MM/YYYY"
          minDate={getMinDate()}
          onChange={handleDayClick}
        />
      </FormControl>
      <FormControl label={polyglot.t("new_date_time_change.modal.select_hour")}>
        <Select
          name="start_hour"
          onChange={({ value }) => {
            setFieldTouched("start_hour", true);
            setFieldValue("start_hour", value);
          }}
          error={!!touched.start_hour && errors.start_hour}
          value={values.start_hour}
          disabled={isLoading}
          options={getOptsHours(values.date)}
          placeholder={polyglot.t(
            "new_date_time_change.select_hour_placeholder"
          )}
        />
      </FormControl>
    </>
  );
};
export default ChangeConfirmDateTime;
