import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../utils";
import getCSRFToken from "../../utils/csrf";
import { useFetch, useToast } from "../hooks";
import polyglot from "@/utils/polyglot";

export const getZipCodes = (data) => {
  getCSRFToken();
  return axios.get("/cities/autocomplete_city", { params: data });
};

export const getAddresses = (data) => {
  getCSRFToken();
  return axios.get("/cities/autocomplete_address", { params: data });
};

export const getAddressDetails = (data) => {
  getCSRFToken();
  return axios.get("/cities/details_address", { params: data });
};

export const useGetZipCode = (values, { enabled }) => {
  const res = useFetch("/cities/autocomplete_city", {
    params: values,
    enabled,
    retry: false,
    shadow: true,
    refetchOnWindowFocus: false,
  });
  if (!values.term) {
    res.remove();
    return { ...res, data: null };
  }
  return res;
};

export const useGetZipCodeDetails = () => {
  const toast = useToast();
  return useMutation(
    ({ place_id }) =>
      api.get("/cities/details_city", {
        params: { place_id },
      }),
    {
      onError: () => toast.danger(polyglot.t("common.problem_has_occurred")),
    }
  );
};

export const useGetAddresses = (values, { enabled, queryKey }) => {
  const res = useFetch("/cities/autocomplete_address", {
    params: values,
    enabled,
    queryKey,
    apiBackwardCompatibility: true,
    retry: false,
    shadow: true,
    refetchOnWindowFocus: false,
  });
  if (!values.term) {
    res.remove();
    return { ...res, data: null };
  }
  return res;
};

export const useGetAddressDetails = ({ onSuccess, onError }) =>
  useMutation(
    ({ place_id, session_token }) =>
      api.get("/cities/details_address", {
        params: { place_id, session_token },
      }),
    {
      onSuccess: ({ data }, { place_id, label }) => {
        const coordinates = data.coordinates.split(",");
        const latitude = coordinates[0];
        const longitude = coordinates[1];
        onSuccess({
          place_id: place_id.toString(),
          label,
          location: { latitude, longitude },
        });
      },
      onError,
    }
  );
