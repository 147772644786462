import { useFormikContext } from "formik";
import polyglot from "../../../utils/polyglot";
import { MAX_NB_HOURS, MIN_NB_HOURS } from "../constants";
import useCategoryValue from "../useCategoryValue";
import { FormLargeStepper, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const NbHours = ({ title, children }) => {
  const { values, _propsInitialValues } = useFormikContext();
  const { getNbHours } = useCategoryValue();
  const recommendedNbHours = _propsInitialValues?.nb_hours || getNbHours();

  return (
    <>
      <StepTitle>
        {title ||
          polyglot.t("categories.estimated_time_duration_suits_for_you", {
            time: polyglot.toDuration(recommendedNbHours, true),
          })}
      </StepTitle>
      {children}
      <StyledFormControl>
        <FormLargeStepper
          divider={false}
          name="nb_hours"
          max={MAX_NB_HOURS}
          min={MIN_NB_HOURS}
          step={0.5}
          FieldChildren={polyglot.toDuration(values.nb_hours, true)}
        />
      </StyledFormControl>
    </>
  );
};

export default NbHours;
