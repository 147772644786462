import { useCallback } from "react";

const useFileThumbnail = () => {
  const get = useCallback(async (file) => {
    if (!file) {
      return { src: null, fileType: null };
    }

    try {
      if (file.type === "application/pdf") {
        // Handle PDF files
        return { src: null, fileType: "document" };
      }
      if (file.type.startsWith("image/")) {
        const src = URL.createObjectURL(file);
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve({
              src,
              fileType: "image",
              width: img.width,
              height: img.height,
            });
          };
          img.src = src;
        });
      }
      if (file.type.startsWith("video/")) {
        // Handle video files
        const video = document.createElement("video");
        video.preload = "metadata";

        const videoUrl = URL.createObjectURL(file);
        video.src = videoUrl;

        await new Promise((resolve) => {
          video.onloadedmetadata = () => {
            video.currentTime = 0;
            resolve();
          };
        });

        await new Promise((resolve) => {
          video.onseeked = resolve;
        });

        const canvas = document.createElement("canvas");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const thumbnailBlob = await new Promise((resolve) => {
          canvas.toBlob(resolve, "image/jpeg");
        });

        const src = URL.createObjectURL(thumbnailBlob);

        // Clean up video URL
        URL.revokeObjectURL(videoUrl);
        return {
          src,
          fileType: "video",
          width: video.videoWidth,
          height: video.videoHeight,
        };
      }
      if (file.type.startsWith("audio/")) {
        // Handle audio files
        const reader = new FileReader();
        const base64Audio = await new Promise((resolve, reject) => {
          reader.onload = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
        return { src: base64Audio, fileType: "audio" };
      }
      // Unsupported file type
      return { src: null, fileType: "unsupported" };
    } catch (error) {
      console.error("Error generating thumbnail:", error);
      return { src: null, fileType: null };
    }
  }, []);

  return { get };
};

export default useFileThumbnail;
