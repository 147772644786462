import { sizes, spacings } from "../../../assets/themes";
import { Block, Col, List, Row, Skeleton } from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import { SKELETON } from "../../../components/Styles/variants";

const ReferralSkeleton = () => (
  <Skeleton.Group>
    <Row gutter={[spacings.l]}>
      <Col size={{ xs: 12, lg: 8 }}>
        <Skeleton height={250} width="100%" sb />
        <Block marginBottom={spacings.m} marginTop={spacings.l}>
          <Skeleton height={sizes.size32} width="40%" sb />
        </Block>
        <Block marginBottom={spacings.l}>
          <Skeleton height={sizes.size16} rows={2} width="100%" st />
        </Block>
        <CardBase>
          <Block display="flex" justifyContent="space-around">
            <Skeleton height={100} sx sy width="45%" />
            <Skeleton height={100} sx sy width="45%" />
          </Block>
        </CardBase>
        <Block marginTop={spacings.l}>
          <Skeleton height={sizes.size24} width="40%" st sb />
          <Skeleton height={sizes.size16} width="100%" rows={1} st sb />
          <Skeleton height={sizes.size54} width="100%" rows={1} st sb />
        </Block>
        <Block marginTop={spacings.l}>
          <Skeleton height={sizes.size24} width="30%" st sb />
          <List.Item
            divider
            RightComponent={() => <Skeleton height={sizes.size16} width={75} />}
            LeftComponent={() => (
              <Skeleton
                shape={SKELETON.SHAPE.CIRCLE}
                width={sizes.size48}
                height={sizes.size48}
              />
            )}
          >
            <Skeleton height={sizes.size16} width="50%" sb />
            <Skeleton height={sizes.size16} width="30%" />
          </List.Item>
          <List.Item
            divider
            RightComponent={() => <Skeleton height={sizes.size16} width={75} />}
            LeftComponent={() => (
              <Skeleton
                shape={SKELETON.SHAPE.CIRCLE}
                width={sizes.size48}
                height={sizes.size48}
              />
            )}
          >
            <Skeleton height={sizes.size16} width="50%" sb />
            <Skeleton height={sizes.size16} width="30%" />
          </List.Item>
          <List.Item
            divider
            RightComponent={() => <Skeleton height={sizes.size16} width={75} />}
            LeftComponent={() => (
              <Skeleton
                shape={SKELETON.SHAPE.CIRCLE}
                width={sizes.size48}
                height={sizes.size48}
              />
            )}
          >
            <Skeleton height={sizes.size16} width="50%" sb />
            <Skeleton height={sizes.size16} width="30%" />
          </List.Item>
        </Block>
      </Col>
      <Col size={{ xs: 12, lg: 4 }}>
        <CardBase>
          <Skeleton height={sizes.size24} width="50%" sx sy />
          <Skeleton height={sizes.size16} rows={4} sx sy />
        </CardBase>
      </Col>
    </Row>
  </Skeleton.Group>
);
export default ReferralSkeleton;
