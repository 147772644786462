import { useState } from "react";
import { spacings } from "../../assets/themes";
import { Alert, Block } from "../../components";
import { ALERT } from "../../components/Styles/variants";
import JobberOfferItemContent from "../../pages/dashboard/jobs/JobberOfferItemContent";
import { JOBBER } from "./config";
import PreviewCursor from "./PreviewCursor";
// eslint-disable-next-line import/no-cycle
import UserPreviewContent from "./UserPreviewContent";

const PreviewElemJobOffer = ({ offer, divider }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [hoverElement, setHoverElement] = useState(null);
  return (
    <div ref={setReferenceElement}>
      <PreviewCursor node={hoverElement} isPortal />
      <UserPreviewContent
        kind={JOBBER}
        node={referenceElement}
        id={offer.jobber.id}
        noPortal
      />
      <div ref={setHoverElement}>
        <JobberOfferItemContent
          jobber={offer.jobber}
          id={offer.id}
          price={offer.price}
          price_per_hour={offer.price_per_hour}
          fees={offer.fees}
          urssaf={offer.urssaf}
          state={offer.state}
          auto_postulated={offer.auto_postulated}
          currency={offer.currency}
          nb_hours={offer.nb_hours}
          urlTargetBlank
          withGutters
          divider={divider}
        />
      </div>
      {offer.jobber.deleted && (
        <Block marginY={spacings.s} marginX={spacings.m}>
          <Alert.High kind={ALERT.KIND.DANGER} icon="user-times">
            Utilisateur supprimé
          </Alert.High>
        </Block>
      )}
    </div>
  );
};

export default PreviewElemJobOffer;
