import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useCategoryForm } from "../../../modules/hooks";
import {
  DESTINATION_FLOOR_PATH,
  DESTINATION_LIFT_PATH,
  FLOOR_PATH,
  LIFT_PATH,
  PROPERTY_TYPE_OPTIONS,
} from "../constants";
import {
  addObjectAtIndex,
  getAddressDetailsPageByValue,
  getDestinationAddressDetailsPageByValue,
} from "../utils";
import { FormRadios, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

// set default address pages are in hook : useAutoUpdatePages
const AddressKind = ({ title, name_prefix = "", index }) => {
  const { pages, setPages } = useCategoryForm();
  const { values } = useFormikContext();

  const updatePages = (value) => {
    const newPages = [...pages];
    const isDestination = name_prefix === "destination_";
    const filterArray = isDestination
      ? [DESTINATION_FLOOR_PATH, DESTINATION_LIFT_PATH]
      : [FLOOR_PATH, LIFT_PATH];

    setPages(
      addObjectAtIndex(
        // pages without existing address details pages
        newPages.filter((p) => !filterArray.includes(p.path)),
        isDestination
          ? getDestinationAddressDetailsPageByValue(value)
          : getAddressDetailsPageByValue(value),
        index
      )
    );
  };

  useEffect(() => {
    updatePages(values[`${name_prefix}property_type`]);
  }, [values[`${name_prefix}property_type`]]);

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <FormRadios
          name={`${name_prefix}property_type`}
          options={PROPERTY_TYPE_OPTIONS}
        />
      </StyledFormControl>
    </>
  );
};
export default AddressKind;
