import { useEffect, useRef } from "react";

const Marker = ({ map, woosmap, lat, lng, children }) => {
  const markerRef = useRef(null);

  useEffect(() => {
    const marker = document.createElement("div");
    if (map && woosmap) {
      const position = new woosmap.map.LatLng(lat, lng);
      marker.style.position = "absolute";
      marker.appendChild(markerRef.current);
      map.getDiv().appendChild(marker);

      const updatePosition = () => {
        const projection = map.getProjection();
        const point = projection.fromLatLngToDivPixel(position);
        marker.style.left = `${point.x}px`;
        marker.style.top = `${point.y}px`;
      };

      map.addListener("projection_changed", updatePosition);
      map.addListener("zoom_changed", updatePosition);
      map.addListener("center_changed", updatePosition);

      updatePosition();
    }
    return () => {
      marker.remove();
    };
  }, [map, lat, lng]);

  return (
    <div ref={markerRef} style={{ width: 1, height: 1 }}>
      {children}
    </div>
  );
};

export default Marker;
