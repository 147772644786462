import { useFormikContext } from "formik";
import React, { useState } from "react";
import styled, { css, withTheme } from "styled-components";
import { colors, sizes } from "../../assets/themes";
import {
  Alert,
  ArtworkLabel,
  Button,
  Container,
  Divider,
  Flow,
  List,
  Rating,
  Tag,
  Avatar,
  StatusEnhancer,
  Icon,
  Block,
} from "../../components";
import { TAG } from "../../components/Styles/variants";
import { getVoucherDetails } from "../../modules/routes";
import { DIRECT_PLANS } from "../../utils";
import polyglot from "../../utils/polyglot";
import AddVoucherModal from "../common/AddVoucherModal";
import PaymentMethodsUpdated from "../common/payment-methods";
import Plan from "./plans/Plan";

const StyledBanner = styled(List.Item)`
  ${({ theme }) => css`
    color: ${theme.colors.orangered900} !important;
    background-color: ${theme.colors.orangered200};
    min-height: 54px !important;
    span,
    .icon-spinner {
      color: ${theme.colors.orangered900} !important;
    }
    &:hover {
      background-color: ${theme.colors.orangered200}!important;
    }
  `}
`;

const Confirmation = ({ user, title, go, isLoading, goTo, initialStep }) => {
  const { values, status, setFieldValue, isValid, validateForm } =
    useFormikContext() || {};
  const [voucher, setVoucher] = useState(null);
  const [voucherError, setVoucherError] = useState("");
  const [voucherBannerIsLoading, setVoucherBannerIsLoading] = useState(false);
  const [voucherModalIsOpen, setVoucherModalIsOpen] = useState(false);
  const finalPrice = voucher
    ? voucher?.price_after_reduction
    : DIRECT_PLANS[values.plan].price;
  const selectedJobber = !values.jobber_id
    ? values.external_jobber
    : values.jobber;

  const handleSubmitVoucher = (voucherValues) => {
    setVoucherBannerIsLoading(true);
    const res = getVoucherDetails({
      code: voucherValues.code,
      plan: values.plan,
    });
    res
      .then(({ data }) => {
        setVoucher(data);
        setVoucherError("");
        setVoucherBannerIsLoading(false);
        setFieldValue("voucher_code", voucherValues.code);
        setVoucherModalIsOpen(false);
      })
      .catch((err) => {
        setVoucher(null);
        setVoucherError(err.response.data.error_message);
        setVoucherModalIsOpen(false);
        setVoucherBannerIsLoading(false);
      });
  };

  const handleRemoveVoucher = () => {
    setVoucher(null);
    setFieldValue("voucher_code", "");
    setVoucherError("");
  };

  return (
    <Container.Medium>
      <Flow.Item.Title>{title}</Flow.Item.Title>
      {status?.error && (
        <Alert.High kind="danger" title={status?.error} className="mb-3" />
      )}
      <div className="row">
        <div className="col-md-6 order-md-0 order-1">
          <span className="h5">Votre prestataire n'attend plus que vous</span>
          <List.Item
            divider={false}
            className="py-4"
            LeftComponent={() => (
              <Avatar
                src={selectedJobber.avatar}
                alt={selectedJobber.first_name}
                name={selectedJobber.first_name}
                size={sizes.size64}
              />
            )}
          >
            <StatusEnhancer isPro={selectedJobber.is_pro}>
              <p className="mb-0 font-weight-medium font-size-4">
                {selectedJobber.first_name}
              </p>
            </StatusEnhancer>
            {!values.jobber_id ? (
              <Tag.Medium kind={TAG.KIND.PRIMARY}>
                <i className="icon-user-plus" /> Nouveau prestataire
              </Tag.Medium>
            ) : (
              <Rating
                rate={selectedJobber.rate}
                count={selectedJobber.rates_count}
                type="compact"
              />
            )}
          </List.Item>
          <div className="">
            <List.Header title="Récapitulatif" />
            <List.Item
              divider={false}
              title={`Offre ${DIRECT_PLANS[values.plan].title}`}
              RightComponent={() => (
                <List.Elem.Label>
                  {polyglot.toSmartCurrency(DIRECT_PLANS[values.plan].price)} /
                  mois
                </List.Elem.Label>
              )}
            />
            {values.voucher_code && (
              <List.Item
                divider={false}
                LeftComponent={() => (
                  <Icon.Large name="tag" color={colors.primary} />
                )}
                title={values.voucher_code}
                RightComponent={() => (
                  <span className="badge badge-pill badge-primary p-2">
                    {voucher.body}
                  </span>
                )}
              />
            )}
            <Divider.Cell className="my-2" />
            <List.Item
              divider={false}
              RightComponent={() => (
                <List.Elem.Label className="text-dark font-size-4 font-weight-medium">
                  {polyglot.toSmartCurrency(finalPrice)}{" "}
                </List.Elem.Label>
              )}
            >
              <span className="font-weight-medium">Montant à payer</span>
            </List.Item>
          </div>
          {!!values.token &&
            (voucher?.price_after_reduction === 0 ||
              DIRECT_PLANS[values.plan].price === 0) && (
              <Alert.Low
                title="Votre carte de crédit a besoin d'être vérifiée pour un futur paiement, nous devons prélever 1 € qui seront immédiatement remboursé."
                className="mb-3 mt-3"
              />
            )}
          {!values.voucher_code && (
            <StyledBanner
              divider={false}
              onClick={() => handleSubmitVoucher({ code: "BIENVENUEYD" })}
              className="px-3"
              LeftComponent={() => <i className="icon-gift" />}
              RightComponent={
                voucherBannerIsLoading
                  ? () => <i className="icon-spinner icon-spin" />
                  : null
              }
            >
              <List.Elem.Title className="font-weight-medium">
                Activer mon premier mois offert
              </List.Elem.Title>
            </StyledBanner>
          )}
          <List.Header
            className="flex-wrap"
            title="Moyen de paiement"
            RightComponent={() => (
              <ArtworkLabel.Medium
                className="text-muted text-nowrap ml-n3"
                title="Paiement sécurisé"
                icon="icon-lock"
              />
            )}
          />
          <PaymentMethodsUpdated.A divider methods={values.payment_methods} />
          <List.Item
            divider={values.voucher_code === "BIENVENUEYD"}
            chevron={!values.voucher_code}
            title={values.voucher_code || "Ajouter un code promotionnel"}
            onClick={() => {
              values.voucher_code
                ? handleRemoveVoucher()
                : setVoucherModalIsOpen(true);
            }}
            RightComponent={
              values.voucher_code
                ? () => (
                    <List.Elem.Label className="text-primary font-weight-medium">
                      Supprimer
                    </List.Elem.Label>
                  )
                : null
            }
            LeftComponent={() => (
              <Block
                width={sizes.size32}
                display="flex"
                justifyContent="center"
              >
                <Icon.Large name="tag" color={colors.primary} />
              </Block>
            )}
          />

          {voucherError && <p className="text-danger mt-2">{voucherError}</p>}

          <div className="d-flex flex-column align-items-start">
            <Button.Large
              type="submit"
              block
              isLoading={isLoading}
              disabled={!isValid}
              className="mt-3"
            >
              {finalPrice > 0
                ? `Activer l’offre ${
                    DIRECT_PLANS[values.plan].title
                  } pour  ${polyglot.toSmartCurrency(
                    DIRECT_PLANS[values.plan].price
                  )} / mois`
                : "Activer l'essai gratuit"}
            </Button.Large>
            {voucher?.body && finalPrice <= 0 && (
              <p className="text-muted font-size-2 mt-2 mb-0">
                {voucher.body}, puis{" "}
                {polyglot.toSmartCurrency(DIRECT_PLANS[values.plan].price)} /
                mois, sans engagement
              </p>
            )}
            <Button.Large
              kind="secondary"
              onClick={() => (initialStep !== 0 ? goTo(10) : go(-2))}
              className="mt-2"
            >
              Retour
            </Button.Large>
          </div>

          <AddVoucherModal
            isOpen={voucherModalIsOpen}
            onClose={() => setVoucherModalIsOpen(false)}
            onSubmit={handleSubmitVoucher}
          />
        </div>
        <div className="col-md-6 mb-4 mb-md-0">
          <Plan kind={values.plan} />
        </div>
      </div>
    </Container.Medium>
  );
};

export default withTheme(Confirmation);
