import { Form, Formik } from "formik";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  useGetOfferInstantPayment,
  usePayInstant,
} from "@/modules/routes/jobber-instant-payment-routes";

import { colors, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Button,
  Container,
  H4,
  Icon,
  Link,
  List,
  Spinner,
  Stepper,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import { LIST, STEPPER } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import { useTokenizeQuery } from "@/modules/routes/payment-routes";
import UserPreview from "@/pages-admin/UserPreview";
import { JOBBER } from "@/pages-admin/UserPreview/config";
import PaymentMethodsUpdated from "@/pages/common/payment-methods";
import { paymentCreditCardValidation, setPaymentInitialValues } from "@/utils";
import polyglot from "@/utils/polyglot";
import { getPaymentSummaryItems } from "../common/payment-methods/utils/get-payment-summary-items";
import Summary from "../jobber-booking/Summary";
import EditPricePerHourModal from "./EditPricePerHourModal";
import BookingHeader from "../jobber-booking/BookingHeader";
import CesuItem from "@/components/CesuItem";

const JobberInstantPayment = ({ id, initial_values = {} }) => {
  const breakpoints = useBreakpoints();
  const [nbHours, setNbHours] = useState(initial_values?.nb_hours);
  const [pricePerHour, setPricePerHour] = useState(
    initial_values?.price_per_hour
  );

  const [editPricePerHourModalIsOpen, setEditPricePerHourModalIsOpen] =
    useState(false);
  const { data, isLoading, isFetching, refetch } = useGetOfferInstantPayment(
    {
      id,
      price_per_hour: pricePerHour,
      nb_hours: nbHours,
    },
    { enabled: false }
  );
  const formikRef = useRef();
  const paymentMethodRef = useRef();
  const confirm = usePayInstant({ id });
  const confirmPayment = useTokenizeQuery(confirm.mutateAsync);

  const handleSubmit = (values) => {
    confirmPayment.mutate({
      ...values,
      nb_hours: values.nb_hours,
      id,
    });
  };

  useLayoutEffect(() => {
    if (confirmPayment.isError) {
      const mustResetCard = confirmPayment.error.data?.data?.must_reset_card;
      if (mustResetCard) {
        paymentMethodRef.current?.reset();
        formikRef.current?.setValues((s) => ({
          ...s,
          default_payment_method: "",
          payment_method: "",
          card_number: "",
          card_type: "",
          token: "",
        }));
      }
    }
  }, [confirmPayment.isError, confirmPayment.isSuccess, formikRef.current]);

  const getMaxNbHours = (values) => {
    if (values.cesu) {
      return data?.pricing_with_cesu[data?.pricing_with_cesu.length - 1]
        .nb_hours;
    }
    return data?.pricing_without_cesu[data?.pricing_without_cesu.length - 1]
      .nb_hours;
  };

  const getMinNbHours = (values) => {
    if (values.cesu) {
      return data?.pricing_with_cesu[0].nb_hours;
    }
    return data?.pricing_without_cesu[0].nb_hours;
  };

  const getPricing = (values) => {
    if (values.cesu) {
      return (
        data?.pricing_with_cesu.find((p) => p.nb_hours === values.nb_hours) ||
        {}
      );
    }
    return (
      data?.pricing_without_cesu.find((p) => p.nb_hours === values.nb_hours) ||
      {}
    );
  };

  useEffect(async () => {
    // refetch when pricePerHour is set due to submit
    await refetch();
    setEditPricePerHourModalIsOpen(false);
  }, [pricePerHour]);

  const handleEditPricePerHourSubmit = ({ price_per_hour }) => {
    // Set data for update variable in the fetch hook.
    setPricePerHour(price_per_hour);
    formikRef.current.setFieldValue("price_per_hour", price_per_hour);
    setNbHours(formikRef.current.values.nb_hours);
  };
  return (
    <>
      <BookingHeader />
      {!isLoading && data ? (
        <Block marginY={spacings.ml}>
          {data?.job_url && (
            <Container.Small>
              <Block marginBottom={spacings.s}>
                <Link
                  href={data?.job_url}
                  color={colors.muted}
                  css={`
                    display: inline-block;
                  `}
                >
                  <Icon.Medium name="arrow-left" />
                  &nbsp;
                  {polyglot.t("common.back")}
                </Link>
              </Block>
            </Container.Small>
          )}
          <Block marginY={spacings.ml}>
            <Container.Small
              css={`
                margin-top: ${spacings.ml};
                margin-bottom: ${spacings.ml};
              `}
            >
              <Block display="flex" alignItems="" />

              <Formik
                validateOnMount
                innerRef={formikRef}
                validationSchema={yup.lazy((values) => {
                  if (getPricing(values).total > 0) {
                    return paymentCreditCardValidation;
                  }
                  return yup.object().shape({});
                })}
                initialValues={{
                  ...initial_values,
                  ...setPaymentInitialValues({
                    ...initial_values,
                    ...data,
                  }),
                  payment_method:
                    initial_values.default_payment_method ||
                    data.default_payment_method ||
                    initial_values.payment_method ||
                    data.payment_method,
                  cesu: initial_values?.cesu || false,
                  nb_hours: initial_values.nb_hours || data.nb_hours,
                  price_per_hour:
                    initial_values.price_per_hour || data.price_per_hour,
                }}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, isValid }) => (
                  <Form id="instant-payment-form">
                    <CardBase flat>
                      <Block paddingTop={spacings.m} paddingBottom={spacings.s}>
                        <Block
                          display="flex"
                          alignItems="center"
                          gap={spacings.m}
                          flexDirection="column"
                          css=">div:first-of-type{width: inherit}"
                        >
                          <UserPreview kind={JOBBER} id={data.jobber.id}>
                            <Avatar
                              src={data.jobber.avatar}
                              size={sizes.size96}
                            />
                          </UserPreview>
                          <H4 align="center">
                            {polyglot.t(
                              "job_review.how_many_nb_hours_done_by_jobber"
                            )}
                          </H4>
                        </Block>

                        <Block marginTop={spacings.l} marginBottom={spacings.m}>
                          <Stepper
                            size={STEPPER.SIZE.LARGE}
                            step={0.5}
                            max={getMaxNbHours(values)}
                            min={getMinNbHours(values)}
                            value={values.nb_hours}
                            onChange={(value) =>
                              setFieldValue("nb_hours", value)
                            }
                            name="extra_duration"
                            hint={() => (
                              <Block display="flex" alignItems="baseline">
                                <Body14 color={colors.muted}>
                                  {polyglot.t("instant_payment.hourly_rate")}
                                </Body14>
                                &nbsp;
                                <Button.Link
                                  RightComponent={() => (
                                    <Icon.Medium name="edit" />
                                  )}
                                  onClick={() =>
                                    setEditPricePerHourModalIsOpen(true)
                                  }
                                  css={`
                                    padding-left: 0;
                                    padding-right: 0;
                                  `}
                                >
                                  {polyglot.toSmartCurrency(
                                    values.price_per_hour,
                                    {
                                      pricePerHour: true,
                                    }
                                  )}
                                </Button.Link>
                              </Block>
                            )}
                          >
                            {polyglot.toDuration(values.nb_hours, true)}
                          </Stepper>
                          <EditPricePerHourModal
                            isLoading={isFetching}
                            pricePerHour={values.price_per_hour}
                            isOpen={editPricePerHourModalIsOpen}
                            onClose={() =>
                              setEditPricePerHourModalIsOpen(false)
                            }
                            initialPricePerHour={data.initial_price_per_hour}
                            onSubmit={handleEditPricePerHourSubmit}
                            max={data.price_per_hour_max}
                            min={data.price_per_hour_min}
                          />
                        </Block>
                      </Block>
                    </CardBase>
                    <List.Header>{polyglot.t("common.summary")}</List.Header>
                    <Block>
                      <CardBase flat>
                        <Block
                          paddingX={spacings.m}
                          paddingBottom={spacings.xs}
                          paddingTop={{ xs: spacings.xs, md: "0px" }}
                        >
                          <Summary pricing={getPricing(values)} hideVoucher />
                        </Block>
                      </CardBase>
                    </Block>
                    <Block marginBottom={spacings.m}>
                      <List.Header>
                        {polyglot.t("payment_methods.title")}
                      </List.Header>
                      <CardBase flat>
                        <Block marginX={spacings.m}>
                          <PaymentMethodsUpdated
                            disabled={getPricing(values).total === 0}
                            methods={data.payment_methods}
                            ref={paymentMethodRef}
                          />
                          {data.cesu?.amount > 0 && (
                            <CesuItem
                              shape={LIST.SHAPE.ROUND}
                              divider={false}
                              withGutters
                              eligibility={data.cesu?.eligibility}
                              amount={data.cesu?.amount}
                              checked={values.cesu}
                              onChange={() =>
                                setFieldValue("cesu", !values.cesu)
                              }
                            />
                          )}

                          <Block marginY={spacings.m}>
                            <Body14 color={colors.muted} align="center">
                              {polyglot.t(
                                "instant_payment.instant_payment_cannot_be_refunded"
                              )}
                            </Body14>
                          </Block>
                          <Block
                            position="sticky"
                            bottom={0}
                            paddingBottom={spacings.m}
                          >
                            <PaymentMethodsUpdated.PayButtonEnhancer
                              block
                              textType="pay"
                              disabled={!isValid}
                              amount={getPricing(values).total}
                              lineItems={getPaymentSummaryItems(
                                getPricing(values)
                              )}
                              isLoading={
                                confirmPayment.isLoading ||
                                confirmPayment.isSuccess
                              }
                              onPaymentAuthorized={({ token, session }) =>
                                handleSubmit({
                                  ...values,
                                  token,
                                  applePaySession: session,
                                })
                              }
                            >
                              <Button.Large
                                block
                                type="submit"
                                form="instant-payment-form"
                                isLoading={confirmPayment.isLoading}
                                disabled={!isValid}
                              >
                                {polyglot.t("job_review.pay_n_and_confirm", {
                                  n: polyglot.toSmartCurrency(
                                    getPricing(values).total
                                  ),
                                })}
                              </Button.Large>
                            </PaymentMethodsUpdated.PayButtonEnhancer>
                          </Block>
                        </Block>
                      </CardBase>
                    </Block>
                  </Form>
                )}
              </Formik>
            </Container.Small>
          </Block>
        </Block>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={spacings.m}
        >
          <Spinner.Medium />
        </Block>
      )}
    </>
  );
};

export default JobberInstantPayment;
