import { VISA_MASTERCARD, VISA, MASTERCARD } from "../constants";

export function getCreditCardTypeByName(name) {
  const formatedName = name?.toLowerCase();
  if (
    formatedName === VISA ||
    formatedName === VISA_MASTERCARD ||
    formatedName === MASTERCARD
  ) {
    return VISA_MASTERCARD;
  }
  return formatedName;
}
