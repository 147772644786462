import { useState } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { colors, spacings } from "@/assets/themes";
import {
  Alert,
  Block,
  Body14,
  Body16,
  Button,
  Carousel,
  ConditionnalWrapper,
  Container,
  Divider,
  H1,
  H5,
  Icon,
  Info,
  List,
  MobileHeader,
  ReadMore,
  Shape,
  Tag,
} from "@/components";
import { getRate } from "@/components/Rating";
import { BUTTON, MOBILE_HEADER, TAG } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import { useGetJobberProfile } from "@/modules/routes/jobber-profile-routes";
import { ID_OFFSET } from "@/utils";
import polyglot from "@/utils/polyglot";
import ShareModal from "../common/ShareModal";
import JobberAvatarInfo from "./JobberAvatarInfo";
import JobberInfoItem from "./JobberInfoItem";
import JobberInterventionArea from "./JobberInterventionArea";
import JobberPaymentInformations from "./JobberPaymentInformations";
import JobberProfileSkeleton from "./JobberProfileSkeleton";
import JobberProfileTrucks from "./JobberProfileTrucks";
import JobberRatingsModal from "./JobberRatingsModal";
import JobberSkillInformations from "./JobberSkillInformations";
import JobberStickyFooter from "./JobberStickyFooter";
import RateItem from "./RateItem";
import RatesProgression from "./RatesProgression";
import JobberProfileCalendar from "./JobberProfileCalendar";
import JobberProfileJobs from "./JobberProfileJobs";

const getIdFromPathname = (pathname) =>
  parseInt(pathname.split("-").pop(), 10) - ID_OFFSET;

const JobberProfile = () => {
  const location = useLocation();
  const id = getIdFromPathname(location.pathname);
  const history = useHistory();
  const [shareModalIsOpen, setShareModalIsOpen] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const showRatingsModal = queryParams.get("ratings") === "true";
  const breakpoints = useBreakpoints();

  const { data, isLoading } = useGetJobberProfile({
    id,
    skill: queryParams.get("skill"),
    category: queryParams.get("category"),
    offer_id: queryParams.get("offer_id"),
  });

  const handleGoToRating = ({ id, rate }) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("ratings", "true");
    if (id) searchParams.set("ratings_id", id);
    if (rate) searchParams.set("ratings_rate", rate);
    history.replace({ search: searchParams.toString() });
  };

  return (
    <div>
      {!isLoading ? (
        <Block
          paddingTop={{ xs: "0px", md: spacings.l }}
          paddingBottom={{ xs: "0px", md: spacings.l }}
          position="relative"
        >
          <ShareModal
            phrase={polyglot.t("jobber_profile.profile_share", {
              first_name: data.jobber.first_name,
            })}
            referralUrl={data.share_url}
            copySuccessPhrase={polyglot.t("jobber_profile.share_success")}
            isOpen={shareModalIsOpen}
            onClose={() => setShareModalIsOpen(false)}
          />
          <ConditionnalWrapper
            condition={breakpoints.get({ xs: false, md: true })}
            wrapper={(children) => (
              <Container.Large>{children}</Container.Large>
            )}
          >
            {breakpoints.get({ xs: true, md: false }) && (
              <Block position="absolute" top="0" left="0" right="0" zIndex="10">
                <MobileHeader
                  kind={MOBILE_HEADER.KIND.FLOATING}
                  onBack={() => history.goBack()}
                  actionButtons={[
                    {
                      icon: "share",
                      onClick: () => setShareModalIsOpen(true),
                    },
                  ]}
                />
              </Block>
            )}
            <Block
              display="grid"
              gridCols={{ xs: "minmax(0, 1fr)", md: "350px minmax(0, 1fr)" }}
              gap={{ xs: spacings.ml, md: spacings.xl }}
            >
              <Block
                position={{ xs: "static", md: "sticky" }}
                top={`calc(var(--nav-height) + ${spacings.l})`}
                height="fit-content"
              >
                <JobberAvatarInfo
                  age={data.age}
                  id={data.jobber.id}
                  is_top_jobber={data.jobber.is_top_jobber}
                  first_name={data.jobber.first_name}
                  role_translated={data.role_translated}
                  avatar={data.jobber.avatar}
                  avatar_large={data.jobber.avatar_large}
                  price_per_hour={data.price_per_hour}
                  rates_count={data.jobber.rates_count}
                  rate={data.jobber.rate}
                  member_since={data.jobber.member_since}
                  is_pro={data.jobber.is_pro}
                />
                {breakpoints.get({ xs: false, md: true }) && (
                  <Block paddingTop={spacings.sm}>
                    <JobberStickyFooter data={data} sticky={false} />
                  </Block>
                )}
              </Block>
              <ConditionnalWrapper
                condition={breakpoints.get({ xs: true, md: false })}
                wrapper={(children) => (
                  <Container.Large>{children}</Container.Large>
                )}
              >
                <Block>
                  <Block
                    marginBottom={spacings.m}
                    display={{ xs: "none", md: "flex" }}
                    alignItems="center"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <H1>{data.jobber.first_name}</H1>
                    <Button.Small
                      kind={BUTTON.KIND.MINIMAL}
                      LeftComponent={() => <Icon.Medium name="share" />}
                      onClick={() => setShareModalIsOpen(true)}
                    >
                      {polyglot.t("common.share")}
                    </Button.Small>
                  </Block>

                  {data.rebooking_jobs?.length > 0 && (
                    <JobberProfileJobs data={data} />
                  )}

                  {data.availabilities && (
                    <>
                      <List.Header>
                        {polyglot.t("jobber_profile.availabilities")}
                      </List.Header>
                      <JobberProfileCalendar
                        user={data.jobber}
                        availabilities={data.availabilities}
                        subcategories={data.subcategories}
                      />
                      <Block marginY={spacings.ml}>
                        <Divider.Cell />
                      </Block>
                    </>
                  )}

                  <JobberSkillInformations data={data} />

                  {data.description && (
                    <>
                      <Block marginTop={spacings.ml}>
                        <Divider.Cell />
                      </Block>
                      <List.Header>
                        {polyglot.t("jobber_profile.about")}
                      </List.Header>
                      <Body16>
                        <ReadMore
                          numberOfLines={4}
                          css={`
                            button {
                              color: ${colors.body}!important;
                            }
                          `}
                        >
                          {data.description}
                        </ReadMore>
                      </Body16>
                    </>
                  )}

                  {data.commitments?.length > 0 && (
                    <>
                      <Block marginTop={spacings.ml}>
                        <Divider.Cell />
                      </Block>
                      <List.Header>
                        {polyglot.t("jobber_profile.commitments")}
                      </List.Header>
                      <Block display="flex" flexWrap="wrap" gap={spacings.xs}>
                        {data.commitments.map((commitment) => (
                          <Tag.Small
                            kind={TAG.KIND.MUTED}
                            key={`jobber-profile-commitment-${commitment}`}
                          >
                            {polyglot.t(`commitments.${commitment}.name`)}
                          </Tag.Small>
                        ))}
                      </Block>
                    </>
                  )}

                  {data.stuffs?.length > 0 && (
                    <>
                      <Block marginTop={spacings.ml}>
                        <Divider.Cell />
                      </Block>
                      <List.Header>
                        {polyglot.t("jobber_profile.equipments")}
                      </List.Header>
                      <Block display="flex" flexWrap="wrap" gap={spacings.xs}>
                        {data.stuffs.map((stuff) => (
                          <Tag.Small
                            kind={TAG.KIND.WHITE}
                            key={`jobber-profile-stuff-${stuff}`}
                          >
                            {polyglot.t(`skills.stuffs.${stuff}`)}
                          </Tag.Small>
                        ))}
                      </Block>
                    </>
                  )}

                  {data.vehicles?.length > 0 && (
                    <>
                      <Block marginTop={spacings.ml}>
                        <Divider.Cell />
                      </Block>
                      <List.Header>
                        {polyglot.t("jobber_profile.vehicles_availables")}
                        &nbsp;({data.vehicles.length})
                      </List.Header>
                      <JobberProfileTrucks data={data} />
                    </>
                  )}

                  <>
                    <Block marginTop={spacings.ml}>
                      <Divider.Cell />
                    </Block>
                    <List.Header>
                      {polyglot.t("jobber_profile.quality_criteria")}
                    </List.Header>
                    {!data.good_punctuality_percentage &&
                      !data.good_interpersonal_percentage && (
                        <Block marginBottom={spacings.s}>
                          <Alert.Low>
                            {polyglot.t(
                              "jobber_profile.jobber_no_enough_rate_for_quality",
                              { first_name: data.jobber.first_name }
                            )}
                          </Alert.Low>
                        </Block>
                      )}
                    <Block display="flex" flexWrap="wrap" gap={spacings.xs}>
                      <List.Group>
                        {[
                          {
                            icon: "users",
                            key: "interpersonal",
                            value: parseInt(
                              data.good_interpersonal_percentage,
                              10
                            ),
                          },
                          {
                            icon: "clock",
                            key: "punctuality",
                            value: parseInt(
                              data.good_punctuality_percentage,
                              10
                            ),
                          },
                        ].map((quality) => (
                          <List.Item
                            key={`jobber-profile-quality-${quality.key}`}
                            LeftComponent={() => (
                              <Shape.Small
                                backgroundColor={colors.transparent}
                                color={colors.body}
                              >
                                <Icon.Large name={quality.icon} />
                              </Shape.Small>
                            )}
                            RightComponent={() => (
                              <H5>{quality.value || `- `}%</H5>
                            )}
                          >
                            <Body16 strong>
                              {polyglot.t(`jobber_profile.${quality.key}`)}
                            </Body16>
                            <Body14>
                              {polyglot.t(
                                `jobber_profile.${quality.key}_description`
                              )}
                            </Body14>
                          </List.Item>
                        ))}
                      </List.Group>
                    </Block>
                  </>

                  <>
                    <Block marginTop={spacings.ml}>
                      <Divider.Cell />
                    </Block>
                    <List.Header>
                      <Icon.XLarge
                        name="star-solid"
                        css={`
                          padding-right: ${spacings.xs};
                        `}
                      />
                      <span>
                        {getRate(data.jobber.rate)} (
                        {polyglot.t("common.rates", {
                          count: Number(data.jobber.rates_count),
                        })}
                        )
                      </span>
                    </List.Header>
                    <Block spaceY={spacings.m}>
                      <RatesProgression
                        rates_count_for_rate={data.rates_count_for_rate}
                        onChange={handleGoToRating}
                      />
                      <Carousel
                        slideToShow={{
                          xs: 1.2,
                          sm: 1.5,
                          md: 1.2,
                          xl: 2.5,
                        }}
                        gutter={spacings.s}
                        draggable={breakpoints.get({ xs: true, md: false })}
                      >
                        {data.rates.map((rate) => (
                          <RateItem
                            key={`jobber-profile-rate-card-${rate.id}`}
                            rate={rate.rate}
                            onClick={() => handleGoToRating({ id: rate.id })}
                            customer={rate.customer}
                            job_title={rate.job_title}
                            comment={rate.comment}
                            created_at={rate.created_at}
                            isCard
                          />
                        ))}
                      </Carousel>
                      {data.jobber.rates_count > 0 && (
                        <Button.Medium
                          kind={BUTTON.KIND.SECONDARY}
                          block
                          onClick={handleGoToRating}
                        >
                          {polyglot.t("jobber_profile.see_ratings")} (
                          {data.total_count})
                        </Button.Medium>
                      )}
                    </Block>
                  </>

                  <>
                    <Block marginTop={spacings.ml}>
                      <Divider.Cell />
                    </Block>
                    <JobberInterventionArea
                      location={data.location}
                      jobber={data.jobber}
                    />
                  </>

                  <>
                    <Block marginTop={spacings.ml}>
                      <Divider.Cell />
                    </Block>
                    <List.Header>
                      {polyglot.t("jobber_profile.verified_informations")}
                    </List.Header>
                    <JobberInfoItem icon="check-circle">
                      {polyglot.t("jobber_profile.verified_identify")}
                    </JobberInfoItem>
                    <JobberInfoItem icon="check-circle">
                      {polyglot.t("jobber_profile.verified_phone_number")}
                    </JobberInfoItem>
                    {data.jobber.is_pro && (
                      <JobberInfoItem icon="check-circle">
                        {polyglot.t("jobber_profile.verified_pro")}
                      </JobberInfoItem>
                    )}
                    <Block marginTop={spacings.s}>
                      <Info
                        css="display: inline-block;"
                        render={() => (
                          <Button.Link
                            accentColor={BUTTON.ACCENT_COLOR.BODY}
                            css={`
                              padding-left: 0;
                              padding-right: 0;
                              min-height: inherit;
                            `}
                            onClick={() => {}}
                          >
                            <Body14 strong>
                              {polyglot.t(
                                "jobber_profile.learn_about_verified_identity"
                              )}
                            </Body14>
                          </Button.Link>
                        )}
                      >
                        {polyglot.t(
                          "jobber_profile.identity_verification_details"
                        )}
                      </Info>
                    </Block>
                  </>

                  <>
                    <Block marginTop={spacings.ml}>
                      <Divider.Cell />
                    </Block>
                    <JobberPaymentInformations
                      payment_methods={data.payment_methods}
                    />
                  </>

                  <>
                    <Block>
                      <Divider.Cell />
                    </Block>
                    <List.Header>
                      {polyglot.t("jobber_profile.insurrance_warranties")}
                    </List.Header>
                    <JobberInfoItem icon="double-arrow">
                      <Body16 strong>
                        {polyglot.t("jobber_profile.jobber_replacement")}
                      </Body16>
                      <Body14 color={colors.muted}>
                        {polyglot.t(
                          "jobber_profile.jobber_replacement_description"
                        )}
                      </Body14>
                    </JobberInfoItem>
                    <JobberInfoItem icon="support">
                      <Body16 strong>
                        {polyglot.t("jobber_profile.assistance")}
                      </Body16>
                      <Body14 color={colors.muted}>
                        {polyglot.t("jobber_profile.assistance_description")}
                      </Body14>
                    </JobberInfoItem>
                    {polyglot.insurance && (
                      <JobberInfoItem icon="umbrella">
                        <Body16 strong>
                          {polyglot.t("jobber_profile.insurrance")}
                        </Body16>
                        <Body14 color={colors.muted}>
                          {polyglot.t("warranties.insurrance_short", {
                            insurance: polyglot.insurance,
                          })}
                        </Body14>
                      </JobberInfoItem>
                    )}
                  </>
                </Block>
              </ConditionnalWrapper>
            </Block>
          </ConditionnalWrapper>
          {breakpoints.get({ xs: true, md: false }) && (
            <JobberStickyFooter data={data} />
          )}
          {showRatingsModal && <JobberRatingsModal data={data} />}
        </Block>
      ) : (
        <JobberProfileSkeleton />
      )}
    </div>
  );
};

export default JobberProfile;
