import { Field } from "formik";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../../../assets/themes";
import { Block, List, Radio } from "../../../../components";
import { LIST } from "../../../../components/Styles/variants";

const StyledListItem = styled(List.Item)`
  border: solid ${borderWidth.m}
    ${({ checked }) => (checked ? colors.primary : colors.border)};
`;

const FormHorizontalCardRadios = ({
  name,
  options,
  children,
  onChange,
  imageSize = sizes.size96,
}) => (
  <>
    {options.map(({ value, label, subtitle, image }) => (
      <Field
        name={name}
        type="radio"
        value={value}
        key={`option-key-${name}-${value}`}
      >
        {({ field, form }) => (
          <Block marginBottom={spacings.s}>
            <StyledListItem
              shape={LIST.SHAPE.ROUND}
              checked={field.checked}
              withGutters
              divider={false}
              LeftComponent={() => (
                <img
                  alt=""
                  src={image}
                  style={{
                    objectFit: "cover",
                    width: imageSize,
                    height: imageSize,
                    borderRadius: radius.m,
                  }}
                />
              )}
              onClick={() => {
                if (onChange) onChange(value, form);
                form.setFieldValue(name, value);
                form.setFieldTouched(name, true);
              }}
              RightComponent={() => <Radio tabIndex={-1} {...field} />}
            >
              <List.Elem.Title strong>{label}</List.Elem.Title>
              <List.Elem.Subtitle>{subtitle}</List.Elem.Subtitle>
              {children}
            </StyledListItem>
          </Block>
        )}
      </Field>
    ))}
  </>
);

export default FormHorizontalCardRadios;
