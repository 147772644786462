import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { ScrollableDatePicker } from "@/components";
import { addDays, formatTo } from "@/components/DatePicker/utils";
import { useCategoryForm } from "@/modules/hooks";
import { useGetAvailabilities } from "@/modules/routes/category-forms-routes";
import { getHoursOptions, getMinDate } from "@/utils/job-date-time";
import { CATEGORY_DATE_FORMAT } from "../../constants";
import polyglot from "@/utils/polyglot";

const DEFAULT_RANGE = 15;

const FormDatePickerPrivate = ({
  onChange,
  names = ["date", "start_hour"],
}) => {
  const formikContext = useFormikContext();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const { availabilities: _availabilities, setAvailabilities } =
    useCategoryForm();

  const availabilities = _availabilities?.[values.nb_hours] || {};

  const minDate = useMemo(
    () => formatTo(getMinDate(), null, CATEGORY_DATE_FORMAT),
    []
  );

  const defaultEndDate = useMemo(
    () =>
      formatTo(
        addDays(
          moment(values.date || new Date(), CATEGORY_DATE_FORMAT).toDate(),
          DEFAULT_RANGE
        ),
        "YYYY-MM-DD",
        CATEGORY_DATE_FORMAT
      ),
    [values.date]
  );

  const [endDate, setEndDate] = useState(defaultEndDate);

  const { data, refetch, isSuccess } = useGetAvailabilities(
    {
      nb_hours: values.nb_hours,
      start_date: availabilities ? Object.keys(availabilities).pop() : minDate,
      end_date: endDate,
      private_jobber_id: values.private_jobber_id,
    },
    { enabled: false }
  );

  useEffect(() => {
    setAvailabilities((old) => ({
      ...old,
      [values.nb_hours]: { ...old[values.nb_hours], ...data?.availabilities },
    }));
  }, [isSuccess]);

  const handleChange = ({ start_hour, date }) => {
    if (onChange) onChange({ start_hour, date }, formikContext);
    setFieldTouched(names[0], true);
    setFieldValue(names[0], date);
    setFieldTouched(names[1], true);
    setFieldValue(names[1], start_hour);
  };

  const getOptions = (date) => {
    if (Object.keys(availabilities).length > 0) {
      return getHoursOptions(date, "YYYY-MM-DD", false, {
        availabilities,
      });
    }
    return [];
  };

  useEffect(() => {
    refetch();
  }, [endDate, refetch]);

  const handleCarouselChange = (index, { totalPages, activePage }) => {
    if (activePage + 2 >= totalPages) {
      const newEndDate = formatTo(
        addDays(endDate, DEFAULT_RANGE),
        null,
        CATEGORY_DATE_FORMAT
      );
      setEndDate(newEndDate);
    }
  };

  return (
    <ScrollableDatePicker
      minDate={minDate}
      format={CATEGORY_DATE_FORMAT}
      values={{ date: values[names[0]], start_hour: values[names[1]] }}
      availabilities={availabilities}
      slots={getOptions}
      onChange={handleChange}
      onCarouselChange={handleCarouselChange}
      label={polyglot.t("categories.select_start_hour")}
    />
  );
};

export default FormDatePickerPrivate;
