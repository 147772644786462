import { useRef } from "react";
import { List } from "@/components";
import { LIST_HEADER } from "@/components/Styles/variants";
import { useIntersectionObserver } from "@/modules/hooks";

const InventoryHeader = ({ children, onIntersect }) => {
  const elementRef = useRef(null);

  useIntersectionObserver({
    target: elementRef,
    onIntersect,
    threshold: 0.3,
    enabled: true,
  });
  return (
    <div
      ref={elementRef}
      css={`
        scroll-margin-top: -100px;
      `}
    >
      <List.Header withGutters kind={LIST_HEADER.KIND.SUBTITLE}>
        {children}
      </List.Header>
    </div>
  );
};

export default InventoryHeader;
