import { spacings } from "../../../assets/themes";
import { Alert, Block } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { INFORMATIONS_PATH, NB_HOURS_PATH } from "../constants";

const config = {
  isCustom: true,
  multipleJobbersAllowed: true,
  initialTitleIsEmpty: true,
  renderPages: (pages) => {
    const newPages = [...pages];
    const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
    const detailsIndex = pages.findIndex((p) => p.path === INFORMATIONS_PATH);
    const { View } = newPages[detailsIndex];
    newPages[detailsIndex].View = () => (
      <div>
        <Block marginTop={spacings.sm}>
          <Alert.Low>
            {polyglot.t("categories.tiling_services_not_accepted")}
          </Alert.Low>
        </Block>
        <View />
      </div>
    );
    newPages[nbHoursIndex].title = polyglot.t(
      "categories.how_many_hours_of_service_do_you_need"
    );
    return newPages;
  },
};

export function useCategory1108() {
  return {
    initialValues: { nb_hours: 2 },
    config,
  };
}
