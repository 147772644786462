import { useState } from "react";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Button,
  Icon,
  List,
  Popover,
  Rating,
  Tag,
} from "../../components";
import { BUTTON, LIST, POPOVER } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import PublishJobModal from "../dashboard/job/PublishJobModal";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";
import UserPreview from "../../pages-admin/UserPreview/UserPreview";
import { JOBBER } from "../../pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";

const JobPrivateJobber = () => {
  const { data } = useJob();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleClick = () => {
    setModalIsOpen(true);
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (
    <Block
    // border={`solid ${borderWidth.s} ${colors.border}`}
    // borderRadius={radius.ml}
    >
      <UserPreview id={data.private_jobber.id} kind={JOBBER}>
        <Popover.Enhancer
          trigger={POPOVER.TRIGGER_TYPE.CLICK}
          position={POPOVER.POSITIONS.BOTTOM_RIGHT}
          content={({ close }) => (
            <Popover.Elem.Menu>
              <List.Item
                withGutters
                divider={false}
                href={data.private_jobber.url}
                LeftComponent={() => (
                  <Icon.Large name="user" color={colors.primary} />
                )}
              >
                <Body16>{polyglot.t("job.see_profile")}</Body16>
              </List.Item>
              <List.Item
                withGutters
                divider={false}
                disabled={!data.can_publish_job}
                onClick={() => {
                  handleClick();
                  close();
                }}
                LeftComponent={() => (
                  <Icon.Large name="eye" color={colors.primary} />
                )}
              >
                <Body16>{polyglot.t("job.publish_jobbers")}</Body16>
              </List.Item>
            </Popover.Elem.Menu>
          )}
        >
          <List.Item
            shape={LIST.SHAPE.ROUND}
            withGutters
            size={LIST.SIZE.COMPACT}
            divider={false}
            css={`
              border: solid ${borderWidth.s} ${colors.border};
            `}
            LeftComponent={() => (
              <Avatar
                name={data.private_jobber.first_name}
                src={data.private_jobber.avatar}
                size={sizes.size48}
                isVerified
                isTopJobber={data.private_jobber.is_top_jobber}
              />
            )}
            RightComponent={() => (
              <Icon.Medium name="menu-v" color={colors.muted} />
            )}
          >
            <Block paddingY={spacings.s}>
              <Body14 color={colors.muted}>
                {polyglot.t("job.private_job_for")}
              </Body14>
              <Body16 strong>{data.private_jobber.first_name}</Body16>
            </Block>
          </List.Item>
        </Popover.Enhancer>
      </UserPreview>
      <PublishJobModal
        isOpen={modalIsOpen}
        onClose={handleClose}
        id={data.id}
      />
    </Block>
  );
};
export default JobPrivateJobber;
