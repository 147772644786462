import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = { isDateRange: true, noTime: true, IsNbHoursReadOnly: true };

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory5008() {
  const initialValues = {
    nh_specific: "",
    nh_specific3: "",
  };

  const getNbHours = () => undefined;

  const nhConfig = {
    nh_specific: {
      options: [
        { value: 1.0, label: "1" },
        { value: 2.0, label: "2" },
        { value: 3.0, label: "3" },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.how_many_pet_walks_per_day"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl>
            <FormSelectables
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    version: 2,
    pages,
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
