const config = { multipleJobbersAllowed: true, isCustom: true };

export function useCategory1041() {
  const initialValues = {};

  const getNbHours = () => 1.5;

  const nhConfig = {};

  return {
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
