import { useFormikContext } from "formik";
import * as yup from "yup";
import { Alert } from "../../../components";
import polyglot from "../../../utils/polyglot";
import StepTitle from "../common/StepTitle";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory2001() {
  const initialNbHours = 2;
  const initialValues = { nb_hours: initialNbHours, stuffs: null };
  const config = {
    IsNbHoursReadOnly: true,
  };

  const nhConfig = {
    stuffs: {
      label: polyglot.t("categories.jobber_bring_mower"),
      options: [
        { value: [], label: polyglot.t("common.no") },
        { value: ["mower"], label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        title: polyglot.t("categories.how_many_mowing_hours"),
        options: [
          {
            value: initialNbHours,
            label: polyglot.t("categories.small_field"),
            subtitle: polyglot.t("categories.mow_around_1000_sqm"),
            popular: true,
          },
          {
            value: 4,
            label: polyglot.t("categories.medium_field"),
            subtitle: polyglot.t("categories.mow_around_2000_sqm"),
          },
          {
            value: 6,
            label: polyglot.t("categories.large_field"),
            subtitle: polyglot.t("categories.mow_around_3000_sqm"),
          },
        ],
      },
    },
    {
      path: "bring-own-mower",
      validationSchema: yup.object().shape({
        stuffs: yup
          .array()
          .test("stuffs-0-exist", (value) => Array.isArray(value)),
      }),
      View: () => {
        const { values } = useFormikContext();
        return (
          <>
            <StepTitle>{nhConfig.stuffs.label}</StepTitle>
            <StyledFormControl>
              <FormSelectables
                options={nhConfig.stuffs.options}
                name="stuffs"
              />
            </StyledFormControl>
            <StyledFormControl>
              {values.stuffs?.[0] === "mower" && (
                <Alert.Low>
                  {polyglot.t("job.jobber_with_mower_are_fewer")}
                </Alert.Low>
              )}
            </StyledFormControl>
          </>
        );
      },
    },
  ];

  return {
    version: 1.2,
    config,
    pages,
    nhConfig,
    initialValues,
  };
}
