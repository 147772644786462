import React from 'react';
import { Field } from 'formik';
import MaskedInput from 'react-text-mask';
import { getEventsFromObject } from '../../utils/functions';

const WizardRadioPrice = props => {
  const {
    onClick,
    currency,
    type,
    name,
    checked,
    value,
    onBlur,
    onChange,
    onKeyUp,
    onFocus,
    focus,
    errors,
    values,
    tabIndex,
    label,
  } = props;
  const setDynamicInputWidth = () => {
    const lght = value.length;
  };
  const eventsHandler = getEventsFromObject(props);
  return (
    <div className="amount-radio-item" onClick={onClick} onFocus={onFocus} onBlur={onBlur}>
      {type === 'radio' || type === undefined ? (
        <div className="amount-radio">
          <div className="amount-radio-content">
            <input
              type="checkbox"
              name={name}
              value={value}
              checked={checked}
              tabIndex={tabIndex}
              {...eventsHandler}
            />
            <label>
              <div className="price-content">
                <div className="price">
                  <span>{value}</span>
                  <span>{currency}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      ) : (
        <div
          className={`amount-radio amount-radio--custom ${focus ? 'is-selected' : ''} ${
            errors.amount && values.customAmount ? 'has-error' : ''
          }`}>
          <div className="amount-radio-content">
            <p className="amount-radio-custom-label">{label}</p>
            <label>
              <div className="price-content">
                <div className="price">
                  <Field type="number" name={name}>
                    {({ field }) => (
                      <MaskedInput
                        guide={false}
                        tabIndex={tabIndex}
                        {...eventsHandler}
                          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                        {...field}
                      />
                    )}
                  </Field>
                  <span>{currency}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default WizardRadioPrice;
