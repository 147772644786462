import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { sizes, spacings } from "../assets/themes";
import { InputBase } from "./Styles/Base";

const StyledWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: no-wrap;
  justify-content: center;
  input {
    margin-right: ${spacings.s};
    &:last-of-type {
      margin-right: 0;
    }
    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }
`;

const StyledPin = styled(InputBase)`
  width: ${sizes.size48};
  text-align: center;
  font-weight: var(--font-weight-medium);
`;

const PinCode = ({ values, autoFocus, onChange }) => {
  const inputsRef = useRef([]);
  const [pin, setPin] = React.useState(values);
  const [activeNumber, setActiveNumber] = React.useState(autoFocus ? 0 : null);

  if (inputsRef.current.length !== values.length) {
    inputsRef.current = Array(values.length)
      .fill()
      .map((r, i) => inputsRef.current[i] || React.createRef());
  }

  useEffect(() => {
    inputsRef.current[activeNumber]?.current?.focus();
  }, [activeNumber]);

  useEffect(() => {
    onChange && onChange(pin);
  }, [pin]);

  const handleOnChange = (event, index) => {
    const val = event.target.value.replace(/\D/g, "") || "";
    const newPin = pin.map((num, valuesIndex) =>
      valuesIndex === index ? val : num
    );
    setPin(newPin);
    setActiveNumber((curr) => (val ? curr + 1 : curr));
  };

  const handleOnFocus = (event, index) => {
    setActiveNumber(index);
    inputsRef.current[index]?.current?.select();
  };

  const handleKeyDown = (event) => {
    const hasSelection = document.getSelection().toString().length > 0;
    if (event.keyCode === 8 && !hasSelection) {
      setActiveNumber((curr) => (curr > 0 ? curr - 1 : 0));
      return event.preventDefault;
    }
    return event;
  };

  const handlePaste = (e) => {
    const data = e.clipboardData.getData("Text").split("");
    const newPin = pin.map((num, index) => data[index] || num);
    setPin(newPin);
  };

  return (
    <StyledWrapper>
      {values.map((item, index) => (
        <StyledPin
          key={`pin-code-input-${index}`}
          type="text"
          inputMode="numeric"
          pattern="[0-9]*"
          onPaste={handlePaste}
          onKeyDown={(e) => handleKeyDown(e, index)}
          autocomplete="one-time-code"
          maxLength={1}
          placeholder="•"
          value={pin[index]}
          id={index}
          onChange={(e) => handleOnChange(e, index)}
          onFocus={(e) => handleOnFocus(e, index)}
          ref={inputsRef.current[index]}
        />
      ))}
    </StyledWrapper>
  );
};

export default PinCode;
