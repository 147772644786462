import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import {
  Alert,
  Button,
  Container,
  FilePicker,
  Flow,
  FormControl,
  List,
  Radio,
} from "../../../components";

const GenerateContract = ({ goNext, title, isLoading, goBack }) => {
  const { isValid, setFieldValue, values, status, handleSubmit } =
    useFormikContext();
  useEffect(() => {
    setFieldValue("attachment", "");
  }, [values.new_contract]);

  return (
    <Container.Small>
      <h1>Ajouter un contrat de travail</h1>
      {status && <Alert.Low kind="danger" title={status} />}
      <div>
        <FormControl label="Joindre un contrat existant au format .pdf (optionnel)">
          <FilePicker
            name="attachment"
            value={values.attachment}
            accept="application/pdf"
            onRemove={() => setFieldValue("attachment", "")}
            onChange={(event) => {
              setFieldValue("attachment", event.currentTarget.files[0]);
            }}
          />
        </FormControl>
        <Alert.Low title="Les données renseignées précédemment doivent correspondre avec le contrat existant" />
      </div>
      <Flow.Item.Navigation
        isValid={isValid}
        goBack={goBack}
        goNext={handleSubmit}
        nextLabel="Terminer"
        isLoading={isLoading}
      />
    </Container.Small>
  );
};

export default GenerateContract;
