import { useEffect } from "react";
import { useGoogleAnalytics, useListenToTargetClick } from "../modules/hooks";

const SendAnalyticsEvent = ({
  category,
  enabled = true,
  minEnabledBreakpoint,
  action,
  label,
  selector = "",
  ...rest
}) => {
  const { sendEvent } = useGoogleAnalytics();

  const handleEvent = () => {
    if (
      minEnabledBreakpoint
        ? window.innerWidth >= minEnabledBreakpoint && enabled
        : enabled
    ) {
      sendEvent({ category, action, label, ...rest });
    }
  };

  if (selector) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useListenToTargetClick(selector, handleEvent);
  }

  useEffect(() => {
    if (!selector) {
      handleEvent();
    }
  }, []);

  return null;
};

export default SendAnalyticsEvent;
