const getDistance = (location1, location2) => {
  if (location1 && location2) {
    if (
      location1.latitude === location2.latitude &&
      location1.longitude === location2.longitude
    ) {
      return 0;
    }
    const radlat1 = (Math.PI * location1.latitude) / 180;
    const radlat2 = (Math.PI * location2.latitude) / 180;
    const theta = location1.longitude - location2.longitude;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist *= 1.609344;
    return dist;
  }
  return 0;
};

export default getDistance;
