import { useContext, useEffect } from "react";
import { LoginContext } from "../providers/LoginProvider";

export default function useLogin(props) {
  const loginHelpers = useContext(LoginContext);
  useEffect(() => {
    loginHelpers.init(props);
  }, []);

  return loginHelpers;
}
