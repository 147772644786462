import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { breakpointsEnhancer } from "../Styles/Helper";

const StyledWrapper = styled.div`
  position: relative;
  ${({ size }) => breakpointsEnhancer("width", size)};
  ${({ size }) => breakpointsEnhancer("height", size)};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
let to;
const Circular = ({
  value = 0,
  size = 36,
  children,
  thickness = 4,
  ...rest
}) => {
  const [enhancedValue, setEnhancedValue] = useState(0);

  useEffect(() => {
    if (enhancedValue === 0) {
      to = setTimeout(() => setEnhancedValue(value), 0);
    } else setEnhancedValue(value);
    return () => {
      clearTimeout(to);
    };
  }, [value]);

  const STROKE_WIDTH = thickness;
  const radius = size / 2 - STROKE_WIDTH / 2;
  const pathDescription = `
      M ${size / 2},${size / 2} m 0,-${radius}
      a ${radius},${radius} 0 1 1 0,${2 * radius}
      a ${radius},${radius} 0 1 1 0,-${2 * radius}
    `;

  const diameter = Math.PI * 2 * radius;
  const progressStyle = {
    stroke: "var(--color-primary)",
    strokeDasharray: `${diameter}px ${diameter}px`,
    strokeDashoffset: `${((100 - enhancedValue) / 100) * diameter}px`,
    transition: "0.5s",
  };

  return (
    <StyledWrapper size={size}>
      <svg
        viewBox={`0 0 ${size} ${size}`}
        style={{ width: size, height: size }}
      >
        <path
          d={pathDescription}
          strokeWidth={STROKE_WIDTH}
          fillOpacity={0}
          style={{
            stroke: "var(--color-gray100)",
          }}
        />
        <path
          d={pathDescription}
          strokeWidth={STROKE_WIDTH}
          fillOpacity={0}
          style={progressStyle}
          {...rest}
        />
      </svg>
      {typeof children === "function" ? children({ value }) : children}
    </StyledWrapper>
  );
};

export default Circular;
