import React from 'react';

const WizardHeaderStep = props => {
  const { active, text, index, valid } = props;
  return (
    <div
      className={`wizard-header-step ${active ? 'active' : ''} ${
        valid ? 'valid' : ''
      }`}
    >
      <div className="header-step-icon">
        {!valid ? <span className="step-icon-index">{index + 1}</span> : null}
        {valid ? <i className="icon-check" /> : null}
      </div>
      <span className="wizard-step-text">{text}</span>
    </div>
  );
};

export default WizardHeaderStep;
