import React, { Component } from "react";
import WizardInput from "../../../../components/Wizard/WizardInput";
import polyglot from "../../../../utils/polyglot";
import GiftCardRegisteredCard from "./GiftCardPaiment/GiftCardRegisteredCard";
import WizardNavigation from "./WizardNavigation";

class GiftCardPaiment extends Component {
  state = {
    cardNumber: "",
    cardExpirationDate: "",
    cardCvx: "",
    focused: "",
    stepIndex: 1,
  };

  componentDidMount() {
    const { updateStep } = this.props;
    updateStep(2);
  }

  stepIsValid = () => {
    const { isValid, values } = this.props;
    if (values.useNewCard) {
      return isValid;
    }
    if (!values.useNewCard) {
      return true;
    }
  };

  handleInputFocus = ({ target }) => {
    if (target) {
      switch (target.name) {
        case "cardNumber":
          this.setState({ focused: "number" });
          break;
        case "cardExpirationDate":
          this.setState({ focused: "expiry" });
          break;
        case "cardCvx":
          this.setState({ focused: "cvc" });
          break;
        default:
          break;
      }
    } else {
      this.setState({ focused: "" });
    }
  };

  handleInputChange = ({ target }) => {
    switch (target.name) {
      case "cardNumber":
        this.setState({ cardNumber: target.value.replace(/ /g, "") });
        break;
      case "cardExpirationDate":
        this.setState({
          cardExpirationDate: target.value.replace(/ |\//g, ""),
        });
        break;
      case "cardCvx":
        this.setState({ cardCvx: target.value });
        break;
      default:
        break;
    }
  };

  render() {
    const { values, creditCard, handleChange } = this.props;
    return (
      <div>
        <div className="gift-card-wizard-content">
          <h2 className="gift-card-wizard-content-title">
            {polyglot.t("common.cards.payment_by_card")}
          </h2>
          <div className="d-flex align-items-center justify-content-center flex-column">
            {creditCard && creditCard.cardNumber ? (
              <div>
                <GiftCardRegisteredCard
                  handleChange={handleChange}
                  setFieldValue={this.props.setFieldValue}
                  cardNumber={creditCard.cardNumber}
                />
              </div>
            ) : null}
            {values.useNewCard || !creditCard.cardNumber ? (
              <div className="w-100">
                <div>
                  <div>
                    <WizardInput
                      {...this.props}
                      label={polyglot.t("common.cards.card_number")}
                      type="text"
                      name="cardNumber"
                      placeholder="XXXX XXXX XXXX XXXX"
                      autoFocus
                      onKeyUp={(target) => {
                        this.handleInputChange(target);
                      }}
                      onFocus={(target) => {
                        this.handleInputFocus(target);
                      }}
                      mask={[
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        " ",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        " ",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        " ",
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                        /[0-9]/,
                      ]}
                    />
                  </div>
                  <div className="d-flex align-items-center align-items-sm-start flex-sm-column">
                    <WizardInput
                      {...this.props}
                      name="cardExpirationDate"
                      type="text"
                      label={polyglot.t("common.cards.expiry_date")}
                      placeholder="01/20"
                      onKeyUp={(target) => {
                        this.handleInputChange(target);
                      }}
                      onFocus={(target) => {
                        this.handleInputFocus(target);
                      }}
                      mask={[/[0-1]/, /[0-9]/, "/", /[0-9]/, /[0-9]/]}
                    />
                    <WizardInput
                      {...this.props}
                      name="cardCvx"
                      type="text"
                      label={polyglot.t("common.cards.cvx")}
                      placeholder="123"
                      guide={false}
                      onKeyUp={(target) => {
                        this.handleInputChange(target);
                      }}
                      onFocus={(target) => {
                        this.handleInputFocus(target);
                      }}
                      onBlur={(target) => {
                        this.props.handleBlur(target);
                        this.handleInputFocus(false);
                      }}
                      mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <WizardNavigation
          backUrl={this.props.backUrl}
          currStep={this.state.stepIndex}
          submitted={this.props.submitted}
          submit={{ payAmount: values.amount }}
          stepIsValid={this.stepIsValid()}
        />
      </div>
    );
  }
}

export default GiftCardPaiment;
