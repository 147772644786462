import moment from "moment";
import capitalize from "./capitalize";
import polyglot from "./polyglot";

moment.locale(polyglot.locale);

const dateToString = (date, format = null) => {
  const today = moment();
  const newDate = moment(date, format);
  if (today.isSame(newDate, "day")) {
    return polyglot.t("common.today");
  }
  if (today.diff(newDate, "days") === 0) {
    return polyglot.t("common.tomorrow");
  }
  if (today.diff(newDate, "days") === -1) {
    return polyglot.t("common.after_tomorrow");
  }
  if (today.diff(newDate, "days") === 1) {
    return polyglot.t("common.yesterday");
  }
  if (today.diff(newDate, "days") === 2) {
    return polyglot.t("common.before_yesterday");
  }
  if (today.isSame(newDate, "year")) {
    return capitalize(newDate.format("dddd Do MMMM"));
  }
  return capitalize(newDate.format("dddd Do MMMM YYYY"));
};

const DAYS_DICTIONARY = {
  monday: "Lundi",
  tuesday: "Mardi",
  wednesday: "Mercredi",
  thursday: "Jeudi",
  friday: "Vendredi",
  saturday: "Samedi",
  sunday: "Dimanche",
};

const getAge = (date, format = "DD/MM/YYYY") =>
  moment().diff(moment(date, format), "years");

const translateDay = (dayName) => `${DAYS_DICTIONARY[dayName]}`;

const translateDayPlural = (dayName) => `${DAYS_DICTIONARY[dayName]}s`;

const getToday = (format = "DD/MM/YYYY") => moment().format(format);

const isToday = (date, format = "DD/MM/YYYY") =>
  moment().isSame(moment(date, format), "days");

const isSame = (date, date2, format = "DD/MM/YYYY") =>
  moment(date, format).isSame(moment(date2, format), "days");

const isBefore = (date, date2, format = "DD/MM/YYYY") =>
  moment(date, format).isBefore(moment(date2, format), "days");

const isTomorrow = (date, format = "DD/MM/YYYY") =>
  moment().add(1, "days").isSame(moment(date, format), "days");

const getDiff = (date, date2, kind, format = "DD/MM/YYYY") =>
  moment(date, format).diff(moment(date2, format), kind);

const formatDate = (date, to, initialFormat = "YYYY-MM-DD") =>
  moment(date, initialFormat).format(to);

const getDateOfBirthMaxDate = () => moment().add(-17, "years").toDate();
const getDateOfBirthActiveMonth = () => moment().add(-30, "years").toDate();

const getDiffFromInitialDay = (date, to, initialFormat = "YYYY-MM-DD") =>
  moment(date, initialFormat).diff(moment(to, initialFormat), "days") * -1 + 1;

const getShortDayNameByIndex = (index) => {
  moment.locale(polyglot.locale);
  return moment()
    .isoWeekday(index + 1)
    .format("ddd");
};

const getDayIndexByValue = (dayName) => {
  moment.locale("en-US");
  for (let i = 0; i < 7; i += 1) {
    if (
      moment().day(i).format("dddd").toLowerCase() === dayName.toLowerCase()
    ) {
      return i;
    }
  }
  return -1;
};

export {
  dateToString,
  translateDay,
  formatDate,
  translateDayPlural,
  getToday,
  isToday,
  isTomorrow,
  getAge,
  isSame,
  isBefore,
  getDiffFromInitialDay,
  getDateOfBirthMaxDate,
  getDateOfBirthActiveMonth,
  getDayIndexByValue,
  getShortDayNameByIndex,
  getDiff,
};
