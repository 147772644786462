import { useState } from "react";
import { borderWidth, colors, spacings } from "@/assets/themes";
import { Block, Body14, Body16, Button, Icon, List } from "@/components";
import { BUTTON, LIST } from "@/components/Styles/variants";
import { useLocalStorage } from "@/modules/hooks";
import { getCategoryFormUrlById } from "@/utils";
import polyglot from "@/utils/polyglot";

const HomeResumeForm = () => {
  const [resumeForm, set] = useLocalStorage("category-form", []);
  const [categoryId, setCategoryId] = useState(Object.keys(resumeForm)[0]);

  const handleRemove = () => {
    set({});
    setCategoryId(null);
  };

  if (!categoryId) return null;

  return (
    <Block marginTop={spacings.ml}>
      <List.Item
        withGutters
        divider={false}
        shape={LIST.SHAPE.ROUND}
        css={`
          border: solid ${borderWidth.s} ${colors.border};
        `}
      >
        <Body16 strong>
          {polyglot.t("home.resume_form_title", {
            title: polyglot.t(`categories.${categoryId}.name`),
          })}
        </Body16>
        <Body14>{polyglot.t("home.resume_form_description")}</Body14>
        <Block display="flex" gap={spacings.s} marginTop={spacings.s}>
          <Button.Small
            shape={BUTTON.SHAPE.PILL}
            kind={BUTTON.KIND.SECONDARY}
            href={getCategoryFormUrlById(categoryId)}
          >
            {polyglot.t("common.resume")}
          </Button.Small>
          <Button.Small
            shape={BUTTON.SHAPE.CIRCLE}
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            kind={BUTTON.KIND.SECONDARY}
            onClick={handleRemove}
          >
            <Icon.Medium name="trash" />
          </Button.Small>
        </Block>
      </List.Item>
    </Block>
  );
};

export default HomeResumeForm;
