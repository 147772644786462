import { useFormikContext } from "formik";
import React, { useState } from "react";
import {
  Button,
  Container,
  Flow,
  FormControl,
  PhoneInput,
} from "../../components";
import { getJobber } from "../../modules/routes";

const SetJobberPhone = ({ goByPath, jobbers }) => {
  const { isValid, values, errors, touched, setFieldValue } =
    useFormikContext();
  const [isLoading, setIsLoading] = useState(false);
  const handleCheckJobber = (phone) => {
    setIsLoading(true);
    const res = getJobber({ phone });
    res.then(({ data }) => {
      setIsLoading(false);
      setFieldValue("jobber", {});
      goByPath(data.jobber ? "jobber" : "email", { jobber: data.jobber });
    });
  };

  return (
    <Container.Small>
      <Flow.Item.Title>
        Quel est le numéro de téléphone de votre employé de maison ?
      </Flow.Item.Title>
      <FormControl label="Saisissez le numéro de télephone">
        <PhoneInput
          value={values.external_jobber.phone}
          name="external_jobber[phone]"
          autoComplete="prevent-autocomplete"
          autoFocus
          error={touched.phone && errors.phone ? true : null}
          onChange={(v) => {
            setFieldValue("external_jobber[phone]", v);
          }}
        />
      </FormControl>
      <Button.Link
        onClick={() => goByPath("email", { altTitle: true })}
        css="padding-left: 0"
      >
        Je ne connais pas le numéro de téléphone
      </Button.Link>
      <Flow.Item.Navigation
        isValid={isValid}
        goNext={() => handleCheckJobber(values.external_jobber.phone)}
        isLoading={isLoading}
        goBack={jobbers.length > 0 ? () => goByPath("jobber-selection") : null}
      />
    </Container.Small>
  );
};

export default SetJobberPhone;
