import { useMutation } from "@tanstack/react-query";
import { api } from "../../utils";

export const useSendCheques = () =>
  useMutation(({ codes, kind }) =>
    api.post("/cesu", { cesu_codes: codes, kind })
  );

export const useVerifyCheques = () =>
  useMutation(({ code }) => api.get(`/cesu/check_code?cesu_code=${code}`));
