import { MemoryRouter } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import CategoryFormProvider from "../modules/providers/CategoryFormProvider";
import CategoryForms from "../pages/categoryForms/CategoryForms";
import ExperimentProvider from "../modules/providers/ExperimentProvider";

const CategoryFormsApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      {/* <ExperimentProvider name="ab-2001/3099-simplified">
        
      </ExperimentProvider> */}
      <CategoryFormProvider id={props.id} initialValues={props.initial_values}>
        <MemoryRouter basename={rails.pathname}>
          <CategoryForms {...props} rails={rails} />
        </MemoryRouter>
      </CategoryFormProvider>
    </BaseApp>
  );

export default CategoryFormsApp;
