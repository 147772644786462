import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Icon } from "../../components";
import InformationItem from "./InformationItem";

const getColor = (value) => {
  if (value < 0) return colors.danger;
  if (value >= 100) return colors.success;
  return colors.warning;
};

const getIcon = (value) => {
  if (value < 0) return "thumb-down-solid";
  return "thumb-up-solid";
};

const PreviewElemJobberScore = ({ score }) => (
  <InformationItem
    title="Score de visibilité"
    RightComponent={() => (
      <Block display="flex" alignItems="center">
        <Icon.Medium
          name={getIcon(score)}
          color={getColor(score)}
          css={`
            margin-right: ${spacings.xs};
          `}
        />
        <Body16 strong color={getColor(score)}>
          {score}
        </Body16>
      </Block>
    )}
  />
);

export default PreviewElemJobberScore;
