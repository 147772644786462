import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Icon } from "../../components";
import InformationItem from "./InformationItem";

const getColor = (value) => {
  if (value >= 1) return colors.danger;
  return colors.success;
};

const getIcon = (value) => {
  if (value >= 1) return "exclamation-circle-solid";
  return "check-circle-solid";
};

const PreviewElemJobberCommentReport = ({ count, total }) => {
  const percentRaw = (count / total) * 100;
  const percent = total > 0 ? Math.round(percentRaw * 10) / 10 : 0;

  return (
    <InformationItem
      title="Commentaires frauduleux"
      subtitle="Sur les 30 derniers jours"
      RightComponent={() => (
        <Block display="flex" alignItems="center" color={getColor(percent)}>
          <Icon.Medium
            name={getIcon(percent)}
            css={`
              margin-right: ${spacings.xs};
            `}
          />
          <Body16 strong>{percent.toString().replace(".", ",")}%</Body16>&nbsp;
          <Body16 color={colors.muted}>
            ({count}/{total})
          </Body16>
        </Block>
      )}
    />
  );
};

export default PreviewElemJobberCommentReport;
