import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1001() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 2,
            subtitle: polyglot.t("categories.two_to_four_chair_sofa"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: 4,
            subtitle: polyglot.t("categories.dresser_and_bed"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 6,
            subtitle: polyglot.t(
              "categories.large_cabinet_or_several_furniture"
            ),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues: { nb_hours: 4 },
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
    },
  };
}
