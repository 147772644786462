import React from "react";
import styled, { css } from "styled-components";
import { colors, spacings } from "../../assets/themes";
import { LIST_HEADER } from "../Styles/variants";
import { Body16, H4 } from "../Text";

const StyledWrapper = styled.div`
  ${({ kind }) =>
    kind === LIST_HEADER.KIND.SUBTITLE &&
    css`
      padding-top: ${spacings.s};
    `};
  ${({ kind }) =>
    kind === LIST_HEADER.KIND.HEADING &&
    css`
      padding-top: ${spacings.ml};
    `};

  ${({ withGutters }) =>
    withGutters &&
    css`
      padding-top: ${spacings.m};
      padding-left: ${spacings.m};
      padding-right: ${spacings.m};
    `}
`;

const StyledRightComponent = styled.div`
  padding-left: ${spacings.s};
`;

const StyledContent = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
`;

const StyledChild = styled.div`
  padding-bottom: ${spacings.s};
  padding-top: ${spacings.s};
`;

const Child = ({ children, kind }) => (
  <StyledChild>
    {kind === LIST_HEADER.KIND.SUBTITLE ? (
      <Body16 color={colors.muted}>{children}</Body16>
    ) : (
      <H4>{children}</H4>
    )}
  </StyledChild>
);

const Header = ({
  title,
  RightComponent,
  withGutters,
  kind = LIST_HEADER.KIND.HEADING,
  children,
  ...rest
}) => (
  <StyledWrapper withGutters={withGutters} kind={kind} {...rest}>
    <StyledContent>
      <Child kind={kind}>{!children ? title : children}</Child>
      {RightComponent && (
        <StyledRightComponent>{RightComponent()}</StyledRightComponent>
      )}
    </StyledContent>
  </StyledWrapper>
);

export default Header;
