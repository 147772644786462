import { Form, Formik } from "formik";
import { motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { colors, radius, shadows, spacings } from "../assets/themes";
import { Block, Button, Icon, Input, Steps } from "../components";
import { BUTTON, INPUT, STEPS } from "../components/Styles/variants";
import { useBreakpoints } from "../modules/hooks";
import {
  getCategoryFormUrlById,
  setAddressInitialValues,
  setDestinationAddressInitialValues,
} from "../utils";
import polyglot from "../utils/polyglot";
import AddressModal from "./common/AddressModal";

const StyledInput = styled(Input)`
  background-color: ${colors.background};
  flex: 1;
`;

const RemovalSearchMobile = ({
  openAddress,
  openDestinationAddress,
  values,
}) => (
  <Block width="100%">
    <Steps orientation={STEPS.ORIENTATION.VERTICAL} size={STEPS.SIZE.COMPACT}>
      <Steps.Step
        active
        renderLineColor={colors.muted}
        render={() => (
          <Icon.Large name="circle-small-solid" color={colors.muted} />
        )}
      >
        <StyledInput
          css={`
            margin-left: calc(${spacings.m} * -1);
          `}
          size={INPUT.SIZE.SMALL}
          shape={INPUT.SHAPE.CIRCLE}
          name="address"
          onClick={openAddress}
          value={values.address}
          placeholder={`${polyglot.t("common.start_place")}...`}
        />
      </Steps.Step>

      <Steps.Step
        active
        renderLineColor={colors.muted}
        render={() => (
          <Block marginY={spacings.s}>
            <Icon.Large name="map-marker-solid" color={colors.muted} />
          </Block>
        )}
      >
        <StyledInput
          css={`
            margin-left: calc(${spacings.m} * -1);
          `}
          size={INPUT.SIZE.SMALL}
          shape={INPUT.SHAPE.CIRCLE}
          name="destination_address"
          onClick={openDestinationAddress}
          value={values.destination_address}
          placeholder={`${polyglot.t("common.destination_place")}...`}
        />
      </Steps.Step>
    </Steps>
  </Block>
);

const RemovalSearch = ({
  addresses = {},
  cta_label = polyglot.t("common.start"),
  onClick,
  urlParams: _urlParams,
}) => {
  const breakpoints = useBreakpoints();
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [destinationAddressModalIsOpen, setDestinationAddressModalIsOpen] =
    useState(false);

  const handleSubmit = (values) => {
    if (onClick) onClick();
    const urlParams = { ...values, ..._urlParams };
    urlParams.address_location = JSON.stringify(urlParams.address_location);
    urlParams.destination_address_location = JSON.stringify(
      urlParams.destination_address_location
    );
    Object.keys(urlParams).forEach((key) => {
      if (!urlParams[key]) {
        delete urlParams[key];
      }
    });
    window.location = getCategoryFormUrlById("3014", urlParams);
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{
        ...setAddressInitialValues(),
        ...setDestinationAddressInitialValues(),
      }}
    >
      {(formik) => (
        <Form>
          <Block
            borderRadius={{ xs: radius.m, md: radius.circle }}
            boxShadow={shadows.m}
            padding={spacings.sm}
            background={colors.background}
          >
            <Block
              display="flex"
              alignItems="center"
              flexDirection={{ xs: "column", md: "row" }}
            >
              {breakpoints.get({
                xs: (
                  <RemovalSearchMobile
                    values={formik.values}
                    openAddress={(e) => {
                      if (onClick) onClick(e);
                      setAddressModalIsOpen(true);
                    }}
                    openDestinationAddress={(e) => {
                      if (onClick) onClick(e);
                      setDestinationAddressModalIsOpen(true);
                    }}
                  />
                ),
                md: (
                  <>
                    <StyledInput
                      shape={INPUT.SHAPE.CIRCLE}
                      LeftComponent={() => <Icon.Large name="map-marker" />}
                      name="address"
                      onClick={(e) => {
                        if (onClick) onClick(e);
                        setAddressModalIsOpen(true);
                      }}
                      value={formik.values.address}
                      placeholder={`${polyglot.t("common.start_place")}...`}
                    />
                    <Block
                      marginX={spacings.m}
                      display={{ xs: "none", md: "block" }}
                    >
                      <Icon.Large name="arrow-right" color={colors.muted} />
                    </Block>
                    <StyledInput
                      shape={INPUT.SHAPE.CIRCLE}
                      LeftComponent={() => (
                        <Icon.Large name="map-marker-solid" />
                      )}
                      name="destination_address"
                      onClick={(e) => {
                        if (onClick) onClick(e);
                        setDestinationAddressModalIsOpen(true);
                      }}
                      value={formik.values.destination_address}
                      placeholder={`${polyglot.t(
                        "common.destination_place"
                      )}...`}
                    />
                  </>
                ),
              })}
              {breakpoints.get({
                xs: (
                  <Block marginTop={spacings.xs} width="100%">
                    <Button.Small block type="submit">
                      {cta_label}
                    </Button.Small>
                  </Block>
                ),
                md: (
                  <Button.Large type="submit" shape={BUTTON.SHAPE.PILL}>
                    {cta_label}
                  </Button.Large>
                ),
              })}
            </Block>
          </Block>
          <AddressModal
            addresses={addresses}
            isOpen={addressModalIsOpen}
            onClose={() => setAddressModalIsOpen(false)}
          />
          <AddressModal
            isDestination
            addresses={addresses}
            isOpen={destinationAddressModalIsOpen}
            onClose={() => setDestinationAddressModalIsOpen(false)}
          />
        </Form>
      )}
    </Formik>
  );
};

const RemovalSearchExperiment = ({ animate, ...rest }) => (
  <motion.div
    initial={animate ? { y: "-30%", opacity: 0 } : { y: "0%", opacity: 0 }}
    animate={{ y: "0%", opacity: 1 }}
  >
    <RemovalSearch {...rest} />
  </motion.div>
);

export default RemovalSearchExperiment;
