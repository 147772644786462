import React, { useState, useEffect } from "react";
import { withTheme } from "styled-components";
import {
  StyledPassordCheckerWrapper,
  StyledPassordCheckerBar,
} from "../../pages/Styles/PasswordCheckerStyles";
import polyglot from "../../utils/polyglot";

const PasswordChecker = ({ value, theme }) => {
  const [width, setWidth] = useState(0);
  const [status, setStatus] = useState(polyglot.t("security_levels.too_short"));
  const PASS_MIN_LENGHT = 8;
  const [color, setColor] = useState("");
  const scorePassword = (pass) => {
    let score = 0;
    if (!pass) return score;
    const letters = {};
    for (let i = 0; i < pass.length; i += 1) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };
    let variationCount = 0;
    Object.keys(variations).forEach((check) => {
      variationCount += variations[check] === true ? 1 : 0;
    });
    score += (variationCount - 1) * 10;

    return parseInt(score, 10);
  };

  useEffect(() => {
    const checkPassStrength = (pass) => {
      const score = scorePassword(pass);
      if (score > 75 && pass.length > PASS_MIN_LENGHT)
        return polyglot.t("security_levels.secure");
      if (score > 50 && pass.length > PASS_MIN_LENGHT)
        return polyglot.t("security_levels.medium");
      // if (score >= 30 && pass.length > PASS_MIN_LENGHT) return 'Faible';
      if (pass.length < PASS_MIN_LENGHT)
        return polyglot.t("security_levels.too_short");
      return polyglot.t("security_levels.low");
    };

    const renderColor = (pass) => {
      const score = scorePassword(pass);
      if (score > 75 && pass.length > PASS_MIN_LENGHT)
        return theme.colors.success;
      if (score > 50 && pass.length > PASS_MIN_LENGHT)
        return theme.colors.warning;
      if (pass.length < PASS_MIN_LENGHT) return "grey";
      return theme.colors.danger;
    };
    const handleInputChange = () => {
      setWidth(scorePassword(value));
      setStatus(checkPassStrength(value));
      setColor(renderColor(value));
    };
    handleInputChange();
  }, [theme.colors.danger, theme.colors.success, theme.colors.warning, value]);

  return (
    <div className="d-flex align-items-center mt-n1">
      {value && value.length > 0 && (
        <>
          <StyledPassordCheckerWrapper>
            <StyledPassordCheckerBar width={width} color={color} />
          </StyledPassordCheckerWrapper>
          <span
            className="font-weight-medium ml-3 text-nowrap"
            style={{ color }}
          >
            {status}
          </span>
        </>
      )}
    </div>
  );
};

export default withTheme(PasswordChecker);
