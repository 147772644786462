import React from 'react';

const ParagraphInfo = ({ title, body, children, icon, className }) => (
  <div className={`d-flex py-2 ${className}`}>
    <i className={`${icon} text-primary h4 ${icon ? 'mr-2' : ''}`} style={{ marginTop: 1 }} />
    <div className="d-flex flex-column align-items-start">
      <p className="font-weight-medium h4 mb-1">{title}</p>
      <p>{body}</p>
      {children}
    </div>
  </div>
);

export default ParagraphInfo;
