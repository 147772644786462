import { useFormikContext } from "formik";
import * as Sentry from "@sentry/react";
import { colors, radius, sizes, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Button,
  FilePicker,
  FilePickerEnhancer,
  Icon,
} from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import ChatInput from "@/pages/common/chatInput/ChatInput";
import polyglot from "@/utils/polyglot";
import { useToast } from "@/modules/hooks";
import { formatFileSize } from "@/utils";

const MAX_IMAGE_SIZE = 20 * 1024 * 1024; // 20 MB
const MAX_VIDEO_SIZE = 50 * 1024 * 1024; // 50 MB
const MAX_AUDIO_SIZE = 20 * 1024 * 1024; // 20 MB
const MAX_DOCUMENT_SIZE = 30 * 1024 * 1024; // 30 MB

const MAX_UPLOADS = 10;

const getFileType = (file) => {
  if (file.type.startsWith("image/")) return "image";
  if (file.type.startsWith("video/")) return "video";
  if (file.type.startsWith("audio/")) return "audio";
  return "document";
};

const getMaxSizeForType = (fileType) => {
  switch (fileType) {
    case "image":
      return MAX_IMAGE_SIZE;
    case "video":
      return MAX_VIDEO_SIZE;
    case "audio":
      return MAX_AUDIO_SIZE;
    default:
      return MAX_DOCUMENT_SIZE;
  }
};

const getErrorMessageForType = (fileType, maxSize) =>
  polyglot.t(`common.errors.${fileType}_max_size`, {
    max: formatFileSize(maxSize),
  });

const ChatBoxInput = ({ disabled }) => {
  const toast = useToast();
  const { values, setFieldValue } = useFormikContext();

  const addUpload = (value) => {
    setFieldValue("uploads", [...values.uploads, ...value]);
  };

  const handleFileChange = (event) => {
    const { files } = event.currentTarget;
    let updatedFiles = Array.from(files);
    const errorTypes = new Set();

    updatedFiles = updatedFiles.filter((file) => {
      const fileType = getFileType(file);
      const maxSize = getMaxSizeForType(fileType);

      if (file.size > maxSize) {
        Sentry.captureException(
          `File size exceeded for ${fileType}. Max size: ${maxSize}. Given: ${file.size}`
        );
        errorTypes.add(fileType);
        return false;
      }
      return true;
    });

    errorTypes.forEach((fileType) => {
      const maxSize = getMaxSizeForType(fileType);
      const errorMessage = getErrorMessageForType(fileType, maxSize);
      toast.danger(errorMessage);
    });

    if (values.uploads.length + updatedFiles.length > MAX_UPLOADS) {
      toast.danger(polyglot.t("common.errors.max_files", { max: MAX_UPLOADS }));
      Sentry.captureException(
        `Max files exceeded. Limit: ${MAX_UPLOADS}. Attempted to add: ${
          values.uploads.length + updatedFiles.length
        }`
      );
      const remainingSlots = MAX_UPLOADS - values.uploads.length;
      updatedFiles = updatedFiles.slice(0, remainingSlots);
    }

    addUpload(updatedFiles);
    event.currentTarget.value = "";
  };

  const handleRemove = (index) => {
    setFieldValue(
      "uploads",
      values.uploads.filter((_, i) => i !== index)
    );
  };

  return (
    <>
      <ChatInput
        name="body"
        value={values.body}
        placeholder={polyglot.t("tickets.message_placeholder")}
        disabled={disabled}
        content={() =>
          values.uploads.length > 0 && (
            <Block
              display="flex"
              flexWrap="wrap"
              gap={spacings.s}
              css={`
                margin: ${spacings.s};
              `}
            >
              {values.uploads.map((upload, i) => (
                <FilePicker
                  key={`upload-chat-${i}`}
                  name={upload.name}
                  value={upload}
                  maxSize={10 * 1024 * 1024}
                  onRemove={() => handleRemove(i)}
                  css={`
                    label {
                      border-radius: ${radius.l};
                    }
                    overflow: hidden;
                    width: ${sizes.size128};
                  `}
                />
              ))}
            </Block>
          )
        }
        onChange={(e) => setFieldValue("body", e.target.value)}
        LeftComponent={() => (
          <FilePickerEnhancer
            name="avatar"
            multiple
            max={MAX_UPLOADS}
            disabled={values.uploads.length >= MAX_UPLOADS}
            accept="image/webp,image/jpeg,image/gif,image/png,application/pdf,video/webm,video/mp4,video/mov,video/avi,video/mkv"
            onChange={handleFileChange}
          >
            <Button.Small
              kind={BUTTON.KIND.MINIMAL}
              shape={BUTTON.SHAPE.CIRCLE}
              disabled={values.uploads.length >= MAX_UPLOADS}
              css="background-color: transparent!important"
            >
              <Icon.Large name="plus" color={colors.muted} />
            </Button.Small>
          </FilePickerEnhancer>
        )}
      >
        {disabled && (
          <Body14
            align="center"
            color={colors.muted}
            css={`
              width: 100%;
            `}
          >
            {polyglot.t("chat.no_input")}
          </Body14>
        )}
      </ChatInput>
    </>
  );
};

export default ChatBoxInput;
