import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { Alert } from "../../../components";
import {
  INSTANT_GO_NEXT_DELAY,
  setDestinationAddressInitialValues,
} from "../../../utils";
import polyglot from "../../../utils/polyglot";
import StepTitle from "../common/StepTitle";
import {
  FormHorizontalCardRadios,
  FormSelect,
  FormSelectables,
  StyledFormControl,
} from "../common/fields";
import {
  DATE_PATH,
  DISTANCE_QUERY,
  FLOORS,
  FURNITURE_QUANTITIES,
  INFORMATIONS_PATH,
  START_HOUR_PATH,
  SURFACE_OPTIONS,
} from "../constants";
import RecommendationView from "./3014/RecommendationWrapper";

const version = 2.0;

const config = {
  addressDetails: true,
  IsNbHoursReadOnly: true,
  renderPages: (pages) => {
    const newPages = [...pages];
    const dateIndex = pages.findIndex((p) => p.path === DATE_PATH);
    const startHoursIndex = pages.findIndex((p) => p.path === START_HOUR_PATH);
    newPages[dateIndex].title = polyglot.t("categories.move_date_question");
    newPages[startHoursIndex].title = polyglot.t(
      "categories.move_start_hour_question"
    );
    newPages.splice(dateIndex, 0, {
      path: "recommendation",
      View: RecommendationView,
      validationSchema: yup.object().shape({}),
    });
    return newPages;
  },
};

export function useCategory3014() {
  const queryClient = useQueryClient();
  const initialValues = {
    nh_specific: "",
    nh_specific2: ["", "", "", ""],
    vehicles: null,
    ...setDestinationAddressInitialValues(),
  };

  const getRawDuration = ({
    nh_specific,
    nh_specific2,
    floor,
    lift,
    destination_floor,
    destination_lift,
  }) => {
    const VOLUME_PER_HOUR_PER_JOBBER = 4;
    let res = 0;
    let volume = 0;

    if (nh_specific2[0]) {
      const furnitureData = FURNITURE_QUANTITIES.find(
        (t) => t.value === nh_specific2[0]
      );
      volume = nh_specific * furnitureData.nbHoursAccumulator;
    }

    const basicDuration = volume / VOLUME_PER_HOUR_PER_JOBBER;

    let additionnalHours = 0;

    if (floor && !lift) {
      additionnalHours =
        basicDuration *
        FLOORS.find((f) => f.value === floor)?.nbHoursAccumulator;
    }
    if (destination_floor && !destination_lift) {
      additionnalHours +=
        basicDuration *
        FLOORS.find((f) => f.value === destination_floor)?.nbHoursAccumulator;
    }
    res = additionnalHours + basicDuration;
    return res;
  };

  const getNbJobbers = (values) => {
    const res = getRawDuration(values);
    if (res <= 4) {
      return 1;
    }
    if (res <= 12) {
      return 2;
    }
    if (res <= 24) {
      return 3;
    }
    return 4;
  };

  const getNbHours = (values) => {
    let res = getRawDuration(values);
    // eslint-disable-next-line no-underscore-dangle
    const nbJobbers = getNbJobbers(values);

    const durationInSeconds = queryClient.getQueryData([
      [
        DISTANCE_QUERY,
        values.address_location,
        values.lift,
        values.floor,
        values.destination_address_location,
        values.destination_lift,
        values.destination_floor,
      ],
    ])?.duration_in_seconds;
    if (
      values.address_location &&
      values.destination_address_location &&
      durationInSeconds
    ) {
      res += (durationInSeconds / 3600) * nbJobbers;
    }
    return Math.ceil(res * 2) / 2 / nbJobbers;
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.what_is_move_home_size"),
      options: SURFACE_OPTIONS,
    },
    "nh_specific2[0]": {
      label: polyglot.t("categories.what_quantity_furniture_at_your_home"),
      options: FURNITURE_QUANTITIES,
    },
    "nh_specific2[1]": {
      label: polyglot.t("categories.are_they_extra_heavy_items"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 1.0, label: polyglot.t("common.yes") },
      ],
    },
    nh_specific3: {
      label: polyglot.t("categories.jobber_bring_own_truck"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 1.0, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      validationSchema: yup.object().shape({
        nh_specific: yup.number().required(),
      }),
      View: ({ goNext }) => {
        const handleChange = (value, { values, setFieldValue }) => {
          // get volume
          if (values.nh_specific2[0]) {
            const furnitureData = FURNITURE_QUANTITIES.find(
              (t) => t.value === values.nh_specific2[0]
            );
            setFieldValue(
              "nh_specific2[2]",
              value * furnitureData.nbHoursAccumulator
            );
          }
          setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY);
        };

        return (
          <>
            <StepTitle>{nhConfig.nh_specific.label}</StepTitle>
            <StyledFormControl>
              <FormSelect
                onChange={handleChange}
                placeholder={polyglot.t(
                  "categories.select_surface_placeholder"
                )}
                name="nh_specific"
                options={nhConfig.nh_specific.options}
              />
            </StyledFormControl>
          </>
        );
      },
    },
    {
      path: "furniture-quantities",
      validationSchema: yup.object().shape({
        nh_specific2: yup
          .array()
          .test("nh2-0-exist", (value) => value?.[0]?.toString()),
      }),
      View: ({ goNext }) => {
        const handleChange = (value, { values, setFieldValue }) => {
          // get volume
          const furnitureData = FURNITURE_QUANTITIES.find(
            (t) => t.value === value
          );
          setFieldValue(
            "nh_specific2[2]",
            values.nh_specific * furnitureData.nbHoursAccumulator
          );
          setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY);
        };
        return (
          <>
            <StepTitle>
              {polyglot.t("categories.what_quantity_furniture_at_your_home")}
            </StepTitle>
            <StyledFormControl>
              <FormHorizontalCardRadios
                onChange={handleChange}
                placeholder={polyglot.t(
                  "categories.select_surface_placeholder"
                )}
                name="nh_specific2[0]"
                options={nhConfig["nh_specific2[0]"].options}
              />
            </StyledFormControl>
          </>
        );
      },
    },
    {
      path: "heavy-items",
      validationSchema: yup.object().shape({
        nh_specific2: yup
          .array()
          .test("nh2-1-exist", (value) => value?.[1]?.toString()),
      }),
      View: ({ goNext }) => (
        <>
          <StepTitle>
            {polyglot.t("categories.are_they_extra_heavy_items")}
          </StepTitle>
          <StyledFormControl>
            <FormSelectables
              onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
              name="nh_specific2[1]"
              options={nhConfig["nh_specific2[1]"].options}
            />
          </StyledFormControl>
          <StyledFormControl>
            <Alert.Low>
              {polyglot.t("categories.heavy_items_exemple")}
            </Alert.Low>
          </StyledFormControl>
        </>
      ),
    },
    {
      path: "jobber-own-truck",
      validationSchema: yup.object().shape({
        nh_specific3: yup.number().required(),
      }),
      View: ({ goNext }) => (
        <>
          <StepTitle>{nhConfig.nh_specific3.label}</StepTitle>
          <StyledFormControl>
            <FormSelectables
              onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
              options={nhConfig.nh_specific3.options}
              name="nh_specific3"
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    getNbJobbers,
    config,
    version,
  };
}
