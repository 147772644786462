import React from "react";
import Main from "./Main";

const Item = React.forwardRef(({ title, children, subtitle, ...rest }, ref) => (
  <Main ref={ref} {...rest}>
    {title && <p className="mb-0">{title}</p>}
    {subtitle && (
      <span className="mt-2 text-muted font-size-2">{subtitle}</span>
    )}
    {children}
  </Main>
));

export default Item;
