import { spacings } from "../../assets/themes";
import { Block, Body16, Button, Modal } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import ModalSupportFooter from "./ModalSupportFooter";

const CustomModalSize = ({ hasChild, children, ...rest }) =>
  hasChild ? (
    <Modal.Small {...rest}>{children}</Modal.Small>
  ) : (
    <Modal.XSmall {...rest}>{children}</Modal.XSmall>
  );

const ChangeConfirmModal = ({
  isLoading,
  disabled,
  isOpen,
  subtitle,
  onClose,
  children,
  onSubmit,
}) => (
  <CustomModalSize
    hasChild={children}
    onClose={onClose}
    isOpen={isOpen}
    fullScreenOnMobile
  >
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>
        {polyglot.t("cancel_review.change_jobber")}
      </Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <Body16>{subtitle}</Body16>
      <Block marginBottom={`calc(${spacings.s} * -1)`}>{children}</Block>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large
        disabled={disabled}
        onClick={onSubmit}
        block
        isLoading={isLoading}
      >
        {polyglot.t("common.confirm")}
      </Button.Large>
      <Button.Large kind={BUTTON.KIND.MINIMAL} onClick={onClose} block>
        {polyglot.t("common.cancel")}
      </Button.Large>
    </Modal.Item.Footer>
    <ModalSupportFooter />
  </CustomModalSize>
);

export default ChangeConfirmModal;
