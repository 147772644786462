import React, { useEffect, useImperativeHandle, useState } from "react";
import { usePopper } from "react-popper";
import { spacings } from "../../assets/themes";
import { Popover } from "../../components";
import PortalContent from "../../components/popover/PortalContent";
import { POPOVER } from "../../components/Styles/variants";
import {
  POPPER_CONFIG,
  MOUSE_OUTSIDE_SHOW_DELAY,
  MOUSE_OUTSIDE_HIDE_DELAY,
} from "./config";

const PreviewPopover = React.forwardRef(
  (
    {
      node,
      children,
      isOpen,
      onOpen,
      onClose,
      noPortal,
      onAnimationComplete,
      onMouseEnterDelay = MOUSE_OUTSIDE_SHOW_DELAY,
    },
    ref
  ) => {
    let hideTo;
    let showTo;
    const [isVisible, setIsVisible] = useState(isOpen || false);

    const [popperElement, setPopperElement] = useState(null);
    const { styles, state, ...popperRest } = usePopper(
      node,
      popperElement,
      POPPER_CONFIG
    );

    useEffect(() => {
      setIsVisible(isOpen);
    }, [isOpen]);

    const handleMouseEnter = () => {
      clearTimeout(hideTo);
      showTo = setTimeout(() => {
        if (onOpen && !isVisible) onOpen();
        setIsVisible(true);
      }, onMouseEnterDelay);
    };

    const handleClose = () => {
      clearTimeout(showTo);
      hideTo = setTimeout(() => {
        setIsVisible(false);
        if (onClose) onClose();
      }, MOUSE_OUTSIDE_HIDE_DELAY);
    };

    const handleInstantClose = (e) => {
      if (onClose) onClose(e);
      if (isOpen === undefined) {
        setIsVisible(false);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        close: handleInstantClose,
        state,
        ...popperRest,
      }),
      [popperRest.update]
    );

    useEffect(() => {
      if (isOpen === undefined) {
        node?.addEventListener("mouseenter", handleMouseEnter);
        node?.addEventListener("mouseleave", handleClose);
        popperElement?.addEventListener("mouseenter", handleMouseEnter);
        popperElement?.addEventListener("mouseleave", handleClose);
      }
      return () => {
        if (isOpen === undefined) {
          node?.removeEventListener("mouseenter", handleMouseEnter);
          popperElement?.addEventListener("mouseenter", handleMouseEnter);
          node?.removeEventListener("mouseleave", handleClose);
          popperElement?.removeEventListener("mouseleave", handleClose);
        }
      };
    }, [node, popperElement]);

    return (
      <PortalContent
        position={POPOVER.POSITIONS.RIGHT}
        isVisible={isVisible}
        onAnimationComplete={onAnimationComplete}
        styles={styles}
        ref={setPopperElement}
        noPortal={noPortal}
      >
        <>
          <Popover.Elem.Menu
            css={`
              width: 375px;
              margin: ${noPortal ? spacings.xs : spacings.m};
            `}
          >
            {children}
          </Popover.Elem.Menu>
        </>
      </PortalContent>
    );
  }
);

export default PreviewPopover;
