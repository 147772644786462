import { useState } from "react";
import ECesuModal from "../eCesu/ECesuModal";
import CesuBtn from "./CesuBtn";
import { Body16, Tag } from "../../../components";
import { TAG } from "../../../components/Styles/variants";
import polyglot from "@/utils/polyglot";

const icon = (
  <svg
    width="180"
    height="180"
    className="svg-rocket-ecesu"
    viewBox="0 0 180 180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CRCESU.icon.se-cesu" fill="none" fillRule="evenodd">
      <g id="fire">
        <path
          d="M93.3020167,139.580275 L97.5998418,149.481428 C97.7395883,149.80337 97.8556083,150.135099 97.9469738,150.473962 C99.6084019,156.635991 96.5381443,160.4 90.0004538,160.4 C83.2797113,160.4 80.2715132,156.440445 82.1747746,150.042917 L82.2160123,149.904302 L86.6974007,139.580275 C87.4890704,137.75646 89.6093391,136.919742 91.4331534,137.711412 C92.2705212,138.074891 92.9385371,138.742907 93.3020164,139.580275 Z"
          stroke="#252932"
          strokeWidth="4.8"
          transform="matrix(1 0 0 -1 0 296)"
        />
      </g>
      <g id="rocket">
        <g id="rocket-body">
          <g transform="translate(48.062 18)">
            <path
              d="M41.2817777,0.0335752409 C47.3698525,0.750911194 55.6335318,12.962674 66.0728157,36.6688638 C69.6680839,44.833233 70.8275179,53.8340669 69.4158807,62.6215214 L62.6016504,105.040169 C62.2353328,107.320499 60.2356384,109 57.8868854,109 L24.1131146,109 C21.7643616,109 19.7646672,107.320499 19.3983496,105.040169 L12.5841193,62.6215214 C11.1724821,53.8340669 12.3319161,44.833233 15.9271843,36.6688638 C26.3664682,12.962674 34.6301475,0.750911194 40.7182223,0.0335752409 L40.7182223,5.68434189e-14 C40.8116126,5.68434189e-14 40.9055385,0.00282957568 41,0.00848872703 C41.0944615,0.00282957567 41.1883874,5.68434189e-14 41.2817777,5.68434189e-14 L41.2817777,0.033562468 L41.2817777,0.0335752409 Z M12.7704918,68 L19,105.714286 L3.15380525,110.956636 C2.91062774,111.037086 2.65613962,111.078089 2.4000001,111.078089 C1.07451664,111.078089 0,110.003572 0,108.678088 L0,91.6767431 C-7.10542736e-15,82.138015 4.79959278,73.2394755 12.7704918,68 Z M69.2295082,68 C77.2004072,73.2394755 82,82.138015 82,91.6767431 L82,108.678088 C82,110.003572 80.9254834,111.078089 79.5999999,111.078089 C79.3438604,111.078089 79.0893723,111.037086 78.8461948,110.956636 L63,105.714286 L69.2295082,68 Z"
              id="Shape"
              fill="#fff"
              stroke="#252932"
              strokeWidth="4.8"
            />
            <g id="blue" transform="translate(21.667 37.132)">
              <circle
                id="Oval"
                stroke="#0078FA"
                strokeWidth="4.8"
                fill="#DCEBFF"
                cx="19.5"
                cy="19.5"
                r="19.5"
              />
              <path
                d="M20.8130081,25.9012016 C21.7960138,25.9012016 22.6315561,25.6586584 23.31966,25.1735648 C24.007764,24.6884711 24.5390472,24.0097952 24.9135255,23.1375167 L29,25.4873164 C28.1574237,26.8758414 27.0316651,27.9750738 25.6226903,28.7850467 C24.2137155,29.5950196 22.619866,30 20.8410939,30 C18.6316716,30 16.7359035,29.4036553 15.1537324,28.2109479 C13.5715614,27.0182406 12.5183569,25.3093124 11.9940872,23.0841121 L10,23.0841121 L10,20.8010681 L11.6851441,20.8010681 C11.6664202,20.445036 11.6570584,20.1691154 11.6570584,19.9732977 C11.6570584,19.6172657 11.6617393,19.3502457 11.6711013,19.1722296 L10,19.1722296 L10,16.8758344 L11.9940872,16.8758344 C12.5183569,14.6506342 13.5715614,12.9483815 15.1537324,11.7690254 C16.7359035,10.5896692 18.6316716,10 20.8410939,10 C22.619866,10 24.216056,10.3983049 25.6297118,11.1949266 C27.0433675,11.9915483 28.1667857,13.0796549 29,14.459279 L24.9135255,16.8090788 C24.1364829,14.9755138 22.7696575,14.058745 20.8130081,14.058745 C18.7908251,14.058745 17.4614469,14.9977654 16.8248337,16.8758344 L22.0206948,16.8758344 L22.0206948,19.1722296 L16.4175905,19.1722296 C16.3988666,19.5104601 16.3895048,19.7774801 16.3895048,19.9732977 C16.3895048,20.1691154 16.3988666,20.445036 16.4175905,20.8010681 L22.0206948,20.8010681 L22.0206948,23.0841121 L16.8248337,23.0841121 C17.4614469,24.9621812 18.7908251,25.9012016 20.8130081,25.9012016 Z"
                id="€"
                fill="#0078FA"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const ECesu = ({ reference }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <CesuBtn
      onClick={() => setModalIsOpen(true)}
      TopComponent={() => (
        <Tag.Medium kind={TAG.KIND.MUTED}>
          {polyglot.t("common.ecesu")}
        </Tag.Medium>
      )}
    >
      {icon}
      <Body16 strong>{polyglot.t("cesu.tranfer_my_ecesu")}</Body16>
      <ECesuModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        reference={reference}
      />
    </CesuBtn>
  );
};
export default ECesu;
