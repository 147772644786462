import { useEffect, useState } from "react";
import { spacings } from "@/assets/themes";
import { Block, Divider, List } from "@/components";
import { useBanners, useBreakpoints, useLocalStorage } from "@/modules/hooks";
import Reminders from "../jobs/Reminders";
import BannerHorizontal from "./Blocks/BannerHorizontal";
import CategoriesGrid from "./Blocks/CategoriesGrid";
import CategoriesHorizontal from "./Blocks/CategoriesHorizontal";
import CategoriesVertical from "./Blocks/CategoriesVertical";
import JobbersGrid from "./Blocks/JobbersGrid";
import JobbersHorizontal from "./Blocks/JobbersHorizontal";
import MainCategoriesHorizontal from "./Blocks/MainCategoriesHorizontal";
import HomeActiveJobs from "./HomeActiveJobs";
import HomeResumeForm from "./HomeResumeForm";

function filterBlocks(blocks, bannersToHide, banners) {
  const bannerKeys = new Set(Object.keys(banners));
  return blocks
    .map((row) =>
      row.filter(
        (block) =>
          block.data.length > 0 &&
          !(
            block.display === "banner_vertical" &&
            (bannersToHide.some((banner) => block.data.includes(banner)) ||
              block.data.some((banner) => !bannerKeys.has(banner)))
          )
      )
    )
    .filter((row) => row.length > 0);
}

const HomeBlocks = ({ blocs, active_jobs, reminders }) => {
  const breakpoints = useBreakpoints();
  const banners = useBanners();
  const [hiddenBanners] = useLocalStorage("hiddenBanners", []);
  const [firsteredBlocs, setFilteredBlocs] = useState([]);

  useEffect(() => {
    setFilteredBlocs(filterBlocks(blocs, hiddenBanners, banners));
  }, [hiddenBanners]);

  const handleStorageChange = (event) => {
    if (event.detail?.hiddenBanners) {
      setFilteredBlocs(
        filterBlocks(blocs, event.detail?.hiddenBanners, banners)
      );
    }
  };

  useEffect(() => {
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const renderBlockByDisplay = (block) => {
    if (block.display === "categories_vertical" && block.data?.length > 0)
      return <CategoriesVertical block={block} />;
    if (block.display === "categories_horizontal" && block.data?.length > 0)
      return <CategoriesHorizontal block={block} />;
    if (block.display === "categories_grid" && block.data?.length > 0)
      return <CategoriesGrid block={block} />;
    if (block.display === "jobbers_horizontal" && block.data?.length > 0)
      return <JobbersHorizontal block={block} />;
    if (block.display === "jobbers_grid" && block.data?.length > 0)
      return <JobbersGrid block={block} />;
    if (block.display === "banner_vertical" && block.data?.length > 0)
      return <BannerHorizontal block={block} />;
    if (
      block.display === "categories_inspiration_vertical" &&
      block.data?.length > 0
    )
      return <MainCategoriesHorizontal block={block} />;
    return <div />;
  };

  return (
    <>
      <HomeResumeForm />
      {reminders?.length > 0 && <Reminders data={reminders} />}
      {active_jobs?.length > 0 && <HomeActiveJobs active_jobs={active_jobs} />}
      {firsteredBlocs?.map((block, i, arr) => (
        <Block key={`filtered-block-${i}`}>
          {block?.map((b) => (
            <Block spaceY={spacings.s} key={`block-${b.title}`}>
              {b.title && b.data?.length > 0 ? (
                <List.Header>{b.title}</List.Header>
              ) : (
                <Block marginTop={spacings.ml} />
              )}
              {renderBlockByDisplay(b)}
            </Block>
          ))}
          {i < arr.length - 1 && (
            <Block marginTop={spacings.l}>
              {breakpoints.get({
                xs: <Divider.Group />,
                md: <Divider.Cell />,
              })}
            </Block>
          )}
        </Block>
      ))}
    </>
  );
};

export default HomeBlocks;
