import polyglot from "@/utils/polyglot";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Button, H5, Icon } from "../../components";
import { CardBase } from "../../components/Styles/Base";
import useJob from "../../modules/hooks/useJob";

const JobJobberJoinBox = () => {
  const { public_pages } = useJob();
  return (
    <CardBase flat>
      <Block padding={spacings.m}>
        <H5>{polyglot.t("job.want_become_jobber")}</H5>
        <Block marginTop={spacings.s} marginBottom={spacings.sm}>
          <Body16 color={colors.muted}>
            {polyglot.t("job.want_become_jobber_now")}
          </Body16>
        </Block>
        <Button.Medium
          href={public_pages.become_jobber}
          block
          LeftComponent={() => <Icon.Medium name="work" />}
        >
          {polyglot.t("job.become_jobber_cta")}
        </Button.Medium>
      </Block>
    </CardBase>
  );
};
export default JobJobberJoinBox;
