import BaseApp from "../modules/BaseApp";
import VolumeCalculator from "../pages/VolumeCalculator";

const VolumeCalculatorApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <VolumeCalculator {...props} />
    </BaseApp>
  );

export default VolumeCalculatorApp;
