import { useEffect, useLayoutEffect } from "react";

const MOUSEDOWN = "mousedown";
const useOutlineEnhancer = () => {
  useLayoutEffect(() => {
    if (!document.body.classList.contains(MOUSEDOWN)) {
      document.body.classList.add(MOUSEDOWN);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", () => {
      if (!document.body.classList.contains(MOUSEDOWN)) {
        document.body.classList.add(MOUSEDOWN);
      }
    });
    document.addEventListener(
      "touchstart",
      () => {
        if (!document.body.classList.contains(MOUSEDOWN)) {
          document.body.classList.add(MOUSEDOWN);
        }
      },
      { passive: true }
    );
    document.addEventListener("keydown", () => {
      if (document.body.classList.contains(MOUSEDOWN)) {
        document.body.classList.remove(MOUSEDOWN);
      }
    });
  }, []);
};

export default useOutlineEnhancer;
