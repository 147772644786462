import { BrowserRouter } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import JobProvider from "../modules/providers/JobProvider";
import Job from "../pages/Job";

const JobApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <BrowserRouter>
        <JobProvider {...props}>
          <Job />
        </JobProvider>
      </BrowserRouter>
    </BaseApp>
  );

export default JobApp;
