import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { borderWidth, colors, sizes, spacings } from "../assets/themes";
import {
  Avatar,
  Badge,
  Block,
  Body16,
  Button,
  ConditionnalWrapper,
  Icon,
  Popover,
} from "../components";
import { BUTTON, POPOVER } from "../components/Styles/variants";
import { useDocumentTitle, useWindowSize } from "../modules/hooks";
import { useGetDashboard } from "../modules/routes/dashboard-routes";
import { BREAKPOINTS } from "../utils";
import { ADD_BADGE } from "../utils/types";
import MenuItems from "./headerMenu/MenuItems";
import MobileMenu from "./headerMenu/MobileMenu";
import Progress from "@/components/progress";

const MenuButton = ({ onClick, badges }) => (
  <Button.Small
    kind={BUTTON.KIND.MINIMAL}
    shape={BUTTON.SHAPE.CIRCLE}
    onClick={onClick}
  >
    <Block
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Badge dot={badges.hasBadge}>
        <Icon.Large name="bars" />
      </Badge>
    </Block>
  </Button.Small>
);

const HeaderMenu = ({ user = {}, data, public_pages }) => {
  const documentTitle = useDocumentTitle();
  const { width } = useWindowSize();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { data: badges } = useGetDashboard(null);

  useEffect(() => {
    documentTitle.update();
  }, [badges.messages_count]);

  useEffect(() => {
    dispatch({ type: ADD_BADGE, payload: data });
  }, []);

  return (
    <Block display="flex" alignItems="center">
      {user && (
        <>
          {width < BREAKPOINTS.lg ? (
            <>
              <MenuButton
                onClick={() => setModalIsOpen(true)}
                badges={badges}
              />
              <MobileMenu
                user={user}
                data={data}
                public_pages={public_pages}
                badges={badges}
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
              />
            </>
          ) : (
            <Popover.Enhancer
              position={POPOVER.POSITIONS.BOTTOM_RIGHT}
              trigger={POPOVER.TRIGGER_TYPE.CLICK}
              strategy={POPOVER.STRATEGY.FIXED}
              content={() => (
                <Popover.Elem.Menu>
                  <Block width={300}>
                    <MenuItems
                      user={user}
                      data={data}
                      public_pages={public_pages}
                      badges={badges}
                    />
                  </Block>
                </Popover.Elem.Menu>
              )}
            >
              <Button.Medium
                kind={BUTTON.KIND.MINIMAL}
                shape={BUTTON.SHAPE.PILL}
                RightComponent={() => <Icon.Medium name="bars" />}
                LeftComponent={() => (
                  <Block
                    marginLeft={spacings.s}
                    display="flex"
                    alignItems="center"
                  >
                    <Badge dot={badges.hasBadge}>
                      <Avatar
                        src={user.avatar}
                        size={sizes.size24}
                        name={user.first_name}
                      />
                    </Badge>
                  </Block>
                )}
                css={`
                  padding-left: 0;
                  padding-right: ${spacings.sm};
                  border: solid ${borderWidth.s} ${colors.border};
                `}
              >
                <Block
                  display={{ xs: "none", md: "block" }}
                  marginRight={spacings.xs}
                  marginLeft={spacings.xs}
                >
                  <Body16>{user.first_name}</Body16>
                </Block>
              </Button.Medium>
            </Popover.Enhancer>
          )}
        </>
      )}
    </Block>
  );
};

export default HeaderMenu;
