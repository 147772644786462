import { Form, Formik } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { MemoryRouter } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";
import { spacings } from "@/assets/themes";
import { Block, Button, Flow } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { useEditRateJobber, useRateJobber } from "@/modules/routes";
import polyglot from "@/utils/polyglot";
import ExtraRatingPage from "../ExtraRatingModal/ExtraRatingPage";
import ExtraRatingProblemPage from "../ExtraRatingModal/ExtraRatingProblemsPage";
import JobberRate from "./JobberRate";

const JobberRating = ({
  offer_id,
  rate,
  extra_rate,
  jobber,
  isEdit,
  onSuccess,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const rateJobber = useRateJobber();
  const editRateJobber = useEditRateJobber();
  const formRef = useRef();
  const flowRef = useRef();
  const isLoading =
    rateJobber.isLoading || rateJobber.isSuccess || editRateJobber.isLoading;

  const onClose = () => {};
  const getPages = useCallback(() => {
    if (!isLoading && jobber)
      return [
        {
          path: "intro",
          View: JobberRate,
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            jobber: jobber || {},
          },
          validationSchema: yup.object().shape({
            rate: yup.number().min(1).required(),
            badge: yup.string(),
            improvments: yup.array().of(yup.string()),
            complaints: yup.array().of(yup.string()),
            bad_skills: yup.array().of(yup.string()),
            private_note: yup.string(),
            comment: yup.string().required(),
          }),
        },
        {
          path: "punctuality",
          View: ExtraRatingPage,
          title: polyglot.t("extra_rating.punctuality.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.punctuality.good"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.good_description"
                ),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.punctuality.average"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.average_description"
                ),
                value: "average",
              },
              {
                title: polyglot.t("extra_rating.punctuality.bad"),
                name: "punctuality",
                subtitle: polyglot.t(
                  "extra_rating.punctuality.bad_description"
                ),
                value: "bad",
              },
            ],
          },
          nextLabel: polyglot.t("common.next"),
          validationSchema: yup.object().shape({
            punctuality: yup.string().required(),
          }),
        },
        {
          path: "interpersonal",
          View: ExtraRatingPage,
          nextLabel: polyglot.t("common.next"),
          title: polyglot.t("extra_rating.interpersonal.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.interpersonal.good"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.good_description"
                ),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.interpersonal.average"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.average_description"
                ),
                value: "average",
              },
              {
                title: polyglot.t("extra_rating.interpersonal.bad"),
                name: "interpersonal",
                subtitle: polyglot.t(
                  "extra_rating.interpersonal.bad_description"
                ),
                value: "bad",
              },
            ],
          },
          validationSchema: yup.object().shape({
            interpersonal: yup.string().required(),
          }),
        },
        {
          path: "pricing",
          View: ExtraRatingPage,
          nextLabel: polyglot.t("common.next"),
          title: polyglot.t("extra_rating.pricing.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
            fields: [
              {
                title: polyglot.t("extra_rating.pricing.good"),
                name: "pricing",
                subtitle: polyglot.t("extra_rating.pricing.good_description"),
                value: "good",
              },
              {
                title: polyglot.t("extra_rating.pricing.bad"),
                name: "pricing",
                subtitle: polyglot.t("extra_rating.pricing.bad_description"),
                value: "bad",
              },
            ],
          },
          validationSchema: yup.object().shape({
            pricing: yup.string().required(),
          }),
        },
        {
          path: "problems",
          View: ExtraRatingProblemPage,
          nextLabel: polyglot.t("common.finish"),
          title: polyglot.t("exta_rating.problems.title"),
          customProps: {
            onClose,
            goBack: () => {
              flowRef.current?.goBack();
            },
          },
          validationSchema: yup.object().shape({
            is_valid: yup
              .boolean()
              .test("at-least-one-true", "", (_, context) => {
                if (context.parent.has_problem === false) return true;
                if (context.parent.has_problem === true) {
                  return (
                    context.parent.forces_payment_confirmation ||
                    context.parent.asks_more_money ||
                    context.parent.forces_furnitures_payment ||
                    context.parent.innapropriate_behavior ||
                    context.parent.not_focused ||
                    context.parent.uses_drugs ||
                    context.parent.unskilled ||
                    context.parent.no_stuff ||
                    context.parent.sloppy_job_done ||
                    context.parent.no_cleanup ||
                    (context.parent.other_boolean === true &&
                      context.parent.other_report?.length > 1)
                  );
                }
                return false;
              }),
          }),
        },
      ];
    return [];
  }, [jobber]);

  const handleSubmit = (values) => {
    if (isEdit) {
      editRateJobber.mutate({ id: offer_id, ...values });
    } else {
      rateJobber.mutate({ id: offer_id, ...values });
    }
  };

  const handleStepChange = (currIndex) => {
    setActiveIndex(currIndex);
  };

  useEffect(() => {
    formRef.current?.validateForm();
  }, [activeIndex]);

  useEffect(() => {
    if (onSuccess && (editRateJobber.isSuccess || rateJobber.isSuccess)) {
      onSuccess(editRateJobber.isSuccess || rateJobber.isSuccess);
    }
  }, [editRateJobber.isSuccess, rateJobber.isSuccess]);

  return (
    <Block>
      <MemoryRouter>
        <Formik
          initialValues={{
            rate: rate?.rate || null,
            badge: rate?.badge?.key || "",
            improvments: rate?.improvments || [],
            complaints: rate?.complaints || [],
            bad_skills: rate?.bad_skills || "",
            private_note: rate?.private_note || "",
            comment: rate?.comment || "",
            punctuality: extra_rate?.punctuality || null,
            interpersonal: extra_rate?.interpersonal || null,
            pricing: extra_rate?.pricing || null,
            forces_payment_confirmation:
              extra_rate?.reports?.forces_payment_confirmation || false,
            asks_more_money: extra_rate?.reports?.asks_more_money || false,
            forces_furnitures_payment:
              extra_rate?.reports?.forces_furnitures_payment || false,
            innapropriate_behavior:
              extra_rate?.reports?.innapropriate_behavior || false,
            not_focused: extra_rate?.reports?.not_focused || false,
            uses_drugs: extra_rate?.reports?.uses_drugs || false,
            unskilled: extra_rate?.reports?.unskilled || false,
            no_stuff: extra_rate?.reports?.no_stuff || false,
            sloppy_job_done: extra_rate?.reports?.sloppy_job_done || false,
            no_cleanup: extra_rate?.reports?.no_cleanup || false,
            // UI ONLY
            has_problem: extra_rate
              ? Object.values(extra_rate?.reports || {}).some(
                  (value) => value === true
                )
              : null,
            other_boolean: false,
            // END UI ONLY
            other_report: extra_rate?.other_report || "",
          }}
          onSubmit={handleSubmit}
          // validateOnMount
          innerRef={formRef}
          validationSchema={() =>
            yup.lazy(() => getPages()[activeIndex].validationSchema)
          }
        >
          {({ isValid }) => (
            <Form
              css={`
                width: 100%;
              `}
            >
              <Flow.Router
                renderProgress={() => null}
                onChange={handleStepChange}
                routes={getPages()}
                ref={flowRef}
              >
                {({ goNext, goBack }) => (
                  <Block
                    display="flex"
                    gap={spacings.s}
                    justifyContent="space-between"
                    width="100%"
                    marginTop={spacings.m}
                  >
                    {activeIndex !== 0 && (
                      <Button.Large
                        kind={BUTTON.KIND.SECONDARY}
                        onClick={goBack}
                      >
                        {polyglot.t("common.back")}
                      </Button.Large>
                    )}

                    {activeIndex === getPages().length - 1 ? (
                      <Button.Large
                        type="submit"
                        disabled={!isValid}
                        block
                        isLoading={isLoading}
                        // Key prevent auto-submit on last page bug
                        key="submit-button"
                      >
                        {polyglot.t("common.validate")}
                      </Button.Large>
                    ) : (
                      <Button.Large
                        onClick={goNext}
                        disabled={!isValid}
                        block
                        // Key prevent auto-submit on last page bug
                        key={`next-${activeIndex}-button`}
                      >
                        {polyglot.t("common.next")}
                      </Button.Large>
                    )}
                  </Block>
                )}
              </Flow.Router>
            </Form>
          )}
        </Formik>
      </MemoryRouter>
    </Block>
  );
};

export default JobberRating;
