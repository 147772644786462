import { colors } from "../../assets/themes";

const VIEWSIZE = 1000;
const DEFAULT_STROKE_WIDTH = 15;
const DEFAULT_COLOR = colors.muted;

const CubeSchema = ({
  width,
  height,
  long,
  strokeWidth = DEFAULT_STROKE_WIDTH,
  color = DEFAULT_COLOR,
}) => {
  const perspectiveFactor = 0.3;

  const calculateCubePoints = (width, height, long) => {
    const px = long * perspectiveFactor;
    const py = long * perspectiveFactor;

    const front = `0,0 ${width},0 ${width},${height} 0,${height} 0,0`;
    const back = `${px},${py} ${px + width},${py} ${px + width},${
      py + height
    } ${px},${py + height} ${px},${py}`;

    return { front, back };
  };

  // Trouver la dimension maximale
  const maxDim = Math.max(width * 1.5, height * 1.5, long * 2.5);

  // Normaliser les dimensions par rapport à VIEWSIZE
  const normalizedWidth = (width / maxDim) * VIEWSIZE;
  const normalizedHeight = (height / maxDim) * VIEWSIZE;
  const normalizedLong = (long / maxDim) * VIEWSIZE;

  const { front, back } = calculateCubePoints(
    normalizedWidth,
    normalizedHeight,
    normalizedLong
  );

  const cx =
    VIEWSIZE / 2 - (normalizedWidth + normalizedLong * perspectiveFactor) / 2;
  const cy =
    VIEWSIZE / 2 - (normalizedHeight + normalizedLong * perspectiveFactor) / 2;

  return (
    <svg
      viewBox={`0 0 ${VIEWSIZE} ${VIEWSIZE}`}
      css={`
        width: 100%;
      `}
    >
      <g transform={`translate(${cx}, ${cy})`}>
        {/* Face avant du cube */}
        <polyline
          points={front}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
        />

        {/* Face arrière du cube */}
        <polyline
          points={back}
          fill="none"
          stroke={color}
          strokeWidth={strokeWidth}
        />

        {/* Lignes reliant les coins */}
        <line
          x1="0"
          y1="0"
          x2={back.split(" ")[0].split(",")[0]}
          y2={back.split(" ")[0].split(",")[1]}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <line
          x1={`${normalizedWidth}`}
          y1="0"
          x2={back.split(" ")[1].split(",")[0]}
          y2={back.split(" ")[1].split(",")[1]}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <line
          x1="0"
          y1={`${normalizedHeight}`}
          x2={back.split(" ")[3].split(",")[0]}
          y2={back.split(" ")[3].split(",")[1]}
          stroke={color}
          strokeWidth={strokeWidth}
        />
        <line
          x1={`${normalizedWidth}`}
          y1={`${normalizedHeight}`}
          x2={back.split(" ")[2].split(",")[0]}
          y2={back.split(" ")[2].split(",")[1]}
          stroke={color}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default CubeSchema;
