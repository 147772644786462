import { useSelector } from "react-redux";
import { getToday, isBefore, isSame } from "@/utils";
import { CATEGORY_DATE_FORMAT } from "./constants";

export function useFormatFormInitialValues() {
  const { isConcierge: _isConcierge, isSav: _isSav } = useSelector(
    (s) => s.rails
  );
  const isConcierge = _isConcierge && !_isSav;
  const isSav = _isConcierge && _isSav;

  return (values, config) => {
    const newValues = { ...values };
    if (isConcierge || isSav) {
      // prevent back-end crash when phone step is hidden if phone cache is not valid
      delete newValues.phone;
    }
    if (values.start_hour && config.noTime) {
      delete newValues.start_hour;
    }
    if (
      newValues?.date &&
      (isBefore(
        newValues.date,
        getToday(CATEGORY_DATE_FORMAT),
        CATEGORY_DATE_FORMAT
      ) ||
        isSame(
          newValues.date,
          getToday(CATEGORY_DATE_FORMAT),
          CATEGORY_DATE_FORMAT
        ))
    ) {
      delete newValues.start_hour;
      delete newValues.date;
      delete newValues.end_date;
    }
    return newValues;
  };
}
