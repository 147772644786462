import React from 'react';
import { colors } from '../../../assets/themes';
import { Body14 } from '../../Text';

const Subtitle = ({ children, ...rest }) => (
  <Body14 color={colors.muted} {...rest}>
    {children}
  </Body14>
);

export default Subtitle;
