import React from "react";
import { Provider } from "react-redux";
import BaseApp from "../modules/BaseApp";
import SearchBar from "../pages/SearchBar";
import store from "../store";

const SearchBarApp = (props, rails) => () =>
  (
    <BaseApp exclude={["ToastProvider", "QueryClient", "ThemeProvider"]}>
      <SearchBar {...props} />
    </BaseApp>
  );

export default SearchBarApp;
