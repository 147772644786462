import React from "react";
import { Body14, Body16, Icon, List, Shape } from "@/components";
import polyglot from "@/utils/polyglot";
import { SHAPE } from "@/components/Styles/variants";
import { colors } from "@/assets/themes";

const CustomAskItem = React.memo(
  ({ href, onClick, divider }) => (
    <List.Item
      href={href}
      chevron
      divider={divider}
      onClick={onClick}
      withGutters
      LeftComponent={() => (
        <Shape.Large
          backgroundColor={colors.mutedLight}
          color={colors.onColor}
          shape={SHAPE.SHAPE.ROUND}
        >
          <Icon.Large name="edit" />
        </Shape.Large>
      )}
    >
      <Body14 color={colors.muted}>
        {polyglot.t("categories_modal.cant_find_category_question")}
      </Body14>
      <Body16 strong>{polyglot.t("categories_modal.create_custom_job")}</Body16>
    </List.Item>
  ),
  (prevProps, nextProps) => prevProps.isLoading === nextProps.isLoading
);
export default CustomAskItem;
