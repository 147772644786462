import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { colors, spacings } from "../../assets/themes";
import { Alert, Block, Body14, Icon, List, Tabs } from "../../components";
import { ALERT } from "../../components/Styles/variants";
import JobberOfferItemContent from "../../pages/dashboard/jobs/JobberOfferItemContent";
import QrcodeGenerator from "../../pages/QrcodeGenerator";
import polyglot from "../../utils/polyglot";
import { getAdminJobberProfile, JOBBER } from "./config";
import useJobberConfig from "./hooks/useJobberConfig";
import PreviewElemSkeleton from "./PreviewElemSkeleton";
import usePreviewInit from "./usePreviewInit";

const PreviewElemJobber = ({ id, popoverRef }) => {
  const { current_admin } = useSelector((state) => state.rails);
  const { init, data, isLoading, tabs } = usePreviewInit({
    id,
    kind: JOBBER,
  });
  const config = useJobberConfig({ id });

  useEffect(() => {
    if (popoverRef) init(popoverRef);
  }, [popoverRef]);

  return (
    <div>
      {isLoading ? (
        <PreviewElemSkeleton />
      ) : (
        <>
          <JobberOfferItemContent
            jobber={data}
            urlTargetBlank
            hideDetails
            withGutters
            RightComponent={() => (
              <QrcodeGenerator
                size={64}
                user_token={data.token}
                admin_id={current_admin.id}
              />
            )}
          />
          {data.deleted && (
            <Block marginY={spacings.s} marginX={spacings.m}>
              <Alert.High kind={ALERT.KIND.DANGER} icon="user-times">
                Utilisateur supprimé
              </Alert.High>
            </Block>
          )}
          {data.frozen_account_message && (
            <Block marginY={spacings.s} marginX={spacings.m}>
              <Alert.Low kind={ALERT.KIND.DANGER}>
                {data.frozen_account_message}
              </Alert.Low>
            </Block>
          )}
          {data.shadow_banned_account_message && (
            <Block marginY={spacings.s} marginX={spacings.m}>
              <Alert.High
                renderIcon={() => <Icon.XLarge name="eye-slashed" />}
                css={`
                  background-color: ${colors.backgroundDark};
                `}
              >
                {data.shadow_banned_account_message}
              </Alert.High>
            </Block>
          )}
          {config.informations()}
          <List.Item
            href={getAdminJobberProfile({ offseted_id: data.offseted_id })}
            withGutters
            divider={false}
          >
            <List.Elem.Title strong color={colors.primary}>
              Gérer le profil jobber
            </List.Elem.Title>
          </List.Item>
          {!data.deleted && (
            <Block marginTop={spacings.xs}>
              <Tabs value={tabs.active} onChange={tabs.set}>
                {config.tabs.map((tab, i) => (
                  <Tabs.Tab value={i} key={`tab-jobber-admin-${i}`} {...tab}>
                    {tab.title}
                  </Tabs.Tab>
                ))}
              </Tabs>
              <List.Group>{config.menu()[tabs.active]}</List.Group>
            </Block>
          )}
        </>
      )}
    </div>
  );
};

export default PreviewElemJobber;
