import {
  SUBMIT_FORM,
  WIZARD_GO_TO_STEP,
  WIZARD_NEXT_STEP,
  WIZARD_PREV_STEP,
  WIZARD_STEP_NAVIGATION,
} from '../../utils/types';

export default function WizardReducer(state = { step: 1 }, action) {
  switch (action.type) {
    case WIZARD_STEP_NAVIGATION:
      return action.payload;
    default:
      break;
  }
  return state;
}
