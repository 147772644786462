import styled, { css, withTheme } from "styled-components";
import { colors } from "../assets/themes";

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 52px;
  height: 32px;
  border-radius: 20px;
  overflow: hidden;
  opacity: ${({ disabled }) => (disabled ? "0.5" : "1")};
  &::before {
    content: "";
    position: absolute;
    background-color: ${colors.transparent};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 9999;
    transition: background-color 0.2s;
  }
  &:hover {
    &:before {
      background-color: ${colors.overlayLight};
    }
  }
`;
const CheckBoxLabel = styled.label`
  background: ${colors.muted};
  width: 100%;
  height: 100%;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin: 3px;
    background: #ffffff;
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  ${({ theme }) => css`
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    border-radius: 15px;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &:checked + ${CheckBoxLabel} {
      background: ${theme.colors.primary};
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 26px;
        height: 26px;
        margin-left: 23px;
        transition: 0.2s;
      }
    }
  `}
`;

const Toggle = ({ onChange, name, disabled, ...rest }) => (
  <CheckBoxWrapper disabled={disabled}>
    <CheckBox
      name={name}
      type="checkbox"
      disabled={disabled}
      onChange={onChange}
      {...rest}
    />
    <CheckBoxLabel htmlFor={name} />
  </CheckBoxWrapper>
);

export default withTheme(Toggle);
