import { BrowserRouter } from "react-router-dom";
import JobberInstantPayment from "@/pages/JobberInstantPayment";
import BaseApp from "@/modules/BaseApp";

const JobberInstantPaymentApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <BrowserRouter>
        <JobberInstantPayment
          id={props.id}
          initial_values={props.initial_values}
        />
      </BrowserRouter>
    </BaseApp>
  );

export default JobberInstantPaymentApp;
