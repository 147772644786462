import { useFormikContext } from "formik";
import moment from "moment";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { spacings } from "@/assets/themes";
import { Block, Body16, Divider, List, Selectable, Toggle } from "@/components";
import capitalize from "@/utils/capitalize";
import polyglot from "@/utils/polyglot";

const DayItem = ({ onChange, data, children }) => {
  const isChecked = Array.isArray(data);

  const timeIsChecked = (v) => data.includes(v);

  const handleChange = (e, time) => {
    const set = new Set(data);

    if (time === "all_day") {
      set.clear();
    } else if (set.has("all_day")) {
      set.delete("all_day");
    }
    if (!e.target.checked) {
      set.delete(time);
    } else {
      set.add(time);
    }

    onChange(Array.from(set));
  };

  return (
    <div>
      <List.Item
        onClick={(e) => onChange(isChecked ? null : [])}
        LeftComponent={() => <Toggle checked={isChecked} readOnly />}
        divider={!isChecked}
      >
        <Body16 strong>{children}</Body16>
      </List.Item>
      <AnimatePresence>
        {isChecked && (
          <motion.div
            className="my-2"
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.1 }}
            style={{ overflow: "hidden" }}
          >
            <Block display="flex" flexDirection="column" gap={spacings.s}>
              <Selectable.Medium
                onChange={(e) => handleChange(e, "all_day")}
                checked={timeIsChecked("all_day")}
              >
                {polyglot.t("common.all_day")}
              </Selectable.Medium>
              <Divider.Cell>OU</Divider.Cell>
              <Selectable.Medium
                onChange={(e) => handleChange(e, "morning")}
                checked={timeIsChecked("morning")}
              >
                {polyglot.t("common.morning")}
              </Selectable.Medium>
              <Selectable.Medium
                onChange={(e) => handleChange(e, "afternoon")}
                checked={timeIsChecked("afternoon")}
              >
                {polyglot.t("common.afternoon")}
              </Selectable.Medium>
              <Selectable.Medium
                onChange={(e) => handleChange(e, "evening")}
                checked={timeIsChecked("evening")}
              >
                {polyglot.t("common.evening")}
              </Selectable.Medium>
              <Selectable.Medium
                onChange={(e) => handleChange(e, "night")}
                checked={timeIsChecked("night")}
              >
                {polyglot.t("common.night")}
              </Selectable.Medium>
            </Block>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FormChildCareDays = ({ name }) => {
  const { values, setFieldValue } = useFormikContext();
  const [data, setData] = useState(values[name] || []);

  const getWeekdays = () => {
    const weekdays = [];
    for (let i = 1; i <= 7; i += 1) {
      weekdays.push({
        label: moment().isoWeekday(i).format("dddd").toLowerCase(),
        value: moment().locale("en").isoWeekday(i).format("dddd").toLowerCase(),
      });
    }
    return weekdays;
  };

  const handleChange = (day, newVal) => {
    function removeEmpty(obj) {
      return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
      );
    }
    let newData = { ...data };
    delete data[day];
    newData[day] = newVal;
    newData = removeEmpty(newData);
    setData(newData);
    setFieldValue(name, newData);
  };

  return (
    <div>
      {getWeekdays().map((day, i) => (
        <DayItem
          key={`child-day-item-${i}`}
          data={data[day.value]}
          name={name}
          onChange={(v) => handleChange(day.value, v)}
        >
          {capitalize(day.label)}
        </DayItem>
      ))}
    </div>
  );
};

export default FormChildCareDays;
