import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Button,
  Checkbox,
  List,
  Modal,
  Spinner,
  Stepper,
} from "../../../components";
import {
  getEditPayment,
  getSimulatePricing,
  putUpdatePayment,
} from "../../../modules/routes";
import { formatDate, getTotalPricing } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import SimulateTaxCredit from "../../common/taxCreditModal/SimulateTaxCredit";

let to;
const AsyncPricing = ({ value, className, isLoading }) => (
  <>
    {!isLoading ? (
      <span className={className}>{polyglot.toSmartCurrency(value)}</span>
    ) : (
      <i className="icon-spinner icon-spin text-muted" />
    )}
  </>
);

const EditPaymentModal = ({
  onClose,
  isOpen,
  id,
  payment_date,
  payment_id,
  updateData,
}) => {
  const [editData, setEditData] = useState();
  const [showDetails, setShowDetails] = useState(false);
  const [pricing, setPricing] = useState();
  const [pricingIsLoading, setPricingIsLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setShowDetails(false);
    setEditData(null);
    setPricing(null);
    onClose();
  };

  const getData = () => {
    const res = getEditPayment({ id, payment_id });
    res
      .then(({ data }) => {
        setEditData(data);
        setPricing({
          jobber_price: data.jobber_price,
          urssaf_price: data.urssaf_price,
          deduction: data.deduction,
          employee_part: data.employee_part,
          employer_part: data.employer_part,
          deduction_term: data.deduction_term,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const updatePricing = ({ price_per_hour, nb_hours }) => {
    const res = getSimulatePricing({ price_per_hour, nb_hours, id });
    setPricingIsLoading(true);
    clearTimeout(to);
    to = setTimeout(() => {
      res
        .then(({ data }) => {
          setPricing(data);
          setPricingIsLoading(false);
        })
        .catch((error) => {
          setPricingIsLoading(false);
          console.error(error);
        });
    }, 1000);
  };

  useEffect(() => {
    isOpen && getData();
  }, [isOpen]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = putUpdatePayment({ ...values, id, payment_id });
    res
      .then(({ data }) => {
        setIsLoading(false);
        handleClose();
        updateData();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={handleClose} fullScreenOnMobile>
      {editData && pricing ? (
        <>
          <Modal.Item.Header
            onClose={handleClose}
            title={`Paiement du ${formatDate(payment_date, "DD MMMM")}`}
            subtitle="Vous pouvez modifier le paiement avant que celui-ci ait lieu."
          />
          <Modal.Item.Wrapper>
            <Formik
              validationSchema={yup.object().shape({
                price_per_hour: yup
                  .number()
                  .min(editData.price_per_hour_min)
                  .max(editData.price_per_hour_max)
                  .required(),
                nb_hours: yup.number().when("frequency", {
                  is: "monthly",
                  then: yup
                    .number()
                    .min(0)
                    .max(editData.monthly_nb_hours_max)
                    .required(),
                  otherwise: yup
                    .number()
                    .min(0)
                    .max(editData.weekly_nb_hours_max)
                    .required(),
                }),
              })}
              initialValues={{
                nb_hours: editData.nb_hours,
                price_per_hour: editData.price_per_hour,
                update_future_payments: false,
                frequency: editData.frequency,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values, isValid }) => (
                <Form>
                  <List.Header kind="subtitle" title="Modifier le paiement" />
                  <List.Item
                    divider={false}
                    title="Nombre d'heures"
                    RightComponent={() => (
                      <Stepper
                        name="nb_hours"
                        suffix="h"
                        min={0}
                        max={
                          editData.frequency === "monthly"
                            ? editData.monthly_nb_hours_max
                            : editData.weekly_nb_hours_max
                        }
                        hint={
                          editData.frequency === "monthly"
                            ? "par mois"
                            : "par semaine"
                        }
                        onChange={(value) => {
                          setFieldValue("nb_hours", value);
                          value === 0 &&
                            setFieldValue("update_future_payments", false);
                          updatePricing({ ...values, nb_hours: value });
                        }}
                        value={values.nb_hours}
                      />
                    )}
                  />
                  <List.Item
                    divider={false}
                    title="Tarif horaire du prestataire"
                    RightComponent={() => (
                      <Stepper
                        name="price_per_hour"
                        suffix="€"
                        hint="par heure"
                        min={editData.price_per_hour_min}
                        max={editData.price_per_hour_max}
                        onChange={(value) => {
                          setFieldValue("price_per_hour", value);
                          updatePricing({ ...values, price_per_hour: value });
                        }}
                        value={values.price_per_hour}
                      />
                    )}
                  />
                  <List.Header
                    title="Récapitulatif"
                    RightComponent={() => (
                      <Button.Link
                        onClick={() => {
                          setShowDetails((v) => !v);
                        }}
                      >
                        Détails du paiement
                        <i className="icon-angle-down ml-2" />
                      </Button.Link>
                    )}
                  />
                  {showDetails && (
                    <div className="mt-3">
                      <SimulateTaxCredit
                        title={null}
                        urssaf={editData.urssaf}
                        isLoading={pricingIsLoading}
                        deduction={pricing.deduction}
                        employee_part={pricing.employee_part}
                        employer_part={pricing.employer_part}
                        deduction_term={pricing.deduction_term}
                        nb_hours={values.nb_hours}
                        price_per_hour={values.price_per_hour}
                        frequency={editData.frequency}
                      />
                    </div>
                  )}
                  <List.Item
                    RightComponent={() => (
                      <List.Elem.Label className="">
                        <AsyncPricing
                          value={getTotalPricing({
                            urssaf: editData.urssaf,
                            employee_part: pricing.employee_part,
                            employer_part: pricing.employer_part,
                            deduction: pricing.deduction,
                            price_per_hour: values.price_per_hour,
                            nb_hours: values.nb_hours,
                          })}
                          className="font-size-4 font-weight-medium text-body"
                          isLoading={pricingIsLoading}
                        />
                      </List.Elem.Label>
                    )}
                  >
                    <span className="font-weight-medium">Montant total</span>
                  </List.Item>
                  <Checkbox
                    name="update_future_payments"
                    className="mt-3"
                    disabled={values.nb_hours === 0}
                    checked={values.update_future_payments === true}
                    onChange={() => {
                      setFieldValue(
                        "update_future_payments",
                        !values.update_future_payments
                      );
                    }}
                  >
                    Conserver les modifications pour les prochains paiements
                  </Checkbox>
                  <Button.Large
                    type="submit"
                    block
                    className="mt-3"
                    isLoading={isLoading}
                    disabled={!isValid}
                  >
                    Enregistrer
                  </Button.Large>
                  <Button.Large
                    kind="minimal"
                    onClick={handleClose}
                    block
                    className="mt-2"
                  >
                    Annuler
                  </Button.Large>
                </Form>
              )}
            </Formik>
          </Modal.Item.Wrapper>
        </>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin={spacings.l}
        >
          <Spinner.Medium color={colors.muted} />
        </Block>
      )}
    </Modal.Small>
  );
};

export default EditPaymentModal;
