import React from "react";
import { spacings } from "../../../assets/themes";

const Footer = ({ children, column }) => (
  <div
    css={`
      display: flex;
      align-items: center;
      width: ${column && "100%"};
      flex-direction: ${column ? "column" : "row"};
      justify-content: ${column ? "center" : "flex-end"};
      margin-top: ${spacings.s};
      padding: ${column
        ? `${spacings.m} ${spacings.m} 0 ${spacings.m}`
        : `${spacings.s} ${spacings.m} 0 ${spacings.m}`};
    `}
  >
    {React.Children.map(children, (child, index) => (
      <div
        css={`
          width: ${column && "100%"};
          margin-left: ${index !== 0 && !column && spacings.s};
          margin-top: ${index !== 0 && column && spacings.s};
        `}
      >
        {React.cloneElement(child)}
      </div>
    ))}
  </div>
);

export default Footer;
