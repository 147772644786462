import { useCallback } from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, H3, Icon } from "../../components";
import polyglot from "../../utils/polyglot";

const Result = ({ surface }) => {
  const getMovingBoxesResult = useCallback(
    () => Math.ceil(surface * 0.75),
    [surface]
  );

  const getTapeRollResult = useCallback(
    () => Math.ceil(Math.max(getMovingBoxesResult() / 20, 1)),
    [surface]
  );

  return (
    <Block
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      margin={spacings.m}
    >
      <Body16 color={colors.muted}>{polyglot.t("moving.needed_boxes")}</Body16>
      <Block display="flex" alignItems="center">
        <Block marginRight={spacings.xs}>
          <Icon.Large name="box" />
        </Block>
        <H3>{getMovingBoxesResult()}</H3>
        <Body16
          strong
          css={`
            margin-left: ${spacings.s};
          `}
        >
          (
          {polyglot.t("moving_boxes.plus_n_tapes_roll_plural", {
            count: getTapeRollResult(),
          })}
          )
        </Body16>
      </Block>
    </Block>
  );
};
export default Result;
