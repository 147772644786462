import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  FilePicker,
  FormControl,
  List,
  Modal,
} from "../../../components";
import { putUpdateContract } from "../../../modules/routes/new-relation-routes";
import { formatDate } from "../../../utils";

const ContractModal = ({
  onClose,
  isOpen,
  duration,
  kind,
  start_date,
  probation_weeks,
  url,
  probation,
  id,
}) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = (values) => {
    const res = putUpdateContract({ id, values });
    setIsLoading(true);
    res
      .then(({ data }) => {
        window.location.href = history.createHref({ pathname: "/" });
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };
  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header
        onClose={onClose}
        title="Voir mon contrat de travail"
      />
      <List.Header withGutters title="Informations" />
      <List.Item
        withGutters
        title="Durée des services"
        RightComponent={() => (
          <List.Elem.Label>
            {duration === "fixed"
              ? "Nombre d'heures fixe"
              : "Nombre d'heures variable"}
          </List.Elem.Label>
        )}
      />
      <List.Item
        withGutters
        title="Type de contrat"
        RightComponent={() => (
          <List.Elem.Label>{kind.toUpperCase()}</List.Elem.Label>
        )}
      />
      <List.Item
        withGutters
        title="Date de début"
        RightComponent={() => (
          <List.Elem.Label>
            {formatDate(start_date, "DD/MM/YYYY")}
          </List.Elem.Label>
        )}
      />
      {probation && (
        <List.Item
          withGutters
          title="Période d'essai"
          RightComponent={() => (
            <List.Elem.Label>
              {probation_weeks > 1
                ? `${probation_weeks} semaines`
                : `${probation_weeks} semaine`}
            </List.Elem.Label>
          )}
        />
      )}
      {url ? (
        <>
          <List.Header withGutters title="Contrat de travail" />
          <List.Item
            withGutters
            chevron
            title="Voir le contrat de travail"
            LeftComponent={() => <i className="icon-download text-primary" />}
            href={url}
            target="_blank"
          />
        </>
      ) : (
        <Modal.Item.Wrapper className="mt-3">
          <Formik onSubmit={handleSubmit} initialValues={{ attachment: null }}>
            {({ values, setFieldValue }) => (
              <Form>
                <FormControl label="Joindre un contrat (.pdf)">
                  <FilePicker
                    name="attachment"
                    value={values.attachment}
                    accept="application/pdf"
                    onRemove={() => setFieldValue("attachment", "")}
                    onChange={(event) => {
                      setFieldValue("attachment", event.currentTarget.files[0]);
                    }}
                  />
                </FormControl>
                <Button.Large
                  block
                  disabled={!values.attachment}
                  isLoading={isLoading}
                  type="submit"
                >
                  Envoyer
                </Button.Large>
              </Form>
            )}
          </Formik>
        </Modal.Item.Wrapper>
      )}
    </Modal.Small>
  );
};

export default ContractModal;
