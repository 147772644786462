import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1009() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1,
            subtitle: polyglot.t("categories.one_to_two_shelves"),
            popular: true,
          },
          {
            label: polyglot.t("categories.classic"),
            value: 2,
            subtitle: polyglot.t("categories.three_to_four_shelves"),
          },
          {
            label: polyglot.t("categories.long"),
            value: 3,
            subtitle: polyglot.t(
              "categories.more_than_four_shelves_or_modular_shelves"
            ),
          },
        ],
      },
    },
  ];

  return {
    pages,
    initialValues: { nb_hours: 1 },
    version: 1.1,
    config: {
      IsNbHoursReadOnly: true,
    },
  };
}
