import React from "react";

const useTrustBox = (attributes) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-businessunit-id="600969dcc770200001eed841"
      data-locale="fr-FR"
      data-theme="light"
      {...attributes}
    >
      <a
        href="https://www.trustpilot.com/review/yoojo.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  );
};
export default useTrustBox;
