import polyglot from "@/utils/polyglot";
import { spacings } from "../../assets/themes";
import { Block, Body16, Button, H3, Modal } from "../../components";

const CesuSuccess = ({ isOpen, finishPath = "/account/cesu" }) => (
  <Modal.XSmall
    isOpen={isOpen}
    fullScreenOnMobile
    css={`
      > div > div {
        overflow: hidden;
      }
    `}
  >
    <svg
      width="380"
      height="212"
      className="svg-scan-success"
      viewBox="0 0 380 212"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="CRCESU.icon.waitingForValidation" fill="none" fillRule="evenodd">
        <rect fill="#FFF" width="380" height="212" />
        <path
          d="M220.119095,77.5 L249.26528,77.5 C261.691687,77.5 271.76528,87.5735931 271.76528,100 L271.76528,128.72449 L380,128.72449 L380,143.72449 L271.76528,143.72449 L271.76528,170.180641 C271.76528,174.322776 275.123144,177.680641 279.26528,177.680641 L380,177.680641 L380,192.680641 L279.26528,192.680641 C266.838873,192.680641 256.76528,182.607048 256.76528,170.180641 L256.76528,143.72449 L227.619095,143.72449 C215.192688,143.72449 205.119095,133.650897 205.119095,121.22449 L205.119095,92.5 L99.9313961,92.5 C95.7892605,92.5 92.4313961,95.8578644 92.4313961,100 L92.4313961,145.72493 C92.4313961,158.151336 82.357803,168.22493 69.9313961,168.22493 L0,168.22493 L0,153.22493 L69.9313961,153.22493 C74.0735318,153.22493 77.4313961,149.867065 77.4313961,145.72493 L77.4313961,100 C77.4313961,87.5735931 87.5049893,77.5 99.9313961,77.5 L205.119095,77.5 L205.119095,39 C205.119095,34.8578644 201.76123,31.5 197.619095,31.5 L0,31.5 L0,16.5 L197.619095,16.5 C210.045501,16.5 220.119095,26.5735931 220.119095,39 L220.119095,77.5 Z M220.119095,92.5 L220.119095,121.22449 C220.119095,125.366625 223.476959,128.72449 227.619095,128.72449 L256.76528,128.72449 L256.76528,100 C256.76528,95.8578644 253.407415,92.5 249.26528,92.5 L220.119095,92.5 L220.119095,92.5 Z"
          id="bg"
          fill="#F2F2F2"
          fillRule="nonzero"
        />
        <g id="tickets1" transform="translate(-23 -1.86)">
          <g id="group">
            <g id="ticket1" transform="translate(306)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="62.511935 100.479113 61.9655633 0.816081082 56.9656205 0.836967729 57.5119923 100.5"
              />
              <g id="CRCESU.ticket" transform="translate(0 100)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.4"
                  y="2.437"
                  width="115.2"
                  height="61.735"
                  rx="1.92"
                />
                <path
                  d="M5.59999933,0.406153798 L114.399986,0.406153798 C117.271867,0.406153798 119.599986,2.77009004 119.599986,5.68615317 L119.599986,60.9230697 C119.599986,63.8391328 117.271867,66.203069 114.399986,66.203069 L5.59999933,66.203069 C2.72811898,66.203069 0.399999952,63.8391328 0.399999952,60.9230697 L0.399999952,5.68615317 C0.399999952,2.77009004 2.72811898,0.406153798 5.59999933,0.406153798 Z M5.59999933,4.46769178 C4.93725771,4.46769178 4.39999948,5.01321552 4.39999948,5.68615317 L4.39999948,60.9230697 C4.39999948,61.5960073 4.93725771,62.1415311 5.59999933,62.1415311 L114.399986,62.1415311 C115.062728,62.1415311 115.599986,61.5960073 115.599986,60.9230697 L115.599986,5.68615317 C115.599986,5.01321552 115.062728,4.46769178 114.399986,4.46769178 L5.59999933,4.46769178 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.3999935,15.0276905 C54.3999935,16.1492533 53.5045631,17.0584595 52.3999938,17.0584595 L15.5999981,17.0584595 C14.4954288,17.0584595 13.5999984,16.1492533 13.5999984,15.0276905 C13.5999984,13.9061278 14.4954288,12.9969215 15.5999984,12.9969215 L52.3999938,12.9969215 C53.5045631,12.9969215 54.3999935,13.9061278 54.3999935,15.0276905 Z M39.1999953,23.1507665 C39.1999953,24.2723292 38.3045649,25.1815355 37.1999956,25.1815355 L15.5999981,25.1815355 C14.4954288,25.1815355 13.5999984,24.2723292 13.5999984,23.1507665 C13.5999984,22.0292037 14.4954288,21.1199975 15.5999984,21.1199975 L37.1999956,21.1199975 C38.3045649,21.1199975 39.1999953,22.0292037 39.1999953,23.1507665 Z M61.9999928,43.5242247 L76.3999909,43.5242247 C77.5045603,43.5242247 78.3999907,44.433431 78.3999907,45.5549937 L78.3999907,49.6165317 C78.3999907,50.7380945 77.5045603,51.6473007 76.3999909,51.6473007 L61.9999926,51.6473007 C60.8954232,51.6473007 59.9999928,50.7380945 59.9999928,49.6165317 L59.9999928,45.5549937 C59.9999928,44.433431 60.8954232,43.5242247 61.9999928,43.5242247 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.5999888,51.9876861 L107.199987,51.9876861 L107.199987,56.8615317 L93.5999888,56.8615317 L93.5999888,51.9876861 Z M93.5999888,38.178457 L107.199987,38.178457 L107.199987,43.0523026 L93.5999888,43.0523026 L93.5999888,38.178457 Z M93.5999888,18.6830747 L107.199987,18.6830747 L107.199987,22.7446127 L93.5999888,22.7446127 L93.5999888,18.6830747 Z M93.5999888,47.9261481 L107.199987,47.9261481 L107.199987,49.5507633 L93.5999888,49.5507633 L93.5999888,47.9261481 Z M93.5999888,44.6769178 L107.199987,44.6769178 L107.199987,46.3015329 L93.5999888,46.3015329 L93.5999888,44.6769178 Z M93.5999888,24.3692279 L107.199987,24.3692279 L107.199987,25.9938431 L93.5999888,25.9938431 L93.5999888,24.3692279 Z M93.5999888,13.8092291 L107.199987,13.8092291 L107.199987,15.4338443 L93.5999888,15.4338443 L93.5999888,13.8092291 Z M93.5999888,9.74769115 L107.199987,9.74769115 L107.199987,12.1846139 L93.5999888,12.1846139 L93.5999888,9.74769115 Z M93.5999888,33.3046114 L107.199987,33.3046114 L107.199987,34.9292266 L93.5999888,34.9292266 L93.5999888,33.3046114 Z M93.5999888,28.4307658 L107.199987,28.4307658 L107.199987,30.8676886 L93.5999888,30.8676886 L93.5999888,28.4307658 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
            <g id="ticket2" transform="translate(152 1)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="63.5 99.5 63.5 0.5 58.5 0.5 58.5 99.5"
              />
              <g id="CRCESU.ticket" transform="translate(.925 98.649)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.403"
                  y="2.44"
                  width="115.344"
                  height="61.813"
                  rx="1.922"
                />
                <path
                  d="M5.60699943,0.406661497 L114.542988,0.406661497 C117.418459,0.406661497 119.749488,2.7735527 119.749488,5.69326096 L119.749488,60.9992245 C119.749488,63.9189328 117.418459,66.285824 114.542988,66.285824 L5.60699943,66.285824 C2.73152917,66.285824 0.400499959,63.9189328 0.400499959,60.9992245 L0.400499959,5.69326096 C0.400499959,2.7735527 2.73152917,0.406661497 5.60699943,0.406661497 Z M5.60699943,4.47327647 C4.94342937,4.47327647 4.40549955,5.01948213 4.40549955,5.69326096 L4.40549955,60.9992245 C4.40549955,61.6730034 4.94342937,62.219209 5.60699943,62.219209 L114.542988,62.219209 C115.206558,62.219209 115.744488,61.6730034 115.744488,60.9992245 L115.744488,5.69326096 C115.744488,5.01948213 115.206558,4.47327647 114.542988,4.47327647 L5.60699943,4.47327647 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.4679944,15.0464754 C54.4679944,16.1694401 53.5714447,17.0797829 52.4654946,17.0797829 L15.6194984,17.0797829 C14.5135483,17.0797829 13.6169986,16.1694401 13.6169986,15.0464754 C13.6169986,13.9235107 14.5135483,13.0131679 15.6194984,13.0131679 L52.4654946,13.0131679 C53.5714447,13.0131679 54.4679944,13.9235107 54.4679944,15.0464754 Z M39.248996,23.1797053 C39.248996,24.30267 38.3524463,25.2130128 37.2464962,25.2130128 L15.6194984,25.2130128 C14.5135483,25.2130128 13.6169986,24.30267 13.6169986,23.1797053 C13.6169986,22.0567406 14.5135483,21.1463978 15.6194984,21.1463978 L37.2464962,21.1463978 C38.3524463,21.1463978 39.248996,22.0567406 39.248996,23.1797053 Z M62.0774937,43.5786308 L76.4954922,43.5786308 C77.6014423,43.5786308 78.497992,44.4889735 78.497992,45.6119383 L78.497992,49.6785532 C78.497992,50.8015179 77.6014423,51.7118607 76.4954922,51.7118607 L62.0774937,51.7118607 C60.9715436,51.7118607 60.0749939,50.8015179 60.0749939,49.6785532 L60.0749939,45.6119383 C60.0749939,44.4889735 60.9715436,43.5786308 62.0774937,43.5786308 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.7169904,52.0526716 L107.333989,52.0526716 L107.333989,56.9326096 L93.7169904,56.9326096 L93.7169904,52.0526716 Z M93.7169904,38.2261807 L107.333989,38.2261807 L107.333989,43.1061187 L93.7169904,43.1061187 L93.7169904,38.2261807 Z M93.7169904,18.7064289 L107.333989,18.7064289 L107.333989,22.7730438 L93.7169904,22.7730438 L93.7169904,18.7064289 Z M93.7169904,47.9860566 L107.333989,47.9860566 L107.333989,49.6127026 L93.7169904,49.6127026 L93.7169904,47.9860566 Z M93.7169904,44.7327647 L107.333989,44.7327647 L107.333989,46.3594106 L93.7169904,46.3594106 L93.7169904,44.7327647 Z M93.7169904,24.3996898 L107.333989,24.3996898 L107.333989,26.0263358 L93.7169904,26.0263358 L93.7169904,24.3996898 Z M93.7169904,13.8264909 L107.333989,13.8264909 L107.333989,15.4531369 L93.7169904,15.4531369 L93.7169904,13.8264909 Z M93.7169904,9.75987593 L107.333989,9.75987593 L107.333989,12.1998449 L93.7169904,12.1998449 L93.7169904,9.75987593 Z M93.7169904,33.3462427 L107.333989,33.3462427 L107.333989,34.9728887 L93.7169904,34.9728887 L93.7169904,33.3462427 Z M93.7169904,28.4663048 L107.333989,28.4663048 L107.333989,30.9062738 L93.7169904,30.9062738 L93.7169904,28.4663048 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
            <g id="ticket3" transform="translate(0 1)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="62.5211872 99.4641305 61.5181873 0.136795548 56.5183684 0.172665018 57.5213683 99.5"
              />
              <g id="CRCESU.ticket" transform="translate(0 98.761)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.4"
                  y="2.437"
                  width="115.2"
                  height="61.735"
                  rx="1.92"
                />
                <path
                  d="M5.59999967,0.406153822 L114.4,0.406153822 C117.271874,0.406153822 119.599993,2.7700902 119.599993,5.68615351 L119.599993,60.9230733 C119.599993,63.8391366 117.271874,66.203073 114.399993,66.203073 L5.59999967,66.203073 C2.72811914,66.203073 0.399999976,63.8391366 0.399999976,60.9230733 L0.399999976,5.68615351 C0.399999976,2.7700902 2.72811914,0.406153822 5.59999967,0.406153822 Z M5.59999967,4.46769204 C4.93725801,4.46769204 4.39999974,5.01321582 4.39999974,5.68615351 L4.39999974,60.9230733 C4.39999974,61.596011 4.93725801,62.1415348 5.59999967,62.1415348 L114.399993,62.1415348 C115.062735,62.1415348 115.599993,61.596011 115.599993,60.9230733 L115.599993,5.68615351 C115.599993,5.01321582 115.062735,4.46769204 114.4,4.46769204 L5.59999967,4.46769204 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.3999968,15.0276914 C54.3999968,16.1492542 53.5045663,17.0584605 52.3999969,17.0584605 L15.5999991,17.0584605 C14.4954296,17.0584605 13.5999992,16.1492542 13.5999992,15.0276914 C13.5999992,13.9061286 14.4954296,12.9969223 15.5999992,12.9969223 L52.3999969,12.9969223 C53.5045663,12.9969223 54.3999968,13.9061286 54.3999968,15.0276914 Z M39.1999977,23.1507679 C39.1999977,24.2723307 38.3045672,25.181537 37.1999978,25.181537 L15.5999991,25.181537 C14.4954296,25.181537 13.5999992,24.2723307 13.5999992,23.1507679 C13.5999992,22.029205 14.4954296,21.1199987 15.5999992,21.1199987 L37.1999978,21.1199987 C38.3045672,21.1199987 39.1999977,22.029205 39.1999977,23.1507679 Z M61.9999964,43.5242273 L76.3999954,43.5242273 C77.5045649,43.5242273 78.3999953,44.4334336 78.3999953,45.5549965 L78.3999953,49.6165347 C78.3999953,50.7380975 77.5045649,51.6473038 76.3999954,51.6473038 L61.9999963,51.6473038 C60.8954269,51.6473038 59.9999964,50.7380975 59.9999964,49.6165347 L59.9999964,45.5549965 C59.9999964,44.4334336 60.8954269,43.5242273 61.9999964,43.5242273 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.5999944,51.9876892 L107.199994,51.9876892 L107.199994,56.8615351 L93.5999944,56.8615351 L93.5999944,51.9876892 Z M93.5999944,38.1784593 L107.199994,38.1784593 L107.199994,43.0523051 L93.5999944,43.0523051 L93.5999944,38.1784593 Z M93.5999944,18.6830758 L107.199994,18.6830758 L107.199994,22.744614 L93.5999944,22.744614 L93.5999944,18.6830758 Z M93.5999944,47.926151 L107.199994,47.926151 L107.199994,49.5507663 L93.5999944,49.5507663 L93.5999944,47.926151 Z M93.5999944,44.6769204 L107.199994,44.6769204 L107.199994,46.3015357 L93.5999944,46.3015357 L93.5999944,44.6769204 Z M93.5999944,24.3692293 L107.199994,24.3692293 L107.199994,25.9938446 L93.5999944,25.9938446 L93.5999944,24.3692293 Z M93.5999944,13.8092299 L107.199994,13.8092299 L107.199994,15.4338452 L93.5999944,15.4338452 L93.5999944,13.8092299 Z M93.5999944,9.74769173 L107.199994,9.74769173 L107.199994,12.1846147 L93.5999944,12.1846147 L93.5999944,9.74769173 Z M93.5999944,33.3046134 L107.199994,33.3046134 L107.199994,34.9292287 L93.5999944,34.9292287 L93.5999944,33.3046134 Z M93.5999944,28.4307675 L107.199994,28.4307675 L107.199994,30.8676905 L93.5999944,30.8676905 L93.5999944,28.4307675 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
          </g>
        </g>
        <g id="tickets2" transform="translate(-22 -1.86)">
          <g id="group">
            <g id="ticket1" transform="translate(306)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="62.511935 100.479113 61.9655633 0.816081082 56.9656205 0.836967729 57.5119923 100.5"
              />
              <g id="CRCESU.ticket" transform="translate(0 100)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.4"
                  y="2.437"
                  width="115.2"
                  height="61.735"
                  rx="1.92"
                />
                <path
                  d="M5.59999933,0.406153798 L114.399986,0.406153798 C117.271867,0.406153798 119.599986,2.77009004 119.599986,5.68615317 L119.599986,60.9230697 C119.599986,63.8391328 117.271867,66.203069 114.399986,66.203069 L5.59999933,66.203069 C2.72811898,66.203069 0.399999952,63.8391328 0.399999952,60.9230697 L0.399999952,5.68615317 C0.399999952,2.77009004 2.72811898,0.406153798 5.59999933,0.406153798 Z M5.59999933,4.46769178 C4.93725771,4.46769178 4.39999948,5.01321552 4.39999948,5.68615317 L4.39999948,60.9230697 C4.39999948,61.5960073 4.93725771,62.1415311 5.59999933,62.1415311 L114.399986,62.1415311 C115.062728,62.1415311 115.599986,61.5960073 115.599986,60.9230697 L115.599986,5.68615317 C115.599986,5.01321552 115.062728,4.46769178 114.399986,4.46769178 L5.59999933,4.46769178 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.3999935,15.0276905 C54.3999935,16.1492533 53.5045631,17.0584595 52.3999938,17.0584595 L15.5999981,17.0584595 C14.4954288,17.0584595 13.5999984,16.1492533 13.5999984,15.0276905 C13.5999984,13.9061278 14.4954288,12.9969215 15.5999984,12.9969215 L52.3999938,12.9969215 C53.5045631,12.9969215 54.3999935,13.9061278 54.3999935,15.0276905 Z M39.1999953,23.1507665 C39.1999953,24.2723292 38.3045649,25.1815355 37.1999956,25.1815355 L15.5999981,25.1815355 C14.4954288,25.1815355 13.5999984,24.2723292 13.5999984,23.1507665 C13.5999984,22.0292037 14.4954288,21.1199975 15.5999984,21.1199975 L37.1999956,21.1199975 C38.3045649,21.1199975 39.1999953,22.0292037 39.1999953,23.1507665 Z M61.9999928,43.5242247 L76.3999909,43.5242247 C77.5045603,43.5242247 78.3999907,44.433431 78.3999907,45.5549937 L78.3999907,49.6165317 C78.3999907,50.7380945 77.5045603,51.6473007 76.3999909,51.6473007 L61.9999926,51.6473007 C60.8954232,51.6473007 59.9999928,50.7380945 59.9999928,49.6165317 L59.9999928,45.5549937 C59.9999928,44.433431 60.8954232,43.5242247 61.9999928,43.5242247 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.5999888,51.9876861 L107.199987,51.9876861 L107.199987,56.8615317 L93.5999888,56.8615317 L93.5999888,51.9876861 Z M93.5999888,38.178457 L107.199987,38.178457 L107.199987,43.0523026 L93.5999888,43.0523026 L93.5999888,38.178457 Z M93.5999888,18.6830747 L107.199987,18.6830747 L107.199987,22.7446127 L93.5999888,22.7446127 L93.5999888,18.6830747 Z M93.5999888,47.9261481 L107.199987,47.9261481 L107.199987,49.5507633 L93.5999888,49.5507633 L93.5999888,47.9261481 Z M93.5999888,44.6769178 L107.199987,44.6769178 L107.199987,46.3015329 L93.5999888,46.3015329 L93.5999888,44.6769178 Z M93.5999888,24.3692279 L107.199987,24.3692279 L107.199987,25.9938431 L93.5999888,25.9938431 L93.5999888,24.3692279 Z M93.5999888,13.8092291 L107.199987,13.8092291 L107.199987,15.4338443 L93.5999888,15.4338443 L93.5999888,13.8092291 Z M93.5999888,9.74769115 L107.199987,9.74769115 L107.199987,12.1846139 L93.5999888,12.1846139 L93.5999888,9.74769115 Z M93.5999888,33.3046114 L107.199987,33.3046114 L107.199987,34.9292266 L93.5999888,34.9292266 L93.5999888,33.3046114 Z M93.5999888,28.4307658 L107.199987,28.4307658 L107.199987,30.8676886 L93.5999888,30.8676886 L93.5999888,28.4307658 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
            <g id="ticket2" transform="translate(152 1)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="63.5 99.5 63.5 0.5 58.5 0.5 58.5 99.5"
              />
              <g id="CRCESU.ticket" transform="translate(.925 98.649)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.403"
                  y="2.44"
                  width="115.344"
                  height="61.813"
                  rx="1.922"
                />
                <path
                  d="M5.60699943,0.406661497 L114.542988,0.406661497 C117.418459,0.406661497 119.749488,2.7735527 119.749488,5.69326096 L119.749488,60.9992245 C119.749488,63.9189328 117.418459,66.285824 114.542988,66.285824 L5.60699943,66.285824 C2.73152917,66.285824 0.400499959,63.9189328 0.400499959,60.9992245 L0.400499959,5.69326096 C0.400499959,2.7735527 2.73152917,0.406661497 5.60699943,0.406661497 Z M5.60699943,4.47327647 C4.94342937,4.47327647 4.40549955,5.01948213 4.40549955,5.69326096 L4.40549955,60.9992245 C4.40549955,61.6730034 4.94342937,62.219209 5.60699943,62.219209 L114.542988,62.219209 C115.206558,62.219209 115.744488,61.6730034 115.744488,60.9992245 L115.744488,5.69326096 C115.744488,5.01948213 115.206558,4.47327647 114.542988,4.47327647 L5.60699943,4.47327647 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.4679944,15.0464754 C54.4679944,16.1694401 53.5714447,17.0797829 52.4654946,17.0797829 L15.6194984,17.0797829 C14.5135483,17.0797829 13.6169986,16.1694401 13.6169986,15.0464754 C13.6169986,13.9235107 14.5135483,13.0131679 15.6194984,13.0131679 L52.4654946,13.0131679 C53.5714447,13.0131679 54.4679944,13.9235107 54.4679944,15.0464754 Z M39.248996,23.1797053 C39.248996,24.30267 38.3524463,25.2130128 37.2464962,25.2130128 L15.6194984,25.2130128 C14.5135483,25.2130128 13.6169986,24.30267 13.6169986,23.1797053 C13.6169986,22.0567406 14.5135483,21.1463978 15.6194984,21.1463978 L37.2464962,21.1463978 C38.3524463,21.1463978 39.248996,22.0567406 39.248996,23.1797053 Z M62.0774937,43.5786308 L76.4954922,43.5786308 C77.6014423,43.5786308 78.497992,44.4889735 78.497992,45.6119383 L78.497992,49.6785532 C78.497992,50.8015179 77.6014423,51.7118607 76.4954922,51.7118607 L62.0774937,51.7118607 C60.9715436,51.7118607 60.0749939,50.8015179 60.0749939,49.6785532 L60.0749939,45.6119383 C60.0749939,44.4889735 60.9715436,43.5786308 62.0774937,43.5786308 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.7169904,52.0526716 L107.333989,52.0526716 L107.333989,56.9326096 L93.7169904,56.9326096 L93.7169904,52.0526716 Z M93.7169904,38.2261807 L107.333989,38.2261807 L107.333989,43.1061187 L93.7169904,43.1061187 L93.7169904,38.2261807 Z M93.7169904,18.7064289 L107.333989,18.7064289 L107.333989,22.7730438 L93.7169904,22.7730438 L93.7169904,18.7064289 Z M93.7169904,47.9860566 L107.333989,47.9860566 L107.333989,49.6127026 L93.7169904,49.6127026 L93.7169904,47.9860566 Z M93.7169904,44.7327647 L107.333989,44.7327647 L107.333989,46.3594106 L93.7169904,46.3594106 L93.7169904,44.7327647 Z M93.7169904,24.3996898 L107.333989,24.3996898 L107.333989,26.0263358 L93.7169904,26.0263358 L93.7169904,24.3996898 Z M93.7169904,13.8264909 L107.333989,13.8264909 L107.333989,15.4531369 L93.7169904,15.4531369 L93.7169904,13.8264909 Z M93.7169904,9.75987593 L107.333989,9.75987593 L107.333989,12.1998449 L93.7169904,12.1998449 L93.7169904,9.75987593 Z M93.7169904,33.3462427 L107.333989,33.3462427 L107.333989,34.9728887 L93.7169904,34.9728887 L93.7169904,33.3462427 Z M93.7169904,28.4663048 L107.333989,28.4663048 L107.333989,30.9062738 L93.7169904,30.9062738 L93.7169904,28.4663048 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
            <g id="ticket3" transform="translate(0 1)">
              <polygon
                id="Line"
                fill="#252932"
                fillRule="nonzero"
                points="62.5211872 99.4641305 61.5181873 0.136795548 56.5183684 0.172665018 57.5213683 99.5"
              />
              <g id="CRCESU.ticket" transform="translate(0 98.761)">
                <rect
                  id="bg"
                  fill="#DCEBFF"
                  x="2.4"
                  y="2.437"
                  width="115.2"
                  height="61.735"
                  rx="1.92"
                />
                <path
                  d="M5.59999967,0.406153822 L114.4,0.406153822 C117.271874,0.406153822 119.599993,2.7700902 119.599993,5.68615351 L119.599993,60.9230733 C119.599993,63.8391366 117.271874,66.203073 114.399993,66.203073 L5.59999967,66.203073 C2.72811914,66.203073 0.399999976,63.8391366 0.399999976,60.9230733 L0.399999976,5.68615351 C0.399999976,2.7700902 2.72811914,0.406153822 5.59999967,0.406153822 Z M5.59999967,4.46769204 C4.93725801,4.46769204 4.39999974,5.01321582 4.39999974,5.68615351 L4.39999974,60.9230733 C4.39999974,61.596011 4.93725801,62.1415348 5.59999967,62.1415348 L114.399993,62.1415348 C115.062735,62.1415348 115.599993,61.596011 115.599993,60.9230733 L115.599993,5.68615351 C115.599993,5.01321582 115.062735,4.46769204 114.4,4.46769204 L5.59999967,4.46769204 Z"
                  id="stroke"
                  fill="#0078FA"
                  fillRule="nonzero"
                />
                <path
                  d="M54.3999968,15.0276914 C54.3999968,16.1492542 53.5045663,17.0584605 52.3999969,17.0584605 L15.5999991,17.0584605 C14.4954296,17.0584605 13.5999992,16.1492542 13.5999992,15.0276914 C13.5999992,13.9061286 14.4954296,12.9969223 15.5999992,12.9969223 L52.3999969,12.9969223 C53.5045663,12.9969223 54.3999968,13.9061286 54.3999968,15.0276914 Z M39.1999977,23.1507679 C39.1999977,24.2723307 38.3045672,25.181537 37.1999978,25.181537 L15.5999991,25.181537 C14.4954296,25.181537 13.5999992,24.2723307 13.5999992,23.1507679 C13.5999992,22.029205 14.4954296,21.1199987 15.5999992,21.1199987 L37.1999978,21.1199987 C38.3045672,21.1199987 39.1999977,22.029205 39.1999977,23.1507679 Z M61.9999964,43.5242273 L76.3999954,43.5242273 C77.5045649,43.5242273 78.3999953,44.4334336 78.3999953,45.5549965 L78.3999953,49.6165347 C78.3999953,50.7380975 77.5045649,51.6473038 76.3999954,51.6473038 L61.9999963,51.6473038 C60.8954269,51.6473038 59.9999964,50.7380975 59.9999964,49.6165347 L59.9999964,45.5549965 C59.9999964,44.4334336 60.8954269,43.5242273 61.9999964,43.5242273 Z"
                  id="Shape"
                  fill="#0078FA"
                />
                <path
                  d="M93.5999944,51.9876892 L107.199994,51.9876892 L107.199994,56.8615351 L93.5999944,56.8615351 L93.5999944,51.9876892 Z M93.5999944,38.1784593 L107.199994,38.1784593 L107.199994,43.0523051 L93.5999944,43.0523051 L93.5999944,38.1784593 Z M93.5999944,18.6830758 L107.199994,18.6830758 L107.199994,22.744614 L93.5999944,22.744614 L93.5999944,18.6830758 Z M93.5999944,47.926151 L107.199994,47.926151 L107.199994,49.5507663 L93.5999944,49.5507663 L93.5999944,47.926151 Z M93.5999944,44.6769204 L107.199994,44.6769204 L107.199994,46.3015357 L93.5999944,46.3015357 L93.5999944,44.6769204 Z M93.5999944,24.3692293 L107.199994,24.3692293 L107.199994,25.9938446 L93.5999944,25.9938446 L93.5999944,24.3692293 Z M93.5999944,13.8092299 L107.199994,13.8092299 L107.199994,15.4338452 L93.5999944,15.4338452 L93.5999944,13.8092299 Z M93.5999944,9.74769173 L107.199994,9.74769173 L107.199994,12.1846147 L93.5999944,12.1846147 L93.5999944,9.74769173 Z M93.5999944,33.3046134 L107.199994,33.3046134 L107.199994,34.9292287 L93.5999944,34.9292287 L93.5999944,33.3046134 Z M93.5999944,28.4307675 L107.199994,28.4307675 L107.199994,30.8676905 L93.5999944,30.8676905 L93.5999944,28.4307675 Z"
                  id="Shape"
                  fill="#0078FA"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
    <Modal.Item.Wrapper>
      <Block marginBottom={spacings.m}>
        <H3>{polyglot.t("cesu.transfer_with_success")}</H3>
      </Block>
      <Body16>{polyglot.t("cesu.treatment_delay")}</Body16>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large
        block
        onClick={() => {
          window.location.href = finishPath;
        }}
      >
        {polyglot.t("cesu.see_my_tickets")}
      </Button.Large>
    </Modal.Item.Footer>
  </Modal.XSmall>
);

export default CesuSuccess;
