import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { sizes, spacings } from "@/assets/themes";
import { formatDate } from "@/utils";
import Block from "../Block";
import { isAfter } from "../DatePicker/utils";
import Selectable from "../Selectable";
import Skeleton from "../skeleton";
import { Body16 } from "../Text";
import ScrollableDays from "./ScrollableDays";
import { SELECTABLE } from "../Styles/variants";

// Default SlotComponent using Selectable.Small
const DefaultSlotComponent = ({ children, ...props }) => (
  <Selectable.Small {...props} shape={SELECTABLE.SHAPE.CIRCLE}>
    {children}
  </Selectable.Small>
);

const ScrollableDatePicker = ({
  slots,
  availabilities,
  onChange,
  minDate,
  values = {},
  format = "YYYY-MM-DD",
  onCarouselChange,
  SlotComponent = DefaultSlotComponent,
  label,
}) => {
  const [currDate, setCurrDate] = useState(values?.date || minDate);
  const [currSlot, setCurrSlot] = useState(values.start_hour);
  const [pendingDate, setPendingDate] = useState(null);
  const [direction, setDirection] = useState(1);
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (onChange) {
      onChange({
        date: currDate,
        start_hour: currSlot,
        change: "initial",
      });
    }
  }, []);

  const handleSlotChange = (value) => {
    setCurrSlot(value);
    if (onChange)
      onChange({
        date: formatDate(currDate, format),
        start_hour: value,
        change: "start_hour",
      });
  };

  const handleDateChange = (newDate) => {
    setDirection(isAfter(newDate, currDate, format) ? 1 : -1);
    onChange({
      date: newDate,
      start_hour: currSlot,
      change: "date",
    });
    setPendingDate(newDate);
  };

  // useEffect(() => {
  //   if (currSlot !== values.start_hour) handleSlotChange(values.start_hour);
  // }, [values.start_hour]);

  useEffect(() => {
    if (pendingDate !== null) {
      setCurrDate(pendingDate);
      setPendingDate(null);
    }
  }, [direction, pendingDate]);

  // useEffect(() => {
  //   if (slots(currDate)?.find((slot) => slot.value === currSlot)) {
  //     setCurrSlot(null);
  //   }
  // }, [currDate, slots]);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.offsetHeight);
    }
  }, [currDate, slots]);

  const memoizedSlots = useMemo(
    () => slots(formatDate(currDate, format)),
    [currDate, slots]
  );

  useEffect(() => {
    const currentSlotIsDisabled = currSlot
      ? memoizedSlots?.find((slot) => slot.value === currSlot && slot.disabled)
      : false;
    if (currentSlotIsDisabled) {
      handleSlotChange(null);
    }
  }, [memoizedSlots]);

  return (
    <Block spaceY={spacings.sm}>
      <ScrollableDays
        format={format}
        minDate={minDate}
        onChange={handleDateChange}
        availabilities={availabilities}
        onCarouselChange={onCarouselChange}
        active={currDate}
      />
      {label && (
        <Body16 strong align="center">
          {label}
        </Body16>
      )}
      <div
        style={{
          height: contentHeight,
          position: "relative",
          overflow: "hidden",
        }}
      >
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={currDate}
            initial={{ x: 200 * direction, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -200 * direction, opacity: 0 }}
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            style={{
              position: "absolute",
              width: "100%",
            }}
          >
            <div ref={contentRef}>
              {memoizedSlots?.length > 0 ? (
                <Block
                  display="grid"
                  gridCols={{ xs: 4, md: 6, lg: 7 }}
                  gap={spacings.s}
                >
                  {memoizedSlots?.map(({ label, value, disabled }) => (
                    <SlotComponent
                      key={`${currDate}-${value}`}
                      disabled={disabled}
                      checked={currSlot === value}
                      onClick={() => handleSlotChange(value)}
                    >
                      <Body16>{label}</Body16>
                    </SlotComponent>
                  ))}
                </Block>
              ) : (
                <Skeleton.Group>
                  <Block
                    display="grid"
                    gridCols={{ xs: 4, md: 6, lg: 7 }}
                    gap={spacings.s}
                  >
                    {new Array(29).fill("").map((_, index) => (
                      <Skeleton
                        height={sizes.size36}
                        key={`placeholder-slots-${index}`}
                      />
                    ))}
                  </Block>
                </Skeleton.Group>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </Block>
  );
};

export default ScrollableDatePicker;
