import { useEffect, useState } from "react";
import { MemoryRouter, Redirect, Route, Switch } from "react-router-dom";
import { Alert, Modal } from "../../components";
import { useListenToTargetClick } from "../../modules/hooks";
import SwitchAuth from "./SwitchAuth";
import Login from "./login/Login";
import ConfirmUser from "./login/content/ConfirmUser";
import CreateUser from "./login/content/CreateUser";
import SignUp from "./signUp/SignUp";
import { formatRedirectionUrl } from "./utils";
import polyglot from "@/utils/polyglot";

const Wrapper = ({ modal, children, isOpen, onClose }) =>
  modal ? (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      {children}
    </Modal.Small>
  ) : (
    <>{children}</>
  );

const RouteFooter = ({ hasSwitch, isJobber, forceNavigation }) =>
  hasSwitch ? (
    <SwitchAuth forceNavigation={forceNavigation} isJobber={isJobber} />
  ) : (
    <div />
  );

const LoginOrRegisterDispatch = (props) => {
  const {
    selector,
    type,
    hasSwitch,
    autoOpen = false,
    modal,
    redirectionPath,
    forceRedirectionPath,
    formId,
    forceNavigation,
    isJobber,
    onClose,
    isOpen,
    onSuccess,
    params = {},
    alertMessage,
  } = props;
  const [modalIsOpen, setModalIsOpen] = useState(autoOpen);

  const handleClick = (e) => {
    if (e) e.preventDefault();
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    if (onClose) onClose();
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (isOpen === true) {
      handleClick();
    }
    if (isOpen === false) {
      handleCloseModal();
    }
  }, [isOpen]);

  useListenToTargetClick(selector, handleClick);

  const handleSuccess = (res) => {
    const forceRedirection = document.getElementById("force-redirection");
    if (onSuccess) {
      onSuccess(res);
    } else if (formId) {
      document.getElementById(formId).submit();
    } else if (forceRedirectionPath) {
      window.location = formatRedirectionUrl(forceRedirectionPath, res);
    } else if (forceRedirection) {
      window.location = formatRedirectionUrl(
        forceRedirection.getAttribute("href"),
        res
      );
    } else if (redirectionPath) {
      window.location = formatRedirectionUrl(redirectionPath, res);
    } else {
      window.location = formatRedirectionUrl(res.redirectUrl, res);
    }
  };

  const handleRes = (res) =>
    res.then(({ data }) => {
      handleSuccess(data);
    });

  return (
    <Wrapper isOpen={modalIsOpen} onClose={handleCloseModal} modal={modal}>
      <MemoryRouter
        initialEntries={[
          "/",
          "/create_user",
          "/confirm_user",
          "/login",
          "/signup",
        ]}
      >
        <Switch>
          <Redirect from="/" exact to={`/${type || "login"}`} />
          <Route path="/create_user" exact>
            <CreateUser
              {...props}
              params={params}
              alertMessage={alertMessage}
              type={type}
              onClose={handleCloseModal}
              onSuccess={handleSuccess}
              handleRes={handleRes}
            />
          </Route>
          <Route path="/confirm_user">
            <ConfirmUser
              params={params}
              alertMessage={alertMessage}
              {...props}
              onClose={handleCloseModal}
              onSuccess={handleSuccess}
            />
          </Route>
          <Route path="/login">
            <Login
              {...props}
              params={params}
              alertMessage={alertMessage}
              onSuccess={handleSuccess}
              onClose={handleCloseModal}
            />
            <RouteFooter
              hasSwitch={hasSwitch}
              isJobber={isJobber}
              forceNavigation={forceNavigation}
            />
          </Route>
          <Route path="/signup">
            <SignUp
              params={params}
              alertMessage={alertMessage}
              onClose={handleCloseModal}
              handleFacebookSuccess={handleSuccess}
              {...props}
              handleRes={handleRes}
            />
            <RouteFooter
              hasSwitch={hasSwitch}
              isJobber={isJobber}
              forceNavigation={forceNavigation}
            />
          </Route>
        </Switch>
      </MemoryRouter>
    </Wrapper>
  );
};

export default LoginOrRegisterDispatch;
