import React from "react";
import { colors, radius, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Icon,
  Link,
  List,
} from "../../../components";
import { LIST, SHAPE } from "../../../components/Styles/variants";
import { formatFileSize } from "../../../utils";

const TicketMessage = ({
  id,
  external_id,
  body,
  html_body,
  created_at,
  first_name,
  avatar,
  attachments,
  isLast,
}) => (
  <div>
    <List.Item
      withGutters
      divider={!isLast}
      css={`
        padding-top: ${spacings.ml};
        padding-bottom: ${spacings.ml};
        .signature {
          color: ${colors.muted};
          font-size: var(--font-size-body14);
          margin-top: ${spacings.m};
          p {
            margin: 0;
          }
        }
      `}
      size={LIST.SIZE.LARGE}
      LeftComponent={() => (
        <Block height="100%" marginRight={spacings.s}>
          <Avatar src={avatar} size={sizes.size48} name={first_name} />
        </Block>
      )}
    >
      <Block display="flex" alignItems="baseline" width="100%">
        <Body16 strong>{first_name}</Body16>
        <Body14
          color={colors.muted}
          css={`
            margin-left: ${spacings.s};
          `}
        >
          {created_at}
        </Body14>
      </Block>
      <Body16
        dangerouslySetInnerHTML={{ __html: html_body }}
        css={`
          word-break: break-word;
          white-space: break-spaces;
          img {
            max-width: 100%;
            height: auto;
          }
          p:last-of-type {
            margin-bottom: 0;
          }
        `}
      />
      {attachments?.length > 0 &&
        attachments.map(
          ({
            id,
            external_id,
            file_name,
            file_url,
            thumbnail_url,
            size,
            content_type,
          }) => (
            <Block
              marginTop={spacings.xs}
              marginLeft={`calc(${spacings.m} * -1)`}
              width="100%"
              display="block"
              key={`attachments-chat-${id}-${external_id}`}
            >
              <List.Item
                color={colors.body}
                target="_blank"
                download
                withGutters
                divider={false}
                shape={LIST.SHAPE.ROUND}
                size={LIST.SIZE.COMPACT}
                href={file_url}
                LeftComponent={() => {
                  const isFile = content_type === "application/pdf";
                  return (
                    <Block
                      borderRadius={radius.s}
                      width={sizes.size36}
                      height={sizes.size36}
                      backgroundImage={!isFile && `url(${thumbnail_url})`}
                      backgroundSize="cover"
                      backgroundPosition="center"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      shape={SHAPE.SHAPE.ROUND}
                      backgroundColor={
                        isFile ? colors.orangered500 : colors.transparent
                      }
                    >
                      {isFile && (
                        <Icon.Large name="file" color={colors.onColor} />
                      )}
                    </Block>
                  );
                }}
              >
                <List.Elem.Title strong numberOfLines={1}>
                  {file_name}
                </List.Elem.Title>
                <List.Elem.Subtitle>{formatFileSize(size)}</List.Elem.Subtitle>
              </List.Item>
            </Block>
          )
        )}
    </List.Item>
  </div>
);

const areEqual = (p, n) =>
  p.external_id === n.external_id && p.id === n.id && p.isLast === n.isLast;
export default React.memo(TicketMessage, areEqual);
