import { useSelector } from "react-redux";
import { getHoursOptions } from "../../utils/job-date-time";

export default function useJobAvailableHours(options = {}) {
  const { current_admin } = useSelector((state) => state.rails);

  return {
    getOptions: ({ date, min, max, ignoreCurrentHour, availabilities }) =>
      getHoursOptions(date, options.format, current_admin, {
        availabilities,
        min,
        max,
        ignoreCurrentHour,
      }),
  };
}
