import { colors } from "../../assets/themes";

export const getColor = (rate) => {
  if (rate >= 4.0) return colors.warning;
  if (rate >= 3) return colors.orange;
  return colors.danger;
};

export const getRate = (rate) => {
  if (typeof rate === "number") {
    return rate
      .toFixed(Number.isInteger(rate) ? 1 : 2)
      .toString()
      .replace(".", ",")
      .padEnd(3, "0");
  }
  return rate;
};
