import { FastField, useFormikContext } from "formik";
import React from "react";
import { colors } from "../assets/themes";
import SelectZipCode from "../pages/common/addressModal/SelectZipCode";
import FormControl from "./FormControl";
import Icon from "./Icon";

const PostalCodeInput = ({ name, placeholder }) => {
  const { setFieldValue, setFieldTouched, validateForm } = useFormikContext();
  const handleSelect = (option) => {
    setFieldValue(name, option?.value?.toString() || "", false);
    setTimeout(() => validateForm(), 0);
  };

  return (
    <FormControl>
      <FastField name={name}>
        {({ field }) => (
          <SelectZipCode
            onChange={handleSelect}
            name={field.name}
            // value={field.value}
            onFocus={() => setFieldTouched(name, true)}
            autoComplete="no-autocomplete"
            placeholder={placeholder}
            LeftComponent={() => (
              <Icon.Large name="map-marker" color={colors.mutedLight} />
            )}
          />
        )}
      </FastField>
    </FormControl>
  );
};

export default PostalCodeInput;
