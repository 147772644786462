import React, { useEffect, useState } from "react";
import Select from "../../../components/Select";
import { useDebounce } from "../../../modules/hooks";
import {
  useGetZipCode,
  useGetZipCodeDetails,
} from "../../../modules/routes/location-routes";
import polyglot from "../../../utils/polyglot";

const getOptions = (data) => {
  if (data) {
    return data.map(({ city, place_id, location }) => ({
      label: city,
      value: place_id,
      location,
    }));
  }
  return null;
};

const SelectZipCode = ({
  name,
  onChange,
  error,
  disabled,
  value: _value = {},
  placeholder = polyglot.t("common.placeholder.zip_code"),
  ...rest
}) => {
  const [value, setValue] = useState(_value);
  const [term, setTerm] = useState("");
  const debouncedTerm = useDebounce(term);
  const zipCodeDetails = useGetZipCodeDetails();
  const { data, isFetching } = useGetZipCode(
    { term: debouncedTerm },
    {
      enabled: debouncedTerm === term && !!debouncedTerm,
    }
  );

  const handleChange = (option) => {
    setValue(option);
    if (option?.value) zipCodeDetails.mutate({ place_id: option.value });
  };

  useEffect(() => {
    if (onChange && zipCodeDetails.isSuccess) {
      onChange({
        label: zipCodeDetails?.data?.data?.data?.city,
        value: zipCodeDetails?.data?.data?.data?.city_id,
        location: zipCodeDetails?.data?.data?.data?.location,
      });
    }
  }, [zipCodeDetails.isSuccess]);

  const isLoading = isFetching || debouncedTerm !== term;

  return (
    <Select
      name={name}
      disabled={disabled}
      error={error}
      options={getOptions(data?.cities)}
      isLoading={isLoading}
      onChange={handleChange}
      clearable
      value={value}
      placeholder={placeholder}
      onInputChange={(e) => setTerm(e.target.value)}
      {...rest}
    />
  );
};

export default SelectZipCode;
