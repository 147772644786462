import styled from "styled-components";
import { Body14, Body16, List, Radio } from "../../components";
import { borderWidth, colors } from "../../assets/themes";
import { LIST } from "../../components/Styles/variants";

const StyledList = styled(List.Item)`
  background-color: ${colors.backgroundLight};
  border: solid ${borderWidth.m}
    ${({ checked }) => (checked ? colors.primary : colors.backgroundLight)};
`;

const ExtraRatingInput = ({
  title,
  subtitle,
  checked,
  onChange,
  name,
  strong = true,
  InputComponent = Radio,
}) => (
  <StyledList
    shape={LIST.SHAPE.ROUND}
    divider={false}
    withGutters
    checked={checked}
    onClick={onChange}
    RightComponent={() => <InputComponent checked={checked} name={name} />}
  >
    <Body16 strong={strong}>{title}</Body16>
    {subtitle && <Body14 color={colors.muted}>{subtitle}</Body14>}
  </StyledList>
);
export default ExtraRatingInput;
