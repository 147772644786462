export default function cesuGetAmount(code, formated = false) {
  const unitIndex = 12;
  const centsIndex = 15;
  if (code) {
    const codeUnit = Number(code.substring(unitIndex, unitIndex + 3));
    const codeCents = code.substring(centsIndex, centsIndex + 2);
    if (formated) {
      return `${codeUnit},${codeCents}€`;
    }
    return codeUnit + Number(codeCents) / 100;
  }
  return false;
}
