import React, { Suspense, useState } from "react";
import { useLogAsUser } from "../routes/user-preview-routes";

const AdminNotes = React.lazy(() => import("../../pages-admin/AdminNotes"));
const AdminPreviewContext = React.createContext(null);

const AdminPreviewProvider = ({ children }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [adminNotesModalOptions, setAdminNotesModalOptions] = useState({});
  const [popoverRef, setPopoverRef] = useState(null);
  const logAsUser = useLogAsUser();

  const closeAll = () => {
    popoverRef.current.close();
  };

  const showAdminNotes = (options = {}) => {
    closeAll();
    setTimeout(() => {
      setAdminNotesModalOptions(options);
      setModalIsOpen(true);
    }, 200);
  };

  const hideAdminNotes = () => {
    setModalIsOpen(false);
  };

  const init = (ref) => {
    if (!popoverRef) {
      setPopoverRef(ref);
    }
  };

  return (
    <AdminPreviewContext.Provider
      value={{
        notes: { show: showAdminNotes, hide: hideAdminNotes },
        hideAdminNotes,
        popovers: { init },
        logAsUser: logAsUser.mutate,
      }}
    >
      {children}
      <Suspense fallback={<div />}>
        {modalIsOpen && (
          <AdminNotes
            isOpen={modalIsOpen}
            onClose={hideAdminNotes}
            id={adminNotesModalOptions.id}
            user={adminNotesModalOptions.user}
            job={adminNotesModalOptions.job}
          />
        )}
      </Suspense>
    </AdminPreviewContext.Provider>
  );
};

export { AdminPreviewContext };
export default AdminPreviewProvider;
