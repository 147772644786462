import React, { useContext } from "react";
import { borderWidth, colors, radius, spacings } from "../../assets/themes";
import { Button, Col, Row } from "../../components";
import Block from "../../components/Block";
import { BUTTON } from "../../components/Styles/variants";
import { Body16, Body18 } from "../../components/Text";
import { ChatContext } from "../../modules/contexts";
import { getCancelReviewUrl } from "../../modules/routes/dashboard-routes";
import polyglot from "@/utils/polyglot";

const getCancelUrl = ({ id }) => {
  if (id) return getCancelReviewUrl({ id });
  return null;
};

const ActionJobberIsUnavailable = ({ user, offerId }) => {
  const { openDateTimeChange, canEditDateTime } = useContext(ChatContext);
  return (
    <Block
      borderRadius={radius.ml}
      padding={spacings.m}
      marginTop={spacings.m}
      border={`solid ${borderWidth.m} ${colors.border}`}
    >
      <Body18
        strong
        css={`
          margin-bottom: ${spacings.xs};
        `}
      >
        {polyglot.t("chat.first_name_is_not_available", {
          first_name: user?.first_name,
        })}
      </Body18>
      <Body16
        css={`
          margin-bottom: ${spacings.sm};
        `}
      >
        {polyglot.t("chat.what_do_you_do")}
      </Body16>
      <Row gutter={[null, spacings.s]}>
        <Col size={12}>
          <Button.Medium
            onClick={openDateTimeChange}
            block
            disabled={!canEditDateTime}
          >
            {polyglot.t("chat.edit_datetime")}
          </Button.Medium>
        </Col>
        {getCancelUrl({ id: offerId }) && (
          <Col size={12}>
            <Button.Medium
              numberOfLines={1}
              block
              kind={BUTTON.KIND.SECONDARY}
              accentColor={BUTTON.ACCENT_COLOR.DANGER}
              href={getCancelUrl({ id: offerId })}
            >
              {polyglot.t("jobs.change_or_cancel_first_name", {
                first_name: user?.first_name,
              })}
            </Button.Medium>
          </Col>
        )}
      </Row>
    </Block>
  );
};

export default ActionJobberIsUnavailable;
