import { useFormikContext } from "formik";
// eslint-disable-next-line import/no-unresolved
import { useEffect, useState } from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, Button, Divider, H5, Icon, Info, List } from "../../components";
import { BUTTON, LIST } from "../../components/Styles/variants";
import {
  useAddVoucher,
  useRemoveVoucher,
} from "../../modules/routes/jobber-booking-routes";
import polyglot from "../../utils/polyglot";
import VoucherCodeModal from "../common/VoucherCodeModal";
import SummaryItem from "./SummaryItem";
import { useGetPricing } from "./useGetPricing";

const Summary = ({ pricing: _pricing, hideVoucher }) => {
  const pricing = useGetPricing(_pricing);
  const [voucherModalIsOpen, setVoucherModalIsOpen] = useState(false);
  const addVoucher = useAddVoucher();
  const removeVoucher = useRemoveVoucher();
  const { values, validateForm } = useFormikContext();

  const handleRemoveVoucher = () => {
    removeVoucher.mutate();
  };

  const handleVoucherChange = ({ code }) => {
    addVoucher.mutate({ code });
  };

  useEffect(() => {
    if (addVoucher.isSuccess) {
      setVoucherModalIsOpen(false);
    }
  }, [addVoucher.isSuccess]);

  useEffect(() => {
    // force revalidate form -> Fix new voucher code form isValid was wrongly false
    setTimeout(() => {
      // Prevent button not valid when add/remove voucher
      validateForm(values);
    }, 0);
  }, [addVoucher.isSuccess, removeVoucher.isSuccess]);

  return (
    <Block marginBottom={{ md: spacings.sm }}>
      <Block paddingTop={{ md: spacings.m }}>
        {pricing.price > 0 && (
          <SummaryItem
            title={polyglot.t("transaction_details.jobber_price")}
            value={polyglot.toSmartCurrency(pricing.price)}
          />
        )}
        {pricing.fees > 0 && (
          <SummaryItem
            title={polyglot.t("common.fees")}
            value={polyglot.toSmartCurrency(pricing.fees)}
          >
            <Info>{polyglot.t("common.fees_description")}</Info>
          </SummaryItem>
        )}
        {pricing.taxes > 0 && (
          <SummaryItem
            title={polyglot.t("common.taxes")}
            value={polyglot.toSmartCurrency(pricing.taxes)}
          >
            <Info>{polyglot.t("common.taxes_description")}</Info>
          </SummaryItem>
        )}

        {pricing.wallet + (pricing.fees_discount || 0) > 0 && (
          <SummaryItem
            title={polyglot.t("wallet.pot")}
            value={polyglot.toSmartCurrency(
              pricing.wallet + (pricing.fees_discount || 0)
            )}
            positive
          >
            <Info>{polyglot.t("common.pot_description")}</Info>
          </SummaryItem>
        )}
        {pricing.cesu > 0 && (
          <SummaryItem
            title={polyglot.t("wallet.cesu")}
            value={polyglot.toSmartCurrency(pricing.cesu)}
            positive
          />
        )}
        {values.voucher_code && (
          <SummaryItem
            title={
              values.voucher_code === "YOOJO5%"
                ? polyglot.t("common.vip_status_voucher")
                : values.voucher_code
            }
            value={polyglot.toSmartCurrency(pricing.voucher)}
            positive
          >
            <Button.Small
              isLoading={removeVoucher.isLoading}
              css={`
                margin-top: calc(${spacings.m} * -1);
                margin-bottom: calc(${spacings.m} * -1);
              `}
              kind={BUTTON.KIND.MINIMAL}
              shape={BUTTON.SHAPE.CIRCLE}
              onClick={handleRemoveVoucher}
            >
              <Icon.Medium name="trash" color={colors.muted} />
            </Button.Small>
          </SummaryItem>
        )}
      </Block>

      <Block marginY={spacings.s}>
        <Divider.Cell />
      </Block>
      <List.Item
        divider={false}
        size={LIST.SIZE.COMPACT}
        RightComponent={() => (
          <H5 strong>{polyglot.toSmartCurrency(pricing.total)}</H5>
        )}
      >
        <H5 strong>{polyglot.t("common.total")}</H5>
      </List.Item>
      {!hideVoucher && !values.voucher_code && (
        <Block paddingY={spacings.s}>
          <Button.Link
            onClick={() => setVoucherModalIsOpen(true)}
            css={`padding 0; min-height: inherit; color: ${colors.muted}!important`}
            LeftComponent={() => <Icon.Medium name="tag-solid" />}
          >
            {polyglot.t("common.add_voucher_code")}
          </Button.Link>
        </Block>
      )}
      <VoucherCodeModal
        isOpen={voucherModalIsOpen}
        onClose={() => setVoucherModalIsOpen(false)}
        onChange={handleVoucherChange}
        isLoading={addVoucher.isLoading}
      />
    </Block>
  );
};

export default Summary;
