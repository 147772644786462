import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { colors, spacings } from "../../assets/themes";
import {
  Block,
  Body16,
  Icon,
  Input,
  List,
  Modal,
  Spinner,
} from "../../components";
import { useDebounce } from "../../modules/hooks";
import {
  useGetAddressDetails,
  useGetAddresses,
} from "../../modules/routes/location-routes";
import { uuid } from "../../utils";
import polyglot from "../../utils/polyglot";
import ManualAddress from "./addressModal/ManualAddress";

const Wrapper = ({ children, isOpen, handleClose, inContent }) =>
  inContent ? (
    <div>{children}</div>
  ) : (
    <Modal.Small isOpen={isOpen} onClose={handleClose} fullScreenOnMobile>
      {children}
    </Modal.Small>
  );

const AddressModal = ({
  onClose,
  isDestination,
  addresses,
  inContent,
  isOpen = inContent,
  onChange,
  onBack,
}) => {
  const { values, setFieldTouched, setValues, validateForm } =
    useFormikContext();
  const [term, setTerm] = useState("");
  const [status, setStatus] = useState("");
  const [showManualAddress, setShowManualAddress] = useState(false);
  const [sessionToken, setSessionToken] = useState(uuid());
  const prefix = isDestination ? "destination_" : "";
  const debouncedTerm = useDebounce(term);

  const { isFetching, data, isSuccess, remove, isError } = useGetAddresses(
    { term, session_token: sessionToken },
    {
      queryKey: [`${prefix}address-search`],
      enabled: debouncedTerm === term && isOpen && !!debouncedTerm,
    }
  );
  const isLoading = isFetching || debouncedTerm !== term;

  useEffect(
    () => () => {
      remove();
    },
    []
  );

  const handleSelection = ({
    place_id,
    address_id,
    city_id,
    address_name,
    label,
    location,
    lift,
    floor,
    property_type,
  }) => {
    let newVal = {};
    if (place_id) {
      newVal = {
        [`${prefix}place_id`]: place_id,
        [`${prefix}address_id`]: null,
        [`${prefix}address_name`]: null,
        [`${prefix}city_id`]: null,
      };
    } else if (address_id) {
      newVal = {
        [`${prefix}place_id`]: null,
        [`${prefix}address_id`]: address_id,
        [`${prefix}address_name`]: null,
        [`${prefix}city_id`]: null,
      };
    } else if (city_id && address_name && label) {
      newVal = {
        [`${prefix}place_id`]: null,
        [`${prefix}address_id`]: null,
        [`${prefix}address_name`]: address_name,
        [`${prefix}city_id`]: city_id,
      };
    }
    newVal = {
      ...newVal,
      [`${prefix}address`]: label,
      [`${prefix}address_location`]: location,
      [`${prefix}lift`]: lift || null,
      [`${prefix}floor`]: floor || null,
      [`${prefix}property_type`]: property_type || null,
    };
    setValues({ ...values, ...newVal });
    if (onChange) onChange(newVal);
    setFieldTouched(`${prefix}address`, true, false);
    setTimeout(() => validateForm(), 0);
  };

  const handleClose = () => {
    setShowManualAddress(false);
    setStatus("");
    setTerm("");
    if (onBack) onBack();
    else if (onClose) onClose();
  };

  const checkAddressSuccess = ({ place_id, label, location }) => {
    handleSelection({ place_id, label, location });
    handleClose();
  };

  const checkAddressError = () => {
    setShowManualAddress(true);
    setStatus(polyglot.t("common.address_incomplete"));
    setTerm("");
  };

  const getAddressDetails = useGetAddressDetails({
    onSuccess: checkAddressSuccess,
    onError: checkAddressError,
  });

  const handleSelect = ({ place_id, label }) => {
    getAddressDetails.mutate({
      place_id,
      label,
      session_token: sessionToken,
    });
    setSessionToken(uuid());
  };

  const handleManualSubmit = (values) => {
    handleSelection({
      label: `${values.address_name}, ${values.zip_name}`,
      city_id: values.city_id,
      address_name: values.address_name,
      location: values.location,
    });
    handleClose();
  };

  const handleChange = (value = "") => {
    setTerm(value);
  };

  const listLeftComponent = () => (
    <Icon.Large name="map-marker-solid" color={colors.primary} />
  );

  return (
    <Wrapper isOpen={isOpen} handleClose={handleClose} inContent={inContent}>
      {!showManualAddress && (
        <>
          <Modal.Item.Header
            onClose={handleClose}
            onBack={inContent && onBack}
            title={polyglot.t("common.enter_address")}
          />
          <Modal.Item.Wrapper className="mt-2" style={{ flex: "inherit" }}>
            <Input
              type="search"
              autoFocus
              placeholder={polyglot.t("common.placeholder.search_address")}
              onChange={(e) => handleChange(e.target.value)}
              value={term}
              RightComponent={
                isLoading && term
                  ? () => <Spinner.Small color={colors.muted} />
                  : null
              }
            />
          </Modal.Item.Wrapper>
          {data && isSuccess && (
            <Block marginTop={spacings.m} data-test="addresses-result">
              {data.map(({ id, description }) => (
                <List.Item
                  withGutters
                  key={id}
                  chevron
                  isLoading={
                    getAddressDetails.isLoading &&
                    getAddressDetails.variables.place_id === id
                  }
                  LeftComponent={() => listLeftComponent()}
                  onClick={() => {
                    handleSelect({ place_id: id, label: description });
                  }}
                >
                  <List.Elem.Title>{description}</List.Elem.Title>
                </List.Item>
              ))}
              <List.Item
                withGutters
                LeftComponent={() => (
                  <Icon.Large name="plus-circle" color={colors.primary} />
                )}
                onClick={() => {
                  setShowManualAddress(true);
                }}
              >
                <List.Elem.Title color={colors.primary} strong>
                  {polyglot.t("common.add_manual_address")}
                </List.Elem.Title>
              </List.Item>
            </Block>
          )}
          {isError && (
            <div className="d-flex flex-column justify-content-center align-items-center mt-4">
              <Body16 color={colors.muted}>
                {polyglot.t("common.no_result")}
              </Body16>
              <button
                type="button"
                className="btn btn-link"
                onClick={() => {
                  setShowManualAddress(true);
                }}
              >
                {polyglot.t("common.manual_address")}
              </button>
            </div>
          )}
          {!data && addresses?.length > 0 && (
            <>
              <List.Header
                withGutters
                title={polyglot.t("common.last_address")}
              />
              {addresses.map(
                (
                  {
                    id,
                    address,
                    location,
                    place_id,
                    city_id,
                    address_name,
                    lift,
                    floor,
                    property_type,
                  },
                  i
                ) => (
                  <List.Item
                    withGutters
                    key={`known-addresses-selection-item-${i}`}
                    chevron
                    isLoading={
                      getAddressDetails.isLoading &&
                      getAddressDetails.variables.place_id === id
                    }
                    LeftComponent={() => listLeftComponent()}
                    onClick={() => {
                      handleSelection({
                        place_id,
                        address_id: id,
                        label: address,
                        lift,
                        floor,
                        property_type,
                        location,
                        address_name,
                        city_id,
                      });
                      handleClose();
                    }}
                  >
                    <List.Elem.Title>{address}</List.Elem.Title>
                  </List.Item>
                )
              )}
            </>
          )}
        </>
      )}
      {showManualAddress && (
        <>
          <ManualAddress
            onSubmit={handleManualSubmit}
            onBack={() => {
              setShowManualAddress(false);
              setStatus("");
            }}
            status={status}
          />
        </>
      )}
    </Wrapper>
  );
};

const areEqual = (p, n) => p.isOpen === n.isOpen;
export default React.memo(AddressModal, areEqual);
