import { useState } from "react";

function useLocalStorage(key, initialValue) {
  const storedValue = localStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  const [value, setValue] = useState(initial);

  const set = (newValue) => {
    setValue(newValue);
    localStorage.setItem(key, JSON.stringify(newValue));
    window.dispatchEvent(
      new CustomEvent("storage", { detail: { [key]: newValue } })
    );
  };

  const remove = () => {
    setValue(undefined);
    localStorage.removeItem(key);
    window.dispatchEvent(
      new CustomEvent("storage", { detail: { [key]: undefined } })
    );
  };

  return [value, set, remove];
}

export default useLocalStorage;
