function sendEvent(payload = {}) {
  const CustomEventName = payload?.action;
  const customPayload = { ...payload };
  delete customPayload.action;

  window.dataLayer?.push({
    event: "CustomEvent",
    CustomEventName,
    ...customPayload,
  });
}

export default {
  sendEvent,
};
