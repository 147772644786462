import { Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { sizes } from "../../assets/themes";
import {
  ArtworkLabel,
  Avatar,
  Button,
  Divider,
  List,
  Spinner,
  Toast,
} from "../../components";
import {
  putPaymentMethod,
  putUpdateCreditCard,
  putUpdateIban,
} from "../../modules/routes";
// import { putPaymentMethod, putUpdateCreditCard, putUpdateIban } from '../../modules/routes';
import {
  DIRECT_PLANS,
  formatPhone,
  getMonthlyOptions,
  getWeeklyOptions,
  setPaymentInitialValues,
} from "../../utils";
import polyglot from "../../utils/polyglot";
import PaymentMethodsUpdated from "../common/payment-methods";
import { ACCOUNT_CESU_PATH } from "../dashboard/config/routes";
import EditNbHoursModal from "./EditNbHoursModal";
import EditPaymentDayModal from "./EditPaymentDayModal";
import EditPricePerHourModal from "./EditPricePerHourModal";
import PaymentDetailsModal from "./show/PaymentDetailsModal";

const StickyBox = ({
  data,
  updateData,
  isLoading,
  setPaymentMethodSuccess,
}) => {
  const [editNbHoursIsOpen, setEditNbHoursIsOpen] = useState(false);
  const [editPricePerHoursIsOpen, setEditPricePerHoursIsOpen] = useState(false);
  const [paymentDetailsIsOpen, setPaymentDetailsIsOpen] = useState(false);
  const [editPaymentDayIsOpen, setEditPaymentDayIsOpen] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState(null);
  const history = useHistory();

  const handlePaymentSelection = ({ payment_method, iban, token }) => {
    setPaymentMethodSuccess(false);
    setPaymentMethodError(null);
    if (iban) {
      const res = putUpdateIban({ iban, id: data.id });
      res
        .then(({ data }) => {
          if (data.secure_url) {
            window.location = data.secure_url;
          } else {
            setPaymentMethodSuccess(true);
            updateData();
          }
        })
        .catch((err) => {
          setPaymentMethodError(
            err?.response?.data?.error_message ||
              "La modification du moyen de paiement a échoué, veuillez vérifier les informations transmises"
          );
          console.error(err);
        });
    } else if (token) {
      const res = putUpdateCreditCard({ token, id: data.id });
      res
        .then(({ data }) => {
          if (data.secure_url) {
            window.location = data.secure_url;
          } else {
            setPaymentMethodSuccess(true);
            updateData();
          }
        })
        .catch((err) => {
          setPaymentMethodError(
            err?.response?.data?.error_message ||
              "La modification du moyen de paiement a échoué, veuillez vérifier les informations transmises"
          );
          console.error(err);
        });
    } else if (payment_method) {
      const res = putPaymentMethod({ payment_method, id: data.id });
      res
        .then(({ data }) => {
          setPaymentMethodSuccess(true);
          updateData();
        })
        .catch((err) => {
          setPaymentMethodError(
            err?.response?.data?.error_message ||
              "La modification du moyen de paiement a échoué, veuillez vérifier les informations transmises"
          );
          console.error(err);
        });
    }
  };

  const getPaymentDay = () => {
    const options =
      data.frequency === "monthly" ? getMonthlyOptions() : getWeeklyOptions();
    return options.find(
      (o) =>
        o.value ===
        (data.frequency === "monthly"
          ? data.monthly_payment_day
          : data.weekly_payment_day)
    ).label;
  };

  return (
    <div className="sticky-with-fixed-header">
      <div className="rounded border w-100 bg-white">
        {isLoading && (
          <div
            className="position-absolute top-0 left-0 right-0 bottom-0 d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "rgba(255,255,255,0.8", zIndex: 9999 }}
          >
            <Spinner.Small />
          </div>
        )}
        <List.Item
          withGutters
          divider={false}
          style={{ minHeight: "inherit" }}
          LeftComponent={() => (
            <div className="d-flex align-items-center">
              <img
                src={DIRECT_PLANS[data.plan].artwork}
                width={24}
                height={24}
                alt="icon"
                className="mr-2"
              />
              <p className="font-size-3 h2 mb-0">
                {DIRECT_PLANS[data.plan].title}
              </p>
            </div>
          )}
          RightComponent={() => (
            <List.Elem.Label>
              <span className="font-size-2">
                {polyglot.toSmartCurrency(DIRECT_PLANS[data.plan].price)} / mois
              </span>
            </List.Elem.Label>
          )}
        />
        <Divider.Cell />
        <div className="d-flex flex-column align-items-center px-3">
          <div className="my-3 d-flex flex-column align-items-center">
            <Avatar
              size={sizes.size96}
              src={data.jobber.avatar}
              first_name={data.jobber.first_name}
            />
            <p className="font-weight-medium mb-0 mt-2">
              {data.jobber.first_name}
            </p>
            {data.jobber?.phone && (
              <ArtworkLabel.Medium
                className="text-muted"
                title={formatPhone(data.jobber.phone)}
                LeftComponent={() => <i className="icon-phone" />}
              />
            )}
          </div>
          {data.discussion_id && (
            <Button.Medium
              kind="tertiary"
              className="mb-3"
              block
              onClick={() => {
                window.location.href = `/inboxes/${data.discussion_id}`;
              }}
            >
              <i className="icon-comment" />
              <span className="ml-2">Messagerie</span>
            </Button.Medium>
          )}
        </div>
        <Divider.Cell />
        {data.cesu && (
          <List.Item
            withGutters
            href={ACCOUNT_CESU_PATH}
            target="_blank"
            chevron
            LeftComponent={() => <i className="icon-wallet text-primary" />}
            RightComponent={() => (
              <List.Elem.Label className="text-nowrap">
                {polyglot.toSmartCurrency(data.cesu_wallet || 0)}
              </List.Elem.Label>
            )}
          >
            <span className="text-primary font-weight-medium">Solde CESU</span>
          </List.Item>
        )}
        <List.Header
          withGutters
          title="Conditions"
          RightComponent={() => (
            <Button.Link
              className="pb-0"
              onClick={() => setPaymentDetailsIsOpen(true)}
            >
              Détails
            </Button.Link>
          )}
        />

        <List.Item
          withGutters
          onClick={
            data.state === "inactive"
              ? null
              : () => setEditPricePerHoursIsOpen(true)
          }
          title="Taux horaire"
          chevron
          RightComponent={() => (
            <List.Elem.Label>
              {data.price_per_hour}
              {polyglot.currency} / heure
            </List.Elem.Label>
          )}
        />
        <List.Item
          withGutters
          onClick={
            data.state === "inactive" ? null : () => setEditNbHoursIsOpen(true)
          }
          title={
            data.frequency === "monthly"
              ? `Heures par mois`
              : `Heures par semaine`
          }
          chevron
          RightComponent={() => (
            <List.Elem.Label>
              {polyglot.t("common.hours_plural", {
                count: data.nb_hours,
              })}
            </List.Elem.Label>
          )}
        />
        <List.Item
          withGutters
          onClick={
            data.state === "inactive"
              ? null
              : () => setEditPaymentDayIsOpen(true)
          }
          title="Jour de paiement"
          chevron
          RightComponent={() => (
            <List.Elem.Label>{getPaymentDay()}</List.Elem.Label>
          )}
        />
        <List.Item
          withGutters
          title="Déclaratif automatique"
          RightComponent={() => (
            <List.Elem.Label>{data.urssaf ? "Oui" : "Non"}</List.Elem.Label>
          )}
        />
        <List.Item
          chevron
          onClick={() => history.push(`/documents`)}
          withGutters
        >
          Documents et attestations
        </List.Item>
        {data.state !== "inactive" && (
          <>
            <Formik
              initialValues={{ ...setPaymentInitialValues(data) }}
              onSubmit={handlePaymentSelection}
            >
              {({ submitForm }) => (
                <PaymentMethodsUpdated.A
                  needCCCheck
                  withGutters
                  onChange={(values) => values.token && submitForm()}
                  methods={data.payment_methods}
                />
              )}
            </Formik>
            {!!paymentMethodError && (
              <Toast
                title={polyglot.t("common.problem_has_occurred")}
                body={paymentMethodError}
                icon="exclamation-circle"
                type="danger"
                isVisible={!!paymentMethodError}
              />
            )}
          </>
        )}
        {data.state !== "inactive" && (
          <>
            <Divider.Cell />
            <List.Item
              divider={false}
              onClick={() => history.push(`/cancel_subscription`)}
              withGutters
            >
              <span className="text-muted">Annuler mon abonnement</span>
            </List.Item>
          </>
        )}
        <EditPricePerHourModal
          price_per_hour={data.price_per_hour}
          price_per_hour_min={data.price_per_hour_min}
          price_per_hour_max={data.price_per_hour_max}
          onClose={() => setEditPricePerHoursIsOpen(false)}
          isOpen={editPricePerHoursIsOpen}
          updateData={updateData}
          id={data.id}
        />
        <EditNbHoursModal
          nb_hours={data.nb_hours}
          weekly_nb_hours_min={data.weekly_nb_hours_min}
          weekly_nb_hours_max={data.weekly_nb_hours_max}
          monthly_nb_hours_min={data.monthly_nb_hours_min}
          monthly_nb_hours_max={data.monthly_nb_hours_max}
          frequency={data.frequency}
          onClose={() => setEditNbHoursIsOpen(false)}
          isOpen={editNbHoursIsOpen}
          updateData={updateData}
          id={data.id}
        />
        <EditPaymentDayModal
          frequency={data.frequency}
          monthly_payment_day={data.monthly_payment_day}
          weekly_payment_day={data.weekly_payment_day}
          updateData={updateData}
          id={data.id}
          isOpen={editPaymentDayIsOpen}
          onClose={() => setEditPaymentDayIsOpen(false)}
        />
        <PaymentDetailsModal
          isOpen={paymentDetailsIsOpen}
          onClose={() => setPaymentDetailsIsOpen(false)}
          data={data}
        />
      </div>
    </div>
  );
};

export default StickyBox;
