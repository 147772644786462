import { NB_HOURS_PATH } from "../constants";
import polyglot from "@/utils/polyglot";

export function useCategory4025() {
  const initialValues = { nb_hours: 2 };

  return {
    initialValues,
    config: {
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
        newPages[nbHoursIndex].title = polyglot.t(
          "categories.how_many_cleaning_nb_hours"
        );
        return newPages;
      },
    },
  };
}
