import React, { useEffect } from "react";
import { NavLink, Route, Switch, useHistory } from "react-router-dom";
import { borderWidth, colors, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Badge,
  Block,
  Body14,
  Body16,
  Body18,
  Caption,
  Container,
  H4,
  Icon,
  List,
  Modal,
} from "../../components";
import { ActiveWrapperBase } from "../../components/Styles/Base";
import { LIST } from "../../components/Styles/variants";
import { useDocumentTitle, useWindowSize } from "../../modules/hooks";
import { BREAKPOINTS } from "../../utils";
import polyglot from "../../utils/polyglot";
import TicketNew from "./tickets/TicketNew";

// ---------- FULL ---------- //

const Full = ({ children, title }) => {
  const documentTitle = useDocumentTitle();
  useEffect(() => {
    documentTitle.set(title);
  }, []);
  return children;
};

// ---------- MESSAGES LAYOUT ---------- //

const ConversationWrapper = ({ mobileLayout, children }) => {
  const history = useHistory();
  const onClose = () => {
    history.goBack();
  };
  return mobileLayout ? (
    <Modal.FullScreen
      isOpen
      hideBackdrop
      onClose={onClose}
      css={`
        height: 100%;
        > div {
          height: 100%;
          padding-bottom: 0;
          > div {
            padding-bottom: 0; //Remove bottom padding from StyledModal
          }
          > ${Block}:last-of-type {
            overflow: hidden;
          }
        }
      `}
    >
      {children}
    </Modal.FullScreen>
  ) : (
    children
  );
};

const ConversationRouter = ({ path, children, newTicketRoute }) => {
  const { width } = useWindowSize();
  const mobileLayout = width < BREAKPOINTS.md;
  return (
    <Block width={!mobileLayout && "100%"}>
      <Switch>
        {!mobileLayout && (
          <Route exact path={path}>
            <Block
              display="flex"
              flexDirection="column"
              alignItems="center"
              height="100%"
              marginX={spacings.xl}
              justifyContent="center"
            >
              <Icon.XLarge
                name="chat-solid"
                size={sizes.size96}
                color={colors.primary}
              />
              <H4 align="center">
                {polyglot.t("inboxes.check_your_conversations")}
              </H4>
              <Body18 align="center">
                {polyglot.t("inboxes.select_to_start_chating")}
              </Body18>
            </Block>
          </Route>
        )}
        {newTicketRoute && (
          <Route path={`${path}/new`}>
            <ConversationWrapper mobileLayout={mobileLayout}>
              <TicketNew />
            </ConversationWrapper>
          </Route>
        )}
        <Route path={`${path}/:id`}>
          <ConversationWrapper mobileLayout={mobileLayout}>
            {children}
          </ConversationWrapper>
        </Route>
      </Switch>
    </Block>
  );
};

const ChatThumbnailNoMemo = ({
  to,
  last_message,
  id,
  discussable_title,
  users,
  msg_unread,
  unread_messages,
  avatar,
  title,
  documentTitle,
  noDefaultNameAvatar,
}) => {
  const { set } = useDocumentTitle();
  return (
    <ActiveWrapperBase>
      <List.Item
        as={NavLink}
        shape={LIST.SHAPE.ROUND}
        divider={false}
        style={{
          paddingLeft: spacings.s,
          paddingRight: spacings.s,
          marginBottom: spacings.xs,
        }}
        onClick={() => {
          set(documentTitle);
        }}
        to={to}
        LeftComponent={() => (
          <>
            {users?.length >= 1 ? (
              <Avatar.Group>
                {users.map((user) => (
                  <Avatar
                    src={user.avatar}
                    name={noDefaultNameAvatar ? null : user.first_name}
                    key={`user-avatar${user.id}`}
                    size={sizes.size48}
                  />
                ))}
              </Avatar.Group>
            ) : (
              <Avatar
                src={avatar}
                size={sizes.size48}
                name={noDefaultNameAvatar ? null : users?.[0]?.first_name}
              />
            )}
          </>
        )}
      >
        <Block
          display="flex"
          alignItems="baseline"
          justifyContent="space-between"
          width="100%"
        >
          <Body16
            css={`
              margin-right: ${spacings.s};
              color: ${colors.body};
            `}
            strong
            numberOfLines={1}
          >
            {title || discussable_title}
          </Body16>
          {last_message && (
            <Caption color={colors.muted} style={{ whiteSpace: "nowrap" }}>
              {last_message.created_at}
            </Caption>
          )}
        </Block>
        <Block display="flex" alignItems="center" width="100%">
          <Block width="100%">
            <Body14
              color={msg_unread || unread_messages ? colors.body : colors.muted}
              numberOfLines={1}
              strong={msg_unread || unread_messages}
            >
              {last_message?.first_name && `${last_message.first_name} : `}
              {last_message?.body}
              {!last_message && "Commencez la discussion"}
            </Body14>
          </Block>
          {!!(msg_unread || unread_messages) && (
            <Badge count={msg_unread || unread_messages} />
          )}
        </Block>
      </List.Item>
    </ActiveWrapperBase>
  );
};
const ChatThumbnail = React.memo(
  ChatThumbnailNoMemo,
  (p, n) =>
    p.msg_unread === n.msg_unread &&
    p.last_message === n.last_message &&
    p.unread_messages === n.unread_messages
);

const Messages = ({ LeftMenu, children, path, newTicketRoute }) => (
  <Block
    height={{ xs: "inherit", md: `calc(100vh - var(--nav-height))` }}
    overflowY="hidden"
    alignItems="stretch"
    display="flex"
  >
    <Block width="100%" maxWidth={{ xs: "100%", md: "400px" }}>
      <Block
        borderRight={`solid ${borderWidth.s} ${colors.border}`}
        paddingTop={{ xs: spacings.m, md: spacings.l }}
        height={{ xs: "inherit", md: "100%" }}
        overflowY={{ xs: "inherit", md: "scroll" }}
        paddingX={spacings.s}
      >
        {LeftMenu && LeftMenu()}
      </Block>
    </Block>
    <ConversationRouter path={path} newTicketRoute={newTicketRoute}>
      {children}
    </ConversationRouter>
  </Block>
);

// ---------- CONTENT LAYOUT ---------- //

const Content = ({ children, title, ...rest }) => {
  const documentTitle = useDocumentTitle();
  useEffect(() => {
    documentTitle.set(title);
  }, []);
  return (
    <Block
      as={Container.Medium}
      marginTop={{ xs: spacings.m, md: spacings.l }}
      marginBottom={{ xs: spacings.m, md: spacings.l }}
      {...rest}
    >
      {children}
    </Block>
  );
};

export { ChatThumbnail, Content, Full, Messages };
