import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors, sizes, spacings } from "../../assets/themes";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import Modal from "../Modal";
import { BUTTON } from "../Styles/variants";
import { Body16 } from "../Text";
import { useBreakpoints } from "../../modules/hooks";

const StyledNavigationWrapper = styled.div`
  ${({ left }) => left && "left : 0"};
  ${({ right }) => right && "right : 0"};
  top: 0;
  bottom: 0;
  margin: auto 0;
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 9;
  margin: ${spacings.m};
  ${
    "" /* prevent user missclick outside button and trigger the close by setting a width (size of the button) and hide the button only instead of the wrapper when page limits */
  }
  cursor: default;
  width: ${sizes.size48};
`;

const StyledGalleryHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: ${spacings.s};
  z-index: 10;
`;

const StyledNavigationButton = styled(Button.Medium)`
  background-color: ${colors.overlayLight}!important;
  * {
    color: ${colors.white};
  }
  &:hover {
    &:after {
      background-color: ${colors.overlayDark}!important;
    }
  }
`;

const StyledPagination = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const StyledMotionImageWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: ${spacings.xxl};
`;

const StyledImg = styled.img`
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  pointer-events: all;
  user-select: none;
  cursor: default;
`;

const StyledGalleryWrapper = styled.div`
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  cursor: zoom-out;
`;

const variants = {
  enter: (direction) => ({
    x: direction > 0 ? window.innerWidth : -window.innerWidth,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    zIndex: 0,
    x: direction < 0 ? window.innerWidth : -window.innerWidth,
    opacity: 0,
  }),
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => Math.abs(offset) * velocity;

const ImageGalleryModal = ({ isOpen, onClose, initialIndex = 0, children }) => {
  const [childs] = useState(React.Children.toArray(children));
  const [[page, direction], setPage] = useState([initialIndex, 0]);
  const breakpoints = useBreakpoints();
  const isMobile = breakpoints.get({ xs: true, md: false });
  const canNext = page < childs.length - 1;
  const canPrev = page > 0;
  const paginate = (newDirection) => {
    let v = page + newDirection;
    if (v < 0) {
      v = 0;
    } else if (v > childs.length - 1) {
      v = childs.length - 1;
    }
    setPage([v, newDirection]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowRight") {
      paginate(1);
    }
    if (e.key === "ArrowLeft") {
      paginate(-1);
    }
  };

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleKeyDown);
    if (!isOpen) document.removeEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [page, isOpen]);

  const getSrcFromChildrenIndex = () => childs[page]?.props?.srcSet;

  const isVideo = (src) => {
    const videoExtensions = [
      "mp4",
      "webm",
      "ogg",
      "mov",
      "avi",
      "flv",
      "mkv",
      "mpeg",
      "mpg",
      "wmv",
      "3gp",
      "m4v",
    ];
    const extension = src.split(".").pop();
    return videoExtensions.includes(extension.toLowerCase());
  };

  return (
    <Modal.FullScreen
      hideBackdrop
      isOpen={isOpen}
      onClose={onClose}
      css={`
        > div > div {
          padding: 0;
          background-color: ${colors.overlayBackdropDark};
        }
      `}
    >
      <StyledGalleryWrapper
        onMouseDown={() => {
          if (!isMobile && onClose) onClose();
        }}
      >
        <StyledGalleryHeader>
          {childs.length > 1 && (
            <StyledPagination>
              <Body16 color={colors.white}>
                {page + 1}/{childs.length}
              </Body16>
            </StyledPagination>
          )}
          <StyledNavigationButton
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.CIRCLE}
            // prevent mouseDownEvent parent triggering
            onMouseDown={(e) => e.stopPropagation()}
            onClick={onClose}
          >
            <Icon.Large name="times" color={colors.white} />
          </StyledNavigationButton>
        </StyledGalleryHeader>
        <AnimatePresence exitBeforeEnter initial={false} custom={direction}>
          <StyledMotionImageWrapper
            key={page}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            style={isMobile ? {} : { pointerEvents: "none" }}
            transition={{
              x: { ease: "easeOut", duration: 0.1 },
              opacity: { duration: 0.1 },
            }}
            drag={isMobile ? "x" : false}
            dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
            dragElastic={1}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(-1);
              }
            }}
          >
            {isVideo(getSrcFromChildrenIndex()) ? (
              <StyledImg
                as="video"
                controls
                autoPlay
                width="100%"
                disablePictureInPicture
                controlsList="nodownload noremoteplayback disablePictureInPicture noplaybackrate"
                // prevent mouseDownEvent parent triggering
                onMouseDown={(e) => e.stopPropagation()}
              >
                <source src={getSrcFromChildrenIndex()} type="video/mp4" />
                Your browser does not support the video tag.
              </StyledImg>
            ) : (
              <StyledImg
                src={getSrcFromChildrenIndex()}
                // prevent mouseDownEvent parent triggering
                onMouseDown={(e) => e.stopPropagation()}
              />
            )}
          </StyledMotionImageWrapper>
        </AnimatePresence>
        <Block display={{ xs: "none", md: "block" }}>
          <StyledNavigationWrapper
            left
            onMouseDown={(e) => e.stopPropagation()} // prevent mouseDownEvent parent triggering
          >
            {canPrev && (
              <StyledNavigationButton
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                onClick={() => {
                  paginate(-1);
                }}
              >
                <Icon.Large name="angle-left" color={colors.white} />
              </StyledNavigationButton>
            )}
          </StyledNavigationWrapper>
        </Block>
        <Block display={{ xs: "none", md: "block" }}>
          <StyledNavigationWrapper
            right
            onMouseDown={(e) => e.stopPropagation()} // prevent mouseDownEvent parent triggering
          >
            {canNext && (
              <StyledNavigationButton
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                onClick={() => {
                  paginate(1);
                }}
              >
                <Icon.Large name="angle-right" color={colors.white} />
              </StyledNavigationButton>
            )}
          </StyledNavigationWrapper>
        </Block>
      </StyledGalleryWrapper>
    </Modal.FullScreen>
  );
};

export default ImageGalleryModal;
