import { FastField, Field } from "formik";
import * as yup from "yup";
import { radius, sizes, spacings } from "../../assets/themes";
import {
  Block,
  FormControl,
  Input,
  List,
  PhoneInput,
  Textarea,
} from "../../components";
import { LIST } from "../../components/Styles/variants";
import { MAX_JOB_PHOTOS, formatPhone, phoneRegex } from "../../utils";
import polyglot from "../../utils/polyglot";
import PhotosUpload from "./PhotosUpload";

const getFields = ({
  postPhotos,
  updatePhone,
  data: {
    address,
    datetime,
    description,
    can_edit_datetime,
    can_edit_job,
    informations,
    destination_address,
    phone,
    photos,
    title,
  },
}) => [
  "Informations",
  {
    title: polyglot.t("common.title"),
    name: "title",
    disabled: true,
    value: title,
    Component: () => (
      <Field name="title">
        {({ field }) => (
          <FormControl label={polyglot.t("common.title")}>
            <Input {...field} />
          </FormControl>
        )}
      </Field>
    ),
  },
  {
    title: polyglot.t("common.description"),
    name: "description",
    placeholder: polyglot.t("job.description_is_empty"),
    disabled: true,
    value: description,
    Component: () => (
      <Field name="description">
        {({ field }) => (
          <FormControl label="description">
            <Textarea {...field} />
          </FormControl>
        )}
      </Field>
    ),
  },
  {
    title: polyglot.t("common.phone_number"),
    name: "phone",
    isLoading: updatePhone.isLoading,
    value: phone && formatPhone(phone),
    initialValues: { phone },
    onSubmit: (values) => {
      updatePhone.mutate({ name: "phone", values });
    },
    validationSchema: yup.object().shape({
      phone: yup.string().min(10).matches(phoneRegex).required(),
    }),
    Component: () => (
      <Field name="phone">
        {({ field, form }) => (
          <FormControl label={polyglot.t("common.phone_number")}>
            <PhoneInput
              {...field}
              onChange={(v) => form.setFieldValue("phone", v)}
              onBlur={(e) => e.preventDefault()}
            />
          </FormControl>
        )}
      </Field>
    ),
  },
  {
    title: `${polyglot.t("common.photos")} (${
      photos.length
    }/${MAX_JOB_PHOTOS})`,
    name: "photos",
    disabled: !can_edit_job || photos.length === MAX_JOB_PHOTOS,
    placeholder: polyglot.t("job.no_photos_added"),
    isLoading: postPhotos.isLoading,
    validationSchema: yup.object().shape({
      phone: yup.array().of(yup.string()),
    }),
    initialValues: { uploads: [], photos: [] },
    onSubmit: (values) => {
      postPhotos.mutate({ photos: values.photos });
    },
    RenderValue:
      photos.length > 0
        ? () => (
            <Block display="flex" flexWrap="wrap">
              {photos.map(({ thumbnail }, index) => (
                <img
                  src={thumbnail}
                  style={{
                    borderRadius: radius.m,
                    width: sizes.size96,
                    height: sizes.size96,
                    objectFit: "cover",
                  }}
                  alt=""
                  key={`photos-job-${index}`}
                  css={`
                    margin-right: ${spacings.s};
                  `}
                />
              ))}
            </Block>
          )
        : null,
    Component: () => {
      const handleChange = ({ uploads, tokens }, { setFieldValue }) => {
        setFieldValue("uploads", uploads);
        setFieldValue("photos", tokens);
      };
      return (
        <FastField>
          {({ form }) => (
            <FormControl label={`${polyglot.t("common.photos")}`}>
              <PhotosUpload
                initialSrc={photos.map(({ thumbnail }) => thumbnail)}
                tokens={form.values.photos}
                onChange={(values) => handleChange(values, form)}
              />
            </FormControl>
          )}
        </FastField>
      );
    },
  },
  polyglot.t("job.date_and_contact_details"),
  {
    title: polyglot.t("common.date_and_hour"),
    name: "datetime",
    value: datetime,
    disabled: !can_edit_datetime,
  },
  {
    title: polyglot.t("common.address"),
    name: "address",
    value: address,
    disabled: true,
  },
  ...(destination_address && {
    title: polyglot.t("common.destination_address"),
    name: "destination_address",
    value: destination_address,
    disabled: true,
  }),
  ...(informations?.length > 0
    ? [
        polyglot.t("job.service_details"),
        {
          title: polyglot.t("common.informations"),
          RenderValue: () =>
            informations.map((info, index) => (
              <List.Item
                divider={false}
                size={LIST.SIZE.COMPACT}
                key={`job-informations-${index}`}
              >
                <List.Elem.Title strong>
                  {info.key} : {info.value}
                </List.Elem.Title>
              </List.Item>
            )),
          disabled: true,
        },
      ]
    : []),
];

export default getFields;
