import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormStepper, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = { multipleJobbersAllowed: true };

const validationSchema = yup.object().shape({
  nh_specific2: yup
    .array()
    .test(
      "one-is-more-than-zero",
      (value) => value.reduce((a, b) => a + b) > 0
    ),
});

export function useCategory1019() {
  const initialValues = {
    nh_specific2: [0, 0, 0, 0],
  };

  const getNbHours = (values) => values.nh_specific2.reduce((a, b) => a + b);

  const nhConfig = {
    "nh_specific2[0]": {
      label: polyglot.t("categories.bathroom_sink"),
      options: [
        { value: 0.0, label: 0 },
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
      ],
    },
    "nh_specific2[1]": {
      label: polyglot.t("categories.italian_shower"),
      options: [
        { value: 0.0, label: 0 },
        { value: 2.0, label: 1 },
        { value: 4.0, label: 2 },
        { value: 6.0, label: 3 },
      ],
    },
    "nh_specific2[2]": {
      label: polyglot.t("categories.shower"),
      options: [
        { value: 0.0, label: 0 },
        { value: 2.0, label: 1 },
        { value: 4.0, label: 2 },
        { value: 6.0, label: 3 },
      ],
    },
    "nh_specific2[3]": {
      label: polyglot.t("categories.bathtub"),
      options: [
        { value: 0.0, label: 0 },
        { value: 2.5, label: 1 },
        { value: 5.0, label: 2 },
        { value: 7.5, label: 3 },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl
            label={polyglot.t("categories.number_elements_isolate")}
          >
            <FormStepper
              name="nh_specific2[0]"
              title={nhConfig["nh_specific2[0]"].label}
              options={nhConfig["nh_specific2[0]"].options}
            />
            <FormStepper
              name="nh_specific2[1]"
              title={nhConfig["nh_specific2[1]"].label}
              options={nhConfig["nh_specific2[1]"].options}
            />
            <FormStepper
              name="nh_specific2[2]"
              title={nhConfig["nh_specific2[2]"].label}
              options={nhConfig["nh_specific2[2]"].options}
            />
            <FormStepper
              name="nh_specific2[3]"
              title={nhConfig["nh_specific2[3]"].label}
              options={nhConfig["nh_specific2[3]"].options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    config,
  };
}
