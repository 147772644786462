import AmericanExpress from "../assets/images/svg-icons/Badges/Payments/American Express.svg";
import Maestro from "../assets/images/svg-icons/Badges/Payments/Maestro.svg";
import Mastercard from "../assets/images/svg-icons/Badges/Payments/Mastercard.svg";
import Paypal from "../assets/images/svg-icons/Badges/Payments/Paypal.svg";
import Placeholder from "../assets/images/svg-icons/Badges/Payments/Placeholder.svg";
import IBAN from "../assets/images/svg-icons/Badges/Payments/RIB.svg";
import Visa from "../assets/images/svg-icons/Badges/Payments/Visa.svg";
import VisaMastercard from "../assets/images/svg-icons/Badges/Payments/VisaMastercard.svg";

const AMEX = "AMEX";
const VISA_MASTERCARD = "VISA_MASTERCARD";
const MAESTRO = "MAESTRO";
const VISA = "VISA";
const MASTERCARD = "MASTERCARD";

const badge = {
  AMERICANEXPRESS: AmericanExpress,
  AMEX: AmericanExpress,
  MAESTRO: Maestro,
  MASTERCARD: VisaMastercard,
  PAYPAL: Paypal,
  PLACEHOLDER: Placeholder,
  IBAN,
  VISA: VisaMastercard,
  VISA_MASTERCARD: VisaMastercard,
};

const getCreditCardTypeByName = (name) => {
  const formatedName = name.toUpperCase();
  if (formatedName === VISA || formatedName === MASTERCARD) {
    return VISA_MASTERCARD;
  }
  return formatedName;
};

const getCreditCardType = (value) => {
  const cc = value?.replace(/\s/g, "");
  const amex = new RegExp("(?:3[47][0-9]{13})");
  const visa = new RegExp("(?:4[0-9]{12})(?:[0-9]{3})?");
  const mastercard = new RegExp(
    "(?:(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12})"
  );
  const maestro = new RegExp(
    "(?:(?:5[0678]\\d\\d|6304|6390|67\\d\\d)\\d{8,15})"
  );

  if (visa.test(cc)) {
    return VISA_MASTERCARD;
  }
  if (amex.test(cc)) {
    return AMEX;
  }
  if (mastercard.test(cc)) {
    return VISA_MASTERCARD;
  }
  if (maestro.test(cc)) {
    return MAESTRO;
  }
  return "";
};

const getBadge = (value) =>
  (value && badge[value.toUpperCase()]) || badge.PLACEHOLDER;

export { getBadge, getCreditCardType, getCreditCardTypeByName };
