import React, { useState, useContext } from "react";
import styled from "styled-components";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Breadcrumb,
  Button,
  Col,
  Icon,
  InfiniteScrollEnhancer,
  Link,
  List,
  NoResult,
  Row,
  Shape,
  Tag,
  WalletCard,
} from "../../../components";
import {
  BUTTON,
  LIST,
  LIST_HEADER,
  SHAPE,
  TAG,
} from "../../../components/Styles/variants";
import { DashboardContext } from "../../../modules/contexts";
import { useGetAccountCesu } from "../../../modules/routes/dashboard-routes";
import { formatDate } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import CesuSkeleton from "../skeletons/CesuSkeleton";
import { getCesuState } from "../dashboard-utils";
import CesuDetailsModal from "./CesuDetailsModal";
import { ACCOUNT_PATH } from "../config/routes";

const StyledCesuWalletCard = styled(WalletCard)`
  background: ${colors.primary900};
`;

const CesuItem = ({
  amount,
  fee,
  credited_amount,
  date,
  ecesu,
  number,
  secure_code,
  state,
  refused_reason,
  billing_url,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const formatCesuNumber = (str) =>
    `${str.substring(0, 4)} •••• ${str.substring(str.length - 4, str.length)}`;

  return (
    <>
      <Row>
        <Col size={{ xs: 6, lg: 4 }}>
          <List.Item
            size={LIST.SIZE.LARGE}
            LeftComponent={() => (
              <Shape.Small
                backgroundColor={colors.primaryLight}
                color={colors.primary}
                shape={SHAPE.SHAPE.CIRCLE}
              >
                <Icon.Large name="ticket" />
              </Shape.Small>
            )}
          >
            <Body16 strong numberOfLines={1}>
              {formatCesuNumber(number)}
            </Body16>
            {!!secure_code && (
              <Block display={{ xs: "none", lg: "block" }}>
                <Body14 color={colors.muted} numberOfLines={1}>
                  Code : {secure_code}
                </Body14>
              </Block>
            )}
            <Block display={{ xs: "block", lg: "none" }}>
              <Body14 size={LIST.SIZE.LARGE} numberOfLines={1}>
                {getCesuState(state)}
              </Body14>
            </Block>
          </List.Item>
        </Col>
        <Col size={4} display={{ xs: "none", lg: "block" }}>
          <List.Item size={LIST.SIZE.LARGE}>{getCesuState(state)}</List.Item>
        </Col>
        <Col size={{ xs: 3, lg: 1 }}>
          <List.Item size={LIST.SIZE.LARGE}>
            <Body16 strong>{polyglot.toSmartCurrency(amount)}</Body16>
          </List.Item>
        </Col>
        <Col size={{ xs: 3, lg: 3 }}>
          <List.Item size={LIST.SIZE.LARGE}>
            <Block display="flex" justifyContent="flex-end" alignSelf="stretch">
              <Button.Link
                onClick={() => setModalIsOpen(true)}
                numberOfLines={1}
              >
                {polyglot.t("common.see_more")}
              </Button.Link>
            </Block>
          </List.Item>
        </Col>
      </Row>
      <CesuDetailsModal
        isOpen={modalIsOpen}
        amount={amount}
        credited_amount={credited_amount}
        fee={fee}
        date={date}
        onClose={() => setModalIsOpen(false)}
        ecesu={ecesu}
        number={number}
        secure_code={secure_code}
        state={state}
        refused_reason={refused_reason}
        billing_url={billing_url}
      />
    </>
  );
};

const Cesu = ({ config }) => {
  const { public_pages } = useContext(DashboardContext);
  const getCesu = useGetAccountCesu();
  return (
    <>
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <InfiniteScrollEnhancer query={getCesu} unionBy="tickets">
        {({ data, isLoading }) =>
          !isLoading ? (
            <div>
              <Row gutter={spacings.s}>
                <Col size={12}>
                  <StyledCesuWalletCard
                    name={polyglot.t("wallet.cesu")}
                    icon="ticket"
                    renderAmount={() => (
                      <Block
                        width="100%"
                        display="flex"
                        alignItems="center"
                        flexWrap="wrap"
                        justifyContent="space-between"
                      >
                        <span>
                          {polyglot.toSmartCurrency(data.cesu_wallet_amount)}
                        </span>
                        {data.cesu_amount_being_processed > 0 && (
                          <Block marginY={spacings.xs}>
                            <Tag.Small kind={TAG.KIND.MUTED}>
                              +
                              {polyglot.toSmartCurrency(
                                data.cesu_amount_being_processed
                              )}{" "}
                              en attente
                            </Tag.Small>
                          </Block>
                        )}
                      </Block>
                    )}
                    amount={polyglot.toSmartCurrency(data.cesu_wallet_amount)}
                    description={polyglot.t("wallet.cesu_description")}
                    FooterComponent={() => (
                      <Link
                        as={Button.Small}
                        color={colors.body}
                        href="/cesu/new"
                        kind={BUTTON.KIND.MINIMAL}
                        shape={BUTTON.SHAPE.PILL}
                        LeftComponent={() => <Icon.Medium name="scanner" />}
                      >
                        {polyglot.t("cesu.add")}
                      </Link>
                    )}
                  />
                </Col>
              </Row>
              <List.Header
                size={LIST.SIZE.LARGE}
                css={`
                  margin-top: ${spacings.s};
                `}
                RightComponent={() => (
                  <Link href={public_pages.cesu}>
                    {polyglot.t("common.find_out_more")}
                  </Link>
                )}
              >
                {polyglot.t("cesu.saved_tickets")}
              </List.Header>
              {data.tickets.map((item, index, arr) => (
                <React.Fragment key={`cesu-ticket-${index}`}>
                  {item.date !== arr[index - 1]?.date && (
                    <Block marginTop={index !== 0 ? spacings.s : 0}>
                      <List.Header
                        kind={LIST_HEADER.KIND.SUBTITLE}
                        size={LIST.SIZE.COMPACT}
                        css="text-transform: capitalize;"
                      >
                        {formatDate(item.date, "dddd DD MMMM")}
                      </List.Header>
                    </Block>
                  )}
                  <CesuItem {...item} />
                </React.Fragment>
              ))}
              {data.tickets?.length === 0 && (
                <NoResult
                  title={polyglot.t("cesu.no_ticket")}
                  subtitle={polyglot.t("cesu.no_ticket_description")}
                />
              )}
            </div>
          ) : (
            <CesuSkeleton />
          )
        }
      </InfiniteScrollEnhancer>
    </>
  );
};

export default Cesu;
