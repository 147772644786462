/* eslint-disable no-underscore-dangle */
import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import FormReducer from "./modules/reducers/global/form-reducer";
import WizardReducer from "./modules/reducers/wizard-reducer";
import SearchBarReducer from "./modules/reducers/search-bar-reducer";
import BadgesReducer from "./modules/reducers/badges-reducer";
import RailsReducer from "./modules/reducers/rails-reducer";

const rootReducer = combineReducers({
  form: FormReducer,
  wizard: WizardReducer,
  searchBar: SearchBarReducer,
  badges: BadgesReducer,
  rails: RailsReducer,
});

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
