import { useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow, Stepper } from '../../components';

const SetNbHours = ({
  goNext,
  goBack,
  weekly_nb_hours_min,
  weekly_nb_hours_max,
  monthly_nb_hours_min,
  monthly_nb_hours_max,
}) => {
  const { values, setFieldValue, isValid } = useFormikContext();
  const selectedJobber = !values.jobber_id ? values.external_jobber : values.jobber;
  return (
    <Container.Small>
      <Flow.Item.Title>
        Combien d’heures {selectedJobber?.first_name} effectue chaque{' '}
        {values?.frequency === 'weekly' ? 'semaine' : 'mois'}&nbsp;?
      </Flow.Item.Title>
      <div className="d-flex justify-content-center">
        <div style={{ transform: 'scale(1.5)', transformOrigin: 'center' }} className="py-4">
          {values.frequency === 'weekly' ? (
            <Stepper
              name="nb_hours"
              min={weekly_nb_hours_min}
              max={weekly_nb_hours_max}
              suffix="h"
              onChange={value => setFieldValue('nb_hours', value)}
              value={values.nb_hours}
            />
          ) : (
            <Stepper
              name="nb_hours"
              min={monthly_nb_hours_min}
              max={monthly_nb_hours_max}
              suffix="h"
              onChange={value => setFieldValue('nb_hours', value)}
              value={values.nb_hours}
            />
          )}
        </div>
      </div>
      <Flow.Item.Navigation goNext={goNext} goBack={goBack} isValid={isValid} />
      <div className="mt-5 alert alert-light bg-light text-alert border d-flex align-items-center">
        <div>
          <i className="icon-question-circle icon-lg mr-3 text-primary" />
        </div>
        <div>En cas d’absence ou d’heures supplémentaires, vous pourrez modifier le nombre d’heures à payer chaque {values?.frequency === 'weekly' ? 'semaine' : 'mois'} depuis votre compte.
        </div>
      </div>
    </Container.Small>
  );
};

export default SetNbHours;
