import React from "react";
import { Progress } from "../components";

const ProgressCircularApp =
  (
    {
      color,
      size,
      transition = 0.5,
      thickness,
      value,
      rest,
      label,
      fontSize = "1rem",
    },
    rails
  ) =>
  () =>
    (
      <Progress.Circular
        size={size}
        value={value}
        thickness={thickness}
        css={`
          stroke: ${color}!important;
          transition: ${transition}s!important;
        `}
        {...rest}
      >
        <span
          css={`
            font-weight: var(--font-weight-medium);
            text-align: center;
            font-size: ${fontSize || `${(1 * size) / 48}rem`};
          `}
        >
          {label}
        </span>
      </Progress.Circular>
    );

export default ProgressCircularApp;
