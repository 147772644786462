import {
  CHILDREN_AMOUNT,
  CHILD_CARE_DAYS,
  CHILD_CARE_LOCATION,
  CHILD_PAGE,
  CONFIG,
  NH_CONFIG,
} from "./9000/common";
import polyglot from "@/utils/polyglot";

const config = {
  ...CONFIG,
  noTime: true,
  renderPages: (pages) => {
    const newPages = [...CONFIG.renderPages(pages)];
    const datePageIndex = pages.findIndex((p) => p.path === "date");
    newPages[datePageIndex].title = polyglot.t(
      "categories.when_child_care_start"
    );
    return newPages;
  },
};

export function useCategory9006() {
  const nhConfig = NH_CONFIG;

  const getNbHours = () => undefined;

  const pages = [
    ...CHILD_CARE_DAYS,
    ...CHILD_CARE_LOCATION,
    ...CHILDREN_AMOUNT,
    ...CHILD_PAGE,
  ];

  return {
    getNbHours,
    pages,
    nhConfig,
    version: 1.1,
    config,
  };
}
