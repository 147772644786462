import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

const config = {
  IsNbHoursReadOnly: true,
};

export function useCategory1081() {
  const DEFAULT_NB_HOURS = 4;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 2,
            subtitle: polyglot.t("categories.three_to_five_steps"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.six_to_ten_steps"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 6,
            subtitle: polyglot.t("categories.eleven_to_twenty_steps"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1,
    initialValues,
    config,
  };
}
