import React, { useImperativeHandle, useRef, useState } from "react";
import { CUSTOMER, JOB, JOBBER } from "./config";
import PreviewPopover from "./PreviewPopover";
// eslint-disable-next-line import/no-cycle
import PreviewElemCustomer from "./PreviewElemCustomer";
// eslint-disable-next-line import/no-cycle
import PreviewElemJob from "./PreviewElemJob";
import PreviewElemJobber from "./PreviewElemJobber";

const UserPreviewContent = React.forwardRef(
  ({ id, node, noPortal, onMouseEnterDelay, kind, isOpen, onClose }, ref) => {
    const contentRef = useRef(ref);
    const [popoverRef, setPopoverRef] = useState(null);

    useImperativeHandle(ref, () => popoverRef, [popoverRef?.update]);

    return (
      <>
        <PreviewPopover
          node={node}
          ref={setPopoverRef}
          noPortal={noPortal}
          isOpen={isOpen}
          onClose={onClose}
          onMouseEnterDelay={onMouseEnterDelay}
        >
          <div ref={contentRef}>
            {kind === JOBBER && (
              <PreviewElemJobber popoverRef={popoverRef} id={id} />
            )}
            {kind === CUSTOMER && (
              <PreviewElemCustomer popoverRef={popoverRef} id={id} />
            )}
            {kind === JOB && <PreviewElemJob popoverRef={popoverRef} id={id} />}
          </div>
        </PreviewPopover>
      </>
    );
  }
);

export default UserPreviewContent;
