import styled from "styled-components";
import Icon from "../Icon";
import Info from "../Info";
import Block from "../Block";
import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import { NEW_JOBBER_CREDIT } from "../../utils";
import { Body14 } from "../Text";
import { INFO } from "../Styles/variants";

const StyledArrowPosition = styled.div`
  position: absolute;
  top: -14px;
  left: ${spacings.s};
`;

const NewJobberCreditTag = ({ first_name }) => (
  <Block position="relative" display="flex" alignItems="center">
    <StyledArrowPosition>
      <Icon.Medium name="caret-down" color={colors.primaryLight} />
    </StyledArrowPosition>
    <Body14 strong color={colors.primary}>
      {polyglot.t("job.new_jobber_credit_amount", {
        amount: polyglot.toSmartCurrency(NEW_JOBBER_CREDIT, {
          noDecimal: true,
        }),
      })}
    </Body14>
    <Info size={INFO.SIZE.SMALL}>
      {polyglot.t("job.new_jobber_credit_amount_description", {
        first_name,
        amount: polyglot.toSmartCurrency(NEW_JOBBER_CREDIT, {
          noDecimal: true,
        }),
      })}
    </Info>
  </Block>
);
export default NewJobberCreditTag;
