import { useEffect } from "react";

function observerIsAvailable() {
  if (
    !("IntersectionObserver" in window) ||
    !("IntersectionObserverEntry" in window) ||
    !("intersectionRatio" in window.IntersectionObserverEntry.prototype)
  ) {
    return false;
  }
  return true;
}

export default function useIntersectionObserver({
  root,
  target,
  onIntersect,
  onObserver,
  threshold = 1.0,
  rootMargin = "0px",
  getIsAvailable,
  enabled = true,
}) {
  useEffect(() => {
    const isAvailable = observerIsAvailable();
    if (getIsAvailable) {
      getIsAvailable(isAvailable);
    }
    if (!enabled || !isAvailable) {
      return null;
    }
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (onIntersect && entry.isIntersecting) onIntersect(entry, observer);
          if (onObserver) onObserver(entry);
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );
    const el = target && target.current;
    if (!el) {
      return null;
    }
    observer.observe(el);
    return () => {
      observer.unobserve(el);
    };
  }, [target.current, enabled]);
}
