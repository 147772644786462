import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormRadios, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const LOCAL = "local";
const NETWORK = "network";

const validationSchema = yup.object().shape({
  nh_specific: yup.string().required(),
});

export function useCategory6010() {
  const initialValues = {
    nh_specific: "",
  };

  const getNbHours = (values) => {
    let res = 0;
    if (values.nh_specific === LOCAL) res += 2;
    if (values.nh_specific === NETWORK) res += 2;
    return res;
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.printer_installation_type"),
      options: [
        {
          value: LOCAL,
          label: "Locale",
          subtitle: polyglot.t("categories.with_usb_wire"),
        },
        {
          value: NETWORK,
          label: "Réseau",
          subtitle: polyglot.t("categories.wifi_or_bluethoot"),
        },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl label={nhConfig.nh_specific.label}>
            <FormRadios
              name="nh_specific"
              title={nhConfig.nh_specific.label}
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
  };
}
