import { useFormikContext } from "formik";
import { useEffect } from "react";
import { colors } from "../../../assets/themes";
import { Body14 } from "../../../components";
import polyglot from "../../../utils/polyglot";
import CreditCard from "./CreditCard";
import ExternalMethod from "./ExternalMethod";
import { IDEAL, PAYPAL, VISA_MASTERCARD } from "./constants";
import { browserInfo } from "./utils";

const PaymentMethodsUpdated = ({
  methods: _methods,
  onChange,
  needCCCheck,
  disabled,
  withGutters,
  cardRegistrationDataParams,
}) => {
  const { validateForm, setValues, setFieldValue } = useFormikContext();

  let methods = _methods.includes(VISA_MASTERCARD)
    ? _methods
    : [VISA_MASTERCARD, ..._methods];
  methods = methods.map((m) => m.toLowerCase());

  useEffect(() => {
    setFieldValue("browserInfo", browserInfo);
  }, []);

  const handleChange = ({ payment_method, token, card_number, card_type }) => {
    let arr;
    if (card_number) {
      arr = { ...arr, card_number };
    }
    if (card_type) {
      arr = { ...arr, card_type };
    }
    if (token) {
      arr = { ...arr, token };
    }
    if (payment_method) {
      arr = { ...arr, payment_method };
    }
    setValues((s) => ({ ...s, ...arr }), false);
    setTimeout(() => {
      validateForm();
    }, 0);
    if (onChange) onChange(arr);
  };

  return (
    <>
      <CreditCard
        methods={methods}
        needCCCheck={needCCCheck}
        onChange={handleChange}
        disabled={disabled}
        withGutters={withGutters}
        cardRegistrationDataParams={cardRegistrationDataParams}
      />
      {methods.includes(PAYPAL) && (
        <ExternalMethod
          name={PAYPAL}
          onChange={handleChange}
          disabled={disabled}
          withGutters={withGutters}
        >
          <Body14 color={colors.muted}>
            {polyglot.t("payment_methods.paypal_4x_description")}
          </Body14>
        </ExternalMethod>
      )}
      {methods.includes(IDEAL) && (
        <ExternalMethod
          name={IDEAL}
          onChange={handleChange}
          disabled={disabled}
          withGutters={withGutters}
        />
      )}
    </>
  );
};

export default PaymentMethodsUpdated;
