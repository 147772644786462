import { Field, Form, Formik } from "formik";
import React from "react";
import * as yup from "yup";
import {
  Alert,
  Button,
  FormControl,
  Modal,
  Select,
  Textarea,
} from "../../../components";
import { ALERT, BUTTON } from "../../../components/Styles/variants";
import { usePutCloseJob } from "../../../modules/routes/job-routes";
import polyglot from "../../../utils/polyglot";
import useJob from "../../../modules/hooks/useJob";

const REASON_OTHER = "other";
export const close_REASONS = [
  {
    value: "other_solution",
    label: polyglot.t("jobs.closed_reason.other"),
  },
  {
    value: "job_canceled",
    label: polyglot.t("jobs.closed_reason.canceled"),
  },
  {
    value: "price_not_good",
    label: polyglot.t("jobs.closed_reason.price"),
  },
  {
    value: "job_mislead",
    label: polyglot.t("jobs.closed_reason.mislead"),
  },
  {
    value: "not_enough_skill",
    label: polyglot.t("jobs.closed_reason.skill"),
  },
  {
    value: REASON_OTHER,
    label: polyglot.t("common.other"),
  },
];

const validationSchema = yup.object().shape({
  close_reason: yup.string().required(),
  close_comment: yup.string().when("close_reason", {
    is: (v) => v === REASON_OTHER,
    then: yup.string().required(),
    otherwise: yup.string().notRequired(),
  }),
});

const CloseJobModal = ({ isOpen, onClose }) => {
  const { data } = useJob();
  const putCloseJob = usePutCloseJob({ id: data.id });
  const handleSubmit = (values) => {
    putCloseJob.mutate(values, {
      onSuccess: () => {
        if (onClose) onClose();
      },
    });
  };
  return (
    <Modal.Small isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("jobs.cancel_job")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Formik
        validateOnMount
        initialValues={{ close_reason: "" }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, isValid, resetForm }) => (
          <Form>
            <Modal.Item.Wrapper>
              <FormControl label={polyglot.t("jobs.select_closed_reason")}>
                <Select
                  options={close_REASONS}
                  name="close_reason"
                  placeholder={polyglot.t("common.placeholder.reason")}
                  onChange={({ value }) => {
                    resetForm();
                    setFieldValue("close_reason", value);
                  }}
                />
              </FormControl>
              {values.close_reason === REASON_OTHER && (
                <FormControl label={polyglot.t("jobs.closed_reason_details")}>
                  <Field name="close_comment">
                    {({ field }) => (
                      <Textarea
                        placeholder={polyglot.t(
                          "jobs.closed_reason_details_placeholder"
                        )}
                        name="close_comment"
                        {...field}
                      />
                    )}
                  </Field>
                </FormControl>
              )}
              {putCloseJob.isError && (
                <Alert.Low kind={ALERT.KIND.DANGER}>
                  {putCloseJob.error?.response?.data?.error_message}
                </Alert.Low>
              )}
            </Modal.Item.Wrapper>
            <Modal.Item.Footer>
              <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={onClose}>
                {polyglot.t("common.back")}
              </Button.Medium>
              <Button.Medium
                type="submit"
                disabled={!isValid}
                isLoading={putCloseJob.isLoading}
                accentColor={BUTTON.ACCENT_COLOR.DANGER}
              >
                {polyglot.t("cancel_review.confirm_cancel")}
              </Button.Medium>
            </Modal.Item.Footer>
          </Form>
        )}
      </Formik>
    </Modal.Small>
  );
};

export default CloseJobModal;
