import React from "react";
import styled, { css } from "styled-components";
import { borderWidth, colors, sizes } from "../../assets/themes";
import { BREAKPOINTS } from "../../utils";
import { CALENDAR } from "../Styles/variants";
import { Body14, Body16 } from "../Text";

const getDaySize = ({ size }) => {
  if (size === CALENDAR.SIZE.COMPACT) return sizes.size36;
  return sizes.size48;
};

const setResponsiveBefore = ({ isStartDay, isEndDay, active }) => {
  const sideGap = `calc(((100% - ${sizes.size48}) / 2) + (${sizes.size48} / 2))`;
  if (isStartDay && !isEndDay) {
    return css`
      left: ${sideGap};
      width: calc(100% - ${sideGap});
    `;
  }
  if (isEndDay && !isStartDay) {
    return css`
      right: ${sideGap};
      width: calc(100% - ${sideGap});
    `;
  }
  if (isStartDay && isEndDay && !active) {
    return css`
      left: 0;
      width: ${sizes.size48};
    `;
  }
  return null;
};

const StyledDay = styled.button`
  position: relative;
  border: 0;
  height: ${({ size }) => getDaySize({ size })};
  outline: none !important;
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    min-width: ${sizes.size36};
  }
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    min-width: ${({ size }) => getDaySize({ size })};
  }
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  * {
    z-index: 9;
    position: relative;
  }

  &:after {
    content: "";
    position: absolute;
    width: ${({ size }) => getDaySize({ size })};
    height: ${({ size }) => getDaySize({ size })};
    margin: auto;
    z-index: 1;
    border-radius: 50%;
    background-color: transparent;
  }
  &:before {
    content: "";
    z-index: 0;
    position: absolute;
    width: 100%;
    height: ${({ size }) => getDaySize({ size })};
    margin: auto;
    background-color: transparent;
  }
  &:focus {
    &:after {
      border: solid ${borderWidth.m} ${colors.primary};
      box-shadow: 0 0 0 ${borderWidth.l} ${colors.primary50};
    }
  }
  ${({ isBetweenOrSame, active, isStartDay, isEndDay }) =>
    isBetweenOrSame &&
    css`
      &:before {
        background-color: ${colors.primaryLight};
        ${setResponsiveBefore({ isStartDay, isEndDay, active })};
      }
    `}
  ${({ disabled }) =>
    css`
      color: ${disabled ? colors.mutedLight : colors.body};
    `}
  ${({ isToday, disabled }) =>
    isToday &&
    !disabled &&
    css`
      ${"" /* font-weight: var(--font-weight-bold); */}
    `}
  ${({ active, disabled }) =>
    active
      ? css`
          * {
            color: ${colors.onColor}!important;
            font-weight: var(--font-weight-bold);
          }
          &:after {
            background-color: ${colors.primary};
          }
        `
      : !disabled &&
        css`
          &:hover {
            &:after {
              background: ${colors.overlayDark};
            }
          }
        `}
`;

function Day({
  onClick,
  label,
  active,
  isToday,
  disabled,
  value,
  isBetweenOrSame,
  isStartDay,
  isEndDay,
  onMouseEnter,
  onMouseLeave,
  size = CALENDAR.SIZE.DEFAULT,
}) {
  return (
    <StyledDay
      type="button"
      onClick={() => onClick(value)}
      active={active}
      isToday={isToday}
      disabled={disabled}
      onMouseOver={onMouseEnter}
      onMouseLeave={onMouseLeave}
      isBetweenOrSame={isBetweenOrSame}
      isStartDay={isStartDay}
      isEndDay={isEndDay}
      size={size}
    >
      {size === CALENDAR.SIZE.COMPACT ? (
        <Body14 align="center">{label}</Body14>
      ) : (
        <Body16 align="center">{label}</Body16>
      )}
    </StyledDay>
  );
}

export default Day;
