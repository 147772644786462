export const JOB_QUERY_KEY = "JOB_QUERY_KEY";
export const EDIT_INFORMATIONS_PATH = "/edit-informations";
export const JOB_DATETIME_EDIT_PATH = `${EDIT_INFORMATIONS_PATH}/datetime`;
export const CLOSE_PATH = "/close";
export const CLOSE_MULTIPLE_PATH = "/close-multiple";

export const OFFER_STATE_SELECTED = "selected";
export const OFFER_STATE_CANCELED = "canceled";
export const OFFER_STATE_PENDING = "pending";
export const OFFER_STATE_PAID = "paid";
export const GREAT_VALUE_LEVEL_1_TAG = "great_value_level_1";
export const GREAT_VALUE_LEVEL_2_TAG = "great_value_level_2";
export const GREAT_VALUE_LEVEL_3_TAG = "great_value_level_3";
export const ECO_DISTANCE_LEVEL_1_TAG = "eco_distance_level_1";
export const ECO_DISTANCE_LEVEL_2_TAG = "eco_distance_level_2";
export const ECO_DISTANCE_LEVEL_3_TAG = "eco_distance_level_3";
export const HIGH_DEMAND_TAG = "high_demand";
export const CREDIT_TAG = "credit";

export const getSelectedOrPaidOffers = (offers) =>
  offers?.filter(
    (offer) =>
      offer.state === OFFER_STATE_SELECTED || offer.state === OFFER_STATE_PAID
  );

export const getSelectedOffers = (offers) =>
  offers?.filter((offer) => offer.state === OFFER_STATE_SELECTED);

export const getCanceledOffers = (offers) =>
  offers?.filter((offer) => offer.state === OFFER_STATE_CANCELED);

export const getCancelOfferUrl = (id) => `/offers/${id}/cancel_review`;
export const getValidationOfferUrl = (id) => `/offers/${id}/review`;
export const getInstantPaymentOfferUrl = (id) =>
  `/instant_payment_relations/${id}/instant_payments/new`;
export const getInboxesUrl = (id, search = "") => `/inboxes/${id}${search}`;
