import React from "react";
import { Link } from "../components";

const linkify = (text) => {
  const httpRegex =
    /((https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/gim;
  const hasHttpRegex = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
  const textToArray = text.replaceAll("\r\n", " \r\n").replaceAll("\n", " \n").replaceAll("\r", " \r").split(" ");
  const r = textToArray.map((str, i) => {
    const isLink = httpRegex.test(str);
    const strWSpacing = textToArray.length - 1 === i ? `${str}` : `${str} `;
    if (isLink) {
      const url = hasHttpRegex.test(str) ? `https://${str}` : str;
      return (
        <Link key={i} href={url} target="_blank">
          {strWSpacing}
        </Link>
      );
    }
    return strWSpacing;
  });
  return r.reduce((p, c) =>
    typeof c === "string" && typeof p === "string" ? `${p}${c}` : [p, c]
  );
};

export default linkify;
