import { isValidPhoneNumber } from "libphonenumber-js";
import * as yup from "yup";
import { addressValidation } from "../../utils";
import polyglot from "../../utils/polyglot";
import Address from "./common/Address";
import Date from "./common/Date";
import Details from "./common/Details";
import Hour from "./common/Hour";
import NbHours from "./common/NbHours";
import NbJobbers from "./common/NbJobbers";
import PhoneNumber from "./common/PhoneNumber";
import Email from "./common/login/Email";
import {
  ADDRESS_PATH,
  DATE_PATH,
  DETAILS_PATH,
  EMAIL_PATH,
  NB_HOURS_PATH,
  NB_JOBBERS_PATH,
  START_HOUR_PATH,
} from "./constants";
import { ADDRESS_DETAILS_PAGES, PHOTOS_PAGE } from "./defaults";

const getCommonPages = ({ config, data, isSav, isConcierge, isLogged }) => [
  // Add photos page after Title/Description page for custom category
  ...(!config.hidePhotos && config.isCustom ? PHOTOS_PAGE : []),
  ...(config.isDateRange
    ? {
        path: "date",
        View: Date,
        title: polyglot.t("categories.when_start_the_petsitting"),
        validationSchema: yup.object().shape({
          date: yup.string().required(),
          ...(config.isDateRange
            ? {
                end_date: yup
                  .string()
                  .required(polyglot.t("common.errors.field_required")),
              }
            : []),
        }),
      }
    : []),
  ...(config.addressDetails ? ADDRESS_DETAILS_PAGES : []),
  ...(!config.IsNbHoursReadOnly
    ? {
        path: NB_HOURS_PATH,
        View: NbHours,
        title: null,
        validationSchema: yup.object().shape({
          nb_hours: yup.number().required(),
        }),
      }
    : []),
  ...(config.multipleJobbersAllowed
    ? {
        path: NB_JOBBERS_PATH,
        View: NbJobbers,
        title: polyglot.t("categories.how_many_jobbers_do_you_need"),
        validationSchema: yup.object().shape({
          nb_jobbers: yup.number().required(),
        }),
      }
    : []),
  ...(!config.isDateRange
    ? {
        path: DATE_PATH,
        View: Date,
        title: polyglot.t("categories.which_day_suits_bests"),
        validationSchema: yup.object().shape({
          date: yup.string().required(),
          ...(config.isDateRange
            ? {
                end_date: yup
                  .string()
                  .required(polyglot.t("common.errors.field_required")),
              }
            : []),
        }),
      }
    : []),
  ...(!config.noTime
    ? {
        path: START_HOUR_PATH,
        View: Hour,
        title: config.isTimeRange
          ? polyglot.t("categories.what_is_the_start_end_hour")
          : polyglot.t("categories.which_hour_suits_you"),
        validationSchema: yup.object().shape({
          start_hour: yup
            .string()
            .required(polyglot.t("common.errors.field_required")),
          ...(config.isTimeRange
            ? {
                end_hour: yup
                  .string()
                  .test(
                    "end_hour_respect_min_duration",
                    (value, { parent, createError }) => {
                      if (
                        value - parent.start_hour < 1 &&
                        value - parent.start_hour > 0
                      ) {
                        return createError({
                          path: "end_hour",
                          message: polyglot.t(
                            "categories.minimal_duration_error"
                          ),
                        });
                      }
                      return true;
                    }
                  )
                  .required(polyglot.t("common.errors.field_required")),
              }
            : []),
        }),
      }
    : []),
  ...(config.hideAddressPage || config.addressDetails
    ? []
    : {
        path: ADDRESS_PATH,
        View: Address,
        title: polyglot.t("categories.what_is_job_address"),
        validationSchema: yup.object().shape(
          {
            ...addressValidation,
          },
          [
            ["place_id", "address_id"],
            ["address_name", "city_id"],
          ]
        ),
      }),
  ...((isSav || isConcierge) && data?.phone
    ? []
    : {
        path: "phone",
        View: PhoneNumber,
        validationSchema: yup.object().shape({
          phone: yup
            .string()
            .min(10)
            .test("phone-is-valid", (value) =>
              value ? isValidPhoneNumber(value) : false
            )
            .required(),
        }),
      }),
  ...(!config.hidePhotos && !config.isCustom ? PHOTOS_PAGE : []),
  ...(!config.isCustom
    ? {
        path: DETAILS_PATH,
        View: Details,
        title: polyglot.t("categories.do_you_have_additionnal_informations"),
        validationSchema: yup.object().shape({
          title: yup
            .string()
            .required()
            .test(
              "non-empty-title",
              (value) => value?.replace(/\s/g, "").length > 0
            ),
          description: yup.string().notRequired(),
        }),
      }
    : []),
  ...(!isLogged
    ? {
        path: EMAIL_PATH,
        View: Email,
        title: polyglot.t("categories.what_is_your_email"),
        validationSchema: yup.object().shape({
          user: yup.object().shape({
            email: yup
              .string()
              .email(polyglot.t("common.errors.email_invalid"))
              .required(polyglot.t("common.errors.field_required")),
          }),
        }),
      }
    : []),
];

export default getCommonPages;
