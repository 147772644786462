/* eslint-disable import/no-unresolved */
import { useContext } from "react";
import CesuSrc from "@/assets/images/banners/cesu-rc.svg";
// import FirstTimeSrc from "@/assets/images/banners/tax-credit-bg.svg";
import AppRcSrc from "@/assets/images/banners/app-rc.svg";
import FirstTimeRcSrc from "@/assets/images/banners/first-time-rc.png";
import NewBabySittingBgSrc from "@/assets/images/banners/new-baby-sitting-bg.svg";
import NewBabySittingRcSrc from "@/assets/images/banners/new-baby-sitting-rc.svg";
import ReferralSrc from "@/assets/images/banners/referral-bg.svg";
import TaxesSrc from "@/assets/images/banners/tax-credit-bg.svg";
import GiftCardSrc from "@/assets/images/banners/gift-card-bg.svg";
import TaxesRcSrc from "@/assets/images/banners/tax-credit-rc.svg";
import { Block } from "@/components";
import { getStoreBadgesSrc } from "@/utils/get-store-badges-src";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  ACCOUNT_TAXES_PATH,
  REFERRAL_PATH,
} from "../../pages/dashboard/config/routes";
import polyglot from "../../utils/polyglot";
import { DashboardContext } from "../contexts";
import useBreakpoints from "./useBreakpoints";

export default function useBanners() {
  const { public_pages } = useContext(DashboardContext);
  const breakpoints = useBreakpoints();

  return {
    gift_card: {
      backgroundImage: GiftCardSrc,
      title: "Une carte cadeau pour vos proches",
      subtitle:
        "En voilà un super cadeau : du temps libre pour vos proches ou vos collègues ",
      href: ACCOUNT_TAXES_PATH,
      accent: "dark",
      buttonTitle: polyglot.t("common.discover"),
      event: "home_tax_credit",
    },
    ...(polyglot.country === "fr"
      ? {
          taxes: {
            backgroundImage: TaxesSrc,
            backgroundColor: colors.primaryLight,
            rightBackgroundImage: TaxesRcSrc,
            title: "Bénéficiez du crédit d’impôt",
            subtitle: "Réduisez le coût de vos services à domicile.",
            href: ACCOUNT_TAXES_PATH,
            accent: "light",
            buttonTitle: polyglot.t("common.discover"),
            event: "home_tax_credit",
          },
        }
      : []),
    download_app: {
      rightBackgroundImage: AppRcSrc,
      backgroundColor: colors.primary,
      title: polyglot.t("downloadAppModal.title"),
      subtitle: polyglot.t("downloadAppModal.body"),
      href: public_pages.mobile_app,
      accent: "dark",
      bodyFontSize: "13px",
      // titleFontSize: "20px",
      event: "download_app",
      closable: true,
      renderButton: () =>
        breakpoints.get({
          xs: (
            <Block display="flex" gap={spacings.xs}>
              <img
                alt="Apple store"
                src={getStoreBadgesSrc().ios}
                css={`
                  height: ${sizes.size20};
                `}
              />
              <img
                alt="Google play"
                src={getStoreBadgesSrc().android}
                css={`
                  height: ${sizes.size20};
                `}
              />
            </Block>
          ),
          md: (
            <Block display="flex" gap={spacings.xs}>
              <img
                alt="Apple store"
                src={getStoreBadgesSrc().ios}
                css={`
                  height: ${sizes.size36};
                `}
              />
              <img
                alt="Google play"
                src={getStoreBadgesSrc().android}
                css={`
                  height: ${sizes.size36};
                `}
              />
            </Block>
          ),
        }),
    },
    new_baby_sitting: {
      rightBackgroundImage: NewBabySittingRcSrc,
      backgroundImage: NewBabySittingBgSrc,
      backgroundColor: colors.pink500,
      title: polyglot.t("banners.baby_sitting_new"),
      subtitle: polyglot.t("banners.baby_sitting_book_baby_sitter"),
      href: public_pages.baby_sitting,
      bodyFontSize: "13px",
      accent: "light",
      event: "new_baby_sitting",
      closable: true,
    },
    first_time: {
      rightBackgroundImage: FirstTimeRcSrc,
      backgroundColor: colors.primary500,
      title: polyglot.t("banners.it_is_your_first_name"),
      subtitle: polyglot.t("banners.discover_what_we_can_do"),
      href: public_pages.who_are_we,
      event: "home_first_time_banner",
    },
    referral: {
      backgroundImage: ReferralSrc,
      backgroundColor: colors.turquoise700,
      title: polyglot.t("referral.banner_title"),
      subtitle: polyglot.t("referral.banner_subtitle"),
      href: REFERRAL_PATH,

      event: "home_referral",
    },
    ...(polyglot.country === "fr"
      ? {
          cesu: {
            rightBackgroundImage: CesuSrc,
            backgroundColor: colors.primaryLight,
            rightBackgroundColor: colors.primary,
            title: polyglot.t("banners.pay_with_cesu"),
            subtitle: polyglot.t("banners.book_jobbers_with_cesu"),
            href: public_pages.cesu,
            accent: "light",
            event: "home_cesu",
          },
        }
      : []),
  };
}
