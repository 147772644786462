import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelect, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

export function useCategory1013() {
  const getNbHours = (values) => values.nh_specific;

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.what_surface_aera_to_be_placed"),
      options: [
        { value: 1.0, label: "3 m²" },
        { value: 2.0, label: "6 m²" },
        { value: 3.0, label: "9 m²" },
        { value: 4.0, label: "12 m²" },
        { value: 5.0, label: "15 m²" },
        { value: 6.0, label: "18 m²" },
        { value: 7.0, label: "21 m²" },
        { value: 8.0, label: "24 m²" },
        { value: 9.0, label: "27 m²" },
        { value: 10.0, label: "30 m²" },
        { value: 11.0, label: "33 m²" },
        { value: 12.0, label: "36 m²" },
        { value: 13.0, label: "39 m²" },
        { value: 14.0, label: "42 m²" },
        { value: 15.0, label: "45 m²" },
        { value: 16.0, label: "48 m²" },
        { value: 17.0, label: "51 m²" },
        { value: 18.0, label: "54 m²" },
        { value: 19.0, label: "57 m²" },
        { value: 20.0, label: "60 m²" },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: nhConfig.nh_specific.label,
      validationSchema: yup.object().shape({
        nh_specific: yup.number().required(),
      }),
      View: () => (
        <StyledFormControl>
          <FormSelect
            placeholder={polyglot.t("categories.select_surface_placeholder")}
            name="nh_specific"
            options={nhConfig.nh_specific.options}
          />
        </StyledFormControl>
      ),
    },
  ];

  return {
    pages,
    initialValues: { nh_specific: "" },
    version: 1.2,
    getNbHours,
    config: {
      multipleJobbersAllowed: true,
    },
  };
}
