import { useFormikContext } from "formik";
import styled from "styled-components";
import { spacings } from "../../assets/themes";
import { Block, Button, Icon, Popover } from "../../components";
import polyglot from "../../utils/polyglot";
import PaymentMethodsUpdated from "../common/payment-methods";
import { getPaymentSummaryItems } from "../common/payment-methods/utils/get-payment-summary-items";
import { useGetPricing } from "./useGetPricing";

const StyledOverlayButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  button {
    cursor: not-allowed;
  }
  * {
    height: 100%;
  }
`;

const StyledOverlayButton = styled.button`
  z-index: 9;
  border: 0;
  background: none;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const BookingSubmit = ({ isLoading, handleSubmit }) => {
  const { values, isValid, ...formikUtils } = useFormikContext();
  const pricing = useGetPricing();

  return (
    <div>
      <Block marginTop={spacings.m} position="relative">
        {!isValid && !isLoading && (
          <StyledOverlayButtonWrapper>
            <Popover.Enhancer
              onMouseLeaveDelay={0}
              content={() => (
                <Popover.Elem.Tooltip>
                  <Block display="flex" alignItems="center">
                    <Icon.Large
                      name="exclamation-circle"
                      css={`
                        margin-right: ${spacings.m};
                      `}
                    />
                    {polyglot.t("booking.you_need_to_add_payment_method")}
                  </Block>
                </Popover.Elem.Tooltip>
              )}
            >
              <StyledOverlayButton />
            </Popover.Enhancer>
          </StyledOverlayButtonWrapper>
        )}
        <PaymentMethodsUpdated.PayButtonEnhancer
          block
          textType="book"
          disabled={!isValid}
          amount={pricing.total}
          lineItems={getPaymentSummaryItems({
            ...pricing,
            voucher_code: values.voucher_code,
          })}
          isLoading={isLoading}
          onPaymentAuthorized={({ token, applePaySession }) =>
            handleSubmit(
              {
                ...values,
                token,
                applePaySession,
              },
              formikUtils
            )
          }
        >
          <Button.Large
            block
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
          >
            {polyglot.t("common.booking")}
          </Button.Large>
        </PaymentMethodsUpdated.PayButtonEnhancer>
      </Block>
    </div>
  );
};
export default BookingSubmit;
