import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelect, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = {};

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory4010() {
  const initialValues = {};

  const getNbHours = (values) => values.nh_specific;

  const nhConfig = {
    nh_specific: {
      options: [
        { value: 2.5, label: "< 35 m²" },
        { value: 3.0, label: "35 m²" },
        { value: 3.5, label: "45 m²" },
        { value: 4.0, label: "55 m²" },
        { value: 4.5, label: "65 m²" },
        { value: 5.0, label: "75 m²" },
        { value: 5.5, label: "85 m²" },
        { value: 6.0, label: "95 m²" },
        { value: 6.5, label: "105 m²" },
        { value: 7.0, label: "115 m²" },
        { value: 7.5, label: "125 m²" },
        { value: 8.0, label: "135 m²" },
        { value: 8.5, label: "145 m²" },
        { value: 9.0, label: "155 m²" },
        { value: 9.5, label: "165 m²" },
        { value: 10.0, label: "175 m²" },
        { value: 10.5, label: "185 m²" },
        { value: 11.0, label: "195 m²" },
        { value: 11.5, label: "> 195 m²" },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_surface_to_clean"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl>
            <FormSelect
              placeholder={polyglot.t("categories.select_surface_placeholder")}
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    config,
  };
}
