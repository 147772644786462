import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { api } from "../../utils";
import getCSRFToken from "../../utils/csrf";

export const usePostLogin = () =>
  useMutation((values) => api.post("/sessions", values));

export const useSignUp = () =>
  useMutation((values) => api.post("/users", values));

export const useSession = () =>
  useMutation((values) => api.post("/sessions", values));

export const useFbLogin = () =>
  useMutation((params) => api.get("/facebook/auth", { params }));

export const useFbConfirm = () =>
  useMutation((values) =>
    api.put(`/facebook/${values.user?.id}/confirm_user`, ...values)
  );

export const useFbCreate = () =>
  useMutation((values) => api.post("/facebook/create_user", values));

// --- DEPRECATED — USE HOOK INSTEAD --- //

export const signUp = (data) => {
  getCSRFToken();
  return axios.post("/users", data);
};
