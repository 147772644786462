import React, { useEffect, useState } from "react";
import { radius, sizes, spacings } from "../../assets/themes";
import { Block, FilePicker } from "../../components";
import {
  useDeletePhoto,
  usePostPhotoToToken,
} from "../../modules/routes/job-routes";
import { MAX_JOB_PHOTOS } from "../../utils";

const EnhancedFilePicker = ({
  size,
  value,
  token: _token,
  onChange,
  onRemove,
  ...rest
}) => {
  const uploadPhoto = usePostPhotoToToken();
  const removePhoto = useDeletePhoto();
  const [upload, setUpload] = useState(value);
  const [token, setToken] = useState(_token);

  const handleRemove = () => {
    removePhoto.mutate({ id: token });
    if (onChange) onRemove({ token });
    setUpload(null);
    setToken(null);
  };

  useEffect(() => {
    if (uploadPhoto.isSuccess) {
      const token = uploadPhoto.data.data.data.id;
      const file = uploadPhoto.variables.attachment;
      setUpload(file);
      setToken(token);
      if (onChange) onChange({ token, file });
    }
  }, [uploadPhoto.isSuccess]);

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    uploadPhoto.mutate({
      attachment: file,
    });
    // eslint-disable-next-line no-param-reassign
    event.currentTarget.value = "";
  };

  return (
    <FilePicker
      value={upload}
      style={{ width: size, height: size }}
      accept="image/jpeg,image/png"
      title={false}
      progressAmount={uploadPhoto.progress}
      css={`
        margin-right: ${spacings.s};
        margin-bottom: ${spacings.s};
      `}
      onRemove={handleRemove}
      onChange={handleFileChange}
      {...rest}
    />
  );
};

// const setDefaultValue = (defaultValues) => {
//   const arr = new Array(MAX_JOB_PHOTOS).fill("");
//   defaultValues.forEach((dv, index) => {
//     arr[index] = dv;
//   });
// };

const PhotosUpload = ({
  size = sizes.size96,
  onChange,
  max = MAX_JOB_PHOTOS,
  value = [],
  initialSrc = [],
  tokens: _tokens = [],
  ...rest
}) => {
  const [uploads, setUploads] = useState(value);
  const [tokens, setTokens] = useState(_tokens);

  const handleFileChange = ({ token, file }) => {
    setTokens((s) => [...s, token]);
    setUploads((s) => [...s, file]);
  };

  useEffect(() => {
    if (onChange) onChange({ tokens, uploads });
  }, [tokens]);

  const handleFileRemove = ({ token }) => {
    const index = tokens.findIndex((up) => up === token);
    setTokens((s) => s.filter((_, i) => i !== index));
    setUploads((s) => s.filter((_, i) => i !== index));
  };

  return (
    <Block
      display="flex"
      flexWrap="wrap"
      marginBottom={`calc(${spacings.s} * -1)`}
    >
      {initialSrc.map((src, index) => (
        <img
          key={`photos-job-${index}`}
          src={src}
          style={{
            borderRadius: radius.m,
            width: size,
            height: size,
            objectFit: "cover",
          }}
          css={`
            margin-right: ${spacings.s};
          `}
          alt=""
        />
      ))}
      {new Array(max - initialSrc.length).fill("").map((_, index) => (
        <EnhancedFilePicker
          key={`job-photos-file-picker-${index}`}
          value={uploads[index]}
          token={tokens[index]}
          onChange={handleFileChange}
          onRemove={handleFileRemove}
          size={size}
          {...rest}
        />
      ))}
    </Block>
  );
};

export default PhotosUpload;
