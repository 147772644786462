import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useState } from "react";
import Lottie from "react-lottie-player";

import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  ConditionnalWrapper,
  H3,
  H4,
  Modal,
} from "../../../components";
import { useBreakpoints } from "../../../modules/hooks";

const AnimatedSubtitle = ({ onAnimationComplete, phrases }) => {
  const [currentPhrase, setCurrentPhrase] = useState(0);
  const breakpoints = useBreakpoints();

  const slideVariants = {
    initial: { y: "100%" },
    animate: { y: "0%" },
    exit: { y: "-100%" },
  };

  const handleAnimationComplete = () => {
    const newIndex = currentPhrase + 1;
    if (newIndex < phrases.length) {
      setCurrentPhrase(newIndex);
    } else {
      onAnimationComplete();
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleAnimationComplete();
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [currentPhrase]);

  return (
    <Block
      // get real height size
      height="calc(var(--font-line-height-paragraph) * var(--font-size-body16))"
      overflow="hidden"
    >
      <AnimatePresence initial={false} exitBeforeEnter>
        <motion.div
          key={phrases[currentPhrase]}
          variants={slideVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          onAnimationComplete={handleAnimationComplete}
        >
          {breakpoints.get({
            xs: (
              <Body16 color={colors.muted} align="center">
                {phrases[currentPhrase]}
              </Body16>
            ),
            sm: (
              <Body18 color={colors.muted} align="center">
                {phrases[currentPhrase]}
              </Body18>
            ),
          })}
        </motion.div>
      </AnimatePresence>
    </Block>
  );
};

const RecommendationLoading = ({
  animationData,
  phrases,
  title,
  onAnimationComplete,
}) => {
  const [isMobile, setIsMobile] = useState(false);
  const breakpoints = useBreakpoints();

  useLayoutEffect(() => {
    setIsMobile(breakpoints.get({ xs: true, sm: false }));
  }, [breakpoints.get({ xs: true, sm: false })]);

  return (
    <ConditionnalWrapper
      condition={isMobile}
      wrapper={(children) => (
        <Modal.FullScreen isOpen hideBackdrop animate={false}>
          {children}
        </Modal.FullScreen>
      )}
    >
      <Block
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        height="100%"
      >
        <Lottie
          loop
          animationData={animationData}
          play
          style={{ height: breakpoints.get({ xs: 80, sm: 90 }) }}
        />
        <Block
          marginTop={{ xs: spacings.m, sm: spacings.ml }}
          marginBottom={spacings.s}
        >
          {breakpoints.get({
            xs: <H4 align="center">{title}</H4>,
            sm: <H3 align="center">{title}</H3>,
          })}
        </Block>
        <AnimatedSubtitle
          onAnimationComplete={onAnimationComplete}
          phrases={phrases}
        />
      </Block>
    </ConditionnalWrapper>
  );
};
export default RecommendationLoading;
