import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { spacings } from "../assets/themes";
import {
  Accordion,
  Block,
  Body16,
  Container,
  Divider,
  HistoryScrollToTop,
  List,
} from "../components";
import { CESU_ADD_END_POINT } from "../config/path";
import BaseApp from "../modules/BaseApp";
import CesuButtons from "../pages/CESU/CesuButtons";
import CesuInputs from "../pages/CESU/manualInput/CesuInputs";
import polyglot from "../utils/polyglot";
import CesuProvider from "../modules/providers/CesuProvider";

const CesuButtonsApp = (props, rails) => () =>
  (
    <BaseApp>
      <Container.Small>
        <Block marginY={spacings.l}>
          <Router basename={CESU_ADD_END_POINT}>
            <HistoryScrollToTop />
            <CesuProvider>
              <Switch>
                <Route exact path="/">
                  <CesuButtons {...props} />
                </Route>
                <Route exact path="/manual-entries">
                  <CesuInputs
                    show_new_fees_information={props.show_new_fees_information}
                  />
                </Route>
              </Switch>
            </CesuProvider>
          </Router>
        </Block>
        <Block paddingTop={spacings.m}>
          <Divider.Cell />
        </Block>
        <Block paddingBottom={spacings.xl}>
          <List.Header>{polyglot.t("common.how_it_works")}</List.Header>
          <Accordion.Group>
            {Array.from({ length: 4 }).map((_, i) => (
              <Accordion
                key={`accordion-faq-cesu-${i + 1}`}
                title={polyglot.t(`cesu.new.faq.title${i + 1}`)}
              >
                <Body16>{polyglot.t(`cesu.new.faq.content${i + 1}`)}</Body16>
              </Accordion>
            ))}
          </Accordion.Group>
        </Block>
      </Container.Small>
    </BaseApp>
  );

export default CesuButtonsApp;
