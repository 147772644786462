import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { Button, Container, Modal } from "../../components";
import { putCancelRelation } from "../../modules/routes";
import polyglot from "../../utils/polyglot";

const CancelSubscription = ({ id }) => {
  const history = useHistory();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setModalIsOpen(false);
  };
  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = putCancelRelation({ id, cancel_reason: values.cancel_reason });
    res
      .then(({ data }) => {
        window.location.href = history.createHref({ pathname: "/" });
      })
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
  };
  return (
    <Container.Medium className="py-5">
      <Button.Link onClick={() => history.push("/")} className="mb-4">
        <i className="icon-arrow-left mr-2" />
        Retour
      </Button.Link>
      <h1>Annuler mon abonnement</h1>
      <p>Nous sommes désolés de vous voir annuler votre abonnement.</p>
      <Formik
        onSubmit={handleSubmit}
        validateOnMount
        initialValues={{ cancel_reason: "" }}
        validationSchema={yup.object().shape({
          cancel_reason: yup.string().required(),
        })}
      >
        {({ isValid }) => (
          <Form>
            <div className="form-group">
              <label>Indiquez la raison de votre annulation</label>
              <Field name="cancel_reason">
                {({ field }) => (
                  <textarea
                    className="form-control"
                    placeholder={polyglot.t("common.placeholder.textarea")}
                    {...field}
                  />
                )}
              </Field>
            </div>
            <Button.Large
              className="bg-danger"
              onClick={() => setModalIsOpen(true)}
              disabled={!isValid}
            >
              Annuler mon abonnement
            </Button.Large>
          </Form>
        )}
      </Formik>
      <Modal.XSmall isOpen={modalIsOpen} onClose={handleClose}>
        <Modal.Item.Header
          title="Vous allez annuler votre abonnement"
          subtitle="Nous supprimerons votre service récurrent mais vos documents seront conservés."
          onClose={handleClose}
        />
        <Modal.Item.Wrapper>
          <Button.Large
            block
            className="bg-danger"
            onClick={handleSubmit}
            isLoading={isLoading}
          >
            Confirmer l'annulation
          </Button.Large>
          <Button.Large
            kind="minimal"
            block
            onClick={handleClose}
            className="mt-2"
          >
            Revenir en arrière
          </Button.Large>
        </Modal.Item.Wrapper>
      </Modal.XSmall>
    </Container.Medium>
  );
};

export default CancelSubscription;
