import { useHistory, useLocation } from "react-router-dom";
import { sizes, spacings } from "@/assets/themes";
import {
  Block,
  Divider,
  Icon,
  InfiniteScrollEnhancer,
  Selectable,
} from "@/components";
import { DIVIDER, SELECTABLE } from "@/components/Styles/variants";
import { useRefetchOnHistoryEvent } from "@/modules/hooks";
import { useGetJobs } from "@/modules/routes/dashboard-routes";
import polyglot from "@/utils/polyglot";
import PageTitle from "./PageTitle";
import { DASHBOARD_PATH } from "./config/routes";
import ActiveJobs from "./jobs/ActiveJobs";
import ClosedJobs from "./jobs/ClosedJobs";
import YoojoDirectJobs from "./jobs/YoojoDirectJobs";
import JobsSkeleton from "./skeletons/JobsSkeleton";

const Jobs = ({ config }) => {
  const { search } = useLocation();
  const history = useHistory();
  const closedJobsIsActive = search === "?filter=closed";
  const yoojoDirectJobsIsActive = search === "?filter=yoojo_direct";
  const activeJobsIsActive = !closedJobsIsActive && !yoojoDirectJobsIsActive;
  const getJobs = useGetJobs();
  useRefetchOnHistoryEvent(getJobs);

  return (
    <div>
      <PageTitle>{config.title}</PageTitle>
      {getJobs.data?.pages[0]?.data?.has_yoojo_direct_jobs && (
        <Block
          display="flex"
          flexWrap="nowrap"
          overflowX="auto"
          marginTop={{ xs: spacings.s, md: spacings.m }}
          marginX={`calc(${spacings.m} * -1)`}
          paddingX={spacings.m}
          css={`
            &::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
          `}
          alignItems="center"
        >
          <Selectable.Small
            shape={SELECTABLE.SHAPE.CIRCLE}
            onChange={() => history.replace(DASHBOARD_PATH)}
            checked={activeJobsIsActive}
            css={`
              margin-right: ${spacings.s};
            `}
          >
            {polyglot.t("routes.jobs")}
          </Selectable.Small>
          {polyglot.country === "fr" && (
            <>
              <Block display="flex" height={sizes.size36}>
                <Divider.Cell
                  orientation={DIVIDER.ORIENTATION.VERTICAL}
                  css={`
                    margin-right: ${spacings.s};
                    margin-top: ${spacings.s};
                    margin-bottom: ${spacings.s};
                  `}
                />
              </Block>
              <Selectable.Small
                shape={SELECTABLE.SHAPE.CIRCLE}
                checked={yoojoDirectJobsIsActive}
                css={`
                  margin-top: ${spacings.s};
                  margin-bottom: ${spacings.s};
                `}
                LeftComponent={() => <Icon.Medium name="yoojo-direct" />}
                onChange={() =>
                  history.replace({
                    pathname: DASHBOARD_PATH,
                    search: "?filter=yoojo_direct",
                  })
                }
              >
                {polyglot.t("routes.yoojo_direct")}
              </Selectable.Small>
            </>
          )}
        </Block>
      )}
      {yoojoDirectJobsIsActive && <YoojoDirectJobs />}
      {activeJobsIsActive && (
        <>
          <InfiniteScrollEnhancer query={getJobs} unionBy="done_jobs">
            {({ isLoading, data }) =>
              !isLoading ? (
                <>
                  <ActiveJobs data={data.active_jobs} />
                  <ClosedJobs data={data.done_jobs} />
                </>
              ) : (
                <JobsSkeleton />
              )
            }
          </InfiniteScrollEnhancer>
        </>
      )}
    </div>
  );
};

export default Jobs;
