import React from "react";
import styled, { css } from "styled-components";
import { breakpointsEnhancer } from "./Styles/Helper";

const TextBase = styled.span`
  display: block;
  ${({ align }) =>
    align &&
    css`
      ${breakpointsEnhancer("text-align", align)}
    `};
  ${({ color }) =>
    color &&
    css`
      ${breakpointsEnhancer("color", color)}
    `};
  ${({ textBreak }) =>
    textBreak &&
    css`
      overflow-wrap: break-word;
    `};
  ${({ numberOfLines }) =>
    numberOfLines &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: ${numberOfLines};
      -webkit-box-orient: vertical;
    `};
`;

const HeadingBase = styled(TextBase)`
  font-weight: var(--font-weight-bold);
  line-height: var(--font-line-height-heading);
  margin-bottom: 0;
`;

const ParagraphBase = styled(TextBase)`
  line-height: var(--font-line-height-paragraph);
  margin-bottom: 0;
  ${({ strong }) =>
    strong &&
    css`
      font-weight: var(--font-weight-medium);
    `}
`;

const StyledBody20 = styled(ParagraphBase)`
  font-size: var(--font-size-body20);
`;
const StyledBody18 = styled(ParagraphBase)`
  font-size: var(--font-size-body18);
`;
const StyledBody16 = styled(ParagraphBase)`
  font-size: var(--font-size-body16);
`;
const StyledBody14 = styled(ParagraphBase)`
  font-size: var(--font-size-body14);
`;
const StyledCaption = styled(ParagraphBase)`
  font-size: var(--font-size-caption);
`;

const StyledDisplay1 = styled(HeadingBase)`
  font-size: var(--font-size-display1);
  font-weight: var(--font-weight-bold);
`;

const StyledDisplay2 = styled(HeadingBase)`
  font-size: var(--font-size-display2);
  font-weight: var(--font-weight-bold);
`;

const StyledDisplay3 = styled(HeadingBase)`
  font-size: var(--font-size-display3);
  font-weight: var(--font-weight-bold);
`;

const StyledH1 = styled(HeadingBase)`
  font-size: var(--font-size-h1);
`;
const StyledH2 = styled(HeadingBase)`
  font-size: var(--font-size-h2);
`;
const StyledH3 = styled(HeadingBase)`
  font-size: var(--font-size-h3);
`;
const StyledH4 = styled(HeadingBase)`
  font-size: var(--font-size-h4);
`;
const StyledH5 = styled(HeadingBase)`
  font-size: var(--font-size-h5);
`;

// -------------- PARAGRAPH -------------- //

export const Body20 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledBody20 as="span" {...rest} ref={ref}>
    {children}
  </StyledBody20>
));
export const Body18 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledBody18 as="span" {...rest} ref={ref}>
    {children}
  </StyledBody18>
));
export const Body16 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledBody16 as="span" {...rest} ref={ref}>
    {children}
  </StyledBody16>
));
export const Body14 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledBody14 as="span" {...rest} ref={ref}>
    {children}
  </StyledBody14>
));
export const Caption = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledCaption {...rest} ref={ref}>
    {children}
  </StyledCaption>
));

// -------------- HEADING -------------- //

export const Display1 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledDisplay1 {...rest} ref={ref}>
    {children}
  </StyledDisplay1>
));

export const Display2 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledDisplay2 {...rest} ref={ref}>
    {children}
  </StyledDisplay2>
));

export const Display3 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledDisplay3 {...rest} ref={ref}>
    {children}
  </StyledDisplay3>
));

export const H1 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledH1 as="h1" {...rest} ref={ref}>
    {children}
  </StyledH1>
));
export const H2 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledH2 as="h2" {...rest} ref={ref}>
    {children}
  </StyledH2>
));
export const H3 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledH3 as="h3" {...rest} ref={ref}>
    {children}
  </StyledH3>
));
export const H4 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledH4 as="h4" {...rest} ref={ref}>
    {children}
  </StyledH4>
));
export const H5 = React.forwardRef(({ children, ...rest }, ref) => (
  <StyledH5 as="h5" {...rest} ref={ref}>
    {children}
  </StyledH5>
));
