import styled from "styled-components";
import { borderWidth, colors, sizes } from "../../assets/themes";

const StyledWrapper = styled.div`
  width: 100%;
  border-top: solid ${borderWidth.s} ${colors.border};
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  min-height: ${sizes.size64};
  align-items: stretch;
  background-color: ${colors.background};
`;

const BottomNavigation = ({ children }) => (
  <StyledWrapper>{children}</StyledWrapper>
);

export default BottomNavigation;
