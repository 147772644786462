import { useFormikContext } from "formik";
import { Icon } from "../../../components";
import { SELECTABLE } from "../../../components/Styles/variants";
import { useCategoryForm, useJobAvailableHours } from "../../../modules/hooks";
import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { CATEGORY_DATE_FORMAT } from "../constants";
import { getHourDiff } from "../utils";
import { FormFromTo, FormSelectables, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Hour = ({ goNext, title }) => {
  const { values, errors, touched } = useFormikContext();
  const { config } = useCategoryForm();
  const { getOptions } = useJobAvailableHours({ format: CATEGORY_DATE_FORMAT });

  const getOptHours = (date = values.date) =>
    getOptions({
      date,
      min: config.minHour,
      max: config.maxHour,
    });

  const getOptHoursEnd = (date = values.date) =>
    getOptions({
      date,
      min: config.minHour,
      max: config.maxHour,
      ignoreCurrentHour: true,
    });

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        {!config.isTimeRange && (
          <FormSelectables
            isWrap
            shape={SELECTABLE.SHAPE.CIRCLE}
            name="start_hour"
            error={errors.start_hour && touched.start_hour}
            options={getOptHours()}
            onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
          />
        )}
        {config.isTimeRange && (
          <StyledFormControl
            hint={
              !!values.start_hour.toString() &&
              !!values.end_hour.toString() &&
              `${polyglot.t("common.duration")} : ${polyglot.toDuration(
                getHourDiff(values.start_hour, values.end_hour),
                true
              )}`
            }
            error={
              (!!values.end_hour && errors.end_hour) ||
              (!!values.start_hour && errors.start_hour)
            }
          >
            <FormFromTo
              names={["start_hour", "end_hour"]}
              placeholders={["Début", "Fin"]}
              LeftComponents={[
                () => <Icon.Medium name="clock" />,
                () => <Icon.Medium name="clock-solid" />,
              ]}
              options={[getOptHours(), getOptHoursEnd()]}
            />
          </StyledFormControl>
        )}
      </StyledFormControl>
    </>
  );
};

export default Hour;
