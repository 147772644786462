import AmericanExpress from "../../../../assets/images/svg-icons/Badges/Payments/American Express.svg";
import Maestro from "../../../../assets/images/svg-icons/Badges/Payments/Maestro.svg";
import Paypal from "../../../../assets/images/svg-icons/Badges/Payments/Paypal.svg";
import Ideal from "../../../../assets/images/svg-icons/Badges/Payments/Ideal.svg";
import ApplePay from "../../../../assets/images/svg-icons/Badges/Payments/Apple Pay.svg";
import GooglePay from "../../../../assets/images/svg-icons/Badges/Payments/Google Pay.svg";
import Placeholder from "../../../../assets/images/svg-icons/Badges/Payments/Placeholder.svg";
import IBAN from "../../../../assets/images/svg-icons/Badges/Payments/RIB.svg";
import VisaMastercard from "../../../../assets/images/svg-icons/Badges/Payments/VisaMastercard.svg";
import {
  AMEX,
  MAESTRO,
  MASTERCARD,
  PAYPAL,
  VISA,
  VISA_MASTERCARD,
  APPLE_PAY,
  GOOGLE_PAY,
  IDEAL,
} from "../constants";
// import Mastercard from "../../../../assets/images/svg-icons/Badges/Payments/Mastercard.svg";
// import Visa from "../../../../assets/images/svg-icons/Badges/Payments/Visa.svg";

const badges = {
  [AMEX]: AmericanExpress,
  [MAESTRO]: Maestro,
  [MASTERCARD]: VisaMastercard,
  [PAYPAL]: Paypal,
  [IBAN]: IBAN,
  [VISA]: VisaMastercard,
  [VISA_MASTERCARD]: VisaMastercard,
  [IDEAL]: Ideal,
  [APPLE_PAY]: ApplePay,
  [GOOGLE_PAY]: GooglePay,
  placeholder: Placeholder,
};

export function getPaymentMethodBadge(value) {
  return (value && badges[value.toLowerCase()]) || badges.placeholder;
}
