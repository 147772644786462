import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Icon from "../Icon";
import Info from "../Info";
import { INFO } from "../Styles/variants";
import { Body14 } from "../Text";

const MAX_LEVEL = 3;
const FILL_ICON_NAME = "leaf-solid";
const EMPTY_ICON_NAME = "leaf";
const MARGIN_ICON_DISTANCE = -3;

const RenderIcons = (level) => (
  <Block
    marginLeft={`calc(${MARGIN_ICON_DISTANCE} * -1)`}
    display="flex"
    alignItems="center"
  >
    {Array.from({ length: MAX_LEVEL }, (_, i) => (
      <Block marginLeft={MARGIN_ICON_DISTANCE}>
        <Icon.Medium
          key={i}
          name={i < level ? FILL_ICON_NAME : EMPTY_ICON_NAME}
          color={i < level ? colors.success600 : colors.mutedLight}
        />
      </Block>
    ))}
  </Block>
);

const EcoDistanceTag = ({ level }) => (
  <Block display="flex" alignItems="center">
    <Block gap={spacings.xs} display="flex" alignItems="center">
      {RenderIcons(level)}
      <Body14 strong color={colors.success600}>
        {polyglot.t("offer_badges.kinds.eco_distance")}
      </Body14>
    </Block>
    <Info size={INFO.SIZE.SMALL}>
      {polyglot.t(`offer_badges.kinds.eco_distance_level_${level}_description`)}
    </Info>
  </Block>
);
export default EcoDistanceTag;
