import { MemoryRouter } from "react-router-dom";
import JobberSearch from "@/pages/JobberSearch";
import BaseApp from "../modules/BaseApp";

const JobberSearchApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <MemoryRouter>
        <JobberSearch {...props} rails={rails} />
      </MemoryRouter>
    </BaseApp>
  );

export default JobberSearchApp;
