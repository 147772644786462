import { spacings } from "../../assets/themes";
import { useBreakpoints } from "../../modules/hooks";
import { H4, H5 } from "../Text";

const Title = ({ children, className, style }) => {
  const breakpoints = useBreakpoints();
  return breakpoints.get({
    xs: (
      <H5 style={{ marginBottom: spacings.xs, ...style }} className={className}>
        {children}
      </H5>
    ),
    sm: (
      <H4 style={{ marginBottom: spacings.xs, ...style }} className={className}>
        {children}
      </H4>
    ),
  });
};

export default Title;
