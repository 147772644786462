import React from "react";
import ChatNoResult from "../../assets/images/svg-icons/NoResults/chat.svg";
import { spacings } from "../../assets/themes";
import {
  Block,
  Button,
  InfiniteScrollEnhancer,
  List,
  NoResult,
} from "../../components";
import { useAskService, useRefetchOnHistoryEvent } from "../../modules/hooks";
import { useGetInboxes } from "../../modules/routes/dashboard-routes";
import polyglot from "../../utils/polyglot";
import ChatBox from "../chatBox/ChatBox";
import * as Layouts from "./Layouts";
import PageTitle from "./PageTitle";
import ConversationsSkeleton from "./skeletons/ConversationsSkeleton";

const getDocumentTitle = (users, title) => {
  const u = users.length > 0 ? users.map((u) => u.first_name).join(", ") : null;
  return `${u ? `Messages avec ${u}` : "Messages"} (${title})`;
};

const Inboxes = ({ config }) => {
  const askService = useAskService();
  const getInboxes = useGetInboxes();
  useRefetchOnHistoryEvent(getInboxes);
  return (
    <Layouts.Messages
      path={config.path}
      LeftMenu={() => (
        <>
          <Block marginX={spacings.s}>
            <PageTitle>{config.title}</PageTitle>
          </Block>
          <InfiniteScrollEnhancer
            query={getInboxes}
            debug
            unionBy="archived_discussions"
          >
            {({ data, isLoading }) =>
              !isLoading ? (
                <>
                  {data.discussions?.length > 0 ? (
                    <Block as={List.Header} paddingX={spacings.s}>
                      {polyglot.t("common.active")}
                    </Block>
                  ) : (
                    <NoResult
                      artwork={ChatNoResult}
                      title={polyglot.t("conversations.no_conversations")}
                      subtitle={polyglot.t(
                        "conversations.no_conversations_description"
                      )}
                    >
                      <Button.Medium onClick={() => askService.show()}>
                        {polyglot.t("jobs.ask_service")}
                      </Button.Medium>
                    </NoResult>
                  )}
                  {data.discussions.map((discussion) => (
                    <Layouts.ChatThumbnail
                      {...discussion}
                      documentTitle={getDocumentTitle(
                        discussion.users,
                        discussion.discussable_title
                      )}
                      to={`${config.path}/${discussion.id}`}
                      key={`discussion-${discussion.id}`}
                    />
                  ))}
                  {data.archived_discussions?.length > 0 && (
                    <Block as={List.Header} paddingX={spacings.s}>
                      {polyglot.t("common.closed_f")}
                    </Block>
                  )}
                  {data.archived_discussions.map((discussion) => (
                    <Layouts.ChatThumbnail
                      {...discussion}
                      documentTitle={getDocumentTitle(
                        discussion.users,
                        discussion.discussable_title
                      )}
                      to={`${config.path}/${discussion.id}`}
                      key={`archived_discussion-${discussion.id}`}
                    />
                  ))}
                </>
              ) : (
                <ConversationsSkeleton />
              )
            }
          </InfiniteScrollEnhancer>
        </>
      )}
    >
      <ChatBox />
    </Layouts.Messages>
  );
};

export default Inboxes;
