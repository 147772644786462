import { useFormikContext } from "formik";
import { Block } from "../../../../components";
import polyglot from "../../../../utils/polyglot";
import { FormInput, StyledFormControl } from "../fields";
import StepTitle from "../StepTitle";

const FirstName = () => {
  const { errors } = useFormikContext();

  return (
    <>
      <StepTitle>{polyglot.t("categories.what_is_your_first_name")}</StepTitle>
      <StyledFormControl error={errors.user?.first_name}>
        <FormInput
          name="user.first_name"
          autoComplete="given-name"
          placeholder={polyglot.t("common.placeholder.first_name")}
        />
      </StyledFormControl>
      {/* //Autofill last_name for next step */}
      <Block height={0} overflow="hidden">
        <FormInput name="user.last_name" autoComplete="family-name" />
      </Block>
    </>
  );
};

export default FirstName;
