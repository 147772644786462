import { colors, spacings } from "@/assets/themes";
import { Block, Icon, List, Shape } from "@/components";
import { LIST } from "@/components/Styles/variants";

const JobberInfoItem = ({ icon, children }) => (
  <List.Item
    divider={false}
    withGutters={false}
    size={LIST.SIZE.COMPACT}
    LeftComponent={() => (
      <Block marginY={`calc(${spacings.xs} * -1)`}>
        <Shape.Small backgroundColor={colors.transparent} color={colors.body}>
          <Icon.Large name={icon} />
        </Shape.Small>
      </Block>
    )}
  >
    {children}
  </List.Item>
);

export default JobberInfoItem;
