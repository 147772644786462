import { useFormikContext } from "formik";
import { useEffect } from "react";
import { colors } from "../../../assets/themes";
import { Body14, Button, FilePickerEnhancer, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { usePostTicketAttachment } from "../../../modules/routes/dashboard-routes";
import polyglot from "../../../utils/polyglot";
import TicketChatInputUploads from "./TicketChatInputUploads";
import JobCommentChatInput from "@/pages/Job/JobCommentChatInput";

const TicketChatInput = ({ disabled, isLoading }) => {
  const { values, setFieldValue } = useFormikContext();
  const uploadAttachment = usePostTicketAttachment();

  const addToken = (token) => {
    const newVal = values.uploads.map((up) => {
      if (up.token === null) {
        return { ...up, token };
      }
      return up;
    });
    setFieldValue("uploads", newVal);
  };

  useEffect(() => {
    const token = uploadAttachment.data?.data?.data?.token;
    if (token) {
      addToken(token);
    }
  }, [uploadAttachment.isSuccess]);

  const addUpload = (value) => {
    setFieldValue("uploads", [...values.uploads, value]);
  };

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    addUpload({ token: null, file });
    uploadAttachment.mutate({
      attachment: file,
    });
    event.currentTarget.value = "";
  };

  const handleRemove = (token) => {
    setFieldValue(
      "uploads",
      values.uploads.filter((up) => up.token !== token)
    );
  };

  return (
    <>
      <JobCommentChatInput
        name="body"
        value={values.body}
        placeholder={polyglot.t("tickets.message_placeholder")}
        disabled={disabled}
        isLoading={isLoading}
        content={() =>
          values.uploads.length > 0 && (
            <TicketChatInputUploads
              onRemove={handleRemove}
              progress={uploadAttachment.progress}
              uploads={values.uploads}
            />
          )
        }
        onChange={(e) => setFieldValue("body", e.target.value)}
        LeftComponent={() => (
          <FilePickerEnhancer
            name="avatar"
            disabled={uploadAttachment.isLoading}
            accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
            onChange={(e) => {
              handleFileChange(e);
            }}
          >
            <Button.Small
              disabled={uploadAttachment.isLoading}
              kind={BUTTON.KIND.MINIMAL}
              shape={BUTTON.SHAPE.CIRCLE}
              css="background-color: transparent!important"
            >
              <Icon.Large name="plus" color={colors.muted} />
            </Button.Small>
          </FilePickerEnhancer>
        )}
      >
        {disabled && (
          <Body14
            align="center"
            color={colors.muted}
            css={`
              width: 100%;
            `}
          >
            {polyglot.t("tickets.resolved")}
          </Body14>
        )}
      </JobCommentChatInput>
    </>
  );
};

export default TicketChatInput;
