import { useState } from "react";
import OfferJobberRating from "@/components/JobOfferItem/OfferJobberRating";
import { colors, sizes, spacings } from "../../../assets/themes";
import { Avatar, Block, Body14, List } from "../../../components";
import { LIST } from "../../../components/Styles/variants";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import { JOBBER } from "../../../pages-admin/UserPreview/config";
import { formatDate } from "../../../utils";
import capitalize from "../../../utils/capitalize";
import polyglot from "../../../utils/polyglot";
import EditRatingModal from "@/components/JobOfferItem/EditRatingModal";

const ReminderItem = ({
  jobber,
  url,
  id,
  kind,
  date,
  job_title,
  subtitle,
  title,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <UserPreview id={jobber.id} kind={JOBBER}>
      <List.Item
        href={kind === "rate" ? undefined : url}
        onClick={kind === "rate" ? () => setModalIsOpen(true) : undefined}
        shape={LIST.SHAPE.ROUND}
        withGutters
        css={`
          background-color: ${colors.backgroundLight};
          height: 100%;
        `}
        divider={false}
        chevron
        LeftComponent={() => (
          <Block alignSelf="flex-start" marginTop={spacings.s}>
            <Avatar
              src={jobber.avatar}
              size={sizes.size36}
              name={jobber.first_name}
            />
          </Block>
        )}
      >
        {title ? (
          title()
        ) : (
          <List.Elem.Title color={colors.body} strong>
            {polyglot.t(`dashboard.reminders.${kind}.title`)}{" "}
            <strong>{jobber.first_name}</strong>
          </List.Elem.Title>
        )}
        {subtitle ? (
          subtitle()
        ) : (
          <Body14 color={colors.muted}>
            Pour "{job_title}" du {capitalize(formatDate(date, "dddd DD MMMM"))}
          </Body14>
        )}
      </List.Item>
      {kind === "rate" && modalIsOpen && (
        <EditRatingModal
          isOpen={modalIsOpen}
          isNew
          onClose={() => setModalIsOpen(false)}
          jobber={jobber}
          id={id}
        />
      )}
    </UserPreview>
  );
};

export default ReminderItem;
