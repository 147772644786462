import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { googleMapStyle } from "@/assets/themes";
import { WOOSMAP_API_KEY } from "./config";
import useWoosmap from "./useWoosmap";

const MapContext = createContext(null);
const WoosmapAPIProvider = ({ apiKey, children }) => {
  const isLoaded = useWoosmap(apiKey);

  if (!isLoaded) {
    return null;
  }
  return (
    <MapContext.Provider value={window.woosmap}>{children}</MapContext.Provider>
  );
};

const WoosmapMap = ({
  center,
  zoom,
  onApiLoaded,
  zoomControl = true,
  gestureHandling = "auto",
  disableDefaultUI = false,
  children,
}) => {
  const mapRef = useRef(null);
  const woosmap = useContext(MapContext);
  const [mapInstance, setMapInstance] = useState(null);

  useEffect(() => {
    if (mapRef.current && !mapInstance) {
      const mapOptions = {
        zoom,
        center,
        disableDefaultUI,
        zoomControl,
        gestureHandling,
        styles: googleMapStyle,
      };

      const map = new woosmap.map.Map(mapRef.current, mapOptions);

      setMapInstance(map);
      if (onApiLoaded) onApiLoaded(map);
    }
  }, [woosmap, zoom, center, zoomControl, gestureHandling, disableDefaultUI]);

  return (
    <>
      <div
        ref={mapRef}
        style={{ width: "100%", height: "100%", position: "relative" }}
        css={`
          .mapboxgl-ctrl-attrib {
            display: none;
          }
        `}
      />
      {mapInstance &&
        React.Children.map(children, (child) =>
          React.cloneElement(child, {
            map: mapInstance,
            woosmap: window.woosmap,
          })
        )}
    </>
  );
};

const Map = ({
  defaultCenter,
  defaultZoom,
  onApiLoaded,
  zoomControl,
  gestureHandling,
  disableDefaultUI,
  children,
}) => (
  <WoosmapAPIProvider apiKey={WOOSMAP_API_KEY}>
    <WoosmapMap
      center={defaultCenter}
      zoom={defaultZoom}
      onApiLoaded={onApiLoaded}
      zoomControl={zoomControl}
      gestureHandling={gestureHandling}
      disableDefaultUI={disableDefaultUI}
    >
      {children}
    </WoosmapMap>
  </WoosmapAPIProvider>
);

export default Map;
