import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { spacings } from "../../../assets/themes";
import { Block, Container, H2, Spinner } from "../../../components";

const InstantBookingLoading = ({ onAnimationEnd, steps }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const variants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  useEffect(() => {
    const to = setTimeout(() => {
      if (activeIndex < steps.length - 1) {
        setActiveIndex(activeIndex + 1);
      } else onAnimationEnd();
    }, 1500);
    return () => {
      clearInterval(to);
    };
  }, [steps, activeIndex]);

  return (
    <Container.Small>
      <Block
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="70vh"
        marginY={spacings.xl}
      >
        <Block marginBottom={spacings.l}>
          <Spinner.Large />
        </Block>
        <Block
          position="relative"
          display="flex"
          justifyContent="center"
          height={100}
          width="100%"
        >
          <motion.div
            key={`steps-loading-${activeIndex}`}
            initial="hidden"
            animate="visible"
            variants={variants}
            css="position: absolute"
          >
            <H2 align="center">{steps[activeIndex]}</H2>
          </motion.div>
        </Block>
      </Block>
    </Container.Small>
  );
};

export default InstantBookingLoading;
