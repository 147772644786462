import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { spacings } from "@/assets/themes";
import { useGetTaxCredit } from "@/modules/routes/simulators-routes";
import { Block, Body16, Button, H2, List, Stepper } from "../components";
import polyglot from "../utils/polyglot";
import CategoriesNavigationModal from "./CategoriesNavigationModal";
import TaxesCreditSimulatorSummary from "./dashboard/taxesCredit/TaxesCreditSimulatorSummary";

const TaxCreditSimulator = ({
  initial_values = {},
  url_params,
  show_cta,
  label,
  rails,
}) => {
  const initialValues = {
    price_per_hour: 12,
    nb_hours: 2,
    ...initial_values,
  };
  const formikRef = useRef();
  const getTaxesCredit = useGetTaxCredit();

  useEffect(() => {
    formikRef?.current?.submitForm();
  }, []);

  const handleSubmit = (values) => {
    getTaxesCredit.mutate(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      innerRef={formikRef}
    >
      {({ values, setFieldValue, submitForm }) => (
        <Form>
          <div className="row row-large-gutter">
            <div className="col-md-6">
              <Block marginBottom={spacings.m}>
                <Block marginBottom={spacings.s}>
                  <H2>
                    {label || polyglot.t("taxes_credit.simulate_your_credit")}
                  </H2>
                </Block>
                <Body16>
                  {polyglot.t("taxes_credit.simulate_your_credit_description")}
                </Body16>
              </Block>
              <List.Item
                RightComponent={() => (
                  <div className="py-2">
                    <Stepper
                      min={1}
                      max={35}
                      suffix="h"
                      value={values.nb_hours}
                      onChange={(v) => {
                        setFieldValue("nb_hours", v);
                        setTimeout(submitForm, 0);
                      }}
                    />
                  </div>
                )}
              >
                <List.Elem.Title>
                  {polyglot.t("taxes_credit.what_is_job_duration")}
                </List.Elem.Title>
              </List.Item>
              <List.Item
                divider={false}
                RightComponent={() => (
                  <div className="py-2">
                    <Stepper
                      min={9}
                      max={50}
                      suffix={polyglot.currency}
                      value={values.price_per_hour}
                      hint="Par heure"
                      onChange={(v) => {
                        setFieldValue("price_per_hour", v);
                        setTimeout(submitForm, 0);
                      }}
                    />
                  </div>
                )}
              >
                <List.Elem.Title>
                  {polyglot.t("taxes_credit.how_many_price_per_hour")}
                </List.Elem.Title>
              </List.Item>
              {show_cta && (
                <Button.Medium block id="taxesAskService" className="mt-3">
                  {polyglot.t("common.actions.ask_for_service")}
                </Button.Medium>
              )}
            </div>

            <div className="col-md-6 mt-md-0 mt-3">
              <TaxesCreditSimulatorSummary
                isLoading={
                  getTaxesCredit.isLoading || !getTaxesCredit.isSuccess
                }
                price={values.price_per_hour * values.nb_hours}
                taxes={
                  getTaxesCredit.data?.data?.employee_part +
                  getTaxesCredit.data?.data?.employer_part -
                  getTaxesCredit.data?.data?.deduction
                }
              />
            </div>
          </div>
          <CategoriesNavigationModal
            url_params={url_params}
            selector="#taxesAskService"
            rails={rails}
          />
        </Form>
      )}
    </Formik>
  );
};

export default TaxCreditSimulator;
