import Fuse from "fuse.js";
import { useCallback, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Divider, List } from "../../components";
import { DIVIDER, LIST_HEADER } from "../../components/Styles/variants";
import Inventory from "./Inventory";
import MovingHeader from "./MovingHeader";
import PropItem from "./PropItem";
import VolumeResult from "./VolumeResult";
import DATA from "./data";
import { useBreakpoints } from "../../modules/hooks";
import polyglot from "@/utils/polyglot";
import InventoryHeader from "./InventoryHeader";

const MovingVolumeCalculator = () => {
  const [inventory, setInventory] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const headersRef = useRef({});
  const itemsWrapperRef = useRef();
  const breakpoint = useBreakpoints();
  const fuse = new Fuse(Object.values(DATA).flat(), {
    keys: ["label"],
  });
  const [term, setTerm] = useState("");
  const [headerVisible, setHeaderVisible] = useState(true);
  let lastScrollTop = 0;
  let lastActionScrollTop = 0;
  const isMobile = breakpoint.get({ xs: true, md: false });

  const handleHeaderIntersect = (entry, index) => {
    setActiveTabIndex(index);
  };

  useEffect(() => {
    const handleScroll = () => {
      const SCROLL_TRIGGER_RANGE = 50;
      const HEADER_HEIGHT = 100;

      const st = itemsWrapperRef.current.scrollTop;

      if (
        isMobile &&
        Math.abs(st - lastActionScrollTop) > SCROLL_TRIGGER_RANGE &&
        st >= HEADER_HEIGHT / 2
      ) {
        if (st > lastScrollTop) {
          setHeaderVisible(false);
        } else {
          setHeaderVisible(true);
          lastActionScrollTop = st;
        }
      }
      lastScrollTop = st;
    };

    itemsWrapperRef.current.addEventListener("scroll", handleScroll);
    return () => {
      itemsWrapperRef.current.removeEventListener("scroll", handleScroll);
    };
  }, [isMobile]);

  const handleClear = () => {
    setInventory([]);
  };

  const handleSearch = (e) => {
    const { value } = e?.target;
    setTerm(value);
    itemsWrapperRef.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (value) {
      const seen = new Set();
      setSearchResult(
        fuse
          .search(value)
          .map((r) => r.item)
          .filter((item) => !seen.has(item.value) && seen.add(item.value))
      );
    } else {
      setSearchResult(null);
    }
  };

  const handleChange = (value, amount, { label, volume, image }) => {
    const oldInventory = [...inventory];
    const itemIndex = oldInventory.findIndex((item) => item.value === value);
    if (amount > 0) {
      if (itemIndex !== -1) {
        oldInventory[itemIndex].amount = amount;
      } else {
        oldInventory.push({ value, amount, label, volume, image });
      }
    } else if (itemIndex !== -1) {
      oldInventory.splice(itemIndex, 1);
    }
    setInventory(oldInventory);
  };

  const renderItems = useCallback(
    () =>
      Object.keys(DATA).map((key, index) => (
        <div key={`items-header-${key}`} id={`inventory-elem-${index}`}>
          <InventoryHeader onIntersect={(e) => handleHeaderIntersect(e, index)}>
            {key}
          </InventoryHeader>
          {DATA[key].map((item) => (
            <PropItem
              key={`item-header-${key}-${item.value}`}
              onChange={(amount) => handleChange(item.value, amount, item)}
              image={item.image}
              value={inventory.find((inv) => inv.value === item.value)?.amount}
              label={item.label}
            />
          ))}
        </div>
      )),
    [inventory, headersRef]
  );

  const handleTabChange = (index) => {
    setSearchResult(null);
    const HEADER_HEIGHT = 104;
    const element = itemsWrapperRef.current.querySelector(
      `#inventory-elem-${index}`
    );
    const elementOffsetTop = element.offsetTop;
    const newScrollTop = elementOffsetTop - HEADER_HEIGHT;
    itemsWrapperRef.current.scrollTo({ top: newScrollTop, behavior: "smooth" });
  };

  const renderSearchResultItems = useCallback(
    () =>
      searchResult.map((item) => (
        <PropItem
          key={`item-search-${item.value}`}
          onChange={(amount) => handleChange(item.value, amount, item)}
          value={inventory.find((inv) => inv.value === item.value)?.amount}
          image={item.image}
          label={item.label}
        />
      )),
    [(inventory, searchResult)]
  );

  return (
    <Block
      borderRadius={spacings.l}
      backgroundColor={colors.backgroundLight}
      paddingX={spacings.m}
      paddingTop={spacings.m}
      width="100%"
    >
      <Block
        display="flex"
        alignItems="stretch"
        overflow="hidden"
        backgroundColor={colors.background}
        borderRadius={spacings.m}
        height={{ xs: "400px", md: "600px" }}
      >
        <Block
          flex="1"
          overflow="auto"
          ref={itemsWrapperRef}
          position="relative"
        >
          <motion.div
            initial={{ y: 0 }}
            animate={{ y: 0 }} // Suppose que la hauteur de MovingHeader est 104px
            transition={{ duration: 0.3 }}
            style={{
              position: "sticky",
              top: "0px",
              zIndex: "1",
              backgroundColor: colors.background,
            }}
          >
            <MovingHeader
              onSearch={handleSearch}
              term={term}
              inventory={inventory}
              onClear={handleClear}
              onChange={handleChange}
              activeTabIndex={activeTabIndex}
              onTabChange={handleTabChange}
            />
          </motion.div>

          {term?.length > 0 ? renderSearchResultItems() : renderItems()}
        </Block>
        <Block display={{ xs: "none", md: "flex" }} alignItems="stretch">
          <Divider.Cell orientation={DIVIDER.ORIENTATION.VERTICAL} />
          <Block width="300px" overflow="auto">
            <Inventory
              items={inventory}
              onChange={handleChange}
              onClear={handleClear}
            />
          </Block>
        </Block>
      </Block>
      <Block
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {inventory.length > 0 ? (
          <VolumeResult
            value={inventory?.reduce(
              (acc, obj) => acc + obj.amount * obj.volume,
              0
            )}
          />
        ) : (
          <Block padding={spacings.m}>
            <Body16 color={colors.muted} align="center">
              {polyglot.t("moving_simulator.please_add_items")}
            </Body16>
          </Block>
        )}
      </Block>
    </Block>
  );
};
export default MovingVolumeCalculator;
