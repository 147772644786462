import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import * as yup from "yup";
import TaxesCreditImage from "images/tax-credit-artwork.svg";
import { borderWidth, colors, spacings } from "@/assets/themes";
import {
  Alert,
  Block,
  Body14,
  Button,
  Divider,
  H3,
  H4,
  H5,
  Icon,
  Info,
  List,
  Popover,
} from "@/components";
import CesuItem from "@/components/CesuItem";
import { BUTTON, LIST } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import { useTokenizeQuery } from "@/modules/routes/payment-routes";
import { useGetTax, usePutTax } from "@/modules/routes/tax-routes";
import { paymentCreditCardValidation, setPaymentInitialValues } from "@/utils";
import polyglot from "@/utils/polyglot";
import PaymentMethodsUpdated from "../../common/payment-methods";
import { getPaymentSummaryItems } from "../../common/payment-methods/utils/get-payment-summary-items";
import JobberOfferItem from "../jobs/JobberOfferItem";
import TaxesCreditSimulatorSummary from "./TaxesCreditSimulatorSummary";
import SummaryItem from "../../jobber-booking/SummaryItem";
import PayTaxesSkeleton from "@/pages/dashboard/skeletons/PayTaxesSkeleton";

const getPricing = (values, pricings = {}) => {
  if (values.cesu) {
    return pricings.pricing_with_cesu;
  }
  return pricings.pricing_without_cesu;
};

const JobberTaxes = ({
  id,
  onClose,
  goNext,
  hideJobberItem,
  initialValues,
}) => {
  const handleNext = () => {
    if (onClose) onClose();
    if (goNext) goNext();
  };

  const paymentMethodRef = useRef();
  const { data, isLoading } = useGetTax({ id });
  const formikRef = useRef();
  const submit = usePutTax({ id });
  const submitTax = useTokenizeQuery(submit.mutateAsync);
  const breakpoints = useBreakpoints();

  const getJobber = () => {
    if (data?.offer?.jobber) {
      const newObj = { ...(data.offer.jobber || []) };
      newObj.url = null;
      return newObj;
    }
    return null;
  };

  const handleSubmit = (values) => {
    submitTax.mutate(values);
  };

  useEffect(() => {
    if (submitTax.isSuccess) {
      if (!submitTax.data.data.data.secure_url) {
        handleNext();
      }
    }
    if (submitTax.isError) {
      const mustResetCard = submitTax.error.data?.data?.must_reset_card;
      if (mustResetCard) {
        paymentMethodRef.current?.reset();
        formikRef.current?.setValues((s) => ({
          ...s,
          default_payment_method: "",
          payment_method: "",
          card_number: "",
          card_type: "",
          token: "",
        }));
      }
    }
  }, [submitTax.isError, submitTax.isSuccess, formikRef.current]);

  const handleCesuClick = (v) => {
    formikRef.current.setFieldValue("cesu", v);
  };

  return (
    <div>
      {!isLoading && data ? (
        <div>
          {!hideJobberItem && (
            <Block marginBottom={spacings.ml}>
              <JobberOfferItem
                urlTargetBlank
                jobber={getJobber()}
                hideDetails
                price={data.offer.price}
                withGutters
                state={data.offer.state}
                shape={LIST.SHAPE.ROUND}
                css={`
                  background-color: ${colors.background};
                  border: solid ${borderWidth.s} ${colors.border};
                `}
              />
            </Block>
          )}
          {/* paddingX={spacings.s} -> Monkey patch text alignement ("?" was in new line) */}
          <Block marginBottom={spacings.m} paddingX={spacings.s}>
            {breakpoints.get({
              xs: (
                <H4 align="center">
                  {polyglot.t("pay_taxes.do_you_want_to_declare")}
                </H4>
              ),
              md: (
                <H3 align="center">
                  {polyglot.t("pay_taxes.do_you_want_to_declare")}
                </H3>
              ),
            })}
          </Block>
          <Alert.Low
            renderIcon={() => (
              <img
                src={TaxesCreditImage}
                css={`
                  width: 48px;
                  height: auto;
                `}
              />
            )}
          >
            <div>
              {polyglot.t("pay_taxes.by_declaring_you_recieve_n_amount1")}
              <strong>
                {" "}
                {polyglot.toSmartCurrency(data.tax_credit, {
                  noDecimal: true,
                })}{" "}
              </strong>
              {polyglot.t("pay_taxes.by_declaring_you_recieve_n_amount2")}
            </div>

            <Info
              PopoverContainer={Popover.Elem.Menu}
              render={() => (
                <Button.Link
                  css={`
                    padding: 0;
                    min-height: 0;
                  `}
                >
                  {polyglot.t("common.find_out_more")}
                </Button.Link>
              )}
            >
              <Block paddingX={spacings.m} paddingY={spacings.sm}>
                <TaxesCreditSimulatorSummary
                  price={data.total_price}
                  taxes={data.tax_amount}
                />
              </Block>
            </Info>
          </Alert.Low>
          <Formik
            validationSchema={yup.lazy((values) => {
              if (getPricing(values, data).total > 0) {
                return paymentCreditCardValidation;
              }
              return yup.object().shape({});
            })}
            validateOnMount
            initialValues={{
              ...initialValues,
              ...data.initial_values,
              urssaf: true,
              ...setPaymentInitialValues({
                ...data.initial_values,
                ...data,
              }),
              payment_method:
                data.default_payment_method || data.payment_method,
              cesu: data.initial_values?.cesu || false,
            }}
            innerRef={formikRef}
            onSubmit={handleSubmit}
          >
            {({ isValid, values, setValues }) => (
              <Form>
                <List.Header
                  RightComponent={() => (
                    <Block
                      display="flex"
                      alignItems="center"
                      gap={spacings.xs}
                      color={colors.muted}
                    >
                      <Icon.Medium name="lock" />
                      <Body14 strong>
                        {polyglot.t("common.secure_payment")}
                      </Body14>
                    </Block>
                  )}
                >
                  {polyglot.t("common.summary")}
                </List.Header>
                {getPricing(values, data).fees > 0 && (
                  <SummaryItem
                    title={polyglot.t("common.fees")}
                    value={polyglot.toSmartCurrency(
                      getPricing(values, data).fees
                    )}
                  >
                    <Info>{polyglot.t("common.fees_description")}</Info>
                  </SummaryItem>
                )}
                {getPricing(values, data).taxes > 0 && (
                  <SummaryItem
                    title={polyglot.t("common.taxes")}
                    value={polyglot.toSmartCurrency(
                      getPricing(values, data).taxes
                    )}
                  >
                    <Info>{polyglot.t("common.taxes_description")}</Info>
                  </SummaryItem>
                )}

                {getPricing(values, data).wallet +
                  (getPricing(values, data).fees_discount || 0) >
                  0 && (
                  <SummaryItem
                    title={polyglot.t("wallet.pot")}
                    value={polyglot.toSmartCurrency(
                      getPricing(values, data).wallet +
                        (getPricing(values, data).fees_discount || 0)
                    )}
                    positive
                  >
                    <Info>{polyglot.t("common.pot_description")}</Info>
                  </SummaryItem>
                )}
                {getPricing(values, data).cesu > 0 && (
                  <SummaryItem
                    title={polyglot.t("wallet.cesu")}
                    value={polyglot.toSmartCurrency(
                      getPricing(values, data).cesu
                    )}
                    positive
                  />
                )}
                <Block width="100%" paddingY={spacings.s}>
                  <Divider.Cell />
                </Block>
                <List.Item
                  divider={false}
                  size={LIST.SIZE.COMPACT}
                  RightComponent={() => (
                    <H5>
                      {polyglot.toSmartCurrency(getPricing(values, data).total)}
                    </H5>
                  )}
                >
                  <H5 strong>{polyglot.t("common.total")}</H5>
                </List.Item>
                <PaymentMethodsUpdated
                  ref={paymentMethodRef}
                  disabled={getPricing(values, data).total === 0}
                  divider
                  methods={data.payment_methods}
                />
                {data.cesu_amount > 0 && (
                  <CesuItem
                    shape={LIST.SHAPE.ROUND}
                    divider={false}
                    eligibility="eligible"
                    withGutters
                    amount={data.cesu_amount}
                    checked={values.cesu}
                    onChange={() => handleCesuClick(!values.cesu)}
                  />
                )}
                <Block
                  display="flex"
                  gap={spacings.s}
                  marginTop={spacings.m}
                  flexDirection="column"
                >
                  <PaymentMethodsUpdated.PayButtonEnhancer
                    block
                    textType="pay"
                    disabled={!isValid}
                    amount={getPricing(values, data).total}
                    lineItems={getPaymentSummaryItems({
                      ...getPricing(values, data),
                    })}
                    isLoading={submitTax.isLoading}
                    onPaymentAuthorized={({ token, applePaySession }) =>
                      handleSubmit(
                        {
                          ...values,
                          token,
                          applePaySession,
                        },
                        { setValues }
                      )
                    }
                  >
                    <Button.Large
                      block
                      type="submit"
                      disabled={!isValid}
                      isLoading={submitTax.isLoading}
                    >
                      {getPricing(values, data).total > 0
                        ? polyglot.t("job_review.pay_n_and_confirm", {
                            n: polyglot.toSmartCurrency(
                              getPricing(values, data).total,
                              { noDecimal: true }
                            ),
                          })
                        : polyglot.t("common.validate")}
                    </Button.Large>
                  </PaymentMethodsUpdated.PayButtonEnhancer>
                  <Button.Large
                    kind={BUTTON.KIND.SECONDARY}
                    onClick={handleNext}
                  >
                    {polyglot.t("common.later")}
                  </Button.Large>
                </Block>
              </Form>
            )}
          </Formik>
        </div>
      ) : (
        <PayTaxesSkeleton />
      )}
    </div>
  );
};
export default JobberTaxes;
