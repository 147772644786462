import { useFormikContext } from "formik";
import React from "react";
import { useLocation } from "react-router-dom";
import { Container, Flow } from "../../components";
import JobberDirectCard from "../common/JobberDirectCard";

const SelectJobber = ({ goBack, goByPath }) => {
  const { setFieldValue, values } = useFormikContext() || {};
  const {
    state: { jobber: dJobber },
  } = useLocation();
  const jobber = values.jobber.first_name ? values.jobber : dJobber;

  const handleGoNext = (j) => {
    setFieldValue("jobber_id", j.id);
    setFieldValue("jobber", j);
    setFieldValue("external_jobber", { phone: "", email: "", first_name: "" });
    goByPath("services");
  };

  const handleEsc = () => {
    setFieldValue("jobber_id", null);
    setFieldValue("jobber", {});
    goByPath("email", { altTitle: true });
  };

  return (
    <Container.Small>
      <Flow.Item.Title>
        Super ! Nous avons trouvé {jobber.first_name}, est-ce bien ce
        prestataire ?
      </Flow.Item.Title>
      <JobberDirectCard
        onClick={handleGoNext}
        user={jobber}
        key={jobber.id}
        onEsc={handleEsc}
      />
      <Flow.Item.Navigation goBack={goBack} />
    </Container.Small>
  );
};

export default SelectJobber;
