import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { colors, radius, spacings } from "../../assets/themes";
import { Body16, Button, Icon, List } from "../../components";
import Block from "../../components/Block";
import { BUTTON } from "../../components/Styles/variants";
import { useWindowSize } from "../../modules/hooks";
import { formatPhone } from "../../utils";
import polyglot from "../../utils/polyglot";
import scrollToBottom from "../../utils/scroll-to-bottom";
import ChatMessage from "./ChatMessage";

const Messages = React.memo(
  ({ messages, users, discussable_id, discussable_url, id, current_user }) => {
    const messagesWrapperRef = useRef();
    const { height: windowHeight } = useWindowSize();

    const gallery = useMemo(
      () =>
        messages
          .filter(
            (message) =>
              message?.attachment?.kind === "image_message" ||
              message?.attachment?.kind === "video_message"
          )
          ?.map((message) => message.attachment)
          ?.reverse(),
      [messages.length]
    );

    useEffect(() => {
      scrollToBottom(messagesWrapperRef);
    }, [messages.length, windowHeight]);

    useEffect(() => {
      const imageElements = [];
      gallery.forEach((attachment) => {
        const imgElement = document.getElementById(
          `chat-image-${attachment.id}`
        );
        if (imgElement) {
          imageElements.push(imgElement);
        }
      });

      let imagesLoaded = 0;
      const onImageLoad = (e) => {
        imagesLoaded += 1;
        if (imagesLoaded === imageElements.length) {
          scrollToBottom(messagesWrapperRef);
        }
      };
      imageElements.forEach((img) => {
        if (img.complete) {
          onImageLoad();
        } else {
          img.addEventListener("load", onImageLoad);
          img.addEventListener("error", onImageLoad); // Treat errors as "loaded"
        }
      });

      return () => {
        imageElements.forEach((img) => {
          img.removeEventListener("load", onImageLoad);
          img.removeEventListener("error", onImageLoad);
        });
      };
    }, [gallery]);

    const renderMessage = useCallback(
      () =>
        messages.map((message, index) => {
          const forceMainTimestamp =
            index === messages.length - 1 ||
            message?.date !== messages[index + 1]?.date;

          const offerId = users?.find(
            (u) => u?.id === message?.user?.id
          )?.offer_id;

          const isLastGroup =
            !message?.user ||
            !messages[index - 1]?.user ||
            messages[index - 1]?.user.id !== message?.user?.id ||
            messages[index - 1]?.date !== message?.date;

          const isFirstGroup =
            !message?.user ||
            !messages[index + 1]?.user ||
            messages[index + 1]?.date !== message?.date ||
            (message?.user?.id &&
              messages[index + 1]?.user.id !== message?.user?.id);

          return (
            <div key={message?.id}>
              <ChatMessage
                {...message}
                offerId={offerId}
                discussable_id={discussable_id}
                discussable_url={discussable_url}
                action={message?.action}
                id={id}
                isLast={index === 0}
                current_user={current_user}
                forceMainTimestamp={forceMainTimestamp}
                gallery={gallery}
                stacked={{
                  middle: !isFirstGroup && !isLastGroup,
                  last: isLastGroup,
                  first: isFirstGroup,
                }}
                own={message?.user?.is_current_user}
                isSeen={message?.seen_by_users_ids?.length - 1 === users.length}
              />
            </div>
          );
        }),
      [
        messages,
        users,
        discussable_id,
        discussable_url,
        id,
        current_user,
        gallery,
      ]
    );

    return (
      <Block overflowY="auto" flex="1">
        {messages.length > 0 && (
          <div ref={messagesWrapperRef}>
            <Block
              flexDirection="column-reverse"
              display="flex"
              padding={spacings.m}
            >
              {renderMessage()}
            </Block>
          </div>
        )}
      </Block>
    );
  },
  (prevProps, nextProps) =>
    prevProps.messages === nextProps.messages &&
    prevProps.users === nextProps.users &&
    prevProps.show_message_form === nextProps.show_message_form
);

export default Messages;
