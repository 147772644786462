import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useState } from "react";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Body14,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Icon,
  Input,
  Link,
  Modal,
} from "../../../components";
import FacebookLogin from "../../../components/FacebookLogin";
import { ALERT } from "../../../components/Styles/variants";
import { usePostLogin } from "../../../modules/routes/sign-routes";
import polyglot from "../../../utils/polyglot";
import WrongCountryModal from "./WrongCountryModal";

const Login = ({
  onSuccess,
  title,
  subtitle,
  modal,
  onClose,
  params,
  alertMessage,
}) => {
  const [wrongCountryModalIsOpen, setWrongCountryModalIsOpen] = useState(false);
  const postLogin = usePostLogin();
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      user: { email: "", password: "" },
      remember_me: false,
      ...params,
    },
    validationSchema: Yup.object().shape({
      user: Yup.object().shape({
        email: Yup.string().required(
          polyglot.t("common.errors.field_required")
        ),
        password: Yup.string().required(
          polyglot.t("common.errors.field_required")
        ),
      }),
    }),
    onSubmit: (values) => {
      formik.setStatus(null);
      postLogin.mutate(values, {
        onSuccess: ({ data }) => onSuccess(data),
        onError: ({ response }) => {
          if (
            response.data.user_country_code &&
            response.data.domain_country_code
          ) {
            setWrongCountryModalIsOpen(true);
          } else {
            formik.setStatus(response.data.errorMessage);
          }
        },
      });
    },
  });

  const handleSignUp = () => {
    history.push("/signup");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal.Item.Header
        onClose={modal && onClose}
        onBack={history.location?.state?.from ? () => history.goBack() : null}
        title={title || polyglot.t("login.happy_to_see_you_again")}
        subtitle={subtitle || polyglot.t("login.connect_for_access_to.account")}
      />
      <Modal.Item.Wrapper>
        <FacebookLogin onSuccess={onSuccess} />
        <Block marginY={spacings.m}>
          <Divider.Cell>{polyglot.t("common.or")}</Divider.Cell>
        </Block>
        {formik.status && (
          <Block marginBottom={spacings.m}>
            <Alert.Low kind={ALERT.KIND.DANGER}>{formik.status}</Alert.Low>
          </Block>
        )}
        <FormControl
          error={formik.submitCount > 0 && formik.errors.user?.email}
        >
          <Input
            value={formik.values.user?.email}
            name="user[email]"
            type="email"
            autoFocus
            placeholder={polyglot.t("common.email")}
            onChange={formik.handleChange}
            LeftComponent={() => (
              <Icon.Large name="envelope" color={colors.mutedLight} />
            )}
          />
        </FormControl>
        <FormControl
          error={formik.submitCount > 0 && formik.errors.user?.password}
        >
          <Input
            value={formik.values.user?.password}
            name="user[password]"
            type="password"
            placeholder={polyglot.t("common.password")}
            onChange={formik.handleChange}
            LeftComponent={() => (
              <Icon.Large name="lock" color={colors.mutedLight} />
            )}
          />
        </FormControl>
        <Block
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={spacings.m}
        >
          <Checkbox
            name="remember_me"
            checked={formik.values.remember_me}
            onChange={formik.handleChange}
          >
            {polyglot.t("login.remember_me")}
          </Checkbox>
          <Link href="/password/new?context=user">
            <Body14>{polyglot.t("login.forgot_password")}</Body14>
          </Link>
        </Block>
        {alertMessage && (
          <Block marginBottom={spacings.s}>
            <Alert.Low>{alertMessage}</Alert.Low>
          </Block>
        )}
        <Button.Medium
          type="submit"
          isLoading={postLogin.isLoading || postLogin.isSuccess}
          // prevent trigger submit again when modal is Open
          disabled={wrongCountryModalIsOpen}
        >
          {polyglot.t("common.to_login")}
        </Button.Medium>
      </Modal.Item.Wrapper>
      {wrongCountryModalIsOpen && (
        <WrongCountryModal
          userEmail={formik.values.user.email}
          originCountryCode={postLogin.error?.response?.data?.user_country_code}
          domainCountryCode={
            postLogin.error?.response?.data?.domain_country_code
          }
          isOpen={wrongCountryModalIsOpen}
          onSignUp={handleSignUp}
          onRedirect={() => onSuccess(postLogin.error?.response?.data)}
          onClose={() => {
            setWrongCountryModalIsOpen(false);
          }}
        />
      )}
    </form>
  );
};

export default Login;
