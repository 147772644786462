import * as Sentry from "@sentry/react";
import { spacings } from "../assets/themes";
import { Block, Body16, Button, Container, H3 } from "../components";
import { IS_STAGING } from "./constants";
import polyglot from "./polyglot";

Sentry.init({
  dsn: "https://f5ca8debd006487d8480b904e8d85ba1@sentry.io/1322913",
  environment: IS_STAGING ? "staging" : process.env.NODE_ENV,
  // prevent send Facebook pixel errors (due to users adblockers)
  ignoreErrors: [/^Can't find variable: fbq$/, /^fbq is not defined$/],
});

const ErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary
    fallback={() => (
      <>
        <Container.Small>
          <Block margin={spacings.l}>
            <span aria-label="" role="img" style={{ fontSize: "40px" }}>
              🧐
            </span>
            <H3>{polyglot.t("error_boundary.oups")}</H3>
            <Body16>{polyglot.t("error_boundary.description")}</Body16>
            <Block marginTop={spacings.s}>
              <Button.Medium
                type="button"
                onClick={() => {
                  window.location.reload();
                }}
              >
                {polyglot.t("error_boundary.retry")}
              </Button.Medium>
            </Block>
          </Block>
        </Container.Small>
      </>
    )}
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
