import React from "react";
import BaseApp from "../modules/BaseApp";
import TimeRangeInput from "../pages/TimeRangeInput";

const TimeRangeApp = (props, rails) => () =>
  (
    <BaseApp>
      <TimeRangeInput {...props} />
    </BaseApp>
  );

export default TimeRangeApp;
