import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormLargeStepper, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory1031() {
  const initialValues = {
    nh_specific: 1.0,
  };

  const getNbHours = (values) => values.nh_specific;

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.radiators_installation"),
      options: [
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
        { value: 4.0, label: 4 },
        { value: 5.0, label: 5 },
        { value: 6.0, label: 6 },
        { value: 7.0, label: 7 },
        { value: 8.0, label: 8 },
        { value: 9.0, label: 9 },
        { value: 10.0, label: 10 },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.how_many_radiators_to_install"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl>
            <FormLargeStepper
              isWrap
              name="nh_specific"
              title={nhConfig.nh_specific.label}
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
  };
}
