import { AnimatePresence, motion } from "framer-motion";
import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Body16, Col, Icon, List, Row } from "../../components";
import { LIST, LIST_HEADER } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import { formatPhone } from "../../utils";
import polyglot from "@/utils/polyglot";

const JobDetails = ({ isVisible }) => {
  const { data, isPoster } = useJob();

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ duration: 0.5 }}
          style={{ overflow: "hidden" }}
        >
          <Block marginBottom={spacings.sm}>
            <Row gutter={{ xs: 0, lg: spacings.xl }}>
              {data.informations?.length > 0 && (
                <Col size={{ xs: 12, lg: 6 }}>
                  <List.Header kind={LIST_HEADER.KIND.SUBTITLE}>
                    {polyglot.t("job.service_details")}
                  </List.Header>
                  {data.informations.map(({ key, value }, i) => (
                    <List.Item
                      key={`job-details-information-${i}`}
                      size={LIST.SIZE.COMPACT}
                      divider={false}
                      RightComponent={() => (
                        <Body16 strong align="right">
                          {value}
                        </Body16>
                      )}
                    >
                      {key}
                    </List.Item>
                  ))}
                </Col>
              )}
              <Col size={{ xs: 12, lg: 6 }}>
                {isPoster && (
                  <>
                    <List.Header kind={LIST_HEADER.KIND.SUBTITLE}>
                      {polyglot.t("job.personnal_data")}
                    </List.Header>
                    <List.Item
                      divider={false}
                      size={LIST.SIZE.COMPACT}
                      LeftComponent={() => (
                        <Icon.Large name="phone" color={colors.primary} />
                      )}
                    >
                      <Body16>{polyglot.t("common.phone_number")}</Body16>
                      <Body16 strong>{formatPhone(data.phone)}</Body16>
                      <Body14 color={colors.muted}>
                        <Icon.Medium name="eye-slashed" />
                        &nbsp; {polyglot.t("job.hidden_until_booking")}
                      </Body14>
                    </List.Item>
                    <List.Item
                      divider={false}
                      size={LIST.SIZE.COMPACT}
                      css={`
                        margin-top: ${spacings.s};
                        margin-bottom: ${spacings.s};
                      `}
                      LeftComponent={() => (
                        <Icon.Large name="map-marker" color={colors.primary} />
                      )}
                    >
                      <Body16>
                        {data.destination_address
                          ? "Adresse de départ"
                          : "Adresse"}
                      </Body16>
                      <Body16 strong>{data.address}</Body16>
                      <Body14 color={colors.muted}>
                        <Icon.Medium name="eye-slashed" />
                        &nbsp; {polyglot.t("job.hidden_until_booking")}
                      </Body14>
                    </List.Item>
                    {data.destination_address && (
                      <List.Item
                        divider={false}
                        LeftComponent={() => (
                          <Icon.Large name="race-flag" color={colors.primary} />
                        )}
                      >
                        <Body16>
                          {polyglot.t("common.destination_address")}
                        </Body16>
                        <Body16 strong>{data.destination_address}</Body16>
                        <Body14 color={colors.muted}>
                          <Icon.Medium name="eye-slashed" />
                          &nbsp; {polyglot.t("job.hidden_until_booking")}
                        </Body14>
                      </List.Item>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Block>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default JobDetails;
