import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled, { css, ThemeContext } from "styled-components";
import { colors } from "../../assets/themes";
import {
  Alert,
  ArtworkLabel,
  Button,
  Container,
  Link,
  List,
  Toast,
} from "../../components";
import { DIRECT_PLANS, translateDayPlural } from "../../utils";
import polyglot from "../../utils/polyglot";
import Card from "./payments/Card";
import ContractModal from "./show/ContractModal";
import SuccessContractModal from "./show/SuccessContractModal";
import SuccessModal from "./show/SuccessModal";
import RelationSkeleton from "./skeleton/RelationSkeleton";
import StickyBox from "./StickyBox";

const DirectIcon = ({ style, width, height }) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      style={style}
      d="m6 3c-1.65685 0-3 1.34315-3 3v12c0 1.6569 1.34315 3 3 3h12c1.6569 0 3-1.3431 3-3v-12c0-1.65685-1.3431-3-3-3zm5 5v6h-3v-6zm2 0h3v8h-3z"
    />
  </svg>
);

const StyledEmploymentContract = styled.div`
  ${({ theme, isEmpty }) => css`
    width: 100%;
    height: 150px;
    background-color: ${isEmpty ? theme.colors.primary100 : theme.colors.white};
    border: solid 2px ${isEmpty ? theme.colors.primary100 : theme.colors.border};
    color: ${isEmpty ? theme.colors.primary500 : theme.colors.body};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  `}
`;

const Show = ({ data, updateData, isLoading }) => {
  const [successIsOpen, setSuccessIsOpen] = useState(false);
  const [successContratIsOpen, setSuccessContratIsOpen] = useState(false);
  const [successContratNewIsOpen, setSuccessContratNewIsOpen] = useState(false);
  const [contractDetailsIsOpen, setContractDetailsIsOpen] = useState(false);
  const [paymentMethodSuccess, setPaymentMethodSuccess] = useState(false);
  const themeContext = useContext(ThemeContext);
  const history = useHistory();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.get("success") && setSuccessIsOpen(true);
    urlParams.get("success_contract") && setSuccessContratIsOpen(true);
    urlParams.get("success_contract_new") && setSuccessContratNewIsOpen(true);
    urlParams.get("payment_method_update_success") &&
      setPaymentMethodSuccess(true);
  }, []);

  const renderState = () => {
    if (data.state !== "inactive") {
      if (data.jobber.external)
        return (
          <Alert.High
            kind="warning"
            title="Votre prestataire n’a pas encore rejoint Yoojo"
            className="mb-4"
          />
        );
      if (data.has_payments_failed)
        return (
          <Alert.High
            kind="danger"
            title="Un ou plusieurs paiements ont échoué, veuillez mettre à jour votre moyen de paiement"
            className="mb-4"
          />
        );
      return null;
    }
    return (
      <span
        className="badge badge-pill px-3 py-2 mb-3"
        style={{ background: themeContext.gray100 }}
      >
        Terminé
      </span>
    );
  };

  return (
    <Container.Large className="py-lg-5 py-3">
      {data ? (
        <>
          <div className="row">
            <div className="col-lg-8">
              <h1 className="mb-3 display-4 font-weight-medium">
                {data.jobber.first_name}
              </h1>
              {renderState()}
              <div className="row mb-5">
                <div className="col-md-6">
                  <ArtworkLabel.Medium
                    className="mb-3"
                    LeftComponent={() => (
                      <i
                        className="icon-calendar p-2 rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          background:
                            data.state === "inactive"
                              ? colors.gray100
                              : colors.primary50,
                          color:
                            data.state === "inactive"
                              ? colors.muted
                              : colors.primary500,
                          width: 24,
                          height: 24,
                        }}
                      />
                    )}
                  >
                    {data.services.map((s) => s.translation).join(", ")}
                  </ArtworkLabel.Medium>
                  <ArtworkLabel.Medium
                    className="mb-3"
                    LeftComponent={() => (
                      <i
                        className="icon-wallet p-2 rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          background:
                            data.state === "inactive"
                              ? colors.gray100
                              : colors.primary50,
                          color:
                            data.state === "inactive"
                              ? colors.muted
                              : colors.primary500,
                          width: 24,
                          height: 24,
                        }}
                      />
                    )}
                  >
                    {data.frequency === "monthly"
                      ? `Paiement tous les ${data.monthly_payment_day} du mois`
                      : `Paiement tous les ${translateDayPlural(
                          data.weekly_payment_day
                        ).toLowerCase()}`}
                  </ArtworkLabel.Medium>
                  <ArtworkLabel.Medium
                    className=""
                    LeftComponent={() => (
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                          borderRadius: 99,
                          background:
                            data.state === "inactive"
                              ? colors.gray100
                              : colors.primary50,
                          width: 24,
                          height: 24,
                        }}
                      >
                        <DirectIcon
                          width={18}
                          height={18}
                          style={{
                            fill:
                              data.state === "inactive"
                                ? colors.muted
                                : colors.primary500,
                          }}
                        />
                      </div>
                    )}
                  >
                    Offre {DIRECT_PLANS[data.plan].title} (
                    {polyglot.toSmartCurrency(DIRECT_PLANS[data.plan].price)} /
                    mois)
                  </ArtworkLabel.Medium>
                </div>
                <div className="col-md-6">
                  <ArtworkLabel.Medium
                    className="mb-3"
                    LeftComponent={() => (
                      <i
                        className="icon-clock p-2 rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          background:
                            data.state === "inactive"
                              ? colors.gray100
                              : colors.primary50,
                          color:
                            data.state === "inactive"
                              ? colors.muted
                              : colors.primary500,
                          width: 24,
                          height: 24,
                        }}
                      />
                    )}
                  >
                    {data.nb_hours}{" "}
                    {data.nb_hours > 1 ? "heures / " : "heure / "}
                    {data.frequency === "monthly" ? "mois" : "semaine"}
                  </ArtworkLabel.Medium>
                  <ArtworkLabel.Medium
                    className=""
                    LeftComponent={() => (
                      <i
                        className="icon-map-marker p-2 rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                          background:
                            data.state === "inactive"
                              ? colors.gray100
                              : colors.primary50,
                          color:
                            data.state === "inactive"
                              ? colors.muted
                              : colors.primary500,
                          width: 24,
                          height: 24,
                        }}
                      />
                    )}
                  >
                    {data.address}
                  </ArtworkLabel.Medium>
                </div>
              </div>
              <div className="mb-5">
                <div className="d-flex align-items-baseline justify-content-between ">
                  <h2>Paiements</h2>
                  <Link className="font-weight-medium" to="/payments_history">
                    Voir plus
                  </Link>
                </div>
                <div className="row align-items-stretch mt-n2">
                  {data.direct_payments.map((dp) => (
                    <div
                      key={`direct-payment-${dp.id}`}
                      className="col-sm-4 my-2"
                    >
                      <Card
                        {...dp}
                        editable={dp.editable}
                        updateData={updateData}
                        relId={data.id}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-5">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <h2>Contrat de travail</h2>
                  </div>
                  <StyledEmploymentContract isEmpty={!data.contract?.url}>
                    {!data.contract ? (
                      <Link
                        to={{
                          pathname: `/employement-contract/new`,
                          state: {
                            jobberIsNotReady:
                              data.jobber.external && data.state !== "inactive",
                          },
                        }}
                        kind="tertiary"
                        disabled={data.state === "inactive"}
                        className="position-absolute top-0 bottom-0  px-3 text-center d-flex align-items-center justify-content-center"
                      >
                        <span>
                          <i className="icon-plus-circle mr-2" />
                          Ajouter un contrat de travail (optionnel)
                        </span>
                      </Link>
                    ) : (
                      <>
                        <Button.Link
                          onClick={() => setContractDetailsIsOpen(true)}
                          kind="tertiary"
                          block
                          className="position-absolute top-0 bottom-0  px-3 text-center d-flex align-items-center justify-content-center"
                        >
                          <span>Voir les détails du contrat de travail</span>
                        </Button.Link>
                        <ContractModal
                          id={data.id}
                          {...data.contract}
                          isOpen={contractDetailsIsOpen}
                          onClose={() => setContractDetailsIsOpen(false)}
                        />
                      </>
                    )}
                  </StyledEmploymentContract>
                </div>
                <div className="col-md-6 mb-5">
                  <div className="d-flex align-items-baseline justify-content-between">
                    <h2>Historique</h2>
                    <Link className="font-weight-medium" to="/actions_history">
                      Voir plus
                    </Link>
                  </div>
                  {data.direct_actions.map((d, index) => (
                    <List.Item
                      key={`actions-direct-${index}`}
                      chevron={!!d.url}
                      className="px-0"
                      href={d.url || null}
                      target={d.url ? "_blank" : null}
                      RightComponent={() =>
                        d.price && (
                          <List.Elem.Label>
                            {polyglot.toSmartCurrency(d.price)}
                          </List.Elem.Label>
                        )
                      }
                    >
                      <List.Elem.Title className="font-weight-medium">
                        {d.title}
                      </List.Elem.Title>
                      <List.Elem.Subtitle>{d.date_time}</List.Elem.Subtitle>
                    </List.Item>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-1">
              <StickyBox
                data={data}
                updateData={updateData}
                isLoading={isLoading}
                setPaymentMethodSuccess={setPaymentMethodSuccess}
              />
            </div>
            <SuccessModal
              onClose={() => {
                setSuccessIsOpen(false);
                history.replace("/");
              }}
              isOpen={successIsOpen}
              cesu={data.cesu}
            />
            <SuccessContractModal
              onClose={() => {
                setSuccessContratIsOpen(false);
                history.replace("/");
              }}
              isOpen={successContratIsOpen}
              title="Vos conditions ont été enregistrées"
              subtitle="Vous pourrez enregistrer votre contrat depuis votre compte ultérieurement."
            />
            <SuccessContractModal
              onClose={() => {
                setSuccessContratNewIsOpen(false);
                history.replace("/");
              }}
              isOpen={successContratNewIsOpen}
              title="Votre contrat de travail a été enregistré"
              subtitle="Celui-ci a été envoyé à votre prestataire, qui pourra y accéder depuis son compte."
            />
          </div>
          {paymentMethodSuccess && (
            <Toast
              title="Modification enregistrée"
              body="Votre moyen de paiement à été modifié avec succès"
              icon="check"
              type="success"
              isVisible={paymentMethodSuccess}
            />
          )}
        </>
      ) : (
        <RelationSkeleton />
      )}
    </Container.Large>
  );
};

export default Show;
