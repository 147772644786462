import { spacings } from "../../assets/themes";
import { Block, Breadcrumb, Link } from "../../components";
import { useBreakpoints } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import polyglot from "../../utils/polyglot";
import { DASHBOARD_PATH } from "../dashboard/config/routes";
import CrossSellCarrousel from "../dashboard/jobs/CrossSellCarrousel";
import CustomContainer from "./CustomContainer";
import JobHelpBox from "./JobHelpBox";
import JobComments from "./JobComments";
import JobInformations from "./JobInformations";
import JobOffers from "./JobOffers";
import JobJobberJoinBox from "./JobJobberJoinBox";

const Job = () => {
  const { data, isPoster } = useJob();
  const breakpoints = useBreakpoints();

  return (
    <div>
      <Block marginBottom={spacings.m}>
        <Block paddingY={spacings.m} display={{ xs: "none", md: "block" }}>
          {isPoster && (
            <CustomContainer>
              <Breadcrumb>
                <Link href={DASHBOARD_PATH}>{polyglot.t("routes.jobs")}</Link>
                <span>{data.title}</span>
              </Breadcrumb>
            </CustomContainer>
          )}
        </Block>
        <Block display="flex" flexDirection={{ xs: "column", md: "row" }}>
          {/* fix : minWidth="0" prevent Carrousel extend parent size */}
          <Block flex="1" minWidth="0">
            <Block width="100%">
              <JobInformations />
              {!isPoster && (
                <CustomContainer>
                  <Block marginTop={spacings.m}>
                    <JobJobberJoinBox />
                  </Block>
                </CustomContainer>
              )}
              <JobOffers />
            </Block>
          </Block>
          {breakpoints.get({ xs: false, md: true }) && (
            <Block
              min-width={{ xs: "inherit", md: "300px", lg: "375px" }}
              max-width={{ xs: "inherit", md: "300px", lg: "375px" }}
              width={{ xs: "inherit", md: "300px", lg: "375px" }}
              marginLeft={{ xs: 0, md: spacings.l }}
            >
              <JobComments id={data.id} inModal={false} />
              {isPoster && <JobHelpBox />}
            </Block>
          )}
        </Block>
      </Block>
    </div>
  );
};
export default Job;
