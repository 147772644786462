import React from 'react';
import { Button, Modal } from '../../../components';
import SimulateTaxCredit from '../../common/taxCreditModal/SimulateTaxCredit';

const PaymentDetailsModal = ({ onClose, isOpen, data }) => (
  <Modal.Small onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header title="Détails du paiement" onClose={onClose} />
    <Modal.Item.Wrapper>
      <div>
        <SimulateTaxCredit
          title={null}
          urssaf={data.urssaf}
          deduction={data.deduction}
          employee_part={data.employee_part}
          employer_part={data.employer_part}
          deduction_term={data.deduction_term}
          nb_hours={data.nb_hours}
          price_per_hour={data.price_per_hour}
          frequency={data.frequency}
        />
        <Button.Large block onClick={onClose} kind="secondary" className="mt-3">
          Fermer
        </Button.Large>
      </div>
    </Modal.Item.Wrapper>
  </Modal.Small>
);

export default PaymentDetailsModal;
