import React from "react";
import BaseApp from "../modules/BaseApp";
import AdminNotes from "../pages-admin/AdminNotes";

const AdminNotesApp = (props, rails) => () =>
  (
    <BaseApp exclude={["ReactQueryDevtools"]}>
      <AdminNotes {...props} />
    </BaseApp>
  );

export default AdminNotesApp;
