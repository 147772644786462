import React from "react";
import { sizes, spacings } from "../../assets/themes";
import { Block, Skeleton } from "../../components";
import { SKELETON } from "../../components/Styles/variants";

const PreviewElemSkeleton = () => (
  <Skeleton.Group>
    <Block marginX={spacings.ml}>
      <Block display="flex" alignItems="center" marginY={spacings.m}>
        <Skeleton
          shape={SKELETON.SHAPE.CIRCLE}
          width={sizes.size48}
          height={sizes.size48}
          sr
        />
        <Block flex="1">
          <Block marginBottom={spacings.s}>
            <Skeleton
              shape={SKELETON.SHAPE.CIRCLE}
              width={sizes.size96}
              height={sizes.size20}
            />
          </Block>
          <Skeleton
            shape={SKELETON.SHAPE.CIRCLE}
            width={sizes.size64}
            height={sizes.size16}
          />
        </Block>
        <Skeleton width={sizes.size64} height={sizes.size64} />
      </Block>
      <Skeleton height={sizes.size24} width="100%" sb rows={3} />
      <Skeleton height={sizes.size24} width="40%" sb />
    </Block>
  </Skeleton.Group>
);

export default PreviewElemSkeleton;
