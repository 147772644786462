import React, { useState } from "react";
import BaseApp from "../modules/BaseApp";
import { useListenToTargetClick } from "../modules/hooks";
import PublishJobModal from "../pages/dashboard/job/PublishJobModal";

const ModalEnhancer = ({ selector, id }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useListenToTargetClick(selector, () => setModalIsOpen(true));
  return (
    <PublishJobModal
      id={id}
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
    />
  );
};

const PublishJobModalApp = (props, rails) => () =>
  (
    <BaseApp exclude={["AskService"]} {...props}>
      <ModalEnhancer id={props.id} selector={props.selector} />
    </BaseApp>
  );

export default PublishJobModalApp;
