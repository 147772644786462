import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { colors, radius, shadows, sizes, spacings } from "../../assets/themes";
import { Icon } from "../../components";

const StyledCursorContent = styled.div`
  width: ${sizes.size24};
  height: ${sizes.size24};
  border-radius: ${radius.circle};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${spacings.sm};
  margin-left: ${spacings.sm};
  background: ${colors.background};
  box-shadow: ${shadows.s};
`;

const StyledCursor = styled.div`
  position: fixed;
  pointer-events: none;
  top: 0;
  left: 0;
  z-index: 9999999999;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s;
  transition-timing-function: ease-out;
`;

const PreviewCursor = ({ node }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 });

  const handleMove = (e) => {
    setCursorPosition({ top: e.clientY, left: e.clientX });
  };

  const handleOver = () => {
    setIsVisible(true);
  };
  const handleLeave = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMove);
    node?.addEventListener("mouseenter", handleOver);
    node?.addEventListener("mouseleave", handleLeave);
    return () => {
      node?.removeEventListener("mouseenter", handleOver);
      node?.removeEventListener("mouseleave", handleLeave);
      document.removeEventListener("mousemove", handleMove);
    };
  }, [node]);

  return ReactDOM.createPortal(
    isVisible ? (
      <StyledCursor
        style={{
          transform: `translate3d(${cursorPosition.left}px, ${cursorPosition.top}px, 0)`,
        }}
      >
        <StyledCursorContent>
          <Icon.Medium name="question-circle-solid" color={colors.danger} />
        </StyledCursorContent>
      </StyledCursor>
    ) : (
      <></>
    ),
    document.body
  );
};

export default PreviewCursor;
