import { Block, Body14, Body16, Card, Carousel } from "@/components";
import { TRUCKS } from "../categoryForms/constants";
import { borderWidth, colors, spacings } from "@/assets/themes";
import { useBreakpoints } from "@/modules/hooks";

const getTruck = (value) => TRUCKS.find((truck) => truck.value === value);

const JobberProfileTrucks = ({ data }) => {
  const breakpoints = useBreakpoints();

  return (
    <>
      <Carousel
        slideToShow={{ xs: 2.1, md: 2.5, lg: 3 }}
        gutter={spacings.s}
        draggable={breakpoints.get({ xs: true, md: false })}
      >
        {data.vehicles.map((truck) => (
          <Card
            headerImage={getTruck(truck).image}
            css={`
              border: solid ${borderWidth.s} ${colors.border};
            `}
          >
            <Block marginY={spacings.s}>
              <Body16 align="center">{getTruck(truck).label}</Body16>
              <Body14 align="center" color={colors.muted}>
                {getTruck(truck).subtitle}
              </Body14>
            </Block>
          </Card>
        ))}
      </Carousel>
    </>
  );
};

export default JobberProfileTrucks;
