import { colors, spacings } from "@/assets/themes";
import JobHasBeenDoneEnhancer from "@/pages/Job/JobHasBeenDoneModal";
import {
  getCancelOfferUrl,
  getInstantPaymentOfferUrl,
  getValidationOfferUrl,
} from "@/pages/Job/utils";
import polyglot from "@/utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import { BUTTON } from "../Styles/variants";
import { Body14 } from "../Text";
import JobberPaymentDoneTodayEnhancer from "@/pages/Job/JobberPaymentDoneTodayEnhancer";

const SelectedActions = ({ offer }) => (
  <Block display="flex" flexDirection="column">
    <Block marginBottom={spacings.s}>
      {offer.instant_payment_payable && (
        <JobberPaymentDoneTodayEnhancer
          last_instant_payment={offer.last_instant_payment}
        >
          <Button.Medium
            kind={BUTTON.KIND.TERTIARY}
            block
            href={getInstantPaymentOfferUrl(offer.instant_payment_relation_id)}
          >
            {polyglot.t("job.send_payment")}
          </Button.Medium>
        </JobberPaymentDoneTodayEnhancer>
      )}
      {offer.payable && (
        <JobHasBeenDoneEnhancer>
          <Button.Medium
            kind={BUTTON.KIND.TERTIARY}
            block
            href={getValidationOfferUrl(offer.id)}
          >
            {polyglot.t("job.payment_validation_amount", {
              total_price: polyglot.toSmartCurrency(offer.price, {
                noDecimal: true,
              }),
            })}
          </Button.Medium>
        </JobHasBeenDoneEnhancer>
      )}
    </Block>
    {offer.cancelable && (
      <Block flex="1">
        <Button.Medium
          block
          kind={BUTTON.KIND.SECONDARY}
          accentColor={BUTTON.ACCENT_COLOR.DANGER}
          href={getCancelOfferUrl(offer.id)}
          LeftComponent={() => <Icon.Large name="user-times" />}
        >
          {polyglot.t("jobs.change_or_cancel_first_name", {
            first_name: offer.jobber.first_name,
          })}
        </Button.Medium>
      </Block>
    )}
    {offer.instant_payment_payable && (
      <Block marginTop={spacings.sm}>
        <Body14 color={colors.muted} align="center">
          {polyglot.t("job.booked_informations", {
            price_per_hour: polyglot.toSmartCurrency(offer.price_per_hour, {
              pricePerHour: true,
            }),
          })}
        </Body14>
      </Block>
    )}
  </Block>
);
export default SelectedActions;
