import BaseApp from "../modules/BaseApp";
import SendAnalyticsEvent from "../pages/SendAnalyticsEvent";

const SendAnalyticsEventApp = (props, rails) => () =>
  (
    <BaseApp>
      <SendAnalyticsEvent {...props} />
    </BaseApp>
  );

export default SendAnalyticsEventApp;
