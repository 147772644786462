import { useState } from "react";
import { useSelector } from "react-redux";
import { Block, Container } from "../../components";
import JobSkeleton from "../../pages/Job/skeletons/JobSkeleton";
import JobSuccessModal from "../../pages/jobSuccess/JobSuccessModal";
import polyglot from "../../utils/polyglot";
import { JobContext } from "../contexts/index";
import { useBreakpoints } from "../hooks";
import { useGetJob, useGetPendingOffers } from "../routes/job-routes";

const JobProvider = ({ children, public_pages, show_success, jobbers }) => {
  const { data, isSuccess } = useGetJob();
  const [showSuccessModal, setShowSuccessModal] = useState(show_success);
  const { current_user } = useSelector((state) => state.rails);
  const pendingOffers = useGetPendingOffers();
  const breakpoints = useBreakpoints();

  const Wrapper = breakpoints.get({ xs: Block, md: Container.Large });

  const SORT_LIST = [
    {
      title: polyglot.t("job.filter.recommended.name"),
      subtitle: polyglot.t("job.filter.recommended.subtitle"),
      value: "recommended",
    },
    {
      title: polyglot.t("job.filter.pricing.name"),
      value: "pricing",
    },
    {
      title: polyglot.t("job.filter.rating.name"),
      value: "rating",
    },
    {
      title: polyglot.t("job.filter.recency.name"),
      value: "recency",
    },
  ];

  return (
    <JobContext.Provider
      value={{
        isPoster:
          isSuccess && current_user && current_user.id === data.poster?.id,
        data,
        sortList: SORT_LIST,
        public_pages,
        pendingOffers,
      }}
    >
      {!isSuccess && !data ? (
        <Wrapper>
          <JobSkeleton />
        </Wrapper>
      ) : (
        <>
          <Wrapper>{children}</Wrapper>
          {showSuccessModal && (
            <JobSuccessModal
              onClose={() => setShowSuccessModal(false)}
              isOpen={showSuccessModal}
              avg_time_to_first_offer={data.avg_time_to_first_offer}
              jobbers={jobbers}
              location={data.location}
              private_jobber={data.private_jobber}
            />
          )}
        </>
      )}
    </JobContext.Provider>
  );
};
export default JobProvider;
