import { spacings } from "../../../assets/themes";
import { Alert, Block } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

const config = {
  IsNbHoursReadOnly: true,
};

export function useCategory1054() {
  const DEFAULT_NB_HOURS = 3;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      View: (props) => (
        <>
          <NB_HOURS_SELECTION_PAGE.View {...props}>
            <Block marginTop={spacings.sm}>
              <Alert.Low>
                {polyglot.t("categories.tiling_services_not_accepted")}
              </Alert.Low>
            </Block>
          </NB_HOURS_SELECTION_PAGE.View>
        </>
      ),
      customProps: {
        options: [
          {
            label: polyglot.t("categories.small_surface"),
            value: 2,
            subtitle: polyglot.t("categories.example_toilet"),
          },
          {
            label: polyglot.t("categories.medium_surface"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.example_bathroom"),
            popular: true,
          },
          {
            label: polyglot.t("categories.large_surface"),
            value: 4,
            subtitle: polyglot.t("categories.example_kitchen"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1,
    initialValues,
    config,
  };
}
