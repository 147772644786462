import styled, { css } from "styled-components";
import { colors, radius, spacings } from "../../assets/themes";
import { PROGRESS } from "../Styles/variants";
import Block from "../Block";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  > div {
    flex: 1;
  }
`;

const StyledBar = styled.div`
  ${({ value, shape }) => css`
    position: relative;
    background-color: ${colors.border};
    height: 4px;
    min-width: 40px;
    border-radius: ${shape === PROGRESS.SHAPE.ROUND ? radius.circle : "0px"};
    overflow: hidden;
    &::before {
      content: "";
      width: ${value}%;
      transition: 0.5s ease-in-out;
      height: 4px;
      position: absolute;
      background-color: ${colors.primary};
    }
  `}
`;

function setProgressBar(value, steps) {
  const stepValue = 100 / steps;
  const fullBars = Math.floor(value / stepValue);
  const bars = new Array(steps).fill(0);
  for (let i = 0; i < fullBars; i += 1) {
    bars[i] = 100; // 100% rempli
  }
  if (fullBars < steps && value % stepValue > 0) {
    bars[fullBars] = (value % stepValue) * steps;
  }
  return bars;
}

const Bar = ({ value, LeftComponent, RightComponent, steps, ...rest }) => (
  <StyledWrapper>
    {LeftComponent && (
      <div
        css={`
          flex: initial !important;
          margin-right: ${spacings.xs};
        `}
      >
        {LeftComponent()}
      </div>
    )}
    <Block display="flex" gap={spacings.xs}>
      {steps ? (
        setProgressBar(value, steps).map((v, i) => (
          <StyledBar
            role="progressbar"
            value={v}
            key={`progress-bar-${v}-step-${i}`}
            {...rest}
          />
        ))
      ) : (
        <StyledBar role="progressbar" value={value} {...rest} />
      )}
    </Block>
    {RightComponent && (
      <div
        css={`
          flex: initial !important;
          margin-left: ${spacings.xs};
        `}
      >
        {RightComponent()}
      </div>
    )}
  </StyledWrapper>
);

export default Bar;
