import { Field } from "formik";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  CardSelectable,
  Col,
  ConditionnalWrapper,
  Icon,
  Row,
  Tag,
} from "../../../../components";
import { TAG } from "../../../../components/Styles/variants";
import polyglot from "../../../../utils/polyglot";

const StyledTagWrapper = styled.div`
  border-radius: ${radius.circle};
  border: solid ${borderWidth.m} ${colors.background};
  ${"" /* re-align by reduce border width */}
  margin-top: calc(${borderWidth.m} * -1);
`;

const StyledRecommendedPosition = styled.div`
  top: calc(
    ((${sizes.size24} / 2) + ${spacings.s} + (${borderWidth.m} / 2)) * -1
  );
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const FormCardSelectables = ({ options = [], name, onChange, scrollable }) => (
  <ConditionnalWrapper
    condition={scrollable}
    wrapper={(children) => (
      <Block marginTop={`calc(${spacings.s} * -1)`}>
        <Block
          marginX={`calc(${spacings.m} * -1)`}
          overflowX="auto"
          overflowY="hidden"
          display="flex"
          paddingTop={spacings.s}
        >
          <Block marginX={spacings.m}>{children}</Block>
        </Block>
      </Block>
    )}
  >
    <Row
      gutter={[spacings.s]}
      display={scrollable ? "inline-flex" : "flex"}
      wrap={scrollable ? "nowrap" : "wrap"}
    >
      {options?.map(
        (option, i) =>
          !option.disabled && (
            <Col
              key={`selectable-item-${name}-${i}`}
              size={{
                xs: scrollable ? "auto" : 6,
                sm: scrollable ? "auto" : 4,
              }}
            >
              <Field
                name={name}
                value={option.value}
                type="button"
                role="radio"
              >
                {({ field, form }) => (
                  <CardSelectable
                    {...field}
                    css={scrollable && "width: 37vw; max-width: 180px"}
                    checked={field.value === option.value}
                    role="radio"
                    image={option.image}
                    onClick={(e) => {
                      if (onChange) onChange(option.value, form);
                      form.setFieldTouched(field.name, true);
                      form.setFieldValue(field.name, option.value);
                    }}
                  >
                    <Block position="relative">
                      {option.recommended && (
                        <StyledRecommendedPosition>
                          <StyledTagWrapper>
                            {typeof option.recommended === "function" ? (
                              option.recommended()
                            ) : (
                              <Tag.Small
                                kind={TAG.KIND.WARNING}
                                LeftComponent={() => (
                                  <Icon.Small name="thumb-up-solid" />
                                )}
                              >
                                {polyglot.t("common.recommended")}
                              </Tag.Small>
                            )}
                          </StyledTagWrapper>
                        </StyledRecommendedPosition>
                      )}
                      <Body16 strong>{option.label}</Body16>
                      {option.subtitle && (
                        <Body14
                          color={colors.muted}
                          style={{ fontWeight: 400 }}
                        >
                          {option.subtitle}
                        </Body14>
                      )}
                    </Block>
                  </CardSelectable>
                )}
              </Field>
            </Col>
          )
      )}
    </Row>
  </ConditionnalWrapper>
);

export default FormCardSelectables;
