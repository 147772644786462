import React from "react";
import styled from "styled-components";
import { useFormikContext } from "formik";
import FormSelect from "./FormSelect";
import { Block, Icon } from "../../../../components";
import { spacings } from "../../../../assets/themes";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    flex: 1;
  }
  div:last-child {
    flex: 1;
  }
`;

const FormFromTo = ({
  options,
  names = ["", ""],
  LeftComponents = [],
  RightComponents = [],
  onChange,
  placeholders = [],
}) => {
  const { errors, touched } = useFormikContext();
  return (
    <StyledWrapper>
      <FormSelect
        error={errors[names[0]] && touched[names[0]]}
        name={names[0]}
        placeholder={placeholders[0]}
        options={options[0]}
        LeftComponent={LeftComponents[0]}
        RightComponent={RightComponents[0]}
        onChange={(e, form) => {
          if (onChange) onChange({ [names[0]]: e }, form);
        }}
        css={`
          width: 100%;
        `}
      />
      <Block marginX={spacings.m}>
        <Icon.Large name="arrow-right" />
      </Block>
      <FormSelect
        error={errors[names[1]] && touched[names[1]]}
        name={names[1]}
        placeholder={placeholders[1]}
        options={options[1]}
        LeftComponent={LeftComponents[1]}
        RightComponent={RightComponents[1]}
        onChange={(e, form) => {
          if (onChange) onChange({ [names[1]]: e }, form);
        }}
        css={`
          width: 100%;
        `}
      />
    </StyledWrapper>
  );
};

export default FormFromTo;
