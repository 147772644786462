import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body14,
  Button,
  FormControl,
  Icon,
  Info,
  Input,
  Modal,
} from "../../../components";
import { BUTTON, INPUT } from "../../../components/Styles/variants";
import { useToast } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";

const CODE = "1472804*8";

const ECesuModal = ({ isOpen, onClose, reference }) => {
  const toast = useToast();

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value);
    toast.remove();
    toast.success(polyglot.t("common.copy_success"));
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("cesu.new.add_e_cesu")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <FormControl label={polyglot.t("cesu.new.e_cesu_step_1_label")}>
          <Block display="flex" flexWrap="wrap">
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-bottom: ${spacings.s};
              `}
              kind={BUTTON.KIND.TERTIARY}
              rel="noopener noreferrer"
              target="_blank"
              href="https://ebeneficiaire.cesu-as.fr/login.aspx"
            >
              Edenred
            </Button.Medium>
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-bottom: ${spacings.s};
              `}
              kind={BUTTON.KIND.TERTIARY}
              target="_blank"
              rel="noopener noreferrer"
              href="https://edomiserve.com/sidomi/compte/authentificationBeneficiaire.do"
            >
              Domiserve
            </Button.Medium>
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-bottom: ${spacings.s};
              `}
              kind={BUTTON.KIND.TERTIARY}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cheque-domicile-universel.com/espace-beneficiaire/startup.do"
            >
              Groupe Up
            </Button.Medium>
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-bottom: ${spacings.s};
              `}
              kind={BUTTON.KIND.TERTIARY}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cesudomalin.com/jcms/sk_6521/fr/cesu"
            >
              Domalin
            </Button.Medium>
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-bottom: ${spacings.s};
              `}
              kind={BUTTON.KIND.TERTIARY}
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cesu-sodexo.fr/"
            >
              Sodexo
            </Button.Medium>
          </Block>
        </FormControl>
        <FormControl
          label={() => (
            <>
              <span>
                {polyglot.t("cesu.new.e_cesu_step_2_label_1")}{" "}
                <strong>{polyglot.t("cesu.new.e_cesu_step_2_label_2")} </strong>
                {polyglot.t("cesu.new.e_cesu_step_2_label_3")}
                <Info
                  css={`
                    display: inline-block;
                  `}
                >
                  {polyglot.t("cesu.new.e_cesu_find_helper")}
                </Info>
              </span>
            </>
          )}
        >
          <Input
            name="code"
            size={INPUT.SIZE.LARGE}
            value={CODE}
            readOnly
            css={`
              font-weight: var(--font-weight-bold);
            `}
            RightComponent={() => (
              <Button.Medium
                onClick={() => handleCopy(CODE)}
                LeftComponent={() => <Icon.Large name="copy" />}
                kind={BUTTON.KIND.SECONDARY}
              >
                {polyglot.t("common.copy")}
              </Button.Medium>
            )}
          />
        </FormControl>
        <FormControl label={polyglot.t("cesu.new.e_cesu_step_3_label")}>
          <Input
            name="reference"
            size={INPUT.SIZE.LARGE}
            value={reference}
            readOnly
            css={`
              font-weight: var(--font-weight-bold);
            `}
            RightComponent={() => (
              <Button.Medium
                onClick={() => handleCopy(reference)}
                LeftComponent={() => <Icon.Large name="copy" />}
                kind={BUTTON.KIND.SECONDARY}
              >
                {polyglot.t("common.copy")}
              </Button.Medium>
            )}
          />
        </FormControl>
        <Body14 color={colors.muted}>
          {polyglot.t("cesu.new.legal_informations")}
        </Body14>
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large block kind={BUTTON.KIND.SECONDARY} onClick={onClose}>
          {polyglot.t("common.finish")}
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.Small>
  );
};

export default ECesuModal;
