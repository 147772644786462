import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import Relation from "../pages/Relation";

const RelationApp = (props, rails) => () =>
  (
    <BaseApp exclude={["AskService"]}>
      <Router basename={rails.pathname}>
        <Relation {...props} />
      </Router>
    </BaseApp>
  );

export default RelationApp;
