import { Block, Col, Row, Skeleton } from "@/components";
import { sizes, spacings } from "../../../assets/themes";

const TaxesCreditSkeleton = () => (
  <Skeleton.Group>
    <Skeleton height={sizes.size48} width="250px" sb />
    <Skeleton height={sizes.size16} width="100%" sb />
    <Skeleton height={sizes.size16} width="150px" sb />
    <Block paddingTop={spacings.ml}>
      <Skeleton height={sizes.size20} width="200px" sb />
    </Block>
    <Row gutter={{ xs: 0, lg: spacings.l }}>
      <Col size={{ xs: 12, lg: 5 }} order={{ xs: "0", lg: "1" }}>
        {new Array(10).fill("").map((_, i) => (
          <Skeleton key={`tax-box-skel-${i}`} height={350} width="100%" sb />
        ))}
      </Col>
      <Col size={{ xs: 12, lg: 7 }}>
        <Skeleton height={250} width="100%" sb />
        <Skeleton height={250} width="100%" sb />
        <Skeleton height={500} width="100%" sb />
      </Col>
    </Row>
  </Skeleton.Group>
);

export default TaxesCreditSkeleton;
