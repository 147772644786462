import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import { TRUCKS } from "../../pages/categoryForms/constants";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import List from "../List";
import { LIST } from "../Styles/variants";
import { Body14, Body16 } from "../Text";

const MAX_COLLAPSED_VISIBLE_TRUCK = 2;

const getTruck = (value) => TRUCKS.find((truck) => truck.value === value);

const Vehicle = ({ value }) => (
  <List.Item
    divider={false}
    withGutters
    size={LIST.SIZE.COMPACT}
    LeftComponent={() => (
      <Block
        background={`${colors.backgroundLight} url(${
          getTruck(value).image
        }) center/contain no-repeat`}
        borderRadius={radius.m}
        border={`solid ${borderWidth.s} ${colors.border}`}
        width={sizes.size48}
        height={sizes.size48}
      />
    )}
  >
    <Body16 strong>{getTruck(value).label}</Body16>
    <Body14 color={colors.muted}>{getTruck(value).subtitle}</Body14>
  </List.Item>
);

const Trucks = ({ vehicles }) => {
  const [showMore, setShowMore] = useState(false);

  const variants = {
    open: { height: "auto", transition: { duration: 0.2 } },
    closed: { height: 0, transition: { duration: 0.2 } },
  };

  return (
    <Block
      backgroundColor={colors.backgroundLight}
      paddingY={spacings.xs}
      borderRadius={radius.ml}
      overflow="hidden"
    >
      <Block marginX={spacings.m} marginTop={spacings.s}>
        <Body16 color={colors.muted}>Camions ({vehicles?.length})</Body16>
      </Block>
      {vehicles.slice(0, MAX_COLLAPSED_VISIBLE_TRUCK).map((vehicle) => (
        <Vehicle value={vehicle} key={`vehicles-${vehicle}`} />
      ))}
      <AnimatePresence>
        {showMore && (
          <motion.div
            initial="closed"
            animate="open"
            exit="closed"
            variants={variants}
            style={{ overflow: "hidden" }}
          >
            {vehicles.slice(MAX_COLLAPSED_VISIBLE_TRUCK).map((vehicle) => (
              <Vehicle value={vehicle} key={`vehicles-${vehicle}`} />
            ))}
          </motion.div>
        )}
      </AnimatePresence>
      {vehicles.length > MAX_COLLAPSED_VISIBLE_TRUCK && (
        <Block marginX={spacings.m}>
          <Button.Link
            onClick={() => setShowMore(!showMore)}
            css={`
              padding-left: 0;
              padding-right: 0;
            `}
          >
            {showMore
              ? polyglot.t("common.see_less")
              : polyglot.t("common.see_more")}
          </Button.Link>
        </Block>
      )}
    </Block>
  );
};
export default Trucks;
