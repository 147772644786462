import { useLocalStorage } from "../../modules/hooks";

export function useCategoryFormLocalStorage(id) {
  const name = `category-form`;
  const [localStorageData, setItem] = useLocalStorage(name, {});

  return {
    get: () => {
      const data = { ...localStorageData?.[id] };
      return data;
    },
    set: (values) => {
      // create new array  { ...values } = fix strange bug that format values in formik too when set in cookies
      const formatedValues = { ...values };
      // remove photos bc blob cant be saved in localStorage
      delete formatedValues.photos;
      delete formatedValues.uploads;
      delete formatedValues.user;
      delete formatedValues.alert_setting;
      delete formatedValues.price_per_hour;
      // set cookies from formik values
      setItem({ [id]: formatedValues });
    },
    remove: () => {
      setItem({});
    },
  };
}
