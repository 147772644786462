import { useMemo, useRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "styled-components";
import { spacings } from "../../assets/themes";
import { useBreakpoints, useOnClickOutside } from "../../modules/hooks";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import Input from "../Input";
import Popover from "../popover/Popover";
import PortalContent from "../popover/PortalContent";
import { BUTTON, CALENDAR, POPOVER } from "../Styles/variants";
import Calendar from "./Calendar";
import {
  formatTo,
  getDatePickerMaxDate,
  getDatePickerMinDate,
  getDayIsDisabled,
  getInitialValue,
  getMaskByFormat,
  getReorderedRange,
  isValid,
  toDate,
} from "./utils";

const StyledRangeWrapper = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    flex: 1;
  }
  div:last-child {
    flex: 1;
  }
`;

const StyledReferenceElement = styled.div`
  border: 0;
  outline: 0;
  background: none;
  padding: 0;
  margin: 0;
  flex: 1;
`;

const StyledDatePickerPopover = styled(Popover.Elem.Menu)`
  padding-left: ${spacings.sm};
  padding-right: ${spacings.sm};
`;

const getArrayValue = (v, n) => v && (typeof v === "string" ? v : v[n]);

const DatePicker = ({
  onChange,
  onMonthChange,
  onYearChange,
  range = false,
  name,
  monthsShown = 1,
  value,
  format = "YYYY-MM-DD",
  formatString = "DD/MM/YYYY",
  disabledDate,
  disabledWeekDays,
  disabled,
  minRange,
  maxDate = getDatePickerMaxDate(),
  minDate = getDatePickerMinDate(maxDate),
  placeholder = "jj/mm/aaaa",
  position = POPOVER.POSITIONS.BOTTOM,
  onBlur,
  onFocus,
  error,
  activeMonth,
  size,
  ...rest
}) => {
  const breakpoints = useBreakpoints();
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const endInputRef = useRef();
  const inputRef = useRef();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [invalidDate, setInvalidDate] = useState(null);
  const mask = useMemo(() => getMaskByFormat(formatString), [formatString]);
  const [inputValues, setInputValues] = useState(
    getInitialValue(value, format, formatString)
  );
  const [selectedDates, setSelectedDates] = useState(
    getInitialValue(value, format, formatString)
  );
  const openMenuOnFocus = breakpoints.get({ xs: false, sm: true });

  const { styles, state } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: "flip",
        options: {
          padding: 8,
          flipVariations: true,
        },
      },
      {
        name: "preventOverflow",
        options: {
          altBoundary: false,
          padding: 8,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  const handleClose = () => {
    setMenuIsOpen(false);
  };

  const handleOpen = () => {
    if (!disabled) {
      setMenuIsOpen(true);
    }
  };

  const handleFocus = (e) => {
    if (openMenuOnFocus) handleOpen();
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e) => {
    if (onBlur) onBlur(e);
  };

  const handleCloseMenu = (e) => {
    const isOutside =
      !popperElement?.contains(e?.target) &&
      !referenceElement?.contains(e?.target);
    if (isOutside) {
      handleClose();
    }
  };

  useOnClickOutside({ current: popperElement }, handleCloseMenu);
  useOnClickOutside({ current: referenceElement }, handleCloseMenu);

  const handleKeyDown = (e) => {
    if (e.key === "Tab" && menuIsOpen) {
      handleCloseMenu();
    }
    if (e.key === "Escape") {
      e.preventDefault();
      handleCloseMenu();
    }
  };

  const handleChange = (v) => {
    const newDates = range
      ? v.map((a) => formatTo(a, formatString, format))
      : formatTo(v[0], formatString, format);
    if (onChange) onChange(newDates);
  };

  const valueToArray = (value, kind) => {
    let res;
    if (kind === 1) {
      res = [inputValues[0] || "", value];
    } else if (kind === 0) {
      res = [value, inputValues[1] || ""];
    }
    if (
      res[0] &&
      isValid(res[0], formatString) &&
      res[1] &&
      isValid(res[1], formatString)
    ) {
      return getReorderedRange(res, formatString);
    }
    return res;
  };

  const handleDayClick = (v) => {
    const formatedValue = typeof v === "string" ? [v] : v;
    handleChange(formatedValue);
    setInvalidDate(false);
    setInputValues(formatedValue);
    setSelectedDates(formatedValue);
    if (!range || v[1]?.length > 0) {
      setMenuIsOpen(false);
    } else if (endInputRef.current) {
      endInputRef.current.inputElement?.focus();
    }
  };

  const handleInputChange = (e, kind) => {
    const { value } = e.target;
    setInputValues(valueToArray(value, kind));
    setInvalidDate(false);
    if (
      value &&
      isValid(value, formatString) &&
      !getDayIsDisabled(toDate(value, formatString), {
        minRange,
        minDate,
        maxDate,
        disabledDate,
        disabledWeekDays,
        value: selectedDates.map((s) => toDate(s, formatString)),
      })
    ) {
      setSelectedDates(valueToArray(value, kind));
      handleChange(valueToArray(value, kind));
    } else {
      setSelectedDates(valueToArray("", kind));
      handleChange(valueToArray("", kind));
      setInvalidDate(true);
    }
  };

  const handleCalendarClick = (e) => {
    e.stopPropagation();
    setMenuIsOpen((s) => !s);
  };

  return (
    <StyledRangeWrapper>
      <StyledReferenceElement
        role="button"
        ref={setReferenceElement}
        onKeyDown={handleKeyDown}
        tabIndex={-1}
      >
        <Block display="flex" alignItems="center">
          <Input
            RightComponent={() => (
              <Button.Small
                kind={BUTTON.KIND.MINIMAL}
                disabled={disabled}
                shape={BUTTON.SHAPE.CIRCLE}
                style={{ background: "transparent" }}
                onClick={handleCalendarClick}
                marginLeft={spacings.s}
              >
                {menuIsOpen ? (
                  <Icon.Large name="calendar-solid" />
                ) : (
                  <Icon.Large name="calendar" />
                )}
              </Button.Small>
            )}
            name={name}
            mask={mask}
            onChange={(e) => handleInputChange(e, 0)}
            value={inputValues?.[0] || ""}
            inputMode="numeric"
            ref={inputRef}
            guide
            onFocus={handleFocus}
            onBlur={handleBlur}
            size={size}
            disabled={disabled}
            error={invalidDate || error}
            placeholder={getArrayValue(placeholder)}
            {...rest}
          />
          {range && (
            <>
              <Block marginX={spacings.m}>
                <Icon.Large name="arrow-right" />
              </Block>
              <Input
                RightComponent={() => (
                  <Button.Small
                    kind={BUTTON.KIND.MINIMAL}
                    disabled={disabled}
                    shape={BUTTON.SHAPE.CIRCLE}
                    style={{ background: "transparent" }}
                    onClick={handleCalendarClick}
                    marginLeft={spacings.s}
                  >
                    {menuIsOpen ? (
                      <Icon.Large name="calendar-solid" />
                    ) : (
                      <Icon.Large name="calendar" />
                    )}
                  </Button.Small>
                )}
                name={name}
                mask={mask}
                onChange={(e) => handleInputChange(e, 1)}
                value={inputValues?.[1] || ""}
                inputMode="numeric"
                ref={endInputRef}
                guide
                size={size}
                onFocus={handleFocus}
                onBlur={handleBlur}
                disabled={disabled}
                error={invalidDate || error}
                placeholder={getArrayValue(placeholder)}
                {...rest}
              />
            </>
          )}
        </Block>
      </StyledReferenceElement>
      <PortalContent
        position={state?.placement || position}
        isVisible={menuIsOpen}
        styles={styles}
        ref={setPopperElement}
        noPortal
      >
        <StyledDatePickerPopover>
          <Calendar
            size={CALENDAR.SIZE.COMPACT}
            canSwitchDate
            monthsShown={monthsShown}
            value={selectedDates}
            minRange={minRange}
            minDate={minDate}
            maxDate={maxDate}
            disabledDate={disabledDate}
            disabledWeekDays={disabledWeekDays}
            onMonthChange={onMonthChange}
            onYearChange={onYearChange}
            format={formatString}
            activeMonth={activeMonth}
            onChange={handleDayClick}
            range={range}
          />
        </StyledDatePickerPopover>
      </PortalContent>
    </StyledRangeWrapper>
  );
};

export default DatePicker;
