import { colors, sizes } from "../../../assets/themes";
import { Block, Button } from "../../../components";
import { cssVarToNumber } from "../../../components/Styles/Helper";
import { BUTTON } from "../../../components/Styles/variants";
import Progress from "../../../components/progress";
import { useBreakpoints, useGoogleAnalytics } from "../../../modules/hooks";
import { getHomeBannerPressEvent } from "../../../utils/analytics-events";

// const StyledVipImg = styled.img`
//   filter: ${({ active }) => !active && "grayscale(100%)"};
//   opacity: ${({ active }) => !active && "0.5"};
// `;

const VipButton = ({ vip_status = {}, children, onClick, ...rest }) => {
  // const { public_pages } = useContext(DashboardContext);
  const { sendEvent } = useGoogleAnalytics();
  const breakpoints = useBreakpoints();

  const handleClick = (e) => {
    sendEvent(getHomeBannerPressEvent("vip_banner"));
    if (onClick) onClick(e);
  };
  return (
    <Block position="relative">
      <Block
        position="absolute"
        top="0"
        left="0"
        zIndex="1"
        pointerEvents="none"
      >
        <Progress.Circular
          size={cssVarToNumber(
            breakpoints.get({ xs: sizes.size48, md: sizes.size54 })
          )}
          value={vip_status.progress}
          thickness={2}
          css={`
            stroke: ${colors.purple}!important;
            transition: 0s !important;
          `}
        />
      </Block>
      <Block zIndex="-1">
        {breakpoints.get({
          xs: (
            <Button.Medium
              shape={BUTTON.SHAPE.CIRCLE}
              kind={BUTTON.KIND.MINIMAL}
              // href={public_pages.vip}
              onClick={handleClick}
              {...rest}
            >
              {children}
            </Button.Medium>
          ),
          md: (
            <Button.Large
              shape={BUTTON.SHAPE.CIRCLE}
              kind={BUTTON.KIND.MINIMAL}
              // href={public_pages.vip}
              onClick={handleClick}
              {...rest}
            >
              {children}
            </Button.Large>
          ),
        })}
      </Block>
    </Block>
  );
};
export default VipButton;
