/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { getTransactionDetailsPath } from "@/utils/get-transaction-details-path";
import { colors, radius, spacings } from "../../assets/themes";
import { OFFER_STATE_CANCELED, OFFER_STATE_PAID } from "../../pages/Job/utils";
import TransactionDetailsModal from "../../pages/dashboard/account/TransactionDetailsModal";
import { formatDate } from "../../utils";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import { Body16 } from "../Text";

const OfferTransactionDetails = ({ offer, renderLink }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpen = () => {
    setModalIsOpen(true);
  };

  const handleClose = () => {
    setModalIsOpen(false);
  };

  return (offer.state === OFFER_STATE_CANCELED &&
    offer.canceled_at &&
    !offer.instant_payment_relation_id) ||
    (offer.state === OFFER_STATE_PAID &&
      offer.paid_at &&
      !offer.instant_payment_relation_id) ||
    (offer.last_instant_payment && offer.last_instant_payment?.date) ? (
    <Block
      backgroundColor={colors.backgroundLight}
      padding={spacings.sm}
      borderRadius={radius.ml}
    >
      <Body16 color={colors.muted}>
        {offer.state === OFFER_STATE_CANCELED &&
          !offer.instant_payment_relation_id && (
            <span>
              {polyglot.t("dashboard.job.canceled_at", {
                date: formatDate(offer.canceled_at, "DD/MM/YYYY"),
              })}
            </span>
          )}
        {offer.state === OFFER_STATE_PAID &&
          !offer.instant_payment_relation_id && (
            <span>
              {polyglot.t("dashboard.job.paid_at", {
                date: formatDate(offer.paid_at, "DD/MM/YYYY"),
              })}
            </span>
          )}
        {offer.last_instant_payment?.date && (
          <span>
            {polyglot.t("dashboard.job.last_paid_at", {
              date: formatDate(offer.last_instant_payment.date, "DD/MM/YYYY"),
            })}
          </span>
        )}
        &nbsp;•&nbsp;
        {renderLink ? (
          renderLink({ label: polyglot.t("jobs.see_transaction_details") })
        ) : (
          <>
            <a
              href="#"
              onClick={handleOpen}
              css={`
                padding-left: 0;
                padding-right: 0;
                color: ${colors.primary};
                font-weight: var(--font-weight-medium);
                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {polyglot.t("jobs.see_transaction_details")}
            </a>
            <TransactionDetailsModal
              isOpen={modalIsOpen}
              pathname={getTransactionDetailsPath(offer)}
              onClose={handleClose}
            />
          </>
        )}
      </Body16>
    </Block>
  ) : (
    <></>
  );
};
export default OfferTransactionDetails;
