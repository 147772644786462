import { Field, Form, Formik } from "formik";
import TaxesCreditImage from "images/tax-credit-artwork.svg";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import * as yup from "yup";
import { useEffect } from "react";
import { sizes, spacings } from "@/assets/themes";
import {
  Alert,
  Block,
  Button,
  Col,
  DatePicker,
  FormControl,
  Icon,
  Info,
  Input,
  Modal,
  Row,
  Selectable,
  Tag,
} from "@/components";
import { BUTTON, TAG } from "@/components/Styles/variants";
import { usePutTaxesAccount } from "@/modules/routes/dashboard-routes";
import AddressModal from "@/pages/common/AddressModal";
import {
  getDateOfBirthActiveMonth,
  getDateOfBirthMaxDate,
  setAddressInitialValues,
} from "@/utils";
import polyglot from "@/utils/polyglot";

const validationSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  address: yup.string().required(),
  sex: yup.number().required(),
  date_of_birth: yup.date().required(),
});

const ENTRIES = ["/", "/address"];

const DisabledButton = () => (
  <Info icon="lock">
    {polyglot.t("personal_informations.contact_us_to_edit")}
  </Info>
);

const TaxesModal = ({ isOpen, onClose, initialValues = {} }) => {
  const isEditable = initialValues.can_edit_identity_informations;

  const putTaxes = usePutTaxesAccount();

  const handleSubmit = (values) => {
    putTaxes.mutate(values);
  };

  useEffect(() => {
    if (putTaxes.isSuccess) {
      onClose();
    }
  }, [putTaxes.isSuccess]);

  const getIsDisabled = (name) => !(isEditable || initialValues[name] === null);

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Formik
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        initialValues={{
          sex: initialValues.sex || "",
          first_name: initialValues.first_name || "",
          last_name: initialValues.last_name || "",
          date_of_birth: initialValues.date_of_birth || "",
          ...setAddressInitialValues(initialValues),
        }}
      >
        {({ values, isValid }) => (
          <Form>
            <MemoryRouter initialEntries={ENTRIES}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={({ history }) => (
                    <>
                      <Modal.Item.Header onClose={onClose}>
                        <Modal.Item.Title>
                          {polyglot.t("common.contact_details")}
                        </Modal.Item.Title>
                        <Modal.Item.Subtitle>
                          {polyglot.t("taxes.contact_details_description")}
                        </Modal.Item.Subtitle>
                      </Modal.Item.Header>

                      <Modal.Item.Wrapper>
                        <FormControl
                          label={() => (
                            <Block display="flex" alignItems="center">
                              <span>{polyglot.t("common.user_title")}</span>
                              {getIsDisabled("sex") && <DisabledButton />}
                            </Block>
                          )}
                        >
                          <Row gutter={[spacings.s]}>
                            <Col size={{ xs: 12, md: 6 }}>
                              <Field type="radio" name="sex" value={2}>
                                {({ field, form }) => (
                                  <Selectable.Medium
                                    {...field}
                                    disabled={getIsDisabled(field.name)}
                                    onChange={() =>
                                      form.setFieldValue(field.name, 2)
                                    }
                                  >
                                    {polyglot.t("common.madam")}
                                  </Selectable.Medium>
                                )}
                              </Field>
                            </Col>
                            <Col size={{ xs: 12, md: 6 }}>
                              <Field type="radio" name="sex" value={1}>
                                {({ field, form }) => (
                                  <Selectable.Medium
                                    {...field}
                                    disabled={getIsDisabled(field.name)}
                                    onChange={() =>
                                      form.setFieldValue(field.name, 1)
                                    }
                                  >
                                    {polyglot.t("common.sir")}
                                  </Selectable.Medium>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </FormControl>
                        <Field name="first_name">
                          {({ field }) => (
                            <FormControl
                              label={() => (
                                <Block display="flex" alignItems="center">
                                  <span>{polyglot.t("common.first_name")}</span>
                                  {getIsDisabled(field.name) && (
                                    <DisabledButton />
                                  )}
                                </Block>
                              )}
                            >
                              <Input
                                disabled={getIsDisabled(field.name)}
                                placeholder={polyglot.t(
                                  "common.placeholder.first_name"
                                )}
                                {...field}
                              />
                            </FormControl>
                          )}
                        </Field>
                        <Field name="last_name">
                          {({ field }) => (
                            <FormControl
                              label={() => (
                                <Block display="flex" alignItems="center">
                                  <span>{polyglot.t("common.last_name")}</span>
                                  {getIsDisabled(field.name) && (
                                    <DisabledButton />
                                  )}
                                </Block>
                              )}
                            >
                              <Input
                                disabled={getIsDisabled(field.name)}
                                placeholder={polyglot.t(
                                  "common.placeholder.last_name"
                                )}
                                {...field}
                              />
                            </FormControl>
                          )}
                        </Field>
                        <Field name="date_of_birth">
                          {({ field, form }) => (
                            <FormControl
                              label={() => (
                                <Block display="flex" alignItems="center">
                                  <span>
                                    {polyglot.t("common.date_of_birth")}
                                  </span>
                                  {getIsDisabled("date_of_birth") && (
                                    <DisabledButton />
                                  )}
                                </Block>
                              )}
                            >
                              <DatePicker
                                {...field}
                                disabled={getIsDisabled("date_of_birth")}
                                onChange={(date) =>
                                  form.setFieldValue(field.name, date)
                                }
                                activeMonth={getDateOfBirthActiveMonth()}
                                maxDate={getDateOfBirthMaxDate()}
                              />
                            </FormControl>
                          )}
                        </Field>

                        <FormControl label={polyglot.t("common.address")}>
                          <Input
                            placeholder={polyglot.t(
                              "common.placeholder.address"
                            )}
                            value={values.address}
                            onClick={() => history.push("/address")}
                            RightComponent={() => (
                              <Icon.Medium name="dropdown" />
                            )}
                          />
                        </FormControl>
                      </Modal.Item.Wrapper>
                      <Modal.Item.Footer>
                        <Button.Large
                          kind={BUTTON.KIND.MINIMAL}
                          onClick={onClose}
                        >
                          {polyglot.t("common.cancel")}
                        </Button.Large>
                        <Button.Large
                          isLoading={putTaxes.isLoading}
                          disabled={!isValid}
                          type="submit"
                        >
                          {polyglot.t("common.save")}
                        </Button.Large>
                      </Modal.Item.Footer>
                    </>
                  )}
                />
                <Route
                  path="/address"
                  render={({ history }) => (
                    <AddressModal
                      inContent
                      onBack={history.goBack}
                      onClose={onClose}
                      // TODO
                    />
                  )}
                />
              </Switch>
            </MemoryRouter>
          </Form>
        )}
      </Formik>
    </Modal.Small>
  );
};

export default TaxesModal;
