import { useFormikContext } from "formik";
import { spacings } from "@/assets/themes";
import { Block, H3, H4 } from "@/components";
import { useBreakpoints } from "@/modules/hooks";
import ExtraRatingInput from "./ExtraRatingInput";

const ExtraRatingPage = ({ title, fields, index }) => {
  const { values, setFieldValue } = useFormikContext();
  const breakpoints = useBreakpoints();

  return (
    <Block>
      <Block marginBottom={spacings.m}>
        {breakpoints.get({
          xs: <H4>{title}</H4>,
          md: <H3>{title}</H3>,
        })}
      </Block>
      <Block display="flex" flexDirection="column" gap={spacings.sm}>
        {fields.map((field) => (
          <ExtraRatingInput
            key={`${field.value}-extra-rate-flow-${index}`}
            onChange={() => {
              setFieldValue(field.name, field.value);
            }}
            checked={values[field.name] === field.value}
            name={field.name}
            title={field.title}
            subtitle={field.subtitle}
          />
        ))}
      </Block>
    </Block>
  );
};
export default ExtraRatingPage;
