import { Field, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  FormControl,
  Icon,
  Input,
  Modal,
  Select,
} from "../../../components";

const Home = ({
  onClose,
  customStatus,
  isLoading,
  initialValues,
  inContent,
  onBack,
  linkedErrors,
  tax_deductions,
}) => {
  const { isValid, values, errors, touched, setFieldError, setFieldValue } =
    useFormikContext();

  useEffect(() => {
    setFieldError("date_of_birth", linkedErrors.date_of_birth);
  }, [linkedErrors.date_of_birth]);

  const history = useHistory();
  return (
    <>
      <Modal.Item.Header
        title="Votre profil fiscal"
        subtitle="Complétez ou vérifiez vos informations personnelles."
        onClose={onClose}
        onBack={inContent && onBack}
      />
      <Modal.Item.Wrapper>
        {customStatus && <Alert.Low kind="primary" title={customStatus} />}
        <Field name="date_of_birth">
          {({ field }) => (
            <FormControl label="Votre date de naissance">
              <Input
                guide
                error={
                  touched.date_of_birth && errors.date_of_birth
                    ? errors.date_of_birth
                    : null
                }
                placeholder="24/12/1990"
                mask={[
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  "/",
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                ]}
                disabled={!initialValues.user?.can_edit_identity_informations}
                {...field}
              />
            </FormControl>
          )}
        </Field>
        <div className="row">
          <div className="col-md-6">
            <Field name="first_name">
              {({ field }) => (
                <FormControl label="Votre prénom">
                  <Input
                    error={
                      touched.first_name && errors.first_name
                        ? errors.first_name
                        : null
                    }
                    placeholder="Jean"
                    disabled={
                      !initialValues.user?.can_edit_identity_informations
                    }
                    {...field}
                  />
                </FormControl>
              )}
            </Field>
          </div>
          <div className="col-md-6">
            <Field name="last_name">
              {({ field }) => (
                <FormControl label="Votre nom">
                  <Input
                    error={
                      touched.last_name && errors.last_name
                        ? errors.last_name
                        : null
                    }
                    placeholder="Dupond"
                    disabled={
                      !initialValues.user?.can_edit_identity_informations
                    }
                    {...field}
                  />
                </FormControl>
              )}
            </Field>
          </div>
        </div>
        <FormControl label="Votre adresse">
          <Input
            onClick={() => history.push("/address")}
            defaultValue={values.address}
            placeholder="123 rue du bourg neuf, 75001 Paris"
            RightComponent={() => <Icon.Medium name="dropdown" />}
          />
        </FormControl>
        <FormControl label="Abattement fiscal (optionnel)">
          <Select
            name="tax_deduction"
            clearable
            placeholder={tax_deductions[0].translation}
            value={values.tax_deduction}
            onChange={(option) =>
              setFieldValue("tax_deduction", option ? option.value : "")
            }
            options={tax_deductions.map((t) => ({
              label: t.translation,
              value: t.key || "",
            }))}
          />
        </FormControl>
        <Button.Large
          isLoading={isLoading}
          block
          type="submit"
          disabled={!isValid}
        >
          Confirmer
        </Button.Large>
        <Button.Large
          block
          kind="minimal"
          className="mt-2"
          onClick={onClose || onBack}
        >
          Annuler
        </Button.Large>
      </Modal.Item.Wrapper>
    </>
  );
};

export default Home;
