import { useEffect } from "react";
import { colors, sizes, spacings } from "../../../../assets/themes";
import { Block, Body16, Divider, H3 } from "../../../../components";
import { DIVIDER } from "../../../../components/Styles/variants";
import { getTruckByValue } from "../../constants";

const TruckResult = ({ value, onChange }) => {
  const result = getTruckByValue(value);

  useEffect(() => {
    if (onChange) onChange(result);
  }, [result]);

  return (
    <Block display="flex" alignItems="center" justifyContent="center">
      <Block flex="1">
        <Block
          background={`url(${result.image}) center/contain no-repeat`}
          width="100%"
          paddingBottom="100%"
        />
      </Block>
      <Block height={sizes.size48} marginRight={spacings.m} display="flex">
        <Divider.Cell orientation={DIVIDER.ORIENTATION.VERTICAL} />
      </Block>
      <Block flex="1">
        <H3>{result.label}</H3>
        <Body16 color={colors.muted}>{result.subtitle}</Body16>
      </Block>
    </Block>
  );
};
export default TruckResult;
