import React, { useEffect, useState } from "react";
import { sizes, colors } from "../../../assets/themes";
import { List, Modal, Spinner, Avatar, Icon } from "../../../components";
import { getPaymentDetails } from "../../../modules/routes";
import { formatDate, renderStatusBagde } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const ShowPaymentDetailsModal = ({ onClose, isOpen, paymentId, id }) => {
  const [data, setData] = useState(null);
  const getServices = () =>
    data.services.map((service) => service.translation).join(", ");
  useEffect(() => {
    if (isOpen) {
      const res = getPaymentDetails({ id, paymentId });
      res
        .then(({ data }) => {
          setData(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    return () => {
      setData(null);
    };
  }, [paymentId]);
  return (
    <Modal.Small onClose={onClose} isOpen={isOpen} fullScreenOnMobile>
      {data ? (
        <>
          <Modal.Item.Header onClose={onClose} />
          <Modal.Item.Wrapper>
            <div className="d-flex flex-column align-items-center justify-content-center mx-3">
              <Avatar
                size={sizes.size96}
                src={data.jobber.avatar}
                name={data.jobber.first_name}
              />
              <p className="font-weight-medium font-size-4 text-center mt-2 mb-1">
                {getServices()}
              </p>
              <p className="font-weight-medium font-size-6 text-center">
                -{polyglot.toSmartCurrency(data.amount)}
              </p>
            </div>
            <List.Header>Informations complémentaires</List.Header>
            <List.Item
              title="Statut"
              RightComponent={() => renderStatusBagde(data.state)}
            />
            {data.payment_date && (
              <List.Item
                title="Date du paiement"
                RightComponent={() => (
                  <List.Elem.Label>
                    {formatDate(data.payment_date, "DD/MM/YYYY", "DD/MM/YYYY")}
                  </List.Elem.Label>
                )}
              />
            )}
            {data.paid_date && (
              <List.Item
                title="Payé le"
                RightComponent={() => (
                  <List.Elem.Label>
                    {formatDate(data.paid_date, "DD/MM/YYYY", "DD/MM/YYYY")}
                  </List.Elem.Label>
                )}
              />
            )}
            <List.Header>Rémunération prestataire</List.Header>
            <List.Item
              title="Durée"
              RightComponent={() => (
                <List.Elem.Label>{data.nb_hours}</List.Elem.Label>
              )}
            />
            <List.Item
              title="Taux horaire"
              RightComponent={() => (
                <List.Elem.Label>
                  {polyglot.toSmartCurrency(data.price_per_hour)}
                </List.Elem.Label>
              )}
            />
            <List.Item
              title="Rémunération prestataire"
              RightComponent={() => (
                <List.Elem.Label>
                  {polyglot.toSmartCurrency(data.jobber_price)}
                </List.Elem.Label>
              )}
            />
            {data.fees && (
              <List.Item
                title="Frais de service"
                RightComponent={() => (
                  <List.Elem.Label>
                    {polyglot.toSmartCurrency(data.fees)}
                  </List.Elem.Label>
                )}
              />
            )}
            <List.Item
              title="Charges sociales"
              RightComponent={() => (
                <List.Elem.Label>
                  {polyglot.toSmartCurrency(data.taxes)}
                </List.Elem.Label>
              )}
            />
            {(data.paid_cesu_amount > 0 ||
              data.paid_visa_mastercard_amount > 0 ||
              data.paid_iban_amount > 0) && (
              <List.Header>Détails du paiement</List.Header>
            )}
            {data.paid_cesu_amount > 0 && (
              <List.Item
                title="Payé en CESU"
                RightComponent={() => (
                  <List.Elem.Label>
                    {polyglot.toSmartCurrency(data.paid_cesu_amount)}
                  </List.Elem.Label>
                )}
              />
            )}
            {data.paid_visa_mastercard_amount > 0 && (
              <List.Item
                title="Payé par carte bancaire"
                RightComponent={() => (
                  <List.Elem.Label>
                    {polyglot.toSmartCurrency(data.paid_visa_mastercard_amount)}
                  </List.Elem.Label>
                )}
              />
            )}
            {data.paid_iban_amount > 0 && (
              <List.Item
                title="Payé par IBAN"
                RightComponent={() => (
                  <List.Elem.Label>
                    {polyglot.toSmartCurrency(data.paid_iban_amount)}
                  </List.Elem.Label>
                )}
              />
            )}
            {data.invoice_url && (
              <>
                <List.Header>Documents et factures</List.Header>
                <List.Item
                  href={data.invoice_url}
                  target="_blank"
                  LeftComponent={() => (
                    <Icon.Large name="download" color={colors.primary} />
                  )}
                >
                  <List.Elem.Title strong color={colors.primary}>
                    Attestation de paiement
                  </List.Elem.Title>
                </List.Item>
              </>
            )}
          </Modal.Item.Wrapper>
        </>
      ) : (
        <div className="d-flex align-items-center my-3">
          <Spinner.Large className="mx-auto text-muted" />
        </div>
      )}
    </Modal.Small>
  );
};

export default ShowPaymentDetailsModal;
