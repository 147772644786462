import React from 'react';
import ErrorBoundary from '../utils/ErrorBoundary';
import { default as ZoomOnHoverImg } from '../components/ZoomOnHover';

const ZoomOnHover = (props, rails) => () => (
  <ErrorBoundary>
    <ZoomOnHoverImg {...props} />
  </ErrorBoundary>
);

export default ZoomOnHover;
