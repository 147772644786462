import styled, { css } from "styled-components";
import { Caption } from "../Text";
import { spacings, colors } from "../../assets/themes";
import { ButtonBase } from "../Styles/Base";

const ItemWrapper = styled.div`
  ${({ active }) =>
    active &&
    css`
      i {
        color: ${colors.body}!important;
      }
    `}
  flex: 1;
  i {
    color: ${colors.mutedLight};
  }
  button {
    padding: ${spacings.xs};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const Item = ({ IconComponent, name, label, active, onClick }) => (
  <ItemWrapper name={name} active={active}>
    <ButtonBase onClick={onClick}>
      {IconComponent && (
        <div
          css={`
            padding-top: ${spacings.xs};
          `}
        >
          {IconComponent()}
        </div>
      )}
      <Caption
        strong
        numberOfLines={1}
        color={active ? colors.body : colors.mutedLight}
      >
        {label}
      </Caption>
    </ButtonBase>
  </ItemWrapper>
);

export default Item;
