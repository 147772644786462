import moment from "moment";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import { useDeleteDateTimeChange } from "../../modules/routes/dashboard-routes";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";
import Avatar from "../../components/avatar";
import Block from "../../components/Block";
import Button from "../../components/Button";
import Icon from "../../components/Icon";
import List from "../../components/List";
import Modal from "../../components/Modal";
import { BUTTON, TAG } from "../../components/Styles/variants";
import Tag from "../../components/Tag";
import { Body16 } from "../../components/Text";

moment.locale("fr");

const StyledWrapper = styled.div`
  border-radius: ${radius.m};
  background-color: ${colors.backgroundLight};
  padding-bottom: ${spacings.s};
`;

const StyledListDate = styled(List.Item)`
  padding-left: ${spacings.s};
  padding-right: ${spacings.m};
  i {
    margin-right: ${spacings.xs};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      span {
        text-decoration: line-through !important;
      }
    `}
`;

const renderTag = (status, hours_until_expiration) => {
  if (status === "pending") {
    return (
      <Tag.Medium kind={TAG.KIND.WHITE}>
        {polyglot.t("common.remaining", {
          value: hours_until_expiration,
        })}
      </Tag.Medium>
    );
  }
  if (status === "expired") {
    return (
      <Tag.Medium kind={TAG.KIND.WHITE}>
        {polyglot.t("common.expired")}
      </Tag.Medium>
    );
  }
  if (status === "refused") {
    return (
      <Tag.Medium kind={TAG.KIND.DANGER}>
        {polyglot.t("common.refused")}
      </Tag.Medium>
    );
  }
  return (
    <Tag.Medium kind={TAG.KIND.SUCCESS}>
      {polyglot.t("common.accepted")}
    </Tag.Medium>
  );
};

const VoteStatus = ({ jobbers, status, oneHasVoted }) => {
  const refusedUser = jobbers.filter(
    (jobber) => jobber.status === "refused"
  )[0];
  const acceptedUsers = jobbers.filter(
    (jobber) => jobber.status === "accepted"
  );
  const acceptedUsersFirstName = acceptedUsers.map((u) => u.first_name);
  const renderAcceptedPhrase = (amount) =>
    amount === 1
      ? polyglot.t("new_date_time_change.widget.user_has_accepted", {
          user: acceptedUsers[0].first_name,
        })
      : polyglot.t("new_date_time_change.widget.users_has_accepted", {
          user: acceptedUsersFirstName.slice(0, -1).join(", "),
          user1: acceptedUsersFirstName.pop(),
        });

  const renderStatusPhrase = () => {
    if (status === "pending" && acceptedUsers.length === 0) {
      return polyglot.t(
        "new_date_time_change.widget.waiting_for_response_plural",
        {
          count: jobbers.length,
        }
      );
    }
    if (status === "refused") {
      return polyglot.t("new_date_time_change.widget.user_has_declined", {
        user: refusedUser?.first_name,
      });
    }
    return renderAcceptedPhrase(acceptedUsers.length);
  };

  return (
    <>
      {status !== "expired" && (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          paddingX={spacings.m}
          paddingTop={spacings.s}
        >
          <Block display="flex" alignItems="center">
            {oneHasVoted && (
              <Icon.Medium
                name={
                  status === "refused"
                    ? "times-circle-solid"
                    : "check-circle-solid"
                }
                color={status === "refused" ? colors.danger : colors.success}
              />
            )}
            <Body16 color={colors.muted}>{renderStatusPhrase()}</Body16>
          </Block>
          {status === "pending" && jobbers.length > 1 && (
            <Body16 color={colors.muted}>
              {acceptedUsers.length}/{jobbers.length}
            </Body16>
          )}
        </Block>
      )}
    </>
  );
};

const ChatDateTimeChange = ({
  id,
  discussable_id,
  user,
  body,
  date_time_change,
}) => {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const cancelDateTimeChange = useDeleteDateTimeChange();

  const oneHasVoted =
    date_time_change.jobbers.filter(
      (jobber) => jobber.status === "accepted" || jobber.status === "refused"
    ).length > 0;

  const requestIsOver =
    date_time_change.status === "expired" ||
    date_time_change.status === "refused";

  const openConfirmDelete = () => {
    setConfirmIsOpen(true);
  };

  const handleCloseDialog = () => {
    setConfirmIsOpen(false);
  };

  return (
    <StyledWrapper>
      <Block
        marginX={spacings.s}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Body16
          color={colors.muted}
          css={`
            margin: ${spacings.s};
          `}
        >
          {polyglot.t("new_date_time_change.widget.title")}
        </Body16>
        {date_time_change.status === "pending" && (
          <Button.Link
            disabled={oneHasVoted}
            kind={BUTTON.KIND.MINIMAL}
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            onClick={openConfirmDelete}
          >
            {polyglot.t("common.cancel")}
          </Button.Link>
        )}
      </Block>
      <Block
        marginX={spacings.s}
        backgroundColor={colors.background}
        borderRadius={radius.m}
        paddingTop={spacings.s}
      >
        <Block paddingX={spacings.m}>
          <Block
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
            paddingY={spacings.s}
          >
            <Block display="flex" alignItems="center">
              <Avatar
                src={user?.avatar}
                width={sizes.size36}
                name={user?.first_name}
              />
              <Body16
                css={`
                  margin-left: ${spacings.s};
                `}
              >
                {user.first_name}
              </Body16>
            </Block>
            {renderTag(
              date_time_change.status,
              date_time_change.hours_until_expiration
            )}
          </Block>
          {body && (
            <Body16
              css={`
                word-wrap: break-word;
                white-space: break-spaces;
              `}
            >
              {body}
            </Body16>
          )}
        </Block>
        <StyledListDate
          disabled={requestIsOver}
          RightComponent={() => (
            <Block display="flex" alignItems="center">
              <Icon.Large name="clock" />
              <Body16 strong>{date_time_change.new_start_hour}</Body16>
            </Block>
          )}
        >
          <Block display="flex" alignItems="center">
            <Icon.Large name="calendar" />
            <List.Elem.Title strong>
              {capitalize(
                moment(date_time_change.new_date, "YYYY-MM-DD").format(
                  "dddd DD MMMM"
                )
              )}
            </List.Elem.Title>
          </Block>
        </StyledListDate>

        <StyledListDate
          divider={false}
          disabled={!requestIsOver}
          RightComponent={() => (
            <Block display="flex" alignItems="center">
              <Icon.Large name="clock" />
              <Body16 strong>{date_time_change.old_start_hour}</Body16>
            </Block>
          )}
        >
          <Block display="flex" alignItems="center">
            <Icon.Large name="calendar" />
            <List.Elem.Title strong>
              {capitalize(
                moment(date_time_change.old_date, "YYYY-MM-DD").format(
                  "dddd DD MMMM"
                )
              )}
            </List.Elem.Title>
          </Block>
        </StyledListDate>
      </Block>
      <VoteStatus
        jobbers={date_time_change.jobbers}
        status={date_time_change.status}
        oneHasVoted={oneHasVoted}
      />
      <Modal.XSmall isOpen={confirmIsOpen} onClose={handleCloseDialog}>
        <Modal.Item.Header onClose={handleCloseDialog}>
          <Modal.Item.Title>
            {polyglot.t("new_date_time_change.confirm_cancel.title")}
          </Modal.Item.Title>
        </Modal.Item.Header>
        <Modal.Item.Wrapper>
          <Body16>
            {polyglot.t("new_date_time_change.confirm_cancel.body")}
          </Body16>
        </Modal.Item.Wrapper>
        <Modal.Item.Footer>
          <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={handleCloseDialog}>
            {polyglot.t("common.cancel")}
          </Button.Medium>
          <Button.Medium
            kind={BUTTON.KIND.PRIMARY}
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            isLoading={
              cancelDateTimeChange.isLoading || cancelDateTimeChange.isSuccess
            }
            onClick={() =>
              cancelDateTimeChange.mutate({
                id: discussable_id,
                date_time_id: date_time_change.id,
              })
            }
          >
            {polyglot.t("common.remove")}
          </Button.Medium>
        </Modal.Item.Footer>
      </Modal.XSmall>
    </StyledWrapper>
  );
};

export default ChatDateTimeChange;
