import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import { useCategoryForm } from "../../../modules/hooks";
import {
  INSTANT_GO_NEXT_DELAY,
  setDestinationAddressInitialValues,
} from "../../../utils";
import polyglot from "../../../utils/polyglot";
import {
  FormSelectables,
  FormStepper,
  StyledFormControl,
} from "../common/fields";
import StepTitle from "../common/StepTitle";
import {
  ADDRESS_PATH,
  DATE_PATH,
  DESTINATION_PROPERTY_TYPE_PATH,
  DISTANCE_QUERY,
  FLOORS,
  INFORMATIONS_PATH,
  PROPERTY_TYPE_PATH,
  START_ADDRESS_PATH,
  START_HOUR_PATH,
} from "../constants";
import {
  ADDRESS_DETAILS_PAGES,
  ADDRESS_PAGE,
  APARTMENT_PAGES,
  DESTINATION_APARTMENT_PAGES,
  DESTINATION_HOUSE_PAGES,
  DESTINATION_STORAGE_PAGES,
  HOUSE_PAGES,
  STORAGE_PAGES,
} from "../defaults";
import { addObjectAtIndex } from "../utils";
import RecommendationView from "./3003/RecommendationWrapper";

const config = {
  IsNbHoursReadOnly: true,
  renderPages: (pages) => {
    const newPages = [...pages];
    const dateIndex = pages.findIndex((p) => p.path === DATE_PATH);
    newPages.splice(dateIndex, 0, {
      path: "recommendation",
      View: RecommendationView,
      validationSchema: yup.object().shape({}),
    });
    return newPages;
  },
};

const validationSchema = yup.object().shape({
  nh_specific2: yup
    .array()
    .test(
      "one-is-more-than-zero",
      (value) => value[0] + value[1] + value[2] + value[3] > 0
    ),
});

export function useCategory3003() {
  const queryClient = useQueryClient();
  const version = 2;

  const initialValues = {
    nh_specific2: [0, 0, 0, 0],
    nh_specific3: "",
    nh_specific: "",
  };

  const nhConfig = {
    nh_specific3: {
      label: polyglot.t("categories.jobber_bring_own_truck"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 1.0, label: polyglot.t("common.yes") },
      ],
    },
    "nh_specific2[0]": {
      label: polyglot.t("categories.small_size"),
      description: polyglot.t("categories.small_size_description"),
      options: [
        { value: 0.0, label: 0 },
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
        { value: 4.0, label: 4 },
        { value: 5.0, label: 5 },
        { value: 6.0, label: 6 },
        { value: 7.0, label: 7 },
        { value: 8.0, label: 8 },
        { value: 9.0, label: 9 },
        { value: 10.0, label: 10 },
      ],
    },
    "nh_specific2[1]": {
      label: polyglot.t("categories.medium_size"),
      description: polyglot.t("categories.medium_size_description"),
      options: [
        { value: 0.0, label: 0 },
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
        { value: 4.0, label: 4 },
        { value: 5.0, label: 5 },
        { value: 6.0, label: 6 },
        { value: 7.0, label: 7 },
        { value: 8.0, label: 8 },
        { value: 9.0, label: 9 },
        { value: 10.0, label: 10 },
      ],
    },
    "nh_specific2[2]": {
      label: polyglot.t("categories.large_size"),
      description: polyglot.t("categories.large_size_description"),
      options: [
        { value: 0.0, label: 0 },
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
        { value: 4.0, label: 4 },
        { value: 5.0, label: 5 },
        { value: 6.0, label: 6 },
        { value: 7.0, label: 7 },
        { value: 8.0, label: 8 },
        { value: 9.0, label: 9 },
        { value: 10.0, label: 10 },
      ],
    },
    "nh_specific2[3]": {
      label: polyglot.t("categories.very_large_size"),
      description: polyglot.t("categories.very_large_size_description"),
      options: [
        { value: 0.0, label: 0 },
        { value: 1.0, label: 1 },
        { value: 2.0, label: 2 },
        { value: 3.0, label: 3 },
        { value: 4.0, label: 4 },
        { value: 5.0, label: 5 },
        { value: 6.0, label: 6 },
        { value: 7.0, label: 7 },
        { value: 8.0, label: 8 },
        { value: 9.0, label: 9 },
        { value: 10.0, label: 10 },
      ],
    },
    nh_specific: {
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 1.0, label: polyglot.t("common.yes") },
      ],
    },
  };

  const getRawDuration = ({
    nh_specific,
    nh_specific2,
    floor,
    lift,
    destination_floor,
    destination_lift,
  }) => {
    let res = 0;
    let volume = 0;

    if (nh_specific2[0]) {
      volume += nh_specific2[0] * 0.1;
    }
    if (nh_specific2[1]) {
      volume += nh_specific2[1] * 0.25;
    }
    if (nh_specific2[2]) {
      volume += nh_specific2[2] * 0.5;
    }
    if (nh_specific2[3]) {
      volume += nh_specific2[3] * 1;
    }
    const basicDuration = volume;
    let additionnalHours = 0;
    if (nh_specific) {
      if (floor && !lift) {
        additionnalHours =
          basicDuration *
          FLOORS.find((f) => f.value === floor)?.nbHoursAccumulator;
      }
      if (destination_floor && !destination_lift) {
        additionnalHours +=
          basicDuration *
          FLOORS.find((f) => f.value === destination_floor)?.nbHoursAccumulator;
      }
    }
    res = additionnalHours + basicDuration;

    return res;
  };

  const getNbJobbers = (values) => {
    const res = getRawDuration(values);
    if (res <= 6) {
      return 1;
    }
    if (res <= 12) {
      return 2;
    }
    if (res <= 24) {
      return 3;
    }
    return 4;
  };

  const getNbHours = (values) => {
    let res = getRawDuration(values);
    // eslint-disable-next-line no-underscore-dangle
    const nbJobbers = getNbJobbers(values);

    const durationInSeconds = queryClient.getQueryData([
      [
        DISTANCE_QUERY,
        values.address_location,
        values.lift,
        values.floor,
        values.destination_address_location,
        values.destination_lift,
        values.destination_floor,
      ],
    ])?.duration_in_seconds;
    if (
      values.address_location &&
      values.destination_address_location &&
      durationInSeconds
    ) {
      res += (durationInSeconds / 3600) * nbJobbers;
    }
    return Math.ceil(res * 2) / 2 / nbJobbers;
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.move_furniture_question"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl>
            <FormStepper
              name="nh_specific2[0]"
              title={nhConfig["nh_specific2[0]"].label}
              subtitle={nhConfig["nh_specific2[0]"].description}
              options={nhConfig["nh_specific2[0]"].options}
            />
            <FormStepper
              name="nh_specific2[1]"
              title={nhConfig["nh_specific2[1]"].label}
              subtitle={nhConfig["nh_specific2[1]"].description}
              options={nhConfig["nh_specific2[1]"].options}
            />
            <FormStepper
              name="nh_specific2[2]"
              title={nhConfig["nh_specific2[2]"].label}
              subtitle={nhConfig["nh_specific2[2]"].description}
              options={nhConfig["nh_specific2[2]"].options}
            />
            <FormStepper
              name="nh_specific2[3]"
              title={nhConfig["nh_specific2[3]"].label}
              subtitle={nhConfig["nh_specific2[3]"].description}
              options={nhConfig["nh_specific2[3]"].options}
            />
          </StyledFormControl>
        </>
      ),
    },
    {
      path: "same-destination",
      validationSchema: yup.object().shape({
        nh_specific: yup.number().required(),
      }),
      initialValues: { nh_specific: "" },
      View: ({ index }) => {
        const JOBBER_TRUCK_PATH = "jobber-own-truck";
        const { setPages, pages } = useCategoryForm();
        const { setValues, values } = useFormikContext();
        // get all path from addressDetailsPages in array;
        const needDestinationPages = useMemo(
          () => [
            ...ADDRESS_DETAILS_PAGES.map((p) => {
              if (p.path === PROPERTY_TYPE_PATH) {
                p.title = polyglot.t("categories.what_is_job_start_address");
              }
              if (p.path === DESTINATION_PROPERTY_TYPE_PATH) {
                p.title = polyglot.t(
                  "categories.what_is_job_destination_address"
                );
              }
              return p;
            }),
            {
              path: JOBBER_TRUCK_PATH,
              validationSchema: yup.object().shape({
                nh_specific3: yup.number().required(),
              }),
              View: ({ goNext }) => (
                <>
                  <StepTitle>{nhConfig.nh_specific3.label}</StepTitle>
                  <StyledFormControl>
                    <FormSelectables
                      onChange={() =>
                        setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)
                      }
                      options={nhConfig.nh_specific3.options}
                      name="nh_specific3"
                    />
                  </StyledFormControl>
                </>
              ),
            },
          ],
          []
        );

        const filterArray = [
          ...needDestinationPages.map((obj) => obj.path),
          ...[
            ...APARTMENT_PAGES,
            ...HOUSE_PAGES,
            ...STORAGE_PAGES,
            ...DESTINATION_APARTMENT_PAGES,
            ...DESTINATION_HOUSE_PAGES,
            ...DESTINATION_STORAGE_PAGES,
          ].map((obj) => obj.path),
          ADDRESS_PAGE.path,
        ];

        const handleChange = (value) => {
          if (value === 0) {
            setValues(
              (values) => ({
                ...values,
                nh_specific3: "",
                destination_address_location: [],
                destination_property_type: null,
                destination_floor: null,
                destination_lift: null,
                ...setDestinationAddressInitialValues(),
              }),
              false
            );
          }
        };

        const updatePages = (value) => {
          // clear all pages that can be added or deleted -> filterArray
          let newPages = pages.filter((p) => !filterArray.includes(p.path));
          if (value === 1) {
            if (newPages.findIndex((p) => p.path === START_ADDRESS_PATH) < 0) {
              // update pages
              newPages = addObjectAtIndex(
                newPages,
                needDestinationPages,
                index
              );
            }
          }
          if (value === 0) {
            if (newPages.findIndex((p) => p.path === ADDRESS_PATH) < 0) {
              const startHourIndex = newPages.findIndex(
                (p) => p.path === START_HOUR_PATH
              );
              newPages = addObjectAtIndex(
                newPages,
                ADDRESS_PAGE,
                startHourIndex
              );
            }
          }
          setPages(newPages);
        };

        useEffect(() => {
          updatePages(values.nh_specific);
        }, [values.nh_specific]);

        const getPlural = () => {
          let count = 0;
          if (values.nh_specific2[0]) {
            count += values.nh_specific2[0];
          }
          if (values.nh_specific2[1]) {
            count += values.nh_specific2[1];
          }
          if (values.nh_specific2[2]) {
            count += values.nh_specific2[2];
          }
          if (values.nh_specific2[3]) {
            count += values.nh_specific2[3];
          }
          return count;
        };

        return (
          <>
            <StepTitle>
              {polyglot.t(
                "categories.furnitures_destination_is_another_address_question_plural",
                { count: getPlural() }
              )}
            </StepTitle>
            <StyledFormControl>
              <FormSelectables
                onChange={handleChange}
                options={nhConfig.nh_specific.options}
                name="nh_specific"
              />
            </StyledFormControl>
          </>
        );
      },
    },
  ];

  return {
    pages,
    version,
    initialValues,
    nhConfig,
    getNbHours,
    getNbJobbers,
    config,
  };
}
