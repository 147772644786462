import {
  AsYouType,
  isValidNumber,
  parsePhoneNumber,
  getExampleNumber,
} from "libphonenumber-js";
import React, { useState } from "react";
import examples from "libphonenumber-js/examples.mobile.json";
import { spacings } from "../assets/themes";
import polyglot from "../utils/polyglot";
import Block from "./Block";
import Input from "./Input";
import CountriesSelect from "./phoneInput/CountriesSelect";

const formatCountryForLibPhoneNumber = (country) =>
  country?.toUpperCase() === "UK" ? "GB" : country?.toUpperCase();

const formatCountryForPhoneInput = (country) =>
  country?.toUpperCase() === "GB" ? "UK" : country?.toUpperCase();

const getDefaultCountry = (value) => {
  if (value && isValidNumber(value)) {
    return parsePhoneNumber(value)?.country;
  }
  return polyglot.country.toUpperCase();
};

const formatNumber = (value = "", country) => {
  if (value && isValidNumber(value, country)) {
    return parsePhoneNumber(value, country).number;
  }
  return value;
};

const formatNational = (value = "", country) => {
  if (value && isValidNumber(value, country)) {
    return parsePhoneNumber(value, country).formatNational();
  }
  return value;
};

const PhoneInput = React.forwardRef(
  ({ name, onChange, error, value, id, autoFocus, size, ...rest }, ref) => {
    const [country, setCountry] = useState(getDefaultCountry(value));
    const [inputValue, setInputValue] = useState(
      formatNational(value, formatCountryForLibPhoneNumber(country))
    );

    const handleChange = (e) => {
      let newVal = e.target.value;
      if (newVal.length > 1) {
        newVal = new AsYouType(formatCountryForLibPhoneNumber(country)).input(
          newVal
        );
      }
      if (onChange)
        onChange(formatNumber(newVal, formatCountryForLibPhoneNumber(country)));
      const caret = e.target.selectionStart;
      let acc = 0;
      if (newVal.length > caret && newVal.length - caret === 1) {
        acc = newVal.length - caret;
      }
      const element = e.target;
      setInputValue(newVal);
      window.requestAnimationFrame(() => {
        element.selectionStart = caret + acc;
        element.selectionEnd = caret + acc;
      });
    };

    const handleCountryChange = (newCountry) => {
      const formated = formatNational(
        inputValue,
        formatCountryForLibPhoneNumber(newCountry)
      );
      const newVal = new AsYouType(
        formatCountryForLibPhoneNumber(newCountry)
      ).input(formated);
      setCountry(newCountry);
      setInputValue(newVal);
      if (onChange)
        onChange(
          formatNumber(formated, formatCountryForLibPhoneNumber(newCountry))
        );
    };

    return (
      <Block display="flex" alignItems="center">
        <Block marginRight={spacings.s}>
          <CountriesSelect
            value={formatCountryForPhoneInput(country)}
            onChange={handleCountryChange}
            size={size}
          />
        </Block>
        <Input
          type="tel"
          name={name}
          id={id}
          ref={ref}
          inputMode="numeric"
          placeholder={formatNational(
            getExampleNumber(formatCountryForLibPhoneNumber(country), examples)
              ?.number,
            formatCountryForLibPhoneNumber(country)
          )}
          autoComplete="phone"
          error={error}
          value={inputValue}
          onChange={handleChange}
          size={size}
          {...rest}
        />
      </Block>
    );
  }
);

export default PhoneInput;
