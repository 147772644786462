import { borderWidth, colors, shadows, sizes, spacings } from "@/assets/themes";
import { Avatar, Block, Body16, List, Map } from "@/components";
import { getCssProperty } from "@/components/Styles/Helper";
import polyglot from "@/utils/polyglot";

const JobberMarker = ({ jobber }) => (
  <Avatar
    src={jobber.avatar}
    size={sizes.size54}
    css={`
      border: solid ${spacings.xs} ${colors.background};
      transform: translate(-50%, -50%);
      box-shadow: ${shadows.s};
    `}
  />
);
const calculateBounds = (center, radius, factor = 0.8) => {
  const earthRadius = 6378137;

  // Appliquez le facteur de réduction au rayon
  const adjustedRadius = radius * factor;

  const latDiff = (adjustedRadius / earthRadius) * (180 / Math.PI);
  const lngDiff =
    (adjustedRadius / (earthRadius * Math.cos((Math.PI * center.lat) / 180))) *
    (180 / Math.PI);

  return {
    northEast: {
      lat: center.lat + latDiff,
      lng: center.lng + lngDiff,
    },
    southWest: {
      lat: center.lat - latDiff,
      lng: center.lng - lngDiff,
    },
  };
};

const zoomToFit = (circle, map) => {
  const center = circle.getCenter();
  const radius = circle.getRadius();

  // Ajustez le facteur pour un zoom plus proche
  const bounds = calculateBounds(
    { lat: center.lat(), lng: center.lng() },
    radius,
    0.8 // Vous pouvez ajuster ce facteur (par exemple, 0.9 ou 0.7) pour modifier le niveau de zoom
  );

  map.fitBounds(
    new window.woosmap.map.LatLngBounds(bounds.southWest, bounds.northEast)
  );
};

const createCircle = (map, location) => {
  const circle = new window.woosmap.map.Circle({
    strokeColor: getCssProperty(colors.primary),
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: getCssProperty(colors.primary),
    fillOpacity: 0.2,
    map,
    center: { lat: location.latitude, lng: location.longitude },
    radius: polyglot.toKm(location.radius) * 1000,
  });
  zoomToFit(circle, map);
  return circle;
};

const JobberInterventionArea = ({ location, jobber }) => {
  const handleApiLoaded = (map) => {
    createCircle(map, location);
  };

  return (
    <>
      <List.Header>
        {polyglot.t("common.intervention_area")}
        <Body16
          css={`
            font-weight: 400 !important;
            margin-top: ${spacings.xs};
          `}
        >
          {polyglot.t("jobber_profile.radius_intervention_area_city", {
            city: `${location.city_name} (${location.zip_code})`,
            radius: polyglot.toDistance(location.radius, {
              noFormat: true,
            }),
          })}
        </Body16>
      </List.Header>
      <Block
        width="100%"
        height={224}
        borderRadius={spacings.ml}
        border={`solid ${borderWidth.s} ${colors.border}`}
        overflow="hidden"
        tabIndex={-1}
      >
        <Map
          onApiLoaded={handleApiLoaded}
          defaultZoom={1}
          defaultCenter={{
            lat: location.latitude,
            lng: location.longitude,
          }}
          zoomControl={false}
          gestureHandling="none"
          disableDefaultUI
        >
          <Map.Marker lat={location.latitude} lng={location.longitude}>
            <JobberMarker jobber={jobber} />
          </Map.Marker>
        </Map>
      </Block>
    </>
  );
};

export default JobberInterventionArea;
