import React from 'react';
import YPJLogo from 'images/brand/yoojo-logo.svg';
import WizardHeaderStep from './WizardHeader/WizardHeaderStep';

// Temp
const WizardHeader = ({ activeIndex, steps }) => {
  const renderSteps = steps.map((step, index) => (
    <WizardHeaderStep
      key={`wizard-step-${index}`}
      active={activeIndex === index}
      text={step.text}
      index={index}
      valid={index < activeIndex}
    />
  ));
  return (
    <div className="wizard-form-header">
      <div
        className="wizard-status-bar"
        style={{ width: `${(activeIndex / renderSteps.length) * 100 + 1}%` }}
      />
      <div className="wizard-form-header-logo">
        <a href="/" tabIndex={-1}>
          <img src={YPJLogo} alt="Logo Yoojo" style={{width: 80}} />
        </a>
      </div>
      <div className="wizard-steps">{renderSteps}</div>
    </div>
  );
};

export default WizardHeader;
