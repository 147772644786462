import ReactDOM from "react-dom";
import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { AnimatePresence } from "framer-motion";
import Toast from "../../components/Toastv2";
import { uuid } from "../../utils";
import { TOAST } from "../../components/Styles/variants";
import { ToastContext } from "../contexts";

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: var(--nav-height);
  /* index over modal */
  z-index: 999999999;
`;

const ToastContainer = ({ toasts }) => {
  const el = (
    <Wrapper>
      <AnimatePresence>
        {toasts.map(({ id, content, kind }) => (
          <Toast key={id} id={id} kind={kind}>
            {content}
          </Toast>
        ))}
      </AnimatePresence>
    </Wrapper>
  );
  return ReactDOM.createPortal(el, document.body);
};

const ToastProvider = ({ children, initial }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback(
    (content, kind) => {
      setToasts((toasts) => [
        ...toasts,
        {
          id: uuid(),
          content,
          kind,
        },
      ]);
    },
    [setToasts]
  );

  const danger = (content) => addToast(content, TOAST.KIND.DANGER);
  const success = (content) => addToast(content, TOAST.KIND.SUCCESS);
  const info = (content) => addToast(content, TOAST.KIND.INFO);

  const remove = useCallback(
    (id) => {
      if (id) {
        setToasts((toasts) => toasts.filter((t) => t.id !== id));
      } else {
        setToasts([]);
      }
    },
    [setToasts]
  );

  useEffect(() => {
    if (initial?.success) success(initial.success);
    if (initial?.danger) danger(initial.danger);
  }, []);

  return (
    <ToastContext.Provider
      value={{
        danger,
        success,
        info,
        remove,
      }}
    >
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
};

export default ToastProvider;
