import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { spacings } from "../../assets/themes";
import { Col, Container, Flow, Row, Selectable } from "../../components";
import { getSimulatePricing, putUrssaf } from "../../modules/routes";
import { formatDate, setAddressField } from "../../utils";
import TaxCreditModal from "../common/TaxCreditModal";
import SimulateTaxCredit from "../common/taxCreditModal/SimulateTaxCredit";

const SetTaxes = ({ goNext, goBack, user, tax_deductions }) => {
  const {
    values,
    setFieldValue,
    isValid,
    errors,
    setFieldError,
    setValues,
    validateForm,
  } = useFormikContext();
  const [taxesIsOpen, setTaxesIsOpen] = useState(false);
  const [priceSimulation, setPriceSimulation] = useState({});
  const [priceSimulationIsLoading, setPriceSimulationIsLoading] =
    useState(null);
  const [taxesIsLoading, setTaxesIsLoading] = useState(false);
  const selectedJobber = !values.jobber_id
    ? values.external_jobber
    : values.jobber;

  const updatePricingSimulation = () => {
    setPriceSimulationIsLoading(true);
    const res = getSimulatePricing({
      nb_hours: values.nb_hours,
      price_per_hour: values.price_per_hour,
    });
    res
      .then(({ data }) => {
        setPriceSimulationIsLoading(false);
        setPriceSimulation(data);
      })
      .catch(() => {
        setPriceSimulationIsLoading(false);
      });
  };

  useEffect(() => {
    if (values.urssaf) updatePricingSimulation();
  }, []);

  const onTaxesSubmit = ({
    date_of_birth,
    first_name,
    last_name,
    place_id,
    city_id,
    address_name,
    address_id,
    address,
    tax_deduction,
  }) => {
    setTaxesIsLoading(true);
    setFieldError("user[date_of_birth]", null);
    const dob = formatDate(date_of_birth, "YYYY-MM-DD", "DD/MM/YYYY");
    const res = putUrssaf({
      date_of_birth: dob,
      first_name,
      last_name,
      place_id,
      city_id,
      address_id,
      address_name,
      tax_deduction,
    });
    res
      .then(() => {
        setTaxesIsLoading(false);
        setFieldValue("urssaf", true);
        if (city_id) setFieldValue("user[city_id]", city_id);
        if (place_id) setFieldValue("user[place_id]", place_id);
        if (address_name) setFieldValue("user[address_name]", address_name);
        if (date_of_birth) setFieldValue("user[date_of_birth]", dob);
        setFieldValue("user[tax_deduction]", tax_deduction);
        setFieldValue("user[address]", address);
        if (!values.address) {
          setAddressField(setFieldValue, {
            place_id,
            address_name,
            label: address,
            city_id,
            address_id,
          });
        }
        setTaxesIsOpen(false);
        updatePricingSimulation();
      })
      .catch(() => {
        setFieldError("user[date_of_birth]", true);
        setTaxesIsLoading(false);
      });
  };

  return (
    <Container.Small>
      <Flow.Item.Title>
        Souhaitez-vous déclarer {selectedJobber?.first_name} via Yoojo et
        bénéficier du crédit d’impôts ?
      </Flow.Item.Title>
      <Row gutter={[spacings.s]}>
        <Col size={{ xs: 12, md: 6 }}>
          <Selectable.Medium
            title="Oui, c'est plus simple"
            checked={values.urssaf === true}
            onChange={() => {
              setTaxesIsOpen(true);
            }}
          />
        </Col>
        <Col size={{ xs: 12, md: 6 }}>
          <Selectable.Medium
            title="Non, je le ferai moi même"
            checked={values.urssaf === false}
            onChange={() => {
              setValues({ ...values, urssaf: false, cesu: null });
            }}
          />
        </Col>
      </Row>
      <div className="mt-3">
        <SimulateTaxCredit
          urssaf={values.urssaf}
          isLoading={priceSimulationIsLoading}
          deduction={priceSimulation.deduction}
          employee_part={priceSimulation.employee_part}
          employer_part={priceSimulation.employer_part}
          deduction_term={priceSimulation.deduction_term}
          nb_hours={values.nb_hours}
          price_per_hour={values.price_per_hour}
          selectedJobber={selectedJobber}
          frequency={values.frequency}
        />
      </div>
      <TaxCreditModal
        isOpen={taxesIsOpen}
        isLoading={taxesIsLoading}
        tax_deductions={tax_deductions}
        errors={errors.user}
        initialValues={{ ...values, user: { ...user, ...values.user } }}
        onClose={() => {
          setTaxesIsOpen(false);
          validateForm();
        }}
        onSubmit={onTaxesSubmit}
      />
      <Flow.Item.Navigation goNext={goNext} goBack={goBack} isValid={isValid} />
    </Container.Small>
  );
};

export default SetTaxes;
