import { Form, Formik } from "formik";
import { useState } from "react";
import { borderWidth, colors, spacings } from "../../assets/themes";
import { Block, Body16, Button, DatePicker, Icon } from "../../components";
import { BUTTON, SHAPE } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import FilterPopover from "./FilterPopover";

const formatData = (obj) =>
  typeof obj === "object" && !Array.isArray(obj) && obj !== null
    ? Object.keys(obj).reduce((r, k) => r.concat(k, obj[k]), [])
    : obj;

const AdministratorsFilters = ({
  administrator_id: default_administrator_id,
  kind: default_kind,
  initial_values,
}) => {
  const [administrator_id] = useState(formatData(default_administrator_id));
  const [kind] = useState(formatData(default_kind));
  const [isLoading, setIsLoading] = useState(false);

  const showResetFilter = window.location.href.includes("?");

  const handleSubmit = (values) => {
    setIsLoading(true);
    const formatedValues = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => {
        if (Array.isArray(values[key])) {
          return values[key]
            .filter((v) => v)
            .map((value) => `${key}[]=${value}`)
            .join("&");
        }
        return `${key}=${values[key].toString()}`;
      });
    const searchParams = formatedValues.join("&");
    window.location.href = `${window.location.pathname}?${searchParams}`;
  };

  return (
    <Block
      display="flex"
      justifyContent="center"
      css={`
        border: solid ${borderWidth.s} ${colors.border};
        background-color: ${colors.background};
      `}
    >
      <Block padding={spacings.xs}>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            start_date: initial_values.start_date || "",
            end_date: initial_values.end_date || "",
            administrator_id:
              initial_values.administrator_id?.length > 0
                ? initial_values.administrator_id
                : administrator_id.map((v) => v.value),
            kind:
              initial_values.kind?.length > 0
                ? initial_values.kind
                : kind.filter((v) => typeof v !== "string").map((v) => v.value),
          }}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Form>
              <Block display="flex" alignItems="center" flexWrap="wrap">
                <Body16 strong>{polyglot.t("common.filter")} : </Body16>
                <Block marginLeft={spacings.s} marginY={spacings.xs}>
                  <DatePicker
                    value={[values.start_date, values.end_date]}
                    name={["start_date", "end_date"]}
                    onChange={(date) => {
                      setFieldValue("start_date", date[0]);
                      setFieldValue("end_date", date[1]);
                    }}
                    range
                    maxDate={new Date().setDate(new Date().getDate() + 1)}
                  />
                </Block>
                <Block display="flex" marginY={spacings.xs}>
                  <Block marginLeft={spacings.s}>
                    <FilterPopover
                      data={administrator_id}
                      name="administrator_id"
                      title="Administrateurs"
                      icon="filter"
                    />
                  </Block>
                  <Block marginLeft={spacings.s}>
                    <FilterPopover
                      data={kind}
                      name="kind"
                      title="Catégories"
                      icon="filter"
                    />
                  </Block>
                </Block>
                <Block
                  marginLeft={spacings.s}
                  display="flex"
                  alignItems="center"
                >
                  <Button.Medium
                    onClick={submitForm}
                    isLoading={isLoading}
                    block
                  >
                    {polyglot.t("common.filter")}
                  </Button.Medium>
                  {showResetFilter && (
                    <Block marginLeft={spacings.xs}>
                      <Button.Medium
                        href={window.location.pathname}
                        kind={BUTTON.KIND.SECONDARY}
                        accentColor={BUTTON.ACCENT_COLOR.DANGER}
                        shape={SHAPE.SHAPE.ROUND}
                        block
                      >
                        <Icon.Large name="trash" />
                      </Button.Medium>
                    </Block>
                  )}
                </Block>
              </Block>
            </Form>
          )}
        </Formik>
      </Block>
    </Block>
  );
};
export default AdministratorsFilters;
