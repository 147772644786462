import React from "react";
import styled, { css } from "styled-components";
import { sizes } from "../assets/themes";
import { breakpointsEnhancer } from "./Styles/Helper";
import { ICON } from "./Styles/variants";

const getSize = (name, size) => {
  if (size === ICON.SIZE.XLARGE) {
    return breakpointsEnhancer(name, sizes.size24);
  }
  if (size === ICON.SIZE.LARGE) {
    return breakpointsEnhancer(name, sizes.size20);
  }
  if (size === ICON.SIZE.MEDIUM) {
    return breakpointsEnhancer(name, sizes.size16);
  }
  if (size === ICON.SIZE.SMALL) {
    return breakpointsEnhancer(name, sizes.size12);
  }
  return breakpointsEnhancer(name, size);
};

const StyledIcon = styled.i.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["size", "color"].includes(prop) && defaultValidatorFn(prop),
})`
  ${({ size, color }) => css`
    ${getSize("font-size", size)};
    ${getSize("width", size)};
    ${getSize("height", size)};
    ${getSize("line-height", size)};
    ${breakpointsEnhancer("color", color)}
  `}
`;

const Main = ({
  name,
  className = "",
  size,
  notification,
  color,
  spin,
  ...rest
}) => (
  <StyledIcon
    size={size}
    color={color}
    className={`icon-${name} ${className} ${spin ? "icon-spin" : ""}`}
    {...rest}
  />
);

const Small = ({ size, ...rest }) => (
  <Main {...rest} size={size || ICON.SIZE.SMALL} />
);
const Medium = ({ size, ...rest }) => (
  <Main {...rest} size={size || ICON.SIZE.MEDIUM} />
);
const Large = ({ size, ...rest }) => (
  <Main {...rest} size={size || ICON.SIZE.LARGE} />
);
const XLarge = ({ size, ...rest }) => (
  <Main {...rest} size={size || ICON.SIZE.XLARGE} />
);

export default { Small, Medium, Large, XLarge };
