import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Block, Col, Row, Skeleton } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const RelationSkeleton = () => (
  <Skeleton.Group>
    <Row alignItems="stretch" gutter={[spacings.m]}>
      <Col size={{ xs: 12, md: 9 }}>
        <Skeleton
          width={300}
          height={sizes.size48}
          shape={SKELETON.SHAPE.CIRCLE}
          sr
        />
        <Block marginTop={spacings.l}>
          <Row gutter={spacings.s}>
            <Col size={{ xs: 12, md: 6 }}>
              <Skeleton height={sizes.size24} width="80%" rows={3} />
            </Col>
            <Col size={{ xs: 12, md: 6 }}>
              <Skeleton height={sizes.size24} width="80%" rows={2} />
            </Col>
          </Row>
        </Block>
        <Block marginTop={spacings.l}>
          <Skeleton height={sizes.size24} width={120} sb />
          <Row gutter={[spacings.m]}>
            <Col size={{ xs: 12, md: 4 }}>
              <Skeleton height={200} width="100%" />
            </Col>
            <Col size={{ xs: 12, md: 4 }}>
              <Skeleton height={200} width="100%" />
            </Col>
            <Col size={{ xs: 12, md: 4 }}>
              <Skeleton height={200} width="100%" />
            </Col>
          </Row>
        </Block>
        <Block marginTop={spacings.l}>
          <Row gutter={[spacings.m]}>
            <Col size={6}>
              <Skeleton height={sizes.size24} width={200} sb />
              <Skeleton height={200} width="100%" />
            </Col>
            <Col size={6}>
              <Skeleton height={sizes.size24} width={200} sb />
              <Skeleton height={200} width="100%" />
            </Col>
          </Row>
        </Block>
      </Col>
      <Col size={{ xs: 12, md: 3 }}>
        <Skeleton height={600} width="100%" />
      </Col>
    </Row>
  </Skeleton.Group>
);

export default RelationSkeleton;
