import BaseApp from "../modules/BaseApp";
import RemovalSearch from "../pages/RemovalSearch";

const RemovalSearchApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <RemovalSearch {...props} />
    </BaseApp>
  );

export default RemovalSearchApp;
