import React from "react";
import styled, { css } from "styled-components";
import { borderWidth, colors, radius, sizes, spacings } from "../assets/themes";
import { ButtonBase } from "./Styles/Base";
import { BUTTON } from "./Styles/variants";
import { Body16, Body18, Body20 } from "./Text";

const setHeight = ({ size }) => {
  switch (size) {
    case BUTTON.SIZE.LARGE:
      return sizes.size54;
    case BUTTON.SIZE.MEDIUM:
      return sizes.size48;
    default:
      return sizes.size36;
  }
};

const setWidth = ({ shape, size, block }) => {
  switch (shape) {
    case BUTTON.SHAPE.CIRCLE:
      return setHeight({ size });
    default:
      return block ? "100%" : null;
  }
};

const setShape = ({ shape }) => {
  switch (shape) {
    case BUTTON.SHAPE.CIRCLE:
      return css`
        border-radius: ${radius.circle};
      `;
    case BUTTON.SHAPE.PILL:
      return css`
        border-radius: ${radius.circle};
        padding: ${spacings.xs} ${spacings.sm};
      `;
    default:
      return css`
        padding: ${spacings.xs} ${spacings.m};
        border-radius: ${radius.m};
      `;
  }
};

const setKind = ({ accentColor, kind }) => {
  const DEFAULT_ACCENT = accentColor || BUTTON.ACCENT_COLOR.PRIMARY;
  const MINIMAL_DEFAULT_ACCENT = accentColor || BUTTON.ACCENT_COLOR.BODY;
  switch (kind) {
    case BUTTON.KIND.SECONDARY:
      return css`
        background-color: ${colors.background};
        color: ${colors[DEFAULT_ACCENT]};
        border: solid ${borderWidth.m} ${colors.border};
        &:hover {
          color: ${colors[DEFAULT_ACCENT]};
        }
        &:hover:not([disabled]) {
          background-color: var(--color-gray50);
          &:active {
            &:after {
              background-color: ${colors.overlayDark};
            }
          }
        }
      `;
    case BUTTON.KIND.TERTIARY:
      return css`
        background-color: ${colors[`${DEFAULT_ACCENT}Light`]};
        color: ${colors[DEFAULT_ACCENT]};
        border: none;
        &:hover {
          color: ${colors[DEFAULT_ACCENT]};
        }
        &:hover:not([disabled]) {
          background-color: ${colors[`${DEFAULT_ACCENT}100`]};
          &:active {
            &:after {
              background-color: ${colors.overlayDark};
            }
          }
        }
      `;
    case BUTTON.KIND.MINIMAL:
      return css`
        background-color: ${colors.background};
        color: ${colors[MINIMAL_DEFAULT_ACCENT]};
        border: none;
        &:hover {
          color: ${colors[MINIMAL_DEFAULT_ACCENT]};
        }
        &:hover:not([disabled]) {
          background-color: var(--color-gray50);
          &:active {
            &:after {
              background-color: ${colors.overlayDark};
            }
          }
        }
      `;
    case BUTTON.KIND.PRIMARY:
      return css`
        background-color: ${colors[DEFAULT_ACCENT]};
        color: ${colors.onColor};
        border: none;
        &:hover {
          color: ${colors.onColor};
        }
        &:hover:not([disabled]) {
          background-color: ${colors[`${DEFAULT_ACCENT}600`]};
          &:active {
            &:after {
              background-color: ${colors.overlayLight};
            }
          }
        }
      `;
    default:
      return css`
        color: ${colors[DEFAULT_ACCENT]};
        &:hover {
          color: ${colors[DEFAULT_ACCENT]};
          text-decoration: underline;
        }
        background-color: transparent;
        border: none;
      `;
  }
};

const ComponentWrapper = styled.div`
  ${({ left }) =>
    left &&
    css`
      margin-right: ${spacings.xs};
    `};
  ${({ right }) =>
    right &&
    css`
      margin-left: ${spacings.xs};
    `}
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.button`
  ${({ isLoading }) =>
    isLoading &&
    css`
      cursor: wait;
      color: transparent !important;
      position: relative;
      &:after {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute !important;
        border: 2px solid #dbdbdb;
        border-radius: 100px;
        border-right-color: transparent;
        border-top-color: transparent;
        height: 1em;
        width: 1em;
        animation: spinAround 0.5s infinite linear;
      }
    `}
  ${({
    kind,
    block,
    size,
    shape,
    style,
    accentColor,
    isLoading,
    disabled,
  }) => css`
    &[disabled] {
      opacity: 0.5;
      cursor: ${!isLoading && "not-allowed"};
      &:active,
      &:hover {
        &:after: {
          content: none;
        }
      }
    }
    ${setKind({ accentColor, kind })};
    ${setShape({ shape })};
    min-height: ${setHeight({ size })};
    display: inline-flex;
    &,
    * {
      align-items: center;
      justify-content: center;
    }
    position: relative;
    user-select: none;
    transition: background-color opacity 0.2s;
    width: ${setWidth({ shape, size, block })};
    font-weight: var(--font-weight-medium);
    text-decoration-color: currentColor;
    overflow: hidden;
    &&,
    * {
      text-decoration: ${kind === BUTTON.KIND.LINK ? "none" : "none!important"};
      &:hover {
        text-decoration: "none";
        > div > span {
          text-decoration: ${kind === BUTTON.KIND.LINK && !disabled
            ? "underline"
            : "none"};
        }
      }
    }
    &:hover {
      transition: background-color 0.2s;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
      }
      &:active {
        position: relative;
        &:after {
          transition: background-color 0.1s;
        }
      }
    }
    ${style};
  `}
`;

const Main = React.forwardRef(
  (
    {
      children,
      href,
      onClick = () => null,
      style,
      isLoading,
      kind = BUTTON.KIND.PRIMARY,
      size = null,
      type = "button",
      disabled = false,
      accentColor,
      block,
      shape = BUTTON.SHAPE.DEFAULT,
      id,
      LeftComponent,
      RightComponent,
      ...rest
    },
    ref
  ) => {
    const isDisabled = isLoading || disabled || false;
    return (
      <StyledWrapper
        as={href ? "a" : ButtonBase}
        type={href ? null : type || ""}
        href={href || null}
        role="button"
        onClick={isDisabled ? (e) => e.preventDefault() : onClick}
        style={style}
        disabled={isDisabled}
        isLoading={isLoading}
        accentColor={accentColor}
        id={id}
        ref={ref}
        kind={kind}
        block={block}
        size={size}
        shape={shape}
        {...rest}
      >
        <div
          css={`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          {LeftComponent && (
            <ComponentWrapper left>{LeftComponent()}</ComponentWrapper>
          )}
          {children}
          {RightComponent && (
            <ComponentWrapper right>{RightComponent()}</ComponentWrapper>
          )}
        </div>
      </StyledWrapper>
    );
  }
);

const XSmall = React.forwardRef((props, ref) => (
  <Main ref={ref} {...props} size="xsmall" />
));

// flex-direction: row, fix alignement when there is only one icon
const Small = React.forwardRef(({ children, numberOfLines, ...rest }, ref) => (
  <Main size={BUTTON.SIZE.SMALL} {...rest} ref={ref}>
    <Body16
      strong
      numberOfLines={numberOfLines}
      align="center"
      css={`
        display: flex;
        flex-direction: row;
      `}
    >
      {children}
    </Body16>
  </Main>
));

const Medium = React.forwardRef(({ children, numberOfLines, ...rest }, ref) => (
  <Main size={BUTTON.SIZE.MEDIUM} {...rest} ref={ref}>
    <Body18
      strong
      numberOfLines={numberOfLines}
      align="center"
      css={`
        display: flex;
        flex-direction: row;
      `}
    >
      {children}
    </Body18>
  </Main>
));

const Large = React.forwardRef(({ children, numberOfLines, ...rest }, ref) => (
  <Main size={BUTTON.SIZE.LARGE} {...rest} ref={ref}>
    <Body20
      strong
      numberOfLines={numberOfLines}
      align="center"
      css={`
        display: flex;
        flex-direction: row;
      `}
    >
      {children}
    </Body20>
  </Main>
));

const Link = React.forwardRef(({ children, numberOfLines, ...rest }, ref) => (
  <Main kind={BUTTON.KIND.LINK} {...rest} ref={ref}>
    <Body16
      strong
      numberOfLines={numberOfLines}
      align="center"
      css={`
        display: flex;
        flex-direction: row;
      `}
    >
      {children}
    </Body16>
  </Main>
));

export default { XSmall, Small, Medium, Large, Link };
