const SecureScanIcon = (props) => (
  <div
    style={{
      width: 92,
      height: 92,
      borderRadius: "999px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="100"
      height="100"
      {...props}
      viewBox="8 0 164 164"
    >
      <defs>
        <clipPath id="circleClip">
          <circle cx="90" cy="82" r="82" />
        </clipPath>
      </defs>
      <g fill="none" fillRule="evenodd" clipPath="url(#circleClip)">
        <circle fill="#F8F8F8" cx="90" cy="82" r="82" />
        <g id="numbers-secure" mask="url(#b)" transform="translate(31)">
          <path
            fill="#FFF"
            stroke="#DEDEDE"
            strokeWidth="5"
            d="M4.5 0h109a4 4 0 014 4v102a4 4 0 01-4 4H4.5a4 4 0 01-4-4V4a4 4 0 014-4z"
          />
          <path
            fill="#DEDEDE"
            stroke="none"
            d="M52 64v26H42V64h10zm36 0v26h-9V64h9zm-57 0v26h-3V64h3zm7 0v26h-3V64h3zm37 0v26h-3V64h3zm20 0v26h-3V64h3zm9 0v26h-5V64h5zm-45 0v26h-3V64h3zm9 0v26h-5V64h5zm-44 0v26H14V64h10zM15.5 94h9a1.5 1.5 0 010 3h-9a1.5 1.5 0 010-3zm15 0h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 010-3zm10 0h6a1.5 1.5 0 010 3h-6a1.5 1.5 0 010-3zm12 0h8a1.5 1.5 0 010 3h-8a1.5 1.5 0 010-3zm14 0h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 010-3zm10 0h7a1.5 1.5 0 010 3h-7a1.5 1.5 0 010-3zm13 0h4a1.5 1.5 0 010 3h-4a1.5 1.5 0 010-3zm10 0h3a1.5 1.5 0 010 3h-3a1.5 1.5 0 010-3z"
          />
          <rect
            width="43"
            height="14"
            x="15"
            y="46"
            fill="#0078FA"
            mask="url(#b)"
            rx="2"
          />
          <rect
            width="26"
            height="3"
            x="15"
            y="40"
            fill="#DEDEDE"
            mask="url(#b)"
            rx="1.5"
          />
        </g>
        <path
          fill="#DEDEDE"
          fillRule="nonzero"
          d="M90 164c45.287 0 82-36.713 82-82S135.287 0 90 0 8 36.713 8 82s36.713 82 82 82zm0-6c-41.974 0-76-34.026-76-76S48.026 6 90 6s76 34.026 76 76-34.026 76-76 76z"
          mask="url(#b)"
        />
      </g>
    </svg>
  </div>
);

export default SecureScanIcon;
