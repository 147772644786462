import React, { useEffect, useState } from 'react';
import { Alert, Button, FormControl, Modal, Select } from '../../components';
import { putUpdatePaymentDay } from '../../modules/routes';
import { getMonthlyOptions, getWeeklyOptions } from '../../utils';

const EditPaymentDayModal = ({
  onClose,
  frequency,
  monthly_payment_day,
  weekly_payment_day,
  isOpen,
  updateData,
  id,
}) => {
  const defaultValue = frequency === 'monthly' ? monthly_payment_day : weekly_payment_day;
  const [currValue, setCurrValue] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrValue(defaultValue);
  }, [defaultValue]);

  const handleSubmit = values => {
    setIsLoading(true);
    const res = putUpdatePaymentDay({ ...values, id });
    res
      .then(() => {
        updateData();
        setIsLoading(false);
        onClose();
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={onClose} title="Jour de paiement" />
      <Modal.Item.Wrapper>
        {frequency === 'weekly' && (
          <FormControl label="Sélectionner un jour de paiement">
            <Select
              name="weekly_payment_day"
              placeholder="Tous les Lundis"
              onChange={option => {
                setCurrValue(option.value);
              }}
              options={getWeeklyOptions()}
              defaultValue={weekly_payment_day}
            />
          </FormControl>
        )}
        {frequency === 'monthly' && (
          <FormControl label="Sélectionner un jour de paiement">
            <Select
              name="monthly_payment_day"
              placeholder="Tous les 31 du mois"
              onChange={option => {
                setCurrValue(option.value);
              }}
              defaultValue={monthly_payment_day}
              options={getMonthlyOptions()}
            />
          </FormControl>
        )}
        <Button.Large
          disabled={currValue === defaultValue}
          onClick={() =>
            handleSubmit(
              frequency === 'weekly'
                ? { weekly_payment_day: currValue }
                : { monthly_payment_day: currValue },
            )
          }
          isLoading={isLoading}>
          Enregistrer
        </Button.Large>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default EditPaymentDayModal;
