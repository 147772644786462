import { useFormik, useFormikContext } from "formik";
import styled from "styled-components";

import { colors, sizes, spacings } from "../../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  CardSelectable,
  FormControl,
  Modal,
  Select,
} from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import polyglot from "../../../utils/polyglot";
import { FURNITURE_QUANTITIES, SURFACE_OPTIONS } from "../constants";
import TruckResult from "./truckSurfaceCalulator/TruckResult";

const StyledCardSelectable = styled(CardSelectable)`
  min-width: ${sizes.size128};
  flex: 1;
  margin-right: ${spacings.m};
  &:last-of-type {
    margin-right: 0;
  }
`;

const validate = (values) => {
  const errors = {};
  if (!values.surface) {
    errors.surface = polyglot.t("common.required");
  }
  if (!values.furniture_quantities) {
    errors.furniture_quantities = polyglot.t("common.required");
  }

  return errors;
};

const TruckSurfaceCalulatorModal = ({ isOpen, onClose }) => {
  const categoryFormFormik = useFormikContext();

  const formik = useFormik({
    validateOnChange: true,
    validateOnMount: true,
    validate,
    initialValues: {
      surface: "",
      furniture_quantities: "",
      vehicles: null,
    },
    onSubmit: (values) => {
      categoryFormFormik.setFieldValue("vehicles", values.vehicles);
      onClose();
    },
  });

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const handleTruckChange = ({ value }) => {
    formik.setFieldValue("vehicles", value);
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={handleClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={handleClose}>
        <Modal.Item.Title>
          {polyglot.t("truck_calculator.calculate_ideal_truck_size")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <form onSubmit={formik.handleSubmit}>
          <FormControl
            label={polyglot.t("categories.what_surface_of_your_home")}
            name="surface"
          >
            <Select
              placeholder={polyglot.t("categories.select_surface_placeholder")}
              onChange={({ value }) => formik.setFieldValue("surface", value)}
              value={formik.values.surface}
              options={SURFACE_OPTIONS}
            />
          </FormControl>
          <FormControl
            label={polyglot.t(
              "categories.what_quantity_furniture_at_your_home"
            )}
            name="furniture_quantities"
          >
            <Block
              marginX={`calc(${spacings.m} * -1)`}
              overflowX="auto"
              paddingTop={spacings.s}
            >
              <Block marginX={spacings.m} display="inline-flex">
                {FURNITURE_QUANTITIES.map((furniture, index) => (
                  <StyledCardSelectable
                    key={`truck-size-calculator-furnitures-${index}`}
                    name="furniture_quantities"
                    image={furniture.image}
                    checked={
                      formik.values.furniture_quantities ===
                      furniture.nbHoursAccumulator
                    }
                    onClick={() =>
                      formik.setFieldValue(
                        "furniture_quantities",
                        furniture.nbHoursAccumulator
                      )
                    }
                  >
                    <Body16 strong>{furniture.label}</Body16>
                    <Body14 color={colors.muted} style={{ fontWeight: 400 }}>
                      {furniture.subtitle}
                    </Body14>
                  </StyledCardSelectable>
                ))}
              </Block>
            </Block>
          </FormControl>
          <CardBase>
            <Block marginX={spacings.m} marginBottom={spacings.m}>
              <Block
                height="150px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Block width="100%">
                  {!formik.isValid ? (
                    <Body14 align="center" color={colors.muted}>
                      {polyglot.t("truck_calculator.empty_state")}
                    </Body14>
                  ) : (
                    <TruckResult
                      onChange={handleTruckChange}
                      value={
                        formik.isValid
                          ? formik.values.surface *
                            Number(formik.values.furniture_quantities)
                          : null
                      }
                    />
                  )}
                </Block>
              </Block>
              <Button.Large type="submit" block disabled={!formik.isValid}>
                {polyglot.t("common.confirm")}
              </Button.Large>
            </Block>
          </CardBase>
        </form>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};
export default TruckSurfaceCalulatorModal;
