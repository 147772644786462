import { useState } from "react";
import styled from "styled-components";
import { colors, radius, spacings } from "../../../assets/themes";
import { Button, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";
import JobberOfferItemContent from "../jobs/JobberOfferItemContent";
import TicketNewJobberModal from "./TicketNewJobberModal";

const StyledGrayWrapper = styled.div`
  background-color: ${colors.backgroundLight};
  border-radius: ${radius.m};
  padding: 0 ${spacings.m} ${spacings.m} ${spacings.m};
`;

const TicketNewJobberInput = ({ value, onChange, job }) => {
  const [jobberModalIsOpen, setJobberModalIsOpen] = useState(false);

  const handleJobberModalClose = () => {
    setJobberModalIsOpen(false);
  };

  const handleJobberModalOpen = () => {
    setJobberModalIsOpen(true);
  };

  function getJobber(jobberId) {
    let jobberOffer = null;
    job.booked_offers.forEach((offer) => {
      if (offer.jobber && offer.jobber.id === jobberId) {
        jobberOffer = offer;
      }
    });
    return jobberOffer;
  }

  return (
    <div>
      {!value ? (
        <Button.Medium
          block
          kind={BUTTON.KIND.SECONDARY}
          onClick={handleJobberModalOpen}
          LeftComponent={() => <Icon.Large name="plus" />}
        >
          {polyglot.t("tickets.select_a_jobber")}
        </Button.Medium>
      ) : (
        <StyledGrayWrapper>
          <JobberOfferItemContent
            {...getJobber(value)}
            divider={false}
            urlTargetBlank
          />
          <Button.Small
            kind={BUTTON.KIND.MINIMAL}
            shape={BUTTON.SHAPE.PILL}
            onClick={() => onChange(null)}
            RightComponent={() => <Icon.Medium name="trash" />}
          >
            {polyglot.t("common.remove")}
          </Button.Small>
        </StyledGrayWrapper>
      )}
      <TicketNewJobberModal
        job={job}
        isOpen={jobberModalIsOpen}
        onChange={(offer) => {
          onChange(offer.jobber.id);
        }}
        onClose={handleJobberModalClose}
      />
    </div>
  );
};
export default TicketNewJobberInput;
