import { colors, radius, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Card,
  Carousel,
  ConditionnalWrapper,
  Info,
  Link,
  List,
  Tag,
} from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import { TAG } from "../../../components/Styles/variants";
import { useBreakpoints } from "../../../modules/hooks";
import { getCategoryFormUrlById } from "../../../utils";
import polyglot from "../../../utils/polyglot";

const CrossSellCarrousel = ({
  category_origin_id,
  categories,
  slideToShow = { xs: 1.5, sm: 3 },
  paddingX = { xs: spacings.m, md: "0px" },
  gutter = spacings.m,
  withGutters,
  cardLayout,
}) => {
  const breakpoints = useBreakpoints();
  return (
    <>
      <List.Header withGutters={withGutters}>
        <Block display="flex" alignItems="center">
          <span>{polyglot.t("cross_sell.selected_for_you")}</span>
          <Info>{polyglot.t("cross_sell.info")}</Info>
        </Block>
      </List.Header>
      <ConditionnalWrapper
        condition={cardLayout}
        wrapper={(children) => (
          <CardBase flat>
            <Block paddingX={spacings.m} paddingY={spacings.s}>
              {children}
            </Block>
          </CardBase>
        )}
      >
        <Block marginTop={spacings.s}>
          <Carousel
            gutter={gutter}
            paddingX={paddingX}
            slideToShow={slideToShow}
          >
            {categories.map((category) => (
              <Block key={`cross-sell-carousel-${category.id}`}>
                <Card
                  href={getCategoryFormUrlById(category.id, {
                    category_origin_id,
                  })}
                  headerImage={category.cover}
                  css={`
                    width: 100%;
                    border-radius: 0;
                    ${Card.Styled.StyledHeaderImage} {
                      border-radius: ${radius.ml};
                      height: ${breakpoints.get({ xs: "100px", md: "120px" })};
                      &:before {
                        background-position: right bottom;
                      }
                    }
                  `}
                >
                  <Block marginTop={spacings.s}>
                    <Body16 strong color={colors.body}>
                      {category.title}
                    </Body16>
                    <Block display="inline-block" marginTop={spacings.xs}>
                      <Tag.Small kind={TAG.KIND.MUTED}>
                        {polyglot.t("common.average_price_per_hour", {
                          price_per_hour: polyglot.toSmartCurrency(
                            category.price_per_hour_average,
                            { pricePerHour: true }
                          ),
                        })}
                      </Tag.Small>
                    </Block>
                  </Block>
                </Card>
              </Block>
            ))}
          </Carousel>
        </Block>
      </ConditionnalWrapper>
    </>
  );
};
export default CrossSellCarrousel;
