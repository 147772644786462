import styled, { css } from "styled-components";
import React from "react";
import { colors } from "../../assets/themes";
import Button from "../Button";

const StyledLi = styled.li`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    border-bottom: solid 2px ${colors.border}!important;
  }
`;
const StyledButton = styled(Button.Small)`
  ${({ isActive }) =>
    css`
      border-radius: 0 !important;
      margin-bottom: -1px;
      border-bottom: solid 0px ${colors.border}!important;
      color: ${isActive ? colors.primary : colors.body}!important;
      text-wrap: nowrap;
      height: 100%;
      min-width: 100%;
    `}
`;

const Tab = React.forwardRef(
  ({ children, isActive, onClick, value, ...rest }, ref) => (
    <StyledLi key={`tab-${value}`} ref={ref}>
      <StyledButton
        kind="minimal"
        isActive={isActive}
        onClick={onClick}
        {...rest}
      >
        {children}
      </StyledButton>
    </StyledLi>
  )
);

export default Tab;
