import React from "react";
import { ToastProvider } from "../modules/providers";
import ErrorBoundary from "../utils/ErrorBoundary";

const ToastsApp = (props, rails) => () =>
  (
    <ErrorBoundary>
      <ToastProvider initial={{ success: props.notice, danger: props.alert }} />
    </ErrorBoundary>
  );

export default ToastsApp;
