import { useHistory, useLocation } from "react-router-dom";
import { colors } from "../../assets/themes";
import {
  Body14,
  Body16,
  Button,
  Icon,
  List,
  Popover,
  Radio,
} from "../../components";
import { BUTTON, POPOVER } from "../../components/Styles/variants";
import { useGoogleAnalytics } from "../../modules/hooks";
import useJob from "../../modules/hooks/useJob";
import { getSortPressEvent } from "../../utils/analytics-events";
import polyglot from "@/utils/polyglot";

const JobOfferFilterButton = () => {
  const { sortList, pendingOffers, data } = useJob();
  const history = useHistory();
  const { search } = useLocation();
  const { sendEvent } = useGoogleAnalytics();

  const getSortParam = () => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get("sort");
  };

  const handleSort = (value) => {
    pendingOffers.mutate({ id: data.id, sort: value });
    history.push(`?sort=${value}`);
    sendEvent(getSortPressEvent({ value }));
  };

  return (
    <Popover.Enhancer
      position={POPOVER.POSITIONS.BOTTOM_RIGHT}
      trigger={POPOVER.TRIGGER_TYPE.CLICK}
      content={({ close }) => (
        <Popover.Elem.Menu
          css={`
            width: 300px;
          `}
        >
          <List.Group>
            {sortList.map((sort) => (
              <List.Item
                withGutters
                key={`sort-filter-${sort.value}`}
                onClick={() => {
                  handleSort(sort.value);
                  close();
                }}
                RightComponent={() => (
                  <Radio
                    checked={
                      search
                        ? getSortParam() === sort.value
                        : sortList[0].value === sort.value
                    }
                  />
                )}
              >
                <Body16 strong>{sort.title}</Body16>
                {sort.subtitle && (
                  <Body14 color={colors.muted}>{sort.subtitle}</Body14>
                )}
              </List.Item>
            ))}
          </List.Group>
        </Popover.Elem.Menu>
      )}
    >
      <Button.Small
        kind={BUTTON.KIND.SECONDARY}
        shape={BUTTON.SHAPE.PILL}
        accentColor={BUTTON.ACCENT_COLOR.BODY}
        css={`
          color: ${colors.muted};
        `}
        LeftComponent={() => <Icon.Medium name="sort" />}
      >
        {polyglot.t("common.sort")}
      </Button.Small>
    </Popover.Enhancer>
  );
};
export default JobOfferFilterButton;
