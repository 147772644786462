import React from "react";
import { ThemeProvider } from "styled-components";
import * as themes from "../assets/themes";
import ErrorBoundary from "../utils/ErrorBoundary";
import RoleFilters from "../pages/RoleFilters";

const RoleFiltersApp = (props, rails) => () =>
  (
    <ThemeProvider theme={themes}>
      <ErrorBoundary>
        <RoleFilters {...props} rails={rails} />
      </ErrorBoundary>
    </ThemeProvider>
  );

export default RoleFiltersApp;
