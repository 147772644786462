import { useCallback, useRef, useState } from "react";
import { colors, sizes, spacings } from "../../assets/themes";
import { Block, Body16, Button, Icon, List } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import PropItem from "./PropItem";
import { getTotalInventoryItems } from "./utils";
import polyglot from "../../utils/polyglot";
import { useOnClickOutside } from "../../modules/hooks";

const Inventory = ({ items, onChange, onClear }) => {
  const [showConfirmClear, setShowConfirmClear] = useState(false);
  const buttonRef = useRef();

  useOnClickOutside(buttonRef, () => {
    setShowConfirmClear(false);
  });

  const renderInventoryItems = useCallback(
    () =>
      items.map((item) => (
        <PropItem
          key={`item-inventory-${item.value}`}
          onChange={(amount) => onChange(item.value, amount, item)}
          imageSize={sizes.size32}
          value={item.amount}
          label={item.label}
          image={item.image}
          strong={false}
        />
      )),
    [items]
  );

  const handleClearClick = (e) => {
    if (showConfirmClear) {
      onClear(e);
      setShowConfirmClear(false);
    } else {
      setShowConfirmClear(true);
    }
  };

  return (
    <>
      <List.Header
        withGutters
        RightComponent={
          items.length > 0
            ? () => (
                <Button.Small
                  ref={buttonRef}
                  onClick={handleClearClick}
                  kind={BUTTON.KIND.SECONDARY}
                  css={`
                    padding-left: ${showConfirmClear ? spacings.s : null};
                    padding-right: ${showConfirmClear ? spacings.s : null};
                  `}
                  shape={
                    showConfirmClear ? BUTTON.SHAPE.PILL : BUTTON.SHAPE.CIRCLE
                  }
                >
                  {showConfirmClear ? (
                    <span
                      css={`
                        color: ${colors.muted};
                      `}
                    >
                      {polyglot.t("common.remove")}
                    </span>
                  ) : (
                    <Icon.Medium name="trash" color={colors.muted} />
                  )}
                </Button.Small>
              )
            : null
        }
      >
        {polyglot.t("moving_simulator.inventory")} (
        {getTotalInventoryItems(items)})
      </List.Header>
      {renderInventoryItems()}
      {items.length === 0 && (
        <Block marginX={spacings.m}>
          <Body16 color={colors.muted}>
            {polyglot.t("moving_simulator.empty_inventory")}
          </Body16>
        </Block>
      )}
    </>
  );
};
export default Inventory;
