import React from "react";
import { Breadcrumb, Button } from "../../components";
import polyglot from "../../utils/polyglot";
import { getCategoriesPathById, getDataById } from "./utils";

const SearchBreadcrumb = React.memo(
  ({ onClick, currId }) => {
    const stage =
      currId && getCategoriesPathById(currId).map((d) => getDataById(d));
    const last = stage[stage.length - 1];
    return currId ? (
      <Breadcrumb>
        <Button.Link
          onClick={() => onClick("")}
          css={`
            padding-left: 0;
            padding-right: 0;
          `}
        >
          {polyglot.t("categories_modal.category")}
        </Button.Link>
        {stage.slice(0, -1).map(({ id }, index) => (
          <Button.Link
            css={`
              padding-left: 0;
              padding-right: 0;
            `}
            key={`${index}breadcrumb-search`}
            onClick={() => onClick(id)}
          >
            {polyglot.t(`categories.${id}.name`)}
          </Button.Link>
        ))}
        <span>{polyglot.t(`categories.${last.id}.name`)}</span>
      </Breadcrumb>
    ) : null;
  },
  (prevProps, nextProps) => prevProps.currId === nextProps.currId
);

export default SearchBreadcrumb;
