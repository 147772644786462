import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { colors, spacings } from "../../../../assets/themes";
import {
  Alert,
  Block,
  Button,
  Checkbox,
  FormControl,
  Icon,
  Input,
  Modal,
} from "../../../../components";
import { ALERT } from "../../../../components/Styles/variants";
import { useFbCreate } from "../../../../modules/routes/sign-routes";
import polyglot from "../../../../utils/polyglot";
import SignUpTerms from "../../SignUpTerms";

const validationSchema = yup.object().shape({
  user: yup.object().shape({
    last_name: yup
      .string()
      .required(polyglot.t("common.errors.field_required")),
    email: yup
      .string()
      .email(polyglot.t("common.errors.email_invalid"))
      .required(polyglot.t("common.errors.field_required")),
    first_name: yup
      .string()
      .required(polyglot.t("common.errors.field_required")),
  }),
});

const CreateUser = ({ modal, onClose, onSuccess, params, alertMessage }) => {
  const history = useHistory();
  const fbCreate = useFbCreate();

  const {
    authResponse: { user, accessToken },
  } = history.location?.state;

  const formik = useFormik({
    initialValues: {
      user: {
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        email: user?.email || "",
        is_jobber: false,
      },
      access_token: accessToken,
      alert_setting: { coreg: true, optin: true },
      ...params,
    },
    validationSchema,
    onSubmit: (values) => {
      formik.setStatus(null);
      fbCreate.mutate(values, {
        onSuccess: ({ data }) => onSuccess(data),
        onError: ({ response }) => {
          formik.setStatus(response.data.errorMessage);
        },
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal.Item.Header
        onClose={modal && onClose}
        onBack={modal && history.goBack}
        title={polyglot.t("signup.signup_with_facebook")}
      />
      <Modal.Item.Wrapper>
        {formik.status && (
          <Block marginBottom={spacings.m}>
            <Alert.Low kind={ALERT.KIND.DANGER}>{formik.status}</Alert.Low>
          </Block>
        )}
        <div>
          <FormControl
            error={formik.submitCount > 0 && formik.errors.user?.email}
          >
            <Input
              value={formik.values.user?.email}
              name="user[email]"
              type="email"
              placeholder={polyglot.t("common.email")}
              onChange={formik.handleChange}
              LeftComponent={() => (
                <Icon.Large name="envelope" color={colors.mutedLight} />
              )}
            />
          </FormControl>
          <FormControl
            error={formik.submitCount > 0 && formik.errors.user?.first_name}
          >
            <Input
              value={formik.values.user?.first_name}
              name="user[first_name]"
              type="text"
              placeholder={polyglot.t("common.first_name")}
              onChange={formik.handleChange}
              LeftComponent={() => (
                <Icon.Large name="user" color={colors.mutedLight} />
              )}
            />
          </FormControl>
          <FormControl
            error={formik.submitCount > 0 && formik.errors.user?.last_name}
          >
            <Input
              value={formik.values.user?.last_name}
              name="user[last_name]"
              type="text"
              placeholder={polyglot.t("common.last_name")}
              onChange={formik.handleChange}
              LeftComponent={() => (
                <Icon.Large name="user" color={colors.mutedLight} />
              )}
            />
          </FormControl>
          <Block marginBottom={spacings.m}>
            <Checkbox
              name="alert_setting[optin]"
              onChange={formik.handleChange}
              checked={formik.values.alert_setting.optin}
            >
              {polyglot.t("optin.news_gift_tips")}
            </Checkbox>
            <Checkbox
              name="alert_setting[coreg]"
              onChange={formik.handleChange}
              checked={formik.values.alert_setting.coreg}
            >
              {polyglot.t("optin.partner")}
            </Checkbox>
          </Block>
          {alertMessage && (
            <Block marginBottom={spacings.s}>
              <Alert.Low>{alertMessage}</Alert.Low>
            </Block>
          )}
          <Button.Medium
            isLoading={fbCreate.isLoading || fbCreate.isSuccess}
            type="submit"
            block
          >
            {polyglot.t("signup.to_signup_with_facebook")}
          </Button.Medium>
          <SignUpTerms />
        </div>
      </Modal.Item.Wrapper>
    </form>
  );
};

export default CreateUser;
