import React, { useEffect, useState } from "react";
import { Block, Button, FilePicker, Icon } from "../components";
import { BUTTON } from "../components/Styles/variants";
import polyglot from "../utils/polyglot";
import ImageCropperInput from "./ImageCropperInput";

const ImageManipulationInput = ({
  name,
  value: _value,
  imageCropperProps,
  ...rest
}) => {
  const [value, setValue] = useState(_value);
  const [src, setSrc] = useState(imageCropperProps?.value);

  useEffect(() => {
    if (value) {
      const reader = new FileReader();
      reader.readAsDataURL(value);
      reader.onload = function () {
        setSrc(reader.result);
      };
    } else {
      setSrc(null);
    }
  }, [value]);

  const handleChange = (e) => {
    const file = e.currentTarget.files[0];
    setValue(file);
  };

  const handleRemove = () => {
    setSrc(null);
    setValue(null);
  };
  return (
    <>
      <Block height={src && 0} overflow={src && "hidden"}>
        <FilePicker onChange={handleChange} name={name} {...rest} />
      </Block>
      {src && (
        <>
          <ImageCropperInput {...imageCropperProps} src={src} />
          <Button.Medium
            block
            kind={BUTTON.KIND.MINIMAL}
            onClick={handleRemove}
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            LeftComponent={() => <Icon.Large name="trash" />}
          >
            {polyglot.t("common.remove")}
          </Button.Medium>
        </>
      )}
    </>
  );
};

export default ImageManipulationInput;
