import BaseApp from "@/modules/BaseApp";
import JobbersSeoSearchBar from "../pages/JobbersSeoSearchBar";
import store from "../store";
import ErrorBoundary from "../utils/ErrorBoundary";

const JobbersSeoSearchBarApp = (props, rails) => () => {
  const updateUrlParams = (values) => {
    const urlParams = new URLSearchParams(window.location.search);
    Object.keys(values).forEach((key) => {
      if (values[key]) {
        urlParams.set(key, values[key]);
      } else {
        urlParams.delete(key);
      }
    });
    window.location.href = `/jobbers?${urlParams.toString()}`;
  };

  const handleSubmit = (values) => {
    updateUrlParams(values);
  };

  return (
    <ErrorBoundary>
      <BaseApp store={store}>
        <JobbersSeoSearchBar
          initialValues={props.initial_values}
          onSubmit={handleSubmit}
        />
      </BaseApp>
    </ErrorBoundary>
  );
};

export default JobbersSeoSearchBarApp;
