import React, { useEffect } from "react";
import { colors, spacings } from "../../assets/themes";
import { Alert, Block, Icon, List, Tabs } from "../../components";
import { ALERT } from "../../components/Styles/variants";
import useAdminPreview from "../../modules/hooks/useAdminPreview";
import { DASHBOARD_PATH } from "../../pages/dashboard/config/routes";
import { CUSTOMER } from "./config";
import PreviewElemCustomerHead from "./PreviewElemCustomerHead";
import PreviewElemSkeleton from "./PreviewElemSkeleton";
// eslint-disable-next-line import/no-cycle
import useCustomerConfig from "./hooks/useCustomerConfig";
import usePreviewInit from "./usePreviewInit";

const PreviewElemCustomer = ({ id, popoverRef }) => {
  const adminPreview = useAdminPreview();
  const { init, data, isLoading, tabs } = usePreviewInit({
    id,
    kind: CUSTOMER,
  });

  const config = useCustomerConfig({ id });

  useEffect(() => {
    if (popoverRef) init(popoverRef);
  }, [popoverRef]);

  return (
    <div>
      {isLoading ? (
        <PreviewElemSkeleton />
      ) : (
        <>
          <PreviewElemCustomerHead
            avatar={data.avatar}
            url={data.url}
            last_name={data.last_name}
            first_name={data.first_name}
            paid_jobs_count={data.paid_jobs_count}
            is_vip={data.is_vip}
            token={data.token}
            kind={data.kind}
          />
          {data.deleted && (
            <Block marginY={spacings.s} marginX={spacings.m}>
              <Alert.High kind={ALERT.KIND.DANGER} icon="user-times">
                Utilisateur supprimé
              </Alert.High>
            </Block>
          )}
          {config.informations()}
          {!data.deleted && (
            <List.Item
              RightComponent={() => (
                <Icon.Large
                  name="exclamation-circle-solid"
                  color={colors.danger}
                />
              )}
              onClick={() =>
                adminPreview.logAsUser({
                  id: data.offseted_id,
                  redirect_url: DASHBOARD_PATH,
                })
              }
              withGutters
              divider={false}
            >
              <List.Elem.Title strong color={colors.danger}>
                Prendre la main
              </List.Elem.Title>
            </List.Item>
          )}
          {!data.deleted && (
            <Block marginTop={spacings.xs}>
              <Tabs value={tabs.active} onChange={tabs.set}>
                {config.tabs.map((tab, i) => (
                  <Tabs.Tab value={i} key={`tab-customer-admin-${i}`} {...tab}>
                    {tab.title}
                  </Tabs.Tab>
                ))}
              </Tabs>
              <List.Group>{config.menu()[tabs.active]}</List.Group>
            </Block>
          )}
        </>
      )}
    </div>
  );
};

export default PreviewElemCustomer;
