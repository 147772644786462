import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import FocusLock from "react-focus-lock";
import { colors, radius, spacings } from "../../assets/themes";
import Backdrop from "./Backdrop";

const fullScreenStyle = css`
  & {
    width: 100%;
    max-width: 100%;
    align-self: stretch;
    margin: 0 !important;
    border-radius: 0 !important;
  }
`;

const getSize = ({ size, fullScreenOnMobile }) => {
  switch (size) {
    case "xsmall":
      return css`
        max-width: 420px;
      `;
    case "small":
      return `${
        fullScreenOnMobile
          ? css`
              @media screen and (max-width: 576px) {
                ${fullScreenStyle}
              }
              max-width: 520px;
            `
          : css`
              max-width: 520px;
            `
      }`;
    case "medium":
      return `${
        fullScreenOnMobile
          ? css`
              @media screen and (max-width: 576px) {
                ${fullScreenStyle}
              }
              max-width: 780px;
            `
          : css`
              max-width: 780px;
            `
      }`;
    case "fullscreen":
      return css`
        ${fullScreenStyle}
      `;
    default:
      return css`
        max-width: 420px;
      `;
  }
};

// will-change: transform, opacity; WAS REMOVED BC CAUSING BLUR IMAGE ON WINDOWS CHROME
const StyledModal = styled(motion.div)`
  ${({ fullScreenOnMobile, size }) => css`
    ${getSize({ size, fullScreenOnMobile })};
    background: ${colors.background};
    border-radius: ${radius.ml};
    width: 100%;
    margin: ${spacings.m};
    will-change: opacity;
    display: flex;
    padding-bottom: ${spacings.m};
    flex-direction: column;
  `}
`;

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999998;
  overflow: auto;
`;

const StyledContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
`;

const backdropVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
};

const dropIn = (animate) =>
  animate
    ? {
        hidden: {
          y: 100,
          opacity: 0,
        },
        visible: {
          y: 0,
          opacity: 1,
        },
      }
    : {
        hidden: {},
        visible: {},
      };

const Modal = ({
  onClose,
  isOpen,
  shouldCloseOnOverlayClick = true,
  fullScreenOnMobile,
  onAnimationComplete,
  children,
  style,
  size,
  hideBackdrop,
  animate = true,
  ...rest
}) => {
  const restore = () => {
    const scroll = document.body.style.getPropertyValue("inset");
    if (scroll) {
      document.body.style = "";
      const scrollToNum = Number(scroll.split(" ")[0].replace("px", ""));
      window.scrollTo(0, scrollToNum * -1);
    }
  };

  useLayoutEffect(() => {
    if (isOpen) {
      document.body.style = `overflow: hidden; position: fixed; inset-inline-end: 0px; inset: -${window.scrollY}px 0px 0px`;
    } else {
      restore();
    }
  }, [isOpen]);

  useEffect(
    () => () => {
      restore();
    },
    []
  );

  const handleBackdropMouseDown = (e) => {
    if (shouldCloseOnOverlayClick) {
      e.stopPropagation();
      if (onClose) onClose(e);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      if (onClose) onClose(e);
    }
  };

  useLayoutEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleKeyDown);
    if (!isOpen) document.removeEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    isOpen && (
      <FocusLock>
        <StyledWrapper {...rest}>
          <AnimatePresence initial={isOpen} exitBeforeEnter>
            {!hideBackdrop && isOpen && (
              <motion.div
                initial="hidden"
                animate="visible"
                variants={backdropVariants}
                style={{ willChange: "opacity" }}
                transition={{
                  duration: 0.2,
                }}
              >
                <Backdrop onMouseDown={handleBackdropMouseDown} aria-hidden />
              </motion.div>
            )}
          </AnimatePresence>
          <AnimatePresence
            initial={isOpen}
            exitBeforeEnter
            data-content="modal"
          >
            {isOpen && (
              <StyledContentWrapper>
                <StyledModal
                  size={size}
                  fullScreenOnMobile={fullScreenOnMobile}
                  onClick={(e) => e.stopPropagation()}
                  onAnimationComplete={onAnimationComplete}
                  variants={dropIn(animate)}
                  initial="hidden"
                  animate="visible"
                  aria-label="dialog"
                  transition={{
                    duration: 0.2,
                  }}
                >
                  {children}
                </StyledModal>
              </StyledContentWrapper>
            )}
          </AnimatePresence>
        </StyledWrapper>
      </FocusLock>
    ),
    document.body
  );
};

const Small = ({ children, ...rest }) => (
  <Modal size="small" {...rest}>
    {children}
  </Modal>
);
const Medium = ({ children, ...rest }) => (
  <Modal size="medium" {...rest}>
    {children}
  </Modal>
);
const XSmall = ({ children, ...rest }) => (
  <Modal size="xsmall" {...rest}>
    {children}
  </Modal>
);
const FullScreen = ({ children, ...rest }) => (
  <Modal size="fullscreen" {...rest}>
    {children}
  </Modal>
);

export { XSmall, Small, Medium, FullScreen };
