import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { withTheme } from 'styled-components';
import * as yup from 'yup';
import { HistoryScrollToTop, Modal } from '../../components';
import { addressValidation, dateOfBirthRegex, setAddressInitialValues } from '../../utils';
import Home from './taxCreditModal/Home';
import SelectAddress from './taxCreditModal/SelectAddress';

const Wrapper = ({ children, isOpen, onClose, inContent }) =>
  inContent ? (
    <div>{children}</div>
  ) : (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      {children}
    </Modal.Small>
  );

const TaxCreditModal = ({
  onClose,
  isOpen,
  status: customStatus,
  onSubmit,
  isLoading,
  initialErrors,
  initialValues = {},
  inContent,
  onBack,
  errors = {},
  tax_deductions,
}) => (
  <Wrapper isOpen={isOpen} onClose={onClose} fullScreenOnMobile inContent={inContent}>
    <Formik
      validateOnMount
      onSubmit={onSubmit}
      validationSchema={yup.object().shape(
        {
          first_name: yup.string().required(),
          last_name: yup.string().required(),
          date_of_birth: yup
            .string()
            .matches(dateOfBirthRegex)
            .required(),
          ...addressValidation,
        },
        [
          ['place_id', 'address_id'],
          ['address_name', 'city_id'],
        ],
      )}
      initialErrors={initialErrors}
      initialValues={{
        first_name: initialValues.user?.first_name || '',
        last_name: initialValues.user?.last_name || '',
        tax_deduction: initialValues.user?.tax_deduction || [],
        ...setAddressInitialValues({
          address_id: initialValues.user?.address_id || '',
          place_id: initialValues.user?.place_id || '',
          address_name: initialValues.user?.address_name || '',
          city_id: initialValues.user?.city_id || '',
        }),
        city: '',
        address: initialValues.user?.address || '',
        date_of_birth:
          moment(initialValues.user?.date_of_birth, 'YYYY-MM-DD').format('DD/MM/YYYY') || '',
      }}>
      <Form className="mt-3">
        <MemoryRouter initialEntries={['/', '/address']}>
          <HistoryScrollToTop />
          <Switch>
            <Route exact path="/">
              <Home
                onClose={onClose}
                customStatus={customStatus}
                onSubmit={onSubmit}
                isLoading={isLoading}
                initialValues={initialValues}
                inContent={inContent}
                onBack={onBack}
                linkedErrors={errors}
                tax_deductions={tax_deductions}
              />
            </Route>
            <Route path="/address">
              <SelectAddress onClose={onClose} />
            </Route>
          </Switch>
        </MemoryRouter>
      </Form>
    </Formik>
  </Wrapper>
);

export default withTheme(TaxCreditModal);
