import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";
import polyglot from "@/utils/polyglot";
import { DashboardContext } from "@/modules/contexts";
import DashboardWrapper from "../DashboardWrapper";
import JobberProfile from "../JobberProfile";
import { Content } from "./Layouts";
import DeleteAccount from "./account/DeleteAccount";
import TransactionDetailsModal from "./account/TransactionDetailsModal";
import { GET_ACCOUNT_MENU } from "./config/account-menu";
import {
  GET_DASHBOARD_ALT_MENU,
  GET_DASHBOARD_MAIN_MENU,
  GET_REFFERAL_MENU_ITEM,
} from "./config/main-menu";
import {
  ACCOUNT_PATH,
  ACCOUNT_TAXES_PAY_PATH,
  ACCOUNT_WALLET_PATH,
  DASHBOARD_EXTRA_RATING_PATH,
  DELETE_ACCOUNT_PATH,
  JOBBER_PROFILE_PATH,
  NOTIFICATIONS_PATH,
} from "./config/routes";
import { Layouts, Notifications } from "./index";
import JobberTaxesModal from "./taxesCredit/JobberTaxesModal";

const DashboardRoutes = () => {
  const data = useSelector((state) => state.badges);
  const { public_pages } = useContext(DashboardContext);

  const location = useLocation();
  const queryClient = useQueryClient();
  const accountData = queryClient.getQueryData([ACCOUNT_PATH]);
  const user = accountData?.user;
  const background = location.state && location.state.background;
  const renderAccountMenuRoutes = useCallback(
    () =>
      GET_ACCOUNT_MENU({ data, user, public_pages }).map((item) =>
        item.routes.map(
          (route) =>
            route.path &&
            !route.hidden && (
              <Route path={route.path} key={`account-route-${route.path}`}>
                <DashboardWrapper>
                  <Layouts.Content title={route.title}>
                    <route.View
                      config={route}
                      key={`route-account-${route.path}`}
                    />
                  </Layouts.Content>
                </DashboardWrapper>
              </Route>
            )
        )
      ),
    [JSON.stringify(data), JSON.stringify(user)]
  );
  const renderMenuRoutes = useCallback(
    () =>
      [
        ...GET_DASHBOARD_MAIN_MENU({ data }),
        ...GET_REFFERAL_MENU_ITEM({}),
        ...GET_DASHBOARD_ALT_MENU({ public_pages, data }),
        {
          path: NOTIFICATIONS_PATH,
          title: polyglot.t("routes.notifications"),
          exact: true,
          View: Notifications,
          Layout: Content,
        },
        {
          path: DELETE_ACCOUNT_PATH,
          title: polyglot.t("routes.delete_account"),
          exact: true,
          View: DeleteAccount,
          Layout: Content,
        },
      ].map(
        (item) =>
          item.path && (
            <Route
              exact={item.exact}
              key={`route-id-${item.path}`}
              path={item.path}
            >
              <DashboardWrapper>
                <item.Layout title={item.title}>
                  <item.View config={item} public_pages={public_pages} />
                </item.Layout>
              </DashboardWrapper>
            </Route>
          )
      ),
    [JSON.stringify(data)]
  );
  return (
    <>
      <Switch location={background || location}>
        {renderMenuRoutes()}
        {renderAccountMenuRoutes()}
        <Route exact path={JOBBER_PROFILE_PATH}>
          <JobberProfile />
        </Route>
      </Switch>
      <Route
        path={[
          `${ACCOUNT_WALLET_PATH}/:id/details_offer`,
          `${ACCOUNT_WALLET_PATH}/:id/details_direct_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_subscription_payment`,
          `${ACCOUNT_WALLET_PATH}/:id/details_instant_payment`,
        ]}
      >
        <TransactionDetailsModal />
      </Route>

      <Route path={ACCOUNT_TAXES_PAY_PATH}>
        <JobberTaxesModal />
      </Route>
    </>
  );
};

export default DashboardRoutes;
