import React from 'react';
import { Button, Modal } from '../../../components';

const SuccessContractModal = ({ onClose, isOpen, title, subtitle }) => (
  <Modal.XSmall onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} />
    <Modal.Item.Wrapper className="d-flex flex-column align-items-center">
      <div className="position-relative mb-4">
        <i className="icon-check-circle mr-2 text-success" style={{ fontSize: '9rem' }} />
      </div>
      <p className="font-size-5 mb-2 text-center font-weight-medium">{title}</p>
      <p className="font-size-3 text-center">{subtitle}</p>
      <Button.Large className="mt-4" block onClick={onClose}>
        D'accord
      </Button.Large>
    </Modal.Item.Wrapper>
  </Modal.XSmall>
);

export default SuccessContractModal;
