import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { MemoryRouter, useHistory } from "react-router-dom";
import * as yup from "yup";
import { spacings } from "../../assets/themes";
import {
  Alert,
  ArtworkLabel,
  Block,
  Button,
  Flow,
  Modal,
} from "../../components";
import { postContract } from "../../modules/routes/new-relation-routes";
import polyglot from "../../utils/polyglot";
import ContractDetails from "./newEmployementContract/ContractDetails";
import GenerateContract from "./newEmployementContract/GenerateContract";
import Informations from "./newEmployementContract/Informations";

const NewEmployementContract = ({ id }) => {
  const [isOpen, setIsOpen] = useState(true);
  const history = useHistory();
  const formikRef = useRef();
  const [current, setCurrent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { jobberIsNotReady } = history.location?.state || false;

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [current]);

  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = postContract({ id, values });
    res
      .then(({ data }) => {
        window.location.href =
          !values.attachment && !values.new_contract
            ? `${history.createHref({
                pathname: "/",
              })}/?success_contract=true`
            : `${history.createHref({
                pathname: "/",
              })}/?success_contract_new=true`;
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const steps = [
    {
      path: "informations",
      title: polyglot.t("common.informations"),
      View: Informations,
      validationSchema: yup.object().shape({
        new_contract: yup.boolean(),
        duration: yup.string().oneOf(["fixed", "variable"]).required(),
        kind: yup.string().oneOf(["cdi", "cdd"]).required(),
      }),
    },
    {
      path: "details",
      title: "Début et période d'essai",
      View: ContractDetails,
      customProps: { isLoading },
      validationSchema: yup.object().shape({
        start_date: yup.string().required(),
        probation: yup.boolean().notRequired(),
        probation_weeks: yup.number().when("probation", {
          is: true,
          then: yup.number().required(),
          otherwise: yup.number().nullable().notRequired(),
        }),
      }),
    },
    {
      path: "generate",
      title: "Création du contrat",
      View: GenerateContract,
      customProps: { isLoading },
      validationSchema: yup.object().shape({
        attachment: yup.mixed().when("new_contract", {
          is: false,
          then: yup.mixed().notRequired(),
        }),
      }),
    },
  ];

  const handleCloseModal = () => {
    if (!jobberIsNotReady) {
      setIsOpen(false);
      window.scrollTo({ top: 0 });
    } else {
      history.goBack();
    }
  };

  const initialValues = {
    duration: "",
    kind: "cdi",
    start_date: "",
    probation: false,
    probation_weeks: 8,
    new_contract: null,
    attachment: null,
  };

  const handleStepChange = (index) => {
    setCurrent(index);
  };

  return (
    <Block marginY={{ xs: spacings.l, md: spacings.xl }}>
      <MemoryRouter
        basename={`${history.createHref({
          pathname: "/",
        })}employement-contract/new`}
      >
        <Formik
          validateOnMount
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={yup.lazy(() => steps[current].validationSchema)}
        >
          <Form id="flow-router">
            <Flow.Router onChange={handleStepChange} routes={steps} />
          </Form>
        </Formik>
      </MemoryRouter>
      <Modal.Small
        isOpen={isOpen}
        onClose={handleCloseModal}
        fullScreenOnMobile
      >
        <Modal.Item.Header
          onClose={handleCloseModal}
          title="Ajouter un contrat de travail"
          subtitle="Votre prestataire intervient plus de 8h par semaine ou plus de 5 jours consécutifs par
            mois ? Nous vous générons un contrat automatiquement."
        />
        <Modal.Item.Wrapper />
        <Modal.Item.Wrapper>
          <ArtworkLabel.Medium
            className="mb-3 font-weight-medium"
            LeftComponent={() => (
              <i className="icon-circle font-size-1 text-primary" />
            )}
            title="Choisissez le type de contrat"
          />
          <ArtworkLabel.Medium
            className="mb-3 font-weight-medium"
            title="Indiquez la date de début"
            LeftComponent={() => (
              <i className="icon-circle font-size-1 text-primary" />
            )}
          />
          <ArtworkLabel.Medium
            className="mb-3 font-weight-medium"
            title="Ajoutez une période d'essai si besoin"
            LeftComponent={() => (
              <i className="icon-circle font-size-1 text-primary" />
            )}
          />
          <ArtworkLabel.Medium
            className="mb-3 font-weight-medium"
            title="Hop ! C'est prêt, il ne vous reste qu'à le faire signer par votre prestataire"
            LeftComponent={() => (
              <i className="icon-circle font-size-1 text-primary" />
            )}
          />

          {!jobberIsNotReady ? (
            <>
              <Alert.Low
                title="Si vous avez déjà un contrat en cours avec votre prestataire, vous pouvez également l'importer à la page suivante."
                LeftComponent={() => (
                  <i className="icon-circle font-size-1 text-primary" />
                )}
                className="mb-4"
              />
              <Button.Large block onClick={handleCloseModal}>
                Continuer
              </Button.Large>
            </>
          ) : (
            <>
              <Alert.Low
                kind="warning"
                title="Vous pourrez ajouter un contrat de travail lorsque votre prestataire aura rejoint
                Yoojo"
                LeftComponent={() => (
                  <i className="icon-circle font-size-1 text-primary" />
                )}
                className="mb-4"
              />
              <Button.Large kind="secondary" block onClick={handleCloseModal}>
                D’accord, je reviendrai plus tard
              </Button.Large>
            </>
          )}
        </Modal.Item.Wrapper>
      </Modal.Small>
    </Block>
  );
};

export default NewEmployementContract;
