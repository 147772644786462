import { useHistory } from "react-router-dom";
import { Block, Button, Carousel } from "@/components";
import JobberItem from "./JobberItem";
import { spacings } from "@/assets/themes";
import { BUTTON } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import { useBreakpoints } from "@/modules/hooks";

const JobbersHorizontal = ({ block }) => {
  const breakpoints = useBreakpoints();
  const history = useHistory();

  const handleClick = () => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("category", block.category_id);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Block spaceY={spacings.s}>
      <Block marginX={{ xs: `calc(${spacings.m} * -1)`, md: "0px" }}>
        <Carousel
          slideToShow={{ xs: 2, sm: 2, md: 2, lg: 3, xl: 4 }}
          gutter={breakpoints.get({ xs: spacings.s, sm: spacings.m })}
          paddingX={{ xs: spacings.m, md: "0px" }}
          withGutters={breakpoints.get({ xs: true, sm: false })}
        >
          {block.data?.map((jobber, i) => (
            <JobberItem
              {...jobber}
              key={`jobber-horizontal-${block.category_id}-${i}`}
            />
          ))}
        </Carousel>
      </Block>
      {block?.category_id && (
        <Button.Small kind={BUTTON.KIND.SECONDARY} onClick={handleClick}>
          {polyglot.t("common.see_more")}
        </Button.Small>
      )}
    </Block>
  );
};

export default JobbersHorizontal;
