import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory2033() {
  const DEFAULT_NB_HOURS = 1.5;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1,
            subtitle: polyglot.t("categories.small_flowers_plant_exemple"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.medium_flowers_plant_exemple"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 2,
            subtitle: polyglot.t("categories.large_flowers_plant_exemple"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1,
    initialValues,
    config: {
      IsNbHoursReadOnly: true,
    },
  };
}
