import styled from "styled-components";
import { borderWidth, colors, radius, sizes } from "../../assets/themes";
import { Stepper } from "../../components";

export const StyledStepper = styled(Stepper)`
  border: solid ${borderWidth.s} ${colors.border};
  border-radius: ${radius.m};
  button {
    background-color: transparent;
    color: ${colors.body}!important;
  }
  button:hover:not([disabled]) {
    background-color: transparent;
  }
  button:active:not([disabled]) {
    background-color: ${colors.backgroundLight};
  }
  > div > div {
    min-width: ${sizes.size16};
    width: ${sizes.size16};
  }
`;
