import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Block } from "../components";
import polyglot from "@/utils/polyglot";

const INTERVAL = 3000;

const BecomeJobberTitle = ({
  selector,
  phrases,
  startPhrase = polyglot.t("become_jobber.start_phrase"),
}) => {
  const longuestPhraseRef = useRef();
  const [currIndex, setCurrIndex] = useState(0);
  const longestPhrase = phrases.reduce((a, b) => (a.length > b.length ? a : b));
  const [maxHeight, setMaxHeight] = useState(0);

  useLayoutEffect(() => {
    setMaxHeight(longuestPhraseRef.current.clientHeight);
    if (maxHeight > 0) {
      document.querySelectorAll(selector)[0].style.display = "none";
      longuestPhraseRef.current.style.display = "none";
    }
  }, [maxHeight]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newI = (currIndex + 1) % phrases.length;
      setCurrIndex(newI);
    }, INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [currIndex]);

  return (
    <div>
      <Block ref={longuestPhraseRef} visibility="hidden">
        {longestPhrase}
      </Block>
      <span>
        {startPhrase}
        <Block height={maxHeight} overflow="hidden">
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              className="text-primary"
              initial={{ y: maxHeight, opacity: 1 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -maxHeight, opacity: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              key={currIndex}
            >
              {phrases[currIndex]}
            </motion.div>
          </AnimatePresence>
        </Block>
      </span>
    </div>
  );
};
export default BecomeJobberTitle;
