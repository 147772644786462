import { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Icon } from ".";
import { useFbLogin } from "../modules/routes/sign-routes";
import { BUTTON } from "./Styles/variants";
import WrongCountryModal from "../pages/loginOrRegister/login/WrongCountryModal";
import { useToast } from "../modules/hooks";
import polyglot from "../utils/polyglot";

const FacebookLogin = ({ onSuccess }) => {
  const [wrongCountryModalIsOpen, setWrongCountryModalIsOpen] = useState(false);
  const fbLogin = useFbLogin();
  const history = useHistory();
  const toast = useToast();
  const initFB = () => {
    window.fbAsyncInit = function fbinit() {
      window.FB.init({
        appId: document.querySelector("meta[property='fb:app_id']").content,
        xfbml: true,
        version: "v3.3",
      });
    };

    return (function init(d, s, id) {
      let js = null;
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/fr_FR/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  };

  useLayoutEffect(() => {
    initFB();
  }, []);

  const handleRedirection = (data, accessToken) => {
    if (data.status === "confirm_user" || data.status === "create_user")
      history.push(`/${data.status}`, {
        authResponse: {
          status: data.status,
          user: data.user,
          accessToken,
        },
      });
  };

  const handleResponse = (response) => {
    fbLogin.mutate(
      {
        access_token: response.authResponse.accessToken,
      },
      {
        onSuccess: ({ data }) => {
          if (data.status === "logged") {
            onSuccess(data);
          } else {
            handleRedirection(data, response.authResponse.accessToken);
          }
        },
        onError: ({ response }) => {
          if (response.data.status === "logged") {
            if (
              response.data.user_country_code &&
              response.data.domain_country_code
            ) {
              setWrongCountryModalIsOpen(true);
            } else {
              toast.danger(response.data.errorMessage);
            }
          } else {
            handleRedirection(response.data, response.authResponse.accessToken);
          }
        },
      }
    );
  };

  const handleLogin = () => {
    window.FB?.login(
      (response) => {
        if (response.authResponse) {
          handleResponse(response);
        }
      },
      { scope: "email,public_profile" }
    );
  };

  const handleSignUp = () => {
    history.push("/signup");
  };

  return (
    <>
      <Button.Medium
        kind={BUTTON.KIND.SECONDARY}
        onClick={handleLogin}
        isLoading={fbLogin.isLoading || fbLogin.isSuccess}
        LeftComponent={() => <Icon.Large name="facebook" />}
      >
        {polyglot.t("signup.continue_with_facebook")}
      </Button.Medium>
      {wrongCountryModalIsOpen && (
        <WrongCountryModal
          userEmail={fbLogin.error?.response?.data?.user_email}
          originCountryCode={fbLogin.error?.response?.data?.user_country_code}
          domainCountryCode={fbLogin.error?.response?.data?.domain_country_code}
          isOpen={wrongCountryModalIsOpen}
          onSignUp={handleSignUp}
          onRedirect={() => onSuccess(fbLogin.error?.response?.data)}
          onClose={() => {
            setWrongCountryModalIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default FacebookLogin;
