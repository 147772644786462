import React, { useState, useRef } from "react";
import { filePicker } from "../modules/routes";
import { Alert, FilePicker } from "../components";
import polyglot from "../utils/polyglot";
import { spacings } from "../assets/themes";

const ContactFilePicker = () => {
  const fileInputRef = useRef();
  const [uploads, setUploads] = useState({});
  const [uploadsProgress, setUploadsProgress] = useState({});
  const [error, setError] = useState("");
  const handleRemove = (id) => {
    const filtered = Object.keys(uploads)
      .filter((key) => key !== id)
      .reduce((obj, key) => {
        obj[key] = uploads[key];
        return obj;
      }, {});
    setUploads(filtered);
  };

  const removeUploaded = (id) => {
    const filtered = Object.keys(uploadsProgress)
      .filter((key) => key !== id)
      .reduce((obj, key) => {
        obj[key] = uploadsProgress[key];
        return obj;
      }, {});
    setUploadsProgress(filtered);
  };

  const handleChange = (event) => {
    event.persist();
    const file = event.currentTarget.files[0];
    const { name } = event.currentTarget;
    const config = {
      onUploadProgress({ loaded, total }) {
        const percentCompleted = Math.round((loaded * 100) / total);
        const fakePercent = (80 * percentCompleted) / 100;
        setUploadsProgress((state) => ({ ...state, [name]: fakePercent }));
      },
      timeout: 0,
    };
    const res = filePicker.postAttachment({
      values: { attachment: file },
      config,
    });
    setError(null);
    res
      .then(({ data }) => {
        setUploadsProgress((state) => ({ ...state, [name]: 100 }));
        setTimeout(() => {
          setUploads((state) => ({ ...state, [data.data.token]: file }));
          removeUploaded(name);
          fileInputRef.current.value = null;
        }, 600);
      })
      .catch(() => {
        setError(polyglot.t("common.attachment_upload_failed"));
        removeUploaded(name);
        fileInputRef.current.value = null;
      });
  };

  return (
    <div>
      {Object.keys(uploads).map((key) => (
        <input
          key={`upload${key}`}
          name="uploads[]"
          hidden
          value={key}
          readOnly
        />
      ))}
      <label className="form-label" htmlFor="attachment-0">
        {polyglot.t("common.add_attachments")} (
        {polyglot.t("common.not_required")})
      </label>
      <div className="d-flex flex-wrap">
        {Object.keys(uploads).map((key) => (
          <FilePicker
            key={`attachment-${key}`}
            style={{ width: 136 }}
            title={false}
            name={`attachment-${key}`}
            value={uploads[key]}
            accept="image/jpeg,image/png,application/pdf"
            onRemove={() => handleRemove(key)}
            onChange={handleChange}
            css={`
              margin-bottom: ${spacings.s};
              margin-right: ${spacings.s};
            `}
          />
        ))}
        <FilePicker
          ref={fileInputRef}
          style={{ width: 136 }}
          css={`
            margin-bottom: ${spacings.s};
            margin-right: ${spacings.s};
          `}
          title={false}
          name="attachment-new"
          accept="image/jpeg,image/png,application/pdf"
          progressAmount={uploadsProgress[`attachment-new`]}
          onChange={handleChange}
        />
      </div>
      {error && <Alert.Low kind="danger">{error}</Alert.Low>}
    </div>
  );
};

export default ContactFilePicker;
