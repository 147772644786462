import { useRef, useState } from "react";
import { colors, radius, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Body16,
  Card,
  Icon,
  Link,
  Rating,
  StatusEnhancer,
  Tag,
} from "@/components";
import TopJobberTag from "@/components/JobOfferItem/TopJobberTag";
import { TAG } from "@/components/Styles/variants";
import { useIntersectionObserver } from "@/modules/hooks";
import UserPreview from "@/pages-admin/UserPreview";
import { JOBBER } from "@/pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";

const JobberItem = ({
  avatar,
  first_name,
  url,
  price_per_hour,
  id,
  is_pro,
  is_top_jobber,
  rates_count,
  rate,
  distance,
  description,
  commitments,
}) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const cardRef = useRef();

  useIntersectionObserver({
    target: cardRef,
    onIntersect: (_, observer) => {
      setIsIntersecting(true);
      observer.unobserve(cardRef?.current);
    },
  });

  return (
    <UserPreview id={id} kind={JOBBER}>
      <Block width="100%">
        <div ref={cardRef} />
        <Link href={url} state={{ id }} unstyled>
          <Card
            renderHeaderImage={() => (
              <>
                {is_top_jobber && (
                  <Block
                    marginBottom={spacings.xs}
                    position="absolute"
                    bottom={spacings.xs}
                    margin={spacings.s}
                  >
                    <TopJobberTag hideInfo />
                  </Block>
                )}
              </>
            )}
            // "#" -> Default avatar for inifitescroll session storage fix wrong scrollTop
            headerImage={isIntersecting ? avatar : "#"}
            css={`
              width: 100%;
              border-radius: 0;
              ${Card.Styled.StyledHeaderImage} {
                border-radius: ${radius.ml};
                padding-bottom: 100%;
                &:before {
                  background-position: center;
                }
              }
            `}
          >
            <Block marginTop={spacings.s}>
              <Block display="flex" alignItems="flex-start" gap={spacings.xs}>
                <span
                  css={`
                    flex: 1;
                  `}
                >
                  <StatusEnhancer isPro={is_pro}>
                    <Body16 strong numberOfLines={1}>
                      {first_name}
                    </Body16>
                  </StatusEnhancer>
                </span>
                {price_per_hour && price_per_hour > 0 && (
                  <Body16 strong numberOfLines={1}>
                    {polyglot.toSmartCurrency(price_per_hour, {
                      noDecimal: true,
                      pricePerHour: true,
                    })}
                  </Body16>
                )}
              </Block>

              <Block
                display="flex"
                alignItems="baseline"
                justifyContent="space-between"
                flexDirection={{ xs: "column", sm: "row" }}
                gap={{ xs: "0px", sm: spacings.s }}
              >
                <Rating rate={rate} count={rates_count} type="compact" />
                {typeof distance === "number" && distance >= 0 && (
                  <Block
                    color={colors.muted}
                    spaceX={spacings.xxs}
                    display="flex"
                    alignItems="center"
                  >
                    <Icon.Small name="map-marker" />
                    <Body14 numberOfLines={1}>
                      {distance > 0
                        ? polyglot.toDistance(distance, { noFormat: true })
                        : polyglot.t("common.near_you")}
                    </Body14>
                  </Block>
                )}
              </Block>
              {description && (
                <Block marginTop={spacings.xs}>
                  <Body16
                    numberOfLines={3}
                    css={`
                      font-weight: normal;
                    `}
                  >
                    {description}
                  </Body16>
                </Block>
              )}
              {commitments?.length > 0 && (
                <Block
                  display="flex"
                  flexWrap="wrap"
                  gap={spacings.xs}
                  marginTop={spacings.s}
                >
                  {commitments.map((commitmentId) => (
                    <Tag.Small
                      kind={TAG.KIND.MUTED}
                      key={`commitments-${commitmentId}-${id}`}
                    >
                      {polyglot.t(`commitments.${commitmentId}.name`)}
                    </Tag.Small>
                  ))}
                </Block>
              )}
            </Block>
          </Card>
        </Link>
      </Block>
    </UserPreview>
  );
};

export default JobberItem;
