import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Button, List, Pagination, Spinner, Tabs } from "../../../components";
import {
  getDirectPayments,
  getDirectSubscription,
} from "../../../modules/routes";
import { DIRECT_PLANS, formatDate, renderStatusBagde } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import EditPaymentModal from "./EditPaymentModal";
import ShowPaymentDetailsModal from "./ShowPaymentDetailsModal";

const Home = ({ id, updateData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [payments, setPayments] = useState();
  const [editPaymentIsOpen, setEditPaymentIsOpen] = useState(false);
  const [paymentDetailsIsOpen, setPaymentDetailsIsOpen] = useState(null);
  const [subscription, setSubscription] = useState();
  const history = useHistory();

  const loadMorePayments = (e, page) => {
    const res = getDirectPayments({ id, page });
    res
      .then(({ data }) => {
        setPayments(data);
      })
      .catch((error) => {
        console.error(error.data);
      });
  };

  const update = () => {
    updateData();
    loadMorePayments(null, payments.page || 1);
  };

  const loadMoreSubscription = (e, page) => {
    const res = getDirectSubscription({ id, page });
    res
      .then(({ data }) => {
        setSubscription(data);
      })
      .catch((error) => {
        console.error(error.data);
      });
  };

  useEffect(() => {
    loadMorePayments(null, 1);
  }, []);

  const handleTabChange = (index) => {
    if (index === 1) {
      history.push("/subscription");
      loadMoreSubscription(null, 1);
      setActiveIndex(1);
    } else {
      history.push("/");
      setActiveIndex(0);
    }
  };

  return (
    <div className="w-100">
      <Tabs
        onChange={handleTabChange}
        value={activeIndex}
      >
        <Tabs.Tab value={0}>Prestataire</Tabs.Tab>
        <Tabs.Tab value={1}>Abonnement</Tabs.Tab>
      </Tabs>
      <Switch>
        <Route path="/" exact>
          {payments ? (
            <>
              <List.Header title="Paiement à venir" />
              {payments.direct_payments.filter((p) => p.editable).length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped w-100 overflow-auto">
                    <thead>
                      <tr>
                        <th scope="col" className="border-top-0">
                          Date
                        </th>
                        <th scope="col" className="border-top-0">
                          Status
                        </th>
                        <th scope="col" className="border-top-0">
                          Montant
                        </th>
                        <th scope="col" className="border-top-0" />
                      </tr>
                    </thead>
                    <tbody>
                      {payments.direct_payments
                        .filter((p) => p.editable)
                        .map((p) => (
                          <tr key={`direct_payments${p.id}`}>
                            <td>{formatDate(p.payment_date, "DD/MM/YYYY")}</td>
                            <td>{renderStatusBagde(p.state)}</td>
                            <td>{polyglot.toSmartCurrency(p.total_price)}</td>
                            <td>
                              <>
                                <EditPaymentModal
                                  isOpen={editPaymentIsOpen}
                                  id={id}
                                  payment_id={p.id}
                                  payment_date={p.payment_date}
                                  updateData={update}
                                  onClose={() => setEditPaymentIsOpen(false)}
                                />
                                <Button.Link
                                  onClick={() => setEditPaymentIsOpen(true)}
                                >
                                  Modifier
                                </Button.Link>
                              </>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="m-3 text-center w-100 d-block text-muted">
                  Vous n'avez pas de paiement à venir
                </span>
              )}
              {payments.direct_payments.filter((p) => !p.editable).length >
                0 && (
                <>
                  <List.Header title="Paiements passés" />
                  <div className="table-responsive">
                    <table className="table table-striped w-100 overflow-auto">
                      <thead>
                        <tr>
                          <th scope="col" className="border-top-0">
                            Date
                          </th>
                          <th scope="col" className="border-top-0">
                            Status
                          </th>
                          <th scope="col" className="border-top-0">
                            Montant
                          </th>
                          <th scope="col" className="border-top-0" />
                        </tr>
                      </thead>
                      <tbody>
                        {payments.direct_payments
                          .filter((p) => !p.editable)
                          .map((p) => (
                            <tr key={`direct_payments${p.id}`}>
                              <td>
                                {formatDate(p.payment_date, "DD/MM/YYYY")}
                              </td>
                              <td>{renderStatusBagde(p.state)}</td>
                              <td>{polyglot.toSmartCurrency(p.total_price)}</td>
                              <td>
                                <Button.Link
                                  onClick={() => setPaymentDetailsIsOpen(p.id)}
                                >
                                  Détails
                                </Button.Link>
                              </td>
                            </tr>
                          ))}
                        <ShowPaymentDetailsModal
                          isOpen={!!paymentDetailsIsOpen}
                          id={id}
                          paymentId={paymentDetailsIsOpen}
                          onClose={() => setPaymentDetailsIsOpen(false)}
                        />
                      </tbody>
                    </table>
                  </div>
                </>
              )}
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Pagination
                  totalPages={payments.total_pages}
                  currentPage={payments.page}
                  onChange={loadMorePayments}
                />
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center my-3">
              <Spinner.Large className="mx-auto text-muted" />
            </div>
          )}
        </Route>
        <Route path="/subscription">
          {subscription ? (
            <>
              <List.Header title="Paiement à venir" />
              {subscription.next_subscription_payment ? (
                <div className="table-responsive">
                  <table className="table table-striped w-100 overflow-auto">
                    <thead>
                      <tr>
                        <th scope="col" className="border-top-0">
                          Date
                        </th>
                        <th scope="col" className="border-top-0">
                          Status
                        </th>
                        <th scope="col" className="border-top-0">
                          Intitulé
                        </th>
                        <th scope="col" className="border-top-0">
                          Montant
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {formatDate(
                            subscription.next_subscription_payment.billing_date,
                            "DD/MM/YYYY"
                          )}
                        </td>
                        <td>{renderStatusBagde("pending")}</td>
                        <td>
                          Abonnement{" "}
                          {
                            DIRECT_PLANS[
                              subscription.next_subscription_payment.plan
                            ].title
                          }
                        </td>
                        <td>
                          {polyglot.toSmartCurrency(
                            subscription.next_subscription_payment.price
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <span className="m-3 text-center w-100 d-block text-muted">
                  Vous n'avez pas de paiement à venir
                </span>
              )}
              <List.Header title="Paiements passés" />
              <div className="table-responsive">
                <table className="table table-striped w-100 overflow-auto">
                  <thead>
                    <tr>
                      <th scope="col" className="border-top-0">
                        Date
                      </th>
                      <th scope="col" className="border-top-0">
                        Status
                      </th>
                      <th scope="col" className="border-top-0">
                        Intitulé
                      </th>
                      <th scope="col" className="border-top-0">
                        Montant
                      </th>
                      <th scope="col" className="border-top-0" />
                    </tr>
                  </thead>
                  <tbody>
                    {subscription.subscription_payments.map((p) => (
                      <tr key={`subscription_payments${p.id}`}>
                        <td>{formatDate(p.billing_date, "DD/MM/YYYY")}</td>
                        <td>{renderStatusBagde(p.state)}</td>
                        <td>Abonnement {DIRECT_PLANS[p.plan].title}</td>
                        <td>{polyglot.toSmartCurrency(p.price)}</td>
                        <td>
                          {p.url && (
                            <a
                              href={p.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Télécharger
                            </a>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex align-items-center justify-content-center mt-3">
                <Pagination
                  totalPages={subscription.total_pages}
                  currentPage={subscription.page}
                  onChange={loadMoreSubscription}
                />
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center my-3">
              <Spinner.Large className="mx-auto text-muted" />
            </div>
          )}
        </Route>
      </Switch>
    </div>
  );
};

export default Home;
