import React from "react";
import styled, { css } from "styled-components";
import { colors, radius, sizes } from "../assets/themes";
import { SHAPE } from "./Styles/variants";
import Block from "./Block";

const getSize = ({ size }) => {
  if (size === SHAPE.SIZE.LARGE) {
    return sizes.size54;
  }
  if (size === SHAPE.SIZE.MEDIUM) {
    return sizes.size48;
  }
  if (size === SHAPE.SIZE.SMALL) {
    return sizes.size36;
  }
  return 0;
};
const getRadius = ({ shape }) => {
  if (shape === SHAPE.SHAPE.CIRCLE) {
    return radius.circle;
  }
  if (shape === SHAPE.SHAPE.ROUND) {
    return radius.m;
  }
  return null;
};

const StyledWrapper = styled.div`
  ${({ size, shape }) => css`
    width: ${getSize({ size })};
    min-width: ${getSize({ size })};
    height: ${getSize({ size })};
    min-height: ${getSize({ size })};
    border-radius: ${getRadius({ shape })};
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const Small = (props) => <Shape size={SHAPE.SIZE.SMALL} {...props} />;
const Medium = (props) => <Shape size={SHAPE.SIZE.MEDIUM} {...props} />;
const Large = (props) => <Shape size={SHAPE.SIZE.LARGE} {...props} />;

const Shape = ({
  backgroundColor = colors.primary,
  color = colors.onColor,
  size,
  shape,
  children,
  ...rest
}) => (
  <Block
    as={StyledWrapper}
    backgroundColor={backgroundColor}
    color={color}
    size={size}
    shape={shape}
    {...rest}
  >
    {children}
  </Block>
);

export default { Small, Medium, Large };
