import Typed from "react-typed";
import {
  colors,
  spacings,
  shadows,
  sizes,
  borderWidth,
} from "../assets/themes";
import { Body14, Body16, Button, Icon, Shape } from "../components";
import { BUTTON, SHAPE } from "../components/Styles/variants";
import { useAskService } from "../modules/hooks";
import { shuffle } from "../utils";

const getSizeStyle = (size) => {
  if (size === "small") {
    return `
      min-height: inherit;
      padding-left: ${spacings.m};
    `;
  }
  if (size === "large") {
    return `
      padding-left: ${spacings.l};
    `;
  }
  return `
    min-height: ${sizes.size48};
    padding-left: ${spacings.ml};
`;
};

const RightComponent = ({ size }) => {
  const CustomShape = (props) => {
    if (size === "small") {
      return (
        <Shape.Small {...props}>
          <Icon.Medium name="search" />
        </Shape.Small>
      );
    }
    if (size === "large") {
      return (
        <Shape.Large {...props}>
          <Icon.XLarge name="search" />
        </Shape.Large>
      );
    }
    return (
      <Shape.Medium {...props}>
        <Icon.Large name="search" />
      </Shape.Medium>
    );
  };
  return (
    <CustomShape
      shape={SHAPE.SHAPE.CIRCLE}
      color={colors.onColor}
      backgroundColor={colors.primary}
    />
  );
};

const CustomText = ({ size, children, ...rest }) => {
  if (size === "small")
    return (
      <Body14 {...rest} align="left">
        {children}
      </Body14>
    );
  return (
    <Body16 {...rest} align="left">
      {children}
    </Body16>
  );
};

const SearchBar = ({
  placeholders,
  size = "medium",
  categoryId,
  urlParams,
  onClick,
  border,
}) => {
  const askService = useAskService();
  return (
    <div
      css={`
        width: 100%;
      `}
    >
      <Button.Large
        block
        shape={BUTTON.SHAPE.PILL}
        kind={BUTTON.KIND.MINIMAL}
        onClick={(e) => {
          if (onClick) onClick(e);
          askService.show({ id: categoryId, urlParams });
        }}
        css={`
          ${border
            ? `border: solid ${borderWidth.s} ${colors.border};`
            : "border: none;"}
          ${getSizeStyle(size)};
          padding-top: ${spacings.s};
          padding-bottom: ${spacings.s};
          padding-right: ${spacings.s};
          box-shadow: ${shadows.xs};
          > div {
            width: 100%;
          }
          span {
            width: 100%;
          }
        `}
        RightComponent={() => <RightComponent size={size} />}
      >
        <CustomText
          numberOfLines={1}
          size={size}
          color={colors.muted}
          css={`
            width: 100%;
            text-align: left;
            font-weight: 400;
          `}
        >
          <Typed
            style={{ textAlign: "left", display: "block", width: "100%" }}
            loop
            typeSpeed={50}
            backSpeed={15}
            backDelay={1300}
            strings={shuffle(placeholders)}
          />
        </CustomText>
      </Button.Large>
    </div>
  );
};

export default SearchBar;
