import { useState } from "react";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Body16,
  Breadcrumb,
  Button,
  Icon,
  Link,
  List,
} from "../../../components";
import { useCesu, useToast } from "../../../modules/hooks";
import { useSendCheques } from "../../../modules/routes/cesu-routes";
import polyglot from "../../../utils/polyglot";
import PageTitle from "../../dashboard/PageTitle";
import CesuSuccess from "../CesuSuccess";
import TotalPrice from "../TotalPrice";
import ManualInputModal from "./ManualInputModal";
import CesuInput from "./cesuInputs/CesuInput";
import ConfirmModal from "./cesuInputs/ConfirmModal";

const CesuInputs = ({ show_new_fees_information }) => {
  const cesu = useCesu();
  const [inputModalIsOpen, setInputModalIsOpen] = useState(false);
  const [cesuSuccessModalIsOpen, setCesuSuccessModalIsOpen] = useState(false);
  const [submitModalIsOpen, setSubmitModalIsOpen] = useState(false);
  const toast = useToast();
  const sendCheques = useSendCheques();

  const handleOpenModal = () => {
    setInputModalIsOpen(true);
  };
  const handleCloseModal = () => {
    setInputModalIsOpen(false);
  };
  const handleOpenConfirmModal = () => {
    setSubmitModalIsOpen(true);
  };
  const handleCloseConfirmModal = () => {
    setSubmitModalIsOpen(false);
  };

  const handleSubmit = () => {
    sendCheques.mutate(
      { codes: cesu.tickets, kind: "manual" },
      {
        onSuccess: () => {
          cesu.set([]);
          setSubmitModalIsOpen(false);
          setCesuSuccessModalIsOpen(true);
        },
        onError: () => {
          setSubmitModalIsOpen(false);
          toast.danger(polyglot.t("common.errors.get"));
        },
      }
    );
  };

  return (
    <div>
      <Block marginBottom={spacings.m}>
        <Breadcrumb>
          <Link to="/">{polyglot.t("cesu.add")}</Link>
          <Body16 numberOfLines={1}>{polyglot.t("cesu.new.enter_cesu")}</Body16>
        </Breadcrumb>
      </Block>
      <Block>
        <PageTitle>{polyglot.t("cesu.new.enter_cesu")}</PageTitle>
      </Block>
      <List.Header RightComponent={() => <TotalPrice codes={cesu.tickets} />}>
        {polyglot.t("routes.cesu")} ({cesu.tickets.length})
      </List.Header>
      {cesu.tickets?.length > 0 && (
        <Block>
          {cesu.tickets.map((cheque) => (
            <CesuInput key={`ticketInput-${cheque.bar_code}`} code={cheque} />
          ))}
        </Block>
      )}
      <List.Item
        onClick={handleOpenModal}
        divider={false}
        LeftComponent={() => (
          <Icon.Large name="plus-circle" color={colors.primary} />
        )}
      >
        <Body16 color={colors.primary} strong>
          {polyglot.t("cesu.new.add_ticket")}
        </Body16>
      </List.Item>
      <Block marginTop={spacings.m}>
        <Button.Large
          block
          type="submit"
          isLoading={sendCheques.isLoading}
          disabled={cesu.tickets.length === 0}
          onClick={handleOpenConfirmModal}
        >
          {polyglot.t("cesu.new.send_ticket")}
        </Button.Large>
      </Block>
      {show_new_fees_information && (
        <Block marginTop={spacings.m}>
          <Alert.Low>
            {polyglot.t("cesu.cesu_fees_information_alt", { fees: 6 })}
          </Alert.Low>
        </Block>
      )}
      <ManualInputModal
        isOpen={inputModalIsOpen}
        closeModal={handleCloseModal}
      />
      <ConfirmModal
        isOpen={submitModalIsOpen}
        onClose={handleCloseConfirmModal}
        onSubmit={handleSubmit}
      />
      <CesuSuccess isOpen={cesuSuccessModalIsOpen} />
    </div>
  );
};

export default CesuInputs;
