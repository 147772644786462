import React from "react";
import { borderWidth, sizes, spacings } from "../../../assets/themes";
import { Block, Skeleton } from "../../../components";

const EditAccountSkeleton = () => (
  <Skeleton.Group>
    {new Array(8).fill("").map((_, i) => (
      <React.Fragment key={`edit-acc-skel-${i}`}>
        <Block
          marginY={spacings.m}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Block>
            <Skeleton height={sizes.size16} width={75} sb />
            <Skeleton height={sizes.size18} width={150} />
          </Block>
          <Skeleton height={sizes.size18} width={80} />
        </Block>
        <Skeleton height={borderWidth.s} width="100%" />
      </React.Fragment>
    ))}
    <Skeleton height={sizes.size16} width={200} st />
  </Skeleton.Group>
);

export default EditAccountSkeleton;
