import styled, { withTheme, css } from "styled-components";
import { borderWidth } from "../../../assets/themes";

const StyledBar = styled.div`
  ${({ theme, progress }) => css`
    background-color: ${theme.colors.border};
    height: 4px;
    width: 100%;
    position: fixed;
    top: calc(var(--nav-height) - ${borderWidth.m});
    z-index: 9999;
    &::before {
      content: "";
      width: ${progress}%;
      transition: 0.2s ease-in-out;
      height: 4px;
      position: absolute;
      background-color: ${theme.colors.primary};
    }
  `}
`;

const ProgressBar = ({ progress }) => <StyledBar progress={progress} />;

export default withTheme(ProgressBar);
