import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';

const StyledImgZoomWrapper = styled.div`
  background-repeat: no-repeat;
  cursor: zoom-in;
  width: 100%;
  &:hover img {
    opacity: 0;
  }
`;

const StyledWrapperButton = styled.button`
  cursor: ${props => props.cursor} !important;
  background: none;
  padding: 0;
  border: none;
  width: 100%;
  height: 100%;
  outline: 0 !important;
  display: block;
  overflow: hidden;
`;

const ZoomOnHover = ({ src, large, helpers }) => {
  const [zoomPosition, setZoomPosition] = useState('0% 0%');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const largeSrc = large || src;
  const handleMouseMove = e => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition(`${x}% ${y}%`);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  return (
    <React.Fragment>
      <StyledWrapperButton
        className="rounded"
        cursor="zoom-in"
        type="button"
        style={{ width: '100%' }}
        onClick={openModal}>
        <StyledImgZoomWrapper
          onMouseMove={handleMouseMove}
          style={{ backgroundPosition: zoomPosition, backgroundImage: `url(${largeSrc})` }}>
          <img
            src={src}
            alt=""
            style={{ width: '100%', pointerEvents: 'none', display: 'block' }}
          />
        </StyledImgZoomWrapper>
      </StyledWrapperButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName="react-modal--img-zoom">
        <StyledWrapperButton
          type="button"
          onClick={closeModal}
          cursor="zoom-out"
          className="d-flex align-items-stretch flex-row-reverse">
          {helpers && (
            <div className="d-flex justify-content-start flex-column bg-white p-3">
              {helpers.map(h => (
                <div className="d-flex flex-column align-items-start mb-3">
                  <span className="text-muted">{h.title}</span>
                  {h.value && <span className="font-weight-bold">{h.value}</span>}
                  {h.src && (
                    <img src={h.src} alt="" width={150} height={150} className="rounded-circle" />
                  )}
                </div>
              ))}
            </div>
          )}
          <img src={largeSrc} alt="" className="img-fluid" style={{ maxHeight: '90vh' }} />
        </StyledWrapperButton>
      </Modal>
    </React.Fragment>
  );
};

export default ZoomOnHover;
