import { Form, Formik } from "formik";
import { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { colors } from "../../assets/themes";
import {
  Button,
  Icon,
  List,
  ListEditable,
  Modal,
  Popover,
} from "../../components";
import { BUTTON, POPOVER } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import {
  usePostPhotos,
  usePutJobInformations,
  usePutJobPhone,
} from "../../modules/routes/job-routes";
import polyglot from "../../utils/polyglot";
import getFields from "./get-fields";
import { EDIT_INFORMATIONS_PATH, JOB_DATETIME_EDIT_PATH } from "../Job/utils";

const EditView = ({ onClose }) => {
  const { data } = useJob();
  const history = useHistory();
  const postPhotos = usePostPhotos({ id: data.id });
  const updateJob = usePutJobInformations({ id: data.id });
  const updatePhone = usePutJobPhone();
  const params = useParams();
  const formikRef = useRef();

  const handleSubmit = (name, values) => {
    updateJob.mutate({ name, values });
  };

  const handleCollapse = () => {
    history.replace(EDIT_INFORMATIONS_PATH);
    formikRef.current.handleReset();
  };

  useEffect(() => {
    if (updateJob.isSuccess || updatePhone.isSuccess || postPhotos.isSuccess)
      handleCollapse();
  }, [updateJob.isSuccess, updatePhone.isSuccess, postPhotos.isSuccess]);

  return (
    <div>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("job.edit")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        {getFields({
          data,
          postPhotos,
          updatePhone,
        }).map((field, i) => {
          const isTitle = typeof field === "string";
          return (
            field && (
              <div key={`job-field-${field.name || i}`}>
                {isTitle ? (
                  <List.Header>{field}</List.Header>
                ) : (
                  <Formik
                    innerRef={formikRef}
                    validationSchema={field.validationSchema}
                    initialValues={
                      field.initialValues || {
                        [field.name]: data[field.name],
                      }
                    }
                    onSubmit={(v, bag) => {
                      if (field.onSubmit) {
                        field.onSubmit(v, bag);
                      } else {
                        handleSubmit(field.name, v, bag);
                      }
                    }}
                  >
                    {({ submitForm, handleReset, isValid, dirty }) => (
                      <Form>
                        <ListEditable
                          onSubmit={submitForm}
                          submitDisabled={!isValid || !dirty}
                          disabled={field.disabled}
                          title={field.title}
                          name={field.name}
                          placeholder={field.placeholder}
                          Component={field.Component}
                          RenderValue={field.RenderValue}
                          value={field.value}
                          isLoading={(() => {
                            if ("isLoading" in field) {
                              return field.isLoading;
                            }
                            return updateJob.isLoading || updateJob.isSuccess;
                          })()}
                          onExpend={() =>
                            field.name !== "datetime"
                              ? history.replace(
                                  `${EDIT_INFORMATIONS_PATH}/${field.name}`
                                )
                              : history.replace(JOB_DATETIME_EDIT_PATH)
                          }
                          onCollapse={() => {
                            history.replace(EDIT_INFORMATIONS_PATH);
                            handleReset();
                          }}
                          isExpended={
                            params.field && params.field === field.name
                          }
                          RightComponent={() =>
                            (field.disabled || field.RightComponent) && (
                              <>
                                {field.disabled && (
                                  <Popover.Enhancer
                                    trigger={POPOVER.TRIGGER_TYPE.HOVER}
                                    position={POPOVER.POSITIONS.BOTTOM_RIGHT}
                                    content={() => (
                                      <Popover.Elem.Tooltip>
                                        <span
                                          css={`
                                            display: block;
                                          `}
                                        >
                                          {polyglot.t("job.close_to_edit_alt")}
                                        </span>
                                      </Popover.Elem.Tooltip>
                                    )}
                                  >
                                    <Button.Medium
                                      kind={BUTTON.KIND.MINIMAL}
                                      shape={BUTTON.SHAPE.CIRCLE}
                                    >
                                      <Icon.Medium
                                        name="lock"
                                        color={colors.muted}
                                      />
                                    </Button.Medium>
                                  </Popover.Enhancer>
                                )}
                                {field.RightComponent && field.RightComponent()}
                              </>
                            )
                          }
                        >
                          {field.Component && field.Component()}
                        </ListEditable>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            )
          );
        })}
      </Modal.Item.Wrapper>
    </div>
  );
};

export default EditView;
