import { useRef } from "react";
import { useFormikContext } from "formik";
import Divider from "../../../components/Divider";
import { borderWidth, sizes, spacings } from "../../../assets/themes";
import polyglot from "../../../utils/polyglot";
import AutoSizeTextarea from "../../../components/AutoSizeTextarea";
import Block from "../../../components/Block";
import Button from "../../../components/Button";
import Icon from "../../../components/Icon";
import { BUTTON, INPUT } from "../../../components/Styles/variants";
import Microphone from "@/pages/chatBox/Microphone";

const ChatInput = ({
  value,
  LeftComponent,
  children,
  content,
  name,
  disabled,
  maxLength,
  placeholder = polyglot.t("chat.add_comment"),
  onChange = () => {},
}) => {
  const { values } = useFormikContext();
  const inputRef = useRef();
  const canSend = values.uploads.length > 0 || values.body.trim() !== "";
  return (
    <>
      <Divider.Cell />
      <Block display="flex" flexDirection="column" paddingY={spacings.sm}>
        {!disabled && (
          <Block
            display="flex"
            alignItems="flex-end"
            gap={spacings.s}
            paddingX={spacings.m}
            position="relative"
            paddingLeft={!LeftComponent && spacings.m}
          >
            <Block flex="1">
              <AutoSizeTextarea
                name={name}
                ref={inputRef}
                value={value}
                placeholder={placeholder}
                shape={INPUT.SHAPE.CIRCLE}
                onChange={onChange}
                maxLength={maxLength}
                RightComponent={() => (
                  <>
                    {LeftComponent && (
                      <div
                        css={`
                          margin-left: ${spacings.xs};
                          display: flex;
                          align-items: center;
                        `}
                      >
                        {LeftComponent()}
                      </div>
                    )}
                  </>
                )}
              >
                {!!content && content()}
              </AutoSizeTextarea>
            </Block>
            <Block
              display="flex"
              alignItems="center"
              height={`calc(${sizes.size48} + ${borderWidth.m} + ${borderWidth.m})`}
            >
              {!canSend ? (
                <Microphone />
              ) : (
                <Button.Medium type="submit" shape={BUTTON.SHAPE.CIRCLE}>
                  <Icon.Large name="arrow-up" />
                </Button.Medium>
              )}
            </Block>
          </Block>
        )}
        {children && (
          <Block
            css={`
              margin-top: ${spacings.xs};
              margin-left: ${spacings.sm};
              margin-right: ${spacings.sm};
            `}
          >
            {children}
          </Block>
        )}
      </Block>
    </>
  );
};
export default ChatInput;
