import { useHistory } from "react-router-dom";
import ChatNoResult from "../../assets/images/svg-icons/NoResults/chat.svg";
import { colors, spacings } from "../../assets/themes";
import {
  Block,
  Icon,
  InfiniteScrollEnhancer,
  List,
  NoResult,
} from "../../components";
import { LIST } from "../../components/Styles/variants";
import { useGetTickets } from "../../modules/routes/dashboard-routes";
import polyglot from "../../utils/polyglot";
import * as Layouts from "./Layouts";
import PageTitle from "./PageTitle";
import { TICKETS_NEW_PATH } from "./config/routes";
import ConversationsSkeleton from "./skeletons/ConversationsSkeleton";
import TicketChat from "./tickets/TicketChat";

const Tickets = ({ config }) => {
  const getTickets = useGetTickets();
  const history = useHistory();
  return (
    <Layouts.Messages
      path={config.path}
      // newTicketRoute -> Create ticket/new route entry
      newTicketRoute
      LeftMenu={() => (
        <>
          <Block marginBottom={spacings.l} marginX={spacings.s}>
            <PageTitle>{config.title}</PageTitle>
          </Block>
          <Block marginTop={spacings.m}>
            <List.Item
              onClick={() => history.push(TICKETS_NEW_PATH)}
              shape={LIST.SHAPE.ROUND}
              withGutters
              divider={false}
              style={{ backgroundColor: colors.primary50 }}
              LeftComponent={() => (
                <Icon.XLarge name="envelope" color={colors.primary} />
              )}
            >
              <List.Elem.Title strong>
                {polyglot.t("tickets.new")}
              </List.Elem.Title>
              {/* <List.Elem.Subtitle
                style={{
                  fontWeight: "var(--font-weight-regular)",
                }}
              >
                {polyglot.t("tickets.response_delay")}
              </List.Elem.Subtitle> */}
            </List.Item>
          </Block>
          <InfiniteScrollEnhancer query={getTickets} unionBy="tickets_closed">
            {({ data, isLoading }) =>
              !isLoading ? (
                <>
                  {!data.tickets_opened?.length > 0 &&
                    !data.tickets_closed?.length > 0 && (
                      <NoResult
                        artwork={ChatNoResult}
                        title={polyglot.t("tickets.no_tickets")}
                        subtitle={polyglot.t("tickets.no_tickets_subtitle")}
                      />
                    )}
                  {data.tickets_opened?.length > 0 && (
                    <Block as={List.Header} paddingX={spacings.s}>
                      {polyglot.t("common.active")}
                    </Block>
                  )}
                  {data.tickets_opened.map((ticket) => (
                    <Layouts.ChatThumbnail
                      noDefaultNameAvatar
                      users={[
                        {
                          avatar: ticket?.avatar,
                          first_name: ticket?.first_name,
                        },
                      ]}
                      {...ticket}
                      key={`ticket-thumbnail-opened-${ticket.id}-${ticket.external_id}`}
                      documentTitle={`${ticket.title} (#${ticket.external_id})`}
                      to={`${config.path}/${ticket.id}`}
                    />
                  ))}
                  {data.tickets_closed?.length > 0 && (
                    <Block as={List.Header} paddingX={spacings.s}>
                      {polyglot.t("common.closed_f")}
                    </Block>
                  )}
                  {data.tickets_closed.map((ticket) => (
                    <Layouts.ChatThumbnail
                      {...ticket}
                      key={`ticket-thumbnail-closed-${ticket.id}-${ticket.external_id}`}
                      documentTitle={`${ticket.title} (#${ticket.external_id})`}
                      to={`${config.path}/${ticket.id}`}
                    />
                  ))}
                </>
              ) : (
                <ConversationsSkeleton />
              )
            }
          </InfiniteScrollEnhancer>
        </>
      )}
    >
      <TicketChat />
    </Layouts.Messages>
  );
};

export default Tickets;
