import React, { useContext } from "react";

import { colors, spacings } from "../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  Button,
  Col,
  H2,
  Link,
  List,
  Row,
  StatusEnhancer,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import { DashboardContext } from "../../modules/contexts";
import {
  useGoogleAnalytics,
  useRefetchOnHistoryEvent,
} from "../../modules/hooks";
import {
  useGetAccount,
  useLogout,
} from "../../modules/routes/dashboard-routes";
import { CUSTOMER } from "../../pages-admin/UserPreview/config";
import UserPreview from "../../pages-admin/UserPreview/UserPreview";
import polyglot from "../../utils/polyglot";
import AvatarUpload from "./account/AvatarUpload";
import { GET_ACCOUNT_MENU } from "./config/account-menu";
import AccountSkeleton from "./skeletons/AccountSkeleton";
import VIPListItem from "./account/VIPListItem";

const Account = () => {
  const { data, isLoading, refetch } = useGetAccount();
  useRefetchOnHistoryEvent({ refetch });
  const { public_pages } = useContext(DashboardContext);
  const logout = useLogout();
  const { sendEvent } = useGoogleAnalytics();

  return (
    <>
      {!isLoading && (
        <>
          <UserPreview id={data.user?.id} kind={CUSTOMER}>
            <Block display="flex" alignItems="center">
              <AvatarUpload
                src={data.user.avatar}
                name={data.user.first_name}
              />
              <Block display="flex" flexDirection="column">
                <StatusEnhancer isVip={data.user?.vip_status?.vip_active}>
                  <H2>{data.user.first_name}</H2>
                </StatusEnhancer>
                <Body16 color={colors.muted}>
                  {polyglot.t("account.n_job_publish_plural", {
                    count: data.user.jobs_posted,
                  })}
                </Body16>
              </Block>
            </Block>
          </UserPreview>
          <Block marginTop={spacings.s}>
            <VIPListItem vip_status={data.user.vip_status} />
          </Block>

          {GET_ACCOUNT_MENU({ data, user: data.user, public_pages }).map(
            (item, i) => (
              <Row gutter={[0, spacings.m]} key={`account-group-${i}`}>
                <Col>
                  <List.Header>{item.title}</List.Header>
                  <Row gutter={[spacings.m]}>
                    {item.routes.map((route, ri) => (
                      <Col
                        size={{ xs: 12, sm: 6, xl: 4 }}
                        key={`account-menu-${ri}`}
                      >
                        <Link
                          to={route.path}
                          href={route.href}
                          onClick={
                            route.event ? () => sendEvent(route.event) : null
                          }
                          css={`
                            display: flex;
                            &:hover {
                              text-decoration: none;
                            }
                          `}
                        >
                          <Block
                            paddingTop={spacings.m}
                            paddingBottom={spacings.m}
                            fontWeight="var(--font-weight-regular)"
                            color={colors.body}
                          >
                            {!!route.LeftComponent && <route.LeftComponent />}
                            <Body18 strong color={colors.primary}>
                              {route.renderTitle
                                ? route.renderTitle()
                                : route.title}
                            </Body18>
                            <Body16
                              color={colors.muted}
                              style={{
                                fontWeight: "var(--font-weight-regular)",
                              }}
                            >
                              {route.renderSubtitle
                                ? route.renderSubtitle()
                                : route.subtitle}
                            </Body16>
                          </Block>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            )
          )}
          <Block
            width={{ xs: "100%", md: "fit-content" }}
            marginTop={spacings.m}
            display="block"
          >
            <Button.Large
              kind={BUTTON.KIND.SECONDARY}
              onClick={() => logout.mutate()}
              isLoading={logout.isLoading || logout.isSuccess}
              block
            >
              {polyglot.t("common.logout")}
            </Button.Large>
          </Block>
        </>
      )}
      {isLoading && <AccountSkeleton />}
    </>
  );
};

export default Account;
