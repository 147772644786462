import React from "react";
import {
  radius,
  shadows,
  sizes,
  borderWidth,
  colors,
  spacings,
} from "../../../assets/themes";
import { Skeleton, Block, List } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const BillingsSkeleton = () => (
  <Skeleton.Group>
    <Block display="flex" marginY={spacings.m}>
      <Skeleton
        height={sizes.size36}
        width={100}
        sr
        sb
        shape={SKELETON.SHAPE.ROUND}
      />
      <Skeleton
        height={sizes.size36}
        width={200}
        sr
        sb
        shape={SKELETON.SHAPE.ROUND}
      />
    </Block>
    <Skeleton height={sizes.size18} width={100} sb st />
    {new Array(10).fill("").map((_, i) => (
      <Block
        key={`skel-bill-h-${i}`}
        borderRadius={radius.m}
        boxShadow={shadows.xs}
        marginY={spacings.m}
        padding={spacings.m}
        border={`solid ${borderWidth.s} ${colors.border}`}
      >
        <List.Item
          divider={false}
          RightComponent={() => <Skeleton height={sizes.size16} width={75} />}
          LeftComponent={() => (
            <Skeleton
              shape={SKELETON.SHAPE.CIRCLE}
              width={sizes.size48}
              height={sizes.size48}
            />
          )}
        >
          <Skeleton height={sizes.size16} width="50%" sb />
          <Skeleton height={sizes.size16} width="30%" />
        </List.Item>
        {new Array(3).fill("").map((_, i) => (
          <React.Fragment key={`skel-bill-${i}`}>
            <List.Item
              divider={false}
              LeftComponent={() => (
                <Skeleton
                  height={sizes.size20}
                  width={sizes.size20}
                  shape={SKELETON.SHAPE.CIRCLE}
                />
              )}
            >
              <Skeleton height={sizes.size16} width={150} />
            </List.Item>
            <Skeleton height={borderWidth.s} width="100%" />
          </React.Fragment>
        ))}
        <Skeleton height={sizes.size48} width="100%" st />
      </Block>
    ))}
  </Skeleton.Group>
);

export default BillingsSkeleton;
