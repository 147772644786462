import { useFormikContext } from "formik";
import { spacings } from "../../assets/themes";
import { Button, Checkbox, Icon, List, Popover } from "../../components";
import {
  BUTTON,
  LIST,
  LIST_HEADER,
  POPOVER,
} from "../../components/Styles/variants";
import { setCheckboxFieldValue } from "../../utils";

const FilterPopover = ({ name, data, title, icon }) => {
  const { values, setFieldValue } = useFormikContext();
  const handleToggleSelectAllAdmin = () => {
    if (
      data.filter((v) => typeof v !== "string")?.length !== values[name].length
    ) {
      setFieldValue(
        name,
        data.filter((v) => typeof v !== "string").map((a) => a.value)
      );
    } else {
      setFieldValue(name, []);
    }
  };

  return (
    <Popover.Enhancer
      trigger={POPOVER.TRIGGER_TYPE.CLICK}
      content={() => (
        <Popover.Elem.Menu
          css={`
            width: 400px;
            padding-top: ${spacings.sm};
            padding-bottom: ${spacings.sm};
          `}
        >
          <List.Group>
            <List.Item
              chevron={false}
              withGutters
              size={LIST.SIZE.COMPACT}
              onClick={() =>
                handleToggleSelectAllAdmin({ setFieldValue, values })
              }
              LeftComponent={() => (
                <Checkbox
                  checked={
                    data.filter((v) => typeof v !== "string")?.length ===
                    values[name].length
                  }
                />
              )}
            >
              Tout sélectionner
            </List.Item>
            {data?.map((d, i) => {
              if (typeof d === "string") {
                return (
                  <List.Header
                    key={`data-filter-item-${name}-${i}`}
                    kind={LIST_HEADER.KIND.SUBTITLE}
                    withGutters
                  >
                    {d}
                  </List.Header>
                );
              }
              return (
                <List.Item
                  key={`data-filter-item-${name}-${i}`}
                  chevron={false}
                  withGutters
                  size={LIST.SIZE.COMPACT}
                  onClick={() =>
                    setCheckboxFieldValue(name, d.value, {
                      setFieldValue,
                      values,
                    })
                  }
                  LeftComponent={() => (
                    <Checkbox checked={values[name].includes(d.value)} />
                  )}
                >
                  {d.label}
                </List.Item>
              );
            })}
          </List.Group>
        </Popover.Elem.Menu>
      )}
    >
      <Button.Medium
        kind={BUTTON.KIND.SECONDARY}
        LeftComponent={() => <Icon.Medium name={icon} />}
      >
        {title} ({values[name].length})
      </Button.Medium>
    </Popover.Enhancer>
  );
};
export default FilterPopover;
