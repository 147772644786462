import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1027() {
  const DEFAULT_NB_HOURS = 2;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1,
            subtitle: polyglot.t("categories.install_nest_indoor_camera"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.install_nest_learning_thermostat"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 3,
            subtitle: polyglot.t(
              "categories.install_multiple_connected_devices"
            ),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues,
    config: { IsNbHoursReadOnly: true },
  };
}
