import AppleBadgeFr from "images/app/apple-badge-fr.svg";
import GoogleBadgeFr from "images/app/google-play-badge-fr.svg";
import AppleBadgeEn from "images/app/apple-badge-en.svg";
import GoogleBadgeEn from "images/app/google-play-badge-en.svg";
import polyglot from "@/utils/polyglot";

export function getStoreBadgesSrc() {
  if (polyglot.locale === "en") {
    return { android: GoogleBadgeEn, ios: AppleBadgeEn };
  }
  return { android: GoogleBadgeFr, ios: AppleBadgeFr };
}
