import Card from "./Card";
import Title from "./Title";
import Footer from "./Footer";
import Body from "./Body";
import * as Styled from "./Styled";

Card.Title = Title;
Card.Footer = Footer;
Card.Body = Body;
Card.Styled = { ...Styled };

export default Card;
