import moment from 'moment';

const currYear = moment().year();
const currYearLastNumber = Number(
  currYear
    .toString()
    .split('')
    .pop(),
);
const isDecember = moment().month() === 11;
const isJanuary = moment().month() === 0;
const prevYear = currYearLastNumber === 0 ? 9 : currYearLastNumber - 1;
const nextYear = currYearLastNumber >= 9 ? 0 : currYearLastNumber + 1;

export default function cesuDateValidation(code) {
  const ticketYear = Number(code.split('')[17]);
  const ticketEqualCurrYear = ticketYear === currYearLastNumber;

  if (isDecember) {
    return ticketEqualCurrYear || ticketYear === nextYear;
  }
  if (isJanuary) {
    return ticketEqualCurrYear || ticketYear === prevYear;
  }
  return ticketEqualCurrYear;
}
