import { useFormikContext } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { Block, Button, Icon } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { FormLargeStepper, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH, MAX_NB_HOURS } from "../constants";
import HedgeTrimmingNbHoursModal from "./2002/HedgeTrimmingNbHoursModal";

export function useCategory2002() {
  const initialValues = { nb_hours: 6 };
  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.how_many_hours_of_service_do_you_need"),
      validationSchema: yup.object().shape({
        nb_hours: yup.number().required(),
      }),
      View: () => {
        const { setFieldValue } = useFormikContext();
        const [modalIsOpen, setModalIsOpen] = useState(false);

        const handleClose = () => {
          setModalIsOpen(false);
        };

        const handleChange = (value) => {
          setFieldValue("nb_hours", value);
          handleClose();
        };

        return (
          <>
            <StyledFormControl>
              <FormLargeStepper
                divider={false}
                name="nb_hours"
                max={MAX_NB_HOURS}
                min={1}
                step={0.5}
                FieldChildren={(field) =>
                  polyglot.toDuration(field.value, true)
                }
              />
              <Block display="flex" justifyContent="center">
                <Button.Link
                  onClick={() => setModalIsOpen(true)}
                  RightComponent={() => (
                    <Icon.Medium name="info-circle-solid" />
                  )}
                >
                  {polyglot.t("categories.unknow_nb_hours_needed")}
                </Button.Link>
              </Block>
              {modalIsOpen && (
                <HedgeTrimmingNbHoursModal
                  isOpen={modalIsOpen}
                  onClose={handleClose}
                  onChange={handleChange}
                />
              )}
            </StyledFormControl>
          </>
        );
      },
    },
  ];
  return {
    initialValues,
    pages,
    config: { IsNbHoursReadOnly: true },
    version: 1.1,
  };
}
