import { colors, sizes, spacings } from "../../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Col,
  Divider,
  Link,
  List,
  Row,
} from "../../../../components";
import { LIST } from "../../../../components/Styles/variants";
import { CUSTOMER } from "../../../../pages-admin/UserPreview/config";
import UserPreview from "../../../../pages-admin/UserPreview/UserPreview";
import getNameInitials from "../../../../utils/get-initials";
import polyglot from "../../../../utils/polyglot";
import { REFERRAL_PATH } from "../../config/routes";

const WalletReferralItem = ({
  first_name,
  last_name,
  earned_amount,
  user_id,
}) => (
  <>
    <Row gutter={spacings.m}>
      <Col size={{ xs: 5, md: 3, lg: 3 }}>
        <UserPreview kind={CUSTOMER} id={user_id}>
          <List.Item
            divider={false}
            size={LIST.SIZE.LARGE}
            LeftComponent={() => (
              <Avatar size={sizes.size48} name={first_name} />
            )}
          >
            <Body16 strong numberOfLines={1}>
              {first_name} {last_name}
            </Body16>
            <Block display={{ xs: "block", md: "none" }}>
              <Body14 color={colors.muted} numberOfLines={1}>
                {polyglot.t("referral.referral_earn")}
              </Body14>
            </Block>
          </List.Item>
        </UserPreview>
      </Col>
      <Col size={{ xs: 0, md: 4, lg: 4 }} display={{ xs: "none", md: "block" }}>
        <List.Item divider={false} size={LIST.SIZE.LARGE}>
          <Body16 numberOfLines={1} color={colors.muted}>
            {polyglot.t("referral.referral_earn")}
          </Body16>
        </List.Item>
      </Col>
      <Col size={{ xs: 3, md: 2, lg: 3 }}>
        <List.Item divider={false} size={LIST.SIZE.LARGE}>
          <Body16 css="align-self: flex-end" strong color={colors.success}>
            +{polyglot.toSmartCurrency(earned_amount)}
          </Body16>
        </List.Item>
      </Col>
      <Col size={{ xs: 4, md: 3, lg: 2 }}>
        <List.Item divider={false} size={LIST.SIZE.LARGE}>
          <Block display="flex" justifyContent="flex-end" width="100%">
            <Link numberOfLines={1} to={REFERRAL_PATH}>
              {polyglot.t("common.see_more")}
            </Link>
          </Block>
        </List.Item>
      </Col>
    </Row>
    <Divider.Cell />
  </>
);
export default WalletReferralItem;
