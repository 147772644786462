import { Field } from "formik";
import React, { Component, Fragment } from "react";
import polyglot from "../../../../../utils/polyglot";

class GiftCardRegisteredCard extends Component {
  state = {
    useNewCard: false,
  };

  componentDidMount() {
    this.props.cardNumber ? this.useNewCard(false) : this.useNewCard(true);
  }

  useNewCard = (bool) => {
    this.props.setFieldValue("useNewCard", bool);
    this.setState({ useNewCard: bool });
  };

  render() {
    const { cardNumber } = this.props;
    return (
      <>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <div className="radio-style">
            <Field
              type="radio"
              name="useNewCard"
              onChange={() => {
                this.props.handleChange;
                this.useNewCard(false);
              }}
              checked={!this.state.useNewCard}
              id="useNewCardInput"
              className="mr-2"
            />
            <label htmlFor="useNewCardInput">
              {polyglot.t("common.cards.pay_with_registered_card", {
                card: cardNumber.match(/.{1,4}/g).join(" "),
              })}
            </label>
          </div>
        </div>
        <div
          className={`card-container ${
            this.state.useNewCard ? "card-disabled" : ""
          }`}
          onClick={() => this.useNewCard(false)}
        />
        <div className="u-divider" data-label={polyglot.t("common.or")} />
        {!this.state.useNewCard ? (
          <div className="my-3 d-flex align-items-center justify-content-center w-100">
            <button
              className="btn u-action-button w-100"
              type="button"
              onClick={() => this.useNewCard(true)}
            >
              {polyglot.t("common.cards.pay_with_other_card")}
            </button>
          </div>
        ) : null}
      </>
    );
  }
}
export default GiftCardRegisteredCard;
