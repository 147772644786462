import { bindActionCreators } from 'redux';
import React, { Component, Fragment } from 'react';
import { Formik } from 'formik';
import connect from 'react-redux/es/connect/connect';
import * as Yup from 'yup';
import { submitForm } from '../../../modules/actions/global/form-actions';
import GiftCardAddFormInputs from './GiftCardAddFrom/GiftCardAddFormInputs';
// import WizardAlert from '../../components/Wizard/WizardAlert';
import polyglot from '../../../utils/polyglot';
import GiftCardAddSuccessModal from './GiftCardAddSuccessModal';
// import { scrollToTop } from '../../../utils/functions';

const validationSchema = Yup.object().shape({
  code: Yup.string().required(polyglot.t('common.errors.field_required')),
});

const initialValues = {
  code: '',
};

class GiftCardAddForm extends Component {
  state = {
    submitted: false,
    alert: {},
    modalIsOpen: false,
    giftCardAmount: '',
    redirectUrl: '',
    walletAmount: '',
  };

  componentDidUpdate(prevProps) {
    prevProps.form.succeed !== this.props.form.succeed && this.props.form.succeed
      ? this.handleSubmitSucceed(this.props)
      : null;
    prevProps.form.error !== this.props.form.error && this.props.form.error
      ? this.handleSubmitErrors(this.props)
      : null;
  }

  componentDidMount = () => {
    this.setState({ walletAmount: this.props.walletAmount });
  };

  showAlert = (text, type) => {
    this.setState({
      alert: {
        text,
        type,
      },
    });
    // scrollToTop(document.querySelectorAll('.wizard-alert)[0]'));
    // if (type !== 'success') {
    //   this.state.alert.text ? this.resetAlert() : null;
    // }
  };

  resetAlert = () => {
    const elem = document.querySelectorAll('.wizard-alert')[0];
    elem.style.WebKitAnimation = 'none';
    elem.style.animation = 'none';
    setTimeout(() => {
      elem.style.WebKitAnimation = '';
      elem.style.animation = '';
    }, 100);
  };

  handleSubmitSucceed = () => {
    this.setState({
      giftCardAmount: this.props.form.succeed.giftCardAmount,
    });
    this.showAlert(polyglot.t('gift_cards.add.success'), 'success'),
      this.props.form.succeed.redirectUrl
        ? this.setState({ redirectUrl: this.props.form.succeed.redirectUrl })
        : false;
    this.openModal();
  };

  submitCode = data => {
    this.setState({ submitted: true });
    this.props.submitForm(data, this.props.config, 'put');
  };

  handleSubmitCallback = () => {
    this.setState({ submitted: false });
    this.focusFirstInput();
  };

  focusFirstInput = () => {
    setTimeout(() => {
      document.getElementById('code').focus();
    }, 0);
  };

  handleSubmitErrors = () => {
    this.handleSubmitCallback();
    this.props.form.error.errorMessage
      ? this.showAlert(this.props.form.error.errorMessage, 'danger')
      : null;
    const { error } = this.props.form;
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
    this.setState({ submitted: false });
    this.setState({
      walletAmount: Number(this.state.walletAmount) + Number(this.state.giftCardAmount),
    });
  };

  render() {
    return (
      <Fragment>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={this.submitCode}>
          {props => (
            <Fragment>
              <GiftCardAddFormInputs
                submitCode={this.submitCode}
                submitted={this.state.submitted}
                {...props}
                {...this.state}
              />
            </Fragment>
          )}
        </Formik>
        <GiftCardAddSuccessModal
          walletAmount={this.state.walletAmount}
          giftCardAmount={this.state.giftCardAmount}
          redirectUrl={this.state.redirectUrl}
          modalIsOpen={this.state.modalIsOpen}
          openModal={this.openModal}
          closeModal={this.closeModal}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ submitForm }, dispatch),
});

const mapStateToProps = state => ({
  form: state.form,
});

export default connect(mapStateToProps, mapDispatchToProps)(GiftCardAddForm);
