import { motion, useAnimation, useDragControls } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { colors, radius, spacings } from "@/assets/themes";
import { Block, Button, Caption, Icon } from "@/components";
import { BUTTON } from "@/components/Styles/variants";

const ProgressBarContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 20px;
  margin: ${spacings.xs} 0;
  border-radius: ${radius.s};
  overflow: hidden;
  cursor: pointer;
`;

const AmplitudeContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const AmplitudeBar = styled.div.attrs((props) => ({
  style: {
    backgroundColor: props.barColor,
    height: `${props.height}px`,
  },
}))`
  min-width: ${spacings.xs};
  margin-right: 1px;
  border-radius: ${radius.circle};
  min-height: ${spacings.xs};
`;

const ProgressLayer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  ${AmplitudeBar} {
    background-color: ${({ activeBarColor }) => activeBarColor};
  }
`;

const DragHandle = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1;
`;

const AudioPlayer = ({
  amplitudes,
  duration,
  barColor = "rgba(255, 255, 255, 0.5)",
  activeBarColor = colors.onColor,
  buttonColor = colors.onColor,
  url,
}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPlayingBeforeInteraction, setWasPlayingBeforeInteraction] =
    useState(false);
  const controls = useAnimation();
  const dragControls = useDragControls();
  const startTimeRef = useRef(null);
  const containerRef = useRef(null);
  const audioRef = useRef(null);
  const [audioCurrentTime, setAudioCurrentTime] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleLoadStart = () => {
    setIsLoading(true);
  };

  const handleCanPlay = () => {
    setIsLoading(false);
  };

  const handleTimeUpdate = (time) => {
    audioRef.current.currentTime = time;
  };

  const handleAnimationPause = useCallback(() => {
    let newTime = audioRef.current.currentTime;
    if (startTimeRef.current) {
      const elapsedTime = (Date.now() - startTimeRef.current) / 1000;
      newTime = audioRef.current.currentTime + elapsedTime;
      startTimeRef.current = null;
    }
    handleTimeUpdate(newTime);
    audioRef.current.pause();
    setIsPlaying(false);
  }, [audioCurrentTime]);

  const handleAnimationStop = useCallback(() => {
    handleAnimationPause();
    controls.set({ width: 0 });
    handleTimeUpdate(0);
    startTimeRef.current = null;
  }, [audioCurrentTime]);

  const updatePlay = (isPlay) => {
    if (isPlay) {
      audioRef.current.play();
      setIsPlaying(true);
    } else {
      controls.stop();
      handleAnimationPause();
    }
  };

  useEffect(() => {
    if (audioCurrentTime > 0 && isPlaying) {
      // start only when the audio is really playing (prevent loading un-synced audio)
      startTimeRef.current = Date.now();
      controls.start({
        width: "100%",
        transition: {
          duration: duration - audioRef.current.currentTime,
          ease: "linear",
        },
      });
    } else {
      controls.stop();
    }
  }, [audioCurrentTime, isPlaying]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const handlePositionChange = async (clientX) => {
    if (containerRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      const containerWidth = containerRect.width;
      const containerLeft = containerRect.left;
      const newWidth = clientX - containerLeft;
      const percentage = Math.min(Math.max(newWidth / containerWidth, 0), 1);
      const newTime = percentage * duration;
      await controls.set({ width: `${percentage * 100}%` });
      handleTimeUpdate(newTime);
    }
  };

  const handleDrag = async (event, info) => {
    handlePositionChange(info.point.x);
  };

  const handleDragEnd = () => {
    if (wasPlayingBeforeInteraction) {
      updatePlay(true);
    }
  };

  const handleMouseDown = () => {
    setWasPlayingBeforeInteraction(isPlaying);
    updatePlay(false);
  };

  const handleClick = async (event) => {
    await handlePositionChange(event.clientX);
    if (wasPlayingBeforeInteraction) {
      updatePlay(true);
    }
  };

  const handleAudioTimeUpdate = (event) => {
    setAudioCurrentTime(event.target.currentTime);
  };

  return (
    <Block>
      <audio
        ref={audioRef}
        src={url}
        onLoadStart={handleLoadStart}
        onCanPlay={handleCanPlay}
        onTimeUpdate={handleAudioTimeUpdate}
        // preload={false}
      >
        <track kind="captions" />
      </audio>
      <Block display="flex" alignItems="center" gap={spacings.s}>
        <Button.Small
          onClick={() => updatePlay(!isPlaying)}
          shape={BUTTON.SHAPE.CIRCLE}
          isLoading={isLoading}
          css={`
            background-color: transparent !important;
            color: ${buttonColor}!important;
            &:hover {
              color: ${buttonColor}!important;
            }
          `}
        >
          {isPlaying ? (
            <Icon.Small name="pause-solid" />
          ) : (
            <Icon.Small name="play-solid" />
          )}
        </Button.Small>
        <Block>
          <ProgressBarContainer ref={containerRef}>
            <AmplitudeContainer>
              {amplitudes?.map((amplitude, index) => (
                <AmplitudeBar
                  key={index}
                  height={amplitude * 20}
                  barColor={barColor}
                />
              ))}
            </AmplitudeContainer>
            <ProgressLayer
              initial={{ width: 0 }}
              animate={controls}
              onAnimationComplete={handleAnimationStop}
              activeBarColor={activeBarColor}
            >
              {amplitudes?.map((amplitude, index) => (
                <AmplitudeBar key={index} height={amplitude * 20} />
              ))}
            </ProgressLayer>
            <DragHandle
              drag="x"
              dragControls={dragControls}
              dragConstraints={containerRef}
              dragElastic={0}
              onMouseDown={handleMouseDown}
              onTouchStart={handleMouseDown}
              onClick={handleClick}
              onDrag={handleDrag}
              onDragEnd={handleDragEnd}
            />
          </ProgressBarContainer>
        </Block>
        <Caption
          strong
          css={`
            white-space: nowrap;
          `}
        >
          {formatTime(duration - audioCurrentTime)}
        </Caption>
      </Block>
    </Block>
  );
};

export default AudioPlayer;
