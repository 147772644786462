import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useToast } from ".";
import { api } from "../../utils";
import polyglot from "../../utils/polyglot";

const fetch = async (query, pageParam = 1, params) => {
  const response = await api.get(query, {
    params: { page: pageParam, ...params },
  });
  return response.data;
};

export default function useInfiniteFetch(query, params, options) {
  const toast = useToast();
  const res = useInfiniteQuery(
    [options?.queryKey || query],
    async ({ pageParam = 1 }) => {
      const r = await fetch(query, pageParam, params);
      return r;
    },
    {
      getPreviousPageParam: (firstPage) => firstPage.data?.page - 1 ?? false,
      getNextPageParam: (lastPage) => {
        if (lastPage.data?.page) {
          if (lastPage.data?.page < lastPage.data?.total_pages) {
            return lastPage.data?.page + 1;
          }
          return false;
        }
        return 1;
      },
      ...options,
      refetchInterval: 0,
    }
  );
  useEffect(() => {
    if (res.isError) toast.danger(polyglot.t("common.errors.get"));
  }, [res.isError]);

  return { ...res, isLoading: res.isLoading || !res.data, key: query };
}
