import polyglot from "@/utils/polyglot";
import { Modal } from "../../../components";
import JobberOfferItemContent from "../jobs/JobberOfferItemContent";

const TicketNewJobberModal = ({ job, onChange, onClose, isOpen }) => (
  <Modal.Small fullScreenOnMobile onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Title>{polyglot.t("tickets.select_jobber")}</Modal.Item.Title>
      <Modal.Item.Subtitle>Pour "{job.title}"</Modal.Item.Subtitle>
    </Modal.Item.Header>
    {job.booked_offers.map((offer) => (
      <JobberOfferItemContent
        {...offer}
        jobber={{ ...offer.jobber, url: null }}
        key={`job-contact-selection-${job.id}`}
        chevron
        divider
        withGutters
        onClick={() => {
          onChange(offer);
          onClose();
        }}
      />
    ))}
  </Modal.Small>
);
export default TicketNewJobberModal;
