import React, { useRef, useState } from "react";
import MaskedInput from "react-text-mask";
import styled from "styled-components";
import { colors, spacings } from "../assets/themes";
import Block from "./Block";
import Button from "./Button";
import Icon from "./Icon";
import Spinner from "./Spinner";
import { InputBase } from "./Styles/Base";
import { BUTTON, ICON, INPUT } from "./Styles/variants";

const UnstyledInput = styled.input`
  border: none;
  background: none;
  ${"" /* prevent outline in input  */}
  outline: 0 !important;
  width: 100%;
`;

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100;
  align-items: stretch;
`;

const StyledFieldContent = styled.div`
  display: flex;
  align-items: stretch;
`;

const StyledComponent = styled(Block)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomInput = React.forwardRef((props, ref) =>
  props.mask ? (
    <MaskedInput
      guide={props.guide}
      mask={props.mask || []}
      placeholder={props.placeholder}
      id={props.name}
      {...props}
      ref={ref}
      render={(ref, maskedProps) => (
        <UnstyledInput ref={ref} {...maskedProps} />
      )}
    />
  ) : (
    <UnstyledInput {...props} ref={ref} />
  )
);

const Input = React.forwardRef(
  (
    {
      label,
      name,
      type = "text",
      placeholder,
      value,
      className,
      onChange,
      onClick,
      pattern,
      disabled = false,
      RightComponent,
      isLoading,
      LeftComponent,
      autoComplete,
      mask,
      autoFocus = false,
      error,
      hint,
      inputClassName,
      guide = false,
      onFocus = () => null,
      onBlur = () => null,
      readOnly,
      shape,
      size,
      clearable,
      children,
      InputComponent,
      isFocus: forceFocus = false,
      ...rest
    },
    ref
  ) => {
    const inputRef = ref || useRef();
    const [isFocus, setIsFocus] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const hasLeftComponent = type === "search" || LeftComponent;
    const handleClearClick = () => {
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
      ).set;
      nativeInputValueSetter.call(inputRef.current, "");
      const ev = new Event("input", { bubbles: true });
      inputRef.current?.dispatchEvent(ev);
    };

    const handleFocus = (e) => {
      onFocus(e);
      setIsFocus(true);
    };

    const setFocus = () => {
      if (mask) {
        inputRef?.current?.inputElement.focus();
      } else {
        inputRef?.current?.focus();
      }
    };

    const setBlur = () => {
      if (mask) {
        inputRef?.current?.inputElement.blur();
      } else {
        inputRef?.current?.blur();
      }
    };

    const handleBlur = (e) => {
      onBlur(e);
      setBlur();
      setIsFocus(false);
    };

    const getSearchSize = () => {
      if (size === INPUT.SIZE.SMALL) {
        return ICON.SIZE.MEDIUM;
      }
      return ICON.SIZE.LARGE;
    };

    const inputProps = {
      name,
      type: !showPassword ? type : "text",
      value,
      pattern,
      disabled,
      mask,
      RightComponent,
      LeftComponent,
      autoComplete,
      hasLeftComponent,
      onChange: onClick ? () => null : onChange,
      placeholder,
      autoFocus,
      guide,
      ref: inputRef,
      readOnly: readOnly || !!onClick,
      onFocus: handleFocus,
      onBlur: handleBlur,
      ...rest,
    };

    return (
      <InputBase
        data-content="input"
        as="div"
        position="relative"
        alignItems="center"
        display="flex"
        isFocus={forceFocus || isFocus}
        disabled={disabled}
        isClickable={!!onClick}
        size={size}
        onClick={(e) => {
          if (onClick) onClick(e);
          if (onFocus) {
            setFocus();
          }
        }}
        shape={shape}
        error={!!error}
        className={className}
      >
        <StyledContent>
          {children}
          <StyledFieldContent>
            {LeftComponent && (
              <StyledComponent marginLeft={spacings.s} color={colors.muted}>
                {LeftComponent()}
              </StyledComponent>
            )}
            {type === "search" && !LeftComponent && (
              <StyledComponent marginLeft={spacings.s}>
                <Icon.Large
                  name="search"
                  size={getSearchSize}
                  color={colors.muted}
                />
              </StyledComponent>
            )}
            {InputComponent ? (
              InputComponent({
                InputComponent: CustomInput,
                innerProps: inputProps,
              })
            ) : (
              <CustomInput {...inputProps} />
            )}

            {isLoading && inputRef.current?.value && (
              <StyledComponent marginRight={spacings.s}>
                <Spinner.Small color={colors.muted} />
              </StyledComponent>
            )}
            {(type === "search" || clearable) &&
              !isLoading &&
              inputRef.current?.value && (
                <StyledComponent>
                  <Button.Small
                    kind={BUTTON.KIND.MINIMAL}
                    shape={BUTTON.SHAPE.CIRCLE}
                    style={{ background: "none" }}
                    onClick={handleClearClick}
                  >
                    <Icon.Large
                      name="times-circle-solid"
                      color={colors.muted}
                    />
                  </Button.Small>
                </StyledComponent>
              )}
            {RightComponent && (
              <StyledComponent marginRight={spacings.s}>
                {RightComponent()}
              </StyledComponent>
            )}
            {type === "password" && !RightComponent && (
              <StyledComponent>
                <Button.Small
                  kind={BUTTON.KIND.MINIMAL}
                  shape={BUTTON.SHAPE.CIRCLE}
                  style={{ background: "transparent" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowPassword((state) => !state);
                  }}
                  marginLeft={spacings.s}
                >
                  <Icon.Large name={!showPassword ? "eye" : "eye-slashed"} />
                </Button.Small>
              </StyledComponent>
            )}
          </StyledFieldContent>
        </StyledContent>
      </InputBase>
    );
  }
);

export default Input;
