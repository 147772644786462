import { Block, Carousel, List } from "@/components";
import { spacings, radius } from "@/assets/themes";
import ReminderItem from "./ReminderItem";
import { useBreakpoints } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";

const Reminders = ({ data }) => {
  const breakpoints = useBreakpoints();

  return (
    <>
      <List.Header>
        {polyglot.t("home.my_reminders")} ({data?.length})
      </List.Header>
      <Block marginX={{ xs: `calc(${spacings.m} * -1)`, md: "0px" }}>
        <Carousel
          paddingX={{ xs: spacings.m, md: "0px" }}
          withGutters={breakpoints.get({ xs: true, sm: false })}
          css={`
            margin: ${spacings.s} 0;
            > div {
              border-radius: ${radius.ml};
            }
          `}
          slideToShow={{ xs: 1, lg: 2 }}
          slideToScroll={1}
          gutter={spacings.sm}
        >
          {data.map((reminder, index) => (
            <ReminderItem
              key={`reminder-${index}`}
              id={reminder.id}
              jobber={reminder.jobber}
              kind={reminder.kind}
              url={reminder.url}
              job_title={reminder.job_title}
              date={reminder.date}
            />
          ))}
        </Carousel>
      </Block>
    </>
  );
};

export default Reminders;
