import { useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useImperativeHandle, useState } from "react";
import { spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Body16,
  Button,
  Divider,
  List,
  Radio,
} from "../../../components";
import { formatCardNumber } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { PAYMENT_METHOD_ICON_WIDTH } from "./constants";
import { CreditCardFields } from "./credit-card-fields";
import {
  getCreditCardTypeByName,
  getPaymentMethodBadge,
  getPaymentMethodConfig,
} from "./utils";

const EXPENDED = "expended";
const COLLAPSED = "collapsed";

const transition = { ease: "easeInOut", duration: 0.2 };

const contentVariants = {
  [EXPENDED]: { opacity: 1, height: "auto", transition },
  [COLLAPSED]: { opacity: 0, height: 0, transition },
};

const CreditCardAccordion = React.forwardRef(
  (
    {
      methods,
      needCCCheck,
      withGutters,
      onChange,
      disabled,
      active,
      setActive,
    },
    ref
  ) => {
    const { values, initialValues, setValues, setFieldValue, validateForm } =
      useFormikContext();
    const [localValues, setLocalValues] = useState(values);

    const handleReset = () => {
      setLocalValues({
        default_payment_method: "",
        payment_method: "",
        card_number: "",
        card_type: "",
        token: "",
        cardNumber: "",
        cardExpirationDate: "",
        cardCvx: "",
        cardType: "",
      });
      setValues({
        default_payment_method: "",
        payment_method: "",
        card_number: "",
        card_type: "",
        token: "",
        cardNumber: "",
        cardExpirationDate: "",
        cardCvx: "",
        cardType: "",
      });
      setActive(true);
    };

    const handleCreditCardClick = () => {
      if (values.card_number) {
        onChange({
          payment_method: getCreditCardTypeByName(values.card_type),
        });
      } else {
        setActive(true);
        setFieldValue("payment_method", null);
      }
    };

    useImperativeHandle(
      ref,
      () => ({
        reset: handleReset,
      }),
      [values]
    );

    useEffect(() => {
      // set VISA_MASTERCARD if payment_method dont exist (cf Apple Pay)
      if (values.payment_method && !methods.includes(values.payment_method)) {
        handleCreditCardClick();
      }
    }, [methods]);

    const handleCollapse = (arr) => {
      const newArr = {
        card_number: initialValues.card_number,
        card_type: initialValues.card_type,
        token: initialValues.token,
        cardNumber: "",
        cardExpirationDate: "",
        cardCvx: "",
        cardType: "",
        ...(arr || []),
      };
      setValues((s) => ({ ...s, ...newArr }), false);
      setTimeout(() => {
        validateForm();
      }, 0);
      setLocalValues(newArr);
    };

    useEffect(() => {
      if (disabled) {
        setActive(false);
        handleCollapse({
          payment_method: initialValues.payment_method,
        });
      }
    }, [disabled]);

    const handleExpend = () => {
      const newArr = {
        card_number: "",
        card_type: "",
        token: "",
        payment_method: null,
      };
      setValues((s) => ({ ...s, ...newArr }), false);
      setTimeout(() => {
        validateForm();
      }, 0);
      setLocalValues(newArr);
    };

    const isChecked =
      (values.payment_method &&
        !getPaymentMethodConfig(values.payment_method)?.external) ||
      active;

    useEffect(() => {
      // trigger collapse if payment method change without manually cancel edit
      if (getPaymentMethodConfig(values.payment_method)?.external) {
        handleCollapse();
      }
    }, [active]);

    return (
      <div>
        <Block position="relative">
          <List.Item
            divider={!active}
            withGutters={withGutters}
            disabled={disabled}
            RightComponent={() =>
              localValues.card_type ? (
                <img
                  src={getPaymentMethodBadge(localValues.card_type)}
                  alt={localValues.card_type}
                  css={`
                    width: ${PAYMENT_METHOD_ICON_WIDTH}px;
                  `}
                />
              ) : (
                methods.map(
                  (m) =>
                    getPaymentMethodConfig(m)?.external === false && (
                      <img
                        key={`payment-type-icon-${m}`}
                        css={`
                          width: ${PAYMENT_METHOD_ICON_WIDTH}px;
                          margin-left: ${spacings.xs};
                        `}
                        src={getPaymentMethodBadge(m)}
                        alt={getPaymentMethodBadge(m)}
                      />
                    )
                )
              )
            }
            onClick={handleCreditCardClick}
            LeftComponent={() => (
              <Radio checked={(isChecked && !disabled) || ""} />
            )}
          >
            <div>
              <Body16>{polyglot.t("payment_methods.credit_card")}</Body16>
              <Block display="flex" alignItems="center" flexWrap="wrap">
                {localValues.card_number && (
                  <Body16
                    strong
                    css={`
                      margin-right: ${spacings.s};
                    `}
                  >
                    {formatCardNumber(localValues.card_number)}
                  </Body16>
                )}
                {initialValues.card_number && (
                  <Block position="relative">
                    {active ? (
                      <Button.Link
                        css={`
                          padding: 0;
                          min-height: inherit;
                        `}
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActive(false);
                          handleCollapse({
                            payment_method: initialValues.payment_method,
                          });
                        }}
                      >
                        {polyglot.t("common.cancel")}
                      </Button.Link>
                    ) : (
                      <Button.Link
                        css={`
                          padding: 0;
                          min-height: inherit;
                        `}
                        disabled={disabled}
                        onClick={(e) => {
                          e.stopPropagation();
                          setActive(true);
                          handleExpend();
                        }}
                      >
                        {polyglot.t("common.edit")}
                      </Button.Link>
                    )}
                  </Block>
                )}
              </Block>
            </div>
          </List.Item>
        </Block>

        <AnimatePresence exitBeforeEnter initial={false}>
          {active && (
            <motion.div
              initial={COLLAPSED}
              animate={EXPENDED}
              exit={COLLAPSED}
              variants={contentVariants}
              transtion={transition}
              style={{ overflow: "hidden" }}
            >
              <Block
                css={`
                  margin: 0 ${withGutters ? spacings.m : 0};
                `}
              >
                <Divider.Cell />
                <Block marginTop={spacings.m}>
                  {needCCCheck && (
                    <Block marginBottom={spacings.s}>
                      <Alert.Low
                        title={polyglot.t(
                          "payment_method.you_credit_card_must_be_verified"
                        )}
                      />
                    </Block>
                  )}
                  <CreditCardFields methods={methods} />
                </Block>
                <Divider.Cell />
              </Block>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

export default CreditCardAccordion;
