import { useHistory } from "react-router-dom";
import CesuBtn from "./CesuBtn";
import { Body16 } from "../../../components";
import polyglot from "@/utils/polyglot";

const icon = (
  <svg
    className="svg-set-barcode-manually"
    width="180"
    height="180"
    viewBox="0 0 180 180"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="CRCESU.icon.setManualy" fill="none" fillRule="evenodd">
      <g id="ticket" transform="rotate(90 77 77)">
        <path
          d="M0,2.5 L63.5,2.5 C65.709139,2.5 67.5,4.290861 67.5,6.5 L67.5,122.5 C67.5,124.709139 65.709139,126.5 63.5,126.5 L0,126.5 L0,2.5 Z"
          id="Combined-Shape"
          fill="#DCEBFF"
        />
        <path
          d="M0,-5.1159077e-13 L63.5,-5.1159077e-13 C67.0898509,-5.1159077e-13 70,2.91014913 70,6.5 L70,122.5 C70,126.089851 67.0898509,129 63.5,129 L0,129 L0,124 L63.5,124 C64.3284271,124 65,123.328427 65,122.5 L65,6.5 C65,5.67157288 64.3284271,5 63.5,5 L0,5 L0,-5.1159077e-13 Z"
          id="Combined-Shape"
          fill="#0078FA"
          fillRule="nonzero"
        />
        <path
          d="M16,72 L42,72 L42,82 L16,82 L16,72 Z M16,36 L42,36 L42,45 L16,45 L16,36 Z M16,93 L42,93 L42,96 L16,96 L16,93 Z M16,86 L42,86 L42,89 L16,89 L16,86 Z M16,49 L42,49 L42,52 L16,52 L16,49 Z M16,29 L42,29 L42,32 L16,32 L16,29 Z M16,20 L42,20 L42,25 L16,25 L16,20 Z M16,65 L42,65 L42,68 L16,68 L16,65 Z M16,56 L42,56 L42,61 L16,61 L16,56 Z M16,100 L42,100 L42,110 L16,110 L16,100 Z"
          id="barcode"
          fill="#0078FA"
        />
        <g id="numbers" transform="translate(44.5 19)" fill="#0078FA">
          <rect
            width="3"
            height="11.5"
            rx="1.5"
            id="n1"
            transform="translate(.5 .5)"
          />
          <rect
            width="3"
            height="7"
            rx="1.5"
            id="n2"
            transform="translate(.5 15.5)"
          />
          <rect
            width="3"
            height="8.5"
            rx="1.5"
            id="n3"
            transform="translate(.5 25.5)"
          />
          <rect
            width="3"
            height="11"
            rx="1.5"
            id="n4"
            transform="translate(.5 37.5)"
          />
          <rect
            width="3"
            height="7.5"
            rx="1.5"
            id="n5"
            transform="translate(.5 51)"
          />
          <rect
            width="3"
            height="10"
            rx="1.5"
            id="n6"
            transform="translate(.5 61.5)"
          />
          <rect
            width="3"
            height="7"
            rx="1.5"
            id="n7"
            transform="translate(.5 74.5)"
          />
          <rect
            width="3"
            height="6"
            rx="1.5"
            id="n8"
            transform="translate(.5 84.5)"
          />
        </g>
      </g>
      <g
        id="keyboard"
        transform="translate(56.5 82)"
        fill="#FFF"
        stroke="#252932"
        strokeWidth="4"
      >
        <g id="k9" transform="translate(26 72)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key9"
          />
        </g>
        <g id="k8" transform="translate(52 48)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key8"
          />
        </g>
        <g id="k7" transform="translate(0 48)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key7"
          />
        </g>
        <g id="k6" transform="translate(52 24)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key6"
          />
        </g>
        <g id="k5" transform="translate(26 24)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key5"
          />
        </g>
        <g id="k4" transform="translate(0 24)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 2.10421167e-16,12.6071081 0,10.8888889 L0,3.11111111 C-2.10421167e-16,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key4"
          />
        </g>
        <g id="k3" transform="translate(52)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,-3.15631751e-16 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,3.15631751e-16 3.11111111,0 Z"
            id="key3"
          />
        </g>
        <g id="k2" transform="translate(26)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,-3.15631751e-16 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,3.15631751e-16 3.11111111,0 Z"
            id="key2"
          />
        </g>
        <g id="k1">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,-3.15631751e-16 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 2.10421167e-16,12.6071081 0,10.8888889 L0,3.11111111 C-2.10421167e-16,1.39289189 1.39289189,3.15631751e-16 3.11111111,0 Z"
            id="key1"
          />
        </g>
        <g id="k0" transform="translate(26 48)">
          <path
            d="M3.11111111,0 L10.8888889,0 C12.6071081,0 14,1.39289189 14,3.11111111 L14,10.8888889 C14,12.6071081 12.6071081,14 10.8888889,14 L3.11111111,14 C1.39289189,14 0,12.6071081 0,10.8888889 L0,3.11111111 C0,1.39289189 1.39289189,0 3.11111111,0 Z"
            id="key0"
          />
        </g>
      </g>
    </g>
  </svg>
);

const ManualInput = () => {
  const history = useHistory();

  const goToForm = () => {
    history.push(`/manual-entries`);
  };

  return (
    <CesuBtn onClick={goToForm}>
      {icon}
      <Body16 strong>{polyglot.t("cesu.new.enter_cesu")}</Body16>
    </CesuBtn>
  );
};

export default ManualInput;
