import React, { useState } from "react";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  Button,
  Col,
  Icon,
  List,
  Modal,
  Row,
  Skeleton,
} from "../../../../components";
import { BUTTON, LIST } from "../../../../components/Styles/variants";
import {
  useDeleteAccountCreditCard,
  useGetAccountCreditCard,
} from "../../../../modules/routes/dashboard-routes";
import { formatCardNumber } from "../../../../utils";
import { getBadge } from "../../../../utils/payment";
import polyglot from "../../../../utils/polyglot";

const ConfirmDialog = ({ isOpen, isLoading, onSubmit, onClose }) => (
  <Modal.XSmall isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>
        {polyglot.t("dashboard.remove_payment_method")}
      </Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <Body16>
        {polyglot.t("dashboard.remove_payment_method_confirmation")}
      </Body16>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer>
      <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={onClose}>
        {polyglot.t("common.cancel")}
      </Button.Medium>
      <Button.Medium
        type="submit"
        onClick={onSubmit}
        isLoading={isLoading}
        accentColor={BUTTON.ACCENT_COLOR.DANGER}
      >
        {polyglot.t("common.remove")}
      </Button.Medium>
    </Modal.Item.Footer>
  </Modal.XSmall>
);

const ManagePaymentMethod = ({ has_credit_card }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, isLoading } = useGetAccountCreditCard({
    enabled: has_credit_card,
  });

  const deleteCreditCard = useDeleteAccountCreditCard({
    closeModal: () => setModalIsOpen(false),
  });

  const handleDelete = () => {
    deleteCreditCard.mutate();
  };

  return (
    <div
      css={`
        margin-top: ${spacings.s};
      `}
    >
      <List.Header>{polyglot.t("wallet.payment_method")}</List.Header>
      {has_credit_card ? (
        <Row
          css={`
            margin-top: ${spacings.s};
          `}
        >
          <Col size={{ xs: 12, xl: 6 }}>
            <List.Item
              divider={false}
              withGutters
              shape={LIST.SHAPE.ROUND}
              RightComponent={() => (
                <Button.Link
                  disabled={isLoading}
                  accentColor={BUTTON.ACCENT_COLOR.DANGER}
                  onClick={() => setModalIsOpen(true)}
                  LeftComponent={() => <Icon.Medium name="trash" />}
                >
                  {polyglot.t("common.remove")}
                </Button.Link>
              )}
              LeftComponent={() =>
                !isLoading && data ? (
                  <img
                    src={getBadge(data.card_type)}
                    alt={polyglot.t("wallet.payment_method")}
                    width={30}
                    height={20}
                  />
                ) : (
                  <Skeleton.Group>
                    <Skeleton
                      width={30}
                      height={20}
                      css={`
                        border-radius: ${radius.s};
                      `}
                    />
                  </Skeleton.Group>
                )
              }
              css={`
                background: ${colors.background};
                border: solid ${borderWidth.s} ${colors.border};
              `}
            >
              {!isLoading && data ? (
                <List.Elem.Title strong>
                  {formatCardNumber(data?.card_number)}
                </List.Elem.Title>
              ) : (
                <Skeleton.Group>
                  <Block display="flex">
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.s}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                    <Block marginLeft={spacings.xxs}>
                      <Skeleton width={sizes.size8} height={sizes.size8} />
                    </Block>
                  </Block>
                </Skeleton.Group>
              )}
            </List.Item>
          </Col>
          <ConfirmDialog
            isOpen={modalIsOpen}
            isLoading={deleteCreditCard.isLoading}
            onClose={() => setModalIsOpen(false)}
            onSubmit={handleDelete}
          />
        </Row>
      ) : (
        <Body16 color={colors.muted}>
          {polyglot.t("wallet.no_payment_method")}
        </Body16>
      )}
    </div>
  );
};

export default ManagePaymentMethod;
