import { useSelector } from "react-redux";
import { BRAND_NAME } from "../../utils";

export default function useDocumentTitle() {
  const badges = useSelector((state) => state.badges);
  const rawTitle = document.title.replaceAll(/ *\([0-9)]*\) */g, "");

  const update = () => {
    if (badges.messages_count > 0) {
      document.title = `(${badges.messages_count}) ${rawTitle}`;
    } else {
      document.title = rawTitle;
    }
  };

  const set = (title) => {
    if (badges.messages_count > 0) {
      document.title = `(${badges.messages_count}) ${title} - ${BRAND_NAME}`;
    } else {
      document.title = `${title} - ${BRAND_NAME}`;
    }
  };

  return {
    update,
    set,
  };
}
