const config = { isCustom: true };

export function useCategory1037() {
  const initialValues = {};

  const getNbHours = () => 2;

  const nhConfig = {};

  return {
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
