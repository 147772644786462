import React from "react";
import { ThemeProvider } from "styled-components";
import ContactFilePicker from "../pages/ContactFilePicker";
import * as themes from "../assets/themes";
import ErrorBoundary from "../utils/ErrorBoundary";

const ContactFilePickerApp = (props, rails) => () =>
  (
    <ThemeProvider theme={themes}>
      <ErrorBoundary>
        <ContactFilePicker {...props} rails={rails} />
      </ErrorBoundary>
    </ThemeProvider>
  );

export default ContactFilePickerApp;
