import React from "react";
import { useHistory } from "react-router-dom";
import polyglot from "@/utils/polyglot";

const SwitchAuth = ({ isJobber, forceNavigation }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const handleSwitch = (type) => {
    if (forceNavigation) {
      if (history.location?.state?.from) {
        history.goBack();
      } else {
        history.push(type, {
          from: pathname,
        });
      }
    } else if (type === "/signup" && isJobber == undefined) {
      window.location = "/inscription";
    } else {
      history.push(type);
    }
  };
  return (
    <div className="mt-3 mb-0 text-muted font-size-2 d-flex justify-content-center">
      {pathname === "/signup" ? (
        <span css="display: flex; align-items:baseline; flex-wrap: wrap; justify-content:center;">
          {polyglot.t("login.already_member_1")}&nbsp;
          <button
            type="button"
            className="btn btn-link p-0 m-0 font-size-2 font-weight-normal"
            onClick={() => {
              handleSwitch("/login");
            }}
          >
            {polyglot.t("login.already_member_2")}
          </button>
        </span>
      ) : (
        <span css="display: flex; align-items:baseline; flex-wrap: wrap; justify-content:center;">
          {polyglot.t("login.not_already_member_1")}&nbsp;
          <button
            type="button"
            className="btn btn-link p-0 m-0 font-size-2 font-weight-normal"
            onClick={() => {
              handleSwitch("/signup");
            }}
          >
            {polyglot.t("login.not_already_member_2")}
          </button>
        </span>
      )}
    </div>
  );
};

export default SwitchAuth;
