import React from "react";
import styled from "styled-components";
import { Body14, H1, H5 } from "../Text";
import Block from "../Block";
import Icon from "../Icon";
import { colors, radius, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Tag from "../Tag";
import { TAG } from "../Styles/variants";

const StyledWrapper = styled.div`
  border-radius: ${radius.l};
  background-color: ${colors.primary};
  padding: ${spacings.ml};
  color: ${colors.onColor};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

const WalletCard = ({
  name,
  amount,
  renderAmount,
  description,
  FooterComponent = null,
  icon = "credit-card",
  className,
}) => (
  <StyledWrapper className={className}>
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      marginBottom={spacings.l}
    >
      <Block marginRight={spacings.s}>
        <H5 color={colors.onColor}>{name}</H5>
      </Block>
      <Icon.XLarge name={icon} />
    </Block>
    <Block>
      <Block display="flex" alignItems="baseline" flexWrap="wrap">
        <H1
          css={`
            margin-right: ${spacings.s};
            width: 100%;
            display: block;
          `}
          color={colors.onColor}
        >
          {renderAmount ? renderAmount(amount) : amount}
        </H1>
      </Block>
      <Block marginBottom={spacings.s}>
        <Body14>{description}</Body14>
      </Block>
    </Block>
    {FooterComponent && <Block>{FooterComponent()}</Block>}
  </StyledWrapper>
);

export default WalletCard;
