import React from "react";
import BaseApp from "../modules/BaseApp";
import UserPreview from "../pages-admin/UserPreview";

const UserPreviewApp = (props, rails) => () =>
  (
    <BaseApp>
      <UserPreview {...props} />
    </BaseApp>
  );

export default UserPreviewApp;
