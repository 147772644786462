import { colors } from "@/assets/themes";
import { Body16, Button, ScrollableDatePicker } from "@/components";
import { BUTTON } from "@/components/Styles/variants";
import { useAskService } from "@/modules/hooks";
import { getHoursOptions, getMinDate } from "@/utils/job-date-time";
import { CATEGORY_DATE_FORMAT } from "../categoryForms/constants";

const JobberProfileCalendar = ({ availabilities, subcategories, user }) => {
  const { show } = useAskService();

  const getOptions = (date) =>
    getHoursOptions(date, CATEGORY_DATE_FORMAT, false, {
      availabilities,
    });

  const handleChange = ({ date, start_hour, change }) => {
    const params = new URLSearchParams();
    if (date) params.append("date", date);
    if (start_hour) params.append("start_hour", start_hour);
    if (change === "start_hour") {
      show({ urlParams: params.toString(), user, subcategories });
    }
  };

  return (
    <div>
      <ScrollableDatePicker
        format={CATEGORY_DATE_FORMAT}
        minDate={getMinDate()}
        onChange={handleChange}
        slots={getOptions}
        availabilities={availabilities}
        SlotComponent={({ children, ...props }) => (
          <Button.Small {...props} kind={BUTTON.KIND.SECONDARY}>
            <Body16 color={colors.body}>{children}</Body16>
          </Button.Small>
        )}
      />
    </div>
  );
};

export default JobberProfileCalendar;
