import React from "react";
import styled, { css } from "styled-components";
import { Body16 } from "../Text";
import { borderWidth, colors, sizes, spacings } from "../../assets/themes";
import Shape from "../Shape";
import Icon from "../Icon";
import { SHAPE, STEPS } from "../Styles/variants";

const setLineDirectionStyle = (orientation, isFirst, isLast) => {
  if (orientation === STEPS.ORIENTATION.HORIZONTAL) {
    return css`
      width: 100%;
    `;
  }
  if (orientation === STEPS.ORIENTATION.VERTICAL) {
    return css`
      height: ${isFirst ? 0 : "100%"};
    `;
  }
  return null;
};

const setDirectionStyle = (orientation) => {
  if (orientation === STEPS.ORIENTATION.HORIZONTAL) {
    return css`
      width: 100%;
      margin-top: ${spacings.s};
      margin-bottom: ${spacings.s};
      flex-direction: row;
    `;
  }
  if (orientation === STEPS.ORIENTATION.VERTICAL) {
    return css`
      margin-right: ${spacings.m};
      flex-direction: column;
    `;
  }
  return null;
};

const setLineColor = ({ enabled, renderLineColor }) => {
  if (renderLineColor) return renderLineColor;
  return enabled ? colors.primary : colors.border;
};

const StyledStepLine = styled.div`
  ${({ orientation, enabled, isFirst, isLast, dashed, renderLineColor }) => css`
    border: ${dashed ? "dashed" : "solid"} ${borderWidth.s}
      ${setLineColor({ enabled, renderLineColor })};
    ${setLineDirectionStyle(orientation, isFirst, isLast)}
  `}
`;

const StyledStepWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledWrapper = styled.div`
  ${({ orientation }) => css`
    display: flex;
    align-items: center;
    min-width: ${sizes.size36};
    justify-content: center;
    ${setDirectionStyle(orientation)}
  `}
`;

const Artwork = ({
  orientation,
  active,
  done,
  children,
  isLast,
  isFirst,
  dashed,
  render,
  renderLineColor,
  index,
  ...rest
}) => {
  const enabled = done || active;
  return (
    <StyledWrapper orientation={orientation} {...rest}>
      <StyledStepWrapper isFirst={isFirst}>
        {!isFirst && (
          <StyledStepLine
            enabled={enabled}
            orientation={orientation}
            renderLineColor={renderLineColor}
            dashed={dashed}
          />
        )}
      </StyledStepWrapper>

      {render ? (
        render()
      ) : (
        <Shape.Small
          shape={SHAPE.SHAPE.CIRCLE}
          backgroundColor={enabled ? colors.primary : colors.background}
          css={`
            border: solid ${borderWidth.m};
            border-color: ${enabled ? colors.transparent : colors.border};
          `}
          color={enabled ? colors.onColor : colors.mutedLight}
        >
          {done ? (
            <Icon.Medium name="check" />
          ) : (
            <Body16 strong>{index}</Body16>
          )}
        </Shape.Small>
      )}

      <StyledStepWrapper>
        {!isLast && (
          <StyledStepLine
            enabled={enabled}
            orientation={orientation}
            renderLineColor={renderLineColor}
            dashed={dashed}
          />
        )}
      </StyledStepWrapper>
    </StyledWrapper>
  );
};

export default Artwork;
