import { useEffect } from "react";

export default function useListenToTargetClick(
  selector,
  handleClick,
  event = "click"
) {
  const elements = (selector && document.querySelectorAll(selector)) || null;

  useEffect(() => {
    if (elements)
      Array.from(elements).forEach((elem) =>
        elem.addEventListener(event, handleClick)
      );

    return () => {
      if (elements)
        Array.from(elements).forEach((elem) =>
          elem.removeEventListener(event, handleClick)
        );
    };
  }, [elements?.length]);
}
