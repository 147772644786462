import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const config = { withFrequency: true };

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

export function useCategory4008() {
  const initialValues = {
    nh_specific: "",
  };

  const getNbHours = (values) => values.nh_specific;

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.car_type"),
      options: [
        { value: 1.5, label: polyglot.t("categories.city_car") },
        { value: 2.0, label: polyglot.t("categories.sedan") },
        { value: 2.5, label: polyglot.t("categories.suv") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl label={nhConfig.nh_specific.label}>
            <FormSelectables
              isWrap
              name="nh_specific"
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    config,
  };
}
