import React, { useState, useEffect } from "react";
import { ImageGalleryModal } from "../components/ImageGallery";
import { useListenToTargetClick } from "../modules/hooks";

const ImageGalleryApp = ({ selector }) => {
  const queryLinksSelector = `${selector} a[data-image-gallery-item]`;
  const [images, setImages] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(null);
  const [linkNodes] = useState(
    (selector && document.querySelectorAll(queryLinksSelector)) || []
  );

  const handleImageClick = (e) => {
    const index = Array.from(linkNodes).findIndex((link) =>
      link.isEqualNode(e.currentTarget)
    );
    e.preventDefault();
    setInitialIndex(index);
    setModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setInitialIndex(null);
    setModalIsOpen(false);
  };

  useEffect(() => {
    // eslint-disable-next-line no-return-assign, no-param-reassign
    linkNodes.forEach((node) => (node.style.cursor = "zoom-in"));
    setImages(
      Array.from(linkNodes).map((link) => {
        const image = link.getElementsByTagName("img")[0];
        return { href: link.href, alt: image.alt };
      })
    );
  }, []);

  useListenToTargetClick(queryLinksSelector, handleImageClick);

  return (
    <>
      {modalIsOpen && (
        <ImageGalleryModal
          isOpen={modalIsOpen}
          initialIndex={initialIndex}
          onClose={handleCloseModal}
        >
          {images.map((img, index) => (
            <img
              srcSet={img.href}
              alt={img.alt}
              key={`image-gallery-item-${index}`}
            />
          ))}
        </ImageGalleryModal>
      )}
    </>
  );
};

export default ImageGalleryApp;
