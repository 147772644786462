import polyglot from "../../../utils/polyglot";
import { NB_HOURS_PATH } from "../constants";

export function useCategory1064() {
  const initialValues = { nb_hours: 1 };

  return {
    version: 1.1,
    initialValues,
    config: {
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
        newPages[nbHoursIndex].title = polyglot.t(
          "categories.lock_opening_recommendation",
          { nb_hours: polyglot.toDuration(initialValues.nb_hours, true) }
        );

        return newPages;
      },
    },
  };
}
