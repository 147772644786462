import styled, { css } from "styled-components";
import { radius, spacings } from "../../assets/themes";
import { breakpointsEnhancer } from "../Styles/Helper";
import { SKELETON } from "../Styles/variants";

const setShape = ({ shape, width, height }) => {
  if (shape === SKELETON.SHAPE.SQUARE) {
    return css`
      border-radius: 0px;
    `;
  }
  if (shape === SKELETON.SHAPE.CIRCLE) {
    return css`
      border-radius: ${radius.circle};
      ${breakpointsEnhancer("min-width", width)};
      ${breakpointsEnhancer("min-height", height)};
    `;
  }
  if (shape === SKELETON.SHAPE.ROUND) {
    return css`
      border-radius: ${radius.circle};
    `;
  }
  return css`
    border-radius: ${radius.ml};
  `;
};

const setSpace = ({ sb, st, sx, sr, sl, sy }, space = spacings.m) => {
  let spacing = "";
  if (sb) {
    spacing += `
      margin-bottom: ${space};
    `;
  }
  if (st) {
    spacing += `
      margin-top: ${space};
    `;
  }
  if (sx) {
    spacing += `
      margin-left: ${space};
      margin-right: ${space};
    `;
  }
  if (sr) {
    spacing += `
      margin-right: ${space};
    `;
  }
  if (sl) {
    spacing += `
      margin-left: ${space};
    `;
  }
  if (sy) {
    spacing += `
      margin-top: ${space};
      margin-bottom: ${space};
    `;
  }
  return css([spacing]);
};

const StyledWrapper = styled.div`
  ${({ width, ...spacings }) => css`
    ${breakpointsEnhancer("width", width)};
    ${setSpace(spacings, spacings.s)}
  `}
`;

const StyledSkeleton = styled.div`
  ${({ width, height, shape = SKELETON.SHAPE.DEFAULT, sb }) => css`
    ${setShape({ shape, width, height })}
    ${setSpace({ sb })}
    width: 100%;
    ${breakpointsEnhancer("height", height)};
  `}
`;

const Skeleton = ({ rows = 1, sb, st, sx, sr, sl, sy, ...props }) => (
  <StyledWrapper
    width={props.width}
    sb={sb || rows > 1}
    st={st}
    sx={sx}
    sr={sr}
    sl={sl}
    sy={sy}
  >
    {new Array(rows).fill("").map((_, index) => (
      <StyledSkeleton
        {...props}
        sb={rows > 1 && index < rows - 1}
        key={`skeleton-row-${index}`}
      />
    ))}
  </StyledWrapper>
);

export default styled(Skeleton)``;
