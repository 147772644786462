import React from "react";
import { colors, sizes } from "../../../../assets/themes";
import {
  Avatar,
  Body14,
  Body18,
  List,
  Rating,
  StatusEnhancer,
  Block,
  Link,
  Divider,
  ConditionnalWrapper,
} from "../../../../components";
import UserPreview from "../../../../pages-admin/UserPreview";
import { JOBBER } from "../../../../pages-admin/UserPreview/config";
import polyglot from "../../../../utils/polyglot";

const RelationContent = ({ jobber }) => (
  <div>
    <Block display={{ xs: "none", xl: "block" }}>
      <Body18>{polyglot.t("yoojo_direct.my_jobber")}</Body18>
    </Block>
    <ConditionnalWrapper
      condition={jobber.id}
      wrapper={(children) => (
        <UserPreview id={jobber.id} kind={JOBBER}>
          {children}
        </UserPreview>
      )}
    >
      <List.Item
        divider={false}
        LeftComponent={() => (
          <div as={jobber.rate && Link} href={jobber.url} css="">
            <Avatar
              src={jobber.avatar}
              size={sizes.size48}
              name={jobber.first_name}
            />
          </div>
        )}
      >
        <StatusEnhancer isPro={jobber.is_pro}>
          <div
            as={jobber.rate && Link}
            href={jobber.url}
            css=""
            color={colors.body}
          >
            <Body18 strong numberOfLines={1}>
              {jobber.first_name}
            </Body18>
          </div>
        </StatusEnhancer>
        {jobber.rate ? (
          <Rating
            type="compact"
            rate={jobber.rate}
            count={jobber.rates_count}
          />
        ) : (
          <Body14 color={colors.muted}>
            {polyglot.t("yoojo_direct.jobber_not_joined")}
          </Body14>
        )}
      </List.Item>
    </ConditionnalWrapper>
  </div>
);

export default RelationContent;
