import React from "react";
import polyglot from "../../utils/polyglot";

const SignUpTerms = ({ isJobber }) => {
  const html = polyglot.t("signup.accept_conditions.text", {
    general_condition: `<a href='/${
      isJobber ? "jobber-terms" : "customer-terms"
    }' target='_blank'>${polyglot.t(
      "signup.accept_conditions.general_conditions"
    )}</a>`,
    privacy_policy: `<a href='/data-policy' target='_blank'>${polyglot.t(
      "signup.accept_conditions.privacy_policy"
    )}</a>`,
  });
  return (
    <p
      className="mt-3 mb-0 text-muted font-size-2"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default SignUpTerms;
