import { Modal } from "../../components";
import Inventory from "./Inventory";

const InventoryModal = ({ isOpen, onClose, items, onChange, onClear }) => (
  <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
    <Modal.Item.Header onClose={onClose} />
    <Inventory items={items} onChange={onChange} onClear={onClear} />
  </Modal.Small>
);
export default InventoryModal;
