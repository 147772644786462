import React, { useState } from "react";
import { Cropper } from "../components";

const ImageCropperInput = ({ cropX, cropY, cropW, cropH, rotate, ...rest }) => {
  const [value, setValue] = useState({});

  const handleChange = (v) => {
    setValue(v.detail);
  };

  return (
    <>
      <Cropper value={value} onChange={handleChange} {...rest} />
      <input name={cropX} defaultValue={value.x} hidden />
      <input name={cropY} defaultValue={value.y} hidden />
      <input name={cropW} defaultValue={value.width} hidden />
      <input name={cropH} defaultValue={value.height} hidden />
      <input name={rotate} defaultValue={value.rotate} hidden />
    </>
  );
};

export default ImageCropperInput;
