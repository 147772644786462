import React from "react";
import { borderWidth, colors, spacings } from "@/assets/themes";
import { Block, Body14, Body16, Icon, List, Shape } from "@/components";
import polyglot from "@/utils/polyglot";
import { formatArrayToComma } from "./utils";
import { LIST, SHAPE } from "@/components/Styles/variants";
import JobberInfoItem from "./JobberInfoItem";

// const i = [
//   "languages": [] polyglot.t(`skills.languages.${lang}`),
//   "certificates":[] polyglot.t(`offer_certificates.${cs}`),
//   "diploma":string,
//   "ageOfChild": polyglot.t(`babysitting.child_${age}`) ["toddlers","preschoolers","school_age_children","preteens"],
//   "placeOfSitting": [polyglot.t("skills.at_caregiver_home"), polyglot.t("skills.at_parents_home")] ["at_caregiver_home", "at_parents_home"],
//   "is_smoker":boolean = polyglot.t("common.non_smoker"), // show only if true
//   "has_driving_license": boolean, polyglot.t("common.driving_license"), // show only if true
//   "professor_status": polyglot.t(`skills.professor_status.${status}`),
//   "remote", polyglot.t("skills.remote"), // show only if true
//   "experience": string,
//   "first_aid_certificate": polyglot.t("skills.first_aid_diploma"),
//   // "baby_sitter_kind",
// ];

const JobberSkillInformations = ({ data }) => {
  const getTrues = (arr) => arr.filter((a) => !!a.value);

  const ageOfchilds = [
    { key: "toddlers", value: data.toddlers },
    { key: "preschoolers", value: data.preschoolers },
    { key: "school_age_children", value: data.school_age_children },
    { key: "preteens", value: data.preteens },
  ];
  const placeOfSitting = [
    { key: "at_caregiver_home", value: data.at_caregiver_home },
    { key: "at_parents_home", value: data.at_parents_home },
  ];

  return (
    <div>
      <Block marginBottom={spacings.sm}>
        {data.jobber.is_top_jobber && (
          <List.Item
            shape={LIST.SHAPE.ROUND}
            withGutters
            divider={false}
            css={`
              border: solid ${borderWidth.s} ${colors.border};
            `}
            LeftComponent={() => (
              <Shape.Small
                backgroundColor={colors.purpleLight}
                color={colors.purple}
                shape={SHAPE.SHAPE.CIRCLE}
              >
                <Icon.Large name="love-solid" />
              </Shape.Small>
            )}
          >
            <Body16 strong color={colors.purple}>
              {polyglot.t("common.top_jobber")}
            </Body16>
            <Body14 color={colors.muted}>
              {polyglot.t("common.top_jobber_description")}
            </Body14>
          </List.Item>
        )}
      </Block>
      {data.languages && (
        <JobberInfoItem icon="globe">
          {polyglot.t("skills.spoken_languages", {
            languages: formatArrayToComma(
              data.languages.map((lang) => {
                const suffix = lang.native
                  ? ` (${polyglot.t("common.native_language").toLowerCase()})`
                  : "";
                return polyglot.t(`skills.languages.${lang.language}`) + suffix;
              }),
              "lowerCase"
            ),
          })}
        </JobberInfoItem>
      )}
      {data.certificates?.length > 0 && (
        <JobberInfoItem icon="diploma">
          {data.certificates.map((certificate) => (
            <span
              css={`
                display: block;
              `}
            >
              {polyglot.t(`offer_certificates.${certificate}`)}
            </span>
          ))}
        </JobberInfoItem>
      )}
      {data.diploma && (
        <JobberInfoItem icon="diploma">{data.diploma}</JobberInfoItem>
      )}
      {data.experience && (
        <JobberInfoItem icon="medal">
          {polyglot.t(`skills.experiences.${data.experience}`)}
        </JobberInfoItem>
      )}
      {getTrues(ageOfchilds).length > 0 && (
        <JobberInfoItem icon="user">
          {polyglot.t("babysitting.child_age_care_from", {
            value: formatArrayToComma(
              getTrues(ageOfchilds).map((child) =>
                polyglot.t(`babysitting.child_${child.key}`)
              ),
              "lowerCase"
            ),
          })}
        </JobberInfoItem>
      )}
      {getTrues(placeOfSitting).length > 0 && (
        <JobberInfoItem icon="home">
          {formatArrayToComma(
            getTrues(placeOfSitting).map((place) =>
              polyglot.t(`skills.${place.key}`)
            )
          )}
        </JobberInfoItem>
      )}
      {(data.is_smoker === false || data.has_driving_license) && (
        <JobberInfoItem icon="info-circle">
          {formatArrayToComma([
            ...(!data.is_smoker ? polyglot.t("common.non_smoker") : []),
            ...(data.has_driving_license
              ? polyglot.t("common.driving_license")
              : []),
          ])}
        </JobberInfoItem>
      )}
      {data.professor_status && (
        <JobberInfoItem icon="work">
          {polyglot.t(`skills.professor_status.${data.professor_status}`)}
        </JobberInfoItem>
      )}
      {data.remote && (
        <JobberInfoItem icon="video">
          {polyglot.t(`skills.remote`)}
        </JobberInfoItem>
      )}

      {data.first_aid_certificate && (
        <JobberInfoItem icon="medical-kit">
          {polyglot.t("skills.first_aid_diploma")}
        </JobberInfoItem>
      )}
    </div>
  );
};

export default JobberSkillInformations;
