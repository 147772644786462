import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { colors, spacings } from "../../../assets/themes";
import {
  Alert,
  Block,
  Breadcrumb,
  Button,
  Col,
  DatePicker,
  FormControl,
  Icon,
  Input,
  Link,
  ListEditable,
  PhoneInput,
  Popover,
  Row,
  Selectable,
  Tag,
} from "../../../components";
import {
  ALERT,
  BUTTON,
  POPOVER,
  TAG,
} from "../../../components/Styles/variants";
import PasswordChecker from "../../../components/password/PasswordChecker";
import {
  useGetAccountInformations,
  usePutAccountInformations,
  usePutEmailVerification,
} from "../../../modules/routes/dashboard-routes";
import {
  DASHBOARD_SMALL_CONTAINER,
  USER_SEX,
  firstNameValidation,
  formatDate,
  formatPhone,
  getDateOfBirthActiveMonth,
  getDateOfBirthMaxDate,
  lastNameValidation,
  phoneRegex,
  setAddressInitialValues,
} from "../../../utils";
import polyglot from "../../../utils/polyglot";
import AddressModal from "../../common/AddressModal";
import PageTitle from "../PageTitle";
import { ACCOUNT_PATH, DELETE_ACCOUNT_PATH } from "../config/routes";
import EditAccountSkeleton from "../skeletons/EditAccountSkeleton";
import EmailVerificationModal from "./EmailVerificationModal";

const EditAccount = ({ config }) => {
  const { data, isLoading } = useGetAccountInformations();
  const [fieldOnEdit, setFieldOnEdit] = useState(null);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [emailModal, setEmailModal] = useState({ isOpen: false, values: null });
  const sendEmailVerification = usePutEmailVerification();
  const updateAccount = usePutAccountInformations();

  useEffect(() => {
    if (updateAccount.isSuccess) setFieldOnEdit(null);
  }, [updateAccount.isSuccess]);

  const handleSubmit = (name, values) => {
    if (name === "email" && data.user.email_confirmed && !values.secure_code) {
      setEmailModal({ isOpen: true, values });
    } else {
      updateAccount.mutate({ name, values });
    }
  };

  const SET_FIELDS = () => [
    {
      title: polyglot.t("common.first_name"),
      name: "first_name",
      disabled: !data.user.can_edit_identity_informations,
      validationSchema: yup.object().shape({
        ...firstNameValidation,
      }),
      Component: () => (
        <Field name="first_name">
          {({ field, form }) => (
            <FormControl
              label={polyglot.t("common.first_name")}
              error={form.errors.first_name}
            >
              <Input {...field} error={form.errors.first_name} />
            </FormControl>
          )}
        </Field>
      ),
    },
    {
      title: polyglot.t("common.last_name"),
      name: "last_name",
      disabled: !data.user.can_edit_identity_informations,
      validationSchema: yup.object().shape({
        ...lastNameValidation,
      }),
      Component: () => (
        <Field name="last_name">
          {({ field, form }) => (
            <FormControl
              label={polyglot.t("common.last_name")}
              error={form.errors.first_name}
            >
              <Input {...field} error={form.errors.first_name} />
            </FormControl>
          )}
        </Field>
      ),
    },
    {
      title: polyglot.t("common.email"),
      name: "email",
      content: () =>
        !data.user.email_confirmed && (
          <Block marginBottom={spacings.m}>
            {!sendEmailVerification.isSuccess && (
              <Button.Medium
                block
                kind={BUTTON.KIND.SECONDARY}
                isLoading={sendEmailVerification.isLoading}
                LeftComponent={() => <Icon.Large name="envelope" />}
                onClick={() => {
                  sendEmailVerification.mutate();
                }}
              >
                {polyglot.t("personal_informations.confirm_email")}
              </Button.Medium>
            )}
            {sendEmailVerification.isSuccess && (
              <Alert.Low kind={ALERT.KIND.SUCCESS}>
                {polyglot.t("personal_informations.confirm_email_success")}
              </Alert.Low>
            )}
          </Block>
        ),
      RightComponent: () =>
        !data.user.email_confirmed && (
          <Tag.Medium kind={TAG.KIND.WARNING}>
            {polyglot.t("common.unverified")}
          </Tag.Medium>
        ),

      Component: () => (
        <>
          <Field name="email">
            {({ field }) => (
              <FormControl label={polyglot.t("common.email")}>
                <Input {...field} onBlur={(e) => e.preventDefault()} />
              </FormControl>
            )}
          </Field>
        </>
      ),
    },
    {
      title: polyglot.t("common.password"),
      name: "password",
      value: polyglot.t("common.placeholder.password"),
      initialValues: { current_password: "", password: "" },
      validationSchema: yup.object().shape({
        current_password: yup.string().required(),
        password: yup.string().min(8).required(),
      }),
      Component: () => (
        <>
          <Field name="current_password">
            {({ field }) => (
              <FormControl
                label={polyglot.t("personal_informations.current_password")}
              >
                <Input
                  {...field}
                  placeholder={polyglot.t("common.placeholder.password")}
                  type="password"
                  onBlur={(e) => e.preventDefault()}
                />
              </FormControl>
            )}
          </Field>
          <Field name="password">
            {({ field }) => (
              <FormControl
                label={polyglot.t("personal_informations.new_password")}
              >
                <Input
                  {...field}
                  placeholder={polyglot.t("common.placeholder.password")}
                  type="password"
                  onBlur={(e) => e.preventDefault()}
                />
                <Block marginTop={spacings.m}>
                  <PasswordChecker value={field.value} />
                </Block>
              </FormControl>
            )}
          </Field>
        </>
      ),
    },
    {
      title: polyglot.t("common.sex"),
      name: "sex",
      disabled: !data.user.can_edit_identity_informations,
      value: USER_SEX[data.user.sex],
      initialValues: { sex: data.user.sex },
      Component: () => (
        <FormControl label={polyglot.t("common.sex")}>
          <Row gutter={spacings.s}>
            <Col size={6}>
              <Field type="radio" name="sex" value={1}>
                {({ form }) => (
                  <Selectable.Medium
                    css="width: 100%"
                    width="100%"
                    checked={form.values.sex === 1}
                    onChange={() => form.setFieldValue("sex", 1)}
                  >
                    {USER_SEX[1]}
                  </Selectable.Medium>
                )}
              </Field>
            </Col>
            <Col size={6}>
              <Field type="radio" name="sex" value={2}>
                {({ form }) => (
                  <Selectable.Medium
                    css="width: 100%"
                    checked={form.values.sex === 2}
                    onChange={() => form.setFieldValue("sex", 2)}
                  >
                    {USER_SEX[2]}
                  </Selectable.Medium>
                )}
              </Field>
            </Col>
          </Row>
        </FormControl>
      ),
    },
    {
      title: polyglot.t("common.date_of_birth"),
      name: "date_of_birth",
      validationSchema: yup.object().shape({
        date_of_birth: yup.date().required(),
      }),
      disabled: !data.user.can_edit_identity_informations,
      value:
        data.user.date_of_birth &&
        formatDate(data.user.date_of_birth, "DD MMMM YYYY", "YYYY-MM-DD"),
      Component: () => (
        <Field name="date_of_birth">
          {({ field, form }) => (
            <FormControl label={polyglot.t("common.date_of_birth")}>
              <DatePicker
                {...field}
                onChange={(date) => form.setFieldValue(field.name, date)}
                activeMonth={getDateOfBirthActiveMonth()}
                maxDate={getDateOfBirthMaxDate()}
              />
            </FormControl>
          )}
        </Field>
      ),
    },
    {
      title: polyglot.t("common.phone_number"),
      name: "phone",
      value: data.user.phone && formatPhone(data.user.phone),
      initialValues: { phone: data.user.phone },
      validationSchema: yup.object().shape({
        phone: yup.string().min(10).matches(phoneRegex).required(),
      }),
      Component: () => (
        <Field name="phone">
          {({ field, form }) => (
            <FormControl label={polyglot.t("common.phone_number")}>
              <PhoneInput
                {...field}
                onChange={(v) => form.setFieldValue("phone", v)}
                onBlur={(e) => e.preventDefault()}
              />
            </FormControl>
          )}
        </Field>
      ),
    },
    {
      title: polyglot.t("common.billing_address"),
      name: "address",
      value: data.user.address,
      initialValues: {
        ...setAddressInitialValues({ address: data.user.address || "" }),
      },
      Component: () => (
        <Field name="address">
          {({ field }) => (
            <FormControl label={polyglot.t("common.billing_address")}>
              <Input
                onClick={() => setAddressModalIsOpen(true)}
                value={field.value}
                placeholder={polyglot.t("common.placeholder.address")}
                RightComponent={() => <Icon.Medium name="dropdown" />}
              />
              <AddressModal
                isOpen={addressModalIsOpen}
                onClose={() => setAddressModalIsOpen(false)}
              />
            </FormControl>
          )}
        </Field>
      ),
    },
  ];

  const handleSecureCodeSubmit = (name, values) => {
    updateAccount.mutate({ name, values });
    setEmailModal({ isOpen: false });
  };

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <PageTitle>{config.title}</PageTitle>
      {!isLoading ? (
        <>
          {SET_FIELDS(data.user).map((field) => (
            <Formik
              key={`account-field${field.name}`}
              onSubmit={(v, bag) => handleSubmit(field.name, v, bag)}
              validationSchema={field.validationSchema}
              enableReinitialize
              initialValues={
                field.initialValues || {
                  [field.name]: data.user[field.name],
                }
              }
            >
              {({ handleReset, isValid, dirty, submitForm }) => (
                <Form>
                  <ListEditable
                    onSubmit={submitForm}
                    title={field.title}
                    value={field.value || data.user[field.name]}
                    content={field.content}
                    submitDisabled={!isValid || !dirty}
                    isLoading={updateAccount.isLoading}
                    disabled={field.disabled}
                    onExpend={() => setFieldOnEdit(field.name)}
                    onCollapse={() => {
                      setFieldOnEdit(null);
                      handleReset();
                    }}
                    isExpended={fieldOnEdit === field.name}
                    RightComponent={() =>
                      (field.disabled || field.RightComponent) && (
                        <>
                          {field.disabled && (
                            <Popover.Enhancer
                              trigger={POPOVER.TRIGGER_TYPE.HOVER}
                              content={() => (
                                <Popover.Elem.Tooltip>
                                  {polyglot.t(
                                    "personal_informations.contact_us_to_edit"
                                  )}
                                </Popover.Elem.Tooltip>
                              )}
                            >
                              <Button.Medium
                                kind={BUTTON.KIND.MINIMAL}
                                shape={BUTTON.SHAPE.CIRCLE}
                              >
                                <Icon.Medium name="lock" color={colors.muted} />
                              </Button.Medium>
                            </Popover.Enhancer>
                          )}
                          {field.RightComponent && field.RightComponent()}
                        </>
                      )
                    }
                  >
                    {field.Component && field.Component()}
                  </ListEditable>
                </Form>
              )}
            </Formik>
          ))}
          <Block marginTop={spacings.m}>
            <Link color={colors.danger} to={DELETE_ACCOUNT_PATH}>
              {polyglot.t("routes.delete_account")}
            </Link>
          </Block>
        </>
      ) : (
        <EditAccountSkeleton />
      )}
      {emailModal.isOpen && (
        <EmailVerificationModal
          isOpen={emailModal.isOpen}
          onSubmit={handleSecureCodeSubmit}
          email={emailModal.values?.email}
          onClose={() => {
            setEmailModal({ isOpen: false });
          }}
          user={data?.user}
        />
      )}
    </Block>
  );
};

export default EditAccount;
