import { forwardRef } from "react";
import { useHistory } from "react-router-dom";
import { Flow } from "../../components";
import { useCategoryForm, useLogin } from "../../modules/hooks";
import Navigation from "./Navigation";
import useMountRedirection from "./useMountRedirection";

const CategoryFormsFlow = forwardRef(
  ({ onChange, pages, isLoading, activeIndex }, ref) => {
    const history = useHistory();
    useMountRedirection();
    const { isLogged } = useLogin();
    const { checkEmail } = useCategoryForm();

    const getSubmitOnNext = (active) => {
      if (isLogged) {
        return active === pages.length - 1;
      }
      if (checkEmail.isSuccess) {
        return active === pages.length - 1;
      }
      return false;
    };

    return (
      <Flow.Router
        renderProgress={() => null}
        onChange={(step) => onChange(step, history)}
        ref={ref}
        routes={pages}
        initialStep={activeIndex}
      >
        {({ goNext, goTo, active }) => (
          <>
            <Navigation
              progress={(100 / (pages.length - 1)) * active}
              isLoading={isLoading}
              submitOnNext={getSubmitOnNext(active)}
              goNext={goNext}
              goTo={goTo}
              goBack={active > 0 ? () => goTo(active - 1) : null}
            />
          </>
        )}
      </Flow.Router>
    );
  }
);

export default CategoryFormsFlow;
