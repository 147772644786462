import { colors, spacings } from "@/assets/themes";
import { Block, Body16, H2, StatusEnhancer, Tag } from "@/components";
import polyglot from "@/utils/polyglot";

const UserTitle = ({
  first_name,
  role_translated,
  price_per_hour,
  is_pro,
  age,
}) => (
  <Block display="flex" alignItems="center" gap={spacings.s}>
    <Block flex={1}>
      <StatusEnhancer isPro={is_pro}>
        <H2 numberOfLines={3} color={colors.white}>
          {first_name}
        </H2>
      </StatusEnhancer>
      <Body16 strong color={colors.white}>
        {role_translated}
        {age
          ? `, ${polyglot.t("common.years_old_plural", { count: age })}`
          : ""}
      </Body16>
    </Block>
    {price_per_hour && (
      <Block>
        <Tag.Medium
          css={`
            background-color: ${colors.backgroundBlur};
            backdrop-filter: blur(${40}px);
            font-weight: bold;
            white-space: nowrap;
          `}
        >
          {polyglot.toSmartCurrency(price_per_hour, {
            noDecimal: true,
            pricePerHour: true,
          })}
        </Tag.Medium>
      </Block>
    )}
  </Block>
);

export default UserTitle;
