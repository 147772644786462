import { useState } from "react";
import styled from "styled-components";
import { Block, Icon, Select } from "../components";
import { spacings } from "../assets/themes";
import { useJobAvailableHours } from "../modules/hooks";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  div:first-child {
    flex: 1;
  }
  div:last-child {
    flex: 1;
  }
`;

const TimeRangeInput = ({
  name = ["", ""],
  minHour,
  maxHour,
  format = "YYYY-MM-DD",
  value = [],
  placeholder = [],
}) => {
  const { getOptions } = useJobAvailableHours({ format });

  const [fromValue, setFromValue] = useState(value[0] || "");
  const [toValue, setToValue] = useState(value[1] || "");

  const getOptHours = () =>
    getOptions({
      min: minHour,
      max: maxHour,
      ignoreCurrentHour: true,
    });

  const getOptHoursEnd = () =>
    getOptions({
      min: fromValue,
      max: maxHour,
      ignoreCurrentHour: true,
    });

  const handleFromChange = (option) => {
    setFromValue(option.value);
  };

  const handleToChange = (option) => {
    setToValue(option.value);
  };

  return (
    <>
      <StyledWrapper>
        <Select
          name={`${name[0]}_tmp`}
          placeholder={placeholder[0]}
          options={getOptHours()}
          onChange={handleFromChange}
          LeftComponent={() => <Icon.Medium name="clock" />}
          value={fromValue}
          css={`
            width: 100%;
          `}
        />
        <Block marginX={spacings.m}>
          <Icon.Large name="arrow-right" />
        </Block>
        <Select
          name={`${name[1]}_tmp`}
          placeholder={placeholder[1]}
          options={getOptHoursEnd()}
          LeftComponent={() => <Icon.Medium name="clock-solid" />}
          onChange={handleToChange}
          value={toValue}
          css={`
            width: 100%;
          `}
        />
      </StyledWrapper>
      <input name={name[0]} value={fromValue} hidden readOnly />
      <input name={name[1]} value={toValue} hidden readOnly />
    </>
  );
};

export default TimeRangeInput;
