import { colors, sizes } from "../../assets/themes";
import Block from "../Block";
import Icon from "../Icon";
import List from "../List";
import { LIST } from "../Styles/variants";
import { Body16 } from "../Text";

const SkillItem = ({ iconName, children, divider = true, strong = false }) => (
  <List.Item
    divider={divider}
    withGutters
    size={LIST.SIZE.COMPACT}
    LeftComponent={() => (
      <Block width={sizes.size24} display="flex" justifyContent="center">
        {typeof iconName === "function" ? (
          iconName()
        ) : (
          <Icon.Large name={iconName} color={colors.muted} />
        )}
      </Block>
    )}
  >
    <Body16 strong={strong} css="overflow-wrap: anywhere">
      {children}
    </Body16>
  </List.Item>
);

export default SkillItem;
