import { useFormikContext } from "formik";
import { DatePicker } from "../../../../components";
import { CALENDAR } from "../../../../components/Styles/variants";
import { useBreakpoints } from "../../../../modules/hooks";
import { CATEGORY_DATE_FORMAT } from "../../constants";

const FormDatePicker = ({
  range,
  name = "date",
  endName = "end_date",
  minDate,
  onChange,
  monthsShown: _monthsShown,
  activeMonth,
}) => {
  const breakpoints = useBreakpoints();
  const monthsShown = breakpoints.get({
    xs: 1,
    md: range ? 2 : 1,
    lg: _monthsShown,
  });
  const formikContext = useFormikContext();
  const { values, setFieldValue, setFieldTouched } = formikContext;
  const handleChange = (values) => {
    if (onChange) onChange(values, formikContext);
    if (range) {
      setFieldValue(name, values[0]);
      setFieldTouched(name, true);
      setFieldValue(endName, values[1]);
      setFieldTouched(endName, true);
    } else {
      setFieldTouched(name, true);
      setFieldValue(name, values);
    }
  };

  return (
    <>
      <DatePicker.Calendar
        minDate={minDate}
        monthsShown={monthsShown}
        value={range ? [values[name], values[endName]] : values[name]}
        format={CATEGORY_DATE_FORMAT}
        range={range}
        onChange={handleChange}
        activeMonth={activeMonth}
        size={
          range
            ? breakpoints.get({
                xs: CALENDAR.SIZE.DEFAULT,
                md: CALENDAR.SIZE.COMPACT,
                lg: CALENDAR.SIZE.COMPACT,
              })
            : CALENDAR.SIZE.DEFAULT
        }
        minRange={range ? 0 : null}
      />
    </>
  );
};

export default FormDatePicker;
