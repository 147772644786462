import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { BOOKING_QUERY_KEY } from "./constants";

export const useGetPricing = (defaultPricing) => {
  const queryClient = useQueryClient();
  const { values } = useFormikContext();
  const data = queryClient.getQueryData([BOOKING_QUERY_KEY]);
  if (defaultPricing) return defaultPricing;
  if (data) {
    if (values.cesu) {
      return data.pricing_with_cesu;
    }
    if (values.urssaf) {
      return data.pricing_with_tax;
    }
    return data.pricing_without_cesu;
  }
  return {};
};
