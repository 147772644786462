import { getHourDiff } from "../utils";
import {
  CHILDREN_AMOUNT,
  CHILD_CARE_LOCATION,
  CHILD_PAGE,
  CONFIG,
  NH_CONFIG,
} from "./9000/common";

const config = CONFIG;

export function useCategory9003() {
  const nhConfig = NH_CONFIG;

  const getNbHours = (values) => {
    if (values.end_hour?.toString() && values.start_hour?.toString()) {
      return getHourDiff(values.start_hour, values.end_hour);
    }
    return 1;
  };

  const pages = [...CHILD_CARE_LOCATION, ...CHILDREN_AMOUNT, ...CHILD_PAGE];

  return {
    pages,
    getNbHours,
    nhConfig,
    version: 1.1,
    config,
  };
}
