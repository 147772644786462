import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  List,
  Select,
  Slider,
} from "../components";
import { setCheckboxFieldValue } from "../utils";
import polyglot from "@/utils/polyglot";

const TASKS = [
  { label: "Nettoyage de l'électroménager", value: 0, nbHours: 0.5 },
  { label: "Nettoyage des fenêtres", value: 1, nbHours: 1 },
  { label: "Repassage", value: 2, nbHours: 1 },
];

const getTasksById = (id) => TASKS.find((task) => task.value === id) || 0;

const getNbHours = ({ surface, tasks }) => {
  const min = 1;
  let tasksTime = 0;
  tasks.forEach((task) => {
    tasksTime += getTasksById(task).nbHours;
  });
  return min + surface.value + tasksTime;
};

const LostTimeSimulator = ({ surface_options }) => {
  const [nbHours, setNbHours] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const surfaceOptions = surface_options.map((opt) => ({
    label: opt[0],
    value: opt[1],
  }));
  const handleSubmit = (values) => {
    setIsLoading(true);
    setNbHours(null);
    setTimeout(() => {
      setNbHours(getNbHours(values));
      setIsLoading(false);
    }, 200);
  };

  return (
    <Formik initialValues={{ surface: 16, tasks: [] }} onSubmit={handleSubmit}>
      {({ values, setFieldValue }) => (
        <Form>
          <p className="h4 mb-4">
            {polyglot.t("lost_time_simulator.calculate_lost_time")}
          </p>
          <Field name="surface">
            {({ field }) => (
              <FormControl label="Surface à nettoyer">
                <Select
                  options={surfaceOptions}
                  name="surface"
                  placeholder="Sélectionnez une surface"
                  {...field}
                  onChange={(selectedOption) => {
                    setFieldValue("surface", selectedOption);
                  }}
                />
              </FormControl>
            )}
          </Field>
          <label className="form-label">
            {polyglot.t("lost_time_simulator.additionnal_tasks")}
          </label>
          {TASKS.map((task) => (
            <List.Item
              key={`lost-time-task-${task.value}`}
              onClick={() =>
                setCheckboxFieldValue("tasks", task.value, {
                  values,
                  setFieldValue,
                })
              }
              RightComponent={() => (
                <Checkbox
                  name="tasks"
                  checked={values?.tasks?.includes(task.value)}
                />
              )}
            >
              <List.Elem.Title>{task.label}</List.Elem.Title>
            </List.Item>
          ))}
          <Button.Large
            type="submit"
            block
            className="mt-3"
            isLoading={isLoading}
            disabled={!values.surface?.value}
          >
            {polyglot.t("lost_time_simulator.calculate_my_lost_time")}
          </Button.Large>
          {nbHours && (
            <Alert.Low kind="success" className="mt-3">
              <span className="d-inline">
                {polyglot.t("lost_time_simulator.you_can_save_1")}{" "}
                <span className="font-weight-medium">
                  {polyglot.toDuration(Math.ceil(nbHours))}
                </span>{" "}
                {polyglot.t("lost_time_simulator.you_can_save_2")}
              </span>
            </Alert.Low>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default LostTimeSimulator;
