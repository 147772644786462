import { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { sizes, spacings } from "@/assets/themes";
import { Avatar, Block, Body14, Link } from "@/components";
import { useCategoryForm, useToast } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";
// eslint-disable-next-line import/no-cycle
import { FormInput, StyledFormControl } from "../fields";
import StepTitle from "../StepTitle";
import { formatRedirectionUrl } from "../../../loginOrRegister/utils";
import WrongCountryModal from "../../../loginOrRegister/login/WrongCountryModal";
import { EMAIL_PATH } from "../../constants";

const CurrentPassword = ({ goByPath }) => {
  const { checkEmail, postForm } = useCategoryForm();
  const [wrongCountryModalIsOpen, setWrongCountryModalIsOpen] = useState(false);
  const { user } = checkEmail.data?.data?.data;
  const { values } = useFormikContext();
  const toast = useToast();
  const { session } = postForm.queries;

  useEffect(() => {
    if (session.isError) {
      if (
        session.error?.response.data?.user_country_code &&
        session.error?.response.data?.domain_country_code
      ) {
        setWrongCountryModalIsOpen(true);
      } else {
        toast.danger(session.error.response?.data?.errorMessage);
        session.reset();
      }
    }
    return () => {
      postForm.resetQueries();
    };
  }, [session.isError]);

  const handleSignUpMissMatchModal = () => {
    goByPath(EMAIL_PATH);
    postForm.resetQueries();
    setWrongCountryModalIsOpen(false);
  };

  return (
    <>
      <Block display="flex" alignItems="flex-start">
        <StepTitle>
          {polyglot.t("categories.welcome_back_first_name", {
            first_name: user.first_name,
          })}
        </StepTitle>
        <Block marginLeft={spacings.s}>
          <Avatar
            src={user.avatar}
            size={sizes.size64}
            name={user.first_name}
          />
        </Block>
      </Block>
      <StyledFormControl
        label={polyglot.t("categories.add_password_to_see_jobbers")}
      >
        <FormInput
          name="user.password"
          autoComplete="current-password"
          type="password"
          placeholder={polyglot.t("common.placeholder.password")}
        />
      </StyledFormControl>
      <Link href="/password/new?context=user" target="_blank">
        <Body14>{polyglot.t("login.forgot_password")}</Body14>
      </Link>
      {wrongCountryModalIsOpen && (
        <WrongCountryModal
          userEmail={values.user.email}
          originCountryCode={session?.error?.response?.data?.user_country_code}
          domainCountryCode={
            session?.error?.response?.data?.domain_country_code
          }
          isOpen={wrongCountryModalIsOpen}
          onSignUp={handleSignUpMissMatchModal}
          onRedirect={() => {
            // set category id in URL
            const url_params = {
              ...Object.fromEntries(
                new URLSearchParams(window.location.search)
              ),
              ...session?.error?.response?.data.url_params,
            };
            window.location.href = formatRedirectionUrl(
              window.location.pathname,
              {
                ...session?.error?.response?.data,
                url_params,
              }
            );
          }}
          onClose={() => {
            postForm.resetQueries();
            setWrongCountryModalIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default CurrentPassword;
