import { Field } from "formik";
import { useState } from "react";
import { Divider } from "../../../components";
import { useCategoryForm } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import { FormFindCustomer, FormSelect, StyledFormControl } from "./fields";
import JobIdValidationInput from "./sav/JobIdValidationInput";

const Sav = () => {
  const [idError, setIdError] = useState(null);
  const { sav_reasons } = useCategoryForm();

  const reasonstoOptions = () =>
    sav_reasons.map((r) => ({ label: r.reason, value: r.id }));

  return (
    <div>
      <StyledFormControl label={polyglot.t("categories.sav_reason")}>
        <FormSelect
          name="sav_reason_id"
          options={reasonstoOptions()}
          placeholder={polyglot.t("categories.choose_sav_reason")}
        />
      </StyledFormControl>
      <Field name="sav_job_id">
        {({ field, form }) => (
          <StyledFormControl
            label={polyglot.t("categories.sav_job_id_optionnal")}
            error={idError}
          >
            <JobIdValidationInput
              {...field}
              onError={(err) => {
                form.setFieldTouched(field.name, true);
                setIdError(err.response?.data?.error_message);
              }}
              onChange={(value) => {
                setIdError(null);
                form.setFieldValue(field.name, value);
                form.setFieldTouched(field.name, true);
              }}
            />
          </StyledFormControl>
        )}
      </Field>
      <Divider.Cell />
      <StyledFormControl label={polyglot.t("categories.sav_choose_customer")}>
        <FormFindCustomer name="concierge_customer_id" />
      </StyledFormControl>
    </div>
  );
};

export default Sav;
