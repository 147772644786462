import { useFormikContext } from 'formik';
import React from 'react';
import { Checkbox, Container, Flow, List } from '../../components';

const Services = ({ goNext, services, goBack }) => {
  const { setFieldValue, values, isValid } = useFormikContext() || {};
  const selectedJobber = !values.jobber_id ? values.external_jobber : values.jobber;

  const getServices = () =>
    services.map(({ key, translation }) => ({ value: key, label: translation }));
  return (
    <Container.Small>
      <Flow.Item.Title>
        Quels services effectue {selectedJobber?.first_name} à votre domicile ?
      </Flow.Item.Title>
      <label htmlFor="" className="form-label">
        Sélectionnez un ou plusieurs services
      </label>
      {getServices().map(s => (
        <List.Item
          key={`services-${s.value}`}
          title={s.label}
          onClick={() => {
            const isChecked = values.services.includes(s.value);
            if (isChecked) {
              setFieldValue(
                'services',
                values.services.filter(v => v !== s.value),
              );
            } else {
              setFieldValue('services', [...values.services, s.value]);
            }
          }}
          RightComponent={() => (
            <Checkbox name="services" checked={values.services.includes(s.value)} />
          )}
        />
      ))}
      <Flow.Item.Navigation goNext={goNext} isValid={isValid} goBack={goBack} />
    </Container.Small>
  );
};

export default Services;
