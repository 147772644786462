import { formatDate, getDiff, getToday } from "@/utils";
import polyglot from "@/utils/polyglot";

export const formatArrayToComma = (array, style = "capitalize") => {
  // style = false|"capitalize"|"lowerCase"
  const arr = array.map((a, i) => {
    if (style === "lowerCase") return a.toLowerCase();
    if (style === "capitalize") {
      if (i !== 0) return a.toLowerCase();
      return a;
    }
    return a;
  });
  const finalString =
    arr.length > 1
      ? polyglot.t("common.prefix_and_suffix", {
          prefix: arr.slice(0, -1).join(", "),
          suffix: arr.slice(-1)[0],
        })
      : arr[0] || "";
  return finalString.charAt(0).toUpperCase() + finalString.slice(1);
};

export const getMemberSince = (date) => {
  const years = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "years",
    "YYYY-MM-DD"
  );
  const months = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "months",
    "YYYY-MM-DD"
  );
  const days = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "days",
    "YYYY-MM-DD"
  );

  if (years >= 1) {
    return {
      label: polyglot.t("jobber_profile.member_since_years", {
        count: years,
      }),
      value: years,
    };
  }
  if (months >= 1) {
    return {
      label: polyglot.t("jobber_profile.member_since_months", {
        count: months,
      }),
      value: months,
    };
  }
  return {
    label: polyglot.t("jobber_profile.member_since_days", {
      count: days,
    }),
    value: days,
  };
};

export const getCustomerSince = (date) => {
  const years = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "years",
    "YYYY-MM-DD"
  );
  const months = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "months",
    "YYYY-MM-DD"
  );
  const days = getDiff(
    getToday("YYYY-MM-DD"),
    formatDate(date, "YYYY-MM-DD"),
    "days",
    "YYYY-MM-DD"
  );

  if (years >= 1) {
    return {
      label: polyglot.t("jobber_profile.customer_since_years", {
        count: years,
      }),
      value: years,
    };
  }
  if (months >= 1) {
    return {
      label: polyglot.t("jobber_profile.customer_since_months", {
        count: months,
      }),
      value: months,
    };
  }
  return {
    label: polyglot.t("jobber_profile.customer_since_days", {
      count: days,
    }),
    value: days,
  };
};
