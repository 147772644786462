import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Skeleton, Block } from "../../../components";

const CesuSkeleton = () => (
  <Skeleton.Group>
    <Skeleton height={sizes.size24} width="30%" sb />
    <Block marginY={spacings.m}>
      <Skeleton height={250} width="100%" sy />
    </Block>
    <Skeleton height={sizes.size18} width="40%" sb />
    <Block marginY={spacings.m}>
      <Skeleton height={sizes.size18} width="30%" sb st />
      <Skeleton height={sizes.size48} width="100%" rows={3} />
    </Block>
    <Skeleton height={sizes.size18} width="20%" sb st />
    <Skeleton height={sizes.size48} width="100%" rows={5} />
  </Skeleton.Group>
);

export default CesuSkeleton;
