import { CountUp, H5 } from "../../components";
import usePrevious from "../../modules/hooks/usePrevious";
import cesuGetAmount from "../../utils/cesu-get-amount";
import polyglot from "../../utils/polyglot";

const TotalPrice = ({ codes }) => {
  const prevCodes = usePrevious(codes);
  const totalPrice = () => {
    const total = codes.map((code) => cesuGetAmount(code.bar_code));
    return total.reduce((pv, cv) => pv + cv, 0);
  };

  const prevTotal = prevCodes
    ? prevCodes.map((code) => cesuGetAmount(code.bar_code))
    : [0];

  const prevSum = prevTotal.reduce((pv, cv) => pv + cv, 0);

  return (
    <H5 strong>
      <CountUp
        duration={1}
        from={prevSum}
        to={totalPrice()}
        decimals={2}
        delay={0}
      />
      &nbsp;
      {polyglot.currency}
    </H5>
  );
};

export default TotalPrice;
