import { SEARCH_TERM } from '../../utils/types';
import { CATEGORIES } from '../../config/categories';

const initialState = [];

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_TERM:
      function convertItemsToSelect(items) {
        return (
          items.map(item => ({
            value: item.path,
            label: item.name,
          })) || []
        );
      }

      // const regex = new RegExp('/age\\b', 'g');
      // searchBarData.filter(data => {
      //   return data.name.match(regex);
      // });
      return convertItemsToSelect(CATEGORIES);
    default:
      return state;
  }
};
