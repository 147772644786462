import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1047() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1.5,
            subtitle: polyglot.t("categories.small_three_seater_sofa"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: 2.5,
            subtitle: polyglot.t("categories.large_corner_or_u_sofa"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 3.5,
            subtitle: polyglot.t("categories.sofa_bed_or_storage_sofa"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues: { nb_hours: 2.5 },
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
    },
  };
}
