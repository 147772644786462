import { WIZARD_GO_TO_STEP, WIZARD_STEP_IS_VALID, WIZARD_STEP_NAVIGATION } from '../../utils/types';

export const getCurrentStep = pathname => {
  let currStep = pathname.split('/');
  currStep = currStep[currStep.length - 1].split('-');
  currStep = Number(currStep[currStep.length - 1]);
  return currStep;
};

export const wizardStepNavigation = (data, wizardName, history) => dispatch => {
  const newStep = getCurrentStep(history.location.pathname) + data.step;
  history.push(`/${wizardName}/wizard/step-${newStep}`);
  dispatch({ type: WIZARD_STEP_NAVIGATION, payload: { step: newStep } });
};

export const updateStep = step => dispatch => {
  dispatch({ type: WIZARD_STEP_NAVIGATION, payload: { step } });
};
