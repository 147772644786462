import React from "react";
import BaseApp from "../modules/BaseApp";
import AdminVerify from "../pages-admin/AdminVerify";

const AdminVerifyApp = (props, rails) => () =>
  (
    <BaseApp exclude={["ReactQueryDevtools"]}>
      <AdminVerify {...props} />
    </BaseApp>
  );

export default AdminVerifyApp;
