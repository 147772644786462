import { Form, Formik } from "formik";
import React from "react";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Breadcrumb,
  Icon,
  Link,
  List,
  Toggle,
} from "../../../components";
import { LIST } from "../../../components/Styles/variants";
import {
  useGetAccountManageNotifications,
  usePutAccountManageNotifications,
} from "../../../modules/routes/dashboard-routes";
import { DASHBOARD_SMALL_CONTAINER } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import PageTitle from "../PageTitle";
import { ACCOUNT_PATH } from "../config/routes";
import ManageNotificationsSkeleton from "../skeletons/ManageNotificationsSkeleton";

const NotificationForm = ({ values, data, setFieldValue, submitForm }) => (
  <Form>
    {data.categories.map((item, index) => (
      <React.Fragment key={`manage-notification-${index}`}>
        <List.Header>{item.category}</List.Header>
        {item.options.map((option) => {
          const currVal = values[option.parameter] || option.value;
          return (
            <List.Item
              key={option.parameter}
              size={LIST.SIZE.LARGE}
              RightComponent={() => (
                <Toggle
                  defaultChecked={currVal}
                  onChange={() => {
                    setFieldValue(option.parameter, !currVal);
                    submitForm();
                  }}
                />
              )}
              LeftComponent={() => (
                <Icon.XLarge name={option.icon} color={colors.primary} />
              )}
            >
              {option.name}
            </List.Item>
          );
        })}
      </React.Fragment>
    ))}
  </Form>
);

const ManageNotifications = ({ config }) => {
  const { data, isLoading } = useGetAccountManageNotifications();
  const updateNotificationsSettings = usePutAccountManageNotifications();
  const handleSubmit = (values) => {
    updateNotificationsSettings.mutate(values);
  };

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Block as="span">{config.title}</Block>
        </Breadcrumb>
      </Block>
      <PageTitle>{config.title}</PageTitle>
      <Block marginTop={spacings.s}>
        {!isLoading ? (
          <>
            <Formik onSubmit={handleSubmit} initialValues={{}}>
              {(formikBag) => <NotificationForm data={data} {...formikBag} />}
            </Formik>
          </>
        ) : (
          <ManageNotificationsSkeleton />
        )}
      </Block>
    </Block>
  );
};

export default ManageNotifications;
