import React from "react";
import styled, { css } from "styled-components";
import { useBreakpoints } from "../modules/hooks";
import { breakpointsEnhancer, numberToPx } from "./Styles/Helper";

const GRID_SIZE = 12;
const sizeToFlex = (s, prefix) =>
  s !== "auto" ? `${prefix}${(s * 100) / GRID_SIZE}%` : `${prefix}auto`;

const getColToVal = (val, prefix) => {
  if (typeof val === "object" && val) {
    let r = val;
    Object.keys(r).forEach((key) => {
      r = {
        ...r,
        [key]: sizeToFlex(r[key], prefix),
      };
    });
    return r;
  }
  return sizeToFlex(val, prefix);
};

export const Col = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["color", "order", "size"].includes(prop) && defaultValidatorFn(prop),
})`
  ${({ size = "12", order, alignSelf, display = "block" }) => css`
    max-width: 100%;
    ${breakpointsEnhancer("flex", getColToVal(size, "0 0 "))};
    ${breakpointsEnhancer("max-width", getColToVal(size, ""))};
    ${breakpointsEnhancer("display", display)};
    ${breakpointsEnhancer("order", order)};
    ${breakpointsEnhancer("align-self", alignSelf)};
  `}
`;

const StyledRow = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["wrap", "justifyContent", "display", "alignContent"].includes(prop) &&
    defaultValidatorFn(prop),
})`
  ${({ xGutter }) =>
    xGutter &&
    css`
      margin-left: calc((${numberToPx(xGutter)} / 2) * -1);
      margin-right: calc((${numberToPx(xGutter)} / 2) * -1);
      ${Col} {
        padding-left: calc(${numberToPx(xGutter)} / 2);
        padding-right: calc(${numberToPx(xGutter)} / 2);
      }
    `};
  ${({ yGutter }) =>
    yGutter &&
    css`
      margin-bottom: calc(${numberToPx(yGutter)} * -1);
      ${Col} {
        padding-bottom: ${numberToPx(yGutter)};
      }
    `};
  ${({ wrap = "wrap", justifyContent, alignContent, display = "flex" }) =>
    css`
      ${breakpointsEnhancer("display", display)};
      ${breakpointsEnhancer("flex-wrap", wrap)};
      ${breakpointsEnhancer("justify-content", justifyContent)};
      ${breakpointsEnhancer("align-items", alignContent)};
    `}
`;

export const Row = ({ children, gutter: _gutter, ...rest }) => {
  const breakpoints = useBreakpoints();
  let gutter = _gutter;

  if (typeof _gutter === "object" && !Array.isArray(_gutter)) {
    gutter = breakpoints.get(_gutter);
  }

  const xGutter = Array.isArray(gutter) && gutter ? gutter[0] : gutter;
  const yGutter =
    Array.isArray(gutter) && gutter ? gutter[1] || gutter[0] : null;

  return (
    <StyledRow xGutter={xGutter} yGutter={yGutter} {...rest}>
      {children}
    </StyledRow>
  );
};
