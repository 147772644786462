import React from "react";
import styled, { css } from "styled-components";
import { STEPS } from "../Styles/variants";

const setDirectionStyle = (orientation) => {
  if (orientation === STEPS.ORIENTATION.HORIZONTAL) {
    return css`
      flex-direction: row;
    `;
  }
  if (orientation === STEPS.ORIENTATION.VERTICAL) {
    return css`
      flex-direction: column;
    `;
  }
  return null;
};

const StyledWrapper = styled.div`
  ${({ orientation }) => css`
    display: flex;
    align-items: flex-start;
    ${setDirectionStyle(orientation)}
  `}
`;

const Steps = ({
  orientation = STEPS.ORIENTATION.VERTICAL,
  size = STEPS.SIZE.DEFAULT,
  current,
  children,
  ...rest
}) => (
  <StyledWrapper orientation={orientation} {...rest}>
    {React.Children.map(children, (child, index) =>
      React.cloneElement(child, {
        index: index + 1,
        isFirst: index === 0,
        isLast: index + 1 === React.Children.count(children),
        active: current === index,
        done: index < current,
        orientation,
        size,
        ...child.props,
      })
    )}
  </StyledWrapper>
);

export default Steps;
