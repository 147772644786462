import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Icon from "../Icon";
import Info from "../Info";
import { TAG } from "../Styles/variants";
import Tag from "../Tag";

const TopJobberTag = ({ hideInfo }) => (
  <Block display="inline-block">
    {!hideInfo ? (
      <Info
        render={() => (
          <Tag.Small
            kind={TAG.KIND.PURPLE}
            LeftComponent={() => <Icon.Small name="love-solid" />}
            RightComponent={() => <Icon.Small name="info-circle" />}
          >
            {polyglot.t("common.top_jobber")}
          </Tag.Small>
        )}
      >
        {polyglot.t("common.top_jobber_description")}
      </Info>
    ) : (
      <Tag.Small
        kind={TAG.KIND.PURPLE}
        LeftComponent={() => <Icon.Small name="love-solid" />}
      >
        {polyglot.t("common.top_jobber")}
      </Tag.Small>
    )}
  </Block>
);
export default TopJobberTag;
