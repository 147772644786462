import polyglot from "@/utils/polyglot";
import { FormFindCustomer, StyledFormControl } from "./fields";

const Concierge = () => (
  <StyledFormControl label={polyglot.t("categories.sav_choose_customer")}>
    <FormFindCustomer name="concierge_customer_id" />
  </StyledFormControl>
);

export default Concierge;
