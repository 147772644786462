import polyglot from "../utils/polyglot";

// Subcategory::Tutoring.to_a - Subcategory::Tutoring.current_to_a('lu')
const notInCountry = {
  fr: [10022, 10021, 10020],
  be: [10014, 10013, 10010, 10009],
  ch: [10021, 10020, 10014, 10013, 10010, 10009],
  nl: [10021, 10014, 10013, 10010, 10009],
  lu: [10014, 10013, 10010, 10009],
  uk: [10002, 10007, 10008, 10009, 10010, 10013, 10014, 10017, 10018, 10019, 10020, 10021],
  ie: [10002, 10007, 10008, 10009, 10010, 10013, 10014, 10017, 10018, 10019, 10020, 10021],
};

const filterChildrenByCountry = (arr) =>
  arr.filter((a) => !notInCountry[polyglot.country]?.includes(a.id));

export const CATEGORIES = [
  {
    id: 20,
  },
  {
    id: 21,
  },
  {
    id: 22,
  },
  {
    id: 23,
  },
  {
    id: 28,
  },
  {
    id: 24,
  },
  {
    id: 25,
  },
  {
    id: 26,
  },
  {
    id: 29,
  },
  {
    id: 205,
  },
  {
    id: 206,
  },
  {
    id: 207,
  },
  {
    id: 208,
  },
  {
    id: 209,
  },
  {
    id: 210,
  },
  {
    id: 211,
  },
  {
    id: 202,
  },
  {
    id: 212,
  },
  {
    id: 204,
  },
  {
    id: 9003,
  },
  {
    id: 9004,
  },
  {
    id: 9005,
  },
  {
    id: 9006,
  },
  {
    id: 1001,
  },
  {
    id: 1003,
  },
  {
    id: 1004,
  },
  {
    id: 1005,
  },
  {
    id: 1009,
  },
  {
    id: 1025,
  },
  {
    id: 1104,
  },
  {
    id: 1002,
  },
  {
    id: 1006,
  },
  {
    id: 1026,
  },
  {
    id: 1027,
  },
  {
    id: 1028,
  },
  {
    id: 1031,
  },
  {
    id: 1032,
  },
  {
    id: 1033,
  },
  {
    id: 1034,
  },
  {
    id: 1035,
  },
  {
    id: 1036,
  },
  {
    id: 1037,
  },
  {
    id: 1038,
  },
  {
    id: 1039,
  },
  {
    id: 1043,
  },
  {
    id: 1101,
  },
  {
    id: 1007,
  },
  {
    id: 1070,
  },
  {
    id: 1008,
  },
  {
    id: 1013,
  },
  {
    id: 1029,
  },
  {
    id: 1030,
  },
  {
    id: 1022,
  },
  {
    id: 1023,
  },
  {
    id: 1024,
  },
  {
    id: 1042,
  },
  {
    id: 1044,
  },
  {
    id: 1102,
  },
  {
    id: 1011,
  },
  {
    id: 1014,
  },
  {
    id: 1015,
  },
  {
    id: 1016,
  },
  {
    id: 1045,
  },
  {
    id: 1046,
  },
  {
    id: 1047,
  },
  {
    id: 1048,
  },
  {
    id: 1049,
  },
  {
    id: 1050,
  },
  {
    id: 1051,
  },
  {
    id: 1052,
  },
  {
    id: 1053,
  },
  {
    id: 1054,
  },
  {
    id: 1055,
  },
  {
    id: 1061,
  },
  {
    id: 1072,
  },
  {
    id: 1073,
  },
  {
    id: 1074,
  },
  {
    id: 1069,
  },
  {
    id: 1075,
  },
  {
    id: 1076,
  },
  {
    id: 1077,
  },
  {
    id: 1085,
  },
  {
    id: 1086,
  },
  {
    id: 1078,
  },
  {
    id: 1079,
  },
  {
    id: 1080,
  },
  {
    id: 1062,
  },
  {
    id: 1071,
  },
  {
    id: 1017,
  },
  {
    id: 1018,
  },
  {
    id: 1063,
  },
  {
    id: 1064,
  },
  {
    id: 1065,
  },
  {
    id: 1066,
  },
  {
    id: 1067,
  },
  {
    id: 1081,
  },
  {
    id: 1068,
  },
  {
    id: 1019,
  },
  {
    id: 1021,
  },
  {
    id: 1040,
  },
  {
    id: 1103,
  },
  {
    id: 1105,
  },
  {
    id: 1106,
  },
  {
    id: 1107,
  },
  {
    id: 1108,
  },
  {
    id: 1111,
  },
  {
    id: 1109,
  },
  {
    id: 301,
  },
  {
    id: 302,
  },
  {
    id: 303,
  },
  {
    id: 304,
  },
  {
    id: 2001,
  },
  {
    id: 2002,
  },
  {
    id: 2008,
  },
  {
    id: 2010,
  },
  {
    id: 2011,
  },
  {
    id: 2012,
  },
  {
    id: 2013,
  },
  {
    id: 2014,
  },
  {
    id: 2015,
  },
  {
    id: 2016,
  },
  {
    id: 2017,
  },
  {
    id: 2018,
  },
  {
    id: 2019,
  },
  {
    id: 2020,
  },
  {
    id: 2021,
  },
  {
    id: 2022,
  },
  {
    id: 2023,
  },
  {
    id: 2024,
  },
  {
    id: 2025,
  },
  {
    id: 2026,
  },
  {
    id: 2027,
  },
  {
    id: 2028,
  },
  {
    id: 2029,
  },
  {
    id: 2030,
  },
  {
    id: 2031,
  },
  {
    id: 2032,
  },
  {
    id: 2033,
  },
  {
    id: 2034,
  },
  {
    id: 2035,
  },
  {
    id: 2036,
  },
  {
    id: 2037,
  },
  {
    id: 2038,
  },
  {
    id: 2039,
  },
  {
    id: 2040,
  },
  {
    id: 2041,
  },
  {
    id: 2042,
  },
  {
    id: 2043,
  },
  {
    id: 2044,
  },
  {
    id: 2045,
  },
  {
    id: 2046,
  },
  {
    id: 2047,
  },
  {
    id: 2048,
  },
  {
    id: 2049,
  },
  {
    id: 2050,
  },
  {
    id: 2051,
  },
  {
    id: 2099,
  },
  {
    id: 3019,
  },
  {
    id: 3020,
  },
  {
    id: 3014,
  },
  {
    id: 3003,
  },
  {
    id: 3004,
  },
  {
    id: 3015,
  },
  {
    id: 3099,
  },
  {
    id: 401,
  },
  {
    id: 402,
  },
  {
    id: 403,
  },
  {
    id: 404,
  },
  {
    id: 4001,
  },
  {
    id: 4003,
  },
  {
    id: 4008,
  },
  {
    id: 4009,
  },
  {
    id: 4010,
  },
  {
    id: 4011,
  },
  {
    id: 4012,
  },
  {
    id: 4013,
  },
  {
    id: 4014,
  },
  {
    id: 4015,
  },
  {
    id: 4016,
  },
  {
    id: 4017,
  },
  {
    id: 4018,
  },
  {
    id: 4019,
  },
  {
    id: 4020,
  },
  {
    id: 4021,
  },
  {
    id: 4022,
  },
  {
    id: 4023,
  },
  {
    id: 4024,
  },
  {
    id: 4025,
  },
  {
    id: 4026,
  },
  {
    id: 4027,
  },
  {
    id: 4028,
  },
  {
    id: 4029,
  },
  {
    id: 4099,
  },
  {
    id: 4100,
  },
  {
    id: 4101,
  },
  {
    id: 4102,
  },
  {
    id: 5006,
  },
  {
    id: 5007,
  },
  {
    id: 5008,
  },
  {
    id: 5009,
  },
  {
    id: 6012,
  },
  {
    id: 6011,
  },
  {
    id: 6010,
  },
  {
    id: 6008,
  },
  {
    id: 6099,
  },
  {
    id: 7014,
  },
  {
    id: 7015,
  },
  {
    id: 7016,
  },
  {
    id: 7018,
  },
  {
    id: 7019,
  },
  {
    id: 7020,
  },
  {
    id: 7021,
  },
  {
    id: 7022,
  },
  {
    id: 7099,
  },
  {
    id: 10001,
  },
  {
    id: 10002,
  },
  {
    id: 10003,
  },
  {
    id: 10004,
  },
  {
    id: 10005,
  },
  {
    id: 10006,
  },
  {
    id: 10007,
  },
  {
    id: 10008,
  },
  {
    id: 10009,
    exclude: ["be", "ch", "nl", "lu"],
  },
  {
    id: 10010,
    exclude: ["be", "ch", "nl", "lu"],
  },
  {
    id: 10011,
  },
  {
    id: 10012,
  },
  {
    id: 10013,

    exclude: ["be", "ch", "nl", "lu"],
  },
  {
    id: 10014,

    exclude: ["be", "ch", "nl", "lu"],
  },
  {
    id: 10015,
  },
  {
    id: 10016,
  },
  {
    id: 10017,
  },
  {
    id: 10018,
  },
  {
    id: 10019,
  },
  {
    id: 10020,
    exclude: ["fr", "ch", "lu"],
  },
  {
    id: 10021,
    exclude: ["fr", "ch", "nl", "lu"],
  },
  {
    id: 10022,
    exclude: ["fr"],
  },
];

export const popular = [
  {
    id: 3014,
  },

  {
    id: 1003,
  },
];

export const CUSTOM_CATEGORIES_SEARCH_EXCLUDES = [
  4099, 1101, 1103, 1104, 1105, 1106, 1107, 1108, 1109, 1110, 1111, 2099, 6099,
  3099,
];

export const CATEGORIES_PATH = [
  {
    id: 20, // DIY
    children: [
      {
        id: 205, // DIY5 - AMEUBLEMENT
        children: [
          { id: 1001 },
          { id: 1003 },
          { id: 1047 },
          { id: 1048 },
          { id: 1050 },
          { id: 1051 },
          { id: 1004 },
          { id: 1036 },
          { id: 1071 },
          { id: 1104, isCustom: true },
        ],
      },
      {
        id: 206, // DIY6 - Pose et fixations
        children: [
          { id: 1005 },
          { id: 1006 },
          { id: 1009 },
          { id: 1032 },
          { id: 1034 },
          { id: 1035 },
          { id: 1052 },
          { id: 1038 },
          { id: 1033 },
          { id: 1025 },
          { id: 1053 },
          { id: 1043 },
          { id: 1054 },
          { id: 1039 },
          { id: 1049 },
          { id: 1055 },
          { id: 1105, isCustom: true },
        ],
      },
      {
        id: 211, // DIY11 - Rénovation des murs
        children: [
          { id: 1007 },
          { id: 1070 },
          { id: 1072 },
          { id: 1073 },
          { id: 1008 },
          { id: 1022 },
          { id: 1054 },
          { id: 1061 },
          { id: 1023 },
          { id: 1109, isCustom: true },
        ],
      },
      {
        id: 210, // DIY10 - Rénovation des sols
        children: [
          { id: 1013 },
          { id: 1029 },
          { id: 1030 },
          { id: 1024 },
          { id: 1068 },
          { id: 1066 },
          { id: 1067 },
          { id: 1081 },
          { id: 1108, isCustom: true },
        ],
      },
      {
        id: 202, // DIY2 - Électricité
        children: [
          { id: 1006 },
          { id: 1026 },
          { id: 1002 },
          { id: 1074 },
          { id: 1031 },
          { id: 1028 },
          { id: 1027 },
          { id: 1101, isCustom: true },
        ],
      },
      {
        id: 204, // DIY4 - PLOMBERIE
        children: [
          { id: 1011 },
          { id: 1014 },
          { id: 1015 },
          { id: 1016 },
          { id: 1045 },
          { id: 1046 },
          { id: 1019 },
          { id: 1021 },
          { id: 1040 },
          { id: 1078 },
          { id: 1079 },
          { id: 1080 },
          { id: 1103, isCustom: true },
        ],
      },
      {
        id: 212, // DIY12 - Électroménager
        children: [
          { id: 1017 },
          { id: 1018 },
          { id: 1085 },
          { id: 1075 },
          { id: 1086 },
          { id: 1076 },
          { id: 1077 },
          { id: 1069 },
          { id: 1111, isCustom: true },
        ],
      },
      {
        id: 207, // DIY7 - Réparation
        children: [
          { id: 1037 },
          { id: 1036 },
          { id: 1061 },
          { id: 1062 },
          { id: 1021 },
          { id: 1037, isCustom: true },
        ],
      },
      {
        id: 208, // DIY8 - Serrurerie
        children: [
          { id: 1063 },
          { id: 1065 },
          { id: 1064 },
          { id: 1106, isCustom: true },
        ],
      },
      {
        id: 209, // DIY9 - Isolation
        children: [{ id: 1042 }, { id: 1044 }, { id: 1107, isCustom: true }],
      },
    ],
  },
  {
    id: 21,
    children: [
      {
        id: 301,
        children: [
          { id: 2001 },
          { id: 2019 },
          { id: 2020 },
          { id: 2017 },
          { id: 2021 },
          { id: 2022 },
          { id: 2012, isCustom: true },
        ],
      },
      {
        id: 302,
        children: [
          { id: 2002 },
          { id: 2023 },
          { id: 2008 },
          { id: 2017 },
          { id: 2024 },
          { id: 2032 },
          { id: 2036 },
          { id: 2031 },
          { id: 2018 },
          { id: 2015 },
          { id: 2025 },
          { id: 2026 },
          { id: 2010 },
          { id: 2027 },
          { id: 2016 },
          { id: 2014 },
          { id: 2011, isCustom: true },
        ],
      },
      {
        id: 303,
        children: [
          { id: 2033 },
          { id: 2034 },
          { id: 2035 },
          { id: 2036 },
          { id: 2032 },
          { id: 2029 },
          { id: 2030 },
          { id: 2031 },
          { id: 2028 },
          { id: 2037 },
          { id: 2099, isCustom: true },
        ],
      },
      {
        id: 304,
        children: [
          { id: 2044 },
          { id: 2045 },
          { id: 2046 },
          { id: 2040 },
          { id: 2042 },
          { id: 2043 },
          { id: 2038 },
          { id: 2039 },
          { id: 2048 },
          { id: 2047 },
          { id: 2013 },
          { id: 2041 },
          { id: 2049 },
          { id: 2050 },
          { id: 2051, isCustom: true },
        ],
      },
    ],
  },
  {
    id: 22,
    children: [
      { id: 3014 },
      { id: 3020 },
      { id: 3003 },
      { id: 3004 },
      { id: 3019 },
      { id: 3015 },
      { id: 3099, isCustom: true },
    ],
  },
  {
    id: 23,
    children: [
      {
        id: 401,
        children: [
          { id: 4001 },
          { id: 4011 },
          { id: 4010 },
          { id: 4013 },
          { id: 4015 },
          { id: 4003 },
          { id: 4009 },
          { id: 4017 },
          { id: 4016 },
          { id: 4012 },
          { id: 4014 },
          { id: 4099, isCustom: true },
        ],
      },
      {
        id: 402,
        children: [
          { id: 4018 },
          { id: 4019 },
          { id: 4020 },
          { id: 4021 },
          { id: 4100, isCustom: true },
        ],
      },
      {
        id: 403,
        children: [
          { id: 4022 },
          { id: 4023 },
          { id: 4024 },
          { id: 4025 },
          { id: 4026 },
          { id: 4101, isCustom: true },
        ],
      },
      {
        id: 404,
        children: [
          { id: 4008 },
          { id: 4027 },
          { id: 4028 },
          { id: 4029 },
          { id: 4102, isCustom: true },
        ],
      },
    ],
  },
  {
    id: 28,
    children: [
      {
        id: 9003,
      },
      {
        id: 9004,
      },
      {
        id: 9005,
      },
      { id: 9006 },
    ],
  },
  {
    id: 24,
    children: [
      {
        id: 5006,
      },
      {
        id: 5007,
      },
      {
        id: 5008,
      },
      {
        id: 5009,
        isCustom: true,
      },
    ],
  },
  {
    id: 25,
    children: [
      {
        id: 6012,
      },
      {
        id: 6011,
      },
      {
        id: 6010,
      },
      {
        id: 6008,
      },
      {
        id: 6099,
        isCustom: true,
      },
    ],
  },
  {
    id: 26,
    children: [
      {
        id: 7099,
      },
      {
        id: 7014,
      },
      {
        id: 7015,
      },
      {
        id: 7016,
      },
      {
        id: 7018,
      },
      {
        id: 7019,
      },
      {
        id: 7020,
      },
      {
        id: 7021,
      },
      {
        id: 7022,
      },
      {
        id: 7099,
        isCustom: true,
      },
    ],
  },
  {
    id: 29,
    children: filterChildrenByCountry([
      {
        id: 10001,
      },
      {
        id: 10002,
      },
      {
        id: 10003,
      },
      {
        id: 10004,
      },
      {
        id: 10005,
      },
      {
        id: 10006,
      },
      {
        id: 10007,
      },
      {
        id: 10008,
      },
      {
        id: 10009,
      },
      {
        id: 10010,
      },
      {
        id: 10011,
      },
      {
        id: 10012,
      },
      {
        id: 10013,
      },
      {
        id: 10014,
      },
      {
        id: 10015,
      },
      {
        id: 10016,
      },
      {
        id: 10017,
      },
      {
        id: 10018,
      },
      {
        id: 10019,
      },
      {
        id: 10020,
      },
      {
        id: 10021,
      },
      {
        id: 10022,
      },
    ]),
  },
];
