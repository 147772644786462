import { sizes, spacings } from "@/assets/themes";
import { Block, Skeleton } from "@/components";

const JobberSearchSkeleton = ({ jobberOnly }) => (
  <Skeleton.Group>
    {!jobberOnly && (
      <Block marginBottom={spacings.m} spaceY={spacings.s}>
        <Block>
          <Skeleton width="200px" height={sizes.size32} sb />
          <Skeleton width="100px" height={sizes.size20} />
        </Block>
        <Block
          display="flex"
          alignItems="center"
          flexWrap="no-wrap"
          gap={spacings.s}
        >
          <Skeleton width="140px" height={sizes.size32} />
          <Skeleton width="140px" height={sizes.size32} />
          <Skeleton width="140px" height={sizes.size32} />
        </Block>
      </Block>
    )}
    <Block
      display="grid"
      gap={{
        xs: spacings.m,
        sm: spacings.s,
        md: spacings.m,
        lg: spacings.ml,
      }}
      gridCols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
    >
      {new Array(10).fill("").map((_, i) => (
        <Skeleton height="300px" />
      ))}
    </Block>
  </Skeleton.Group>
);

export default JobberSearchSkeleton;
