import React from "react";
import { colors } from "../../../assets/themes";
import { Body16 } from "../../Text";

const Title = ({ children, ...rest }) => (
  <Body16 color={colors.body} {...rest}>
    {children}
  </Body16>
);

export default Title;
