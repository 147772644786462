import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { Container } from "../../components";

const AnimationLoader = ({ goNext }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const variants = {
    visible: {
      opacity: 1,
    },
    hidden: {
      opacity: 0,
    },
  };

  useEffect(() => {
    setTimeout(() => setActiveIndex(1), 1500);
    setTimeout(() => setActiveIndex(2), 2500);
    setTimeout(() => goNext(), 3000);
  }, []);

  return (
    <Container.Large>
      <div
        className="d-flex flex-column justify-content-center align-items-center"
        style={{ height: "70vh" }}
      >
        <i className="icon-spinner icon-spin display-3 text-primary" />
        <div
          className="font-weight-medium font-size-6 mt-3 text-center position-relative d-flex align-items-center justify-content-center w-100"
          style={{ height: 100 }}
        >
          <motion.div
            initial="hidden"
            animate={activeIndex === 0 ? "visible" : "hidden"}
            variants={variants}
            className="position-absolute text-center"
          >
            Préparation des éléments...
          </motion.div>
          <motion.div
            initial="hidden"
            animate={activeIndex === 1 ? "visible" : "hidden"}
            variants={variants}
            className="position-absolute text-center"
          >
            Créations des documents...
          </motion.div>
          <motion.div
            initial="hidden"
            animate={activeIndex === 2 ? "visible" : "hidden"}
            variants={variants}
            className="position-absolute text-center"
          >
            Mise en place de l'automatisation...
          </motion.div>
        </div>
      </div>
    </Container.Large>
  );
};

export default AnimationLoader;
