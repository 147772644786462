import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { spacings } from "../../../assets/themes";
import {
  Block,
  Button,
  Divider,
  H5,
  Icon,
  List,
  Spinner,
} from "../../../components";
import { BUTTON, LIST } from "../../../components/Styles/variants";
import {
  useGetTicketMessages,
  usePutTicketMessage,
} from "../../../modules/routes/dashboard-routes";
import polyglot from "../../../utils/polyglot";
import TicketChatInput from "./TicketChatInput";
import TicketMessages from "./TicketMessages";

const TicketChat = () => {
  const history = useHistory();
  const { data, isLoading } = useGetTicketMessages();
  const isClosed = data?.status === "closed";
  const putTicketMessages = usePutTicketMessage();

  const handleSubmit = (values, bag) => {
    putTicketMessages.mutate({
      ...values,
      uploads: values.uploads.map((up) => up.token),
    });
    bag.resetForm();
  };

  return (
    <Block
      display="flex"
      alignItems="stretch"
      flexDirection="column"
      height="100%"
    >
      {!isLoading ? (
        <Block
          display="flex"
          alignItems="stretch"
          flexDirection="column"
          height="100%"
        >
          <div>
            <List.Item
              divider={false}
              size={LIST.SIZE.LARGE}
              withGutters
              LeftComponent={() => (
                <Block display={{ xs: "block", md: "none" }}>
                  <Button.Medium
                    css={`
                      margin-right: ${spacings.s};
                    `}
                    onClick={() => history.goBack()}
                    shape={BUTTON.SHAPE.CIRCLE}
                    kind={BUTTON.KIND.MINIMAL}
                  >
                    <Icon.Large name="arrow-left" />
                  </Button.Medium>
                </Block>
              )}
            >
              <H5 numberOfLines={1}>{data.title}</H5>
              <List.Elem.Subtitle>
                {polyglot.t("tickets.ticket_n", { n: data.external_id })}
              </List.Elem.Subtitle>
            </List.Item>
            <Divider.Cell />
          </div>
          <TicketMessages {...data} />
          <Formik
            onSubmit={handleSubmit}
            initialValues={{ body: "", uploads: [] }}
          >
            <Form>
              <TicketChatInput
                disabled={isClosed}
                isLoading={putTicketMessages.isLoading}
              />
            </Form>
          </Formik>
        </Block>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner.Medium />
        </Block>
      )}
    </Block>
  );
};

export default TicketChat;
