import { useBreakpoints } from "@/modules/hooks";
import { colors, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Body14,
  Button,
  Icon,
  Link,
  List,
  Popover,
  StatusEnhancer,
} from "@/components";
import { BUTTON, ICON, POPOVER } from "@/components/Styles/variants";
import UserPreview from "@/pages-admin/UserPreview";
import { JOBBER } from "@/pages-admin/UserPreview/config";
import { formatPhone } from "@/utils";
import polyglot from "@/utils/polyglot";

const PhoneBtnItem = ({ phone }) => (
  <Button.Small
    kind={BUTTON.KIND.SECONDARY}
    href={`tel:${phone}`}
    shape={BUTTON.SHAPE.PILL}
    LeftComponent={() => (
      <Icon.Medium name="phone-solid" color={colors.primary} />
    )}
  >
    {formatPhone(phone)}
  </Button.Small>
);

const UserPhones = ({ users }) => {
  const breakpoints = useBreakpoints();
  return (
    <>
      <Popover.Enhancer
        trigger={POPOVER.TRIGGER_TYPE.CLICK}
        position={POPOVER.POSITIONS.BOTTOM}
        content={() => (
          <Popover.Elem.Menu style={{ width: 400, maxWidth: "95vw" }}>
            {users.map((user, index, arr) => (
              <UserPreview
                id={user.id}
                kind={JOBBER}
                key={`user-phones-item-${user.id}`}
              >
                <List.Item
                  key={`user-${user.id}`}
                  divider={index !== arr.length - 1}
                  style={{
                    paddingLeft: spacings.m,
                    paddingRight: spacings.m,
                  }}
                  RightComponent={() =>
                    user.phone ? <PhoneBtnItem phone={user.phone} /> : null
                  }
                  LeftComponent={() => (
                    <Link href={user.url}>
                      <Avatar
                        src={user.avatar}
                        size={sizes.size48}
                        name={user.first_name}
                      />
                    </Link>
                  )}
                >
                  <StatusEnhancer isPro={user.is_pro}>
                    <Link numberOfLines={1} href={user.url} color={colors.body}>
                      {user.first_name}
                    </Link>
                  </StatusEnhancer>
                  <Body14 numberOfLines={1} color={colors.muted}>
                    {polyglot.t("conversations.last_active", {
                      date: user.last_seen,
                    })}
                  </Body14>
                </List.Item>
              </UserPreview>
            ))}
          </Popover.Elem.Menu>
        )}
      >
        <Button.Medium
          size={breakpoints.get({
            xs: BUTTON.SIZE.SMALL,
            md: BUTTON.SIZE.MEDIUM,
          })}
          disabled={users.length === 0}
          kind={BUTTON.KIND.MINIMAL}
          shape={breakpoints.get({
            xs: BUTTON.SHAPE.CIRCLE,
            md: BUTTON.SHAPE.PILL,
          })}
        >
          <Icon.XLarge
            name="phone-solid"
            size={breakpoints.get({
              xs: ICON.SIZE.LARGE,
              md: ICON.SIZE.XLARGE,
            })}
          />
          {breakpoints.get({ xs: null, md: <Icon.Medium name="angle-down" /> })}
        </Button.Medium>
      </Popover.Enhancer>
    </>
  );
};

export default UserPhones;
