import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import { FLOORS } from "../constants";
import { FormRadios, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Floor = ({ title, name, goNext }) => (
  <>
    <StepTitle>{title}</StepTitle>
    <StyledFormControl>
      <FormRadios
        onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
        name={name}
        options={FLOORS}
      />
    </StyledFormControl>
  </>
);
export default Floor;
