import { useLocation } from "react-router-dom";
import { colors, sizes, spacings } from "../../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Col,
  ConditionnalWrapper,
  Divider,
  Icon,
  Link,
  List,
  Row,
  Shape,
  StatusEnhancer,
} from "../../../../components";
import { LIST, SHAPE } from "../../../../components/Styles/variants";
import { JOBBER } from "../../../../pages-admin/UserPreview/config";
import UserPreview from "../../../../pages-admin/UserPreview/UserPreview";
import polyglot from "../../../../utils/polyglot";

const renderTransactionArtwork = ({ type, user, title }) => {
  if (user) {
    return (
      <ConditionnalWrapper
        condition={user?.id}
        wrapper={(children) => (
          <UserPreview id={user.id} kind={JOBBER}>
            {children}
          </UserPreview>
        )}
      >
        <List.Item
          divider={false}
          size={LIST.SIZE.LARGE}
          LeftComponent={() => (
            <Avatar
              size={sizes.size48}
              src={user.avatar}
              name={user.first_name}
            />
          )}
        >
          <StatusEnhancer isPro={user.is_pro}>
            <Body16 strong numberOfLines={1}>
              {user.first_name}
            </Body16>
          </StatusEnhancer>
          <Block display={{ xs: "block", md: "none" }}>
            <Body14 color={colors.muted} numberOfLines={1}>
              {title}
            </Body14>
          </Block>
        </List.Item>
      </ConditionnalWrapper>
    );
  }
  if (type === "subscription_payment") {
    return (
      <List.Item
        divider={false}
        size={LIST.SIZE.LARGE}
        LeftComponent={() => (
          <Shape.Medium
            backgroundColor={colors.orangered50}
            shape={SHAPE.SHAPE.CIRCLE}
          >
            <Icon.XLarge name="yoojo-direct" color={colors.orangered500} />
          </Shape.Medium>
        )}
      >
        <Body16 strong numberOfLines={1}>
          {polyglot.t("routes.yoojo_direct")}
        </Body16>
        <Block display={{ xs: "block", md: "none" }}>
          <Body14 color={colors.muted} numberOfLines={1}>
            {title}
          </Body14>
        </Block>
      </List.Item>
    );
  }

  return null;
};

const WalletTransactionItem = ({ title, amount, id, state, type, user }) => {
  const location = useLocation();
  return (
    <>
      <Row gutter={spacings.m}>
        <Col size={{ xs: 5, md: 3, lg: 3 }}>
          {renderTransactionArtwork({ user, type, title })}
        </Col>
        <Col
          size={{ xs: 0, md: 4, lg: 4 }}
          display={{ xs: "none", md: "block" }}
        >
          <List.Item divider={false} size={LIST.SIZE.LARGE}>
            <Body16 numberOfLines={1} color={colors.muted}>
              {title}
            </Body16>
          </List.Item>
        </Col>
        <Col size={{ xs: 3, md: 2, lg: 3 }}>
          <List.Item divider={false} size={LIST.SIZE.LARGE}>
            <Body16
              css="align-self: flex-end"
              strong
              color={state !== "canceled" ? colors.body : colors.success}
            >
              {state === "canceled" ? "+" : "-"}
              {polyglot.toSmartCurrency(amount)}
            </Body16>
          </List.Item>
        </Col>
        <Col size={{ xs: 4, md: 3, lg: 2 }}>
          <List.Item divider={false} size={LIST.SIZE.LARGE}>
            <Block display="flex" justifyContent="flex-end" width="100%">
              <Link
                numberOfLines={1}
                to={{
                  pathname: `/account/wallet/${id}/details_${type}`,
                  state: { background: location },
                }}
              >
                {polyglot.t("common.see_more")}
              </Link>
            </Block>
          </List.Item>
        </Col>
      </Row>
      <Divider.Cell />
    </>
  );
};

export default WalletTransactionItem;
