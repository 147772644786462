import { useMemo, useState } from "react";
import styled from "styled-components";
import { colors, spacings } from "../../../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  CardSelectable,
  FormControl,
  H3,
  H4,
  Modal,
  Select,
} from "../../../../components";
import { CardBase } from "../../../../components/Styles/Base";
import { useBreakpoints } from "../../../../modules/hooks";
import polyglot from "../../../../utils/polyglot";
import {
  HEDGE_TRIMMING_HEIGHT,
  HEDGE_TRIMMING_WIDTH_OPTIONS,
} from "../../constants";

const StyledCardSelectable = styled(CardSelectable)`
  flex: 1;
  margin-right: ${spacings.m};
  &:last-of-type {
    margin-right: 0;
  }
`;

const HedgeTrimmingNbHoursModal = ({ isOpen, onClose, onChange }) => {
  const breakpoints = useBreakpoints();
  const [hedgeWitdh, setHedgeWidth] = useState("");
  const [hedgeHeight, setHedgeHeight] = useState("");

  const result = useMemo(() => {
    if (Number(hedgeWitdh) && Number(hedgeHeight)) {
      return hedgeWitdh * hedgeHeight;
    }
    return null;
  }, [hedgeWitdh, hedgeHeight]);

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>
          Calculer le temps d’une taille de haie
        </Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <FormControl label="Quelle est la longueur de votre haie ?">
          <Select
            placeholder="Sélectionnez une longueur"
            options={HEDGE_TRIMMING_WIDTH_OPTIONS}
            value={hedgeWitdh}
            onChange={(option) => setHedgeWidth(option.value)}
          />
        </FormControl>
        <FormControl label="Quelle est la hauteur de votre haie ?">
          <Block
            marginX={`calc(${spacings.m} * -1)`}
            overflowX="auto"
            paddingTop={spacings.s}
          >
            <Block marginX={spacings.m} display="inline-flex">
              {HEDGE_TRIMMING_HEIGHT.map((hedgeTrimming, index) => (
                <StyledCardSelectable
                  key={`calculator-hedgeTrimmings-${index}`}
                  name="hedge_triming_height"
                  css={`
                    min-width: ${breakpoints.get({
                      xs: "150px",
                      md: "inherit",
                    })};
                  `}
                  image={hedgeTrimming.image}
                  checked={hedgeHeight === hedgeTrimming.nbHoursAccumulator}
                  onClick={() =>
                    setHedgeHeight(hedgeTrimming.nbHoursAccumulator)
                  }
                >
                  <Body16 strong color={colors.body}>
                    {hedgeTrimming.label}
                  </Body16>
                  <Body14 color={colors.muted} style={{ fontWeight: 400 }}>
                    {hedgeTrimming.subtitle}
                  </Body14>
                </StyledCardSelectable>
              ))}
            </Block>
          </Block>
        </FormControl>

        <CardBase>
          <Block
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            padding={spacings.m}
          >
            <Block flex="1" marginBottom={spacings.m}>
              {!result ? (
                <Body14
                  color={colors.muted}
                  align="center"
                  css={`
                    max-width: 300px;
                  `}
                >
                  Renseignez une longueur et une hauteur de haie pour obtenir la
                  durée idéal.
                </Body14>
              ) : (
                <>
                  <Body16 align="center" color={colors.muted}>
                    Nous estimons une durée de
                  </Body16>
                  {breakpoints.get({
                    xs: (
                      <H4 align="center">
                        {polyglot.toDuration(result, true)}
                      </H4>
                    ),
                    md: (
                      <H3 align="center">
                        {polyglot.toDuration(result, true)}
                      </H3>
                    ),
                  })}
                </>
              )}
            </Block>
            <Button.Large
              block
              onClick={() => onChange(result)}
              disabled={!result}
            >
              {polyglot.t("common.confirm")}
            </Button.Large>
          </Block>
        </CardBase>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default HedgeTrimmingNbHoursModal;
