import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { borderWidth, colors, spacings } from "../../assets/themes";
import { Block, Button, Progress } from "../../components";
import { useCategoryForm } from "../../modules/hooks";
import { BREAKPOINTS } from "../../utils";
import polyglot from "../../utils/polyglot";
import CategoryFormContainer from "./CategoryFormContainer";

const StyledBar = styled.div`
  position: fixed;
  bottom: 0;
  background: ${colors.background};
  z-index: 9;
  left: 0;
  right: 0;
  @media screen and (min-width: ${BREAKPOINTS.md}px) {
    border-top: solid ${borderWidth.s} ${colors.border};
  }

  padding-bottom: env(safe-area-inset-bottom);
`;

const Navigation = ({ goBack, goNext, submitOnNext, isLoading, progress }) => {
  const { isValid, values, submitForm } = useFormikContext();
  const { flowControl } = useCategoryForm();
  const [preventDoubleClick, setPreventDoubleClick] = useState(false);

  const onEnter = (e) => {
    if (
      (e.charCode || e.keyCode) === 13 &&
      e.target.tagName?.toLowerCase() !== "textarea"
    ) {
      e.preventDefault();
      e.stopPropagation();
      if (isValid && !flowControl.getNextIsLoading()) {
        if (submitOnNext) {
          submitForm();
        } else {
          goNext();
        }
      }
    }
  };

  useEffect(() => {
    const elem = document.getElementById("flow-router");
    elem?.addEventListener("keydown", onEnter);
    return () => {
      elem?.removeEventListener("keydown", onEnter);
    };
  }, [isValid, goNext, flowControl.getNextIsLoading()]);

  useEffect(() => {
    setPreventDoubleClick(true);
    setTimeout(() => {
      setPreventDoubleClick(false);
    }, 300);
  }, [progress]);

  return (
    <StyledBar>
      <Block display={{ xs: "block", md: "none" }}>
        <Progress.Bar
          value={progress}
          css={`
            width: 100%;
          `}
        />
      </Block>
      <Block marginTop={spacings.s} marginBottom={spacings.s}>
        <CategoryFormContainer>
          <Block display="flex" justifyContent="flex-start">
            <Button.Link
              css={`
                padding-left: 0;
                padding-right: 0;
                span {
                  font-size: var(--font-size-body20);
                }
              `}
              disabled={!goBack}
              onClick={goBack}
            >
              {polyglot.t("common.back")}
            </Button.Link>
            {submitOnNext && (
              <Button.Large
                css={`
                  margin-left: auto;
                  pointer-events: ${preventDoubleClick && "none"};
                `}
                disabled={!isValid}
                type="submit"
                isLoading={isLoading}
              >
                {values.private_jobber_id
                  ? polyglot.t("categories.submit_job")
                  : polyglot.t("categories.see_jobbers")}
              </Button.Large>
            )}
            {!submitOnNext && (
              <Button.Large
                id="button-next"
                css={`
                  margin-left: auto;
                  pointer-events: ${preventDoubleClick && "none"};
                `}
                isLoading={flowControl.getNextIsLoading()}
                disabled={!isValid}
                onClick={goNext}
              >
                {flowControl.getNextLabel()}
              </Button.Large>
            )}
          </Block>
        </CategoryFormContainer>
      </Block>
    </StyledBar>
  );
};

export default Navigation;
