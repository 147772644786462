import { useEffect, useState } from "react";
import { useAdminPreview } from "../../modules/hooks";
import { useGetUserData } from "../../modules/routes/user-preview-routes";

const usePreviewInit = ({ kind, id }) => {
  const adminPreview = useAdminPreview();
  const [active, setActive] = useState(0);
  const [popoverElement, setPopoverElement] = useState(null);
  const query = useGetUserData({ id, kind }, { enabled: false });

  const init = (currRef) => {
    setPopoverElement(currRef);
    adminPreview.popovers.init({ current: currRef });
    query.refetch();
  };

  useEffect(() => {
    if (query.isSuccess) {
      if (popoverElement?.update) popoverElement?.update();
    }
  }, [query.isSuccess, popoverElement?.update]);

  return { ...query, init, tabs: { active, set: setActive } };
};

export default usePreviewInit;
