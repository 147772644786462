import React, { useEffect } from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, Body16, Icon, List, Tabs } from "../../components";
import { JOB } from "./config";
import PreviewElemJobHead from "./PreviewElemJobHead";
// eslint-disable-next-line import/no-cycle
import useJobConfig from "./hooks/useJobConfig";
import PreviewElemSkeleton from "./PreviewElemSkeleton";
import usePreviewInit from "./usePreviewInit";
import { useAdminPreview } from "../../modules/hooks";

const PreviewElemJob = ({ id, popoverRef }) => {
  const { init, data, isLoading, tabs, isSuccess } = usePreviewInit({
    id,
    kind: JOB,
  });
  const adminPreview = useAdminPreview();
  const config = useJobConfig({ id });

  useEffect(() => {
    if (popoverRef) init(popoverRef);
  }, [popoverRef]);

  useEffect(() => {
    if (isSuccess) {
      const defaultTabIndex = data?.offers.length > 0 ? 0 : 1;
      tabs.set(defaultTabIndex);
    }
  }, [isSuccess]);

  return (
    <div>
      {isLoading ? (
        <PreviewElemSkeleton />
      ) : (
        <>
          <PreviewElemJobHead
            url={data.url}
            cover={data.cover}
            title={data.title}
            datetime={data.datetime}
          />
          {config.informations()}
          {!data?.customer.deleted && (
            <List.Item
              RightComponent={() => (
                <Icon.Large
                  name="exclamation-circle-solid"
                  color={colors.danger}
                />
              )}
              onClick={() =>
                adminPreview.logAsUser({
                  id: data?.customer?.offseted_id,
                  redirect_url: data?.url,
                })
              }
              withGutters
              divider={false}
            >
              <Body16 strong color={colors.danger}>
                Prendre la main
              </Body16>
            </List.Item>
          )}
          <Block marginTop={spacings.xs}>
            <Tabs value={tabs.active} onChange={tabs.set}>
              {config.tabs.map((tab, i) => (
                <Tabs.Tab value={i} key={`tab-job-admin-${i}`} {...tab}>
                  {tab.title}
                </Tabs.Tab>
              ))}
            </Tabs>
            <List.Group>{config.menu()[tabs.active]}</List.Group>
          </Block>
        </>
      )}
    </div>
  );
};

export default PreviewElemJob;
