import React, { useState } from "react";
import { PhoneInput } from "../components";

const PhoneNumberInput = ({ name, value: _value, ...rest }) => {
  const [value, setValue] = useState(_value);

  const handleChange = (phone) => {
    setValue(phone);
  };
  return (
    <>
      <PhoneInput onChange={handleChange} value={value} {...rest} />
      <input name={name} value={value || ""} hidden />
    </>
  );
};

export default PhoneNumberInput;
