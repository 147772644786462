import { Form, Formik } from "formik";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../../assets/themes";
import { Avatar, Block, FilePickerEnhancer, Icon } from "../../../components";
import { useBreakpoints } from "../../../modules/hooks";
import { usePostAvatar } from "../../../modules/routes/dashboard-routes";
import AvatarCropModal from "./AvatarCropModal";

const AvatarUpload = ({ src, name }) => {
  const uploadAvatar = usePostAvatar();
  const breakpoints = useBreakpoints();
  const handleSubmit = (values, bag) => {
    const formatedVal = {
      attachment: values.avatar,
      crop_x: values.crop.x,
      crop_y: values.crop.y,
      crop_h: values.crop.height,
      crop_w: values.crop.width,
      rotate: values.crop.rotate,
    };
    const formData = new FormData();
    Object.keys(formatedVal).forEach((key) => {
      formData.append(key, formatedVal[key]);
    });
    uploadAvatar.mutate({ values: formData, bag });
  };

  return (
    <Formik initialValues={{ avatar: "", crop: null }} onSubmit={handleSubmit}>
      {({ values, setFieldValue, resetForm }) => (
        <Form>
          <Block position="relative" marginRight={spacings.m}>
            <FilePickerEnhancer
              name="avatar"
              accept="image/jpeg"
              onChange={(e) => {
                setFieldValue("avatar", e.currentTarget.files[0]);
                e.target.value = null;
              }}
            >
              <Avatar
                src={src}
                size={breakpoints.get({ xs: sizes.size64, md: sizes.size96 })}
                name={name}
              />
            </FilePickerEnhancer>
            <Block
              width={{ xs: sizes.size32, md: sizes.size36 }}
              height={{ xs: sizes.size32, md: sizes.size36 }}
              backgroundColor={colors.primary}
              position="absolute"
              bottom="0"
              right="0"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border={`solid ${borderWidth.m} ${colors.background}`}
              borderRadius={radius.circle}
            >
              <Icon.Large
                name="camera-plus"
                color={colors.onColor}
                size={{ xs: sizes.size16, md: sizes.size20 }}
              />
            </Block>
            {!!values.avatar && (
              <AvatarCropModal
                isOpen={!!values.avatar}
                progress={uploadAvatar.progress}
                error={uploadAvatar.isError}
                isLoading={uploadAvatar.isLoading || uploadAvatar.isSuccess}
                onClose={() => {
                  resetForm();
                }}
              />
            )}
          </Block>
        </Form>
      )}
    </Formik>
  );
};

export default AvatarUpload;
