import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import List from "../List";
import Popover from "../popover/Popover";
import { BUTTON, LIST, POPOVER, TAG } from "../Styles/variants";
import Tag from "../Tag";
import { Body14, Body16 } from "../Text";

const WalletDetailsButton = ({
  refundable_wallet_amount,
  credited_wallet_amount,
  fees_wallet_amount,
}) => (
  <Popover.Enhancer
    trigger={POPOVER.TRIGGER_TYPE.CLICK}
    content={() => (
      <Popover.Elem.Menu
        css={`
          max-width: 400px;
          width: 100%;
          padding-top: ${spacings.s};
          padding-bottom: ${spacings.s};
        `}
      >
        <List.Item size={LIST.SIZE.COMPACT} divider={false} withGutters>
          <Block
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Body16>{polyglot.t("wallet.wallet_details.fees_wallet")}</Body16>
            <Body16>{polyglot.toSmartCurrency(fees_wallet_amount)}</Body16>
          </Block>
          <Body14 color={colors.muted}>
            {polyglot.t("wallet.wallet_details.fees_wallet_description")}
          </Body14>
        </List.Item>

        {/* */}

        <List.Item size={LIST.SIZE.COMPACT} divider={false} withGutters>
          <Block
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Body16>{polyglot.t("wallet.wallet_details.other_credit")}</Body16>
            <Body16>{polyglot.toSmartCurrency(credited_wallet_amount)}</Body16>
          </Block>
          <Body14 color={colors.muted}>
            {polyglot.t("wallet.wallet_details.other_credit_description")}
          </Body14>
        </List.Item>

        {/* */}

        <List.Item size={LIST.SIZE.COMPACT} divider={false} withGutters>
          <Block
            display="flex"
            alignItems="baseline"
            justifyContent="space-between"
            width="100%"
          >
            <Body16>
              {polyglot.t("wallet.wallet_details.refundable_wallet")}
            </Body16>
            <Body16>
              {polyglot.toSmartCurrency(refundable_wallet_amount)}
            </Body16>
          </Block>
          <Body14 color={colors.muted}>
            {polyglot.t("wallet.wallet_details.refundable_wallet_description")}
          </Body14>
          <Block marginTop={spacings.xs}>
            <Tag.Small
              kind={TAG.KIND.WHITE}
              LeftComponent={() => <Icon.Small name="double-arrow" />}
            >
              {polyglot.t("common.refundable")}
            </Tag.Small>
          </Block>
        </List.Item>
      </Popover.Elem.Menu>
    )}
  >
    <Button.Small
      kind={BUTTON.KIND.MINIMAL}
      shape={BUTTON.SHAPE.CIRCLE}
      css={`
        background-color: transparent !important;
      `}
    >
      <Icon.Medium name="question-circle-solid" color={colors.onColor} />
    </Button.Small>
  </Popover.Enhancer>
);
export default WalletDetailsButton;
