import { Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import ChatNoResult from "../../assets/images/svg-icons/NoResults/chat.svg";
import { colors, shadows, sizes, spacings } from "../../assets/themes";
import {
  Avatar,
  Block,
  Button,
  Caption,
  Divider,
  H4,
  Icon,
  InfiniteScrollEnhancer,
  Modal,
  NoResult,
} from "../../components";
import { CardBase } from "../../components/Styles/Base";
import { BUTTON } from "../../components/Styles/variants";
import {
  useGetComments,
  usePostComment,
} from "../../modules/routes/job-routes";
import { JOB_COMMENT_MAX_LENGTH } from "../../utils";
import polyglot from "../../utils/polyglot";
import scrollToBottom from "../../utils/scroll-to-bottom";
import Comments from "../dashboard/job/Comments";
import JobCommentChatInput from "./JobCommentChatInput";

const Wrapper = ({ children, inModal, isOpen, onClose }) =>
  inModal ? (
    <Modal.FullScreen
      isOpen={isOpen}
      onClose={onClose}
      hideBackdrop
      css={`
        height: 100%;
        > div {
          height: 100%;
          > div:last-of-type {
            overflow: auto;
          }
        }
      `}
    >
      {children}
    </Modal.FullScreen>
  ) : (
    <Block marginBottom={spacings.m}>
      <CardBase flat>{children}</CardBase>
    </Block>
  );

const JobComments = ({ id, isOpen, onClose, inModal }) => {
  const scrollChildRef = useRef();
  const scrollRef = useRef();
  const [scrollHeight, setScrollHeight] = useState(null);
  const getComments = useGetComments({ id });
  const postComment = usePostComment({ id });
  const lastPage =
    getComments.data?.pages[getComments.data?.pages.length - 1]?.data;

  const fixScrollTopPosition = (newScroll) => {
    // Needed cause chrome always set scroll position to top when a user scroll for fetching from top.
    // 3) Set the new scroll position to the position before all the news elements was added
    if (scrollRef.current) {
      scrollRef.current.scrollTop = newScroll - scrollHeight;
    }
  };

  useEffect(() => {
    // 2) on new page handle fix scroll
    fixScrollTopPosition(scrollRef.current?.scrollHeight);
  }, [getComments?.data?.pages?.length]);

  useEffect(() => {
    scrollToBottom(scrollChildRef);
  }, [isOpen, postComment.isSuccess]);

  useEffect(() => {
    scrollToBottom(scrollChildRef, { behavior: "smooth" });
  }, [postComment.isSuccess]);

  const handleIntersect = () => {
    // 1) save last scroll height before adding the new data
    setScrollHeight(scrollRef.current?.scrollHeight);
  };

  const handleSubmit = (values, { resetForm }) => {
    // fix enter trigger submit without check if submit button is enabled
    if (values.body.trim().length > 0) {
      postComment.mutate(values);
      resetForm();
    }
  };

  return (
    <Wrapper inModal={inModal} isOpen={isOpen} onClose={onClose}>
      <Block boxShadow={shadows.xs}>
        <Block
          padding={inModal ? `${spacings.sm}` : `${spacings.ml} ${spacings.m}`}
          display="flex"
          alignItems="center"
        >
          <Block display={inModal ? "block" : "none"}>
            <Button.Medium
              css={`
                margin-right: ${spacings.s};
                margin-left: calc(${spacings.s} * -1);
              `}
              onClick={onClose}
              shape={BUTTON.SHAPE.CIRCLE}
              kind={BUTTON.KIND.MINIMAL}
            >
              <Icon.Large name="arrow-left" />
            </Button.Medium>
          </Block>
          <H4>
            {polyglot.t("common.public_chat")} ({lastPage?.total_count || 0})
          </H4>
        </Block>
        <Divider.Cell />
      </Block>
      <Block
        height={inModal ? "100%" : "450px"}
        overflowY="auto"
        ref={scrollRef}
      >
        <InfiniteScrollEnhancer
          query={getComments}
          unionBy="comments"
          reverse
          onIntersect={handleIntersect}
        >
          {({ isLoading, data }) =>
            !isLoading &&
            data && (
              <>
                {data.comments.length > 0 ? (
                  <div ref={scrollChildRef}>
                    <Comments
                      comments={data.comments}
                      id={id}
                      scrollToBottom={scrollToBottom}
                    />
                  </div>
                ) : (
                  <Block
                    paddingX={spacings.m}
                    height="100%"
                    display="flex"
                    alignItems="center"
                  >
                    <NoResult
                      artwork={ChatNoResult}
                      title={polyglot.t("common.public_chat_no_message")}
                      subtitle={polyglot.t(
                        "common.public_chat_empty_message_description"
                      )}
                    />
                  </Block>
                )}
              </>
            )
          }
        </InfiniteScrollEnhancer>
      </Block>

      {!getComments.isLoading && lastPage.show_comment_form && (
        <Formik initialValues={{ body: "" }} onSubmit={handleSubmit}>
          <Form>
            <Field name="body">
              {({ field }) => (
                <JobCommentChatInput
                  name="body"
                  isLoading={postComment.isLoading}
                  maxLength={JOB_COMMENT_MAX_LENGTH}
                  LeftComponent={() => (
                    <Avatar
                      src={getComments.data?.pages[0]?.data?.avatar}
                      size={sizes.size48}
                      name={getComments.data?.pages[0]?.data?.first_name}
                    />
                  )}
                  {...field}
                >
                  <Caption color={colors.muted} align="center">
                    {polyglot.t("chat.dont_give_personnal_informations")}
                  </Caption>
                </JobCommentChatInput>
              )}
            </Field>
          </Form>
        </Formik>
      )}
    </Wrapper>
  );
};

export default JobComments;
