export const SUCCEED_FORM = "SUCCEED_FORM";
export const ERROR_FORM = "ERROR_FORM";
export const WIZARD_STEP_NAVIGATION = "WIZARD_STEP_NAVIGATION";
export const WIZARD_STEP_IS_VALID = "WIZARD_STEP_IS_VALID";
export const USE_NEW_CREDIT_CARD = "USE_NEW_CREDIT_CARD";
export const SEARCH_TERM = "SEARCH_TERM";
export const PAY_LATER = "PAY_LATER";

// Nav
export const REMOVE_BADGE = "REMOVE_BADGE";
export const ADD_BADGE = "ADD_BADGE";

// Rails
export const INIT_DATA = "INIT_DATA";
