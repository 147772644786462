import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Block } from "../../components";
import AdminPreviewProvider from "../../modules/providers/AdminPreviewProvider";
import {
  CUSTOMER,
  IN_APP_MOUSE_OUTSIDE_SHOW_DELAY,
  JOB,
  JOBBER,
} from "./config";
import PreviewCursor from "./PreviewCursor";
import UserPreviewContent from "./UserPreviewContent";

const UserPreview = React.forwardRef(
  ({ id, kind: _kind, children, isOpen, hideAdminCursor, onClose }, ref) => {
    const { current_admin } = useSelector((state) => state.rails);
    const kinds = [JOBBER, CUSTOMER, JOB];
    const [referenceElement, setReferenceElement] = useState(null);
    return (
      <>
        {current_admin ? (
          <>
            {!children &&
              kinds.map((kind) =>
                Array.from(
                  document.querySelectorAll(`[data-preview-${kind}]`) || []
                ).map((elem, index) => (
                  <AdminPreviewProvider
                    kind={kind}
                    key={`${kind}-preview-${index}`}
                    id={elem?.getAttribute(`data-preview-${kind}`)}
                  >
                    {!hideAdminCursor && <PreviewCursor node={elem} />}
                    <UserPreviewContent
                      kind={kind}
                      node={elem}
                      ref={ref}
                      id={elem?.getAttribute(`data-preview-${kind}`)}
                      isOpen={isOpen}
                      onClose={onClose}
                    />
                  </AdminPreviewProvider>
                ))
              )}
            {children && (
              <AdminPreviewProvider kind={_kind} id={id}>
                {!hideAdminCursor && <PreviewCursor node={referenceElement} />}
                <Block width="100%" ref={setReferenceElement}>
                  {children}
                  <UserPreviewContent
                    node={referenceElement}
                    onMouseEnterDelay={IN_APP_MOUSE_OUTSIDE_SHOW_DELAY}
                    id={id}
                    kind={_kind}
                    ref={ref}
                    isOpen={isOpen}
                    onClose={onClose}
                  />
                </Block>
              </AdminPreviewProvider>
            )}
          </>
        ) : (
          children
        )}
      </>
    );
  }
);

export default UserPreview;
