import { radius, sizes, spacings } from "../../../assets/themes";
import { Block, Col, Row, Skeleton } from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import { SKELETON } from "../../../components/Styles/variants";
import { BREAKPOINTS } from "../../../utils";
import OfferSkeletons from "./OfferSkeleton";

const JobSkeleton = () => (
  <Block marginY={{ xs: "0px", md: spacings.m }}>
    <Skeleton.Group>
      <Block display={{ xs: "none", md: "block" }}>
        <Skeleton height={sizes.size16} width={300} sb />
      </Block>
      <Block display="flex" alignItems="flex-start">
        <Block flex="1" minWidth="0">
          <CardBase
            flat
            css={`
              overflow: hidden;
              border-radius: 0;
              @media screen and (min-width: ${BREAKPOINTS.md}px) {
                border-radius: ${radius.ml};
              }
            `}
          >
            <Skeleton
              height={{ xs: "130px", md: "140px" }}
              width="100%"
              shape={SKELETON.SHAPE.SQUARE}
            />
            <Block padding={{ xs: spacings.m, md: spacings.ml }}>
              <Skeleton height={sizes.size36} width="60%" sb />
              <Skeleton height={sizes.size14} width="30%" />
              {new Array(2).fill("").map((_, i) => (
                <Block
                  marginY={spacings.m}
                  display="flex"
                  alignItems="center"
                  key={`job-skeleton-information-${i}`}
                >
                  <Skeleton
                    height={sizes.size36}
                    width={sizes.size36}
                    sr
                    shape={SKELETON.SHAPE.CIRCLE}
                  />
                  <Skeleton
                    height={sizes.size16}
                    width={`${200 / (i + 1)}px`}
                  />
                </Block>
              ))}
              <Row gutter={[spacings.s]}>
                <Col size={6}>
                  <Skeleton height={sizes.size48} width="100%" />
                </Col>
                <Col size={6}>
                  <Skeleton height={sizes.size48} width="100%" />
                </Col>
              </Row>
            </Block>
          </CardBase>
          <Block
            marginTop={spacings.l}
            marginX={{ xs: spacings.sm, md: "0px" }}
          >
            <Skeleton height={sizes.size24} width={150} st />
            <OfferSkeletons />
          </Block>
        </Block>
        <Block marginLeft={spacings.l} display={{ xs: "none", md: "block" }}>
          <CardBase
            flat
            css={`
              width: 375px;
              height: 520px;
              margin-bottom: ${spacings.ml};
            `}
          >
            <Block
              padding={{ xs: spacings.m, md: spacings.ml }}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              <Block>
                <Skeleton width="60%" height={30} sb />
                <Skeleton width="100%" height={90} rows={2} />
              </Block>
              <Block display="flex" alignItems="center">
                <Skeleton
                  width={sizes.size48}
                  height={sizes.size48}
                  shape={SKELETON.SHAPE.CIRCLE}
                  sr
                />
                <Skeleton width="100%" height={sizes.size48} />
              </Block>
            </Block>
          </CardBase>
          <CardBase
            flat
            css={`
              width: 375px;
            `}
          >
            <Block padding={{ xs: spacings.m, md: spacings.ml }}>
              <Skeleton width="60%" height={30} sb />
              <Skeleton width="100%" height={56} sb rows={3} />
            </Block>
          </CardBase>
        </Block>
      </Block>
    </Skeleton.Group>
  </Block>
);

export default JobSkeleton;
