import React, { useState, useEffect } from "react";
import { Button, List, Modal, Stepper } from "../../components";
import { putEditRelation } from "../../modules/routes";

const EditNbHoursModal = ({
  onClose,
  nb_hours,
  frequency,
  isOpen,
  weekly_nb_hours_min,
  weekly_nb_hours_max,
  monthly_nb_hours_min,
  monthly_nb_hours_max,
  updateData,
  id,
}) => {
  const [currValue, setCurrValue] = useState(nb_hours);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCurrValue(nb_hours);
  }, [nb_hours]);

  const label =
    frequency === "monthly"
      ? "Nombre d'heures par mois"
      : "Nombre d'heures par semaine";

  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = putEditRelation({ ...values, id });
    res
      .then(({ data }) => {
        updateData();
        setIsLoading(false);
        onClose();
      })
      .catch((error) => {
        setIsLoading(false);
        console.error(error);
      });
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header
        onClose={onClose}
        title={label}
        subtitle={`Indiquez le nombre d'heures à effectuer chaque ${
          frequency == "monthly" ? "mois" : "semaine"
        } par votre prestataire.`}
      />
      <Modal.Item.Wrapper>
        <List.Item
          divider={false}
          title={label}
          RightComponent={() => (
            <Stepper
              value={currValue}
              min={
                frequency === "monthly"
                  ? monthly_nb_hours_min
                  : weekly_nb_hours_min
              }
              max={
                frequency === "monthly"
                  ? monthly_nb_hours_max
                  : weekly_nb_hours_max
              }
              hint={frequency === "monthly" ? "Par mois" : "Par semaine"}
              suffix="h"
              onChange={(v) => setCurrValue(v)}
            />
          )}
        />
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large
          block
          disabled={currValue === nb_hours}
          onClick={() => handleSubmit({ nb_hours: currValue })}
          isLoading={isLoading}
        >
          Enregistrer
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.Small>
  );
};

export default EditNbHoursModal;
