import React from "react";
import BaseApp from "../modules/BaseApp";
import AddressInput from "../pages/AddressInput";

const AddressApp = (props, rails) => () =>
  (
    <BaseApp>
      <AddressInput {...props} />
    </BaseApp>
  );

export default AddressApp;
