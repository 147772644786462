import React from 'react';
import { Button, Link, Modal } from '../../../components';

const SuccessModal = ({ onClose, isOpen, cesu }) => (
  <Modal.XSmall onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} />
    <Modal.Item.Wrapper className="d-flex flex-column align-items-center">
      <div className="position-relative mb-4">
        <i className="icon-check-circle mr-2 text-success" style={{ fontSize: '9rem' }} />
      </div>
      <p className="font-size-4 text-center font-weight-medium">Félicitations, tout est prêt !</p>
      <Button.Large className="mt-4" block onClick={onClose}>
        Gérer mon employé de maison
      </Button.Large>
      {cesu && (
        <Link className="mt-4" block href="/cesu">
          <i className="icon-ticket text-primary" /> Ajouter des tickets CESU
        </Link>
      )}
    </Modal.Item.Wrapper>
  </Modal.XSmall>
);

export default SuccessModal;
