import { useHistory } from "react-router-dom";
import { radius, spacings } from "@/assets/themes";
import { Banner, Block } from "@/components";
import polyglot from "@/utils/polyglot";
import { BANNER } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";

function importAll(r) {
  const images = {};
  r.keys().map((item) => {
    images[item.replace(/\.[^/.]+$/, "").replace("./", "")] = r(item);
  });
  return images;
}

const artwork = importAll(
  require.context("images/categories-photos/", false, /\.(png|jpe?g|svg|webp)$/)
);

const MainCategoriesHorizontal = ({ block }) => {
  const history = useHistory();
  const breakpoints = useBreakpoints();

  const handleClick = (catId) => {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set("category", catId);
    history.push({
      pathname: history.location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <Block
      display="grid"
      gridCols={{ xs: 1, md: 1, lg: 2 }}
      gap={{ xs: spacings.s, md: spacings.m }}
    >
      {block.data.map((b) => (
        <Banner
          key={`main-categories-banner-${b}`}
          title={polyglot.t(`categories.${b}.name`)}
          subtitle={polyglot.t(`categories.${b}.inspirationnal`)}
          strong
          size={breakpoints.get({
            xs: BANNER.SIZE.MEDIUM,
            xl: BANNER.SIZE.LARGE,
          })}
          backgroundImage={artwork[b]}
          onClick={() => handleClick(b)}
          css={`
            overflow: hidden;
            background-position: 0% 70%;
            & > div:first-child {
              position: relative;
            }
            &:before {
              content: "";
              border-radius: ${radius.ml};
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              background: rgba(0, 0, 0, 0.4);
            }
          `}
        />
      ))}
    </Block>
  );
};

export default MainCategoriesHorizontal;
