import React, { useContext } from "react";
import YoojoDirectCover from "../../../assets/images/yoojo-direct.svg";
import { spacings } from "../../../assets/themes";
import { Block, Button, Icon, Link, List, NoResult } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { DashboardContext } from "../../../modules/contexts";
import { useGetYoojoDirectJobs } from "../../../modules/routes/dashboard-routes";
import polyglot from "../../../utils/polyglot";
import JobsSkeleton from "../skeletons/JobsSkeleton";
import RelationContent from "./item/RelationContent";
import JobsItem from "./JobsItem";

const YoojoDirectJobs = () => {
  const { isLoading, data } = useGetYoojoDirectJobs();
  const { public_pages } = useContext(DashboardContext);
  const hasRelations =
    data?.direct_relations_active?.length > 0 ||
    data?.direct_relations_inactive?.length > 0;
  return (
    <>
      {!isLoading && data ? (
        <>
          <Block marginY={spacings.s}>
            {data.direct_relations_active.length > 0 ? (
              <List.Header
                RightComponent={() => (
                  <Link
                    href={
                      public_pages.yoojo_direct + (hasRelations ? "/new" : "")
                    }
                  >
                    <Icon.Medium
                      name="plus-circle-solid"
                      css={`
                        margin-right: ${spacings.xs};
                      `}
                    />
                    {polyglot.t("yoojo_direct.add_relation")}
                  </Link>
                )}
              >
                {polyglot.t("common.enabled")}
              </List.Header>
            ) : (
              <NoResult
                title={polyglot.t("yoojo_direct.no_active_relation")}
                subtitle={polyglot.t(
                  "yoojo_direct.no_active_relation_description"
                )}
              >
                <Button.Medium href={public_pages.yoojo_direct}>
                  {polyglot.t("yoojo_direct.add_relation")}
                </Button.Medium>
              </NoResult>
            )}
            {data.direct_relations_active.map((relation) => (
              <JobsItem
                key={`job-${relation.id}`}
                title={relation.title}
                href={relation.url}
                artwork={YoojoDirectCover}
                disableAdminPreview
                subtitle={`${relation.frequency} • ${relation.nb_hours}`}
                RightComponent={() => <RelationContent {...relation} />}
              >
                <Button.Medium href={relation.url} block>
                  {polyglot.t("yoojo_direct.manage_relation")}
                </Button.Medium>
              </JobsItem>
            ))}
          </Block>
          <Block marginY={spacings.s}>
            {data.direct_relations_inactive.length > 0 && (
              <List.Header>{polyglot.t("common.closed_f")}</List.Header>
            )}
            {data.direct_relations_inactive.map((relation) => (
              <JobsItem
                key={`job-${relation.id}`}
                title={relation.title}
                href={relation.url}
                artwork={YoojoDirectCover}
                subtitle={`${relation.frequency} • ${relation.nb_hours}`}
                RightComponent={() => <RelationContent {...relation} />}
              >
                <Button.Medium
                  href={relation.url}
                  kind={BUTTON.KIND.SECONDARY}
                  block
                >
                  {polyglot.t("yoojo_direct.check_relation")}
                </Button.Medium>
              </JobsItem>
            ))}
          </Block>
        </>
      ) : (
        <JobsSkeleton />
      )}
    </>
  );
};

export default YoojoDirectJobs;
