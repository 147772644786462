import styled from "styled-components";
import { useRef } from "react";
import { getDayIndexByValue, getShortDayNameByIndex } from "../../utils/moment";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import { Block, Body14, Popover } from "../../components";
import polyglot from "../../utils/polyglot";
import { ButtonBase } from "../../components/Styles/Base";
import { POPOVER } from "../../components/Styles/variants";
import { JOB_MAX_HOUR, JOB_MIN_HOUR } from "../../utils";

const HOURS_TICKS = Array.from(
  { length: (JOB_MAX_HOUR - JOB_MIN_HOUR) / 2 + 1 },
  (_, i) => JOB_MIN_HOUR + i * 2
);

const Y_SIZE = sizes.size36;

const StyledLi = styled.li`
  display: flex;
  align-items: center;
  height: ${sizes.size12};
  &:not(:last-child) {
    margin-bottom: ${spacings.m};
  }
`;

const StyledAvailabilityWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  position: relative;
  height: ${sizes.size12};
  border-radius: ${radius.circle};
  background: repeating-linear-gradient(
    -45deg,
    ${colors.border} 0,
    ${colors.border} 5px,
    ${colors.background} 5px,
    ${colors.background} 10px
  );
`;

const StyledAvailability = styled.div`
  position: absolute;
  background: ${colors.success};
  border-radius: ${radius.circle};
  height: ${sizes.size12};
  top: 0;
  left: ${({ start }) =>
    ((start - JOB_MIN_HOUR) / (JOB_MAX_HOUR - JOB_MIN_HOUR)) * 100}%;
  width: ${({ start, end }) =>
    ((end - start) / (JOB_MAX_HOUR - JOB_MIN_HOUR)) * 100}%;
  &:hover {
    background: ${colors.success600};
  }
`;

const StyledXValues = styled.div`
  margin-left: ${Y_SIZE};
  margin-top: ${spacings.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledUl = styled.ul`
  padding: 0;
  margin: 0;
  position: relative;
`;

const StyledBackgroundXSpliters = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  margin-left: ${Y_SIZE};
`;

const StyledSpliter = styled.div`
  height: 100%;
  border-left: dashed ${borderWidth.s} ${colors.backgroundGray};
`;

const AvailabilitiesPreview = ({ schedules }) => {
  const wrapperRef = useRef();
  return (
    <div ref={wrapperRef}>
      <StyledUl>
        <StyledBackgroundXSpliters>
          {new Array(JOB_MAX_HOUR - JOB_MIN_HOUR + 1)
            .fill("")
            .map((_, index) => (
              <StyledSpliter key={`ul-bg-ticks-${index}`} />
            ))}
        </StyledBackgroundXSpliters>
        {new Array(7).fill("").map((_, i) => (
          <StyledLi key={`day-render-${getShortDayNameByIndex(i)}`}>
            <Block width={Y_SIZE}>
              <Body14 color={colors.muted}>{getShortDayNameByIndex(i)}</Body14>
            </Block>
            <StyledAvailabilityWrapper>
              {schedules
                .filter((s) => i === getDayIndexByValue(s.day_of_week))
                .map((s, scheduleIndex) => (
                  <StyledAvailability
                    start={s.start_hour}
                    end={s.end_hour}
                    key={`schedule-day-${getShortDayNameByIndex(
                      i
                    )}-${scheduleIndex}`}
                  >
                    <Popover.Enhancer
                      onMouseLeaveDelay={0}
                      mountNode={wrapperRef.current}
                      position={POPOVER.POSITIONS.BOTTOM}
                      content={() => (
                        //  pointer-events: none -> prevent close admin widget on Tooltip hover- mountNode dont work here
                        <Popover.Elem.Tooltip
                          css={`
                            pointer-events: none;
                          `}
                        >
                          {polyglot.toTime(s.start_hour)} →{" "}
                          {polyglot.toTime(s.end_hour)}
                        </Popover.Elem.Tooltip>
                      )}
                    >
                      <ButtonBase
                        css={`
                          width: 100%;
                          height: 100%;
                        `}
                      >
                        &nbsp;
                      </ButtonBase>
                    </Popover.Enhancer>
                  </StyledAvailability>
                ))}
            </StyledAvailabilityWrapper>
          </StyledLi>
        ))}
      </StyledUl>
      <StyledXValues>
        {HOURS_TICKS.map((hour, index) => (
          <Body14 color={colors.muted} key={`hour-shedules-${hour}-${index}`}>
            {hour}h
          </Body14>
        ))}
      </StyledXValues>
    </div>
  );
};
export default AvailabilitiesPreview;
