import { api, browserInfo } from '../../utils';

// 📚 https://gist.github.com/rafaelmillan/863360dce4e073e5854f74627d2d6e0c

export const postRelation = values => api.post('/yoojo-direct', { ...values, browserInfo });

export const putCancelRelation = ({ id, cancel_reason }) =>
  api.put(`/yoojo-direct/${id}/cancel`, { cancel_reason });

export const getNewRelation = () => api.get('/yoojo-direct/new');

export const getVoucherDetails = ({ code, plan }) =>
  api.get('/yoojo-direct/voucher', { params: { code, plan } });

export const getPaymentDetails = ({ id, paymentId }) =>
  api.get(`/yoojo-direct/${id}/payments/${paymentId}`);

export const getRelation = ({ id }) => api.get(`/yoojo-direct/${id}`);

export const putEditRelation = ({ nb_hours, price_per_hour, id }) =>
  api.put(`/yoojo-direct/${id}`, { nb_hours, price_per_hour });

export const getHistory = ({ id, page }) =>
  api.get(`/yoojo-direct/${id}/actions`, { params: { page } });

export const getDocuments = ({ id }) => api.get(`/yoojo-direct/${id}/documents`);

export const getDirectPayments = ({ id, page }) =>
  api.get(`/yoojo-direct/${id}/payments`, { params: { page } });

export const putUpdateCreditCard = ({ id, token }) =>
  api.put(`/yoojo-direct/${id}/update_credit_card`, { token, browserInfo });

export const putUpdateIban = ({ id, iban }) => api.put(`/yoojo-direct/${id}/update_iban`, { iban });

export const putPaymentMethod = ({ id, payment_method }) =>
  api.put(`/yoojo-direct/${id}/update_payment_method`, { payment_method });

export const getDirectSubscription = ({ id, page }) =>
  api.get(`/yoojo-direct/${id}/payments/subscription_payments`, { params: { page } });

export const getEditPayment = ({ id, payment_id }) =>
  api.get(`/yoojo-direct/${id}/payments/${payment_id}/edit`);

export const getSimulatePricing = ({ price_per_hour, nb_hours, id }) =>
  api.get(`/users/simulate_taxes`, { params: { price_per_hour, nb_hours, direct_payment_id: id } });

export const getJobber = ({ email, phone }) =>
  api.get(`/yoojo-direct/search_jobber`, { params: { phone, email } });

export const putUpdatePayment = ({ id, payment_id, ...rest }) =>
  api.put(`/yoojo-direct/${id}/payments/${payment_id}`, { ...rest });

export const putUpdatePaymentDay = ({ id, monthly_payment_day, weekly_payment_day }) =>
  api.put(`/yoojo-direct/${id}/update_payment_day`, { monthly_payment_day, weekly_payment_day });

export const putUrssaf = ({ first_name, last_name, date_of_birth, tax_deduction, ...rest }) =>
  api.put(`/users/update_urssaf_informations`, {
    first_name,
    last_name,
    date_of_birth,
    tax_deduction,
    ...rest,
  });

export const postContract = ({ id, values }) => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });
  return api.post(`/yoojo-direct/${id}/contracts`, formData);
};

export const putUpdateContract = ({ id, values }) => {
  const formData = new FormData();
  Object.keys(values).forEach(key => {
    formData.append(key, values[key]);
  });
  return api.put(`/yoojo-direct/${id}/contracts`, formData);
};

// can_edit_identity_informations = true : désactiver first_name / last_name / date_of_birth

// rien OU place_id OU (city_id && address_name)
// first_name
// last_name
// date_of_birth
