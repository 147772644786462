import { useFormik } from "formik";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  shadows,
  spacings,
} from "@/assets/themes";
import { Block, Body14, Button, FormControl, Icon, Select } from "@/components";
import { BUTTON, INPUT } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import { JOBBER_SEARCH_ROLES } from "@/utils";
import polyglot from "@/utils/polyglot";
import SelectZipCode from "./common/addressModal/SelectZipCode";

const StyledZipCode = styled(SelectZipCode)`
  [data-content="input"] {
    padding-top: calc(${spacings.ml} - ${spacings.xs});
    background-color: ${colors.background};
    input {
      padding-right: 0;
    }
  }
`;

const StyledSelect = styled(Select)`
  [data-content="input"] {
    padding-top: calc(${spacings.ml} - ${spacings.xs});
    background-color: ${colors.background};
    input {
      padding-right: 0;
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 0 !important;
  position: relative;
  flex: 1;
  > label {
    margin: 0 !important;
    position: absolute;
    top: ${spacings.xs};
    z-index: 9;
    left: ${spacings.sm};
  }
`;

const StyledLabel = styled(Body14)`
  margin-bottom: 0;
  margin-top: 0;
`;

const JobbersSeoSearchBar = ({ initialValues, onSubmit, submitOnChange }) => {
  const breakpoints = useBreakpoints();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: (values, form) => {
      const newArr = { ...values };
      if (!newArr.city_id) {
        // force submit with null
        newArr.city_id = null;
      }
      delete newArr.city;
      onSubmit(newArr, form);
    },
  });

  const handleZipChange = (values) => {
    formik.setFieldValue("city_id", values.value);
    formik.setFieldValue("city", values.label);
    if (submitOnChange) formik.submitForm();
  };

  const handleRoleChange = ({ value }) => {
    formik.setFieldValue("role", value);
    if (submitOnChange) formik.submitForm();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Block
        borderRadius={{ xs: radius.l, sm: radius.circle }}
        paddingLeft={{ xs: spacings.s, md: spacings.m }}
        paddingRight={spacings.s}
        paddingY={{ xs: spacings.s, sm: spacings.xs }}
        backgroundColor={colors.background}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        border={`solid ${borderWidth.m} ${colors.border}`}
        boxShadow={shadows.xs}
        alignItems={{ xs: "stretch", sm: "center" }}
        gap={spacings.s}
      >
        <StyledFormControl
          label={() => (
            <StyledLabel strong numberOfLines={1}>
              {polyglot.t("jobbers_seo.role_label")}
            </StyledLabel>
          )}
        >
          <StyledSelect
            name="role"
            noPortal={false}
            value={formik.values.role}
            size={INPUT.SIZE.SMALL}
            onChange={handleRoleChange}
            options={JOBBER_SEARCH_ROLES.map((value) => ({
              label: polyglot.t(`roles.${value}`, { count: 0 }),
              value,
            }))}
          />
        </StyledFormControl>
        <StyledFormControl
          label={() => (
            <StyledLabel strong numberOfLines={1}>
              {polyglot.t("search.where")}
            </StyledLabel>
          )}
        >
          <StyledZipCode
            onChange={handleZipChange}
            size={INPUT.SIZE.SMALL}
            noPortal={false}
            value={
              formik.values.city_id
                ? { label: formik.values.city, value: null }
                : {}
            }
          />
        </StyledFormControl>
        {breakpoints.get({
          xs: (
            <Button.Medium block type="submit" key="sumbit-responsive-btn-role">
              {polyglot.t("common.search")}
            </Button.Medium>
          ),
          sm: (
            <Button.Medium
              shape={BUTTON.SHAPE.CIRCLE}
              type="submit"
              key="sumbit-desktop-btn-role"
            >
              <Icon.Medium name="search" />
            </Button.Medium>
          ),
        })}
      </Block>
    </form>
  );
};

export default JobbersSeoSearchBar;
