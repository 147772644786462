import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Skeleton, Block } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const InboxesSkeleton = () => (
  <Skeleton.Group>
    {new Array(20).fill("").map((_, i) => (
      <Block
        display="flex"
        alignItems="center"
        marginY={spacings.l}
        key={`inboxes-skel-${i}`}
      >
        <Skeleton
          shape={SKELETON.SHAPE.CIRCLE}
          height={sizes.size48}
          width={sizes.size48}
          sr
        />
        <Block width="100%">
          <Skeleton height={sizes.size16} width="60%" sb />
          <Skeleton height={sizes.size16} width="100%" />
        </Block>
      </Block>
    ))}
  </Skeleton.Group>
);

export default InboxesSkeleton;
