import { useFormikContext } from "formik";
import CesuItem from "@/components/CesuItem";
import { spacings } from "../../assets/themes";
import { Block, Body14 } from "../../components";
import { LIST } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import PaymentMethodsUpdated from "../common/payment-methods";
import { useGetPricing } from "./useGetPricing";

const Payment = ({ payment_methods, cesu, onChange, innerRef }) => {
  const pricing = useGetPricing();
  const { values, setFieldValue } = useFormikContext();

  const handleCesuClick = (v) => {
    setFieldValue("cesu", v);
  };

  return (
    <>
      <Block marginBottom={spacings.m}>
        <PaymentMethodsUpdated
          disabled={pricing.total === 0}
          divider
          ref={innerRef}
          methods={payment_methods}
          onChange={onChange}
        />
        {cesu?.amount > 0 && (
          <CesuItem
            shape={LIST.SHAPE.ROUND}
            divider={false}
            withGutters
            eligibility={cesu.eligibility}
            amount={cesu.amount}
            checked={values.cesu}
            onChange={() => handleCesuClick(!values.cesu)}
          />
        )}
      </Block>
      {pricing.price > 0 && (
        <Block marginTop={spacings.m}>
          <Body14>{polyglot.t("booking.prepaid_description")}</Body14>
        </Block>
      )}
    </>
  );
};

export default Payment;
