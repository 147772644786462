import { Field, Form, useFormikContext } from "formik";
import { colors, spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Button,
  FormControl,
  Icon,
  Info,
  Input,
  Modal,
  Tag,
} from "../../../components";
import SecureScanIcon from "../../../components/SecureScanIcon";
import { BUTTON, TAG } from "../../../components/Styles/variants";
import { useCesu } from "../../../modules/hooks";
import cesuCodeValidation from "../../../utils/cesu-code-validation";
import cesuGetAmount from "../../../utils/cesu-get-amount";
import polyglot from "../../../utils/polyglot";

const icon = () => (
  <div
    style={{
      width: 92,
      height: 92,
      borderRadius: "999px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <svg
      className="svg-barcode-numbers"
      width="100"
      height="100"
      viewBox="8 0 164 164"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="circleClip">
          <circle cx="90" cy="82" r="82" />
        </clipPath>
      </defs>
      <g
        id="CRCESU.icon.barcodeNumbers"
        fill="none"
        fillRule="evenodd"
        clipPath="url(#circleClip)"
      >
        <circle id="numbers-bg" fill="#F8F8F8" cx="90" cy="82" r="82" />
        <g id="numbers-ticket" transform="translate(31)">
          <path
            d="M4.49998764,-9.23705556e-14 L113.500016,-9.28146449e-14 C115.709155,-9.32204571e-14 117.500016,1.790861 117.500016,4 L117.500016,106.000188 C117.500016,108.209327 115.709155,110.000188 113.500016,110.000188 L4.49998764,110.000188 C2.29084864,110.000188 0.499987643,108.209327 0.499987643,106.000188 L0.499987643,4 C0.499987643,1.790861 2.29084864,-9.19647434e-14 4.49998764,-9.23705556e-14 Z"
            id="cesu-stroke"
            stroke="#DEDEDE"
            strokeWidth="5"
            fill="#FFF"
          />
          <path
            d="M52,64 L52,90 L42,90 L42,64 L52,64 Z M88,64 L88,90 L79,90 L79,64 L88,64 Z M31,64 L31,90 L28,90 L28,64 L31,64 Z M38,64 L38,90 L35,90 L35,64 L38,64 Z M75,64 L75,90 L72,90 L72,64 L75,64 Z M95,64 L95,90 L92,90 L92,64 L95,64 Z M104,64 L104,90 L99,90 L99,64 L104,64 Z M59,64 L59,90 L56,90 L56,64 L59,64 Z M68,64 L68,90 L63,90 L63,64 L68,64 Z M24,64 L24,90 L14,90 L14,64 L24,64 Z"
            id="numbers-Shape"
            fill="#DEDEDE"
          />
          <path
            d="M15.5,94 L24.5,94 C25.3284271,94 26,94.6715729 26,95.5 C26,96.3284271 25.3284271,97 24.5,97 L15.5,97 C14.6715729,97 14,96.3284271 14,95.5 C14,94.6715729 14.6715729,94 15.5,94 Z M30.5,94 L34.5,94 C35.3284271,94 36,94.6715729 36,95.5 C36,96.3284271 35.3284271,97 34.5,97 L30.5,97 C29.6715729,97 29,96.3284271 29,95.5 C29,94.6715729 29.6715729,94 30.5,94 Z M40.5,94 L46.5,94 C47.3284271,94 48,94.6715729 48,95.5 C48,96.3284271 47.3284271,97 46.5,97 L40.5,97 C39.6715729,97 39,96.3284271 39,95.5 C39,94.6715729 39.6715729,94 40.5,94 Z M52.5,94 L60.5,94 C61.3284271,94 62,94.6715729 62,95.5 C62,96.3284271 61.3284271,97 60.5,97 L52.5,97 C51.6715729,97 51,96.3284271 51,95.5 C51,94.6715729 51.6715729,94 52.5,94 Z M66.5,94 L70.5,94 C71.3284271,94 72,94.6715729 72,95.5 C72,96.3284271 71.3284271,97 70.5,97 L66.5,97 C65.6715729,97 65,96.3284271 65,95.5 C65,94.6715729 65.6715729,94 66.5,94 Z M76.5,94 L83.5,94 C84.3284271,94 85,94.6715729 85,95.5 C85,96.3284271 84.3284271,97 83.5,97 L76.5,97 C75.6715729,97 75,96.3284271 75,95.5 C75,94.6715729 75.6715729,94 76.5,94 Z M89.5,94 L93.5,94 C94.3284271,94 95,94.6715729 95,95.5 C95,96.3284271 94.3284271,97 93.5,97 L89.5,97 C88.6715729,97 88,96.3284271 88,95.5 C88,94.6715729 88.6715729,94 89.5,94 Z M99.5,94 L102.5,94 C103.328427,94 104,94.6715729 104,95.5 C104,96.3284271 103.328427,97 102.5,97 L99.5,97 C98.6715729,97 98,96.3284271 98,95.5 C98,94.6715729 98.6715729,94 99.5,94 Z"
            id="Shape"
            fill="#0078FA"
          />
        </g>
        <path
          d="M90,164 C135.287349,164 172,127.287349 172,82 C172,36.7126505 135.287349,0 90,0 C44.7126505,0 8,36.7126505 8,82 C8,127.287349 44.7126505,164 90,164 Z M90,158 C48.026359,158 14,123.973641 14,82 C14,40.026359 48.026359,6 90,6 C131.973641,6 166,40.026359 166,82 C166,123.973641 131.973641,158 90,158 Z"
          id="circle-stroke"
          fill="#DEDEDE"
          fillRule="nonzero"
        />
      </g>
    </svg>
  </div>
);

const stringWithoutMask = (string) => string && string.replace(/\D/g, "");

const ManualForm = ({ onClose, code, isLoading }) => {
  const {
    values,
    errors,
    initialValues,
    touched,
    isValid,
    status,
    setFieldValue,
  } = useFormikContext();
  const isReadOnly = initialValues.bar_code && initialValues.secure_code;
  const cesu = useCesu();

  const handleAdd = ({ bar_code, secure_code }) => {
    cesu.set((s) => [...s, { bar_code, secure_code }]);
    onClose();
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const formatedValue = stringWithoutMask(value);
    setFieldValue("bar_code", formatedValue);
  };

  const handleRemove = () => {
    cesu.set((t) => t.filter((t) => t.bar_code !== code.bar_code));
    onClose();
  };
  return (
    <Form>
      <Modal.Item.Wrapper>
        <Block display="flex" alignItems="center">
          <Field name="bar_code">
            {({ field }) => (
              <FormControl
                css="flex: 1"
                label={() => (
                  <Block display="flex" alignItems="center">
                    <span>{polyglot.t("cesu.ticket_number")}</span>
                    <Block display={{ xs: "block", sm: "none" }}>
                      <Info>{icon()}</Info>
                    </Block>
                  </Block>
                )}
                error={
                  (touched.bar_code || cesuCodeValidation(values.bar_code)) &&
                  errors.bar_code
                }
                hint={polyglot.t("cesu.ticket_number_description")}
              >
                <Input
                  {...field}
                  RightComponent={() => (
                    <>
                      {cesuCodeValidation(values.bar_code) && (
                        <Tag.Small
                          css={`
                            border-radius: 4px;
                          `}
                          kind={TAG.KIND.WHITE}
                          LeftComponent={() => (
                            <Icon.Medium name="ticket" color={colors.muted} />
                          )}
                        >
                          {polyglot.toSmartCurrency(
                            cesuGetAmount(values.bar_code)
                          )}
                        </Tag.Small>
                      )}
                    </>
                  )}
                  readOnly={initialValues.bar_code}
                  pattern="d*"
                  placeholderChar="_"
                  placeholder={polyglot.t("cesu.barecode_numbers_label")}
                  isLoading={isLoading}
                  autoComplete="new-bar-code"
                  inputMode="numeric"
                  type="text"
                  guide
                  mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]} // prettier-ignore
                  onChange={handleChange}
                />
              </FormControl>
            )}
          </Field>
          <Block display={{ xs: "none", sm: "block" }} marginLeft={spacings.m}>
            {icon()}
          </Block>
        </Block>

        <Block display="flex" alignItems="center" flex="1">
          <Field name="secure_code">
            {({ field }) => (
              <FormControl
                css="flex: 1"
                name="secure_code"
                error={touched?.secure_code && errors?.secure_code}
                label={() => (
                  <Block display="flex" alignItems="center">
                    <span>{polyglot.t("cesu.security_code")}</span>
                    <Block display={{ xs: "block", sm: "none" }}>
                      <Info>
                        <SecureScanIcon className="svg-barcode-secure" />
                      </Info>
                    </Block>
                  </Block>
                )}
                hint={polyglot.t("cesu.security_code_description")}
              >
                <Input
                  readOnly={initialValues.secure_code}
                  autoComplete="new-secure-code"
                  type="text"
                  placeholder="••••"
                  {...field}
                />
              </FormControl>
            )}
          </Field>

          <Block marginLeft={spacings.m} display={{ xs: "none", sm: "block" }}>
            <SecureScanIcon
              className={`${touched.bar_code ? "svg-barcode-secure" : ""}`}
            />
          </Block>
        </Block>
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        {isReadOnly ? (
          <Button.Large
            block
            accentColor={BUTTON.ACCENT_COLOR.DANGER}
            onClick={handleRemove}
          >
            {polyglot.t("common.remove")}
          </Button.Large>
        ) : (
          <Button.Large
            block
            onClick={() => handleAdd(values)}
            disabled={!isValid || !!status}
          >
            {polyglot.t("common.save")}
          </Button.Large>
        )}
        <Button.Large block kind={BUTTON.KIND.MINIMAL} onClick={onClose}>
          {polyglot.t("common.cancel")}
        </Button.Large>
      </Modal.Item.Footer>
    </Form>
  );
};

export default ManualForm;
