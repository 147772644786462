import { useFormikContext } from "formik";
import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Button, Col, Row } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import PaymentMethodsUpdated from "../common/payment-methods";
import { getPaymentSummaryItems } from "../common/payment-methods/utils/get-payment-summary-items";

const ConfirmActions = ({ pricing, isLoading, jobber, handleSubmit }) => {
  const { values, isValid } = useFormikContext();
  return (
    <>
      <Row justifyContent="center">
        <Col size={12}>
          <Block
            display="flex"
            justifyContent="flex-end"
            marginTop={spacings.m}
          >
            {!!values.nb_hours && pricing.total > 0 ? (
              <>
                <PaymentMethodsUpdated.PayButtonEnhancer
                  block
                  textType="pay"
                  disabled={!isValid}
                  amount={pricing.total}
                  lineItems={getPaymentSummaryItems(
                    pricing,
                    `${polyglot.t(
                      "common.extra_hours"
                    )} (+${polyglot.toDuration(values.nb_hours, true)})`
                  )}
                  isLoading={isLoading}
                  onPaymentAuthorized={({ token, session }) =>
                    handleSubmit({
                      ...values,
                      token,
                      applePaySession: session,
                    })
                  }
                >
                  <Button.Large
                    block
                    type="submit"
                    form="confirm-payment-form"
                    isLoading={isLoading}
                    disabled={!isValid}
                    kind={BUTTON.KIND.PRIMARY}
                  >
                    {polyglot.t("job_review.pay_n_and_confirm", {
                      n: polyglot.toSmartCurrency(pricing.total),
                    })}
                  </Button.Large>
                </PaymentMethodsUpdated.PayButtonEnhancer>
              </>
            ) : (
              <Button.Large
                block
                type="submit"
                form="confirm-payment-form"
                isLoading={isLoading}
                kind={BUTTON.KIND.PRIMARY}
              >
                {polyglot.t("job_review.confirm_payment")}
              </Button.Large>
            )}
          </Block>
          <Body14
            color={colors.muted}
            align="center"
            css={`
              margin-top: ${spacings.s};
            `}
          >
            {polyglot.t("job_review.you_confirm_first_name_has_done_the_job", {
              first_name: jobber.first_name,
            })}
          </Body14>
        </Col>
      </Row>
    </>
  );
};

export default ConfirmActions;
