import React, { useState } from "react";
import SelectZipCode from "./common/addressModal/SelectZipCode";

const ZipCodeInput = ({ name, value: _value, ...rest }) => {
  const [value, setValue] = useState(_value);

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <>
      <SelectZipCode
        onChange={handleChange}
        name="zip-name"
        value={value}
        {...rest}
      />
      <input name={name} value={value.value || ""} hidden readOnly />
    </>
  );
};

export default ZipCodeInput;
