import polyglot from "../../../utils/polyglot";
import { NB_HOURS_PATH } from "../constants";

export function useCategory2013() {
  const initialValues = { nb_hours: 2 };

  return {
    version: 1.1,
    initialValues,
    config: {
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
        newPages[nbHoursIndex].title = polyglot.t(
          "categories.how_many_hours_of_service_do_you_need"
        );
        return newPages;
      },
    },
  };
}
