import {
  ERROR_FORM,
  SUCCEED_FORM,
  USE_NEW_CREDIT_CARD
} from '../../../utils/types';

export default function FormReducer(state = [], action) {
  switch (action.type) {
    case SUCCEED_FORM:
      return { succeed: action.payload };
    case ERROR_FORM:
      return { error: action.payload };
  }
  return state;
}
