import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../utils";
import { useFetch } from "../hooks";

const getKey = ({ id, kind }) => `/kiss/notes/${id}/index_${kind}`;

export const useGetAdminNotes = ({ id, kind }) =>
  useFetch(`/kiss/notes/${id}/index_${kind}`);

export const usePostAdminNote = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (values) =>
      api.post(`/kiss/notes/${values.id}/create_${values.kind}`, {
        note: values.note,
      }),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData([getKey(variables)], data.data);
      },
    }
  );
};
