const config = {
  multipleJobbersAllowed: true,
  isCustom: true,
  initialTitleIsEmpty: true,
};

export function useCategory1101() {
  const nhConfig = {};

  return {
    nhConfig,
    config,
  };
}
