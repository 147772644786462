import { spacings } from "../../../../assets/themes";
import { useBreakpoints } from "../../../../modules/hooks";
import { H1, H2 } from "../../../Text";

const Title = ({ children, ...rest }) => {
  const breakpoints = useBreakpoints();
  return breakpoints.get({
    xs: (
      <H2 {...rest} style={{ marginBottom: spacings.xs }}>
        {children}
      </H2>
    ),
    sm: (
      <H1 {...rest} style={{ marginBottom: spacings.xs }}>
        {children}
      </H1>
    ),
  });
};

export default Title;
