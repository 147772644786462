import { sizes } from "@/assets/themes";
import { Block, Skeleton } from "@/components";

const TaxesCreditSkeleton = () => (
  <Skeleton.Group>
    <Block display="flex" alignItems="center" flexDirection="column">
      <Skeleton height="120px" width="100%" sb />
      <Skeleton height={sizes.size20} width="80%" sb st />
      <Skeleton height="120px" width="100%" sb />
      <Skeleton height={sizes.size24} width="100%" rows={3} sb st />
      <Skeleton height={sizes.size48} width="100%" sb st />
      <Skeleton height={sizes.size48} width="100%" sb />
      <Skeleton height={sizes.size48} width="100%" sb />
    </Block>
  </Skeleton.Group>
);

export default TaxesCreditSkeleton;
