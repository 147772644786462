import React from "react";
import { colors, sizes } from "../../../assets/themes";
import { Block, Icon, List } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { getPaymentMethodBadge } from "./utils";

const AddCreditCard = ({ onClick, ...rest }) => (
  <List.Item
    onClick={onClick}
    chevron={false}
    RightComponent={() => <Icon.Medium name="plus" color={colors.muted} />}
    LeftComponent={() => (
      <Block width={sizes.size32} display="flex" justifyContent="center">
        <img src={getPaymentMethodBadge(null)} alt="" />
      </Block>
    )}
    {...rest}
  >
    {polyglot.t("payment_methods.add_credit_card")}
  </List.Item>
);

export default AddCreditCard;
