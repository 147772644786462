import { Link as RLink } from "react-router-dom";
import styled, { css } from "styled-components";
import { colors } from "../assets/themes";
import { Body16 } from "./Text";

const StyledLink = styled.a`
  ${({ color, unstyled }) => css`
    color: ${unstyled ? "inherit" : color};
    text-decoration-color: ${unstyled ? "inherit" : color};
    text-decoration: ${unstyled ? "inherit" : undefined};
    &:hover {
      color: ${unstyled ? "inherit" : color};
      text-decoration: ${unstyled ? "inherit" : "underline"};
    }
  `}
`;

const Link = ({
  children,
  href,
  to,
  as,
  onClick,
  color = colors.primary,
  numberOfLines,
  unstyled,
  ...rest
}) => (
  <StyledLink
    as={to ? RLink : as}
    to={to}
    href={href}
    color={color}
    onClick={onClick}
    unstyled={unstyled}
    {...rest}
  >
    <Body16 strong numberOfLines={numberOfLines}>
      {children}
    </Body16>
  </StyledLink>
);

export default Link;
