import { borderWidth, colors } from "../../assets/themes";
import { LIST } from "../../components/Styles/variants";
import JobberOfferItem from "../dashboard/jobs/JobberOfferItem";

const ReviewJobCard = ({ offer_amount, jobber, state }) => (
  <JobberOfferItem
    price={offer_amount}
    state={state}
    shape={LIST.SHAPE.ROUND}
    jobber={jobber}
    divider={false}
    hideDetails
    withGutters
    css={`
      border: solid ${borderWidth.s} ${colors.border};
    `}
  />
);

export default ReviewJobCard;
