import { useFormikContext } from "formik";
import polyglot from "../../../utils/polyglot";
import { MAX_NB_JOBBERS, MIN_NB_JOBBERS } from "../constants";
import StepTitle from "./StepTitle";
import { FormLargeStepper, StyledFormControl } from "./fields";

const NbJobbers = ({ title }) => {
  const { values } = useFormikContext();
  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <FormLargeStepper
          divider={false}
          name="nb_jobbers"
          min={MIN_NB_JOBBERS}
          max={MAX_NB_JOBBERS}
          hint={
            values.nb_jobbers > 1
              ? polyglot.t("categories.nb_hours_each_jobber_time", {
                  time: polyglot.toDuration(values.nb_hours, true),
                })
              : null
          }
        />
      </StyledFormControl>
    </>
  );
};

export default NbJobbers;
