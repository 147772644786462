import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import { spacings } from "../assets/themes";
import { Block, Flow } from "../components";
import { useOnMount } from "../modules/hooks";
import { postRelation } from "../modules/routes";
import {
  addressValidation,
  paymentValidation,
  phoneRegex,
  setAddressInitialValues,
  setPaymentInitialValues,
} from "../utils";
import {
  AdditionnalInformations,
  AnimationLoader,
  Confirmation,
  Plans,
  SelectJobber,
  SelectKnownJobber,
  Services,
  SetFrequency,
  SetJobberPhone,
  SetNbHours,
  SetPricePerHour,
  SetTaxes,
} from "./newRelation/";
import SetJobberEmail from "./newRelation/SetJobberEmail";
import SetJobberFirstName from "./newRelation/SetJobberFirstName";

const NewRelation = ({
  services,
  jobbers,
  addresses,
  card_number,
  card_type,
  default_payment_method,
  payment_methods,
  mandate_iban,
  mandate_url,
  monthly_nb_hours_max,
  monthly_nb_hours_min,
  price_per_hour_max,
  price_per_hour_min,
  price_per_hour_recommended,
  weekly_nb_hours_max,
  weekly_nb_hours_min,
  user,
  values,
  error_message,
  tax_deductions: unenhanced_tax_deduction,
}) => {
  const tax_deductions = [
    { translation: "Aucune exonération spécifique", key: null },
    ...unenhanced_tax_deduction,
  ];

  const formikRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const steps = [
    {
      path: "jobber-selection",
      View: SelectKnownJobber,
      customProps: { jobbers },
      validationSchema: yup.object().shape({
        jobber_id: yup.number(),
      }),
    },
    {
      path: "phone-number",
      View: SetJobberPhone,
      customProps: { jobbers },
      validationSchema: yup.object().shape({
        external_jobber: yup.object({
          phone: yup.string().min(10).matches(phoneRegex).required(),
        }),
      }),
    },
    {
      path: "jobber",
      View: SelectJobber,
      validationSchema: yup.object().shape({
        jobber_id: yup.number(),
      }),
    },
    {
      path: "email",
      View: SetJobberEmail,
      validationSchema: yup.object().shape({
        external_jobber: yup.object({
          email: yup.string().email().required(),
        }),
      }),
    },
    {
      path: "first_name",
      View: SetJobberFirstName,
      validationSchema: yup.object().shape({
        external_jobber: yup.object({ first_name: yup.string().required() }),
      }),
    },
    {
      path: "services",
      View: Services,
      customProps: { services },
      validationSchema: yup.object().shape({
        services: yup.array().of(yup.string().required()).min(1),
      }),
    },
    {
      path: "frequency",
      View: SetFrequency,
      customProps: {},
      validationSchema: yup.object().shape({
        frequency: yup.string().oneOf(["weekly", "monthly"]).required(),
      }),
    },
    {
      path: "price-per-hour",
      View: SetPricePerHour,
      customProps: { price_per_hour_max, price_per_hour_min },
      validationSchema: yup.object().shape({
        price_per_hour: yup
          .number()
          .min(price_per_hour_min)
          .max(price_per_hour_max)
          .required(),
      }),
    },
    {
      path: "nb-hours",
      View: SetNbHours,
      customProps: {
        monthly_nb_hours_max,
        monthly_nb_hours_min,
        weekly_nb_hours_max,
        weekly_nb_hours_min,
      },
      validationSchema: yup.object().shape({
        nb_hours: yup.number().when("frequency", {
          is: "monthly",
          then: yup
            .number()
            .min(monthly_nb_hours_min)
            .max(monthly_nb_hours_max)
            .required(),
          otherwise: yup
            .number()
            .min(weekly_nb_hours_min)
            .max(weekly_nb_hours_max)
            .required(),
        }),
      }),
    },
    ...(!formikRef.current?.values?.jobber?.is_pro
      ? {
          path: "taxes",
          View: SetTaxes,
          customProps: { user, tax_deductions },
          validationSchema: yup.object().shape({
            urssaf: yup.boolean().oneOf([true, false]).required(),
          }),
        }
      : null),
    {
      path: "additionnal-informations",
      title: `Finaliser vos conditions`,
      View: AdditionnalInformations,
      customProps: { addresses, user, tax_deductions },
      validationSchema: yup.object().shape(
        {
          weekly_payment_day: yup.string().when("frequency", {
            is: "weekly",
            then: yup.string().required(),
            otherwise: yup.string().nullable(),
          }),
          monthly_payment_day: yup.number().when("frequency", {
            is: "monthly",
            then: yup.number().required(),
            otherwise: yup.number().nullable(),
          }),
          first_payment_date: yup.string().required(),
          cesu: yup.boolean().required(),
          phone: yup.string().matches(phoneRegex).required(),
          ...addressValidation,
        },
        [
          ["place_id", "address_id"],
          ["address_name", "city_id"],
        ]
      ),
    },
    {
      path: "plans",
      title: `Quelle offre vous convient le mieux ?`,
      View: Plans,
      customProps: {},
      validationSchema: yup.object().shape({}),
    },
    {
      path: "checking",
      View: AnimationLoader,
      validationSchema: yup.object().shape({}),
    },
    {
      path: "confirmation",
      title: "Tout est prêt !",
      View: Confirmation,
      customProps: { services, addresses, jobbers, user, isLoading },
      validationSchema: yup
        .object()
        .shape({ ...paymentValidation, voucher_code: yup.string() }),
    },
  ].filter((a) => a);

  const getInitialStep = () => {
    if (values.frequency) {
      return steps.length - 1 || 0;
    }
    if (jobbers.length === 0) {
      return 1;
    }
    return 0;
  };
  const [current, setCurrent] = useState(getInitialStep());

  useEffect(() => {
    formikRef.current?.validateForm();
  }, [current]);

  const handleStepChange = (index) => {
    setCurrent(index);
  };

  const handleSubmit = (values) => {
    setIsLoading(true);
    const res = postRelation(values);
    res
      .then(({ data }) => {
        if (data.redirect_url) window.location = data.redirect_url;
      })
      .catch((error) => {
        formikRef.current.setStatus({
          error: error.response?.data?.error_message,
        });
        setIsLoading(false);
      });
  };

  useOnMount(() => {
    if (error_message) formikRef.current.setStatus({ error: error_message });
  });

  const initialValues = {
    jobber_id: null,
    selected_jobber: null,
    external_jobber: { phone: "", email: "", first_name: "" },
    price_per_hour: price_per_hour_recommended,
    nb_hours: monthly_nb_hours_min,
    frequency: "",
    monthly_payment_day: "",
    weekly_payment_day: "",
    phone: user?.phone || "",
    services: [],
    urssaf: undefined,
    first_payment_date: "",
    voucher_code: "",
    mandate_iban,
    mandate_url,
    default_payment_method,
    payment_methods,
    cesu: false,
    jobber: {
      email: "",
      first_name: "",
      phone: "",
      ...values.jobber,
    },
    plan: "",
    ...setAddressInitialValues({
      address: addresses[0]?.address || "",
      address_id: addresses[0]?.id || "",
    }),
    ...setPaymentInitialValues({
      payment_method: default_payment_method,
      card_number,
      card_type,
    }),
    ...values,
  };

  return (
    <>
      <Block marginY={{ xs: spacings.l, md: spacings.xl }}>
        <Formik
          validateOnMount
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={yup.lazy(() => steps[current]?.validationSchema)}
        >
          <Form id="flow-router">
            <Flow.Router
              onChange={handleStepChange}
              routes={steps}
              initialStep={getInitialStep()}
            />
          </Form>
        </Formik>
      </Block>
    </>
  );
};

export default NewRelation;
