import { useTrustBox } from "../../modules/hooks";

const TrustBox = () => {
  const TrustBox = useTrustBox({
    "data-template-id": "5419b637fa0340045cd0c936",
    "data-style-height": "20px",
    "data-style-width": "100%",
  });
  return <div>{TrustBox}</div>;
};
export default TrustBox;
