import { colors } from "../../../assets/themes";
import {
  Body14,
  Body16,
  ConditionnalWrapper,
  Link,
  List,
} from "../../../components";

const TicketNewJobItem = ({ job, onChange, divider, withGutters }) => (
  <List.Item
    withGutters={withGutters}
    chevron={!!onChange}
    divider={divider}
    onClick={
      onChange
        ? () => {
            if (onChange) onChange(job);
          }
        : null
    }
  >
    <ConditionnalWrapper
      condition={!onChange}
      wrapper={(children) => (
        <Link href={job.url} color={colors.body} target="_blank">
          {children}
        </Link>
      )}
    >
      <Body16>{job.title}</Body16>
    </ConditionnalWrapper>
    <Body14 color={colors.muted}>{job.datetime}</Body14>
  </List.Item>
);
export default TicketNewJobItem;
