import { borderWidth, colors, spacings } from "@/assets/themes";
import List from "./List";
import { LIST, TAG } from "./Styles/variants";
import Block from "./Block";
import Info from "./Info";
import Tag from "./Tag";
import polyglot from "@/utils/polyglot";
import Icon from "./Icon";
import { Body16 } from "./Text";
import Toggle from "./Toggle";
import CESU_ICON from "@/assets/images/svg-icons/Badges/Payments/CESU.svg";
import { PAYMENT_METHOD_ICON_WIDTH } from "@/pages/common//payment-methods/constants";

const CesuItem = ({ eligibility, amount, onChange, checked, ...rest }) => {
  const disabled = eligibility !== "eligible" || amount === 0;

  return (
    <List.Item
      shape={LIST.SHAPE.ROUND}
      css={`
        border: solid ${borderWidth.s} ${colors.border};
        margin-top: ${spacings.sm};
      `}
      onClick={!disabled ? onChange : null}
      RightComponent={() => (
        <Block display="flex" alignItems="center">
          <Block marginRight={spacings.s}>
            {eligibility !== "eligible" ? (
              <Info
                render={() => (
                  <Tag.Small
                    kind={TAG.KIND.WHITE}
                    RightComponent={() => (
                      <Icon.Small name="info-circle-solid" />
                    )}
                  >
                    {polyglot.t("bookin.not_eligible")}
                  </Tag.Small>
                )}
              >
                {eligibility === "not_eligible_category" &&
                  polyglot.t("booking.cesu_not_eligible_category")}
                {eligibility === "not_eligible_jobber_pro" &&
                  polyglot.t("booking.cesu_not_eligible_jobber_pro")}
              </Info>
            ) : (
              <Body16 color={colors.muted}>
                {polyglot.toSmartCurrency(amount)}
              </Body16>
            )}
          </Block>
          <Toggle checked={checked} disabled={disabled} readOnly />
        </Block>
      )}
      LeftComponent={() => (
        <img
          src={CESU_ICON}
          alt="Cesu"
          css={`
            width: ${PAYMENT_METHOD_ICON_WIDTH}px;
          `}
        />
      )}
      {...rest}
    >
      <Body16 color={disabled && colors.mutedLight}>
        {polyglot.t("common.cesu")}
      </Body16>
    </List.Item>
  );
};
export default CesuItem;
