import { useSelector } from "react-redux";
import analytics from "../../utils/analytics";

export default function useGoogleAnalytics() {
  const { current_admin, isConcierge, isSav } = useSelector(
    (state) => state.rails
  );
  const isEnabled = !current_admin && !isConcierge && !isSav;

  return {
    sendEvent: (payload) => {
      if (isEnabled) analytics.sendEvent(payload);
    },
  };
}
