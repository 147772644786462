import { animate } from "framer-motion";
import { useEffect, useRef } from "react";

const CountUp = ({ from, to, duration = 1, decimals, delay, onComplete }) => {
  const nodeRef = useRef();

  useEffect(() => {
    const node = nodeRef.current;

    const controls = animate(from, to, {
      duration,
      delay,
      onComplete,
      ease: "easeOut",
      onUpdate(value) {
        node.textContent = value.toFixed(decimals).replace(".", ",");
      },
    });

    return () => controls.stop();
  }, [from, to]);

  return <span ref={nodeRef} />;
};

export default CountUp;
