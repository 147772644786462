import { useFormikContext } from "formik";
import { Block, Body16, List, Radio } from "../../../components";
import { PAYMENT_METHOD_ICON_WIDTH } from "./constants";
import { getPaymentMethodBadge, getPaymentMethodConfig } from "./utils";

const ExternalMethod = ({ onChange, disabled, name, children, ...rest }) => {
  const { values } = useFormikContext();
  return (
    <List.Item
      withGutters
      onClick={() =>
        onChange({
          payment_method: name,
        })
      }
      disabled={disabled}
      LeftComponent={() => (
        <Radio checked={values.payment_method === name && !disabled} />
      )}
      RightComponent={() => (
        <Block display="flex" justifyContent="center">
          <img
            src={getPaymentMethodBadge(name)}
            alt={name}
            css={`
              width: ${PAYMENT_METHOD_ICON_WIDTH}px;
            `}
          />
        </Block>
      )}
      {...rest}
    >
      <Body16>{getPaymentMethodConfig(name).label}</Body16>
      {children}
    </List.Item>
  );
};

export default ExternalMethod;
