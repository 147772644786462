import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { spacings } from "../../assets/themes";
import { Modal, Input, Button, Block, FormControl } from "../../components";
import polyglot from "@/utils/polyglot";

const AddVoucherModal = ({ isOpen, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(false);
  }, [isOpen]);
  return (
    <Modal.Small isOpen={isOpen} onClose={onClose} fullScreenOnMobile>
      <Modal.Item.Header
        onClose={onClose}
        title={polyglot.t("common.add_voucher_code")}
      />
      <Modal.Item.Wrapper>
        <Formik
          onSubmit={(values) => {
            setIsLoading(true);
            onSubmit({ code: values?.code?.toUpperCase() });
          }}
          validateOnMount
          initialValues={{ code: "" }}
          validationSchema={yup
            .object()
            .shape({ code: yup.string().required() })}
        >
          {({ isValid, dirty }) => (
            <Form>
              <FormControl label={polyglot.t("common.voucher_code")}>
                <Field name="code">
                  {({ field }) => (
                    <Input
                      placeholder="CODE10"
                      autoComplete="prevent-autocomplete"
                      {...field}
                    />
                  )}
                </Field>
              </FormControl>
              <Block marginTop={spacings.s}>
                <Button.Large
                  block
                  className="mt-auto"
                  disabled={!isValid && !dirty}
                  type="submit"
                  isLoading={isLoading}
                >
                  {polyglot.t("common.validate")}
                </Button.Large>
                <Button.Large
                  block
                  className="mt-2"
                  kind="minimal"
                  onClick={onClose}
                >
                  {polyglot.t("common.cancel")}
                </Button.Large>
              </Block>
            </Form>
          )}
        </Formik>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default AddVoucherModal;
