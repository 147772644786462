import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { colors } from "../../assets/themes";
import { Body16, Button, Icon, List, Modal, Shape } from "../../components";
import { ButtonBase } from "../../components/Styles/Base";
import { BUTTON, SHAPE } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import { useToast } from "@/modules/hooks";

const GmailIcon = () => (
  <Shape.Small
    shape={SHAPE.SHAPE.CIRCLE}
    backgroundColor={colors.background}
    css={`
      border: solid 1px ${colors.border};
    `}
  >
    <svg
      style={{ width: 24, height: 24 }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="52 42 88 66"
    >
      <path fill="#4285f4" d="M58 108h14V74L52 59v43c0 3.32 2.69 6 6 6" />
      <path fill="#34a853" d="M120 108h14c3.32 0 6-2.69 6-6V59l-20 15" />
      <path fill="#fbbc04" d="M120 48v26l20-15v-8c0-7.42-8.47-11.65-14.4-7.2" />
      <path fill="#ea4335" d="M72 74V48l24 18 24-18v26L96 92" />
      <path
        fill="#c5221f"
        d="M52 51v8l20 15V48l-5.6-4.2c-5.94-4.45-14.4-.22-14.4 7.2"
      />
    </svg>
  </Shape.Small>
);

const ShareItem = ({ LeftComponent, title, onClick }) => (
  <List.Item LeftComponent={LeftComponent} onClick={onClick} href="#">
    <Body16 strong>{title}</Body16>
  </List.Item>
);

const GmailShareButton = ({ children, subject, body, url }) => {
  const height = 500;
  const width = 500;

  const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;

  return (
    <ButtonBase
      css=""
      onClick={() =>
        window.open(
          `https://mail.google.com/mail/?view=cm&ui=2&tf=0&fs=1&to=""&su=${subject}&body=${body.replace(
            /(?:\r\n|\r|\n)/g,
            "%0A"
          )}${url}`,
          "_blank",
          `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
        )
      }
    >
      {children}
    </ButtonBase>
  );
};

const ShareModal = ({
  onClose,
  isOpen,
  referralUrl,
  phrase,
  phraseObject = "",
  copySuccessPhrase = polyglot.t("common.copy_success"),
}) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(referralUrl);
    if (copySuccessPhrase) {
      toast.remove();
      toast.success(copySuccessPhrase);
    }
  };

  return (
    <Modal.XSmall onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("referral.share_with")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        {/* Email */}
        <EmailShareButton
          subject={phraseObject}
          body={phrase}
          url={referralUrl}
        >
          <ShareItem
            LeftComponent={() => (
              <Shape.Small
                shape={SHAPE.SHAPE.CIRCLE}
                backgroundColor={colors.body}
                color={colors.onColor}
              >
                <Icon.Large name="envelope" />
              </Shape.Small>
            )}
            title={polyglot.t("common.email")}
          />
        </EmailShareButton>

        {/* Facebook */}
        <FacebookShareButton quote={phrase} url={referralUrl}>
          <ShareItem
            LeftComponent={() => <FacebookIcon size={36} round />}
            title="Facebook"
          />
        </FacebookShareButton>

        {/* Twitter */}
        <TwitterShareButton title={phrase} url={referralUrl}>
          <ShareItem
            LeftComponent={() => <TwitterIcon size={36} round />}
            title="Twitter"
          />
        </TwitterShareButton>

        {/* Messenger */}
        <FacebookMessengerShareButton
          appId={document.querySelector("meta[property='fb:app_id']").content}
          url={referralUrl}
          redirectUri={referralUrl}
        >
          <ShareItem
            LeftComponent={() => <FacebookMessengerIcon size={36} round />}
            title="Messenger"
          />
        </FacebookMessengerShareButton>

        {/* Whatapps */}
        <WhatsappShareButton title={phrase} url={referralUrl}>
          <ShareItem
            LeftComponent={() => <WhatsappIcon size={36} round />}
            title="Whatsapp"
          />
        </WhatsappShareButton>

        {/* Gmail */}
        <GmailShareButton
          body={phrase}
          subject={phraseObject}
          url={referralUrl}
        >
          <ShareItem
            LeftComponent={() => <GmailIcon size={36} round />}
            title="Gmail"
          />
        </GmailShareButton>

        {/* Copy */}
        <ShareItem
          onClick={handleCopy}
          LeftComponent={() => (
            <Shape.Small
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.backgroundGray}
              color={colors.body}
            >
              <Icon.Large name="copy" />
            </Shape.Small>
          )}
          title={polyglot.t("common.copy_link")}
        />
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large kind={BUTTON.KIND.SECONDARY} block onClick={onClose}>
          {polyglot.t("common.close")}
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.XSmall>
  );
};
export default ShareModal;
