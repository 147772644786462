import { useEffect, useState } from "react";
import styled from "styled-components";
import { colors, sizes, spacings } from "../assets/themes";
import Block from "./Block";
import Button from "./Button";
import Icon from "./Icon";
import { BUTTON, STEPPER } from "./Styles/variants";
import { Body14, Display3, H4 } from "./Text";

const StyledChildrenWrapper = styled.div`
  margin-left: ${spacings.s};
  margin-right: ${spacings.s};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: ${sizes.size64};
`;

const EnhancedChildren = ({ children, size, ...rest }) => (
  <>
    {size === STEPPER.SIZE.LARGE && (
      <Block marginX={spacings.m}>
        <Display3 {...rest}>{children}</Display3>
      </Block>
    )}
    {size === STEPPER.SIZE.MEDIUM && <H4 {...rest}>{children}</H4>}
  </>
);

const StyledEnhancedChildren = styled(EnhancedChildren)`
  text-wrap: nowrap;
`;

const StepperButton = ({ icon, size, ...rest }) => (
  <>
    {size === STEPPER.SIZE.LARGE && (
      <Button.Large shape={BUTTON.SHAPE.CIRCLE} {...rest}>
        <Icon.XLarge name={icon} />
      </Button.Large>
    )}
    {size === STEPPER.SIZE.MEDIUM && (
      <Button.Small shape={BUTTON.SHAPE.CIRCLE} {...rest}>
        <Icon.Large name={icon} />
      </Button.Small>
    )}
  </>
);

const Stepper = ({
  value = 0,
  max = 9999,
  min = 0,
  hint,
  suffix,
  prefix,
  onChange,
  step = 1,
  name,
  size = STEPPER.SIZE.MEDIUM,
  children,
  disabled,
  className,
  renderMinButton,
  renderPlusButton,
}) => {
  const [currValue, setCurrValue] = useState(value);
  const canPlus = currValue < max;
  const canMinus = currValue > min;
  useEffect(() => {
    setCurrValue(value);
  }, [value]);

  const handleChange = (additionner) => {
    const newVal = currValue + additionner;
    setCurrValue(newVal);
    if (onChange) {
      onChange(newVal);
    }
  };

  const StepperMinButtonProps = {
    disabled: !canMinus || disabled,
    onClick: () => handleChange(-step),
    icon: "minus",
    size,
  };
  const StepperButtonMin = () =>
    renderMinButton
      ? renderMinButton({
          ...StepperMinButtonProps,
          Component: () => StepperButton(StepperMinButtonProps),
        })
      : StepperButton(StepperMinButtonProps);

  const StepperPlusButtonProps = {
    disabled: !canPlus || disabled,
    onClick: () => handleChange(step),
    icon: "plus",
    size,
  };
  const StepperButtonPlus = () =>
    renderPlusButton
      ? renderPlusButton({
          ...StepperPlusButtonProps,
          Component: () => StepperButton(StepperPlusButtonProps),
        })
      : StepperButton(StepperPlusButtonProps);

  return (
    <Block
      display="flex"
      alignItems="center"
      flexDirection="column"
      className={className}
    >
      <Block
        display="flex"
        alignItems="center"
        aria-valuemax={max}
        aria-valuemin={min}
        aria-valuenow={value}
        role="spinbutton"
      >
        <StepperButtonMin />
        <StyledChildrenWrapper>
          <StyledEnhancedChildren align="center" strong size={size}>
            <input name={name} hidden value={currValue} readOnly />
            {prefix && `${prefix} `}
            {children || currValue}
            {suffix && ` ${suffix}`}
          </StyledEnhancedChildren>
        </StyledChildrenWrapper>
        <StepperButtonPlus />
      </Block>
      {hint && (
        <>
          {typeof hint === "function" ? (
            hint()
          ) : (
            <Body14
              align="center"
              color={colors.muted}
              css={`
                width: 100%;
                margin-top: ${spacings.xs};
              `}
            >
              {hint}
            </Body14>
          )}
        </>
      )}
    </Block>
  );
};

export default Stepper;
