import { useLocation } from "react-router-dom";
import { Block, InfiniteScrollEnhancer, List } from "@/components";
import { useGetInfiniteHomeCategory } from "@/modules/routes/dashboard-routes";
import DiscoverSkeleton from "../skeletons/DiscoverSkeleton";
import CategoriesHorizontal from "./Blocks/CategoriesHorizontal";
import JobbersGrid from "./Blocks/JobbersGrid";

const HomeCategory = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currTabId = Number(params.get("category"));
  const homeCategory = useGetInfiniteHomeCategory(currTabId);

  return (
    <InfiniteScrollEnhancer
      query={homeCategory}
      unionBy="jobbers"
      enableScrollRestauration
    >
      {({ isLoading, data }) =>
        !isLoading ? (
          <Block>
            {data.categories?.length > 0 && (
              <>
                <List.Header>{data.categories_title}</List.Header>
                <CategoriesHorizontal
                  block={{ category_id: currTabId, data: data.categories }}
                />
              </>
            )}
            {data.jobbers?.length > 0 && (
              <>
                <List.Header>{data.jobbers_title}</List.Header>
                <JobbersGrid
                  block={{ data: data.jobbers }}
                  hideJobbersSeeMore
                />
              </>
            )}
          </Block>
        ) : (
          <DiscoverSkeleton />
        )
      }
    </InfiniteScrollEnhancer>
  );
};

export default HomeCategory;
