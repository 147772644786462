import { Form, Formik } from "formik";
import { useCallback, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Body14, Body16, Divider, Icon, List, Spinner } from "../../components";
import Block from "../../components/Block";
import { ChatContext } from "../../modules/contexts";
import {
  useCreateDateTimeRequest,
  useGetMessages,
  usePostChatMessage,
} from "../../modules/routes/dashboard-routes";
import ConversationHeader from "../dashboard/ConversationHeader";
import EditDateTime from "../EditDateTime";
import ChatBoxInput from "./ChatBoxInput";
import Messages from "./Messages";
import { useChatTempMessage } from "@/modules/hooks";
import { uuid } from "@/utils";
import polyglot from "@/utils/polyglot";
import { colors, spacings } from "@/assets/themes";

const ChatBox = () => {
  const { id } = useParams();
  const targetRef = useRef();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { data, isLoading } = useGetMessages({ id });
  const canEditDateTime = !(
    !data?.date_time_changeable || data?.date_time_change_pending
  );
  const createDateTime = useCreateDateTimeRequest({
    onClose: () => setModalIsOpen(false),
    discussionId: id,
    id: !isLoading && data?.discussable_id,
  });
  const chatTempMessages = useChatTempMessage(id);
  const createMessage = usePostChatMessage();

  const handleSubmit = (values, bag) => {
    let acc = 1;
    // body
    if (values.body) {
      acc = 1;
      const tempId = uuid();
      const formData = new FormData();
      formData.append("body", values.body);
      formData.append("temp_id", tempId);
      formData.append("upload_timestamp", Date.now() / 1000 + acc);
      chatTempMessages.set(formData);
      createMessage.mutate(formData);
    }
    // files
    if (values.uploads.length > 0) {
      values.uploads.forEach((upload, index) => {
        acc = 2;
        const tempId = uuid();
        const formData = new FormData();
        formData.append("file", upload);
        formData.append("temp_id", tempId);
        formData.append("upload_timestamp", Date.now() / 1000 + acc + index);
        chatTempMessages.set(formData);
        createMessage.mutate(formData);
      });
    }
    // audios
    if (values.file) {
      acc = 3;
      const tempId = uuid();
      const formData = new FormData();
      formData.append("file", values.file);
      formData.append("temp_id", tempId);
      formData.append("upload_timestamp", Date.now() / 1000 + acc);
      if (values.audio_amplitudes)
        formData.append(
          "audio_amplitudes",
          JSON.stringify(values.audio_amplitudes)
        );
      if (values.audio_duration)
        formData.append("audio_duration", values.audio_duration);
      chatTempMessages.set(formData);
      createMessage.mutate(formData);
    }
    bag.resetForm();
  };

  const renderAlert = useCallback(() => {
    const msgOnly = chatTempMessages
      .get()
      .filter((message) => message.kind === "message");
    const noMessagesFromOthers =
      msgOnly.filter(
        (message) => !message.user.is_lucy && !message.user.is_current_user
      ).length === 0;
    const shouldShowAlert =
      noMessagesFromOthers && msgOnly[0]?.user?.is_current_user;
    const isMultipleJobbers = data?.users.length > 1;
    const texts = {
      multiple: {
        title: polyglot.t("chat.bot_answer.title.multiple"),
        body: polyglot.t("chat.bot_answer.body.multiple"),
      },
      single: {
        title: polyglot.t("chat.bot_answer.title.single", {
          first_name: data?.users[0].first_name,
        }),
        body: polyglot.t("chat.bot_answer.body.single", {
          first_name: data?.users[0].first_name,
        }),
      },
    };
    const getTexts = () => (isMultipleJobbers ? texts.multiple : texts.single);
    return shouldShowAlert ? (
      <>
        <List.Item
          divider={false}
          withGutters
          LeftComponent={() => <Icon.Large name="clock" />}
        >
          <Body16 strong>{getTexts().title}</Body16>
          <Body14>{getTexts().body}</Body14>
        </List.Item>
        <Divider.Cell />
      </>
    ) : null;
  }, [chatTempMessages?.get(), data?.users]);

  return (
    <>
      {!isLoading && data ? (
        <>
          <Block
            display="flex"
            alignItems="stretch"
            flexDirection="column"
            height="100%"
            key={`message-id-${data.messages.id}`}
          >
            <ChatContext.Provider
              value={{
                openDateTimeChange: () => setModalIsOpen(true),
                closeDateTimeChange: () => setModalIsOpen(false),
                canEditDateTime,
              }}
            >
              <ConversationHeader
                users={data.users}
                title={data.discussable_title}
                url={data.discussable_url}
                discussable_id={data.discussable_id}
              />
              {chatTempMessages?.get() &&
                data?.users?.length > 0 &&
                data?.show_message_form &&
                renderAlert()}
              <div ref={targetRef} />
              <Messages {...data} messages={chatTempMessages.get()} />
              <Formik
                onSubmit={handleSubmit}
                initialValues={{
                  body: "",
                  file: null,
                  uploads: [],
                  audio_amplitudes: null,
                  audio_duration: null,
                }}
              >
                <Form>
                  <ChatBoxInput disabled={!data.show_message_form} />
                </Form>
              </Formik>
            </ChatContext.Provider>
          </Block>
          {canEditDateTime && modalIsOpen && (
            <EditDateTime
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
              query={createDateTime}
              isBooked
              discussable_title={data.discussable_title}
              discussable_id={data.discussable_id}
              job_date={data.job_date}
            />
          )}
        </>
      ) : (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
          height="100%"
        >
          <Spinner.Medium />
        </Block>
      )}
    </>
  );
};

export default ChatBox;
