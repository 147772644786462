import polyglot from "../../utils/polyglot";

export const JOB_REPOSTABLE = "job_repostable";
export const JOB_NOT_REPOSTABLE = "job_not_repostable";
export const JOB_REOPENABLE = "job_reopenable";

export const CANCELLATION_ACTION_CHANGE_JOBBER = "changeJobber";
export const CANCELLATION_ACTION_CANCEL_JOB = "cancel_job";
export const CANCELLATION_ACTION_EDIT_DATETIME = "editDateTime";
export const CANCELLATION_ACTION_CANCEL_JOB_BUTTON = "cancel_job_button";

export const POSTER_NEEDS_NEW_DATE = "poster_needs_new_date";

export const CANCELLATION_REASONS = [
  {
    value: "admin_reason",
    label: "MANIPULATION ADMIN",
    isAdmin: true,
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: POSTER_NEEDS_NEW_DATE,
    label: polyglot.t("cancellation_reason.poster_needs_new_date"),
    actions: [
      CANCELLATION_ACTION_EDIT_DATETIME,
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "poster_other_solution",
    label: polyglot.t("cancellation_reason.poster_other_solution"),
    actions: [CANCELLATION_ACTION_CANCEL_JOB_BUTTON],
  },
  {
    value: "poster_doesnt_need_anymore",
    label: polyglot.t("cancellation_reason.poster_doesnt_need_anymore"),
    actions: [CANCELLATION_ACTION_CANCEL_JOB_BUTTON],
  },
  {
    value: "jobber_has_canceled",
    label: polyglot.t("cancellation_reason.jobber_has_canceled"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_didnt_come",
    label: polyglot.t("cancellation_reason.jobber_didnt_come"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_dont_answer",
    label: polyglot.t("cancellation_reason.jobber_dont_answer"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_isnt_available",
    label: polyglot.t("cancellation_reason.jobber_isnt_available"),
    actions: [
      CANCELLATION_ACTION_EDIT_DATETIME,
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_no_stuff",
    label: polyglot.t("cancellation_reason.jobber_no_stuff"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_no_skill",
    label: polyglot.t("cancellation_reason.jobber_no_skill"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_inappropriate_behaviour",
    label: polyglot.t("cancellation_reason.jobber_inappropriate_behaviour"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_ask_more_money",
    label: polyglot.t("cancellation_reason.jobber_ask_more_money"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
  {
    value: "jobber_ask_cash_payment",
    label: polyglot.t("cancellation_reason.jobber_ask_cash_payment"),
    actions: [
      CANCELLATION_ACTION_CHANGE_JOBBER,
      CANCELLATION_ACTION_CANCEL_JOB,
    ],
  },
];
