/* eslint-disable no-underscore-dangle */
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import categories from "../../pages/categoryForms/categories";
import StepTitle from "../../pages/categoryForms/common/StepTitle";
import getCommonPages from "../../pages/categoryForms/commonPages";
import { INFORMATIONS_PATH } from "../../pages/categoryForms/constants";
import {
  CONCIERGE_PAGE,
  CUSTOM,
  DEFAULT_CONFIG,
  DEFAULT_SCHEMA,
  FREQUENCY,
  SAV_PAGE,
} from "../../pages/categoryForms/defaults";
import { useCategoryFormLocalStorage } from "../../pages/categoryForms/useCategoryFormLocalStorage";
import {
  formatRequestValues,
  getValuesByVersion,
} from "../../pages/categoryForms/utils";
import polyglot from "../../utils/polyglot";
import { CategoryFormContext } from "../contexts";
import { useLogin } from "../hooks";
import {
  useCheckEmail,
  useGetFormData,
  usePostForm,
} from "../routes/category-forms-routes";
import { useFormatFormInitialValues } from "@/pages/categoryForms/useFormatFormInitialValues";

const DEFAULT_NEXT_LABEL = polyglot.t("common.next");

const CategoryFormProvider = ({
  children,
  id,
  initialValues: _propsInitialValues,
}) => {
  const previousJobId = new URLSearchParams(window.location.search).get(
    "previous_job_id"
  );
  const categoryOriginId = new URLSearchParams(window.location.search).get(
    "category_origin_id"
  );
  let startHour = new URLSearchParams(window.location.search).get("start_hour");
  startHour = startHour ? Number(startHour) : "";

  const date = new URLSearchParams(window.location.search).get("date");

  const category = categories[id]();
  category.version = category.version || 1;

  // get categories version
  // console.log(
  //   Object.keys(categories)
  //     .sort((a, b) => a - b)
  //     .reduce((acc, key) => {
  //       acc[key] = categories[key]().version || 1;
  //       return acc;
  //     }, {})
  // );

  const { data, isSuccess } = useGetFormData({
    id,
    previous_job_id: previousJobId,
    version: category.version,
    category_origin_id: categoryOriginId,
  });
  const [availabilities, setAvailabilities] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSteps, setActiveSteps] = useState([0]);
  const [nextLabel, setNextLabel] = useState(DEFAULT_NEXT_LABEL);
  const [nextIsLoading, setNextIsLoading] = useState(false);
  const [pages, setPages] = useState([]);
  const checkEmail = useCheckEmail();
  const postForm = usePostForm({ checkEmail });
  const {
    isConcierge: _isConcierge,
    isSav: _isSav,
    current_admin,
  } = useSelector((s) => s.rails);
  const { isLogged } = useLogin();

  const isConcierge = _isConcierge && !_isSav;
  const isSav = _isConcierge && _isSav;

  const categoryFormLocalStorage = useCategoryFormLocalStorage(id);
  const formatFormInitialValues = useFormatFormInitialValues();
  const _resInitialValues = {
    ...data?.previous_job,
  };

  const cookieEnabled =
    !current_admin &&
    Object.keys(_propsInitialValues || {})?.length === 0 &&
    Object.keys(_resInitialValues || {})?.length === 0;

  const {
    nhConfig: _nhConfig,
    getNbHours,
    getNbJobbers,
    getPricePerHour,
    getNhSummary,
    pages: categoryPages = [],
    config: _config,
    initialValues: __categoryInitialValues = {},
    formatInitialValues,
  } = category;

  const config = {
    ...DEFAULT_CONFIG,
    ..._config,
  };

  const _categoryInitialValues = {
    ...__categoryInitialValues,
    // add options if version match
    ...getValuesByVersion(
      {
        options: data?.options || data?.previous_job?.options || [],
        version: _resInitialValues?.version || category.version,
      },
      category.version
    ),
    ...getValuesByVersion(
      formatInitialValues
        ? formatRequestValues(
            formatInitialValues(_resInitialValues),
            __categoryInitialValues
          )
        : formatRequestValues(_resInitialValues, __categoryInitialValues),
      category.version
    ),
    // SET LOCAL STORAGE TO INITIAL VALUES
    ...(cookieEnabled && categoryFormLocalStorage.get()
      ? getValuesByVersion(categoryFormLocalStorage.get(), category.version)
      : []),
    version: category.version,
  };

  const commonPages = useMemo(
    () => [
      ...categoryPages,
      ...getCommonPages({
        config,
        initialValues: _categoryInitialValues,
        data,
        isConcierge,
        isSav,
        isLogged,
      }),
    ],
    [isSuccess]
  );

  const defaultPages = useMemo(() => {
    let enhancedPages = commonPages;
    if (isSuccess) {
      if (isSav) {
        enhancedPages.splice(enhancedPages.length - 1, 0, SAV_PAGE);
      }
      if (isConcierge) {
        enhancedPages.splice(enhancedPages.length - 1, 0, CONCIERGE_PAGE);
      }
      if (config.withFrequency || config.isCustom) {
        if (categoryPages.length === 0) {
          // create Information page if not existing
          enhancedPages = [
            {
              title: polyglot.t("categories.what_is_your_need"),
              path: INFORMATIONS_PATH,
              View: () => null,
              validationSchema: DEFAULT_SCHEMA,
            },
            ...enhancedPages,
          ];
        }
        let enhancedSchema =
          enhancedPages[0].validationSchema || DEFAULT_SCHEMA;
        const InitialView = enhancedPages[0]?.View;

        enhancedPages[0].View = () => (
          <>
            {config.isCustom ? (
              <CUSTOM.View>
                <InitialView />
              </CUSTOM.View>
            ) : (
              <InitialView />
            )}
            {config.withFrequency && <FREQUENCY.View />}
          </>
        );

        if (config.withFrequency) {
          enhancedSchema = enhancedSchema.shape(FREQUENCY.validationSchema);
        }

        if (config.isCustom) {
          enhancedSchema = enhancedSchema.shape(CUSTOM.validationSchema);
        }
        enhancedPages[0].validationSchema = enhancedSchema;
      }
      enhancedPages = config.renderPages
        ? config.renderPages(enhancedPages)
        : enhancedPages;

      const informationsPageIndex = enhancedPages.findIndex(
        (p) => p.path === INFORMATIONS_PATH
      );

      if (informationsPageIndex >= 0) {
        const informationsPage = enhancedPages[informationsPageIndex];
        const EnhancedInitialView = informationsPage?.View;
        informationsPage.View = (viewProps) => (
          <>
            <StepTitle>{informationsPage.title}</StepTitle>
            <EnhancedInitialView {...viewProps} />
          </>
        );
      }

      return enhancedPages;
    }

    return [];
  }, [isSuccess]);

  useEffect(() => {
    setPages(defaultPages);
  }, [isSuccess]);

  const commonInitialValues = {
    title: "",
    phone: data?.phone || "",
    description: "",
    start_hour: startHour || "",
    end_hour: "",
    date: date || "",
    end_date: "",
    category: id,
    nb_hours_calculated: 0,
    nb_hours: 0,
    price_per_hour: 0,
    nb_jobbers: 1,
    address: data?.addresses?.[0]?.address || "",
    address_id: data?.addresses?.[0]?.id || "",
    floor: data?.addresses?.[0]?.floor || null,
    entry_code: data?.addresses?.[0]?.entry_code || null,
    parking: data?.addresses?.[0]?.parking || null,
    lift:
      typeof data?.addresses?.[0]?.lift === "boolean"
        ? data?.addresses?.[0]?.lift
        : null,
    property_type: data?.addresses?.[0]?.property_type || null,
    address_location: data?.addresses?.[0]?.location || {},
    city_id: "",
    address_name: "",
    place_id: "",
    photos: [], // 3 max
    uploads: [],
    private_jobber_id: null,
    previous_job_id: null,
    concierge_customer_id: null,
    frequency: "",
  };

  const setDefaultTitle = () => {
    const callbackValue =
      _propsInitialValues?.title || _categoryInitialValues.title || "";
    if (!config.initialTitleIsEmpty) {
      return (
        _propsInitialValues?.title ||
        _categoryInitialValues.title ||
        polyglot.t(`categories.${id}.name`)
      );
    }
    return callbackValue;
  };

  const loginInitialValues = {
    user: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    },
    alert_setting: { coreg: true, optin: true },
  };

  const initialValues = formatFormInitialValues(
    {
      ...commonInitialValues,
      price_per_hour: data?.pricing.recommended,
      ...(config.withFrequency ? FREQUENCY.initialValues : []),
      ...(config.isCustom ? CUSTOM.initialValues : []),
      ..._categoryInitialValues,
      // Force phone initial value if local storage stored value is empty
      ...(commonInitialValues.phone && !_categoryInitialValues.phone
        ? { phone: commonInitialValues.phone }
        : {}),
      ..._propsInitialValues,
      // check private jobber ID only if exist in initials data.jobbers.
      private_jobber_id:
        data?.jobbers?.find(
          (jobber) => jobber.id === _propsInitialValues.private_jobber_id
        )?.id || null,
      title: setDefaultTitle(),
      ...(!isLogged ? loginInitialValues : []),
    },
    config
  );

  const nhConfig = {
    ...(config.isCustom ? CUSTOM.nhConfig : []),
    ..._nhConfig,
    ...(config.withFrequency ? FREQUENCY.nhConfig : []),
  };

  const handleNextLabel = (v) => {
    if (v) setNextLabel(v);
  };

  return (
    <CategoryFormContext.Provider
      value={{
        _propsInitialValues,
        _categoryInitialValues,
        _resInitialValues,
        initialValues,
        pages,
        nhConfig,
        config,
        getNhSummary,
        getCategoryNbHours: getNbHours,
        getCategoryNbJobbers: getNbJobbers,
        getCategoryPricePerHour: getPricePerHour,
        category,
        title: polyglot.t(`categories.${id}.name`),
        addresses: data?.addresses,
        pricing: data?.pricing,
        jobbers: data?.jobbers,
        sav_reasons: data?.sav_reasons,
        id,
        flowControl: {
          getNextLabel: () => nextLabel,
          setNextLabel: (v) => handleNextLabel(v),
          setNextIsLoading: (v) => setNextIsLoading(v),
          getNextIsLoading: () => nextIsLoading,
          setDefaultNextLabel: () => handleNextLabel(DEFAULT_NEXT_LABEL),
        },
        isSav,
        isConcierge,
        concierge_customers: data?.concierge_customers,
        isLoaded: isSuccess && pages.length > 0,
        setPages,
        activeIndex,
        setActiveIndex,
        activeSteps,
        setActiveSteps,
        availabilities,
        setAvailabilities,
        checkEmail,
        postForm,
        commonPages,
        defaultPages,
        cookieEnabled,
      }}
    >
      {children}
    </CategoryFormContext.Provider>
  );
};

export default CategoryFormProvider;
