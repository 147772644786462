import { Field, useField, useFormikContext } from "formik";
import { Checkbox, List } from "../../../../components";

const useCheckboxValue = (name) => {
  const { setFieldValue } = useFormikContext();
  const field = useField(name);
  return (value) => {
    const currValue = field[0]?.value || [];
    const checked = currValue?.includes(value) || false;
    let newValue;
    if (!checked) {
      newValue = [...currValue, value];
    } else {
      newValue = currValue?.filter((v) => v !== value);
    }
    setFieldValue(name, newValue);
    return newValue;
  };
};

const FormCheckboxes = ({ name, options, children, onChange }) => {
  const setCheckboxValue = useCheckboxValue(name);
  return (
    <>
      {options.map(({ value, label, subtitle }) => (
        <Field
          name={name}
          type="checkbox"
          value={value}
          key={`option-key-${name}-${value}`}
        >
          {({ field, form }) => (
            <List.Item
              divider
              onClick={() => {
                const newValues = setCheckboxValue(value);
                if (onChange) onChange(newValues);
                form.setFieldTouched(name, true);
              }}
              RightComponent={() => (
                <Checkbox
                  tabIndex={-1}
                  {...field}
                  css={`
                    pointer-events: none;
                  `}
                />
              )}
            >
              <List.Elem.Title strong>{label}</List.Elem.Title>
              <List.Elem.Subtitle>{subtitle}</List.Elem.Subtitle>
              {children}
            </List.Item>
          )}
        </Field>
      ))}
    </>
  );
};

export default FormCheckboxes;
