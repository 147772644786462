import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1044() {
  const DEFAULT_NB_HOURS = 2;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.insulate_one_to_two_windows"),
            popular: true,
          },
          {
            label: polyglot.t("categories.classic"),
            value: 3,
            subtitle: polyglot.t("categories.insulate_three_to_four_windows"),
          },
          {
            label: polyglot.t("categories.long"),
            value: 4,
            subtitle: polyglot.t("categories.insulate_five_or_more_windows"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues,
    config: {
      IsNbHoursReadOnly: true,
    },
  };
}
