const config = {
  multipleJobbersAllowed: true,
  withFrequency: true,
  isCustom: true,
};

export function useCategory7016() {
  const initialValues = {};

  const getNbHours = () => 2;

  const nhConfig = {};

  return {
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
