import { colors, spacings } from "../../../../assets/themes";
import { Block, Body14, Body16, Button, Modal } from "../../../../components";
import { BUTTON } from "../../../../components/Styles/variants";
import polyglot from "../../../../utils/polyglot";

const ConfirmModal = ({ isOpen, onClose, onSubmit }) => (
  <Modal.XSmall fullScreenOnMobile isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose}>
      <Modal.Item.Title>
        {polyglot.t("cesu.send_your_tickets")}
      </Modal.Item.Title>
    </Modal.Item.Header>
    <Modal.Item.Wrapper>
      <Body16>{polyglot.t("cesu.you_can_pay_your_jobber_with_cesu")}</Body16>
      <Block marginTop={spacings.s}>
        <Body14 color={colors.muted}>
          {polyglot.t("cesu.cesu_fees_information", { fees: 6 })}
        </Body14>
      </Block>
    </Modal.Item.Wrapper>
    <Modal.Item.Footer column>
      <Button.Large block onClick={onSubmit}>
        {polyglot.t("common.confirm")}
      </Button.Large>
      <Button.Large block kind={BUTTON.KIND.SECONDARY} onClick={onClose}>
        {polyglot.t("common.i_have_not_finish")}
      </Button.Large>
    </Modal.Item.Footer>
  </Modal.XSmall>
);
export default ConfirmModal;
