import BaseApp from "../modules/BaseApp";
import BecomeJobberTitle from "../pages/BecomeJobberTitle";

const BecomeJobberTitleApp = (props, rails) => () =>
  (
    <BaseApp>
      <BecomeJobberTitle {...props} />
    </BaseApp>
  );

export default BecomeJobberTitleApp;
