import { useLayoutEffect, useState } from "react";
import { Cropper as ReactCropper } from "react-cropper";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import Slider from "../Slider";
import { BUTTON, CROPPER } from "../Styles/variants";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cropperjs/dist/cropper.css";

const getCropperShape = (shape) => {
  if (shape === CROPPER.SHAPE.CIRCLE) {
    return radius.circle;
  }
  if (shape === CROPPER.SHAPE.SQUARE) {
    return radius.ml;
  }
  return 0;
};

const StyledCropper = styled(ReactCropper)`
  .cropper-view-box {
    outline: 0;
    border: solid ${borderWidth.m} ${colors.onColor};
  }
  .cropper-crop-box,
  .cropper-view-box {
    border-radius: ${({ shape }) => getCropperShape(shape)};
    overflow: hidden;
  }
  .cropper-face {
    background-color: inherit;
  }
  .cropper-line,
  .cropper-point {
    background-color: ${colors.onColor};
  }
`;

const StyledControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${spacings.s} ${spacings.m};
  bottom: 0;
  left: 0;
  right: 0;
`;

const Cropper = ({
  value,
  height = 400,
  width = "100%",
  onChange,
  shape = CROPPER.SHAPE.CIRCLE,
  disabledCrop,
  disabledRotate,
  ...rest
}) => {
  const [cropper, setCropper] = useState(null);
  const [defaultZoom, setDefaultZoom] = useState(null);
  const [currentZoom, setCurrentZoom] = useState(defaultZoom);
  const isReady = cropper?.ready;

  const sliderMin = defaultZoom - 1 > 0 ? defaultZoom - 1 : 0.1;
  const sliderMax = defaultZoom + 1;

  useLayoutEffect(() => {
    if (isReady) {
      const zoom =
        cropper?.imageData?.width / cropper?.imageData?.naturalWidth || 0.5;
      setDefaultZoom(zoom);
      setCurrentZoom(zoom);
    }
  }, [isReady]);

  const handleCrop = (e) => {
    if (onChange) onChange(e);
  };

  const handleZoom = (v) => {
    let newZoom = currentZoom + v;
    if (newZoom <= sliderMin) {
      newZoom = sliderMin;
    }
    if (newZoom >= sliderMax) {
      newZoom = sliderMax;
    }
    cropper.zoomTo(newZoom);
    setCurrentZoom(newZoom);
  };

  const handleZoomTo = (v) => {
    cropper.zoomTo(v);
    setCurrentZoom(v);
  };

  const handleRotate = () => {
    cropper.rotate(-90);
  };

  return (
    <Block position="relative" minHeight={height} width={width}>
      <Block
        borderRadius={radius.ml}
        overflow="hidden"
        background={colors.body}
        width="100%"
        height="100%"
      >
        <StyledCropper
          src={value}
          style={{
            height,
            width,
          }}
          checkCrossOrigin={false}
          initialAspectRatio={1 / 1}
          shape={shape}
          guides
          step={10}
          cropBoxMovable={false}
          cropBoxResizable={false}
          movable={!disabledCrop}
          viewMode={1}
          dragMode="move"
          autoCrop={!disabledCrop}
          zoomOnWheel={false}
          background={false}
          crop={handleCrop}
          onInitialized={(init) => setCropper(init)}
          {...rest}
        />
      </Block>

      {isReady && (
        <StyledControlsWrapper>
          <Block
            flex="1"
            borderRadius={radius.circle}
            marginRight={spacings.s}
            height={sizes.size48}
            display="flex"
            alignItems="center"
            position="relative"
          >
            <Block marginRight={spacings.s}>
              <Button.Medium
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                css="background: none"
                disabled={disabledCrop || sliderMin === currentZoom}
                onClick={() => handleZoom(-0.1)}
              >
                <Icon.Large name="minus" />
              </Button.Medium>
            </Block>
            <Slider
              min={sliderMin}
              max={sliderMax}
              disabled={disabledCrop}
              step={0.05}
              ticks={false}
              value={currentZoom}
              formatValue={(value) =>
                `${Math.round(
                  ((value - sliderMin) / (sliderMax - sliderMin)) * 100
                )}%`
              }
              onChange={handleZoomTo}
            />
            <Block marginLeft={spacings.s}>
              <Button.Medium
                shape={BUTTON.SHAPE.CIRCLE}
                kind={BUTTON.KIND.MINIMAL}
                css="background: none"
                disabled={disabledCrop || sliderMax === currentZoom}
                onClick={() => handleZoom(0.1)}
              >
                <Icon.Large name="plus" />
              </Button.Medium>
            </Block>
          </Block>

          <Button.Medium
            disabled={disabledRotate}
            shape={BUTTON.SHAPE.CIRCLE}
            kind={BUTTON.KIND.MINIMAL}
            css="background: none"
            onClick={handleRotate}
          >
            <Icon.Large name="retry" />
          </Button.Medium>
        </StyledControlsWrapper>
      )}
    </Block>
  );
};

export default Cropper;
