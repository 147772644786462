import React from "react";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body14, Button, Display3, Modal } from "../../../components";
import { usePutRefund } from "../../../modules/routes/dashboard-routes";
import polyglot from "../../../utils/polyglot";

const RefundModal = ({ onClose, isOpen, amount }) => {
  const putRefund = usePutRefund({ onClose });

  return (
    <Modal.XSmall isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>
          {polyglot.t("wallet.refund_wallet")}
        </Modal.Item.Title>
        <Modal.Item.Subtitle>
          {polyglot.t("wallet.refund_wallet_description_alt")}
        </Modal.Item.Subtitle>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Block
          marginBottom={spacings.l}
          marginTop={spacings.m}
          display="flex"
          justifyContent="center"
        >
          <Display3>{polyglot.toSmartCurrency(amount)}</Display3>
        </Block>
        <Block marginBottom={spacings.s} display="flex" justifyContent="center">
          <Body14 color={colors.muted}>
            {polyglot.t("wallet.refund_delay")}
          </Body14>
        </Block>
        <Button.Large
          isLoading={putRefund.isLoading}
          disabled={amount <= 0}
          onClick={() => putRefund.mutate()}
        >
          {polyglot.t("wallet.pot_refund")}
        </Button.Large>
      </Modal.Item.Wrapper>
    </Modal.XSmall>
  );
};

const areEqual = (prevProps, nextProps) =>
  prevProps.isOpen === nextProps.isOpen &&
  prevProps.amount === nextProps.amount;

export default React.memo(RefundModal, areEqual);
