import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1048() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1.5,
            subtitle: polyglot.t("categories.single_bed_or_baby_bed"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: 2.5,
            subtitle: polyglot.t("categories.double_bed_with_headboard"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 3.5,
            subtitle: polyglot.t(
              "categories.bunk_or_mezzanine_bed_with_storage"
            ),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues: { nb_hours: 2.5 },
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
    },
  };
}
