import React from 'react';

const Pagination = ({ totalPages, currentPage, onChange, type = 'default' }) => {
  const renderPagination = () => {
    let pagination = [];
    if (currentPage === 1) {
      pagination = [currentPage, currentPage + 1, currentPage + 2];
    } else if (currentPage === totalPages) {
      pagination = [currentPage - 2, currentPage - 1, currentPage];
    } else {
      pagination = [currentPage - 1, currentPage, currentPage + 1];
    }
    return pagination.filter(p => p > 0 && p <= totalPages);
  };

  const handleChange = (e, page) => {
    onChange(e, page);
  };

  const PageButton = ({ title, value }) => (
    <li className={`page-item ${currentPage === value && 'disabled'}`}>
      <button
        type="button"
        className="page-link position-relative d-flex align-items-center justify-content-center"
        onClick={e => handleChange(e, value)}>
        <span>{title}</span>
      </button>
    </li>
  );

  return (
    <nav aria-label="Navigation">
      {type === 'default' && (
        <ul className="pagination">
          {renderPagination().map((el, index) =>
            index < totalPages ? (
              <PageButton key={`pagination-id-${el + index}`} value={el} title={el} />
            ) : null,
          )}
        </ul>
      )}
      {type === 'seeMore' && totalPages !== currentPage && (
        <ul className="pagination">
          <PageButton title="Voir plus de commentaires" value={currentPage + 1} />
        </ul>
      )}
    </nav>
  );
};

export default Pagination;
