import { colors, spacings } from "../assets/themes";
import { Body16, H5 } from "./Text";
import DefaultImg from "../assets/images/svg-icons/NoResults/default.svg";
import { useBreakpoints } from "../modules/hooks";

const NoResult = ({
  title,
  subtitle,
  children,
  artwork = DefaultImg,
  className,
}) => {
  const breakpoints = useBreakpoints();
  return (
    <div
      css={`
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: ${breakpoints.get({ xs: spacings.m, md: spacings.l })} auto;
        max-width: 450px;
      `}
      className={className}
    >
      <img
        src={artwork}
        alt=""
        css={`
          width: ${breakpoints.get({ xs: "120px", md: "150px" })};
          height: auto;
          margin-bottom: ${spacings.s};
        `}
      />
      <H5
        strong
        align="center"
        css={`
          margin-bottom: ${spacings.s};
        `}
      >
        {title}
      </H5>
      {subtitle && (
        <Body16 color={colors.muted} align="center">
          {subtitle}
        </Body16>
      )}
      {children && (
        <div
          css={`
            margin-top: ${spacings.s};
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default NoResult;
