import polyglot from "../../../utils/polyglot";
import { NB_JOBBERS_PATH } from "../constants";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1107() {
  const DEFAULT_NB_HOURS = 3;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };

  return {
    version: 1,
    initialValues,
    config: {
      isCustom: true,
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
      initialTitleIsEmpty: true,
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbJobbersIndex = newPages.findIndex(
          (p) => p.path === NB_JOBBERS_PATH
        );
        newPages.splice(nbJobbersIndex + 1, 0, {
          ...NB_HOURS_SELECTION_PAGE,
          customProps: {
            options: [
              {
                label: polyglot.t("categories.small_surface"),
                value: 2,
                subtitle: polyglot.t("categories.isolate_one_window"),
              },
              {
                label: polyglot.t("categories.medium_surface"),
                value: DEFAULT_NB_HOURS,
                subtitle: polyglot.t("categories.isolate_one_door"),
                popular: true,
              },
              {
                label: polyglot.t("categories.large_surface"),
                value: 4,
                subtitle: polyglot.t("categories.isolate_one_under_slope"),
              },
            ],
          },
        });
        return newPages;
      },
    },
  };
}
