import { useState } from "react";
import { colors, sizes } from "../../../assets/themes";
import { Avatar, Body16, List } from "../../../components";
import getNameInitials from "../../../utils/get-initials";
import polyglot from "../../../utils/polyglot";
import ReferredUserHistoryModal from "./ReferredUserHistoryModal";

const ReferredUserItem = ({
  first_name,
  last_name,
  earned_amount,
  referral_rewards,
}) => {
  const [referralHistoryModalIsOpen, setReferralHistoryModalIsOpen] =
    useState();
  const handleCloseReferralHistoryModal = () => {
    setReferralHistoryModalIsOpen(false);
  };

  const handleOpenReferralHistoryModal = () => {
    setReferralHistoryModalIsOpen(true);
  };

  return (
    <>
      <List.Item
        LeftComponent={() => <Avatar size={sizes.size48} name={first_name} />}
        chevron
        onClick={handleOpenReferralHistoryModal}
        RightComponent={() => (
          <Body16 color={colors.muted}>
            {polyglot.toSmartCurrency(earned_amount)}
          </Body16>
        )}
      >
        <Body16 strong>
          {first_name} {last_name}
        </Body16>
      </List.Item>
      <ReferredUserHistoryModal
        isOpen={referralHistoryModalIsOpen}
        onClose={handleCloseReferralHistoryModal}
        referral_rewards={referral_rewards}
      />
    </>
  );
};
export default ReferredUserItem;
