import React from "react";
import styled, { css } from "styled-components";
import { borderWidth, colors, radius } from "../../assets/themes";

const MAX_FLOATING_CHILDREN = 2;
const StyledChild = styled.div``;

const StyledChildWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

const StyledBubbleCount = styled.div`
  ${({ size }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: calc(${size} / 1.5);
    height: calc(${size} / 1.5);
    border-radius: ${radius.circle};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.muted};
    color: ${colors.onColor};
    outline: ${borderWidth.m} solid ${colors.background};
  `}
`;

const StyledGroup = styled.div`
  ${({ childrenAmount }) =>
    childrenAmount >= 2 &&
    css`
      ${StyledChild} {
        position: absolute;
        top: 0;
        right: 0;
        outline: ${borderWidth.m} solid ${colors.background};
        border-radius: ${radius.circle};
        &:first-of-type {
          left: 0;
          bottom: 0;
          top: inherit;
          right: inherit;
          outline: 0;
          border-radius: ${radius.circle};
        }
      }
    `}
  ${({ size }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: ${size};
    height: ${size};
  `}
`;

const Group = ({ children }) => {
  const childs = React.Children.toArray(children).splice(
    0,
    MAX_FLOATING_CHILDREN
  );
  const childrenAmount = React.Children.count(children);
  const currSize = childs.map((child) => child.props.size)[0];
  const ChildsElem = () =>
    childs.map((child, index) => (
      <StyledChild key={`avatar-group-${index}`}>
        {React.cloneElement(child, {
          size:
            childrenAmount > 1
              ? `calc(${child?.props.size} / 1.5)`
              : child?.props.size,
        })}
      </StyledChild>
    ));
  return (
    <StyledGroup size={currSize} childrenAmount={childrenAmount}>
      <StyledChildWrapper>
        <ChildsElem />
      </StyledChildWrapper>
      {childrenAmount > MAX_FLOATING_CHILDREN && (
        <StyledBubbleCount size={currSize}>
          +{childrenAmount - 1}
        </StyledBubbleCount>
      )}
    </StyledGroup>
  );
};

export default Group;
