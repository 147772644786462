import { Field } from "formik";
import { spacings } from "../../../../assets/themes";
import { Col, Row, Selectable } from "../../../../components";

const getSize = (arr, isWrap) => {
  if (!isWrap && arr.length >= 2) {
    return 12 / arr.length;
  }
  return isWrap ? "auto" : { xs: 12, sm: 12 / arr.length };
};

const getFieldIsChecked = (value, fieldValue) => {
  // prevent checked=false when comparing two array ([] !== []).
  if (Array.isArray(value) || Array.isArray(fieldValue)) {
    return JSON.stringify(fieldValue) === JSON.stringify(value);
  }
  return fieldValue === value;
};

const FormSelectables = ({ options = [], isWrap, name, onChange, shape }) => (
  <Row gutter={[spacings.s]}>
    {options?.map(
      (option, i, arr) =>
        !option.disabled && (
          <Col key={`selectable-item-${name}-${i}`} size={getSize(arr, isWrap)}>
            <Field name={name} value={option.value}>
              {({ field, form }) => (
                <Selectable.Medium
                  {...field}
                  shape={shape}
                  checked={getFieldIsChecked(field.value, option.value)}
                  onChange={(e) => {
                    if (onChange) onChange(option.value, form);
                    form.setFieldTouched(field.name, true);
                    form.setFieldValue(field.name, option.value);
                  }}
                >
                  {option.label}
                </Selectable.Medium>
              )}
            </Field>
          </Col>
        )
    )}
  </Row>
);

export default FormSelectables;
