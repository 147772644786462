import { useFormikContext } from "formik";
import { colors, sizes, spacings } from "../../assets/themes";
import { Block, Body14, H4, Info, Stepper } from "../../components";
import { STEPPER } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const EXTRA_HOURS_STEP = 0.5;

const ConfirmForm = ({ pricings }) => {
  const { setFieldValue, values } = useFormikContext();

  return (
    <div>
      <H4 align="center">
        {polyglot.t("job_review.how_many_nb_hours_done_by_jobber")}
      </H4>

      <Block marginTop={spacings.l} marginBottom={spacings.m}>
        <Stepper
          size={STEPPER.SIZE.LARGE}
          step={EXTRA_HOURS_STEP}
          max={pricings[pricings.length - 1].nb_hours_total}
          min={values.initial_hours}
          value={values.nb_hours}
          onChange={(value) => setFieldValue("nb_hours", value)}
          name="extra_duration"
          renderMinButton={({ Component, disabled }) =>
            disabled ? (
              <Block position="relative">
                <Block
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  zIndex="1"
                >
                  <Info
                    render={() => (
                      <button
                        tabIndex={-1}
                        type="button"
                        css={`
                          width: ${sizes.size54};
                          opacity: 0;
                          height: ${sizes.size54};
                        `}
                      >
                        &nbsp;
                      </button>
                    )}
                  >
                    {polyglot.t("job_review.cant_reduce_initial_hours")}
                  </Info>
                </Block>
                <Component />
              </Block>
            ) : (
              <Component />
            )
          }
          render
          hint={() => (
            <>
              <Body14 color={colors.muted}>
                {polyglot.t("common.initial_duration")}&nbsp;:&nbsp;
                {polyglot.toDuration(values.initial_hours, true)}
              </Body14>
              {values.nb_hours - values.initial_hours > 0 && (
                <Body14 color={colors.muted}>
                  {polyglot.t("job_review.extra_hours_hint_plural", {
                    count: values.nb_hours - values.initial_hours,
                    duration: polyglot.toDuration(
                      values.nb_hours - values.initial_hours,
                      true
                    ),
                  })}
                </Body14>
              )}
            </>
          )}
        >
          {polyglot.toDuration(values.nb_hours, true)}
        </Stepper>
      </Block>
    </div>
  );
};

export default ConfirmForm;
