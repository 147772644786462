import { useFormikContext } from "formik";
import { useEffect, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCategoryForm } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";

function useMountRedirection() {
  const history = useHistory();
  const { values, setStatus, status, validateForm } = useFormikContext();
  const { pages, activeIndex } = useCategoryForm();
  useEffect(() => {
    if ((history.action === "POP" && !status?.warning) || status?.warning) {
      const formatedPages = [...pages];
      // formatedPages.slice(0, activeIndex - 1 || 0);
      formatedPages.length = Math.max(activeIndex, 0);
      const isInvalid = formatedPages.some((page, index) => {
        try {
          page.validationSchema.validateSync(values);
        } catch (error) {
          setStatus({
            ...status,
            warning: polyglot.t(
              "categories.please_fill_this_step_before_continue"
            ),
          });
          history.replace({
            pathname: pages[index].path,
            state: { alertRedirection: true, ...history.location.state },
          });

          return true;
        }
        return false;
      });
      // clear status if prev pages are valid;
      if (!isInvalid) {
        setStatus({
          ...status,
          warning: null,
        });
      }
    }
  }, [history.location.pathname]);

  useEffect(() => {
    // force validate form after change page
    validateForm(values);
  }, [activeIndex]);

  useLayoutEffect(() => {
    // Redirect to current step on page load
    history.replace({
      pathname: `/${pages[activeIndex].path}`,
      state: history.location?.state,
    });
  }, []);

  return null;
}

export default useMountRedirection;
