import { Formik } from "formik";
import * as yup from "yup";
import { Modal } from "../../../components";
import polyglot from "../../../utils/polyglot";
import ManualForm from "./ManualForm";
import cesuDateValidation from "../../../utils/cesu-date-validation";
import { useCesu } from "../../../modules/hooks";
import { useVerifyCheques } from "../../../modules/routes/cesu-routes";
import cesuCodeValidation from "../../../utils/cesu-code-validation";

const ManualInputModal = ({ isOpen, closeModal, code }) => {
  const cesu = useCesu();
  const verifyCheques = useVerifyCheques();
  const isReadOnly = code?.bar_code && code?.secure_code;
  return (
    <Modal.Small isOpen={isOpen} onClose={closeModal} fullScreenOnMobile>
      <Modal.Item.Header onClose={closeModal}>
        <Modal.Item.Title>
          {isReadOnly
            ? polyglot.t("cesu.added_ticket")
            : polyglot.t("cesu.new.add_ticket")}
        </Modal.Item.Title>
      </Modal.Item.Header>

      <Formik
        initialValues={{
          bar_code: code?.bar_code || "",
          secure_code: code?.secure_code || "",
        }}
        validationSchema={yup.object().shape({
          bar_code: yup
            .string()
            .min(
              26,
              polyglot.t("common.errors.min_n_length_plural", { count: 26 })
            )
            .required(polyglot.t("common.errors.field_required"))
            .test(
              "checkTicket",
              polyglot.t("cesu.ticket_error"),
              async function checkTicket(value = "") {
                const { path, createError } = this;
                const getLocalErrors = () => {
                  if (
                    cesu.tickets.filter((item) => item.bar_code === value)
                      .length > 0 &&
                    code?.bar_code !== value
                  ) {
                    return polyglot.t("cesu.already_added_this_ticket");
                  }
                  if (!cesuDateValidation(value)) {
                    return polyglot.t("cesu.error_expired");
                  }
                  return "";
                };

                const localError = getLocalErrors();
                if (localError) {
                  return createError({ path, message: localError });
                }

                if (cesuCodeValidation(value)) {
                  try {
                    await verifyCheques.mutateAsync({ code: value });
                  } catch (error) {
                    return createError({
                      path,
                      message: error.response.data.error_message,
                    });
                  }
                }

                return true;
              }
            ),
          secure_code: yup
            .string()
            .required(polyglot.t("common.errors.field_required")),
        })}
        validateOnMount
      >
        <ManualForm
          code={code}
          onClose={closeModal}
          isLoading={verifyCheques.isLoading}
        />
      </Formik>
    </Modal.Small>
  );
};

export default ManualInputModal;
