import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Icon from "../Icon";
import { TAG } from "../Styles/variants";
import Tag from "../Tag";

const OfferGreatValueTag = ({ level }) => (
  <Block display="inline-flex">
    <Tag.Small
      LeftComponent={() => <Icon.Small name="thumb-up-solid" />}
      kind={TAG.KIND.WARNING}
    >
      {polyglot.t(`offer_badges.kinds.great_value_level_${level}`)}
    </Tag.Small>
  </Block>
);

export default OfferGreatValueTag;
