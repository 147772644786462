import { useFormikContext } from "formik";
import React, { useState } from "react";
import {
  Alert,
  Container,
  Flow,
  FormControl,
  Icon,
  Input,
  List,
  PhoneInput,
  Radio,
  Select,
} from "../../components";
import { formatDate, getMonthlyOptions, getWeeklyOptions } from "../../utils";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";
import { AddressModal } from "../common";
import SetCesuModal from "../common/SetCesuModal";
import FirstPaymentDateModal from "./conditions/FirstPaymentDateModal";

const AdditionnalInformations = ({
  goNext,
  title,
  goBack,
  addresses,
  user,
  tax_deductions,
}) => {
  const { setFieldValue, values, isValid, errors, touched } =
    useFormikContext() || {};
  const [addressIsOpen, setAddressIsOpen] = useState(false);
  const [cesuModalIsOpen, setCesuModalIsOpen] = useState(false);
  const [firstPaymentDateIsOpen, setFirstPaymentDateIsOpen] = useState(false);
  const selectedJobber = !values.jobber_id
    ? values.external_jobber
    : values.jobber;

  const handleSubmitFirstPayment = ({ first_payment_date }) => {
    setFieldValue("first_payment_date", first_payment_date);
    setFirstPaymentDateIsOpen(false);
  };

  const handleCesuSubmit = ({ urssaf }) => {
    setFieldValue("urssaf", urssaf);
    setFieldValue("cesu", urssaf);
    setCesuModalIsOpen(false);
  };

  return (
    <Container.Small>
      <Flow.Item.Title>{title}</Flow.Item.Title>
      <FormControl label="Adresse du service récurrent">
        <Input
          placeholder="123 rue de la gare, 75001 Paris"
          onClick={() => setAddressIsOpen(true)}
          value={values.address}
          RightComponent={() => <Icon.Medium name="dropdown" />}
          name="address"
        />
      </FormControl>
      <FormControl label="Votre numéro de téléphone">
        <PhoneInput
          value={values.phone || ""}
          name="phone"
          autoComplete="phone"
          label="Votre numéro de téléphone"
          error={touched.phone && errors.phone ? true : null}
          onChange={(v) => setFieldValue("phone", v)}
        />
      </FormControl>
      {values.frequency === "weekly" && (
        <FormControl
          label={`Quel jour voulez-vous que ${selectedJobber?.first_name} soit rémunéré ?`}
        >
          <Select
            name="weekly_payment_day"
            placeholder="Tous les Lundis"
            onChange={(option) => {
              setFieldValue("weekly_payment_day", option.value);
              setFieldValue("first_payment_date", "");
            }}
            options={getWeeklyOptions()}
            defaultValue={values.weekly_payment_day}
          />
        </FormControl>
      )}
      {values.frequency === "monthly" && (
        <FormControl
          label={`Quel jour voulez-vous que ${selectedJobber?.first_name} soit rémunéré ?`}
        >
          <Select
            name="monthly_payment_day"
            placeholder="Tous les 31 du mois"
            onChange={(option) => {
              setFieldValue("monthly_payment_day", option.value);
              setFieldValue("first_payment_date", "");
            }}
            defaultValue={values.monthly_payment_day}
            options={getMonthlyOptions()}
          />
        </FormControl>
      )}
      {(values.weekly_payment_day || values.monthly_payment_day) && (
        <FormControl
          label={`Quand voulez-vous commencer à payer ${selectedJobber?.first_name} ?`}
        >
          <Input
            onClick={() => setFirstPaymentDateIsOpen(true)}
            placeholder="Sélectionner un jour..."
            value={
              values.first_payment_date
                ? capitalize(
                    formatDate(values.first_payment_date, "dddd D MMMM")
                  )
                : ""
            }
            RightComponent={() => <Icon.Medium name="dropdown" />}
            name="first_payment_date"
          />
        </FormControl>
      )}
      <List.Header>
        Comment souhaitez-vous rémunérer {selectedJobber?.first_name} ?
      </List.Header>
      <List.Item
        title="Carte bancaire"
        LeftComponent={() => <i className="icon-credit-card text-primary" />}
        onClick={() => setFieldValue("cesu", false)}
        RightComponent={() => (
          <Radio name="cesu" checked={values.cesu === false} />
        )}
      />
      {!values.jobber.is_pro && (
        <>
          <List.Item
            onClick={() => {
              !values.urssaf
                ? setCesuModalIsOpen(!values.cesu)
                : setFieldValue("cesu", true);
            }}
            LeftComponent={() => <i className="icon-ticket text-primary" />}
            RightComponent={() => (
              <div className="d-flex align-items-center">
                <List.Elem.Label className="text-nowrap">
                  {polyglot.toSmartCurrency(user.cesu_wallet)}
                </List.Elem.Label>
                <Radio checked={values.cesu === true} className="ml-2" />
              </div>
            )}
          >
            <List.Elem.Title>Tickets CESU</List.Elem.Title>
            <List.Elem.Subtitle>
              Nous utiliserons votre solde CESU en priorité, puis la carte
              bancaire renseignée.
            </List.Elem.Subtitle>
          </List.Item>
          {user.cesu_wallet <= 0 && (
            <Alert.Low kind="primary" className="mt-3">
              Vous pourrez ajouter des tickets CESU une fois que vous aurez
              terminé.
            </Alert.Low>
          )}
        </>
      )}
      <AddressModal
        isOpen={addressIsOpen}
        onClose={() => setAddressIsOpen(false)}
        addresses={addresses}
      />
      <FirstPaymentDateModal
        isOpen={firstPaymentDateIsOpen}
        onClose={() => setFirstPaymentDateIsOpen(false)}
        frequency={values.frequency}
        onChange={handleSubmitFirstPayment}
        weekly_payment_day={values.weekly_payment_day}
        monthly_payment_day={values.monthly_payment_day}
        first_payment_date={values.first_payment_date}
        selectedJobber={selectedJobber}
      />
      <SetCesuModal
        isOpen={cesuModalIsOpen}
        onClose={() => setCesuModalIsOpen(false)}
        user={user}
        onSubmit={handleCesuSubmit}
        tax_deductions={tax_deductions}
      />
      <Flow.Item.Navigation goNext={goNext} isValid={isValid} goBack={goBack} />
    </Container.Small>
  );
};

export default AdditionnalInformations;
