// eslint-disable-next-line import/no-unresolved
import Logo from "images/brand/yoojo-logo.svg";
import styled from "styled-components";
import { borderWidth, colors, sizes } from "../../assets/themes";
import { Block, Body16, Body18, Container, Icon } from "../../components";
import { useBreakpoints } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";

const StyledHeader = styled.div`
  background: ${colors.background};
  border-bottom: solid ${borderWidth.m} ${colors.border};
  height: ${sizes.size72};
`;

const BookingHeader = () => {
  const breakpoints = useBreakpoints();
  return (
    <StyledHeader>
      <Container.Medium
        css={`
          height: 100%;
        `}
      >
        <Block
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          height="100%"
        >
          <img src={Logo} height={36} alt={polyglot.t("common.brand")} />
          <Body18
            strong
            color={colors.mutedLight}
            as={breakpoints.get({ xs: Body16, md: Body18 })}
          >
            <Icon.Large name="lock" />
            &nbsp; {polyglot.t("common.secure_payment")}
          </Body18>
        </Block>
      </Container.Medium>
    </StyledHeader>
  );
};
export default BookingHeader;
