import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, List, Spinner } from "../../components";
import { getDocuments } from "../../modules/routes";

const Documents = ({ id }) => {
  const history = useHistory();
  const [data, setData] = useState();
  useEffect(() => {
    const res = getDocuments({ id });
    res
      .then(({ data }) => {
        setData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <Container.Medium className="py-5">
      <Button.Link onClick={() => history.goBack()} className="mb-4">
        <i className="icon-arrow-left mr-2" />
        Retour
      </Button.Link>
      <h1>Documents et attestations</h1>
      {data ? (
        <>
          {data.map((group, index) => (
            <React.Fragment key={`title-doc-${index}`}>
              <List.Header title={group.title} />
              {group.documents.map((document, i) => (
                <List.Item
                  key={`document-${i}-${index}`}
                  title={document.title}
                  target="_blank"
                  RightComponent={() => (
                    <i className="icon-download text-primary" />
                  )}
                  href={document.url}
                />
              ))}
            </React.Fragment>
          ))}
        </>
      ) : (
        <div className="d-flex align-items-center my-3">
          <Spinner.Large className="mx-auto text-muted" />
        </div>
      )}
    </Container.Medium>
  );
};

export default Documents;
