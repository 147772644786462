import { Field, useFormikContext } from "formik";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getJobber } from "../../modules/routes";
import { Container, Flow, FormControl, Input } from "../../components";

const SetJobberEmail = ({ goByPath }) => {
  const { isValid, setFieldValue, values } = useFormikContext();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const handleCheckJobber = (email) => {
    setIsLoading(true);
    const res = getJobber({ email });
    res.then(({ data }) => {
      setIsLoading(false);
      if (data.jobber) {
        setFieldValue("jobber[email]", email);
        setFieldValue("external_jobber[email]", "");
        goByPath("jobber", { jobber: data.jobber });
      } else {
        goByPath("first_name");
      }
    });
  };

  return (
    <Container.Small>
      <Flow.Item.Title>
        {!history.location?.state?.altTitle
          ? "Oups, votre employé n’est pas encore sur Yoojo, invitez le maintenant"
          : "Quel est l'adresse email de votre employé de maison ?"}
      </Flow.Item.Title>
      <Field name="external_jobber[email]">
        {({ field }) => (
          <FormControl label="Saisissez son adresse e-mail">
            <Input placeholder="jeandupond@mail.fr" autoFocus {...field} />
          </FormControl>
        )}
      </Field>
      <Flow.Item.Navigation
        isValid={isValid}
        goNext={() => handleCheckJobber(values.external_jobber.email)}
        isLoading={isLoading}
        goBack={() => goByPath("phone-number")}
      />
    </Container.Small>
  );
};

export default SetJobberEmail;
