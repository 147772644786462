import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { colors, spacings } from "../../assets/themes";
import { Block, Button, FormControl, Modal, Textarea } from "../../components";
import { useListenToTargetClick } from "../../modules/hooks";
import {
  useGetAdminNotes,
  usePostAdminNote,
} from "../../modules/routes/admin-notes-routes";
import polyglot from "../../utils/polyglot";
import AdminNotesSkeleton from "./AdminNotesSkelleton";
import NoteItem from "./NoteItem";

const validationSchema = yup.object().shape({ note: yup.string().required() });

const AdminNotes = ({ id, isOpen: _isOpen, onClose, user, job, selector }) => {
  const kind = user ? "user" : "job";
  const [isOpen, setIsOpen] = useState(_isOpen);
  const { data, isLoading } = useGetAdminNotes({ id, kind });
  const postAdminNote = usePostAdminNote();

  const handleClose = () => {
    if (onClose) onClose();
    setIsOpen(false);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  useEffect(() => {
    setIsOpen(_isOpen);
  }, [_isOpen]);

  useListenToTargetClick(selector, handleOpen);

  const handleSubmit = (values, { resetForm, validateForm }) => {
    postAdminNote.mutate(
      { ...values, id, kind },
      {
        onSuccess: () => {
          resetForm();
          validateForm();
        },
      }
    );
  };

  return (
    <Modal.Small isOpen={isOpen} onClose={handleClose}>
      <Modal.Item.Header onClose={handleClose}>
        {user && (
          <Modal.Item.Title>
            {polyglot.t("admin.note_for_jobber", {
              first_name: user.first_name,
              last_name: user.last_name,
            })}
          </Modal.Item.Title>
        )}
        {job && (
          <Modal.Item.Title>
            {polyglot.t("admin.note_for_job", { job: job.title })}
          </Modal.Item.Title>
        )}
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Block marginBottom={spacings.s}>
          {!isLoading && data ? (
            <>
              {data?.notes.map((note, index) => (
                <NoteItem
                  key={`admin-note-${index}`}
                  email={note.email}
                  formatted_created_at={note.formatted_created_at}
                  note={note.note}
                />
              ))}
            </>
          ) : (
            <AdminNotesSkeleton />
          )}
        </Block>
      </Modal.Item.Wrapper>
      <div
        css={`
          background: ${colors.background};
          position: sticky;
          bottom: 0;
        `}
      >
        <Formik
          validateOnMount
          initialValues={{ note: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isValid }) => (
            <Form>
              <Modal.Item.Wrapper>
                <Field name="note">
                  {({ field }) => (
                    <FormControl
                      label="Écrire une note"
                      css={`
                        margin-bottom: 0;
                      `}
                    >
                      <Textarea
                        {...field}
                        autoFocus
                        resize
                        placeholder={polyglot.t("common.placeholder.textarea")}
                      />
                    </FormControl>
                  )}
                </Field>
              </Modal.Item.Wrapper>
              <Modal.Item.Footer column>
                <Button.Large
                  type="submit"
                  disabled={!isValid}
                  block
                  isLoading={postAdminNote.isLoading}
                >
                  {polyglot.t("common.save")}
                </Button.Large>
              </Modal.Item.Footer>
            </Form>
          )}
        </Formik>
      </div>
    </Modal.Small>
  );
};

export default AdminNotes;
