import React from "react";
import { borderWidth, sizes, spacings } from "../../../assets/themes";
import { Block, Skeleton } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";

const Item = () => (
  <>
    <Block
      marginY={spacings.m}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Block display="flex" alignItems="center" marginY={spacings.s}>
        <Skeleton
          height={sizes.size36}
          width={sizes.size36}
          shape={SKELETON.SHAPE.CIRCLE}
          sr
        />
        <Skeleton height={sizes.size16} width={150} />
      </Block>
      <Skeleton
        height={sizes.size32}
        shape={SKELETON.SHAPE.ROUND}
        width={sizes.size54}
      />
    </Block>
    <Skeleton height={borderWidth.s} width="100%" />
  </>
);

const ManageNotificationsSkeleton = () => (
  <Skeleton.Group>
    <Block marginTop={spacings.l}>
      <Skeleton height={sizes.size18} width={150} st />
      <Item />
      <Block marginTop={spacings.l}>
        <Skeleton height={sizes.size18} width={150} st />
        <Item />
        <Item />
      </Block>
    </Block>
  </Skeleton.Group>
);

export default ManageNotificationsSkeleton;
