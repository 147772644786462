import polyglot from "@/utils/polyglot";
import artwork from "../../assets/removal-items";

export default {
  [polyglot.t("furniture.living_room")]: [
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: artwork.chair,
    },
    {
      label: polyglot.t("furniture.carpet"),
      value: "carpet",
      volume: 0.2,
      image: artwork.carpet,
    },
    {
      label: polyglot.t("furniture.two_seater_sofa"),
      value: "twoSeaterSofa",
      volume: 2.0,
      image: artwork.twoSeaterSofa,
    },
    {
      label: polyglot.t("furniture.three_seater_sofa"),
      value: "threeSeaterSofa",
      volume: 2.5,
      image: artwork.threeSeaterSofa,
    },
    {
      label: polyglot.t("furniture.corner_sofa"),
      value: "cornerSofa",
      volume: 3.0,
      image: artwork.cornerSofa,
    },
    {
      label: polyglot.t("furniture.armchair"),
      value: "armchair",
      volume: 4.0,
      image: artwork.armchair,
    },
    {
      label: polyglot.t("furniture.small_bookshelf"),
      value: "smallBookshelf",
      volume: 1.0,
      image: artwork.smallBookshelf,
    },
    {
      label: polyglot.t("furniture.large_bookshelf"),
      value: "largeBookshelf",
      volume: 2.0,
      image: artwork.largeBookshelf,
    },
    {
      label: polyglot.t("furniture.chandelier"),
      value: "chandelier",
      volume: 0.2,
      image: artwork.chandelier,
    },
    {
      label: polyglot.t("furniture.coffee_table"),
      value: "coffeeTable",
      volume: 0.5,
      image: artwork.coffeeTable,
    },
    {
      label: polyglot.t("furniture.living_room_table"),
      value: "livingRoomTable",
      volume: 1.5,
      image: artwork.livingRoomTable,
    },
    {
      label: polyglot.t("furniture.large_tv"),
      value: "largeTv",
      volume: 0.4,
      image: artwork.largeTv,
    },
    {
      label: polyglot.t("furniture.small_tv"),
      value: "smallTv",
      volume: 0.2,
      image: artwork.smallTv,
    },
    {
      label: polyglot.t("furniture.tv_stand"),
      value: "tvStand",
      volume: 0.7,
      image: artwork.tvStand,
    },
    {
      label: polyglot.t("furniture.small_desk"),
      value: "smallDesk",
      volume: 0.5,
      image: artwork.smallDesk,
    },
    {
      label: polyglot.t("furniture.large_desk"),
      value: "largeDesk",
      volume: 1.0,
      image: artwork.largeDesk,
    },
    {
      label: polyglot.t("furniture.large_plant"),
      value: "largePlant",
      volume: 0.3,
      image: artwork.largePlant,
    },
    {
      label: polyglot.t("furniture.computer"),
      value: "computer",
      volume: 0.1,
      image: artwork.computer,
    },
    {
      label: polyglot.t("furniture.printer"),
      value: "printer",
      volume: 0.1,
      image: artwork.printer,
    },
    {
      label: polyglot.t("furniture.stereo_system"),
      value: "stereoSystem",
      volume: 0.3,
      image: artwork.stereoSystem,
    },
    {
      label: polyglot.t("furniture.sideboard"),
      value: "sideboard",
      volume: 2.5,
      image: artwork.sideboard,
    },
    {
      label: polyglot.t("furniture.dresser"),
      value: "dresser",
      volume: 1.2,
      image: artwork.dresser,
    },
    {
      label: polyglot.t("furniture.floor_lamp"),
      value: "floorLamp",
      volume: 0.5,
      image: artwork.floorLamp,
    },
  ],
  [polyglot.t("furniture.kitchen")]: [
    {
      label: polyglot.t("furniture.worktop"),
      value: "worktop",
      volume: 1.0,
      image: artwork.worktop,
    },
    {
      label: polyglot.t("furniture.cooker"),
      value: "cooker",
      volume: 0.5,
      image: artwork.cooker,
    },
    {
      label: polyglot.t("furniture.cooking_plate"),
      value: "cookingPlate",
      volume: 0.1,
      image: artwork.cookingPlate,
    },
    {
      label: polyglot.t("furniture.refrigerator"),
      value: "refrigerator",
      volume: 1.0,
      image: artwork.refrigerator,
    },
    {
      label: polyglot.t("furniture.american_fridge"),
      value: "americanFridge",
      volume: 1.9,
      image: artwork.americanFridge,
    },
    {
      label: polyglot.t("furniture.freezer"),
      value: "freezer",
      volume: 1.5,
      image: artwork.freezer,
    },
    {
      label: polyglot.t("furniture.oven"),
      value: "oven",
      volume: 0.6,
      image: artwork.oven,
    },
    {
      label: polyglot.t("furniture.microwave"),
      value: "microwave",
      volume: 0.2,
      image: artwork.microwave,
    },
    {
      label: polyglot.t("furniture.dishwasher"),
      value: "dishwasher",
      volume: 0.5,
      image: artwork.dishwasher,
    },
    {
      label: polyglot.t("furniture.pots_and_pans"),
      value: "potsAndPans",
      volume: 0.2,
      image: artwork.potsAndPans,
    },
    {
      label: polyglot.t("furniture.dish_set"),
      value: "dishSet",
      volume: 1.0,
      image: artwork.dishSet,
    },
    {
      label: polyglot.t("furniture.coffee_maker"),
      value: "coffeeMaker",
      volume: 0.1,
      image: artwork.coffeeMaker,
    },
    {
      label: polyglot.t("furniture.small_appliances"),
      value: "smallAppliances",
      volume: 0.3,
      image: artwork.smallAppliances,
    },
    {
      label: polyglot.t("furniture.kitchen_table"),
      value: "kitchenTable",
      volume: 1.2,
      image: artwork.kitchenTable,
    },
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: artwork.chair,
    },
    {
      label: polyglot.t("furniture.lower_kitchen_cabinet"),
      value: "lowKitchenCabinet",
      volume: 1.0,
      image: artwork.lowKitchenCabinet,
    },
    {
      label: polyglot.t("furniture.high_kitchen_cabinet"),
      value: "highKitchenCabinet",
      volume: 0,
      image: artwork.highKitchenCabinet,
    },
    {
      label: polyglot.t("furniture.wine_cellar"),
      value: "wineCellar",
      volume: 0.5,
      image: artwork.wineCellar,
    },
  ],
  [polyglot.t("furniture.bathroom")]: [
    {
      label: polyglot.t("furniture.washing_machine"),
      value: "washingMachine",
      volume: 0.6,
      image: artwork.washingMachine,
    },
    {
      label: polyglot.t("furniture.dryer"),
      value: "dryer",
      volume: 0.6,
      image: artwork.dryer,
    },
    {
      label: polyglot.t("furniture.two_doors_wardrobe"),
      value: "twoDoorWardrobe",
      volume: 1.2,
      image: artwork.twoDoorWardrobe,
    },
    {
      label: polyglot.t("furniture.one_door_wardrobe"),
      value: "oneDoorWardrobe",
      volume: 0.6,
      image: artwork.oneDoorWardrobe,
    },
    {
      label: polyglot.t("furniture.medicine_cabinet"),
      value: "medicineCabinet",
      volume: 0.1,
      image: artwork.medicineCabinet,
    },
    {
      label: polyglot.t("furniture.mirror"),
      value: "mirror",
      volume: 0.4,
      image: artwork.mirror,
    },
    {
      label: polyglot.t("furniture.various_item"),
      value: "variousItems",
      volume: 0.4,
      image: artwork.variousItems,
    },
  ],
  [polyglot.t("furniture.bedroom")]: [
    {
      label: polyglot.t("furniture.single_bed"),
      value: "singleBed",
      volume: 1.2,
      image: artwork.singleBed,
    },
    { label: "Berceau", value: "crib", volume: 0.5, image: artwork.crib },
    {
      label: polyglot.t("furniture.single_matress"),
      value: "singleMattress",
      volume: 0.4,
      image: artwork.singleMattress,
    },
    {
      label: polyglot.t("furniture.two_doors_wardrobe"),
      value: "twoDoorWardrobe",
      volume: 1.2,
      image: artwork.twoDoorWardrobe,
    },
    {
      label: polyglot.t("furniture.one_door_wardrobe"),
      value: "oneDoorWardrobe",
      volume: 0.6,
      image: artwork.oneDoorWardrobe,
    },
    {
      label: polyglot.t("furniture.double_bed"),
      value: "doubleBed",
      volume: 3.0,
      image: artwork.doubleBed,
    },
    {
      label: polyglot.t("furniture.double_matress"),
      value: "doubleMattress",
      volume: 0.6,
      image: artwork.doubleMattress,
    },
    {
      label: polyglot.t("furniture.bunk_bed"),
      value: "bunkBed",
      volume: 2.5,
      image: artwork.bunkBed,
    },
    {
      label: polyglot.t("furniture.small_desk"),
      value: "smallDesk",
      volume: 0.5,
      image: artwork.smallDesk,
    },
    {
      label: polyglot.t("furniture.large_desk"),
      value: "largeDesk",
      volume: 1.5,
      image: artwork.largeDesk,
    },
    {
      label: polyglot.t("furniture.wall_shelf"),
      value: "wallShelf",
      volume: 0.06,
      image: artwork.wallShelf,
    },
    {
      label: polyglot.t("furniture.dressing"),
      value: "dressing",
      volume: 2.5,
      image: artwork.dressing,
    },
    {
      label: polyglot.t("furniture.nightstand"),
      value: "nightstand",
      volume: 0.5,
      image: artwork.nightstand,
    },
    {
      label: polyglot.t("furniture.dresser"),
      value: "dresser",
      volume: 1.2,
      image: artwork.dresser,
    },
    {
      label: polyglot.t("furniture.chair"),
      value: "chair",
      volume: 0.2,
      image: artwork.chair,
    },
    {
      label: polyglot.t("furniture.carpet"),
      value: "carpet",
      volume: 0.2,
      image: artwork.carpet,
    },
    {
      label: polyglot.t("furniture.toy_chest"),
      value: "toyChest",
      volume: 0.2,
      image: artwork.toyChest,
    },
  ],
  [polyglot.t("furniture.garden")]: [
    {
      label: polyglot.t("furniture.garden_table_for_eight"),
      value: "gardenTableForEight",
      volume: 2.0,
      image: artwork.gardenTableForEight,
    },
    {
      label: polyglot.t("furniture.lounge_chair"),
      value: "loungeChair",
      volume: 0.4,
      image: artwork.loungeChair,
    },
    {
      label: polyglot.t("furniture.garden_chair"),
      value: "gardenChair",
      volume: 0.3,
      image: artwork.gardenChair,
    },
    {
      label: polyglot.t("furniture.barbecue"),
      value: "barbecue",
      volume: 0.4,
      image: artwork.barbecue,
    },
    {
      label: polyglot.t("furniture.lawn_mower"),
      value: "lawnMower",
      volume: 0.4,
      image: artwork.lawnMower,
    },
    {
      label: polyglot.t("furniture.wheelbarrow"),
      value: "wheelbarrow",
      volume: 0.4,
      image: artwork.wheelbarrow,
    },
    {
      label: polyglot.t("furniture.various_tools"),
      value: "variousTools",
      volume: 0.4,
      image: artwork.variousTools,
    },
    {
      label: polyglot.t("furniture.parasol"),
      value: "parasol",
      volume: 0.2,
      image: artwork.parasol,
    },
  ],
  [polyglot.t("furniture.garage")]: [
    {
      label: polyglot.t("furniture.toolbox"),
      value: "toolbox",
      volume: 0.1,
      image: artwork.toolbox,
    },
    { label: "Vélo", value: "bicycle", volume: 0.7, image: artwork.bicycle },
    {
      label: polyglot.t("furniture.scooter"),
      value: "scooter",
      volume: 1.5,
      image: artwork.scooter,
    },
    {
      label: polyglot.t("furniture.sports_equipment"),
      value: "sportsEquipment",
      volume: 0.5,
      image: artwork.sportsEquipment,
    },
  ],
  [polyglot.t("furniture.misc")]: [
    {
      label: polyglot.t("furniture.standard_box"),
      value: "standardBox",
      volume: 0.1,
      image: artwork.standardBox,
    },
    {
      label: polyglot.t("furniture.book_box"),
      value: "bookBox",
      volume: 0.05,
      image: artwork.bookBox,
    },
    {
      label: polyglot.t("furniture.wardrobe_box"),
      value: "wardrobeBox",
      volume: 0.4,
      image: artwork.wardrobeBox,
    },
    {
      label: polyglot.t("furniture.bicycle"),
      value: "bicycle",
      volume: 0.7,
      image: artwork.bicycle,
    },
    {
      label: polyglot.t("furniture.vaccum"),
      value: "vacuum",
      volume: 0.2,
      image: artwork.vacuum,
    },
    {
      label: polyglot.t("furniture.ironing_board"),
      value: "ironingBoard",
      volume: 0.2,
      image: artwork.ironingBoard,
    },
    {
      label: polyglot.t("furniture.upright_piano"),
      value: "uprightPiano",
      volume: 2.0,
      image: artwork.uprightPiano,
    },
    {
      label: polyglot.t("furniture.frames_or_painting"),
      value: "framesOrPainting",
      volume: 0.1,
      image: artwork.framesOrPainting,
    },
  ],
};
