import moment from "moment";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";

export function getCalendarArr(date, acc = 0) {
  const curr = moment(date);
  return new Array(curr.add(acc, "months").daysInMonth())
    .fill(null)
    .map((_, i) => curr.startOf("month").add(i, "days").toDate());
}

export function getWeekDays() {
  moment.updateLocale(polyglot.locale, {
    week: {
      // to iso weekdays
      dow: 1,
    },
  });
  return moment.weekdaysShort(true).map((v) => v.replace(".", ""));
}

export const isValid = (date, format) => moment(date, format, true).isValid();

export const toDate = (date, format = "YYYY-MM-DD") =>
  moment(date, format).toDate();

export const formatTo = (date, from, to) => moment(date, from).format(to);

export const isSame = (date1, date2, kind = "days") =>
  moment(date1).isSame(moment(date2), kind);

export const isAfter = (date1, date2, format) =>
  moment(date1, format).isAfter(moment(date2, format), "days");

export const isBefore = (date1, date2, format) =>
  moment(date1, format).isBefore(moment(date2, format), "days");

export const isSameOrBefore = (date1, date2) =>
  moment(date1).isSameOrBefore(moment(date2), "days");

export const isBetweenOrSame = (date, { from, to }) =>
  moment(date).isBetween(moment(from), moment(to), "days") ||
  isSame(date, to) ||
  isSame(date, from);

export const isBetween = (date, { from, to }) =>
  moment(date).isBetween(moment(from), moment(to), "days");

export const getFirstIsoWeekDayOfMonth = (date, acc) =>
  moment(date).add(acc, "months").startOf("month").isoWeekday();

export const addDays = (date, n) => moment(date).add(n, "days").toDate();

export const addMonths = (date, n) => moment(date).add(n, "months").toDate();

export const getCalendarTitle = (date) =>
  capitalize(moment(date).locale(polyglot.locale).format("MMMM YYYY"));

export const getCalendarMonth = (date, acc) =>
  capitalize(
    moment(date).locale(polyglot.locale).add(acc, "months").format("MMMM")
  );

export const getCalendarYear = (date, acc) =>
  capitalize(
    moment(date).locale(polyglot.locale).add(acc, "months").format("YYYY")
  );

export const getIsoWeekDay = (date) => moment(date).isoWeekday();

export const isSameOrAfter = (date1, date2, kind = "days") =>
  moment(date1).isSameOrAfter(moment(date2), kind);

export const getMaskByFormat = (format) =>
  format.split("").map((c) => {
    if (c.match(/[a-z]/i)) {
      return /[0-9]/;
    }
    return c;
  });

export const getMonth = (date) => moment(date).month();
export const getYear = (date) => moment(date).year();

export const setDate = (date, type, n) => moment(date).set(type, n).toDate();

export const getDayIsDisabled = (
  date,
  { minRange, minDate, maxDate, disabledDate, disabledWeekDays, value }
) => {
  const isDisabled = [];
  if (minRange) {
    if (value[0]) {
      const maxRangeDate = addDays(value[0], minRange - 1);
      const minRangeDate = value[0];
      if (value[1]) {
        // If end date is selected you can still select the start date for change the range
        isDisabled.push(
          isBetween(date, { from: minRangeDate, to: maxRangeDate })
        );
      } else {
        isDisabled.push(
          isBetweenOrSame(date, { from: minRangeDate, to: maxRangeDate })
        );
      }
    }
  }
  if (minDate) {
    isDisabled.push(isBefore(date, minDate));
  }
  if (maxDate) {
    isDisabled.push(isSameOrAfter(date, maxDate));
  }
  if (disabledDate) {
    isDisabled.push(disabledDate(date));
  }
  if (disabledWeekDays) {
    isDisabled.push(
      !!disabledWeekDays.find(
        (disabledWeekDay) => getIsoWeekDay(date) === disabledWeekDay
      )
    );
  }
  return isDisabled.find((e) => e === true) || false;
};

export const getDatePickerMaxDate = () =>
  moment().add(50, "years").endOf("year").toDate();

export const getDatePickerMinDate = () =>
  moment().add(-100, "years").startOf("year").toDate();

export const getMonths = (date, { minDate, maxDate }) =>
  new Array(12).fill("").map((_, i) => {
    const accDate = moment(date).set("month", i).toDate();
    return {
      value: i,
      label: capitalize(moment(accDate).format("MMMM")),
      disabled: getDayIsDisabled(accDate, { minDate, maxDate }),
    };
  });

export const getYears = (_, { maxDate, minDate }) => {
  let maxYear;
  if (moment(maxDate).isBefore(moment(minDate), "years")) {
    maxYear = moment(minDate).add(1, "years").year();
  } else {
    maxYear = moment(maxDate).year();
  }
  const given = moment(maxYear, "YYYY").endOf("year");
  const current = moment(minDate).startOf("year");
  const diff = Math.floor(moment.duration(given.diff(current)).asYears());

  const res = new Array(diff).fill("").map((_, i) => ({
    value: maxYear - i,
    label: `${maxYear - i}`,
  }));
  return res;
};

export const getInitialValue = (val, format, formatTo) => {
  const getFormatedValue = (d) =>
    formatTo ? moment(d, format).format(formatTo) : moment(d, format).toDate();

  if (val) {
    if (typeof val === "object" && !(val instanceof Date)) {
      return val
        .filter((v) => !!v && v.length > 0)
        .map((d) => getFormatedValue(d));
    }
    return [getFormatedValue(val)];
  }
  return [];
};

export const getReorderedRange = (dates, format) => {
  if (isBefore(dates[1], dates[0], format)) {
    return [dates[1], dates[0]];
  }
  return [dates[0], dates[1]];
};

export const getEnhancedDates = (value, values, range, format) => {
  if (range) {
    if (values.length === 1) {
      if (isBefore(value, values[0], format)) {
        return [value];
      }
      return [values[0], value];
    }
    return [value];
  }
  return [value];
};

export const getOutputFormat = (d, format) => {
  if (format) {
    return moment(d).format(format);
  }
  return d;
};
