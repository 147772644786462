import { sizes, spacings } from "@/assets/themes";
import {
  Block,
  ConditionnalWrapper,
  Container,
  Divider,
  Skeleton,
} from "@/components";
import { SKELETON } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";

const JobberProfileSkeleton = () => {
  const breakpoints = useBreakpoints();
  return (
    <Skeleton.Group>
      <ConditionnalWrapper
        condition={breakpoints.get({ xs: false, md: true })}
        wrapper={(children) => <Container.Large>{children}</Container.Large>}
      >
        <Block
          paddingTop={{ xs: "0px", md: spacings.l }}
          paddingBottom={{ xs: "0px", md: spacings.l }}
          display="grid"
          gridCols={{ xs: "minmax(0, 1fr)", md: "350px minmax(0, 1fr)" }}
          gap={{ xs: spacings.ml, md: spacings.xl }}
        >
          <Skeleton width="100%" height="350px" />

          <ConditionnalWrapper
            condition={breakpoints.get({ xs: true, md: false })}
            wrapper={(children) => (
              <Container.Large>{children}</Container.Large>
            )}
          >
            <Block>
              <Skeleton width="200px" sb height={sizes.size36} />
              <Block marginTop={spacings.m}>
                <Skeleton width="80%" st height={sizes.size18} rows={3} />
              </Block>
              <Block marginY={spacings.ml}>
                <Divider.Cell />
              </Block>
              <Skeleton width="200px" sb height={sizes.size18} />
              <Skeleton width="100%" st height={sizes.size18} rows={3} />
              <Block marginY={spacings.ml}>
                <Divider.Cell />
              </Block>
              <Skeleton width="200px" sb height={sizes.size18} />
              <Block
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={spacings.s}
              >
                {Array(3)
                  .fill("")
                  .map((e, i) => (
                    <Skeleton
                      key={`skeleton-tag-${i}`}
                      width="100px"
                      height={sizes.size18}
                      shape={SKELETON.SHAPE.ROUND}
                    />
                  ))}
              </Block>

              <Block marginY={spacings.ml}>
                <Divider.Cell />
              </Block>
              <Skeleton width="200px" sb height={sizes.size18} />
              <Block
                display="flex"
                alignItems="center"
                flexWrap="wrap"
                gap={spacings.s}
              >
                {Array(5)
                  .fill("")
                  .map((e, i) => (
                    <Skeleton
                      key={`skeleton-tag2-${i}`}
                      width="100px"
                      height={sizes.size18}
                      shape={SKELETON.SHAPE.ROUND}
                    />
                  ))}
              </Block>

              <Block marginY={spacings.ml}>
                <Divider.Cell />
              </Block>
              <Skeleton width="200px" sb height={sizes.size18} />
              <Skeleton width="100%" st height={sizes.size18} rows={3} />
            </Block>
          </ConditionnalWrapper>
        </Block>
      </ConditionnalWrapper>
    </Skeleton.Group>
  );
};

export default JobberProfileSkeleton;
