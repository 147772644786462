import { useState } from "react";
import { colors, radius, spacings } from "../../assets/themes";
import { useBreakpoints } from "../../modules/hooks";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Divider from "../Divider";
import List from "../List";
import { TAG } from "../Styles/variants";
import Tag from "../Tag";
import { Body14 } from "../Text";
import SkillItem from "./SkillItem";

const OfferSkills = ({
  diploma,
  experience,
  stuffs,
  category_paid_offers_count,
  category_id,
  commitments,
  certificates,
  jobberId,
}) => {
  const [showAllStuffs, setShowAllStuffs] = useState(false);
  const breakpoints = useBreakpoints();
  const maxCollapsedVisibleSkills = breakpoints.get({ xs: 3, md: 6 });

  const toggleShowAllStuffs = () => {
    setShowAllStuffs(!showAllStuffs);
  };

  const getCertificates = () => {
    const arr = [];
    certificates.forEach((cert) => {
      arr.push(polyglot.t(`offer_certificates.${cert}`));
    });

    return arr.map((a) => <div>{a}</div>);
  };

  const displayedStuffs = showAllStuffs
    ? stuffs
    : stuffs.slice(0, maxCollapsedVisibleSkills);

  return (
    <Block
      backgroundColor={colors.backgroundLight}
      paddingY={spacings.xs}
      borderRadius={radius.ml}
      overflow="hidden"
    >
      {(diploma ||
        experience ||
        stuffs?.length > 0 ||
        category_paid_offers_count > 0) && (
        <List.Group>
          {category_paid_offers_count > 0 && (
            <SkillItem
              strong
              iconName={() => (
                <Block
                  borderRadius={radius.s}
                  padding={spacings.xs}
                  backgroundColor={colors.gray100}
                  display="inline-block"
                >
                  <Body14 strong>{category_paid_offers_count}</Body14>
                </Block>
              )}
            >
              {polyglot.t(`job.category_job_${category_id}_done_plural`, {
                count: category_paid_offers_count,
              })}
            </SkillItem>
          )}
          {certificates?.length > 0 && (
            <SkillItem iconName="diploma">{getCertificates()}</SkillItem>
          )}
          {diploma && certificates?.length === 0 && (
            <SkillItem iconName="diploma">{diploma}</SkillItem>
          )}
          {experience && (
            <SkillItem iconName="medal">
              {polyglot.t(`skills.experiences.${experience}`)}
            </SkillItem>
          )}
          {stuffs && stuffs.length > 0 && (
            <SkillItem iconName="work">
              <Block
                as="span"
                color={colors.muted}
                css={`
                  font-weight: normal;
                `}
              >
                {polyglot.t("common.owns")}{" "}
              </Block>
              {displayedStuffs
                .map((stuff) => polyglot.t(`skills.stuffs.${stuff}`))
                .join(", ")}
              {stuffs.length > maxCollapsedVisibleSkills && (
                <>
                  <span>...</span>
                  <Button.Link
                    css={`
                      padding: 0;
                      margin-left: ${spacings.xs};
                      color: ${colors.body};
                      &:hover {
                        color: ${colors.body};
                      }
                    `}
                    onClick={toggleShowAllStuffs}
                  >
                    {showAllStuffs
                      ? polyglot.t("common.see_less")
                      : polyglot.t("common.see_more")}
                  </Button.Link>
                </>
              )}
            </SkillItem>
          )}
        </List.Group>
      )}
      {commitments?.length > 0 && (
        <div>
          {(diploma || experience || stuffs?.length > 0) && <Divider.Cell />}
          <Block
            paddingX={spacings.sm}
            paddingY={spacings.s}
            display="flex"
            flexWrap="wrap"
            gap={spacings.xs}
          >
            {commitments.map((commitmentId) => (
              <Tag.Small
                kind={TAG.KIND.MUTED}
                key={`commitments-${commitmentId}-${jobberId}`}
              >
                {polyglot.t(`commitments.${commitmentId}.name`)}
              </Tag.Small>
            ))}
          </Block>
        </div>
      )}
    </Block>
  );
};
export default OfferSkills;
