import { useFormik } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import { useEffect } from "react";
import { spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Button,
  Container,
  FormControl,
  Modal,
  Select,
  Textarea,
} from "../../../components";
import {
  useGetNewTicket,
  usePostNewTicket,
} from "../../../modules/routes/ticket-routes";
import polyglot from "../../../utils/polyglot";
import StepTitle from "../../categoryForms/common/StepTitle";
import { TICKETS_PATH } from "../config/routes";
import TicketNewFilePicker from "./TicketNewFilePicker";
import TicketNewJobInput from "./TicketNewJobInput";
import TicketNewJobberInput from "./TicketNewJobberInput";

const validationSchema = yup.object().shape({
  body: yup.string().required(polyglot.t("common.errors.field_required")),
  category: yup.string().required(polyglot.t("common.errors.field_required")),
});

const OPTIONS = [
  { label: polyglot.t("tickets.cancellation_option"), value: "cancellation" },
  {
    label: polyglot.t("tickets.damage_malfunction_option"),
    value: "damage_malfunction",
  },
  { label: polyglot.t("tickets.payment_option"), value: "payment" },
  {
    label: polyglot.t("tickets.administrative_documents_option"),
    value: "administrative_documents",
  },
  { label: polyglot.t("tickets.use_option"), value: "use" },
  { label: polyglot.t("tickets.tax_credit_option"), value: "tax_credit" },
  { label: polyglot.t("tickets.other_option"), value: "other" },
];

const TicketNew = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fromJob = Number(searchParams.get("from_job"));
  const { data, isSuccess } = useGetNewTicket();
  const postNewTicket = usePostNewTicket();
  const history = useHistory();
  const formik = useFormik({
    validationSchema,
    validateOnChange: false,
    initialValues: {
      body: "",
      category: "",
      uploads: [],
      job_id: null,
      jobber_id: null,
    },
    onSubmit: postNewTicket.mutate,
  });

  const getJob = (id) => data?.jobs?.find((job) => job.id === id);

  useEffect(() => {
    // auto set value if from_job url params is valid
    if (isSuccess && fromJob && getJob(fromJob)) {
      formik.setFieldValue("job_id", fromJob);
    }
  }, [isSuccess, fromJob]);

  const handleUploadChange = (uploads) => {
    formik.setFieldValue("uploads", Object.keys(uploads));
  };

  return (
    <Block overflowY="auto" flex="1" height="100%">
      <Block display={{ xs: "block", md: "none" }}>
        <Modal.Item.Header onBack={() => history.replace(TICKETS_PATH)} />
      </Block>
      <Container.Small>
        <Block marginTop={{ xs: "0px", md: spacings.l }}>
          <Block marginBottom={spacings.m}>
            <StepTitle>{polyglot.t("tickets.contact_us")}</StepTitle>
            <Body16>{polyglot.t("tickets.subtitle_new")}</Body16>
          </Block>
          <form onSubmit={formik.handleSubmit}>
            <FormControl label="Raison" error={formik.errors.category}>
              <Select
                name="category"
                options={OPTIONS}
                onChange={({ value }) =>
                  formik.setFieldValue("category", value)
                }
                value={formik.values.category}
                placeholder={polyglot.t("common.placeholder.reason")}
              />
            </FormControl>
            <FormControl
              name="category"
              label={polyglot.t("tickets.concerned_job_optionnal")}
            >
              <TicketNewJobInput
                value={formik.values.job_id}
                jobs={data?.jobs}
                onChange={(jobId) => {
                  formik.setFieldValue("job_id", jobId);
                  if (!jobId) {
                    formik.setFieldValue("jobber_id", null);
                  }
                }}
              />
            </FormControl>
            {formik.values.job_id &&
              getJob(formik.values.job_id)?.booked_offers?.length > 0 && (
                <FormControl
                  name="category"
                  label={polyglot.t("tickets.concerned_jobber_optionnal")}
                >
                  <TicketNewJobberInput
                    value={formik.values.jobber_id}
                    job={getJob(formik.values.job_id)}
                    onChange={(jobber_id) =>
                      formik.setFieldValue("jobber_id", jobber_id)
                    }
                  />
                </FormControl>
              )}
            <FormControl
              label={polyglot.t("tickets.your_message")}
              error={formik.errors.body}
            >
              <Textarea
                name="body"
                placeholder={polyglot.t("tickets.message_placeholder")}
                onChange={formik.handleChange}
                value={formik.values.body}
              />
            </FormControl>
            <FormControl
              name="uploads[]"
              label={`${polyglot.t("common.add_attachments")} (${polyglot.t(
                "common.not_required"
              )})`}
            >
              <TicketNewFilePicker onChange={handleUploadChange} />
            </FormControl>
            <Block paddingBottom={spacings.m}>
              <Button.Large
                type="submit"
                block
                isLoading={postNewTicket.isLoading || postNewTicket.isSuccess}
              >
                {polyglot.t("common.send")}
              </Button.Large>
            </Block>
          </form>
        </Block>
      </Container.Small>
    </Block>
  );
};
export default TicketNew;
