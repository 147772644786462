import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Icon from "../Icon";
import { Body14 } from "../Text";

const HighDemandTag = () => (
  <Block
    gap={spacings.xs}
    color={colors.danger}
    display="flex"
    alignItems="center"
  >
    <Icon.Medium name="exclamation-circle-solid" />
    <Body14 strong>
      {polyglot.t("offer_badges.kinds.highly_requested_jobber")}
    </Body14>
  </Block>
);
export default HighDemandTag;
