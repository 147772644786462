import { useEffect } from "react";
import { colors, spacings } from "../../assets/themes";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Divider from "../Divider";
import { Col, Row } from "../Grid";
import List from "../List/index";
import { Body14, Body16 } from "../Text";

const ListEditable = ({
  name,
  value,
  RenderValue,
  RightComponent,
  LeftComponent,
  onSubmit,
  children,
  title,
  submitOnEnter,
  onExpend,
  disabled,
  submitDisabled,
  onCollapse,
  isLoading,
  content,
  isExpended,
  valueNumberOfLines = 1,
  placeholder = polyglot.t("common.not_specified"),
}) => {
  const handleKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      if (onSubmit) onSubmit();
    }
  };

  useEffect(() => {
    if (submitOnEnter) {
      const elem = document.getElementsByName(name)[0];
      elem?.addEventListener("keydown", handleKeyDown);
      return () => {
        elem?.removeEventListener("keydown", handleKeyDown);
      };
    }
    return () => {};
  }, [name, submitOnEnter, isExpended]);

  return (
    <div>
      {!isExpended && (
        <>
          <List.Item
            divider={false}
            RightComponent={() => (
              <>
                {RightComponent && RightComponent()}
                {!disabled && (
                  <Button.Link onClick={onExpend}>
                    {polyglot.t("common.edit")}
                  </Button.Link>
                )}
              </>
            )}
            LeftComponent={LeftComponent}
          >
            <Body14 color={colors.muted}>{title}</Body14>
            <Body16
              strong
              numberOfLines={valueNumberOfLines}
              css={`
                word-break: break-word;
                white-space: break-spaces;
              `}
            >
              {value}
            </Body16>
            {!value && placeholder && !RenderValue && (
              <Body16 color={colors.muted}>{placeholder}</Body16>
            )}
            {RenderValue && (
              <div
                css={`
                  margin-top: ${spacings.xs};
                  word-break: break-word;
                  white-space: break-spaces;
                `}
              >
                {RenderValue()}
              </div>
            )}
          </List.Item>
          {content && content()}
          <Divider.Cell />
        </>
      )}
      {isExpended && (
        <>
          <Block paddingTop={spacings.s} paddingBottom={spacings.m}>
            {children}
            <Row gutter={[spacings.s]}>
              <Col size="auto">
                <Button.Medium
                  kind="secondary"
                  disabled={isLoading}
                  onClick={() => {
                    onCollapse(false);
                  }}
                >
                  {polyglot.t("common.cancel")}
                </Button.Medium>
              </Col>
              <Col size="auto">
                <Button.Medium
                  block
                  onClick={onSubmit}
                  isLoading={isLoading}
                  disabled={submitDisabled}
                >
                  {polyglot.t("common.save")}
                </Button.Medium>
              </Col>
            </Row>
          </Block>
          <Divider.Cell />
        </>
      )}
    </div>
  );
};

export default ListEditable;
