import { FastField } from "formik";
import { colors } from "../../../assets/themes";
import { Block } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH } from "../constants";
import { FormInput, FormTextarea, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Details = ({ title }) => (
  <>
    <StepTitle>{title}</StepTitle>
    <FastField name="title">
      {({ field }) => (
        <StyledFormControl
          label={() => (
            <Block display="flex" justifyContent="space-between">
              <span>{polyglot.t("categories.job_title")}</span>
              <span
                css={`
                  color: ${colors.muted};
                `}
              >
                {field.value.length} / {MAX_TITLE_LENGTH}
              </span>
            </Block>
          )}
        >
          <FormInput
            clearable
            name={field.name}
            maxLength={MAX_TITLE_LENGTH}
            placeholder={polyglot.t("categories.add_explicite_title")}
          />
        </StyledFormControl>
      )}
    </FastField>
    <StyledFormControl
      label={`${polyglot.t("categories.additionnal_details")} (${polyglot.t(
        "common.not_required"
      )})`}
    >
      <FormTextarea
        name="description"
        resize
        maxLength={MAX_DESCRIPTION_LENGTH}
        placeholder={polyglot.t("common.placeholder.textarea")}
      />
    </StyledFormControl>
  </>
);

export default Details;
