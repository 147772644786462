import React from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Skeleton, Block, Col, Row } from "../../../components";

const WalletSkeleton = () => (
  <Skeleton.Group>
    <Skeleton height={sizes.size64} width="30%" sb />
    <Skeleton height={sizes.size16} width="50%" sb />
    <Block marginY={spacings.m}>
      <Row gutter={[spacings.s]}>
        <Col size={{ xs: 12, md: 6 }}>
          <Skeleton height={250} width="100%" sy />
        </Col>
        <Col size={{ xs: 12, md: 6 }}>
          <Skeleton height={250} width="100%" sy />
        </Col>
      </Row>
    </Block>
    <Skeleton height={sizes.size18} width="40%" sb />
    <Block marginY={spacings.m}>
      <Row>
        <Col size={{ xs: 12, xl: 6 }}>
          <Skeleton height={sizes.size54} width="100%" sb />
        </Col>
      </Row>
    </Block>
    <Skeleton height={sizes.size18} width="40%" sb />
    <Block marginY={spacings.m}>
      <Skeleton height={sizes.size18} width="30%" sb st />
      <Skeleton height={sizes.size48} width="100%" rows={3} />
    </Block>
    <Skeleton height={sizes.size18} width="20%" sb st />
    <Skeleton height={sizes.size48} width="100%" rows={5} />
  </Skeleton.Group>
);

export default WalletSkeleton;
