import { colors, spacings } from "../../assets/themes";
import { useBreakpoints } from "../../modules/hooks";
import { formatPhone } from "../../utils";
import polyglot from "../../utils/polyglot";
import Block from "../Block";
import Button from "../Button";
import Icon from "../Icon";
import List from "../List";
import { BUTTON, POPOVER } from "../Styles/variants";
import { Body16 } from "../Text";
import Popover from "../popover/Popover";

const OfferPhoneChat = ({ offer }) => {
  const breakpoints = useBreakpoints();
  return (
    <Block display="flex" alignItems="center">
      <Block marginBottom={spacings.s}>
        {breakpoints.get({
          xs: (
            <Popover.Enhancer
              trigger={POPOVER.TRIGGER_TYPE.CLICK}
              content={() => (
                <Popover.Elem.Menu>
                  <List.Item
                    withGutters
                    divider={false}
                    href={`tel:${offer.phone}`}
                    LeftComponent={() => (
                      <Icon.Medium name="phone" color={colors.primary} />
                    )}
                  >
                    <Body16 strong color={colors.primary}>
                      {formatPhone(offer.phone)}
                    </Body16>
                  </List.Item>
                </Popover.Elem.Menu>
              )}
            >
              <Button.Large
                kind={BUTTON.KIND.SECONDARY}
                shape={BUTTON.SHAPE.CIRCLE}
              >
                <Icon.Large name="phone" />
              </Button.Large>
            </Popover.Enhancer>
          ),
          xl: (
            <Popover.Enhancer
              trigger={POPOVER.TRIGGER_TYPE.CLICK}
              content={() => (
                <Popover.Elem.Menu>
                  <List.Item
                    withGutters
                    divider={false}
                    href={`tel:${offer.phone}`}
                    LeftComponent={() => (
                      <Icon.Medium name="phone" color={colors.primary} />
                    )}
                  >
                    <Body16 strong color={colors.primary}>
                      {formatPhone(offer.phone)}
                    </Body16>
                  </List.Item>
                </Popover.Elem.Menu>
              )}
            >
              <Button.Medium
                kind={BUTTON.KIND.SECONDARY}
                shape={BUTTON.SHAPE.PILL}
                LeftComponent={() => <Icon.Medium name="phone" />}
                RightComponent={() => <Icon.Small name="caret-down" />}
              >
                Appeler
              </Button.Medium>
            </Popover.Enhancer>
          ),
        })}
      </Block>
    </Block>
  );
};
export default OfferPhoneChat;
