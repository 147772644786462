import React from "react";
import { useHistory } from "react-router-dom";
import { Button, List, Modal, Toggle } from "../../../components";
import { LIST } from "../../../components/Styles/variants";
import SimulateTaxCredit from "../taxCreditModal/SimulateTaxCredit";

const Home = ({
  onSubmit,
  onClose,
  priceSimulationIsLoading,
  priceSimulation,
  checked,
  setChecked,
  nb_hours,
  price_per_hour,
  selectedJobber,
  frequency,
}) => {
  const history = useHistory();
  return (
    <div>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>Vous devez activer le déclaratif</Modal.Item.Title>
        <Modal.Item.Subtitle>
          Pour activer les paiements en CESU vous devez activer le déclaratif
        </Modal.Item.Subtitle>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        {/* <div className="my-4">
          <p className="display-4 font-weight-medium text-center mb-2">
            {polyglot.toSmartCurrency(user.cesu_wallet)}
          </p>
          <span className="text-muted text-center d-block">Montant CESU disponible</span>
        </div> */}
        <List.Item
          withGutters
          shape={LIST.SHAPE.ROUND}
          className="bg-light"
          divider={false}
          onClick={() => {
            checked ? setChecked(false) : history.push("/taxes");
          }}
          title="Mandater Yoojo pour déclarer le prestataire"
          subtitle="Éligible à un crédit d'impôt de 50%"
          RightComponent={() => (
            <Toggle name="urssaf" readOnly checked={checked} />
          )}
        />
        <div className="my-3">
          <SimulateTaxCredit
            urssaf={checked}
            isLoading={priceSimulationIsLoading}
            deduction={priceSimulation.deduction}
            employee_part={priceSimulation.employee_part}
            employer_part={priceSimulation.employer_part}
            deduction_term={priceSimulation.deduction_term}
            nb_hours={nb_hours}
            price_per_hour={price_per_hour}
            selectedJobber={selectedJobber}
            frequency={frequency}
          />
        </div>

        {checked && (
          <Button.Large onClick={() => onSubmit({ urssaf: checked })}>
            Enregistrer
          </Button.Large>
        )}
        <Button.Large
          kind={checked ? "minimal" : "secondary"}
          className="mt-2"
          onClick={onClose}
        >
          Annuler
        </Button.Large>
      </Modal.Item.Wrapper>
    </div>
  );
};

export default Home;
