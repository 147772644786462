import { Field } from "formik";
import React from "react";
import { colors } from "../assets/themes";
import Block from "./Block";
import FormControl from "./FormControl";
import Icon from "./Icon";
import Input from "./Input";

const InputIcon = ({
  icon,
  placeholder,
  type,
  name,
  autoComplete,
  hidden = false,
  disabled = false,
  autoFocus,
  onChange,
  ...rest
}) => (
  <Block height={hidden && 0} overflow={hidden && "hidden"}>
    <Field name={name}>
      {({ field, meta }) => (
        <FormControl error={meta.touched && meta.error}>
          <Input
            {...rest}
            {...field}
            type={type}
            autoFocus={autoFocus}
            onChange={onChange || field.onChange}
            hidden={hidden}
            autoComplete={autoComplete}
            placeholder={placeholder}
            disabled={disabled}
            LeftComponent={
              !hidden
                ? () => <Icon.Large name={icon} color={colors.mutedLight} />
                : null
            }
          />
        </FormControl>
      )}
    </Field>
  </Block>
);

export default InputIcon;
