import React from "react";
import styled from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../../../assets/themes";
import {
  Avatar,
  Badge,
  Block,
  Body14,
  Body16,
  Body18,
  Divider,
  Icon,
  List,
  Shape,
} from "../../../../components";
import { BADGE, LIST, SHAPE } from "../../../../components/Styles/variants";
import polyglot from "../../../../utils/polyglot";
import JobberOfferItem from "../JobberOfferItem";

const StyledAlert = styled(List.Item)`
  background-color: ${colors.primaryLight};
`;

const AvatarInline = ({ src }) => {
  const remaining = src?.length - src?.slice(0, 3).length;
  return (
    <Block display="flex" alignItems="center">
      {src?.slice(0, 3).map((avatar, index) => (
        <div
          key={`avatar-inline-alert-${index}`}
          css={`
            position: relative;
            margin-left: ${index !== 0 && `calc(${spacings.m} * -1)`};
          `}
        >
          <Avatar
            key={`offer-avatar-${index}`}
            src={avatar}
            size={`calc(${sizes.size36} + ${borderWidth.l})`}
            css={`
              border: ${borderWidth.l} solid ${colors.primaryLight};
            `}
          />
        </div>
      ))}
      {remaining > 0 && (
        <Block
          marginLeft={`calc(${spacings.m} * -1)`}
          position="relative"
          borderRadius={radius.circle}
        >
          <Shape.Small
            shape={SHAPE.SHAPE.CIRCLE}
            backgroundColor={colors.muted}
            color={colors.onColor}
            css={`
              outline: ${borderWidth.l} solid ${colors.primaryLight};
            `}
          >
            <Body14 strong>+{remaining}</Body14>
          </Shape.Small>
        </Block>
      )}
    </Block>
  );
};

const JobContent = ({
  nb_jobbers,
  booked_offers,
  pending_offers_avatars,
  pending_offers_wording,
  pending_offers_title,
}) => (
  <Block>
    <Block display={{ xs: "none", xl: "block" }}>
      <Body18>
        {polyglot.t("dashboard.job.booked_jobbers_title_plural", {
          count: nb_jobbers,
        })}
      </Body18>
    </Block>
    {booked_offers.length > 0 && (
      <Block display={{ xs: "block", xl: "none" }}>
        <Divider.Cell />
      </Block>
    )}
    {booked_offers.map((booked_offer, index, arr) => (
      <React.Fragment key={`offer-${booked_offer.id}`}>
        <Block marginBottom={spacings.m}>
          <JobberOfferItem {...booked_offer} />
          {index !== arr.length - 1 && (
            <Block paddingTop={spacings.s}>
              <Divider.Cell />
            </Block>
          )}
        </Block>
      </React.Fragment>
    ))}
    <Block display={{ xs: "none", md: "block" }}>
      {booked_offers.length === 0 && (
        <>
          <Block marginY={spacings.m}>
            <Body16 color={colors.muted}>
              {polyglot.t("jobs.no_jobber_booked")}
            </Body16>
          </Block>
        </>
      )}
    </Block>
    {(pending_offers_avatars || pending_offers_wording) && (
      <Divider.Cell
        css={`
          margin-bottom: ${spacings.m};
        `}
      />
    )}
    {pending_offers_avatars && (
      <Block marginBottom={spacings.m}>
        <StyledAlert
          withGutters
          shape={LIST.SHAPE.ROUND}
          divider={false}
          LeftComponent={() => (
            <Block alignSelf="start">
              <Badge
                position={BADGE.POSITION.TOP_RIGHT}
                dot
                outlineColor={colors.primaryLight}
              >
                <Icon.Large name="bell" color={colors.primary} />
              </Badge>
            </Block>
          )}
        >
          <Body16 strong color={colors.primary}>
            {polyglot.t("dashboard.job.offers_received_plural", {
              count: pending_offers_avatars.length,
            })}
          </Body16>
          <AvatarInline src={pending_offers_avatars} />
        </StyledAlert>
      </Block>
    )}
    {pending_offers_wording && (
      <Block marginBottom={spacings.m}>
        <StyledAlert
          withGutters
          divider={false}
          shape={LIST.SHAPE.ROUND}
          LeftComponent={() => (
            <Block alignSelf="start">
              <Icon.Large
                name="bell"
                color={colors.primary}
                css="align-self: flex-start"
              />
            </Block>
          )}
        >
          <Body16 strong color={colors.primary}>
            {pending_offers_title}
          </Body16>
          <Body14 color={colors.muted}>{pending_offers_wording}</Body14>
        </StyledAlert>
      </Block>
    )}
  </Block>
);

export default JobContent;
