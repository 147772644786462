import { useFormikContext } from 'formik';
import React from 'react';
import { Container, Flow, Selectable } from '../../components';

const SetFrequency = ({ goNext, goBack, title }) => {
  const { values, setFieldValue, isValid } = useFormikContext();
  const selectedJobber = !values.jobber_id ? values.external_jobber : values.jobber;

  return (
    <Container.Small>
      <Flow.Item.Title>
        Comment souhaitez-vous rémunérer {selectedJobber?.first_name} ?
      </Flow.Item.Title>
      <div className="row">
        <div className="col-6">
          <Selectable.Medium
            title="Toutes les semaines"
            checked={values.frequency === 'weekly'}
            onChange={() => setFieldValue('frequency', 'weekly')}
          />
        </div>
        <div className="col-6">
          <Selectable.Medium
            title="Tous les mois"
            checked={values.frequency === 'monthly'}
            onChange={() => setFieldValue('frequency', 'monthly')}
          />
        </div>
      </div>
      <Flow.Item.Navigation goNext={goNext} goBack={goBack} isValid={isValid} />
    </Container.Small>
  );
};

export default SetFrequency;
