import { useFormikContext } from "formik";
import polyglot from "../../../../utils/polyglot";
import { FormInput, StyledFormControl } from "../fields";
import StepTitle from "../StepTitle";

const LastName = () => {
  const { errors } = useFormikContext();

  return (
    <>
      <StepTitle>{polyglot.t("categories.what_is_your_name")}</StepTitle>
      <StyledFormControl error={errors.user?.last_name}>
        <FormInput
          name="user.last_name"
          autoComplete="family-name"
          placeholder={polyglot.t("common.placeholder.last_name")}
        />
      </StyledFormControl>
    </>
  );
};

export default LastName;
