import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Src20 from "@/assets/images/svg-icons/main-categories-icons/20.svg";
import Src21 from "@/assets/images/svg-icons/main-categories-icons/21.svg";
import Src22 from "@/assets/images/svg-icons/main-categories-icons/22.svg";
import Src23 from "@/assets/images/svg-icons/main-categories-icons/23.svg";
import Src24 from "@/assets/images/svg-icons/main-categories-icons/24.svg";
import Src25 from "@/assets/images/svg-icons/main-categories-icons/25.svg";
import Src26 from "@/assets/images/svg-icons/main-categories-icons/26.svg";
import Src28 from "@/assets/images/svg-icons/main-categories-icons/28.svg";
import Src29 from "@/assets/images/svg-icons/main-categories-icons/29.svg";
import { sizes, spacings } from "@/assets/themes";
import { Block, Body14, Icon, Tabs } from "@/components";
import { useDebounce } from "@/modules/hooks";
import { getCategoryDataById } from "@/pages/categoriesNavigationModal/utils";
import polyglot from "@/utils/polyglot";
import ForYouIcon from "@/assets/images/home/for-you.svg";

const IMAGES = {
  20: Src20,
  21: Src21,
  22: Src22,
  23: Src23,
  24: Src24,
  25: Src25,
  26: Src26,
  28: Src28,
  29: Src29,
};

const HomeTabs = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const initialTabId = Number(params.get("category"));
  const [activeTab, setActiveTab] = useState(initialTabId || "");
  const [navigationData] = useState(getCategoryDataById());
  const debouncedActiveTab = useDebounce(activeTab, 100);
  const history = useHistory();

  const handleChange = (value) => {
    setActiveTab(value);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (debouncedActiveTab) {
      searchParams.set("category", debouncedActiveTab);
    } else {
      searchParams.delete("category");
    }
    history.push({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : null,
    });
  }, [debouncedActiveTab, history, location.pathname]);

  useEffect(() => {
    const updateTabFromUrl = () => {
      const params = new URLSearchParams(location.search);
      const tabId = Number(params.get("category"));
      setActiveTab(tabId || "");
    };
    updateTabFromUrl();
    const unlisten = history.listen(() => {
      updateTabFromUrl();
    });
    return () => {
      unlisten();
    };
  }, [history, location.search]);

  return (
    <Tabs value={activeTab} onChange={handleChange} enableChangeOnSwipe fill>
      <Tabs.Tab value="">
        <Block
          display="flex"
          flexDirection="column"
          gap={spacings.s}
          paddingY={{ xs: spacings.xs, md: spacings.s }}
        >
          <img
            src={ForYouIcon}
            alt=""
            css={`
              width: ${sizes.size20};
              height: auto;
            `}
          />
          <Body14>{polyglot.t("home.for_you")}</Body14>
        </Block>
      </Tabs.Tab>
      {navigationData.map((cat) => (
        <Tabs.Tab key={cat.id} value={cat.id}>
          <Block
            display="flex"
            flexDirection="column"
            gap={spacings.s}
            paddingY={{ xs: spacings.xs, md: spacings.s }}
          >
            <img
              alt={polyglot.t(`categories.${cat.id}.name`)}
              src={IMAGES[cat.id]}
              css={`
                width: ${sizes.size24};
                height: auto;
                filter: ${activeTab !== cat.id
                  ? "grayscale(1) brightness(0)"
                  : "none"};
              `}
            />
            <Body14>{polyglot.t(`categories.${cat.id}.name`)}</Body14>
          </Block>
        </Tabs.Tab>
      ))}
    </Tabs>
  );
};

export default HomeTabs;
