import React, { useEffect, useState } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { Toast } from "../components";
import { getRelation } from "../modules/routes";
import polyglot from "../utils/polyglot";
import CancelSubscription from "./relation/CancelSubscription";
import Documents from "./relation/Documents";
import History from "./relation/History";
import NewEmployementContract from "./relation/NewEmployementContract";
import Payments from "./relation/Payments";
import Show from "./relation/Show";

const Relation = ({ id, errorMessage }) => {
  const [data, setData] = useState(null);
  const [dataIsLoading, setDataIsLoading] = useState(null);
  const location = useLocation();

  const getData = (id) => {
    setDataIsLoading(true);
    const res = getRelation({ id });
    res
      .then(({ data }) => {
        setData(data);
        setDataIsLoading(false);
      })
      .catch((error) => {
        setDataIsLoading(false);
        console.error(error);
      });
  };

  useEffect(() => {
    getData(id);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const updateData = () => {
    getData(id);
  };

  return (
    <>
      <Switch>
        <Route
          exact
          path="/"
          render={() => (
            <Show
              id={id}
              data={data}
              updateData={updateData}
              isLoading={dataIsLoading}
            />
          )}
        />
        <Route
          path="/payments_history"
          render={() => (
            <Payments id={id} data={data} updateData={updateData} />
          )}
        />
        <Route
          path="/cancel_subscription"
          render={() => <CancelSubscription id={id} />}
        />
        <Route
          path="/actions_history"
          render={() => <History id={id} data={data} />}
        />
        <Route
          path="/employement-contract/new"
          render={() => <NewEmployementContract id={id} />}
        />
        <Route path="/documents" render={() => <Documents id={id} />} />
      </Switch>
      {!!errorMessage && (
        <Toast
          title={polyglot.t("common.problem_has_occurred")}
          body={errorMessage}
          icon="exclamation-circle"
          type="danger"
          isVisible={!!errorMessage}
        />
      )}
    </>
  );
};

export default Relation;
