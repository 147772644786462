import axios from 'axios';
import getCSRFToken from '../../utils/csrf';

const client = axios.create({
  baseURL: '/',
  responseType: 'json',
  timeout: 30000,
});

client.interceptors.request.use(async config => {
  config.headers.Accept = 'application/json';
  config.headers['Content-Type'] = 'application/json';
  return config;
});

export const sendJobMessage = ({ id, body }) => {
  getCSRFToken();
  return client.post(`/inboxes/${id}/create_message`, { message: { body } });
};

export const getJobMessages = ({ id }) => {
  getCSRFToken();
  return client.get(`/inboxes/${id}`, { data: null });
};
