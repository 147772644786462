import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Lift = ({ title, name, goNext }) => (
  <>
    <StepTitle>{title}</StepTitle>
    <StyledFormControl>
      <FormSelectables
        onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
        name={name}
        options={[
          { value: false, label: polyglot.t("common.no") },
          { value: true, label: polyglot.t("common.yes") },
        ]}
      />
    </StyledFormControl>
  </>
);
export default Lift;
