import React from "react";
import * as yup from "yup";
import polyglot from "../../../utils/polyglot";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { INFORMATIONS_PATH } from "../constants";

const validationSchema = yup.object().shape({
  nh_specific: yup.number().required(),
});

const config = { IsNbHoursReadOnly: true };

export function useCategory6011() {
  const initialValues = {
    nh_specific: "",
  };

  const getNbHours = (values) => values.nh_specific;

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.course_duration_amount"),
      options: [
        { value: 1.0, label: polyglot.toDuration(1) },
        { value: 2.0, label: polyglot.toDuration(2) },
        { value: 3.0, label: polyglot.toDuration(3) },
        { value: 4.0, label: polyglot.toDuration(4) },
        { value: 5.0, label: polyglot.toDuration(5) },
        { value: 6.0, label: polyglot.toDuration(6) },
        { value: 7.0, label: polyglot.toDuration(7) },
        { value: 8.0, label: polyglot.toDuration(8) },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.what_is_your_need"),
      validationSchema,
      View: () => (
        <>
          <StyledFormControl label={nhConfig.nh_specific.label}>
            <FormSelectables
              isWrap
              name="nh_specific"
              title={nhConfig.nh_specific.label}
              options={nhConfig.nh_specific.options}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
