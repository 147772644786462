import { useFormikContext } from "formik";
import React, { useState } from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Block, Body16, Button, List, Radio } from "../../../components";
import { formatCardNumber } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import AddCreditCard from "./AddCreditCard";
import NewCreditCardModal from "./credit-card-fields/NewCreditCardModal";
import {
  getCreditCardTypeByName,
  getPaymentMethodBadge,
  getPaymentMethodConfig,
} from "./utils";

const CreditCard = ({
  methods,
  needCCCheck,
  onChange,
  withGutters,
  disabled,
  cardRegistrationDataParams,
}) => {
  const { values } = useFormikContext();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <>
      {values.card_number ? (
        <Block position="relative">
          <List.Item
            RightComponent={() => (
              <Radio
                checked={
                  !getPaymentMethodConfig(values.payment_method)?.external &&
                  !disabled
                }
              />
            )}
            disabled={disabled}
            withGutters={withGutters}
            onClick={() => {
              onChange({
                payment_method: getCreditCardTypeByName(values.card_type),
              });
            }}
            LeftComponent={() => (
              <img
                src={getPaymentMethodBadge(values.card_type)}
                alt={values.card_type}
              />
            )}
          >
            <Body16 strong>{formatCardNumber(values.card_number)}</Body16>
          </List.Item>
          <Block
            position="absolute"
            top="0"
            right="0"
            bottom="0"
            display="flex"
            alignItems="center"
            marginRight={`calc(${spacings.m} + ${sizes.size24})`}
          >
            <Button.Link
              disabled={disabled}
              css={`
                padding-left: 0;
                padding-right: 0;
              `}
              onClick={(e) => {
                e.stopPropagation();
                setModalIsOpen(true);
              }}
            >
              {polyglot.t("common.edit")}
            </Button.Link>
          </Block>
        </Block>
      ) : (
        <AddCreditCard
          withGutters={withGutters}
          disabled={disabled}
          onClick={() => {
            setModalIsOpen(true);
          }}
        />
      )}
      <NewCreditCardModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        cardRegistrationDataParams={cardRegistrationDataParams}
        methods={methods}
        needCCCheck={needCCCheck}
        onChange={onChange}
      />
    </>
  );
};

export default CreditCard;
