/* eslint-disable jsx-a11y/media-has-caption */
import { colors, radius, sizes } from "@/assets/themes";
import { Block } from "@/components";
import Icon from "@/components/Icon";

const VideoWrapper = ({ children }) => (
  <div style={{ position: "relative", display: "inline-block" }}>
    {children}
    <Block
      width={sizes.size48}
      height={sizes.size48}
      backgroundColor={colors.backgroundBlur}
      backdropBlur={20}
      display="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius={radius.circle}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Icon.Large name="play-solid" color={colors.body} />
    </Block>
  </div>
);

export default VideoWrapper;
