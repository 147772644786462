import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { colors, spacings } from "../../assets/themes";
import { Block, FormControl, SegmentedControl, Slider } from "../../components";
import Result from "./Result";

const CONFIG = [
  {
    min: 5,
    max: 300,
    suffix: "cm",
    step: 5,
    format: (v) => v / 100,
  },
  {
    min: 1,
    max: 10,
    suffix: "m",
    step: 0.5,
    format: (v) => v,
  },
];

const VolumeCalculator = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { min, max, format, suffix, step } = useMemo(
    () => CONFIG[tabIndex],
    [tabIndex]
  );

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {
      width: max / 2,
      height: max / 2,
      long: max / 2,
    },
  });

  const handleTabChange = (i) => {
    setTabIndex(i);
    formik.setValues({
      width: CONFIG[i].max / 2,
      height: CONFIG[i].max / 2,
      long: CONFIG[i].max / 2,
    });
  };

  return (
    <Block
      borderRadius={spacings.l}
      backgroundColor={colors.backgroundLight}
      paddingX={spacings.m}
      paddingTop={spacings.m}
      width="100%"
      maxWidth={500}
    >
      <form>
        <Block backgroundColor={colors.background} borderRadius={spacings.m}>
          <Block padding={spacings.m}>
            <Block paddingBottom={spacings.s}>
              <SegmentedControl
                data={[{ title: "Centimètres" }, { title: "Mètres" }]}
                activeIndex={tabIndex}
                onChange={handleTabChange}
              />
            </Block>
            <div key={`tab-${tabIndex}`}>
              <FormControl name="long" label="Longueur">
                <Slider
                  persisentThumb
                  min={min}
                  max={max}
                  step={step}
                  ticks={[`${min} ${suffix}`, `${max} ${suffix}`]}
                  value={formik.values.long}
                  onChange={(v) => {
                    formik.setFieldValue("long", v);
                  }}
                  formatValue={(v) => `${v} ${suffix}`}
                />
              </FormControl>
              <FormControl name="width" label="Largeur">
                <Slider
                  persisentThumb
                  min={min}
                  max={max}
                  step={step}
                  ticks={[`${min} ${suffix}`, `${max} ${suffix}`]}
                  value={formik.values.width}
                  onChange={(v) => {
                    formik.setFieldValue("width", v);
                  }}
                  formatValue={(v) => `${v} ${suffix}`}
                />
              </FormControl>
              <FormControl name="height" label="Hauteur">
                <Slider
                  persisentThumb
                  min={min}
                  max={max}
                  step={step}
                  ticks={[`${min} ${suffix}`, `${max} ${suffix}`]}
                  value={formik.values.height}
                  onChange={(v) => {
                    formik.setFieldValue("height", v);
                  }}
                  formatValue={(v) => `${v} ${suffix}`}
                />
              </FormControl>
            </div>
          </Block>
        </Block>
        <Block
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Result
            width={format(formik.values.width)}
            height={format(formik.values.height)}
            long={format(formik.values.long)}
          />
        </Block>
      </form>
    </Block>
  );
};
export default VolumeCalculator;
