import React, { useEffect, useRef } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { spacings } from "../../../../assets/themes";
import { Alert, Block, Button, Modal } from "../../../../components";
import { BUTTON } from "../../../../components/Styles/variants";
import { creditCardValidation, formatCardNumber } from "../../../../utils";
import { getCreditCardType } from "../../../../utils/payment";
import polyglot from "../../../../utils/polyglot";
import CreditCardFields from "./CreditCardFields";
import { useTokenizeCreditCard } from "../../../../modules/routes/payment-routes";
import { getTokenisationErrorMessage } from "../utils";

const NewCreditCardModal = ({
  onClose,
  onChange,
  methods,
  needCCCheck,
  cardRegistrationDataParams,
  isOpen,
}) => {
  const { isLoading, mutate, error, isError, reset } = useTokenizeCreditCard();
  const formikRef = useRef();

  useEffect(() => {
    if (error) {
      // useTokenizeCreditCard onError temp fix
      formikRef.current.setStatus(
        getTokenisationErrorMessage(error.ResultCode)
      );
    }
  }, [isError]);

  const handleSubmit = (values) => {
    reset();
    mutate(
      {
        ...values,
        ...cardRegistrationDataParams,
        cardType: getCreditCardType(values.cardNumber),
      },
      {
        onSuccess: (data, variables) => {
          onChange({
            card_number: formatCardNumber(variables.cardNumber),
            payment_method: getCreditCardType(variables.cardNumber),
            card_type: getCreditCardType(variables.cardNumber),
            token: data.CardId,
          });
          onClose();
        },
      }
    );
  };
  return (
    <Modal.Small onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Header title="Ajouter une carte bancaire" onClose={onClose} />
      <Modal.Item.Wrapper>
        {needCCCheck && (
          <Block marginBottom={spacings.s}>
            <Alert.Low
              title={polyglot.t(
                "payment_method.you_credit_card_must_be_verified"
              )}
            />
          </Block>
        )}
      </Modal.Item.Wrapper>
      <Formik
        innerRef={formikRef}
        initialValues={{
          cardNumber: "",
          cardType: "",
          cardExpirationDate: "",
          cardCvx: "",
        }}
        onSubmit={handleSubmit}
        validateOnMount
        validationSchema={yup.object().shape({ ...creditCardValidation })}
      >
        {({ isValid, dirty }) => (
          <Form className="flex-fill d-flex flex-column">
            <CreditCardFields
              onSubmit={handleSubmit}
              methods={methods}
              withGutters
            >
              <Modal.Item.Footer column>
                <Button.Large
                  isLoading={isLoading}
                  block
                  disabled={!isValid || !dirty}
                  type="submit"
                >
                  {polyglot.t("common.save")}
                </Button.Large>
                <Button.Large
                  block
                  kind={BUTTON.KIND.MINIMAL}
                  onClick={onClose}
                >
                  {polyglot.t("common.cancel")}
                </Button.Large>
              </Modal.Item.Footer>
            </CreditCardFields>
          </Form>
        )}
      </Formik>
    </Modal.Small>
  );
};

export default NewCreditCardModal;
