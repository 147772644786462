import React from "react";
import styled from "styled-components";
import ProBadge from "../assets/images/svg-icons/Badges/Pro.svg";
import VipBadge from "../assets/images/svg-icons/Badges/Vip.svg";
import { spacings } from "../assets/themes";

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const BadgeWrapper = styled.div`
  margin-left: ${spacings.s};
`;

const StatusEnhancer = ({ children, isPro, isVip, content }) => (
  <StyledWrapper>
    {children}
    {isPro && (
      <BadgeWrapper>
        <img src={ProBadge} alt="Pro" />
      </BadgeWrapper>
    )}
    {isVip && (
      <BadgeWrapper>
        <img src={VipBadge} alt="Vip" />
      </BadgeWrapper>
    )}
    {content && <BadgeWrapper>{content()}</BadgeWrapper>}
  </StyledWrapper>
);

export default StatusEnhancer;
