import { Accordion, Body16 } from "../../components";

const Faq = ({ data }) => (
  <>
    <Accordion.Group>
      {data.map((article) => (
        <Accordion title={article.title}>
          <Body16>
            <div dangerouslySetInnerHTML={{ __html: article.body }} />
          </Body16>
        </Accordion>
      ))}
    </Accordion.Group>
  </>
);

export default Faq;
