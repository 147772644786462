import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { spacings } from "../../../assets/themes";
import { Alert, Button, FormControl, Input, Modal } from "../../../components";
import { ALERT } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";
import SelectZipCode from "./SelectZipCode";

const ManualAddress = ({ onSubmit, onClose, onBack, status }) => (
  <Formik
    validateOnMount
    onSubmit={onSubmit}
    validationSchema={yup.object().shape({
      address_name: yup.string().required(),
      city_id: yup.string().required(),
    })}
    initialValues={{
      address_name: "",
      city_id: "",
      zip_name: "",
      location: [],
    }}
  >
    {({ isValid, handleSubmit, values, setValues }) => (
      <Form>
        <Modal.Item.Header
          onClose={onClose}
          title={polyglot.t("common.add_manual_address")}
          onBack={onBack}
        />
        <div className="px-3 flex-fill d-flex flex-column">
          {status && (
            <Alert.Low
              kind={ALERT.KIND.DANGER}
              css={`
                margin-bottom: ${spacings.s};
              `}
            >
              {status}
            </Alert.Low>
          )}
          <FormControl label={polyglot.t("common.complete_address")}>
            <Field name="address_name">
              {({ field }) => (
                <Input
                  autoFocus
                  autoComplete="address"
                  placeholder={polyglot.t(
                    "common.placeholder.num_and_address_name"
                  )}
                  {...field}
                />
              )}
            </Field>
          </FormControl>
          <FormControl label={polyglot.t("common.zipcode")}>
            <SelectZipCode
              name="city_id"
              onChange={({ value, label, location = [] }) => {
                const latitude = location[0];
                const longitude = location[1];
                setValues({
                  ...values,
                  zip_name: label,
                  city_id: value,
                  location: location ? { latitude, longitude } : null,
                });
              }}
            />
          </FormControl>
          <Button.Large
            block
            className="mt-auto"
            disabled={!isValid}
            onClick={handleSubmit}
          >
            {polyglot.t("common.save")}
          </Button.Large>
        </div>
      </Form>
    )}
  </Formik>
);

export default ManualAddress;
