import { MemoryRouter, Route } from "react-router-dom";
import { colors } from "../../assets/themes";
import { Icon, List, Popover } from "../../components";
import { POPOVER } from "../../components/Styles/variants";
import useJob from "../../modules/hooks/useJob";
import polyglot from "../../utils/polyglot";
import CloseJobModal from "../dashboard/job/CloseJobModal";
import MultipleCloseJobModal from "../dashboard/job/MultipleCloseJobModal";
import EditJobModal from "../editJob/EditJobModal";
import {
  CLOSE_MULTIPLE_PATH,
  CLOSE_PATH,
  EDIT_INFORMATIONS_PATH,
  getCancelOfferUrl,
  getSelectedOffers,
} from "./utils";

const EditJobEnhancer = ({ children }) => {
  const { data } = useJob();

  const closeJobRedirection = () => {
    if (getSelectedOffers(data.accepted_offers)?.length === 1) {
      return {
        href: getCancelOfferUrl(getSelectedOffers(data.accepted_offers)[0].id),
      };
    }
    if (getSelectedOffers(data.accepted_offers)?.length > 1) {
      return { to: CLOSE_MULTIPLE_PATH };
    }
    return { to: CLOSE_PATH };
  };

  const getEditMenu = () => [
    {
      label: polyglot.t("job.edit"),
      to: EDIT_INFORMATIONS_PATH,
      icon: "edit",
    },
    {
      label: polyglot.t("jobs.cancel_job"),
      icon: "calendar-times",
      color: colors.danger,
      ...closeJobRedirection(),
    },
  ];

  return (
    <MemoryRouter>
      <Route
        render={({ history }) => (
          <>
            <Popover.Enhancer
              trigger={POPOVER.TRIGGER_TYPE.CLICK}
              position={POPOVER.POSITIONS.BOTTOM_RIGHT}
              content={({ close }) => (
                <Popover.Elem.Menu>
                  {getEditMenu().map((item, i) => (
                    <List.Item
                      divider={false}
                      key={`job-menu-${i}`}
                      withGutters
                      disabled={item.disabled}
                      href={item.href}
                      onClick={() => {
                        if (item.to) {
                          history.replace(item.to);
                        }
                        if (item.onClick) item.onClick();
                        close();
                      }}
                      LeftComponent={() => (
                        <Icon.Large name={item.icon} color={item.color} />
                      )}
                    >
                      <List.Elem.Title color={item.color}>
                        {item.label}
                      </List.Elem.Title>
                    </List.Item>
                  ))}
                </Popover.Elem.Menu>
              )}
            >
              {children}
            </Popover.Enhancer>

            <Route
              path={[
                `${EDIT_INFORMATIONS_PATH}/:field`,
                EDIT_INFORMATIONS_PATH,
              ]}
            >
              <EditJobModal isOpen onClose={() => history.replace("/")} />
            </Route>
            <Route path={CLOSE_PATH}>
              <CloseJobModal isOpen onClose={() => history.replace("/")} />
            </Route>
            <Route path={CLOSE_MULTIPLE_PATH}>
              <MultipleCloseJobModal
                isOpen
                onClose={() => history.replace("/")}
              />
            </Route>
          </>
        )}
      />
    </MemoryRouter>
  );
};

export default EditJobEnhancer;
