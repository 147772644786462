import React from "react";
import styled, { css } from "styled-components";
import {
  borderWidth,
  colors,
  radius,
  sizes,
  spacings,
} from "../../assets/themes";
import Icon from "../Icon";
import Spinner from "../Spinner";
import { ButtonBase } from "../Styles/Base";
import { LIST } from "../Styles/variants";

const getSize = (size) => {
  if (size === LIST.SIZE.LARGE) {
    return css`
      min-height: ${sizes.size72};
    `;
  }
  if (size === LIST.SIZE.COMPACT) {
    return css`
      min-height: ${sizes.size36};
    `;
  }
  return css`
    min-height: ${sizes.size48};
  `;
};

const getSizePy = (size) => {
  if (size === LIST.SIZE.LARGE) {
    return css`
      padding-top: ${spacings.sm};
      padding-bottom: ${spacings.sm};
    `;
  }
  if (size === LIST.SIZE.COMPACT) {
    return css`
      padding-top: ${spacings.xs};
      padding-bottom: ${spacings.xs};
    `;
  }
  return css`
    padding-top: ${spacings.m};
    padding-bottom: ${spacings.m};
  `;
};

const StyledWrapper = styled.div`
  width: 100%;
`;

const StyledLeftComponent = styled.div`
  ${({ size }) => css`
    ${getSizePy(size)};
    padding-right: ${spacings.sm};
    display: flex;
    align-items: center;
  `}
`;

const StyledRightComponent = styled.div`
  margin-left: ${spacings.s};
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  min-height: 100%;
  align-items: center;
  ${({ divider }) =>
    divider &&
    css`
      border-bottom: solid ${borderWidth.s} ${colors.border};
    `}
`;

const StyledChildren = styled.div`
  ${({ size }) => css`
    ${getSize(size)};
    ${getSizePy(size)};
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `}
`;

const StyledList = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["withGutters", "isLoading", "align", "shape", "size"].includes(prop) &&
    defaultValidatorFn(prop),
})`
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
      > * {
        pointer-events: none;
      }
    `}
  ${({ withGutters }) =>
    withGutters &&
    css`
      padding-left: ${spacings.m};
      ${StyledContent} {
        padding-right: ${spacings.m};
      }
    `}
  ${({ align, shape }) => css`
    transition: none;
    display: flex;
    align-items: ${align};
    align-items: stretch;
    justify-content: between;
    border-radius: ${shape === LIST.SHAPE.ROUND ? radius.ml : 0};
    color: ${colors.body};
    width: 100%;
    position: relative;
    text-align: left;
    text-decoration: none !important;
  `}
  
  ${({ onClick, href, to, disabled }) =>
    (onClick || href || to) &&
    !disabled &&
    css`
      cursor: pointer !important;
      overflow: hidden;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparent;
        pointer-events: none;
      }
      &:active {
        position: relative;
        &:after {
          background-color: ${colors.overlayDark};
          transition: background-color 0.1s;
        }
      }
      &:hover,
      &:hover&:focus {
        background: ${colors.gray50};
        color: ${colors.body};
      }
    `}
`;

const StyledLoadingDiv = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${colors.overlayLight};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const setType = ({ href, onClick }) => {
  if (href) {
    return "a";
  }
  if (onClick && !href) {
    return "button";
  }
  return null;
};

const Main = React.forwardRef(
  (
    {
      onClick,
      href,
      children,
      divider = true,
      RightComponent,
      LeftComponent,
      chevron,
      target,
      style,
      withGutters,
      isLoading,
      disabled,
      shape = LIST.SHAPE.DEFAULT,
      align = "center",
      size = LIST.SIZE.DEFAULT,
      ...rest
    },
    ref
  ) => (
    <StyledList
      // eslint-disable-next-line no-nested-ternary
      as={href ? "a" : onClick ? ButtonBase : null}
      type={setType({ onClick, href })}
      style={style}
      shape={shape}
      onClick={onClick}
      href={href}
      ref={ref}
      target={target}
      align={align}
      size={size}
      disabled={disabled}
      withGutters={withGutters}
      {...rest}
    >
      {LeftComponent && (
        <StyledLeftComponent size={size}>{LeftComponent()}</StyledLeftComponent>
      )}
      <StyledWrapper>
        <StyledContent divider={divider}>
          {children && <StyledChildren size={size}>{children}</StyledChildren>}
          {(RightComponent || chevron) && (
            <>
              {RightComponent && (
                <StyledRightComponent>{RightComponent()}</StyledRightComponent>
              )}
              {chevron && (onClick || href) && (
                <StyledRightComponent>
                  <Icon.Medium name="angle-right" color={colors.muted} />
                </StyledRightComponent>
              )}
            </>
          )}
        </StyledContent>
      </StyledWrapper>

      {isLoading && (
        <StyledLoadingDiv>
          <Spinner.Small color={colors.muted} />
        </StyledLoadingDiv>
      )}
    </StyledList>
  )
);

export default Main;
