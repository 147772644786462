import moment from "moment";
import { useCategoryForm } from "../../../modules/hooks";
import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import { getMinDate } from "../../../utils/job-date-time";
import { FormDatePicker, StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Date = ({ goNext, title }) => {
  const { config } = useCategoryForm();
  const handleChange = (val) => {
    setTimeout(() => {
      if (config.isDateRange) {
        if (val.length === 2) {
          goNext();
        }
      } else {
        goNext();
      }
    }, INSTANT_GO_NEXT_DELAY);
  };

  const getActiveMonth = () => {
    const isLastDayOfMonth = moment().isSame(moment().endOf("month"), "day");
    if (isLastDayOfMonth) {
      return moment().startOf("month").add(1, "month").toDate();
    }
    return null;
  };
  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <FormDatePicker
          name="date"
          endName="end_date"
          minDate={getMinDate()}
          activeMonth={getActiveMonth()}
          range={config.isDateRange}
          monthsShown={config.isDateRange ? 2 : 1}
          onChange={handleChange}
        />
      </StyledFormControl>
    </>
  );
};

export default Date;
