import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { spacings } from "../assets/themes";
import {
  Block,
  Button,
  Checkbox,
  Divider,
  H4,
  List,
  Modal,
  Selectable,
  Toggle,
} from "../components";
import { LIST } from "../components/Styles/variants";
import { useWindowSize } from "../modules/hooks";
import { setCheckboxFieldValue } from "../utils/form";
import polyglot from "../utils/polyglot";

const MAX_EQUIPMENTS_PREVIEW = 3;

const Wrapper = ({ children, isOpen, onClose, size }) =>
  size.width <= 991 ? (
    <Modal.FullScreen isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>{polyglot.t("role.filters.title")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>{children}</Modal.Item.Wrapper>
    </Modal.FullScreen>
  ) : (
    <div className="radius-l border pb-3 px-3">{children}</div>
  );

const RoleFilters = ({ stuffs = [], experiences = [], rails }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const size = useWindowSize();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const formRef = useRef();
  const [stuffIsExpanded, setStuffIsExpanded] = useState(false);

  const handleSubmit = (values) => {
    const formatedValues = Object.keys(values)
      .filter((key) => values[key])
      .map((key) => {
        if (Array.isArray(values[key])) {
          return values[key].map((value) => `${key}[]=${value}`).join("&");
        }
        return `${key}=${values[key].toString()}`;
      });
    const searchParams = formatedValues.join("&");
    window.location = `${rails.pathname}?${searchParams}#filters`;
  };

  const handleReset = () => {
    window.location = `${rails.pathname}#filters`;
  };

  const renderStuffs = ({ values, setFieldValue }, a = 0, b = 0) =>
    stuffs.slice(a, b).map((stuff) => (
      <Block marginY={spacings.sm} key={`stuffs-${stuff.value}`}>
        <Checkbox
          onChange={() =>
            setCheckboxFieldValue("stuffs", stuff.value, {
              values,
              setFieldValue,
            })
          }
          name="stuffs"
          checked={values?.stuffs?.includes(stuff.value)}
        >
          {stuff.label}
        </Checkbox>
      </Block>
    ));

  const getFilterAmount = ({ values }) => {
    let v = 0;
    if (values?.diploma) v += 1;
    if (values?.experiences?.length > 0) v += values?.experiences.length - 1;
    if (values?.stuffs?.length > 0) v += values?.stuffs.length - 1;
    return v;
  };
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        stuffs: urlParams.getAll("stuffs[]") || null,
        experiences: urlParams.getAll("experiences[]") || null,
        diploma: urlParams.get("diploma") === "true" || false,
      }}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <>
          <button
            type="button"
            onClick={() => setModalIsOpen(true)}
            className="d-inline-block d-lg-none mb-3"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              margin: 0,
            }}
          >
            <Selectable.Small
              name="filter"
              title={`${polyglot.t("role.jobber_filter")} ${
                getFilterAmount({ values }) > 0
                  ? `(${getFilterAmount({ values })})`
                  : ""
              }`}
              checked={
                values?.diploma ||
                values?.experiences?.length > 0 ||
                values?.stuffs?.length > 0
              }
              icon="icon-filter"
            />
          </button>
          <Wrapper
            isOpen={modalIsOpen}
            onClose={() => setModalIsOpen(false)}
            size={size}
          >
            <Form>
              <List.Header>
                {polyglot.t("role.filters.equipments")}{" "}
                {values?.stuffs.length > 0 ? `(${values?.stuffs.length})` : ""}
              </List.Header>
              {renderStuffs(
                { values, setFieldValue },
                0,
                MAX_EQUIPMENTS_PREVIEW
              )}
              {stuffs.length <= MAX_EQUIPMENTS_PREVIEW && <Divider.Cell />}
              {stuffs.length > MAX_EQUIPMENTS_PREVIEW && (
                <>
                  {!stuffIsExpanded && (
                    <List.Item
                      onClick={() => setStuffIsExpanded(true)}
                      RightComponent={() => (
                        <i className="icon-angle-down text-muted" />
                      )}
                    >
                      <span className="font-weight-medium text-primary">
                        {polyglot.t("role.filters.expand_equipments", {
                          amount: stuffs.length - MAX_EQUIPMENTS_PREVIEW,
                        })}
                      </span>
                    </List.Item>
                  )}
                  {stuffIsExpanded && (
                    <>
                      {renderStuffs(
                        { values, setFieldValue },
                        3,
                        stuffs.length
                      )}
                      <List.Item
                        onClick={() => setStuffIsExpanded(false)}
                        RightComponent={() => (
                          <i className="icon-angle-up text-muted" />
                        )}
                      >
                        <span className="font-weight-medium text-primary">
                          {polyglot.t("role.filters.collapse_equipments_title")}
                        </span>
                      </List.Item>
                    </>
                  )}
                </>
              )}
              <List.Item
                size={LIST.SIZE.LARGE}
                onClick={() => setFieldValue("diploma", !values.diploma)}
                RightComponent={() => (
                  <Toggle name="diploma" checked={values.diploma} readOnly />
                )}
              >
                <H4>{polyglot.t("common.graduates")}</H4>
              </List.Item>
              <List.Header style={{ minHeight: 56 }} className="mb-2">
                {polyglot.t("role.filters.experience")}
              </List.Header>
              <div className="d-flex flex-wrap">
                {experiences.map((experience) => (
                  <Selectable.Small
                    key={`exp-${experience.value}`}
                    name="experiences"
                    type="checkbox"
                    checked={values.experiences?.includes(experience.value)}
                    onChange={() =>
                      setCheckboxFieldValue("experiences", experience.value, {
                        values,
                        setFieldValue,
                      })
                    }
                    title={experience.label}
                    className="mr-2 mb-2"
                  />
                ))}
              </div>
              <div className="d-flex flex-column mt-3">
                <Button.Large
                  type="submit"
                  block
                  LeftComponent={() => <i className="icon-filter" />}
                >
                  {polyglot.t("common.filter")}
                </Button.Large>
                <Button.Large
                  kind="secondary"
                  className="mt-2"
                  block
                  LeftComponent={() => <i className="icon-trash" />}
                  onClick={() => handleReset()}
                >
                  {polyglot.t("common.clear")}
                </Button.Large>
              </div>
            </Form>
          </Wrapper>
        </>
      )}
    </Formik>
  );
};

export default RoleFilters;
