import React from "react";
import { borderWidth, sizes, spacings } from "../../assets/themes";
import { Block, Skeleton } from "../../components";

const AdminNotesSkeleton = () => (
  <Skeleton.Group>
    <Block paddingY={spacings.m}>
      <Block>
        <Block
          display="flex"
          alignItems="center"
          marginBottom={spacings.m}
          width="100%"
        >
          <Block flex="1">
            <Skeleton height={sizes.size14} width="40%" sr />
          </Block>
          <Skeleton height={sizes.size16} width="20%" />
        </Block>
        <Skeleton height={sizes.size16} width="100%" sb rows={1} />
        <Skeleton height={borderWidth.s} width="100%" st />
      </Block>
    </Block>
  </Skeleton.Group>
);

export default AdminNotesSkeleton;
