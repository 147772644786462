import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function useRefetchOnHistoryEvent({ refetch }) {
  const history = useHistory();
  const [initialPathname] = useState(window.location.pathname);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (initialPathname === history.location.pathname) {
        refetch();
      }
    });
    return () => {
      unlisten();
    };
  }, []);
}
