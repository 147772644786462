import { useLocation } from "react-router-dom";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body16, Link } from "../../../components";
import { JOBBER } from "../../../pages-admin/UserPreview/config";
import UserPreview from "../../../pages-admin/UserPreview/UserPreview";
import polyglot from "../../../utils/polyglot";
import JobberOfferItemContent from "./JobberOfferItemContent";
import { OFFER_STATE_SELECTED } from "@/pages/Job/utils";
import OfferTransactionDetails from "@/components/JobOfferItem/OfferTransactionDetails";
import { getTransactionDetailsPath } from "@/utils/get-transaction-details-path";

const PaiementInfo = ({
  paid_at,
  canceled_at,
  state,
  id,
  last_instant_payment,
}) => {
  const location = useLocation();
  if (last_instant_payment) {
    return (
      <Block marginBottom={spacings.m}>
        <Body16 color={colors.muted}>
          {polyglot.t(`dashboard.job.${state}_at`, {
            date: paid_at || canceled_at,
          })}
        </Body16>
        <Link
          to={{
            pathname: `/account/wallet/${id}/details_offer`,
            state: { background: location },
          }}
        >
          {polyglot.t("jobs.see_transaction_details")}
        </Link>
      </Block>
    );
  }
  return (paid_at || canceled_at) && last_instant_payment ? (
    <Block marginBottom={spacings.m}>
      <Body16 color={colors.muted}>
        {polyglot.t(`dashboard.job.${state}_at`, {
          date: paid_at || canceled_at,
        })}
      </Body16>
      <Link
        to={{
          pathname: `/account/wallet/${id}/details_offer`,
          state: { background: location },
        }}
      >
        {polyglot.t("jobs.see_transaction_details")}
      </Link>
    </Block>
  ) : (
    <div />
  );
};

const JobberOfferItem = ({
  jobber,
  id,
  price,
  state,
  paid_at,
  urlTargetBlank,
  canceled_at,
  hideDetails,
  instant_payment_relation_id,
  last_instant_payment,
  RightComponent,
  ...rest
}) => (
  <>
    <UserPreview id={jobber.id} kind={JOBBER}>
      <JobberOfferItemContent
        jobber={jobber}
        id={id}
        price={price}
        state={state}
        paid_at={paid_at}
        urlTargetBlank={urlTargetBlank}
        canceled_at={canceled_at}
        hideDetails={hideDetails}
        RightComponent={RightComponent}
        {...rest}
      />
      {!hideDetails && (
        <OfferTransactionDetails
          offer={{
            canceled_at,
            paid_at,
            instant_payment_relation_id,
            state,
            last_instant_payment,
          }}
          renderLink={({ label }) => {
            const location = useLocation();
            return (
              <Link
                to={{
                  pathname: getTransactionDetailsPath({
                    id,
                    last_instant_payment,
                  }),
                  state: { background: location },
                }}
              >
                {label}
              </Link>
            );
          }}
        />
      )}
    </UserPreview>
  </>
);

export default JobberOfferItem;
