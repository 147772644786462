import { useContext } from "react";
import { colors, spacings } from "../../../assets/themes";
import { Block, Body14, Body16, List, Progress } from "../../../components";
import { LIST, PROGRESS } from "../../../components/Styles/variants";
import VipBadge from "../../../assets/images/svg-icons/Badges/Vip.svg";
import { DashboardContext } from "../../../modules/contexts";

const VIPListItem = ({ vip_status }) => {
  const { public_pages } = useContext(DashboardContext);

  return (
    <List.Item
      chevron
      withGutters
      divider={false}
      href={public_pages.vip}
      shape={LIST.SHAPE.ROUND}
      css={`
        background-color: ${colors.purpleLight};
        &:hover {
          background-color: ${colors.purpleLight};
        }
      `}
      RightComponent={() => (
        <Progress.Bar
          LeftComponent={() => (
            <Body14 strong color={colors.purple}>
              {vip_status?.progress} %
            </Body14>
          )}
          css={`
            &:before {
              background-color: ${colors.purple};
            }
          `}
          shape={PROGRESS.SHAPE.ROUND}
          value={vip_status?.progress}
        />
      )}
    >
      <Block display="flex" alignItems="center">
        <Body16
          strong
          css={`
            margin-right: ${spacings.xs};
          `}
        >
          Statut
        </Body16>
        <img src={VipBadge} alt="VIP" />
      </Block>
    </List.Item>
  );
};
export default VIPListItem;
