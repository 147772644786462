import React from "react";
import {
  radius,
  shadows,
  sizes,
  borderWidth,
  colors,
  spacings,
} from "../../../assets/themes";
import { Skeleton, Block } from "../../../components";

const NotificationsSkeleton = () => (
  <Skeleton.Group>
    {new Array(10).fill("").map((_, i) => (
      <Block
        key={`notifications-skel-${i}`}
        borderRadius={radius.m}
        boxShadow={shadows.xs}
        marginY={spacings.m}
        padding={spacings.m}
        border={`solid ${borderWidth.s} ${colors.border}`}
      >
        <Skeleton height={sizes.size16} width="30%" sb />
        <Skeleton height={sizes.size16} width="100%" />
      </Block>
    ))}
  </Skeleton.Group>
);

export default NotificationsSkeleton;
