import { useFormikContext } from "formik";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { api } from "../../utils";
import { useFetch, useToast } from "../hooks";

const FETCH_KEY = "BOOKING";
export const useGetBookingData = () =>
  useFetch(`${window.location.pathname}`, {
    queryKey: [FETCH_KEY],
    refetchOnWindowFocus: false,
  });

export const useAddVoucher = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { setFieldValue } = useFormikContext();
  return useMutation(
    (values) => api.put(`${window.location.pathname}/add_voucher`, values),
    {
      onSuccess: ({ data }, variables) => {
        queryClient.setQueryData([FETCH_KEY], (old) => {
          const newArr = { ...old };
          return {
            ...newArr,
            ...data.data,
          };
        });
        setFieldValue("voucher_code", variables.code);
      },
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};

export const useRemoveVoucher = () => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const { setFieldValue } = useFormikContext();
  return useMutation(
    () => api.delete(`${window.location.pathname}/remove_voucher`),
    {
      onSuccess: ({ data }) => {
        queryClient.setQueryData([FETCH_KEY], (old) => {
          const newArr = old;
          return {
            ...newArr,
            ...data.data,
          };
        });
        setFieldValue("voucher_code", "");
      },
      onError: (err) => toast.danger(err.response.data.error_message),
    }
  );
};

export const useSubmitBooking = () => {
  const toast = useToast();
  return useMutation(
    (values) => api.put(`${window.location.pathname}/accept`, values),
    {
      onSuccess: ({ data }) => {
        window.location.href = data.data.redirect_url;
      },
      onError: (err) => {
        if (err.response.status === 403) {
          window.location.href = err.response.data?.redirect_url_on_error;
        } else {
          toast.danger(err.response?.data?.error_message);
        }
      },
    }
  );
};
