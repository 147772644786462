const config = {};

export function useCategory7017() {
  const initialValues = {};

  const getNbHours = () => 1;

  const nhConfig = {};

  return {
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
