import { INIT_DATA } from "../../utils/types";

export default function RailsReducer(state = {}, action) {
  switch (action.type) {
    case INIT_DATA:
      return { ...state, ...action.payload };
    default:
      break;
  }
  return state;
}
