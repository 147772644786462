import polyglot from "@/utils/polyglot";
import { Button, H3, H4, Modal } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import { usePutPublish } from "../../../modules/routes/job-routes";

const PublishJobModal = ({ onClose, id, isOpen = true }) => {
  const publishJob = usePutPublish({ id });
  const handleSubmit = () => {
    publishJob.mutate(null, {
      onSuccess: () => {
        if (onClose) onClose();
      },
    });
  };
  return (
    <Modal.XSmall isOpen={isOpen} onClose={onClose}>
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title as={H3}>
          {polyglot.t("job.publish_jobbers")}
        </Modal.Item.Title>
        <Modal.Item.Subtitle>
          {polyglot.t("job.publish_jobbers_near_you")}
        </Modal.Item.Subtitle>
      </Modal.Item.Header>
      <Modal.Item.Footer>
        <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={onClose}>
          {polyglot.t("common.cancel")}
        </Button.Medium>
        <Button.Medium
          onClick={handleSubmit}
          isLoading={publishJob.isLoading || publishJob.isSuccess}
        >
          {polyglot.t("common.confirm")}
        </Button.Medium>
      </Modal.Item.Footer>
    </Modal.XSmall>
  );
};

export default PublishJobModal;
