import React, { useState } from "react";
import { DatePicker } from "../components";

const CalendarInput = ({ name, value: _value, range, ...rest }) => {
  const [value, setValue] = useState(_value);

  const handleChange = (date) => {
    setValue(date);
  };

  return (
    <>
      <DatePicker.Calendar
        onChange={handleChange}
        value={value}
        range={range}
        {...rest}
      />
      {range ? (
        <>
          <input name={name[0]} value={value[0] || ""} hidden />
          <input name={name[1]} value={value[1] || ""} hidden />
        </>
      ) : (
        <input name={name} value={value || ""} hidden />
      )}
    </>
  );
};

export default CalendarInput;
