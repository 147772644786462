import { borderWidth, colors, sizes, spacings } from "@/assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Caption,
  ConditionnalWrapper,
  Rating,
  ReadMore,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import polyglot from "@/utils/polyglot";
import { getCustomerSince } from "./utils";
import UserPreview from "@/pages-admin/UserPreview";
import { CUSTOMER } from "@/pages-admin/UserPreview/config";

const RateItem = ({
  customer,
  job_title,
  rate,
  onClick,
  comment,
  created_at,
  isCard,
}) => (
  <ConditionnalWrapper
    condition={isCard}
    wrapper={(children) => (
      <CardBase
        flat
        type="button"
        onClick={onClick}
        css={`
          border: solid ${borderWidth.s} ${colors.border};
        `}
      >
        <Block paddingX={spacings.m} paddingY={spacings.s} height={236}>
          {children}
        </Block>
      </CardBase>
    )}
  >
    <Block
      spaceY={spacings.s}
      paddingY={spacings.s}
      display="flex"
      flexDirection="column"
      height={isCard ? "100%" : undefined}
    >
      <Block spaceY={spacings.s}>
        <UserPreview id={customer.id} kind={CUSTOMER}>
          <Block display="flex" gap={spacings.sm}>
            <Avatar
              size={sizes.size48}
              name={customer.first_name}
              src={customer.avatar}
            />
            <Block>
              <Body16 strong>{customer.first_name}</Body16>
              <Body14 color={colors.muted}>
                {getCustomerSince(customer.member_since).label}
              </Body14>
            </Block>
          </Block>
        </UserPreview>
        <Block display="flex" alignItems="center" flexWrap="wrap">
          <Rating rate={rate} />
          &nbsp;
          <Caption strong numberOfLines={1}>
            {created_at}
          </Caption>
          {!!customer.regular_customer && (
            <Caption numberOfLines={1} strong color={colors.muted}>
              &nbsp;•&nbsp;{polyglot.t("jobber_profile.repeater")}
            </Caption>
          )}
        </Block>
      </Block>
      <Block
        spaceY={spacings.xxs}
        flex={isCard ? "1" : undefined}
        display="flex"
        flexDirection="column"
        justifyContent={isCard ? "space-between" : undefined}
      >
        <Body16>
          {isCard ? (
            <ReadMore
              numberOfLines={3}
              css={`
                button {
                  color: ${colors.body}!important;
                  pointer-events: none;
                }
              `}
            >
              {comment}
            </ReadMore>
          ) : (
            comment
          )}
        </Body16>

        <Body14 color={colors.muted} numberOfLines={isCard ? 1 : undefined}>
          {job_title}
        </Body14>
      </Block>
    </Block>
  </ConditionnalWrapper>
);

export default RateItem;
