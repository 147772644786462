import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { colors } from "../../assets/themes";
import { Badge, Block, BottomNavigation } from "../../components";
import { GET_DASHBOARD_MAIN_MENU } from "./config/main-menu";
import { ACCOUNT_PATH } from "./config/routes";

const BottomNavItemEnhancer = ({ item, active, onClick, data }) => {
  const hasChild = item.LeftComponent?.props?.children;

  const iconName =
    item.LeftComponent?.props?.name ||
    item.LeftComponent?.props?.children?.props?.name;

  const EnhancedChildren =
    item.LeftComponent &&
    React.cloneElement(item.LeftComponent, {
      name: `${iconName}${active ? "-solid" : ""}`,
      outlineColor: colors.background,
      ...(hasChild && {
        children: {
          ...item.LeftComponent.props.children,
          props: {
            ...item.LeftComponent.props.children.props,
            name: `${iconName}${active ? "-solid" : ""}`,
          },
        },
      }),
    });

  const SetChildrenActiveStyle = ({ path }) => {
    if (path === ACCOUNT_PATH) {
      return <Badge count={data.tickets_count}>{EnhancedChildren}</Badge>;
    }
    return EnhancedChildren;
  };

  return (
    <BottomNavigation.Item
      key={`${item.path}`}
      active={active}
      name={item.path}
      onClick={onClick}
      IconComponent={() => SetChildrenActiveStyle({ path: item.path })}
      label={item.title}
    />
  );
};

const BottomNav = ({ data, user }) => {
  const history = useHistory();
  const [active, setActive] = useState(history.location.pathname);

  useEffect(() => {
    const unlisten = history.listen((location) => {
      setActive(location.pathname);
    });
    return () => unlisten();
  }, [history]);

  return ReactDOM.createPortal(
    <Block position="fixed" left={0} right={0} bottom={0}>
      <BottomNavigation>
        {GET_DASHBOARD_MAIN_MENU({ data }).map((item, i) => (
          <BottomNavItemEnhancer
            item={item}
            data={data}
            key={`dashboard-bottom-navgation-item-${i}`}
            active={active === item.path}
            onClick={() => {
              history.push(item.path);
              setActive(item.path);
            }}
          />
        ))}
      </BottomNavigation>
    </Block>,
    document.body
  );
};
export default BottomNav;
