import BaseApp from "../modules/BaseApp";
import AnimatedSelectorPhrase from "../pages/AnimatedSelectorPhrase";

// eslint-disable-next-line no-unused-vars
const AnimatedSelectorPhraseApp = (props, rails) => () =>
  (
    <BaseApp>
      <AnimatedSelectorPhrase {...props} />
    </BaseApp>
  );

export default AnimatedSelectorPhraseApp;
