import React from "react";
import { borderWidth, sizes, spacings } from "../../assets/themes";
import { Block, Skeleton } from "../../components";

const CategoriesNavigationSkeleton = () => (
  <Skeleton.Group>
    {new Array(8).fill("").map((_, i) => (
      <React.Fragment key={`edit-acc-skel-${i}`}>
        <Block marginY={spacings.m} display="flex" alignItems="center">
          <Skeleton height={sizes.size48} width={sizes.size48} rounded />
          <Block
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginLeft={spacings.m}
            flex={1}
          >
            <Skeleton height={sizes.size18} width={180} />
            <Skeleton height={sizes.size18} width={sizes.size18} />
          </Block>
        </Block>
        <Skeleton height={borderWidth.s} width="100%" />
      </React.Fragment>
    ))}
  </Skeleton.Group>
);

export default CategoriesNavigationSkeleton;
