import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import polyglot from "@/utils/polyglot";
import { colors, shadows, spacings } from "../../assets/themes";
import { Block, Body18, Col, Input, List, Modal, Row } from "../../components";
import { useBreakpoints, useIntersectionObserver } from "../../modules/hooks";
import { api, getCategoryFormUrlById, uuid } from "../../utils";
import { MAX_TITLE_LENGTH } from "../categoryForms/constants";
import CategoriesNavigationSkeleton from "./CategoriesNavigationSkeleton";
import CustomAskItem from "./CustomAskItem";
import MainCategoryItem from "./MainCategoryItem";
import SearchBreadcrumb from "./SearchBreadcrumb";
import SearchItem from "./SearchItem";
import SelectCategoryModal from "./SelectCategoryModal";
import { launchSearch } from "./searchAlgo";
import {
  getCategoriesPathById,
  getCategoryDataById,
  getFormatedCategoriesPathForPrivate,
  getUrlParamsForPrivate,
} from "./utils";
import { CATEGORIES_PATH } from "@/config";

let to;

const MIN_SEARCH_LENGTH = 2;
const Show = ({ url_params, user, goTo, onClose, subcategories }) => {
  const anchorRef = useRef();
  const [navigationData, setNavigationData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [inputIsLoading, setInputIsLoading] = useState(false);
  const [sessionId] = useState(uuid());
  const [selectCategoryModalStatus, setSelectCategoryModalStatus] = useState({
    isOpen: false,
  });
  const inputRef = useRef();
  const history = useHistory();
  const breakpoints = useBreakpoints();
  const searchMode = inputRef.current?.value?.length > MIN_SEARCH_LENGTH;
  const searchParams = new URLSearchParams(history.location.search);
  const currId = Number(searchParams.get("search"));
  const [isStickyFloating, setisStickyFloating] = useState(false);

  useIntersectionObserver({
    target: anchorRef,
    onObserver: (entry) => {
      setisStickyFloating(!entry.isIntersecting);
    },
  });

  const categoriesWithPrivate = useMemo(
    () => getFormatedCategoriesPathForPrivate(subcategories, CATEGORIES_PATH),
    [subcategories, CATEGORIES_PATH]
  );

  useEffect(() => {
    if (currId && inputRef.current) {
      setSearchResults([]);
      inputRef.current.value = "";
    }
    setNavigationData(getCategoryDataById(currId, categoriesWithPrivate));
  }, [history.location.search, user, currId, categoriesWithPrivate]);

  useEffect(() => {
    if (searchMode) goTo("");
  }, [searchMode, goTo]);

  const sendSearchTerm = (data) => {
    if (data && data.term.length <= 100) {
      api.post("/searchbar", data);
    }
  };

  const handleChange = (e) => {
    e.persist();
    clearTimeout(to);
    if (e.target?.value?.length > MIN_SEARCH_LENGTH) {
      setInputIsLoading(true);
      to = setTimeout(() => {
        setInputIsLoading(false);
        sendSearchTerm({ term: e.target.value, session: sessionId });
        setSearchResults(launchSearch(e.target.value, categoriesWithPrivate));
      }, 500);
    } else {
      setSearchResults([]);
      setInputIsLoading(false);
    }
  };

  const getNavigationItems = useCallback(
    (navigation) =>
      !currId && !searchMode ? (
        <Block
          marginTop={spacings.s}
          marginBottom={{ xs: 0, sm: `calc(${spacings.m} * -1)` }}
        >
          <Modal.Item.Wrapper>
            <Block
              display="grid"
              gridCols={{ xs: 1, md: 2 }}
              gap={spacings.m}
              marginBottom={{ xs: 0, sm: spacings.m }}
            >
              {navigation.map((elem) => (
                <MainCategoryItem
                  elem={elem}
                  onClick={() => goTo(elem.id)}
                  key={`transition-main-${elem.id}`}
                />
              ))}
            </Block>
          </Modal.Item.Wrapper>
        </Block>
      ) : (
        navigation.map((elem, i) => (
          <div key={`${elem.id}-${i}-navigation-item-transition`}>
            {!elem.children && elem.isCustom && currId ? (
              <CustomAskItem
                href={getCategoryFormUrlById(
                  elem.id,
                  getUrlParamsForPrivate(url_params, {
                    category: elem,
                    user,
                  })
                )}
              />
            ) : (
              <SearchItem
                chevron
                elem={elem}
                url_params={getUrlParamsForPrivate(url_params, {
                  category: elem,
                  user,
                })}
                onClick={() => goTo(elem.id)}
              />
            )}
          </div>
        ))
      ),
    [currId, searchMode, subcategories, goTo, url_params, categoriesWithPrivate]
  );

  const getNavigationWithPrivate = useCallback(
    (categories = navigationData) => {
      const privateCategories = categories.filter((e) => e.eligible_private);
      const publicCategories = categories.filter((e) => !e.eligible_private);
      return (
        <>
          {privateCategories.length > 0 && (
            <>
              <List.Header withGutters>
                {polyglot.t("categories_modal.first_name_services", {
                  first_name: user?.first_name,
                })}
              </List.Header>
              {getNavigationItems(privateCategories)}
              {publicCategories.length > 0 && (
                <List.Header withGutters>
                  {polyglot.t("categories_modal.other_services")}
                </List.Header>
              )}
            </>
          )}
          {publicCategories.length > 0 && getNavigationItems(publicCategories)}
        </>
      );
    },
    [navigationData, getNavigationItems, user, subcategories]
  );

  const getSearchResults = useCallback(() => {
    const formatedSearchResult = searchResults.map(({ item }) => item);
    return (
      <>
        {getNavigationWithPrivate(formatedSearchResult)}
        <CustomAskItem
          onClick={() =>
            setSelectCategoryModalStatus({
              isOpen: true,
            })
          }
        />
      </>
    );
  }, [searchResults, getNavigationWithPrivate]);

  const handleSelectCategoryClose = () => {
    setSelectCategoryModalStatus({ isOpen: false });
  };

  const handleBack = () => {
    const paths = getCategoriesPathById(currId);
    goTo(paths[paths.length - 2]);
  };

  return (
    <>
      <div ref={anchorRef} />
      <Block
        paddingBottom={{ xs: spacings.s, sm: 0 }}
        boxShadow={
          isStickyFloating ? shadows.s : "0px 0px 0px rgba(0, 0, 0, 0)"
        }
        transition="box-shadow 0.2s ease-out"
        background={colors.background}
        zIndex="100"
        position="sticky"
        top="0"
      >
        <Modal.Item.Header
          onBack={currId ? handleBack : null}
          onClose={onClose}
          navBarContent={() => (
            <>
              <Block
                display={{ xs: "block", sm: "none" }}
                position="absolute"
                left="0"
                right="0"
                textAlign="center"
                pointerEvents="none"
              >
                <Body18 strong align="center">
                  {currId
                    ? polyglot.t(`categories.${currId}.name`)
                    : polyglot.t("jobs.ask_service")}
                </Body18>
              </Block>
            </>
          )}
        >
          {breakpoints.get({
            xs: null,
            sm: (
              <Modal.Item.Title>
                {currId
                  ? polyglot.t(`categories.${currId}.name`)
                  : polyglot.t("jobs.ask_service")}
              </Modal.Item.Title>
            ),
          })}
        </Modal.Item.Header>
        <Block marginTop={{ xs: spacings.xs, sm: null }}>
          <Modal.Item.Wrapper>
            <Input
              maxLength={MAX_TITLE_LENGTH}
              isLoading={inputIsLoading}
              onChange={handleChange}
              ref={inputRef}
              placeholder={polyglot.t("ask_service.search_job")}
              type="search"
            />
          </Modal.Item.Wrapper>
        </Block>
        <Modal.Item.Wrapper>
          <Block
            display={{ xs: "none", sm: "block" }}
            marginTop={spacings.sm}
            marginBottom={spacings.xs}
          >
            {!searchMode && <SearchBreadcrumb onClick={goTo} currId={currId} />}
          </Block>
        </Modal.Item.Wrapper>
      </Block>
      <Block display="flex" flexDirection="column" overflow="hidden">
        {inputIsLoading && searchResults.length === 0 ? (
          <Modal.Item.Wrapper>
            <CategoriesNavigationSkeleton />
          </Modal.Item.Wrapper>
        ) : (
          <AnimatePresence history={history} initial={false} exitBeforeEnter>
            <motion.div
              initial={{
                x: 50,
                opacity: 0,
              }}
              transition={{ duration: 0.2, ease: [1, 0, 0, 1] }}
              animate={{
                x: 0,
                opacity: 1,
              }}
              key={`animate-${currId}`}
            >
              {searchMode ? getSearchResults() : getNavigationWithPrivate()}
            </motion.div>
          </AnimatePresence>
        )}
      </Block>
      <SelectCategoryModal
        formatedCategories={categoriesWithPrivate}
        isOpen={selectCategoryModalStatus.isOpen}
        onClose={handleSelectCategoryClose}
        user={user}
        urlParams={url_params}
      />
    </>
  );
};

export default Show;
