import React from "react";
import { QRCodeCanvas } from "qrcode.react";
// eslint-disable-next-line import/no-unresolved
import Logo from "images/brand/logo-qr.svg";

const QrcodeGenerator = ({ user_token, admin_id, size = 200 }) => (
  <div>
    <QRCodeCanvas
      value={`{"user_token": "${user_token}","admin_id": "${admin_id}"}`}
      fgColor="#13151b"
      imageSettings={{
        src: Logo,
        x: null,
        y: null,
        height: size / 5,
        width: size / 5,
        excavate: false,
      }}
      size={size}
    />
  </div>
);

export default QrcodeGenerator;
