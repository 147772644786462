import { useFormikContext } from "formik";
import React from "react";
import { spacings } from "../../../../assets/themes";
import { Alert, Block, Col, Modal, Row } from "../../../../components";
import { ALERT } from "../../../../components/Styles/variants";
import CreditCardNumbersField from "./CreditCardNumbersField";
import CVXField from "./CVXField";
import ExpirationDateField from "./ExpirationDateField";

const CustomWrapper = ({ withGutters, children }) =>
  withGutters ? (
    <Modal.Item.Wrapper>{children}</Modal.Item.Wrapper>
  ) : (
    <div>{children}</div>
  );

const CreditCardFields = ({ children, withGutters }) => {
  const { status } = useFormikContext();

  return (
    <>
      <CustomWrapper withGutters={withGutters}>
        {status && (
          <Block marginBottom={spacings.s}>
            <Alert.Low title={status} kind={ALERT.KIND.DANGER} />
          </Block>
        )}
        <CreditCardNumbersField />
        <Row gutter={spacings.s}>
          <Col size={6}>
            <ExpirationDateField />
          </Col>
          <Col size={6}>
            <CVXField />
          </Col>
        </Row>
      </CustomWrapper>
      {children}
    </>
  );
};

export default CreditCardFields;
