import { AnimatePresence, motion } from "framer-motion";
import React, { useRef } from "react";
import ReactDOM from "react-dom";

import Block from "../Block";
import { POPOVER } from "../Styles/variants";

const getSimplePosition = (position) => position?.split("-")[0];

const setContentAnimation = (position) => {
  const inBottom = getSimplePosition(position) === POPOVER.POSITIONS.BOTTOM;
  const inTop = getSimplePosition(position) === POPOVER.POSITIONS.TOP;
  const inLeft = getSimplePosition(position) === POPOVER.POSITIONS.LEFT;
  const inRight = getSimplePosition(position) === POPOVER.POSITIONS.RIGHT;
  let initial = { opacity: 0 };
  const animate = { opacity: 1, y: 0, x: 0 };
  const exit = { opacity: 0 };
  const transition = { ease: "easeInOut", duration: 0.1 };
  const startOffset = 16;
  if (inBottom) {
    initial = { ...initial, y: -startOffset };
  }
  if (inTop) {
    initial = { ...initial, y: startOffset };
  }
  if (inLeft) {
    initial = { ...initial, x: startOffset };
  }
  if (inRight) {
    initial = { ...initial, x: -startOffset };
  }
  return { initial, animate, transition, exit };
};

const PortalContent = React.forwardRef(
  (
    {
      isVisible,
      children,
      position,
      onAnimationComplete,
      mountNode,
      styles,
      noPortal,
    },
    ref
  ) => {
    const contentRef = ref || useRef();

    const View = (
      <AnimatePresence exitBeforeEnter>
        {isVisible && (
          <div style={{ ...styles.popper, zIndex: "9999999999" }} ref={ref}>
            <motion.div
              ref={contentRef}
              onAnimationComplete={onAnimationComplete}
              style={{
                pointerEvents: "none",
              }}
              {...setContentAnimation(position)}
            >
              <Block pointerEvents="all">{children}</Block>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    );
    return noPortal
      ? View
      : ReactDOM.createPortal(View, mountNode || document.body);
  }
);

export default PortalContent;
