import mangoPay from "mangopay-cardregistration-js-kit";
import { VISA_MASTERCARD } from "..";
import { api } from "../axios";
import { IS_PROD, IS_STAGING, MANGOPAY_CLIENT_ID } from "../constants";

const logMissingValues = ({ cardNumber, cardExpirationDate, cardType }) => {
  if (!cardNumber) console.error("cardNumber cannot be null");
  if (!cardExpirationDate) console.error("cardExpirationDate cannot be null");
  if (!cardType) console.error("cardType cannot be null");
};

const getCardType = (type) => {
  if (type?.toLowerCase() === VISA_MASTERCARD.toLowerCase()) {
    return "CB_VISA_MASTERCARD";
  }
  return type;
};

const baseUrl =
  IS_PROD && !IS_STAGING
    ? "https://api.mangopay.com"
    : "https://api.sandbox.mangopay.com";

export const tokenizeCreditCard = ({
  cardNumber,
  cardExpirationDate,
  cardCvx,
  cardType,
  ...cardRegistrationDataParams
}) =>
  new Promise((resolve, reject) => {
    logMissingValues({ cardNumber, cardExpirationDate, cardCvx, cardType });
    const data = {
      cardNumber: cardNumber.replace(/\s+/g, ""),
      cardExpirationDate: cardExpirationDate.replace(/\//g, ""),
      cardCvx,
      cardType: getCardType(cardType)?.toUpperCase(),
    };
    api
      .get("/mangopay_cards/get_card_registration_data", {
        params: {
          payment_method: getCardType(cardType),
          ...cardRegistrationDataParams,
        },
      })
      .then((response) => {
        const config = response.data.mangopayConfig;
        mangoPay.cardRegistration.baseURL = baseUrl;
        mangoPay.cardRegistration.clientId = MANGOPAY_CLIENT_ID;
        mangoPay.cardRegistration.init({
          cardRegistrationURL: config.cardRegistrationUrl,
          preregistrationData: config.preregistrationData,
          accessKey: config.accessKey,
          Id: config.registrationId,
        });
        mangoPay.cardRegistration.registerCard(
          data,
          (res) => {
            resolve(res);
          },
          (error) => {
            reject(error);
          }
        );
      });
  });
