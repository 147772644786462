import { Field, useFormikContext } from "formik";
import { useState } from "react";
import { Alert, Icon, Input } from "../../../components";
import { useCategoryForm } from "../../../modules/hooks";
import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import AddressModal from "../../common/AddressModal";
import { StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

const Address = ({ goNext, title, name_prefix = "" }) => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const { addresses } = useCategoryForm();
  const { values } = useFormikContext();

  return (
    <>
      <StepTitle>{title}</StepTitle>
      <StyledFormControl>
        <Field name={`${name_prefix}address`}>
          {({ field }) => (
            <Input
              name={`${name_prefix}address`}
              onClick={() => setAddressModalIsOpen(true)}
              value={field.value}
              placeholder={
                name_prefix === "destination_"
                  ? polyglot.t("common.placeholder.destination_address")
                  : polyglot.t("common.placeholder.job_address")
              }
              RightComponent={() => <Icon.Medium name="dropdown" />}
            />
          )}
        </Field>
        <AddressModal
          isDestination={name_prefix === "destination_"}
          addresses={addresses}
          isOpen={addressModalIsOpen}
          onClose={() => setAddressModalIsOpen(false)}
          onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
        />
      </StyledFormControl>
      {!values.private_jobber_id && (
        <Alert.Low renderIcon={() => <Icon.XLarge name="shield-check-solid" />}>
          {polyglot.t("categories.private_informations_are_confidentials")}
        </Alert.Low>
      )}
    </>
  );
};

export default Address;
