import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import { MemoryRouter, Route, Switch } from 'react-router-dom';
import { HistoryScrollToTop, Modal } from '../../components';
import { getSimulatePricing, putUrssaf } from '../../modules/routes';
import { formatDate, setAddressField } from '../../utils';
import Home from './setCesuModal/Home';
import TaxCreditModal from './TaxCreditModal';

const SetCesuModal = ({ onClose, isOpen, onSubmit, user, tax_deductions }) => {
  const [taxesIsLoading, setTaxesIsLoading] = useState(false);
  const [priceSimulation, setPriceSimulation] = useState({});
  const [priceSimulationIsLoading, setPriceSimulationIsLoading] = useState(null);
  const {
    setFieldValue: globalSetFieldValue,
    values,
    errors,
    setFieldError,
    setErrors,
  } = useFormikContext();
  const selectedJobber = !values.jobber_id ? values.external_jobber : values.jobber;
  const [checked, setChecked] = useState(values.checked);
  const routerRef = useRef();

  const updatePricingSimulation = () => {
    setPriceSimulationIsLoading(true);
    const res = getSimulatePricing({
      nb_hours: values.nb_hours,
      price_per_hour: values.price_per_hour,
    });
    res
      .then(({ data }) => {
        setPriceSimulationIsLoading(false);
        setPriceSimulation(data);
      })
      .catch(err => {
        setPriceSimulationIsLoading(false);
        console.error(err);
      });
  };

  const onTaxesSubmit = ({
    date_of_birth,
    first_name,
    last_name,
    place_id,
    city_id,
    address_name,
    address,
    tax_deduction,
  }) => {
    setTaxesIsLoading(true);
    setFieldError('user[date_of_birth]', null);
    const dob = formatDate(date_of_birth, 'YYYY-MM-DD', 'DD/MM/YYYY');
    const res = putUrssaf({
      date_of_birth: dob,
      first_name,
      last_name,
      place_id,
      city_id,
      address_name,
      tax_deduction,
    });
    res
      .then(() => {
        setTaxesIsLoading(false);
        setChecked(true);
        city_id && globalSetFieldValue('user[city_id]', city_id);
        place_id && globalSetFieldValue('user[place_id]', place_id);
        address_name && globalSetFieldValue('user[address_name]', address_name);
        !values.address &&
          setAddressField(globalSetFieldValue, { place_id, address_name, label: address, city_id });
        date_of_birth && globalSetFieldValue('user[date_of_birth]', dob);
        tax_deduction && globalSetFieldValue('user[tax_deduction]', tax_deduction);
        globalSetFieldValue('user[address]', address);
        routerRef.current.history.goBack();
        updatePricingSimulation();
      })
      .catch(() => {
        setFieldError('user[date_of_birth]', true);
        setTaxesIsLoading(false);
      });
  };

  return (
    <Modal.Small onClose={onClose} isOpen={isOpen} fullScreenOnMobile>
      <MemoryRouter ref={routerRef}>
        <HistoryScrollToTop />
        <Switch>
          <Route exact path="/">
            <Home
              onSubmit={onSubmit}
              onClose={onClose}
              user={user}
              setChecked={setChecked}
              checked={checked}
              priceSimulation={priceSimulation}
              priceSimulationIsLoading={priceSimulationIsLoading}
              nb_hours={values.nb_hours}
              price_per_hour={values.price_per_hour}
              frequency={values.frequency}
              selectedJobber={selectedJobber}
            />
          </Route>
          <Route exact path="/taxes">
            <TaxCreditModal
              inContent
              initialValues={{ user }}
              onBack={() => {
                setErrors({});
                routerRef.current.history.goBack();
              }}
              tax_deductions={tax_deductions}
              onSubmit={onTaxesSubmit}
              errors={errors.user}
              isLoading={taxesIsLoading}
            />
          </Route>
        </Switch>
      </MemoryRouter>
    </Modal.Small>
  );
};

export default SetCesuModal;
