import { Block, Button } from "@/components";
import CategoryItem from "./CategoryItem";
import polyglot from "@/utils/polyglot";
import { BUTTON } from "@/components/Styles/variants";
import { spacings } from "@/assets/themes";
import { useAskService } from "@/modules/hooks";

const CategoriesGrid = ({ block }) => {
  const askService = useAskService();
  return (
    <Block spaceY={spacings.s}>
      <Block
        display="grid"
        gridCols={{ xs: 2, xl: 4 }}
        gap={{ xs: spacings.s, md: spacings.m }}
      >
        {block.data?.map((cat, i) => (
          <CategoryItem
            id={cat}
            key={`categories-grid-${block.category_id}-${i}`}
          />
        ))}
      </Block>
      {block.category_id && (
        <Button.Small
          kind={BUTTON.KIND.SECONDARY}
          onClick={() => askService.show({ id: block.category_id })}
        >
          {polyglot.t("common.see_more")}
        </Button.Small>
      )}
    </Block>
  );
};

export default CategoriesGrid;
