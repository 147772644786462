import React, { Component } from "react";
import Modal from "react-modal";
import walletIcon from "images/gift-card/wallet-icon.svg";
import polyglot from "../../../utils/polyglot";
import { isInt } from "../../../utils/functions";
import { CountUp } from "../../../components";

export default class GiftCardAddSuccessModal extends Component {
  renderGiftCardIcon = () => (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" fill="none" fillRule="evenodd">
        <g
          id="Carte-cadeau-page-Form"
          transform="translate(-926 -618)"
          fill="#FFF"
          fillRule="nonzero"
        >
          <g id="gift-card-regular" transform="translate(926 618)">
            <path
              d="M18.3333333,4.25 L16.3170139,4.25 C16.5329861,3.84957031 16.6666667,3.40265625 16.6666667,2.921875 C16.6666667,1.31085938 15.2958333,0 13.6111111,0 C12.1666667,0 11.2322917,0.708554688 10.0333333,2.26876953 C8.83472222,0.708222656 7.90034722,0 6.45590278,0 C4.77118056,0 3.40034722,1.31085938 3.40034722,2.921875 C3.40034722,3.40265625 3.53402778,3.84957031 3.75,4.25 L1.66666667,4.25 C0.746180556,4.25 0,4.96353516 0,5.84375 L0,15.40625 C0,16.2864648 0.746180556,17 1.66666667,17 L18.3333333,17 C19.2538194,17 20,16.2864648 20,15.40625 L20,5.84375 C20,4.96353516 19.2538194,4.25 18.3333333,4.25 Z M13.6111111,1.59375 C14.3770833,1.59375 15,2.18941406 15,2.921875 C15,3.65400391 14.3770833,4.25 13.6111111,4.25 L10.6225694,4.25 C12.4059028,1.71095703 12.9045139,1.59375 13.6111111,1.59375 Z M5.06701389,2.921875 C5.06701389,2.18941406 5.68993056,1.59375 6.45590278,1.59375 C7.14826389,1.59375 7.65659722,1.70232422 9.44444444,4.25 L6.45590278,4.25 C5.68993056,4.25 5.06701389,3.65400391 5.06701389,2.921875 Z M7.71041667,5.84375 L5.91458333,7.56101563 C5.69756944,7.76853516 5.69756944,8.10488281 5.91458333,8.31240234 L6.30729167,8.68792969 C6.52430556,8.89544922 6.87604167,8.89544922 7.09305556,8.68792969 L10,5.90816406 L12.9069444,8.68792969 C13.1239583,8.89544922 13.4756944,8.89544922 13.6927083,8.68792969 L14.0854167,8.31240234 C14.3024306,8.10488281 14.3024306,7.76853516 14.0854167,7.56101563 L12.2895833,5.84375 L18.3333333,5.84375 L18.3333333,10.625 L1.66666667,10.625 L1.66666667,5.84375 L7.71041667,5.84375 Z M1.66666667,15.40625 L1.66666667,12.75 L18.3333333,12.75 L18.3333333,15.40625 L1.66666667,15.40625 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );

  renderWalletIcon = () => (
    <svg
      width="20"
      height="17"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Page-1" fill="none" fillRule="evenodd">
        <g
          id="Carte-cadeau-page-Form"
          transform="translate(-926 -618)"
          fill="#BCD9FF"
          fillRule="nonzero"
        >
          <g id="gift-card-regular" transform="translate(926 618)">
            <path
              d="M18.3333333,4.25 L16.3170139,4.25 C16.5329861,3.84957031 16.6666667,3.40265625 16.6666667,2.921875 C16.6666667,1.31085938 15.2958333,0 13.6111111,0 C12.1666667,0 11.2322917,0.708554688 10.0333333,2.26876953 C8.83472222,0.708222656 7.90034722,0 6.45590278,0 C4.77118056,0 3.40034722,1.31085938 3.40034722,2.921875 C3.40034722,3.40265625 3.53402778,3.84957031 3.75,4.25 L1.66666667,4.25 C0.746180556,4.25 0,4.96353516 0,5.84375 L0,15.40625 C0,16.2864648 0.746180556,17 1.66666667,17 L18.3333333,17 C19.2538194,17 20,16.2864648 20,15.40625 L20,5.84375 C20,4.96353516 19.2538194,4.25 18.3333333,4.25 Z M13.6111111,1.59375 C14.3770833,1.59375 15,2.18941406 15,2.921875 C15,3.65400391 14.3770833,4.25 13.6111111,4.25 L10.6225694,4.25 C12.4059028,1.71095703 12.9045139,1.59375 13.6111111,1.59375 Z M5.06701389,2.921875 C5.06701389,2.18941406 5.68993056,1.59375 6.45590278,1.59375 C7.14826389,1.59375 7.65659722,1.70232422 9.44444444,4.25 L6.45590278,4.25 C5.68993056,4.25 5.06701389,3.65400391 5.06701389,2.921875 Z M7.71041667,5.84375 L5.91458333,7.56101563 C5.69756944,7.76853516 5.69756944,8.10488281 5.91458333,8.31240234 L6.30729167,8.68792969 C6.52430556,8.89544922 6.87604167,8.89544922 7.09305556,8.68792969 L10,5.90816406 L12.9069444,8.68792969 C13.1239583,8.89544922 13.4756944,8.89544922 13.6927083,8.68792969 L14.0854167,8.31240234 C14.3024306,8.10488281 14.3024306,7.76853516 14.0854167,7.56101563 L12.2895833,5.84375 L18.3333333,5.84375 L18.3333333,10.625 L1.66666667,10.625 L1.66666667,5.84375 L7.71041667,5.84375 Z M1.66666667,15.40625 L1.66666667,12.75 L18.3333333,12.75 L18.3333333,15.40625 L1.66666667,15.40625 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </svg>
  );

  countPropsIfInt = () =>
    !isInt(this.props.walletAmount)
      ? {
          decimals: 2,
        }
      : {};

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onRequestClose={() => this.props.closeModal()}
        ariaHideApp={false}
        className="modal-add-gift-card-to-wallet"
      >
        <div className="pyro">
          <div className="before" />
          <div className="after" />
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column react-modal-content">
          <p className="font-size-3 text-muted font-weight-black">
            {polyglot.t("gift_cards.add.new_wallet")}
          </p>
          <div className="d-flex align-items-center justify-content-center flex-column">
            <div className="d-flex align-items-baseline justify-content-center mb-3 mt-3">
              <img src={walletIcon} alt="" className="mr-3" />
              <div className="price-content display-3 font-weight-black">
                <div className="price">
                  <CountUp
                    duration={3}
                    from={Number(this.props.walletAmount)}
                    to={
                      Number(this.props.walletAmount) +
                      Number(this.props.giftCardAmount)
                    }
                    {...this.countPropsIfInt()}
                    delay={0.2}
                  />
                  <span>{polyglot.currency}</span>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <p className="font-size-5 m-0 text-center font-weight-black">
                {polyglot.t("common.congrats")}
              </p>
              <p className="font-size-3 text-muted text-center">
                {polyglot.t("gift_cards.add.success_phrase", {
                  amount: this.props.giftCardAmount,
                  currency: polyglot.currency,
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="react-modal-footer border-top">
          <button
            type="button"
            onClick={() => this.props.closeModal()}
            className="btn btn-link"
          >
            {polyglot.t("common.actions.close")}
          </button>
          <a href={this.props.redirectUrl} className="btn btn-primary">
            {polyglot.t("common.actions.ask_for_service")}
          </a>
        </div>
      </Modal>
    );
  }
}
