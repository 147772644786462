import { isValidNumber, parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";
import polyglot from "./polyglot";
import { OFFER_STATE_PAID } from "@/pages/Job/utils";

export const setAddressField = (
  setFieldValue,
  { place_id, address_id, address_name, label, city_id }
) => {
  if (place_id) {
    setFieldValue("place_id", place_id);
    setFieldValue("address_id", null);
    setFieldValue("address_name", null);
    setFieldValue("city_id", null);
  } else if (address_id) {
    setFieldValue("address_id", address_id);
    setFieldValue("place_id", null);
    setFieldValue("address_name", null);
    setFieldValue("city_id", null);
  } else if (city_id && address_name && label) {
    setFieldValue("city_id", city_id);
    setFieldValue("address_name", address_name);
    setFieldValue("place_id", null);
    setFieldValue("address_id", null);
  }
  setFieldValue("address", label);
};

export const getEventsFromObject = (props) => {
  const { onChange, onBlur, onKeyUp, onFocus, onKeyPress, onClick, onPaste } =
    props;
  const eventsHandler = {
    onChange,
    onBlur,
    onKeyUp,
    onFocus,
    onKeyPress,
    onClick,
    onPaste,
  };
  return _.pickBy(eventsHandler, _.identity);
};

export const isInViewport = (elem) => {
  const distance = elem.getBoundingClientRect();
  return (
    distance.top >= 0 &&
    distance.left >= 0 &&
    distance.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    distance.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const formatPhone = (number, config = { international: false }) => {
  if (number && isValidNumber(number)) {
    const phone = parsePhoneNumber(number);
    if (
      config?.international ||
      phone.country?.toLowerCase() !== polyglot.country?.toLowerCase()
    ) {
      return phone.formatInternational();
    }
    return phone.formatNational();
  }
  return number;
};

export const scrollToTop = (elem = false) => {
  if (elem) {
    setTimeout(() => {
      if (!isInViewport(elem)) {
        elem.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    }, 0);
  } else {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};

export const isInt = (n) => Number(n) % 1 === 0;

export const renderStatusBagde = (status) => {
  switch (status) {
    case "created":
    case "pending":
      return <span className="badge badge-pill badge-warning">En attente</span>;
    case "processing":
      return <span className="badge badge-pill badge-primary">En cours</span>;
    case "succeeded":
    case "paid":
      return <span className="badge badge-pill badge-success">Payé</span>;
    case "failed":
      return <span className="badge badge-pill badge-danger">Échoué</span>;
    case "canceled":
      return <span className="badge badge-pill badge-danger">Annulé</span>;
    default:
      return null;
  }
};

const removeAccents = (str) => {
  const accents =
    "ÀÁÂÃÄÅàáâãäåßÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  const accentsOut =
    "AAAAAAaaaaaaBOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split("");
  str.forEach((letter, index) => {
    const i = accents.indexOf(letter);
    if (i != -1) {
      str[index] = accentsOut[i];
    }
  });
  return str.join("");
};

export const stringToUrl = (string) =>
  removeAccents(
    string
      .toLowerCase()
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ +(?= )/g, "")
      .replace(/ /g, "-")
      .replace(/'/g, "-")
  );

export const getCategoryFormUrlById = (id, url_params) =>
  id
    ? `/${polyglot.t("routes.new_job")}?category=${id}${
        url_params ? `&${new URLSearchParams(url_params).toString()}` : ""
      }`
    : null;

export const getJobReopenUrl = ({ id, category, offers, nbJobbers }) => {
  let params = { previous_job_id: id };
  if (nbJobbers === 1 && offers?.length >= 1) {
    const paidOffer =
      offers?.find((offer) => offer.state === OFFER_STATE_PAID) || {};
    if (paidOffer?.jobber) {
      params = { ...params, private_jobber_id: paidOffer.jobber.id };
    }
  }
  return getCategoryFormUrlById(category, params);
};

export const shuffle = (a) => {
  let j;
  let x;
  let i;
  for (i = a.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    a[i] = a[j];
    a[j] = x;
  }
  return a;
};

export const formatFileSize = (bytes) => {
  const sizes = ["o", "ko", "mo", "go", "to"];
  if (bytes === 0) return "0 ko";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  const value = bytes / 1024 ** i;
  const rounded = Math.round(value * 100) / 100;
  return `${rounded.toString().replace(".", ",")} ${sizes[i]}`;
};
