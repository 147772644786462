import { sizes, spacings } from "@/assets/themes";
import {
  Alert,
  Block,
  Body16,
  Divider,
  H5,
  Info,
  List,
  Skeleton,
} from "@/components";
import { CardBase } from "@/components/Styles/Base";
import { ALERT, LIST } from "@/components/Styles/variants";
import SummaryItem from "@/pages/jobber-booking/SummaryItem";
import polyglot from "@/utils/polyglot";

const TaxesCreditSimulatorSummary = ({ price = 0, taxes = 0, isLoading }) => (
  <>
    <CardBase light flat>
      <Block paddingY={spacings.s}>
        {!isLoading ? (
          <>
            <SummaryItem withGutters value={polyglot.toSmartCurrency(price)}>
              <Body16>{polyglot.t("taxes.job_before_taxes_credit")}</Body16>
            </SummaryItem>
            <SummaryItem withGutters value={polyglot.toSmartCurrency(taxes)}>
              <Body16>{polyglot.t("common.taxes")}</Body16>
              <Info>{polyglot.t("common.taxes_description")}</Info>
            </SummaryItem>
            <SummaryItem
              withGutters
              value={polyglot.toSmartCurrency((price + taxes) / 2)}
              positive
            >
              <Body16>{polyglot.t("taxes.tax_credit_to_obtain")}</Body16>
              <Info>{polyglot.t("taxes.tax_credit_informations")}</Info>
            </SummaryItem>
            <Block paddingY={spacings.s} paddingX={spacings.m}>
              <Divider.Cell />
            </Block>
            <List.Item
              withGutters
              divider={false}
              size={LIST.SIZE.COMPACT}
              RightComponent={() => (
                <Body16>{polyglot.toSmartCurrency((price + taxes) / 2)}</Body16>
              )}
            >
              <Body16 strong>
                {polyglot.t("taxes.job_after_taxes_credit")}
              </Body16>
            </List.Item>
          </>
        ) : (
          <Block marginX={spacings.m} paddingY={spacings.s}>
            <Skeleton.Group>
              <Skeleton height={sizes.size20} width="100%" rows={3} sb />
              <Divider.Cell />
              <Skeleton height={sizes.size20} width="100%" st />
            </Skeleton.Group>
          </Block>
        )}
      </Block>
    </CardBase>
    <Block marginTop={spacings.s}>
      {!isLoading ? (
        <Alert.Low kind={ALERT.KIND.SUCCESS}>
          {polyglot.t("taxes.job_discount_amount_taxes", {
            amount: polyglot.toSmartCurrency(price - (price + taxes) / 2, {
              noDecimal: true,
            }),
          })}
        </Alert.Low>
      ) : (
        <Skeleton.Group>
          <Skeleton height={sizes.size54} width="100%" />
        </Skeleton.Group>
      )}
    </Block>
  </>
);
export default TaxesCreditSimulatorSummary;
