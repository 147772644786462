import styled from "styled-components";
import { radius, sizes, spacings } from "@/assets/themes";
import { Block, ImageGallery } from "@/components";
import UserPreview from "@/pages-admin/UserPreview";
import { JOBBER } from "@/pages-admin/UserPreview/config";
import MainInformations from "./MainInformations";
import UserTitle from "./UserTitle";

const StyledAvatarWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 50%,
      rgba(0, 0, 0, 0.7) 100%
    );
  }
`;

const JobberAvatarInfo = ({
  id,
  first_name,
  role_translated,
  // avatar,
  // use avatar_large
  avatar_large,
  price_per_hour,
  rates_count,
  rate,
  member_since,
  is_pro,
  is_top_jobber,
  age,
}) => (
  <Block position="relative" marginBottom={sizes.size32}>
    <Block position="relative">
      <UserPreview id={id} kind={JOBBER}>
        <Block borderRadius={{ xs: 0, md: radius.ml }} overflow="hidden">
          <StyledAvatarWrapper>
            <ImageGallery
              css={`
                width: 100%;
                height: 100%;
              `}
            >
              <Block
                srcSet={avatar_large}
                as="img"
                src={avatar_large}
                alt={first_name}
                css={`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
              />
            </ImageGallery>
          </StyledAvatarWrapper>
        </Block>
      </UserPreview>
      <Block position="absolute" bottom="0" left="0" right="0">
        <Block
          marginX={spacings.m}
          marginBottom={spacings.l}
          paddingBottom={spacings.m}
          position="relative"
          zIndex="9"
        >
          <UserTitle
            age={age}
            first_name={first_name}
            role_translated={role_translated}
            price_per_hour={price_per_hour}
            is_pro={is_pro}
          />
        </Block>
      </Block>
    </Block>

    <Block
      marginX={spacings.m}
      position="absolute"
      bottom={`calc(${sizes.size32} * -1)`}
      left="0"
      right="0"
    >
      <MainInformations
        is_top_jobber={is_top_jobber}
        rates_count={rates_count}
        rate={rate}
        member_since={member_since}
      />
    </Block>
  </Block>
);

export default JobberAvatarInfo;
