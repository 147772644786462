import { useFormik } from "formik";
import { colors, spacings } from "@/assets/themes";
import {
  Block,
  Body16,
  Button,
  ConditionnalWrapper,
  FormControl,
  Icon,
  List,
  Popover,
  Slider,
  Toggle,
} from "@/components";
import { BUTTON, LIST, POPOVER } from "@/components/Styles/variants";
import {
  JOBBER_SEARCH_DISTANCE_MAX,
  JOBBER_SEARCH_DISTANCE_MIN,
  JOBBER_SEARCH_PRICE_MAX,
  JOBBER_SEARCH_PRICE_MIN,
} from "@/utils";
import polyglot from "@/utils/polyglot";

const FilterButton = ({
  children,
  content,
  value,
  initialValue,
  activeValue,
  onReset,
  onClick,
  ...rest
}) => {
  const active = !!value && value !== initialValue;

  return (
    <ConditionnalWrapper
      condition={content}
      wrapper={(children) => (
        <Popover.Enhancer
          trigger={POPOVER.TRIGGER_TYPE.CLICK}
          content={({ close }) => (
            <Popover.Elem.Menu style={{ width: "90vw", maxWidth: "400px" }}>
              <Block padding={spacings.m}>
                {content({ close })}
                {onReset && (
                  <Button.Small
                    onClick={() => {
                      onReset();
                      close();
                    }}
                    disabled={!active}
                    accentColor={BUTTON.ACCENT_COLOR.BODY}
                    shape={BUTTON.SHAPE.PILL}
                    kind={BUTTON.KIND.SECONDARY}
                    LeftComponent={() => <Icon.Medium name="trash" />}
                  >
                    {polyglot.t("common.remove")}
                  </Button.Small>
                )}
              </Block>
            </Popover.Elem.Menu>
          )}
        >
          {children}
        </Popover.Enhancer>
      )}
    >
      <Button.Small
        {...rest}
        onClick={onClick}
        shape={BUTTON.SHAPE.PILL}
        kind={active ? BUTTON.KIND.TERTIARY : BUTTON.KIND.SECONDARY}
        RightComponent={
          active && content ? () => <Icon.Medium name="edit" /> : undefined
        }
        css={`
          ${!active && `color:${colors.body}!important`};
          white-space: nowrap;
        `}
      >
        {active ? activeValue : children}
      </Button.Small>
    </ConditionnalWrapper>
  );
};

const SearchFilters = ({ initialValues, onChange, showDistance }) => {
  const formik = useFormik({
    initialValues: {
      radius: initialValues.radius,
      top_jobber: initialValues.top_jobber,
      price_per_hour: initialValues.price_per_hour,
    },
    onSubmit: onChange,
  });

  return (
    <Block
      display="flex"
      gap={spacings.s}
      flexWrap="nowrap"
      overflowX="auto"
      marginX={{ xs: `calc(${spacings.m} * -1)`, sm: "0px" }}
      paddingX={{ xs: spacings.m, sm: "0px" }}
      // overflow for prevent border cut
      paddingY={spacings.xs}
    >
      <FilterButton
        value={formik.values.top_jobber}
        activeValue={polyglot.t("common.top_jobber")}
        initialValue={false}
        onClick={() => {
          formik.setFieldValue("top_jobber", !formik.values.top_jobber);
          formik.submitForm();
        }}
        LeftComponent={() => <Icon.Medium name="love-solid" />}
      >
        {polyglot.t("common.top_jobber")}
      </FilterButton>
      <FilterButton
        value={formik.values.price_per_hour}
        activeValue={`< ${polyglot.toSmartCurrency(
          formik.values.price_per_hour,
          {
            noDecimal: true,
          }
        )}`}
        onReset={() => {
          formik.setFieldValue("price_per_hour", JOBBER_SEARCH_PRICE_MAX);
          formik.submitForm();
        }}
        initialValue={JOBBER_SEARCH_PRICE_MAX}
        content={() => (
          <div>
            <FormControl
              label={() => (
                <Body16 strong>{polyglot.t("common.hourly_rate")}</Body16>
              )}
            >
              <Block marginTop={spacings.l} marginX={spacings.ml}>
                <Slider
                  max={JOBBER_SEARCH_PRICE_MAX}
                  min={JOBBER_SEARCH_PRICE_MIN}
                  value={formik.values.price_per_hour}
                  persisentThumb
                  onChange={(value) =>
                    formik.setFieldValue("price_per_hour", value)
                  }
                  onAfterChange={() => formik.submitForm()}
                  formatValue={(value) =>
                    polyglot.toSmartCurrency(value || 0, { noDecimal: true })
                  }
                  ticks={[
                    polyglot.toSmartCurrency(JOBBER_SEARCH_PRICE_MIN, {
                      noDecimal: true,
                    }),
                    polyglot.toSmartCurrency(JOBBER_SEARCH_PRICE_MAX, {
                      noDecimal: true,
                    }),
                  ]}
                />
              </Block>
            </FormControl>
          </div>
        )}
      >
        {polyglot.t("common.hourly_rate")}
      </FilterButton>
      {showDistance && (
        <FilterButton
          value={formik.values.radius}
          activeValue={`< ${polyglot.toDistance(formik.values.radius, {
            noFormat: true,
          })}`}
          onReset={() => {
            formik.setFieldValue("radius", JOBBER_SEARCH_DISTANCE_MAX);
            formik.submitForm();
          }}
          initialValue={JOBBER_SEARCH_DISTANCE_MAX}
          content={() => (
            <div>
              <FormControl
                label={() => (
                  <Body16 strong>{polyglot.t("common.distance")}</Body16>
                )}
              >
                <Block marginTop={spacings.l} marginX={spacings.ml}>
                  <Slider
                    max={JOBBER_SEARCH_DISTANCE_MAX}
                    min={JOBBER_SEARCH_DISTANCE_MIN}
                    value={formik.values.radius}
                    persisentThumb
                    onChange={(value) => {
                      formik.setFieldValue("radius", value);
                    }}
                    onAfterChange={() => formik.submitForm()}
                    formatValue={(value) =>
                      polyglot.toDistance(value || JOBBER_SEARCH_DISTANCE_MAX, {
                        noFormat: true,
                      })
                    }
                    ticks={[
                      polyglot.toDistance(JOBBER_SEARCH_DISTANCE_MIN, {
                        noFormat: true,
                      }),
                      polyglot.toDistance(JOBBER_SEARCH_DISTANCE_MAX, {
                        noFormat: true,
                      }),
                    ]}
                  />
                </Block>
              </FormControl>
            </div>
          )}
        >
          {polyglot.t("common.distance")}
        </FilterButton>
      )}
    </Block>
  );
};

export default SearchFilters;
