import { BrowserRouter as Router } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import { DashboardContext } from "../modules/contexts";
import Dashboard from "../pages/Dashboard";

const DashboardApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <DashboardContext.Provider
        value={{
          public_pages: props.public_pages,
        }}
      >
        <Router>
          <Dashboard {...props} />
        </Router>
      </DashboardContext.Provider>
    </BaseApp>
  );

export default DashboardApp;
