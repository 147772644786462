import { getStoreBadgesSrc } from "@/utils/get-store-badges-src";

const StoreBadge = ({
  appleStore,
  googlePlay,
  url,
  className,
  width,
  height,
}) => (
  <a
    href={url}
    className="d-flex align-items-center justify-content-center"
    target="_blank"
    rel="noreferrer"
  >
    {appleStore && (
      <img
        src={getStoreBadgesSrc().ios}
        alt="Apple store"
        className={className}
        width={width}
        height={height}
      />
    )}
    {googlePlay && (
      <img
        src={getStoreBadgesSrc().android}
        alt="Google Play"
        className={className}
        width={width}
        height={height}
      />
    )}
  </a>
);

export default StoreBadge;
