import * as yup from "yup";
import { INSTANT_GO_NEXT_DELAY } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import StepTitle from "../common/StepTitle";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { DETAILS_PATH, PHOTOS_PATH } from "../constants";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";
import { moveIndexPosition } from "../utils";

const NB_HOURS_OPTIONS = [
  {
    value: 2,
    label: polyglot.t("categories.short"),
    subtitle: polyglot.t("categories.move_large_furniture"),
  },
  {
    value: 4,
    label: polyglot.t("categories.classic"),
    subtitle: polyglot.t("categories.move_about_twenty_boxes"),
    popular: true,
  },
  {
    value: 6,
    label: polyglot.t("categories.long"),
    subtitle: polyglot.t("categories.move_entire_apartment"),
  },
];

export function useCategory3099() {
  const nhConfig = {
    nh_specific3: {
      label: polyglot.t("categories.need_a_truck"),
      options: [
        { value: 0.0, label: polyglot.t("common.no") },
        { value: 1.0, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: "jobber-own-truck",
      validationSchema: yup.object().shape({
        nh_specific3: yup.number().required(),
      }),
      View: ({ goNext }) => (
        <>
          <StepTitle>{nhConfig.nh_specific3.label}</StepTitle>
          <StyledFormControl>
            <FormSelectables
              onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
              options={nhConfig.nh_specific3.options}
              name="nh_specific3"
            />
          </StyledFormControl>
        </>
      ),
    },
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: NB_HOURS_OPTIONS,
      },
    },
  ];

  return {
    version: 2.2,
    pages,
    initialValues: { nb_hours: 4 },
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
      initialTitleIsEmpty: true,
      renderPages: (pages) => {
        let newPages = [...pages];
        const detailsIndex = newPages.findIndex((p) => p.path === DETAILS_PATH);
        const photosIndex = newPages.findIndex((p) => p.path === PHOTOS_PATH);
        newPages[detailsIndex].title = polyglot.t(
          "categories.what_is_your_need"
        );
        newPages = moveIndexPosition(newPages, photosIndex, 0);
        newPages = moveIndexPosition(newPages, detailsIndex, 0);
        return newPages;
      },
    },
  };
}
