import { useFormikContext } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AddressModal from '../AddressModal';

const SelectAddress = ({ onClose }) => {
  const history = useHistory();
  return (
    <AddressModal
      onClose={() => history.replace('/')}
      inContent
      onBack={() => history.replace('/')}
    />
  );
};

export default SelectAddress;
