import React from "react";
import styled from "styled-components";
import Icon from "./Icon";
import { colors, spacings } from "../assets/themes";

function flatten(array) {
  return array.reduce((memo, el) => {
    const items = Array.isArray(el) ? flatten(el) : [el];
    return memo.concat(items);
  }, []);
}

const StyledUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
`;

const StyledLi = styled.li`
  display: inline-flex;
  align-items: center;
`;

const Breadcrumb = ({ children, className, style, ...rest }) => {
  const renderChildren = () =>
    flatten(children).map((child, index) => (
      <StyledLi key={`bc-comp${index}`}>
        {child}
        {index !== flatten(children).length - 1 && (
          <Icon.Small
            name="angle-right"
            color={colors.muted}
            css={`
              margin-left: ${spacings.xs};
              margin-right: ${spacings.xs};
            `}
          />
        )}
      </StyledLi>
    ));
  return (
    <StyledUl className={className} style={style} {...rest}>
      {renderChildren()}
    </StyledUl>
  );
};

export default Breadcrumb;
