import { useCategory1001 } from "./1001";
import { useCategory1002 } from "./1002";
import { useCategory1003 } from "./1003";
import { useCategory1004 } from "./1004";
import { useCategory1005 } from "./1005";
import { useCategory1009 } from "./1009";
import { useCategory1025 } from "./1025";
import { useCategory1100 } from "./1100";
import { useCategory4001 } from "./4001";
import { useCategory1006 } from "./1006";
import { useCategory3003 } from "./3003";
import { useCategory1026 } from "./1026";
import { useCategory1027 } from "./1027";
import { useCategory1028 } from "./1028";
import { useCategory1019 } from "./1019";
import { useCategory1031 } from "./1031";
import { useCategory1032 } from "./1032";
import { useCategory1033 } from "./1033";
import { useCategory1034 } from "./1034";
import { useCategory1035 } from "./1035";
import { useCategory1038 } from "./1038";
import { useCategory1011 } from "./1011";
import { useCategory1014 } from "./1014";
import { useCategory1021 } from "./1021";
import { useCategory1040 } from "./1040";
import { useCategory1015 } from "./1015";
import { useCategory1016 } from "./1016";
import { useCategory1017 } from "./1017";
import { useCategory1018 } from "./1018";
import { useCategory3014 } from "./3014";
import { useCategory1101 } from "./1101";
import { useCategory1103 } from "./1103";
import { useCategory2099 } from "./2099";
import { useCategory3099 } from "./3099";
import { useCategory4099 } from "./4099";
import { useCategory9003 } from "./9003";
import { useCategory9004 } from "./9004";
import { useCategory9005 } from "./9005";
import { useCategory9006 } from "./9006";
import { useCategory1007 } from "./1007";
import { useCategory1008 } from "./1008";
import { useCategory1013 } from "./1013";
import { useCategory1024 } from "./1024";
import { useCategory2010 } from "./2010";
import { useCategory1029 } from "./1029";
import { useCategory1022 } from "./1022";
import { useCategory1023 } from "./1023";
import { useCategory1030 } from "./1030";
import { useCategory1102 } from "./1102";
import { useCategory3004 } from "./3004";
import { useCategory4003 } from "./4003";
import { useCategory4008 } from "./4008";
import { useCategory6010 } from "./6010";
import { useCategory6011 } from "./6011";
import { useCategory6099 } from "./6099";
import { useCategory7099 } from "./7099";
import { useCategory29 } from "./29";
import { useCategory7014 } from "./7014";
import { useCategory1036 } from "./1036";
import { useCategory1037 } from "./1037";
import { useCategory1039 } from "./1039";
import { useCategory1041 } from "./1041";
import { useCategory1042 } from "./1042";
import { useCategory1043 } from "./1043";
import { useCategory1044 } from "./1044";
import { useCategory2001 } from "./2001";
import { useCategory2002 } from "./2002";
import { useCategory2008 } from "./2008";
import { useCategory2011 } from "./2011";
import { useCategory2012 } from "./2012";
import { useCategory2013 } from "./2013";
import { useCategory2014 } from "./2014";
import { useCategory2015 } from "./2015";
import { useCategory2016 } from "./2016";
import { useCategory2017 } from "./2017";
import { useCategory3015 } from "./3015";
import { useCategory3016 } from "./3016";
import { useCategory3017 } from "./3017";
import { useCategory5006 } from "./5006";
import { useCategory5007 } from "./5007";
import { useCategory5008 } from "./5008";
import { useCategory5009 } from "./5009";
import { useCategory6008 } from "./6008";
import { useCategory6012 } from "./6012";
import { useCategory7015 } from "./7015";
import { useCategory7016 } from "./7016";
import { useCategory7017 } from "./7017";
import { useCategory7018 } from "./7018";
import { useCategory7019 } from "./7019";
import { useCategory7020 } from "./7020";
import { useCategory7021 } from "./7021";
import { useCategory7022 } from "./7022";
import { useCategory4009 } from "./4009";
import { useCategory1045 } from "./1045";
import { useCategory1046 } from "./1046";
import { useCategory1047 } from "./1047";
import { useCategory1048 } from "./1048";
import { useCategory1049 } from "./1049";
import { useCategory2018 } from "./2018";
import { useCategory4010 } from "./4010";
import { useCategory3019 } from "./3019";
import { useCategory3020 } from "./3020";
import { useCategory1050 } from "./1050";
import { useCategory1051 } from "./1051";
import { useCategory1071 } from "./1071";
import { useCategory1104 } from "./1104";
import { useCategory1053 } from "./1053";
import { useCategory1052 } from "./1052";
import { useCategory1054 } from "./1054";
import { useCategory1055 } from "./1055";
import { useCategory1105 } from "./1105";
import { useCategory1061 } from "./1061";
import { useCategory1062 } from "./1062";
import { useCategory1063 } from "./1063";
import { useCategory1064 } from "./1064";
import { useCategory1065 } from "./1065";
import { useCategory1106 } from "./1106";
import { useCategory1107 } from "./1107";
import { useCategory1066 } from "./1066";
import { useCategory1067 } from "./1067";
import { useCategory1068 } from "./1068";
import { useCategory1108 } from "./1108";
import { useCategory1081 } from "./1081";
import { useCategory1070 } from "./1070";
import { useCategory1073 } from "./1073";
import { useCategory1072 } from "./1072";
import { useCategory1109 } from "./1109";
import { useCategory1074 } from "./1074";
import { useCategory1069 } from "./1069";
import { useCategory1075 } from "./1075";
import { useCategory1076 } from "./1076";
import { useCategory1077 } from "./1077";
import { useCategory1085 } from "./1085";
import { useCategory1111 } from "./1111";
import { useCategory1086 } from "./1086";
import { useCategory1078 } from "./1078";
import { useCategory1079 } from "./1079";
import { useCategory1080 } from "./1080";
import { useCategory2019 } from "./2019";
import { useCategory2020 } from "./2020";
import { useCategory2021 } from "./2021";
import { useCategory2022 } from "./2022";
import { useCategory2023 } from "./2023";
import { useCategory2024 } from "./2024";
import { useCategory2025 } from "./2025";
import { useCategory2026 } from "./2026";
import { useCategory2027 } from "./2027";
import { useCategory2028 } from "./2028";
import { useCategory2029 } from "./2029";
import { useCategory2030 } from "./2030";
import { useCategory2031 } from "./2031";
import { useCategory2032 } from "./2032";
import { useCategory2033 } from "./2033";
import { useCategory2034 } from "./2034";
import { useCategory2035 } from "./2035";
import { useCategory2036 } from "./2036";
import { useCategory2037 } from "./2037";
import { useCategory2038 } from "./2038";
import { useCategory2039 } from "./2039";
import { useCategory2040 } from "./2040";
import { useCategory2041 } from "./2041";
import { useCategory2042 } from "./2042";
import { useCategory2043 } from "./2043";
import { useCategory2044 } from "./2044";
import { useCategory2045 } from "./2045";
import { useCategory2046 } from "./2046";
import { useCategory2047 } from "./2047";
import { useCategory2048 } from "./2048";
import { useCategory2049 } from "./2049";
import { useCategory2050 } from "./2050";
import { useCategory2051 } from "./2051";
import { useCategory4016 } from "./4016";
import { useCategory4011 } from "./4011";
import { useCategory4012 } from "./4012";
import { useCategory4015 } from "./4015";
import { useCategory4013 } from "./4013";
import { useCategory4014 } from "./4014";
import { useCategory4018 } from "./4018";
import { useCategory4019 } from "./4019";
import { useCategory4020 } from "./4020";
import { useCategory4021 } from "./4021";
import { useCategory4022 } from "./4022";
import { useCategory4023 } from "./4023";
import { useCategory4024 } from "./4024";
import { useCategory4025 } from "./4025";
import { useCategory4026 } from "./4026";
import { useCategory4027 } from "./4027";
import { useCategory4028 } from "./4028";
import { useCategory4029 } from "./4029";
import { useCategory4017 } from "./4017";
import { useCategory4102 } from "./4102";
import { useCategory4100 } from "./4100";
import { useCategory4101 } from "./4101";

export default {
  1001: useCategory1001,
  1002: useCategory1002,
  1003: useCategory1003,
  1004: useCategory1004,
  1005: useCategory1005,
  1009: useCategory1009,
  1025: useCategory1025,
  1100: useCategory1100,
  4001: useCategory4001,
  1006: useCategory1006,
  3003: useCategory3003,
  1026: useCategory1026,
  1027: useCategory1027,
  1028: useCategory1028,
  1019: useCategory1019,
  1031: useCategory1031,
  1032: useCategory1032,
  1033: useCategory1033,
  1034: useCategory1034,
  1035: useCategory1035,
  1038: useCategory1038,
  1011: useCategory1011,
  1014: useCategory1014,
  1021: useCategory1021,
  1040: useCategory1040,
  1015: useCategory1015,
  1016: useCategory1016,
  1017: useCategory1017,
  1018: useCategory1018,
  3014: useCategory3014,
  1101: useCategory1101,
  1103: useCategory1103,
  2099: useCategory2099,
  3099: useCategory3099,
  4099: useCategory4099,
  9003: useCategory9003,
  9004: useCategory9004,
  9005: useCategory9005,
  9006: useCategory9006,
  1007: useCategory1007,
  1008: useCategory1008,
  1013: useCategory1013,
  1024: useCategory1024,
  2010: useCategory2010,
  1029: useCategory1029,
  1022: useCategory1022,
  1023: useCategory1023,
  1030: useCategory1030,
  1102: useCategory1102,
  3004: useCategory3004,
  4003: useCategory4003,
  4008: useCategory4008,
  6010: useCategory6010,
  6011: useCategory6011,
  7099: useCategory7099,
  6099: useCategory6099,
  10001: useCategory29,
  10002: useCategory29,
  10003: useCategory29,
  10004: useCategory29,
  10005: useCategory29,
  10006: useCategory29,
  10007: useCategory29,
  10008: useCategory29,
  10009: useCategory29,
  10010: useCategory29,
  10011: useCategory29,
  10012: useCategory29,
  10013: useCategory29,
  10014: useCategory29,
  10015: useCategory29,
  10016: useCategory29,
  10017: useCategory29,
  10018: useCategory29,
  10019: useCategory29,
  10020: useCategory29,
  10021: useCategory29,
  10022: useCategory29,
  7014: useCategory7014,
  1036: useCategory1036,
  1037: useCategory1037,
  1039: useCategory1039,
  1041: useCategory1041,
  1042: useCategory1042,
  1043: useCategory1043,
  1044: useCategory1044,
  2001: useCategory2001,
  2002: useCategory2002,
  2008: useCategory2008,
  2011: useCategory2011,
  2012: useCategory2012,
  2013: useCategory2013,
  2014: useCategory2014,
  2015: useCategory2015,
  2016: useCategory2016,
  2017: useCategory2017,
  3015: useCategory3015,
  3016: useCategory3016,
  3017: useCategory3017,
  5006: useCategory5006,
  5007: useCategory5007,
  5008: useCategory5008,
  5009: useCategory5009,
  6008: useCategory6008,
  6012: useCategory6012,
  7015: useCategory7015,
  7016: useCategory7016,
  7017: useCategory7017,
  7018: useCategory7018,
  7019: useCategory7019,
  7020: useCategory7020,
  7021: useCategory7021,
  7022: useCategory7022,
  4009: useCategory4009,
  1045: useCategory1045,
  1046: useCategory1046,
  1047: useCategory1047,
  1048: useCategory1048,
  1049: useCategory1049,
  2018: useCategory2018,
  4010: useCategory4010,
  3019: useCategory3019,
  3020: useCategory3020,
  1050: useCategory1050,
  1051: useCategory1051,
  1071: useCategory1071,
  1104: useCategory1104,
  1053: useCategory1053,
  1052: useCategory1052,
  1054: useCategory1054,
  1055: useCategory1055,
  1105: useCategory1105,
  1061: useCategory1061,
  1062: useCategory1062,
  1063: useCategory1063,
  1064: useCategory1064,
  1065: useCategory1065,
  1106: useCategory1106,
  1107: useCategory1107,
  1066: useCategory1066,
  1067: useCategory1067,
  1068: useCategory1068,
  1108: useCategory1108,
  1081: useCategory1081,
  1070: useCategory1070,
  1073: useCategory1073,
  1072: useCategory1072,
  1109: useCategory1109,
  1074: useCategory1074,
  1069: useCategory1069,
  1075: useCategory1075,
  1076: useCategory1076,
  1077: useCategory1077,
  1085: useCategory1085,
  1111: useCategory1111,
  1086: useCategory1086,
  1078: useCategory1078,
  1079: useCategory1079,
  1080: useCategory1080,
  2019: useCategory2019,
  2020: useCategory2020,
  2021: useCategory2021,
  2022: useCategory2022,
  2023: useCategory2023,
  2024: useCategory2024,
  2025: useCategory2025,
  2026: useCategory2026,
  2027: useCategory2027,
  2028: useCategory2028,
  2029: useCategory2029,
  2030: useCategory2030,
  2031: useCategory2031,
  2032: useCategory2032,
  2033: useCategory2033,
  2034: useCategory2034,
  2035: useCategory2035,
  2036: useCategory2036,
  2037: useCategory2037,
  2038: useCategory2038,
  2039: useCategory2039,
  2040: useCategory2040,
  2041: useCategory2041,
  2042: useCategory2042,
  2043: useCategory2043,
  2044: useCategory2044,
  2045: useCategory2045,
  2046: useCategory2046,
  2047: useCategory2047,
  2048: useCategory2048,
  2049: useCategory2049,
  2050: useCategory2050,
  2051: useCategory2051,
  4016: useCategory4016,
  4011: useCategory4011,
  4012: useCategory4012,
  4015: useCategory4015,
  4013: useCategory4013,
  4014: useCategory4014,
  4018: useCategory4018,
  4019: useCategory4019,
  4020: useCategory4020,
  4021: useCategory4021,
  4022: useCategory4022,
  4023: useCategory4023,
  4024: useCategory4024,
  4025: useCategory4025,
  4026: useCategory4026,
  4027: useCategory4027,
  4028: useCategory4028,
  4029: useCategory4029,
  4017: useCategory4017,
  4102: useCategory4102,
  4100: useCategory4100,
  4101: useCategory4101,
};
