import moment from "moment";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const CesuContext = React.createContext(null);

const COOKIE_NAME = "cesu";

const CesuProvider = ({ children }) => {
  const [cookies, addCookies, removeCookie] = useCookies([COOKIE_NAME]);
  const [tickets, setTickets] = useState(cookies[COOKIE_NAME]?.codes || []);

  useEffect(() => {
    if (tickets.length > 0) {
      addCookies(
        COOKIE_NAME,
        { codes: tickets },
        { expires: moment().add(30, "minutes").toDate() }
      );
    } else {
      removeCookie(COOKIE_NAME);
    }
  }, [tickets.length]);

  return (
    <CesuContext.Provider
      value={{
        set: setTickets,
        tickets,
      }}
    >
      {children}
    </CesuContext.Provider>
  );
};

export { CesuContext };
export default CesuProvider;
