import { Form, Formik } from "formik";
import { useEffect } from "react";
import { MemoryRouter, Route, Switch } from "react-router-dom";
import * as yup from "yup";
import Artwork from "@/assets/images/home/home-address-artwork.svg";
import { colors } from "@/assets/themes";
import { Block, Button, H3, Icon, Input, Modal } from "@/components";
import { useUpdateHomeAddress } from "@/modules/routes/dashboard-routes";
import { AddressModal } from "@/pages/common";
import { setAddressInitialValues } from "@/utils";
import polyglot from "@/utils/polyglot";

const HomeAddressModal = ({ isOpen, onClose, initialValues, addresses }) => {
  const updateHomeAddress = useUpdateHomeAddress();
  const handleSubmit = (values) => {
    updateHomeAddress.mutate(values);
  };

  useEffect(() => {
    if (updateHomeAddress.isSuccess) {
      onClose();
    }
  }, [updateHomeAddress.isSuccess]);

  return (
    <Modal.Small
      fullScreenOnMobile
      isOpen={isOpen}
      onClose={onClose}
      css={`
        [aria-label="dialog"] {
          background: linear-gradient(
            to bottom,
            ${colors.primary50},
            ${colors.background}
          );
        }
      `}
    >
      <Formik
        validateOnMount
        validationSchema={yup.object().shape({
          address: yup.string().required(),
        })}
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={{
          ...setAddressInitialValues(initialValues),
        }}
      >
        {({ values, isValid }) => (
          <Form
            css={`
              min-height: 100%;
            `}
          >
            <MemoryRouter>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={({ history }) => (
                    <>
                      <Modal.Item.Header onClose={onClose}>
                        <Block
                          display="flex"
                          alignItems="center"
                          flexDirection="column"
                        >
                          <img
                            src={Artwork}
                            alt=""
                            css={`
                              max-width: 200px;
                              max-height: 200px;
                              height: 100%;
                              width: 100%;
                            `}
                          />
                          <Modal.Item.Title align="center" as={H3}>
                            {polyglot.t("home.personalized_experience")}
                          </Modal.Item.Title>
                          <Modal.Item.Subtitle align="center">
                            {polyglot.t(
                              "home.personalized_experience_description"
                            )}
                          </Modal.Item.Subtitle>
                        </Block>
                      </Modal.Item.Header>

                      <Modal.Item.Wrapper>
                        <Input
                          LeftComponent={() => <Icon.Large name="map-marker" />}
                          placeholder={polyglot.t("common.placeholder.address")}
                          value={values.address}
                          onClick={() => {
                            history.push("/address");
                            const modalContent = document.querySelector(
                              'div[aria-label="dialog"]'
                            );
                            modalContent.style.background = colors.background;
                          }}
                          RightComponent={() => <Icon.Large name="dropdown" />}
                        />
                      </Modal.Item.Wrapper>
                      <Modal.Item.Footer column>
                        <Button.Large
                          type="submit"
                          block
                          disabled={!isValid}
                          isLoading={updateHomeAddress.isLoading}
                        >
                          {polyglot.t("common.save")}
                        </Button.Large>
                      </Modal.Item.Footer>
                    </>
                  )}
                />
                <Route
                  path="/address"
                  render={({ history }) => (
                    <AddressModal
                      inContent
                      addresses={addresses}
                      onBack={() => {
                        history.goBack();
                        const modalContent = document.querySelector(
                          'div[aria-label="dialog"]'
                        );
                        modalContent.style.background = "";
                      }}
                      onClose={onClose}
                    />
                  )}
                />
              </Switch>
            </MemoryRouter>
          </Form>
        )}
      </Formik>
    </Modal.Small>
  );
};

export default HomeAddressModal;
