import { useState } from "react";
import { colors } from "../../../../assets/themes";
import { Block, Body16, Icon, List } from "../../../../components";
import LongStringSlicer from "../../../../components/LongStringSlicer";
import cesuGetAmount from "../../../../utils/cesu-get-amount";
import getFirstStringChars from "../../../../utils/getFirstStringChars";
import getLastStringChars from "../../../../utils/getLastStringChars";
import polyglot from "../../../../utils/polyglot";
import ManualInputModal from "../ManualInputModal";

const CesuInput = ({ code, setTickets, tickets }) => {
  const [inputModalIsOpen, setInputModalIsOpen] = useState(false);

  const handleCloseModal = () => {
    setInputModalIsOpen(false);
  };

  return (
    <List.Item
      chevron
      onClick={() => {
        setInputModalIsOpen(true);
      }}
      RightComponent={
        code
          ? () => (
              <Body16 strong>
                {polyglot.toSmartCurrency(cesuGetAmount(code.bar_code))}
              </Body16>
            )
          : null
      }
      LeftComponent={() => <Icon.Large name="ticket" color={colors.muted} />}
    >
      <Block display="flex" justifyContent="between" width="100%">
        <Body16 strong>
          {getFirstStringChars(code.bar_code, 4)} <LongStringSlicer />{" "}
          {getLastStringChars(code.bar_code, 4)}
        </Body16>
      </Block>
      <ManualInputModal
        code={code}
        isOpen={inputModalIsOpen}
        closeModal={handleCloseModal}
        setTickets={setTickets}
        tickets={tickets}
      />
    </List.Item>
  );
};

export default CesuInput;
