import { useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useFetchDistance } from "../../modules/routes/category-forms-routes";
import { DISTANCE_QUERY } from "./constants";
import getDistance from "../../utils/get-distance";
import { getNbHoursFromDistance } from "./utils";

export const useGetDistance = (
  {
    address_location,
    destination_address_location,
    lift,
    floor,
    destination_lift,
    destination_floor,
  },
  options
) => {
  const queryKey = [
    DISTANCE_QUERY,
    address_location,
    lift,
    floor,
    destination_address_location,
    destination_lift,
    destination_floor,
  ];
  const queryClient = useQueryClient();
  let distance_in_meters = getDistance(
    address_location,
    destination_address_location
  );
  distance_in_meters *= 1000;
  let duration_in_seconds = getNbHoursFromDistance(distance_in_meters);
  duration_in_seconds *= 3600;

  const handleAddFakeData = () => {
    queryClient.setQueryData([queryKey], (data) => ({
      ...data,
      distance_in_meters,
      duration_in_seconds,
    }));
  };

  const res = useFetchDistance(
    {
      address_location,
      destination_address_location,
    },
    {
      shadow: true,
      refetchOnWindowFocus: false,
      queryKey,
      ...options,
    }
  );

  useEffect(() => {
    if (
      res.isError ||
      (res.isSuccess && res.data?.distance_in_meters === null) ||
      (res.isSuccess && res.data?.duration_in_seconds === null)
    ) {
      handleAddFakeData();
    }
  }, [res.isError, res.isSuccess]);

  return res;
};
