import _ from "lodash";
import axios from "axios";
import { cardTokenize } from "../../../utils/mangopay/card-tokenize";
import getCSRFToken from "../../../utils/csrf";
import {
  ERROR_FORM,
  SUBMIT_FORM,
  SUCCEED_FORM,
  USE_NEW_CREDIT_CARD,
  WIZARD_GO_TO_STEP,
  PAY_LATER,
} from "../../../utils/types";
import { browserInfo } from "../../../utils";

const formatCardType = (type = "visa_mastercard") => {
  if (type === "visa_mastercard") {
    return `CB_${type.toUpperCase()}`;
  }
  return type.toUpperCase();
};

const removeCreditCardData = (attrs) =>
  _.omit(attrs, ["cardNumber", "cardCvx", "cardExpirationDate", "useNewCard"]);

export const sendData = (
  res = null,
  filteredAttrs,
  config,
  dispatch,
  httpMethod
) => {
  getCSRFToken();
  const attrsAndMangoToken = _.merge(
    { ...filteredAttrs, browserInfo },
    res ? { token: res.CardId } : null
  );
  axios[httpMethod](config.submitPath, attrsAndMangoToken)
    .then((response) => {
      dispatch({ type: SUCCEED_FORM, payload: response.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_FORM, payload: error.response.data });
    });
};

export const submitForm = (attrs, config, httpMethod) => (dispatch) => {
  if (attrs.cardNumber) {
    const creditCard = {
      cardNumber: attrs.cardNumber.replace(/\s+/g, ""),
      cardExpirationDate: attrs.cardExpirationDate.replace(/\//g, ""),
      cardCvx: attrs.cardCvx,
      cardType: formatCardType(attrs.cardType) || "CB_VISA_MASTERCARD",
    };
    cardTokenize(
      { attrs: removeCreditCardData(attrs), creditCard },
      config,
      dispatch,
      httpMethod
    );
  } else {
    sendData(null, attrs, config, dispatch, httpMethod);
  }
};

export const mangoPayError = (error, dispatch) => {
  dispatch({ type: ERROR_FORM, payload: error });
};

export const payLater = (jobId) => (dispatch) => {
  axios
    .put(`/${jobId}/prepay_later`)
    .then((response) => {
      dispatch({
        type: SUCCEED_FORM,
        payload: { ...response.data, isPrepayment: true },
      });
    })
    .catch((error) => {
      dispatch({
        type: ERROR_FORM,
        payload: { ...error.response.data, isPrepayment: true },
      });
    });
};

export const useNewCard = (data) => (dispatch) =>
  dispatch({ type: USE_NEW_CREDIT_CARD, payload: data });
