import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Divider, List } from "../../components";
import { ICON, LIST } from "../../components/Styles/variants";
import { useGoogleAnalytics } from "../../modules/hooks";
import { useLogout } from "../../modules/routes/dashboard-routes";
import polyglot from "../../utils/polyglot";
import {
  GET_DASHBOARD_ALT_MENU,
  GET_DASHBOARD_MAIN_MENU,
  GET_REFFERAL_MENU_ITEM,
} from "../dashboard/config/main-menu";

const MenuItem = ({
  href,
  LeftComponent,
  RightComponent,
  title,
  renderTitle,
  subtitle,
  path,
  strong,
  onClick,
}) => (
  <List.Item
    withGutters
    href={path || href}
    divider={false}
    size={LIST.SIZE.DEFAULT}
    LeftComponent={LeftComponent ? () => LeftComponent : null}
    RightComponent={RightComponent ? () => RightComponent : null}
    onClick={onClick}
  >
    <List.Elem.Title strong={strong} numberOfLines={1}>
      {renderTitle ? renderTitle() : title}
    </List.Elem.Title>
    {subtitle && <Body14 color={colors.muted}>{subtitle}</Body14>}
  </List.Item>
);

const MenuItems = ({ data, badges, public_pages }) => {
  const logout = useLogout();
  const { sendEvent } = useGoogleAnalytics();
  return (
    <div>
      {GET_DASHBOARD_MAIN_MENU({
        data: badges || data,
        iconSize: ICON.SIZE.LARGE,
      }).map((item, i) => (
        <MenuItem {...item} t key={`header-menu-item-main-${i}`} strong />
      ))}
      <Block marginY={spacings.xs}>
        <Divider.Cell />
      </Block>
      <MenuItem
        {...GET_REFFERAL_MENU_ITEM({ iconSize: ICON.SIZE.LARGE })}
        onClick={() => sendEvent(GET_REFFERAL_MENU_ITEM({}).event)}
        strong
      />
      <Block marginY={spacings.xs}>
        <Divider.Cell />
      </Block>
      {GET_DASHBOARD_ALT_MENU({
        data: badges || data,
        public_pages,
      }).map((item, i) => (
        <MenuItem {...item} key={`header-menu-alt-item-${i}`} />
      ))}
      <List.Item divider={false} withGutters onClick={() => logout.mutate()}>
        <List.Elem.Title>{polyglot.t("common.logout")}</List.Elem.Title>
      </List.Item>
    </div>
  );
};

export default MenuItems;
