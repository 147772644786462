import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import store from '../../store';
import GiftCardAddForm from './GiftCardAdd/GiftCardAddForm';

class GiftCardAdd extends Component {
  render() {
    return (
      <div>
        <Provider store={store}>
          <GiftCardAddForm {...this.props} />
        </Provider>
      </div>
    );
  }
}

export default GiftCardAdd;
