import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { useIntersectionObserver } from "@/modules/hooks";
import { spacings } from "../assets/themes";
import polyglot from "../utils/polyglot";
import Block from "./Block";
import Button from "./Button";
import Spinner from "./Spinner";
import { BUTTON } from "./Styles/variants";

const FetchingBox = React.forwardRef(
  ({ isFetching, reverse, isAvailable, fetchNextPage, enabled }, ref) => (
    <>
      {!isAvailable && enabled && (
        <Block marginTop={spacings.m} display="flex" justifyContent="center">
          <Button.Medium
            kind={BUTTON.KIND.SECONDARY}
            isLoading={isFetching}
            onClick={fetchNextPage}
          >
            {polyglot.t("common.see_more")}
          </Button.Medium>
        </Block>
      )}
      {isAvailable && (
        <Block display="flex" flexDirection="column">
          <div
            ref={ref}
            css={`
              width: 1px;
              height: 1px;
              order: ${reverse && "-1"};
            `}
          />
          {isFetching && (
            <Block
              display="flex"
              alignItem="center"
              justifyContent="center"
              marginY={spacings.m}
            >
              <Spinner.Medium />
            </Block>
          )}
        </Block>
      )}
    </>
  )
);

const InfiniteScrollEnhancer = ({
  query,
  children,
  unionBy,
  unionPath,
  reverse,
  renderIntersection,
  enableScrollRestauration = false,
  onIntersect = () => {},
}) => {
  const { current_admin, isConcierge, isSav } = useSelector(
    (state) => state.rails
  );
  const isSessionStorageEnabled =
    enableScrollRestauration && !current_admin && !isConcierge && !isSav;
  const wrapperRef = useRef();
  const anchorRef = useRef();
  const queryClient = useQueryClient();
  const [isAvailable, setIsAvailable] = useState(true);

  // Session storage data + scroll restauration
  const savedData = sessionStorage.getItem(query.key);
  const getSessionStorageData = async () => {
    if (savedData) {
      const { data } = JSON.parse(savedData) || {};
      await queryClient.setQueryData([query.key], data);
    }
  };
  useEffect(() => {
    if (savedData) {
      const { scrollTop, data } = JSON.parse(savedData) || {};
      if (data && scrollTop <= document.documentElement.scrollHeight) {
        window.scrollTo({ top: scrollTop });
        sessionStorage.removeItem(query.key);
      }
    }
  }, [document.documentElement.scrollHeight]);
  useEffect(() => {
    if (isSessionStorageEnabled) getSessionStorageData();
  }, [queryClient, query.key, savedData]);
  // END

  const firstPage = _.get(
    query.data?.pages[0]?.data,
    unionPath ? unionPath.split(".").slice(0, -1).join(".") : undefined,
    query.data?.pages[0]?.data
  );

  const union =
    query.data?.pages?.length > 1
      ? query.data?.pages
          .map((elem) =>
            _.get(
              elem.data,
              unionPath ? unionPath.split(".").slice(0, -1).join(".") : unionBy
            )
          )
          .flat()
      : _.get(
          query.data?.pages[0]?.data,
          unionPath ? unionPath.split(".").slice(0, -1).join(".") : unionBy
        );

  useIntersectionObserver({
    target: anchorRef,
    getIsAvailable: (b) => setIsAvailable(b),
    onIntersect: (e) => {
      onIntersect(e);
      query.fetchNextPage(e);
    },
    enabled: query.hasNextPage === true,
  });

  const renderFetchBox = () =>
    renderIntersection ? (
      renderIntersection({
        isFetching: query.isFetchingNextPage,
        enabled: query.hasNextPage === true,
        fetchNextPage: () => query.fetchNextPage(),
      })
    ) : (
      <FetchingBox
        reverse={reverse}
        ref={anchorRef}
        enabled={query.hasNextPage === true}
        isAvailable={isAvailable}
        fetchNextPage={() => query.fetchNextPage()}
        isFetching={query.isFetchingNextPage}
      />
    );

  const formatedData = firstPage &&
    unionBy && {
      ...firstPage,
      [unionBy]: union,
    };

  const handleClick = (event) => {
    const { target } = event;
    const parentLink = target.closest("a");
    if (
      isSessionStorageEnabled &&
      parentLink &&
      parentLink.href &&
      query.data &&
      // not type button prevent save data on react-router-dom link -> Should be more reliable
      parentLink.getAttribute("type") !== "button"
    ) {
      sessionStorage.setItem(
        query.key,
        JSON.stringify({
          data: query.data,
          scrollTop: document.documentElement.scrollTop,
        })
      );
    }
  };

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (wrapper) {
      wrapper.addEventListener("click", handleClick);
    }

    return () => {
      if (wrapper) {
        wrapper.removeEventListener("click", handleClick);
      }
    };
  }, [wrapperRef.current, query.dataUpdatedAt]);

  return (
    <div ref={wrapperRef}>
      {reverse && renderFetchBox()}
      {children({
        ...query,
        isLoading: query.isLoading || !formatedData,
        data: formatedData,
      })}
      {!reverse && renderFetchBox()}
    </div>
  );
};

export default InfiniteScrollEnhancer;
