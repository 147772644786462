import polyglot from "@/utils/polyglot";
import { Modal } from "../../../components";
import TicketNewJobItem from "./TicketNewJobItem";

const TicketNewJobModal = ({ jobs, onChange, onClose, isOpen }) => (
  <Modal.Small fullScreenOnMobile onClose={onClose} isOpen={isOpen}>
    <Modal.Item.Header onClose={onClose} isOpen={isOpen}>
      <Modal.Item.Title>{polyglot.t("tickets.select_job")}</Modal.Item.Title>
    </Modal.Item.Header>
    {jobs.map((job) => (
      <TicketNewJobItem
        withGutters
        key={`job-contact-selection-${job.id}`}
        job={job}
        onChange={(v) => {
          onChange(v);
          onClose();
        }}
      />
    ))}
  </Modal.Small>
);
export default TicketNewJobModal;
