import { spacings } from "../../../assets/themes";
import { Alert, Block } from "../../../components";
import polyglot from "../../../utils/polyglot";
import { NB_HOURS_PATH } from "../constants";

export function useCategory1077() {
  const initialValues = { nb_hours: 1 };

  return {
    version: 1.1,
    initialValues,
    config: {
      renderPages: (pages) => {
        const newPages = [...pages];
        const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
        newPages[nbHoursIndex].title = polyglot.t(
          "categories.baking_tray_installation_recommendation",
          { nb_hours: polyglot.toDuration(1, true) }
        );
        const { View } = newPages[nbHoursIndex];
        newPages[nbHoursIndex].View = (props) => (
          <View {...props}>
            <Block marginTop={spacings.sm}>
              <Alert.Low>
                {polyglot.t("categories.gas_cooktop_services_not_accepted")}
              </Alert.Low>
            </Block>
          </View>
        );
        return newPages;
      },
    },
  };
}
