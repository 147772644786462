import { useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import PasswordChecker from "../../../components/password/PasswordChecker";
import InputIcon from "../../../components/InputIcon";
import polyglot from "../../../utils/polyglot";
import FacebookLogin from "../../../components/FacebookLogin";
import { signUp } from "../../../modules/routes/sign-routes";
import PostalCodeInput from "../../../components/PostalCodeInput";
import {
  Alert,
  Avatar,
  Block,
  Body16,
  Button,
  Checkbox,
  FormControl,
  H5,
  Icon,
  Input,
  Modal,
} from "../../../components";
import SignUpTerms from "../SignUpTerms";
import {
  firstNameValidation,
  lastNameValidation,
  MAX_FIRST_NAME_LENGTH,
  MAX_LAST_NAME_LENGTH,
  PASSWORD_MINIMAL_LENGTH,
} from "../../../utils";
import AddressModal from "../../common/AddressModal";
import { ALERT } from "../../../components/Styles/variants";
import { colors, sizes, spacings } from "../../../assets/themes";

const SignUp = (props) => {
  const {
    handleRes,
    title,
    subtitle,
    isPro,
    isJobber,
    hideSocialLogin,
    values,
    disabledFields,
    modal,
    submitText,
    referrer,
    handleFacebookSuccess,
    params,
    alertMessage,
  } = props;
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [error, setError] = useState({});
  const validationSchema = Yup.object().shape({
    user: Yup.object().shape({
      email: Yup.string()
        .email(polyglot.t("common.errors.email_invalid"))
        .required(""),
      ...lastNameValidation,
      ...firstNameValidation,
      is_jobber: Yup.boolean(),
      is_pro: Yup.boolean(),
      kind: Yup.string(),
      company_name: Yup.string().when("is_pro", {
        is: true,
        then: Yup.string().required(""),
      }),
      password: Yup.string()
        .min(
          PASSWORD_MINIMAL_LENGTH,
          polyglot.t("common.errors.password_too_short_plural", {
            count: PASSWORD_MINIMAL_LENGTH,
          })
        )
        .required(""),
    }),
    zipcode: Yup.string().when("user[is_jobber]", {
      is: true,
      then: Yup.string().required(""),
    }),
    place_id: Yup.string().when(["user[is_pro]", "manual_address"], {
      is: (jobberIsPro, manualAddress) => jobberIsPro && !manualAddress,
      then: Yup.string().required(""),
    }),
    address_name: Yup.string().when("manual_address", {
      is: true,
      then: Yup.string().required(""),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    city_id: Yup.string().when("manual_address", {
      is: true,
      then: Yup.string().required(""),
      otherwise: Yup.string().nullable().notRequired(),
    }),
    manual_address: Yup.boolean(),
    alert_setting: Yup.object().shape({
      coreg: Yup.boolean(),
      optin: Yup.boolean(),
    }),
  });

  const sendData = async (form) => {
    let newForm = form;
    if (newForm.zipcode) {
      newForm = {
        ...newForm,
        joblist_address: { city_id: newForm.zipcode },
      };
      delete newForm.zipcode;
    }
    const res = signUp(newForm);
    await handleRes(res).catch(({ response: { data } }) => {
      setLoading(false);
      setError(data);
    });
  };

  const handleSubmit = (formData) => {
    setLoading(true);
    sendData(formData);
  };

  const handlePreventArobase = ({ currentTarget }, { setFieldValue }) => {
    setFieldValue(currentTarget.name, currentTarget.value.replace("@", ""));
  };

  return (
    <Formik
      initialValues={{
        user: {
          email: values?.email || "",
          first_name: values?.first_name || "",
          last_name: values?.last_name || "",
          password: "",
          company_name: "",
          is_jobber: isJobber,
          is_pro: isPro,
          kind: isPro ? "company" : "individual",
        },
        place_id: "",
        address_name: "",
        city_id: "",
        manual_address: false,
        alert_setting: { coreg: true, optin: true },
        ...params,
      }}
      validationSchema={validationSchema}
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formikProps) => (
        <Form className="">
          <div className="">
            <Modal.Item.Header
              onClose={modal && props.onClose}
              onBack={
                history.location?.state?.from ? () => history.goBack() : null
              }
            >
              <Modal.Item.Title>
                {title || polyglot.t("signup.create_account")}
              </Modal.Item.Title>
              <Modal.Item.Subtitle
                dangerouslySetInnerHTML={{
                  __html:
                    subtitle ||
                    polyglot.t("signup.enjoy_free_time_with_jobber"),
                }}
              />
            </Modal.Item.Header>
            <Modal.Item.Wrapper>
              {referrer && (
                <Block
                  display="flex"
                  alignItems="center"
                  marginBottom={spacings.m}
                >
                  <Avatar size={sizes.size54} name={referrer.first_name} />
                  <Block marginLeft={spacings.s}>
                    <Body16>{polyglot.t("referral.invited_by")}</Body16>
                    <H5>{referrer.first_name}</H5>
                  </Block>
                </Block>
              )}
              {!formikProps.values.user.is_pro && (
                <>
                  {!hideSocialLogin && (
                    <>
                      <FacebookLogin
                        {...props}
                        onSuccess={handleFacebookSuccess}
                      />
                      <div
                        className="u-divider text-muted font-size-1"
                        data-label="OU"
                      />
                    </>
                  )}
                </>
              )}
              {error && error.errorMessage && (
                <Block marginBottom={spacings.m}>
                  <Alert.Low kind={ALERT.KIND.DANGER}>
                    {error.errorMessage}
                  </Alert.Low>
                </Block>
              )}
              <InputIcon
                name="user[first_name]"
                maxLength={MAX_FIRST_NAME_LENGTH}
                onChange={(event) =>
                  handlePreventArobase(event, {
                    setFieldValue: formikProps.setFieldValue,
                  })
                }
                autoFocus
                type="text"
                placeholder={polyglot.t("common.first_name")}
                icon="user"
                errors={
                  (!!formikProps.values?.user?.first_name &&
                    formikProps.errors) ||
                  error.errors
                }
              />
              <InputIcon
                name="user[last_name]"
                maxLength={MAX_LAST_NAME_LENGTH}
                onChange={(event) =>
                  handlePreventArobase(event, {
                    setFieldValue: formikProps.setFieldValue,
                  })
                }
                type="text"
                placeholder={polyglot.t("common.last_name")}
                icon="user"
                errors={error.errors}
              />
              {formikProps.values.user.is_jobber && (
                <PostalCodeInput
                  name="zipcode"
                  placeholder={polyglot.t("common.zipcode")}
                />
              )}
              {formikProps.values.user.is_pro && (
                <>
                  <InputIcon
                    name="user[company_name]"
                    onChange={null}
                    type="text"
                    placeholder={polyglot.t("common.company_name")}
                    icon="building"
                    errors={error.errors}
                  />
                  <Field name="address">
                    {({ field }) => (
                      <FormControl>
                        <Input
                          onClick={() => setAddressModalIsOpen(true)}
                          value={field.value}
                          LeftComponent={() => (
                            <Icon.Large
                              name="map-marker"
                              color={colors.mutedLight}
                            />
                          )}
                          placeholder={polyglot.t("common.placeholder.address")}
                          RightComponent={() => <Icon.Medium name="dropdown" />}
                        />
                      </FormControl>
                    )}
                  </Field>
                  <AddressModal
                    isOpen={addressModalIsOpen}
                    onClose={() => setAddressModalIsOpen(false)}
                  />
                </>
              )}
              <InputIcon
                name="user[email]"
                onChange={null}
                type="email"
                placeholder={polyglot.t("common.email")}
                icon="envelope"
                autoComplete="email"
                disabled={disabledFields && disabledFields.includes("email")}
                errors={error.errors}
              />
              <InputIcon
                name="user[password]"
                onChange={null}
                type="password"
                autoComplete="new-password"
                placeholder={polyglot.t("common.password")}
                icon="lock"
                errors={error.errors}
              />
              <PasswordChecker value={formikProps.values.user.password} />
              <div className="my-3">
                <Field name="alert_setting[optin]" type="checkbox">
                  {({ field }) => (
                    <Checkbox {...field}>
                      {polyglot.t("optin.news_gift_tips")}
                    </Checkbox>
                  )}
                </Field>
                <Field name="alert_setting[coreg]" type="checkbox">
                  {({ field }) => (
                    <Checkbox {...field}>
                      {polyglot.t("optin.partner")}
                    </Checkbox>
                  )}
                </Field>
              </div>
              {alertMessage && (
                <Block marginBottom={spacings.s}>
                  <Alert.Low>{alertMessage}</Alert.Low>
                </Block>
              )}
              <Button.Medium
                disabled={!formikProps.isValid}
                type="submit"
                isLoading={loading}
                block
              >
                {submitText || polyglot.t("common.i_register")}
              </Button.Medium>
              <SignUpTerms isJobber={isJobber} />
            </Modal.Item.Wrapper>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SignUp;
