import { colors } from "../../assets/themes";
import { getInboxesUrl } from "../../pages/Job/utils";
import polyglot from "../../utils/polyglot";
import Badge from "../Badge";
import Icon from "../Icon";
import List from "../List";
import { LIST } from "../Styles/variants";
import { Body14, Body16 } from "../Text";

const JobChat = ({ offer }) => (
  <>
    {!!offer.last_message && (
      <List.Item
        css={`
          background-color: ${colors.backgroundLight};
          &:hover {
            background-color: ${colors.backgroundGray};
          }
        `}
        shape={LIST.SHAPE.ROUND}
        divider={false}
        withGutters
        href={getInboxesUrl(offer.inbox_id, "?from_job=true")}
        chevron
        LeftComponent={() => (
          <Badge
            count={offer.inbox_messages_not_read}
            outlineColor={colors.backgroundLight}
          >
            <Icon.Large name="chat" color={colors.primary} />
          </Badge>
        )}
      >
        <Body14 color={colors.muted}>
          {polyglot.t("routes.conversations")}
        </Body14>
        <Body16 numberOfLines={1} strong={offer.inbox_messages_not_read > 0}>
          {offer.last_message.first_name
            ? `${offer.last_message.first_name}: ${offer.last_message.body}`
            : offer.last_message.body}
        </Body16>
      </List.Item>
    )}
  </>
);
export default JobChat;
