import VipBadge from "@/assets/images/svg-icons/Badges/Vip.svg";
import { colors, sizes } from "@/assets/themes";
import { Block, Body14, H5, List, Progress } from "@/components";
import { cssVarToNumber } from "@/components/Styles/Helper";
import { LIST } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";

const JobVipBanner = () => {
  const breakpoints = useBreakpoints();

  // const getVipProgressPhrase = () => {
  //   if (vip_status.progress === 0)
  //     return polyglot.t("job.vip_status_new", { count: 0 });
  //   if (vip_status.progress === 100)
  //     return polyglot.t("job.vip_status_completed", {
  //       count: 0,
  //     });
  //   return polyglot.t("job.vip_status_incompleted", {
  //     count: 0,
  //   });
  // };
  return (
    <List.Item
      divider={false}
      shape={LIST.SHAPE.ROUND}
      withGutters
      css={`
        background-color: ${colors.background};
      `}
      LeftComponent={() => (
        <Block
          position="relative"
          width={sizes.size48}
          height={sizes.size48}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Block
            position="absolute"
            top="0"
            left="0"
            zIndex="1"
            pointerEvents="none"
          >
            <Progress.Circular
              size={cssVarToNumber(sizes.size48)}
              value={100}
              thickness={2}
              css={`
                stroke: ${colors.purple}!important;
                transition: 0s !important;
              `}
            />
          </Block>
          <img src={VipBadge} alt="VIP" />
        </Block>
      )}
    >
      <H5>{polyglot.t("routes.vip_status")}</H5>
      <Body14 color={colors.muted}>
        {polyglot.t("job.vip_status_completed")}
      </Body14>
    </List.Item>
  );
};
export default JobVipBanner;
