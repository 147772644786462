import { Field } from "formik";
import React from "react";
import { Textarea } from "../../../../components";

const FormTextarea = ({ name, onChange, ...rest }) => (
  <Field name={name}>
    {({ field, form }) => (
      <Textarea
        {...rest}
        {...field}
        onChange={(e) => {
          if (onChange) onChange(e, form);
          field.onChange(e);
        }}
      />
    )}
  </Field>
);

export default FormTextarea;
