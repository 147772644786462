import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import NewRelation from "../pages/NewRelation";

const NewRelationApp = (props, railsContext) => () =>
  (
    <BaseApp exclude={["AskService"]}>
      <Router basename={railsContext.pathname}>
        <NewRelation {...props} {...railsContext} />
      </Router>
    </BaseApp>
  );

export default NewRelationApp;
