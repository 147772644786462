import { useState } from "react";
import { Select } from "../components";

const SelectInput = ({ name, value: _value, multi, ...rest }) => {
  const defaultValue = multi ? [] : "";
  const [value, setValue] = useState(_value || defaultValue);

  const handleChange = (option) => {
    if (multi) {
      setValue(option.map((o) => o.value) || defaultValue);
    } else {
      setValue(option?.value || defaultValue);
    }
  };
  return (
    <>
      <Select onChange={handleChange} value={value} multi={multi} {...rest} />
      <input name={name} value={value} hidden readOnly />
    </>
  );
};

export default SelectInput;
