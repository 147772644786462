import { Block, Button, Carousel } from "@/components";
import CategoryItem from "./CategoryItem";
import { spacings } from "@/assets/themes";
import { BUTTON } from "@/components/Styles/variants";
import polyglot from "@/utils/polyglot";
import { useAskService, useBreakpoints } from "@/modules/hooks";

const CategoriesHorizontal = ({ block }) => {
  const askService = useAskService();
  const breakpoints = useBreakpoints();

  const calculateSlidesToShow = (length) => {
    if (length <= 2) {
      return { xs: 1, lg: 2, xl: 2 };
    }
    if (length === 3) {
      return { xs: 1, lg: 3, xl: 3 };
    }
    return { xs: 1, lg: 3, xl: 4 };
  };

  return (
    <Block spaceY={spacings.s}>
      <Block
        marginX={{ xs: `calc(${spacings.m} * -1)`, md: "0px" }}
        spaceY={spacings.m}
      >
        <Carousel
          slideToShow={calculateSlidesToShow(block.data?.length)}
          gutter={breakpoints.get({ xs: spacings.s, md: spacings.m })}
          paddingX={{ xs: spacings.m, md: "0px" }}
          withGutters={breakpoints.get({ xs: true, sm: false })}
        >
          {block.data?.map((cat, i) => (
            <CategoryItem
              id={cat}
              key={`categories-horizontal-${block.category_id}-${i}`}
            />
          ))}
        </Carousel>
      </Block>
      {block.category_id && (
        <Button.Small
          kind={BUTTON.KIND.SECONDARY}
          onClick={() => askService.show({ id: block.category_id })}
        >
          {polyglot.t("common.see_more")}
        </Button.Small>
      )}
    </Block>
  );
};

export default CategoriesHorizontal;
