import { useFormikContext } from "formik";
import React from "react";
import { sizes } from "../../assets/themes";
import {
  Button,
  Container,
  Flow,
  List,
  ParagraphInfo,
  Avatar,
} from "../../components";
import JobberDirectCard from "../common/JobberDirectCard";

const SelectKnownJobber = ({ jobbers, goByPath }) => {
  const { setFieldValue, values } = useFormikContext() || {};
  const handleGoNext = (jobber) => {
    setFieldValue("jobber_id", jobber.id);
    setFieldValue("jobber", jobber);
    setFieldValue("is_new_jobber", false);
    setFieldValue("external_jobber", {});
    goByPath("services");
  };

  return (
    <Container.Medium>
      <Flow.Item.Title>Qui est votre employé de maison ?</Flow.Item.Title>
      <div className="row mt-3">
        {jobbers.length > 0 ? (
          jobbers.map((jobber, index) => (
            <div className="col-md-6" key={`jobber-selection-card-${index}`}>
              <JobberDirectCard
                onClick={handleGoNext}
                user={jobber}
                key={jobber.id}
                onEsc={() => goByPath("phone-number")}
              />
            </div>
          ))
        ) : (
          <p className="m-3 text-muted text-center mx-auto">
            Vous n'avez pas encore de prestataire.
          </p>
        )}
      </div>
      <div className="u-divider" data-label="OU" />
      {!values.is_new_jobber ? (
        <ParagraphInfo
          className="flex-column"
          icon="icon-user-plus"
          title="Votre employé de maison n'est pas inscrit sur Yoojo ?"
          body="Votre prestataire sera invité à nous rejoindre sur Yoojo Direct"
        >
          <Button.Large
            kind="tertiary"
            onClick={() => goByPath("phone-number")}
          >
            Ajouter un employé de maison
          </Button.Large>
        </ParagraphInfo>
      ) : (
        <List.Item
          className="border rounded p-3"
          divider={false}
          onClick={() => goByPath("phone-number")}
          LeftComponent={() => <Avatar size={sizes.size64} />}
          RightComponent={() => (
            <span className="badge badge-pill badge-primary p-2">
              Nouveau prestataire
            </span>
          )}
          chevron
        >
          <List.Elem.Title className="font-weight-medium">
            {values.external_jobber.first_name}{" "}
            {values.external_jobber.last_name}
          </List.Elem.Title>
          <List.Elem.Subtitle>Prestataire externe</List.Elem.Subtitle>
        </List.Item>
      )}
      {values.external_jobber.first_name && (
        <Flow.Item.Navigation
          isValid
          goNext={() => goByPath("services")}
          nextLabel={`Continuer avec ${values.external_jobber.first_name}`}
        />
      )}
    </Container.Medium>
  );
};

export default SelectKnownJobber;
