import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { JOB } from "./config";
import PreviewCursor from "./PreviewCursor";
import PreviewElemJobHead from "./PreviewElemJobHead";
// eslint-disable-next-line import/no-cycle
import UserPreviewContent from "./UserPreviewContent";

const PreviewElemCustomerJob = ({ id, cover, title, datetime, url }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [hoverElement, setHoverElement] = useState(null);

  return (
    <LazyLoad height={200}>
      <div ref={setReferenceElement}>
        <div>
          <PreviewCursor node={hoverElement} isPortal />
          <UserPreviewContent
            kind={JOB}
            id={id}
            node={referenceElement}
            noPortal
          />
          <div ref={setHoverElement}>
            <PreviewElemJobHead
              cover={cover}
              title={title}
              datetime={datetime}
              url={url}
            />
          </div>
        </div>
      </div>
    </LazyLoad>
  );
};

export default PreviewElemCustomerJob;
