import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1028() {
  const DEFAULT_NB_HOURS = 3;
  const initialValues = { nb_hours: DEFAULT_NB_HOURS };
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.short"),
            value: 1.5,
            subtitle: polyglot.t("categories.install_one_connected_device"),
          },
          {
            label: polyglot.t("categories.classic"),
            value: DEFAULT_NB_HOURS,
            subtitle: polyglot.t("categories.install_one_or_more_cameras"),
            popular: true,
          },
          {
            label: polyglot.t("categories.long"),
            value: 4.5,
            subtitle: polyglot.t(
              "categories.install_multiple_connected_devices_with_home_automation_center"
            ),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.1,
    initialValues,
    config: { IsNbHoursReadOnly: true },
  };
}
