import styled, { css } from "styled-components";

export const StyledPassordCheckerWrapper = styled.div.attrs({
  className: "border rounded-circle-box overflow-hidden w-100",
})`
  ${(props) => css`
    height: 10px;
  `}
`;

export const StyledPassordCheckerBar = styled.div`
  ${(props) => css`
    height: 100%;
    background: ${props.color};
    width: ${props.width}%;
    transition: 0.2s ease-out;
  `}
`;
