import { useFormik } from "formik";
import { Block, Button, Modal, Stepper } from "@/components";
import polyglot from "@/utils/polyglot";
import { spacings } from "@/assets/themes";
import { BUTTON, STEPPER } from "@/components/Styles/variants";

const EditPricePerHourModal = ({
  isLoading,
  isOpen,
  onClose,
  pricePerHour,
  initialPricePerHour,
  onSubmit,
  min,
  max,
}) => {
  const form = useFormik({
    initialValues: { price_per_hour: pricePerHour },
    onSubmit,
    enableReinitialize: true,
  });

  const handleClose = () => {
    form.resetForm();
    if (onClose) onClose();
  };

  return (
    <Modal.XSmall isOpen={isOpen} onClose={handleClose}>
      <Modal.Item.Header onClose={handleClose}>
        <Modal.Item.Title>{polyglot.t("common.hourly_rate")}</Modal.Item.Title>
      </Modal.Item.Header>
      <Modal.Item.Wrapper>
        <Block
          marginY={spacings.m}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Stepper
            size={STEPPER.SIZE.LARGE}
            value={form.values.price_per_hour}
            onChange={(v) => form.setFieldValue("price_per_hour", v)}
            suffix={`${polyglot.currency}/h`}
            min={min}
            max={max}
            hint={`Tarif horaire initial : ${polyglot.toSmartCurrency(
              initialPricePerHour,
              {
                pricePerHour: true,
              }
            )}`}
          />
        </Block>
      </Modal.Item.Wrapper>
      <Modal.Item.Footer column>
        <Button.Large
          type="submit"
          block
          onClick={form.submitForm}
          isLoading={isLoading}
        >
          {polyglot.t("common.save")}
        </Button.Large>
        <Button.Large block onClick={handleClose} kind={BUTTON.KIND.SECONDARY}>
          {polyglot.t("common.cancel")}
        </Button.Large>
      </Modal.Item.Footer>
    </Modal.XSmall>
  );
};

export default EditPricePerHourModal;
