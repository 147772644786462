import {
  borderWidth,
  colors,
  radius,
  shadows,
  sizes,
  spacings,
} from "@/assets/themes";
import { Avatar, Block, Map } from "@/components";
import { getCssProperty } from "@/components/Styles/Helper";
import polyglot from "@/utils/polyglot";

const calculateBounds = (center, radius, factor = 0.8) => {
  const earthRadius = 6378137;

  const adjustedRadius = radius * factor;

  const latDiff = (adjustedRadius / earthRadius) * (180 / Math.PI);
  const lngDiff =
    (adjustedRadius / (earthRadius * Math.cos((Math.PI * center.lat) / 180))) *
    (180 / Math.PI);

  return {
    northEast: {
      lat: center.lat + latDiff,
      lng: center.lng + lngDiff,
    },
    southWest: {
      lat: center.lat - latDiff,
      lng: center.lng - lngDiff,
    },
  };
};

const zoomToFit = (circle, map) => {
  const center = circle.getCenter();
  const radius = circle.getRadius();

  const bounds = calculateBounds(
    { lat: center.lat(), lng: center.lng() },
    radius,
    0.8
  );

  map.fitBounds(
    new window.woosmap.map.LatLngBounds(bounds.southWest, bounds.northEast)
  );
};

const Marker = () => (
  <Block
    width={sizes.size20}
    height={sizes.size20}
    borderRadius={radius.circle}
    backgroundColor={colors.primary}
    css={`
      border: solid ${borderWidth.ml} ${colors.onColor};
      transform: translate(-50%, -50%);
      box-shadow: ${shadows.s};
    `}
  />
);

const createCircle = (map, location, zoom = false) => {
  const circle = new window.woosmap.map.Circle({
    strokeColor: getCssProperty(colors.primary),
    strokeOpacity: 1,
    strokeWeight: 2,
    fillColor: getCssProperty(colors.primary),
    fillOpacity: 0.2,
    map,
    center: { lat: location.latitude, lng: location.longitude },
    radius: polyglot.toKm(location.radius) * 1000,
  });
  if (!zoom) zoomToFit(circle, map);
  return circle;
};

const MapStandalone = ({
  width = "100%",
  height = "400px",
  latitude,
  longitude,
  markers = [],
  zoom = false,
  zoomControl = false,
  gestureHandling = "none",
}) => {
  const handleApiLoaded = (map) => {
    markers
      .filter(({ radius_in_km }) => radius_in_km)
      .forEach(({ latitude, longitude, radius_in_km }) => {
        createCircle(map, { latitude, longitude, radius: radius_in_km }, zoom);
      });
  };

  return (
    <Block width={width} height={height}>
      <Map
        onApiLoaded={handleApiLoaded}
        defaultZoom={zoom || 1}
        defaultCenter={{
          lat: latitude,
          lng: longitude,
        }}
        zoomControl={zoomControl}
        gestureHandling={gestureHandling}
        disableDefaultUI
      >
        {markers
          .filter(({ radius_in_km }) => !radius_in_km)
          .map(({ latitude, longitude }, index) => (
            <Map.Marker
              lat={latitude}
              lng={longitude}
              key={`${latitude}-${longitude}-${index}-marker`}
            >
              <Marker />
            </Map.Marker>
          ))}
      </Map>
    </Block>
  );
};

export default MapStandalone;
