import React, { useState } from "react";
import { colors, sizes, spacings } from "../../assets/themes";
import {
  Alert,
  Avatar,
  Block,
  Body14,
  Body16,
  List,
  StatusEnhancer,
} from "../../components";
import { ALERT, LIST } from "../../components/Styles/variants";
import { CUSTOMER } from "./config";
import PreviewCursor from "./PreviewCursor";
// eslint-disable-next-line import/no-cycle
import UserPreviewContent from "./UserPreviewContent";

const PreviewElemJobCustomer = ({ customer, concierge_customer }) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [hoverElement, setHoverElement] = useState(null);
  return (
    <div ref={setReferenceElement}>
      <PreviewCursor node={hoverElement} />
      <UserPreviewContent
        kind={CUSTOMER}
        id={customer.id}
        node={referenceElement}
        noPortal
      />
      <List.Item
        ref={setHoverElement}
        size={LIST.SIZE.COMPACT}
        withGutters
        divider={!customer.deleted}
        target="_blank"
        href={customer.url}
        RightComponent={() => (
          <>
            <Block marginRight={spacings.s}>
              <StatusEnhancer isVip={customer.is_vip}>
                <Body16>
                  {customer.first_name} {customer.last_name}
                </Body16>
              </StatusEnhancer>
            </Block>
            <Avatar
              src={customer.avatar}
              size={sizes.size20}
              name={customer.first_name}
            />
          </>
        )}
      >
        <Body16 color={colors.muted}>
          {concierge_customer ? "Conciergerie" : "Client"}
        </Body16>
        {customer.company_name && (
          <Body14 align="right">{customer.company_name}</Body14>
        )}
      </List.Item>
      {customer.deleted && (
        <Block marginY={spacings.s} marginX={spacings.m}>
          <Alert.High kind={ALERT.KIND.DANGER} icon="user-times">
            Utilisateur supprimé
          </Alert.High>
        </Block>
      )}
    </div>
  );
};

export default PreviewElemJobCustomer;
