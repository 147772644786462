import { sizes, spacings } from "../../../assets/themes";
import { Block, Col, Row, Skeleton } from "../../../components";
import { SKELETON } from "../../../components/Styles/variants";
import { useBreakpoints } from "../../../modules/hooks";

const DiscoverSkeleton = () => {
  const breakpoints = useBreakpoints();
  return (
    <Skeleton.Group>
      <Block marginY={spacings.ml}>
        <Skeleton height={sizes.size24} width="33%" sb />
        <Row gutter={[spacings.m]}>
          {new Array(breakpoints.get({ xs: 2, sm: 3, lg: 4 }))
            .fill("")
            .map((_, i) => (
              <Col
                size={{ xs: 6, sm: 4, lg: 3 }}
                key={`discover-skeleton-item-second-${i}`}
              >
                <Skeleton
                  height={{ xs: "100px", md: "120px" }}
                  width="100%"
                  sb
                />
                <Skeleton height={sizes.size16} width="90%" sb />
                <Skeleton
                  height={sizes.size24}
                  width="30%"
                  sb
                  shape={SKELETON.SHAPE.CIRCLE}
                />
              </Col>
            ))}
        </Row>
      </Block>

      <Block marginBottom={spacings.ml}>
        <Skeleton height={sizes.size24} width="29%" sb />
        <Row gutter={{ xs: [spacings.s, spacings.m], md: [spacings.m] }}>
          {new Array(9).fill("").map((_, i) => (
            <Col
              size={{ xs: 6, md: 4 }}
              key={`discover-skeleton-item-third-${i}`}
            >
              <Skeleton height={{ xs: "100px", md: "120px" }} width="100%" sb />
              <Skeleton height={sizes.size16} width="90%" sb />
            </Col>
          ))}
        </Row>
      </Block>

      <Block marginBottom={spacings.ml}>
        <Skeleton height={sizes.size24} width="31%" sb />
        <Row gutter={[spacings.m]}>
          {new Array(breakpoints.get({ xs: 2, sm: 3, lg: 4 }))
            .fill("")
            .map((_, i) => (
              <Col
                size={{ xs: 6, sm: 4, lg: 3 }}
                key={`discover-skeleton-item-fourth-${i}`}
              >
                <Skeleton
                  height={{ xs: "100px", md: "120px" }}
                  width="100%"
                  sb
                />
                <Skeleton height={sizes.size16} width="90%" sb />
                <Skeleton
                  height={sizes.size24}
                  width="30%"
                  sb
                  shape={SKELETON.SHAPE.CIRCLE}
                />
              </Col>
            ))}
        </Row>
      </Block>

      <Block marginBottom={spacings.ml}>
        <Row gutter={[spacings.m]}>
          {new Array(breakpoints.get({ xs: 1, sm: 2, lg: 3 }))
            .fill("")
            .map((_, i) => (
              <Col
                size={{ xs: 12, sm: 6, lg: 4 }}
                key={`discover-skeleton-item-fiveth-${i}`}
              >
                <Skeleton height={300} width="100%" sb />
              </Col>
            ))}
        </Row>
      </Block>

      <Skeleton height={sizes.size24} width="35%" sb />
      {new Array(3).fill("").map((_, i) => (
        <Row gutter={[spacings.m]} key={`discover-skeleton-item-alea-${i}`}>
          {new Array(2).fill("").map((_, n) => (
            <Col
              size={{ xs: 12, md: 6 }}
              key={`discover-skeleton-item-alea-${i}-${n}`}
            >
              <Skeleton height={sizes.size54} width="100%" sb />
            </Col>
          ))}
        </Row>
      ))}
    </Skeleton.Group>
  );
};

export default DiscoverSkeleton;
