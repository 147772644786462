import React from "react";
import BaseApp from "../modules/BaseApp";
import JobberCancellation from "../pages/jobberCancellation/JobberCancellation";

const JobberCancellationApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <JobberCancellation {...props} />
    </BaseApp>
  );

export default JobberCancellationApp;
