import { useRef } from "react";
import Divider from "@/components/Divider";
import { borderWidth, sizes, spacings } from "@/assets/themes";
import polyglot from "@/utils/polyglot";
import AutoSizeTextarea from "@/components/AutoSizeTextarea";
import Block from "@/components/Block";
import Button from "@/components/Button";
import Icon from "@/components/Icon";
import { BUTTON, INPUT } from "@/components/Styles/variants";

const JobCommentChatInput = ({
  value,
  isLoading,
  LeftComponent,
  children,
  content,
  name,
  disabled,
  maxLength,
  placeholder = polyglot.t("chat.add_comment"),
  onChange = () => {},
}) => {
  const inputRef = useRef();
  return (
    <>
      <Divider.Cell />
      <Block display="flex" flexDirection="column" paddingY={spacings.sm}>
        {!disabled && (
          <Block
            display="flex"
            alignItems="flex-end"
            gap={spacings.s}
            paddingX={spacings.m}
            paddingLeft={!LeftComponent && spacings.m}
          >
            {LeftComponent && (
              <div
                css={`
                  margin-right: ${spacings.xs};
                  display: flex;
                  align-items: center;
                  min-height: ${sizes.size48};
                `}
              >
                {LeftComponent()}
              </div>
            )}
            <Block flex="1">
              <AutoSizeTextarea
                name={name}
                ref={inputRef}
                value={value}
                placeholder={placeholder}
                shape={INPUT.SHAPE.CIRCLE}
                onChange={onChange}
                maxLength={maxLength}
              >
                {!!content && content()}
              </AutoSizeTextarea>
            </Block>
            <Block
              display="flex"
              alignItems="center"
              height={`calc(${sizes.size48} + ${borderWidth.m} + ${borderWidth.m})`}
            >
              <Button.Medium
                type="submit"
                disabled={
                  inputRef.current
                    ? inputRef?.current?.value?.trim() === ""
                    : true
                }
                isLoading={isLoading}
                shape={BUTTON.SHAPE.CIRCLE}
              >
                <Icon.Large name="arrow-up" />
              </Button.Medium>
            </Block>
          </Block>
        )}
        {children && (
          <Block
            css={`
              margin-top: ${spacings.xs};
              margin-left: ${spacings.sm};
              margin-right: ${spacings.sm};
            `}
          >
            {children}
          </Block>
        )}
      </Block>
    </>
  );
};
export default JobCommentChatInput;
