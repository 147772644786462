import React from "react";
import styled from "styled-components";
import { colors } from "../../assets/themes";

const StyledBackdrop = styled.div`
  background-color: ${colors.overlayBackdrop};
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;

const Backdrop = ({ onMouseDown }) => (
  <StyledBackdrop onMouseDown={onMouseDown} />
);

export default Backdrop;
