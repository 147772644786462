import polyglot from "../../../utils/polyglot";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory1023() {
  const pages = [
    {
      ...NB_HOURS_SELECTION_PAGE,
      customProps: {
        options: [
          {
            label: polyglot.t("categories.small_surface"),
            value: 3,
            subtitle: polyglot.t("categories.toilet_bathroom_closet"),
          },
          {
            label: polyglot.t("categories.medium_surface"),
            value: 6,
            subtitle: polyglot.t("categories.bedroom_hallway_dressing_room"),
            popular: true,
          },
          {
            label: polyglot.t("categories.large_surface"),
            value: 9,
            subtitle: polyglot.t("categories.living_room_kitchen"),
          },
        ],
      },
    },
  ];

  return {
    pages,
    version: 1.2,
    initialValues: { nb_hours: 6 },
    config: {
      multipleJobbersAllowed: true,
      IsNbHoursReadOnly: true,
    },
  };
}
