import styled, { css } from "styled-components";
import Skeleton from "./Skeleton";

const Group = styled.div`
  ${({ animation = true }) =>
    animation &&
    css`
      ${Skeleton} {
        background-image: linear-gradient(
          133deg,
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(246, 246, 246),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238),
          rgb(238, 238, 238)
        );
        background-attachment: fixed;
        background-size: 400% 100%;
        animation-name: shimmer;
        animation-duration: 1.5s;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        @keyframes shimmer {
          0% {
            background-position: 100% 50%;
          }
          100% {
            background-position: 0% 50%;
          }
        }
      }
    `}
`;

export default Group;
