import React from "react";
import { colors, spacings } from "../../assets/themes";
import {
  Block,
  Body14,
  Body16,
  Button,
  Divider,
  H5,
  Icon,
  Modal,
} from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import { useSupportPhoneNumber } from "../../modules/hooks";
import { formatPhone, SUPPORT_LINES } from "../../utils";
import polyglot from "../../utils/polyglot";

const ModalSupportFooter = () => {
  const { phone } = useSupportPhoneNumber(SUPPORT_LINES.CANCELLATION);
  return (
    <div>
      <Divider.Cell
        css={`
          margin-top: ${spacings.m};
        `}
      />
      <Modal.Item.Wrapper>
        <Block
          display="flex"
          flexDirection="column"
          alignItems="center"
          paddingTop={spacings.m}
        >
          <H5
            align="center"
            css={`
              margin-bottom: ${spacings.xs};
            `}
          >
            {polyglot.t("cancel_review.need_assistance")}
          </H5>
          <Body16 align="center">
            {polyglot.t("cancel_review.we_can_help_you")}
          </Body16>
          <Button.Medium
            href={`tel:${phone}`}
            isLoading={!phone}
            css={`
              margin-top: ${spacings.m};
              margin-bottom: ${spacings.m};
            `}
            kind={BUTTON.KIND.SECONDARY}
            LeftComponent={() => <Icon.Large name="phone" />}
          >
            {formatPhone(phone)}
          </Button.Medium>
          <Body14 color={colors.muted} align="center">
            {polyglot.t("common.phone_assistance_schedule")}
          </Body14>
        </Block>
      </Modal.Item.Wrapper>
    </div>
  );
};

export default ModalSupportFooter;
