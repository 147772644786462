import React, { Suspense, useState } from "react";
import { useSelector } from "react-redux";

const LoginOrRegisterDispatch = React.lazy(() =>
  import("../../pages/loginOrRegister/LoginOrRegisterDispatch")
);
const LoginContext = React.createContext(null);

const LoginProvider = ({ children }) => {
  const { current_user, is_member } = useSelector((state) => state.rails);
  const [loginProps, setLoginProps] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleShow = () => {
    setModalIsOpen(true);
  };

  const handleHide = () => {
    setModalIsOpen(false);
  };

  return (
    <LoginContext.Provider
      value={{
        show: handleShow,
        hide: handleHide,
        isLogged: !!current_user,
        isMember: is_member,
        init: setLoginProps,
        currentUser: current_user,
      }}
    >
      {children}
      <Suspense fallback={<div />}>
        {modalIsOpen && (
          <LoginOrRegisterDispatch
            modal
            isOpen={modalIsOpen}
            onClose={handleHide}
            {...loginProps}
          />
        )}
      </Suspense>
    </LoginContext.Provider>
  );
};

export { LoginContext };
export default LoginProvider;
