import polyglot from "@/utils/polyglot";
import { sizes } from "../../assets/themes";
import {
  Avatar,
  Button,
  Divider,
  List,
  Rating,
  StatusEnhancer,
} from "../../components";

const JobberDirectCard = ({ user, onClick, onEsc }) => (
  <div className="mb-3">
    <div className="p-3 border rounded">
      <List.Item
        divider={false}
        LeftComponent={() => (
          <Avatar
            src={user.avatar}
            alt={user.first_name}
            size={sizes.size64}
            name={user.first_name}
          />
        )}
      >
        <StatusEnhancer isPro={user.is_pro}>
          <p className="mb-0 font-weight-medium font-size-4">
            {user.first_name}
          </p>
        </StatusEnhancer>
        <Rating count={user.rates_count} rate={user.rate} type="compact" />
      </List.Item>
      <Divider.Cell className="mt-2 mb-3" />
      <Button.Large
        block
        onClick={() => {
          onClick(user);
        }}
      >
        {polyglot.t("yoojo_direct.select_first_name", {
          first_name: user.first_name,
        })}
      </Button.Large>
      <Button.Large className="mt-2" kind="tertiary" block onClick={onEsc}>
        {polyglot.t("yoojo_direct.not_this_jobber")}
      </Button.Large>
    </div>
  </div>
);

export default JobberDirectCard;
