import { sizes, spacings } from "../../../assets/themes";
import { Block, Col, Row, Skeleton } from "../../../components";
import { CardBase } from "../../../components/Styles/Base";
import { SKELETON } from "../../../components/Styles/variants";

const OfferSkeletons = () => (
  <div>
    {new Array(3).fill("").map((_, containerIndex) => (
      <Block
        marginTop={spacings.m}
        key={`offer-skeleton-${containerIndex}-container`}
      >
        <CardBase flat>
          <Block
            paddingX={{ xs: spacings.m, md: spacings.ml }}
            paddingY={{ xs: spacings.m, md: spacings.ml }}
          >
            <Block display="flex" alignItems="flex-start">
              <Block
                display={{ xs: "none", lg: "block" }}
                marginRight={spacings.m}
              >
                {/* AVATAR DESKTOP */}
                <Skeleton
                  width={sizes.size96}
                  height={sizes.size96}
                  shape={SKELETON.SHAPE.CIRCLE}
                />
                {/* AVATAR END */}
              </Block>
              {/* CONTENT */}
              <Block width="100%">
                <Block display="flex" alignItems="flex-start">
                  <Block
                    display="flex"
                    alignItems="center"
                    flex="1"
                    minWidth="0"
                    paddingBottom={spacings.m}
                  >
                    {/* AVATAR MOBILE */}
                    <div>
                      <Block
                        display={{ xs: "block", lg: "none" }}
                        marginRight={spacings.m}
                      >
                        <Skeleton
                          width={sizes.size72}
                          height={sizes.size72}
                          shape={SKELETON.SHAPE.CIRCLE}
                        />
                      </Block>
                    </div>
                    {/* AVATAR END */}
                    <Block>
                      <Skeleton height={sizes.size20} width={150} sb />
                      <Skeleton height={sizes.size14} width={80} />
                    </Block>
                  </Block>
                  <Skeleton height={sizes.size20} width={100} />
                </Block>
                <Block marginBottom={spacings.s}>
                  <Skeleton height={sizes.size128} width="100%" />
                </Block>
                <Block marginBottom={spacings.s}>
                  <Skeleton height={sizes.size64} width="100%" />
                </Block>
                <Row gutter={[spacings.s]}>
                  <Col size={12}>
                    <Skeleton height={sizes.size48} width="100%" />
                  </Col>
                  <Col size={12}>
                    <Skeleton height={sizes.size48} width="100%" />
                  </Col>
                </Row>
              </Block>
              {/* END CONTENT */}
            </Block>
          </Block>
        </CardBase>
      </Block>
    ))}
  </div>
);
export default OfferSkeletons;
