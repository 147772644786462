import { AnimatePresence, motion } from "framer-motion";
import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Caption, H3, H5 } from "../../components";
import { useBreakpoints } from "../../modules/hooks";
import { getTruckByValue } from "../categoryForms/constants";
import polyglot from "@/utils/polyglot";

const VolumeResult = ({ value }) => {
  const result = getTruckByValue(value);
  const breakpoints = useBreakpoints();
  return (
    <Block display="flex" justifyContent="center">
      <div>
        <Block
          css={`
            height: ${breakpoints.get({ xs: "100px", md: "120px" })};
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          `}
        >
          <AnimatePresence exitBeforeEnter>
            <motion.div
              key={result.value}
              initial={{ y: "50%", opacity: 0 }}
              animate={{ y: "0%", opacity: 1 }}
              exit={{ y: "-50%", opacity: 0 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                alt={result.label}
                src={result.image}
                css={`
                  height: auto;
                  width: ${breakpoints.get({ xs: "120px", md: "170px" })};
                `}
              />
            </motion.div>
          </AnimatePresence>
        </Block>
      </div>
      <div>
        <Block
          display="flex"
          justifyContent="center"
          flexDirection="column"
          height="100%"
          marginLeft={spacings.s}
        >
          <Caption
            strong
            color={colors.muted}
            css={`
              text-transform: uppercase;
            `}
          >
            {polyglot.t("common.total")}
          </Caption>
          {/* Math.max prevent show "0m³" as result */}
          {breakpoints.get({
            xs: (
              <H5>
                {polyglot.t("common.around")} {Math.max(value.toFixed(0), 1)} m³
              </H5>
            ),
            md: (
              <H3>
                {polyglot.t("common.around")} {Math.max(value.toFixed(0), 1)} m³
              </H3>
            ),
          })}
          {breakpoints.get({
            xs: <Body14 color={colors.muted}>{result.label}</Body14>,
            md: (
              <Body14 color={colors.muted}>
                {polyglot.t("moving_boxes.recommended_truck")}
                &nbsp;
                {result.label}
              </Body14>
            ),
          })}
        </Block>
      </div>
    </Block>
  );
};
export default VolumeResult;
