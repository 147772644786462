import { POPOVER } from "../../components/Styles/variants";
import { IS_PROD } from "../../utils";

export const MOUSE_OUTSIDE_HIDE_DELAY = 200;
export const MOUSE_OUTSIDE_SHOW_DELAY = 300;
export const IN_APP_MOUSE_OUTSIDE_SHOW_DELAY = 300;
export const JOBBER = "jobber";
export const CUSTOMER = "customer";
export const JOB = "job";
export const POPPER_CONFIG = {
  placement: POPOVER.POSITIONS.RIGHT,
  strategy: POPOVER.STRATEGY.ABSOLUTE,
  modifiers: [
    {
      name: "flip",
      options: {
        padding: 16,
        flipVariations: true,
        fallbackPlacements: ["right", "left"],
      },
    },
    {
      name: "preventOverflow",
      options: {
        altBoundary: false,
        padding: 16,
      },
    },
    {
      name: "offset",
      options: {
        offset: [-8, -8],
      },
    },
    {
      name: "eventListeners",
      options: {
        scroll: false,
      },
    },
  ],
};

export const getQueryKey = ({ kind, id }) => `ADMIN_${kind}_PREVIEW_${id}`;
export const addFromParam = (url) => `${url}?from=${window.location.pathname}`;
export const getMangopayUrlById = (mangopay_id) =>
  IS_PROD
    ? `https://hub.mangopay.com/users/${mangopay_id}#transactions`
    : `https://hub.mangopay.com/users/${mangopay_id}#transactions`;
export const getAdminJobberProfile = ({ offseted_id }) =>
  `/kiss/jobbers/${offseted_id}`;
