function importAll(r) {
  const images = {};
  r.keys().map((item) => {
    images[item.replace(/\.[^/.]+$/, "").replace("./", "")] = r(item);
  });
  return images;
}

const artwork = importAll(
  require.context("@/assets/images/removal-items/", false, /\.(png|jpe?g|svg)$/)
);

export default artwork;
