import { motion, useAnimation, useMotionValue } from "framer-motion";
import React, {
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styled, { css } from "styled-components";
import { colors, shadows, sizes } from "../assets/themes";
import { useBreakpoints, useWindowSize } from "../modules/hooks";
import Button from "./Button";
import { Col, Row } from "./Grid";
import Icon from "./Icon";
import { getCssProperty } from "./Styles/Helper";
import { BUTTON } from "./Styles/variants";

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledChildWrapper = styled.div`
  overflow: hidden;
`;

const StyledNavigation = styled.div`
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  button {
    box-shadow: ${shadows.s};
  }
  ${({ position, paddingX = "0px" }) =>
    css`
      ${position}: calc(((${sizes.size36} / 2.5) * -1) + ${paddingX});
    `};
`;
const Carousel = React.forwardRef(
  (
    {
      children,
      slideToShow: _slideToShow = 1,
      slideToScroll: _slideToScroll = 1,
      gutter,
      draggable = true,
      hideNavigation,
      paddingX: _paddingX,
      showDisabledNavigation,
      onChange,
      initialIndex = 0,
      ...rest
    },
    ref
  ) => {
    const { width } = useWindowSize();
    const breakpoints = useBreakpoints();
    const pageRef = useRef();
    const [isAnimating, setIsAnimating] = useState(false);
    const slideToShow = breakpoints.get(_slideToShow);
    const slideToScroll = breakpoints.get(_slideToScroll);
    const paddingX = breakpoints.get(_paddingX);
    const [activePage, setActivePage] = useState(1);

    const getPageByIndex = (index = 0) => {
      const v = Math.ceil((index + 1 - slideToShow) / slideToScroll) + 1;
      return Math.max(1, v);
    };

    const validChildren = React.Children.toArray(children).filter(
      (child) => child !== null
    );
    const childCount = validChildren.length;
    const activeIndex = activePage * slideToShow;
    const transition = { transition: { ease: "easeInOut" } };
    const x = useMotionValue(0);
    const controls = useAnimation();

    const totalPages =
      Math.ceil((childCount - slideToShow) / slideToScroll) + 1;
    const canNext = activePage < totalPages;
    const canPrev = activePage > 1;

    const getNewPosition = (active) => {
      if (active === 1) {
        return 0;
      }
      const gutterSize = gutter
        ? Number(getCssProperty(gutter).replace("px", ""))
        : 0;

      const pageSize =
        pageRef?.current?.getBoundingClientRect()?.width + gutterSize;

      const maxPosition = -pageSize * (childCount / slideToShow - 1);
      const newPosition = -(
        pageSize *
        (slideToScroll / slideToShow) *
        (active - 1)
      );

      return Math.max(maxPosition, newPosition);
    };

    const handleNext = (opts = { isDrag: false }) => {
      let newIndex = activePage;
      if (canNext) {
        newIndex = activePage + 1;
        if (slideToShow % 1 !== 0 && newIndex === childCount) {
          newIndex = activePage + ((slideToShow % 1) - 1) * -1;
        } else {
          newIndex = Math.ceil(newIndex);
        }
        setActivePage(newIndex);
        if (!opts.isDrag) setIsAnimating(true);
        controls.start({ x: getNewPosition(newIndex), transition }).then(() => {
          if (!opts.isDrag) setIsAnimating(false);
        });
      }
      if (onChange) onChange(activeIndex, { totalPages, activePage: newIndex });
    };

    const handlePrev = (opts = { isDrag: false }) => {
      let newIndex = activePage;
      if (canPrev) {
        newIndex = Math.ceil(activePage - 1);
        setActivePage(newIndex);
        if (!opts.isDrag) setIsAnimating(true);
        controls.start({ x: getNewPosition(newIndex), transition }).then(() => {
          if (!opts.isDrag) setIsAnimating(false);
        });
      }
      if (onChange) onChange(activeIndex, { totalPages, activePage: newIndex });
    };

    const handleGoToIndex = (index) => {
      const newPage = getPageByIndex(index);
      setActivePage(newPage);
      requestAnimationFrame(() => {
        controls.start({
          x: getNewPosition(newPage),
          transition: { duration: 0 },
        });
        if (onChange) onChange(index, { totalPages, activePage: newPage });
      });
    };

    useLayoutEffect(() => {
      if (width > 0) handleGoToIndex(initialIndex);
    }, [width]);

    const handleDragEnd = (_, info) => {
      const swipeThreshold = 50;
      if (info.offset.x > swipeThreshold && canPrev) {
        handlePrev({ isDrag: true });
      } else if (info.offset.x < -swipeThreshold && canNext) {
        handleNext({ isDrag: true });
      } else {
        controls.start({ x: getNewPosition(activePage), transition });
      }
    };

    return (
      <StyledWrapper {...rest}>
        <StyledChildWrapper>
          <motion.div
            ref={pageRef}
            animate={controls}
            style={{
              x,
              marginLeft: paddingX,
              marginRight: paddingX,
            }}
            drag={draggable && !isAnimating ? "x" : null}
            onDragEnd={handleDragEnd}
          >
            <Row style={{ flexWrap: "nowrap" }} gutter={gutter}>
              {React.Children.map(children, (child, i) =>
                child ? (
                  <Col key={`slide-${i}`} size={12 / slideToShow}>
                    {React.cloneElement(child)}
                  </Col>
                ) : null
              )}
            </Row>
          </motion.div>
        </StyledChildWrapper>
        {!hideNavigation && (
          <>
            {(canPrev || showDisabledNavigation) && (
              <StyledNavigation position="left" paddingX={paddingX}>
                <Button.Small
                  shape={BUTTON.SHAPE.CIRCLE}
                  kind={BUTTON.KIND.SECONDARY}
                  disabled={!canPrev}
                  onClick={handlePrev}
                >
                  <Icon.Medium name="arrow-left" color={colors.body} />
                </Button.Small>
              </StyledNavigation>
            )}
            {(canNext || showDisabledNavigation) && (
              <StyledNavigation position="right" paddingX={paddingX}>
                <Button.Small
                  shape={BUTTON.SHAPE.CIRCLE}
                  kind={BUTTON.KIND.SECONDARY}
                  disabled={!canNext}
                  onClick={handleNext}
                >
                  <Icon.Medium name="arrow-right" color={colors.body} />
                </Button.Small>
              </StyledNavigation>
            )}
          </>
        )}
      </StyledWrapper>
    );
  }
);

export default Carousel;
