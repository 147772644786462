import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors, radius, sizes, spacings } from "../../assets/themes";
import Button from "../Button";

const StyledButton = styled(Button.Small)`
  ${({ isActive }) =>
    css`
      width: 100%;
      border-radius: ${radius.m};
      background: ${isActive
        ? colors.background
        : colors.transparent}!important;
      color: ${isActive ? colors.body : colors.muted}!important;
      text-wrap: nowrap;
      min-height: 100%;
    `}
`;

const StyledLi = styled.li`
  margin-right: ${spacings.xs};
  flex: 1;
  &:last-child {
    margin-right: 0;
  }
`;
const StyledUl = styled.ul`
  display: flex;
  border-radius: ${radius.ml};
  background: ${colors.backgroundLight};
  padding: ${spacings.xs};
  flex-wrap: nowrap;
  list-style-type: none;
  height: ${sizes.size48};
`;
const SegmentedControl = ({
  data,
  initialIndex = 0,
  activeIndex = initialIndex,
  onChange,
}) => {
  const [currActive, setCurrActive] = useState(activeIndex);

  useEffect(() => {
    if (activeIndex !== currActive) {
      setCurrActive(activeIndex);
    }
  }, [activeIndex]);

  const handleChange = (selectedIndex) => {
    if (onChange) onChange(selectedIndex);
  };

  const renderTabs = () =>
    data.map((tab, index) => (
      <StyledLi key={`${tab.title}-${index}`}>
        <StyledButton
          kind="minimal"
          isActive={index === currActive}
          onClick={() => handleChange(index)}
          {...tab}
        >
          {tab.title}
        </StyledButton>
      </StyledLi>
    ));
  return <StyledUl>{renderTabs()}</StyledUl>;
};

export default SegmentedControl;
