import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import {
  getCreditCardType,
  getPaymentMethodConfig,
  getTokenisationErrorMessage,
} from "../../pages/common/payment-methods/utils";
import { tokenizeCreditCard } from "../../utils/mangopay/card-tokenize-update";
import { useToast } from "../hooks";

export const useTokenizeCreditCard = () =>
  useMutation((values) => tokenizeCreditCard(values));

export const useTokenizeQuery = (queryFunction, options = {}) => {
  const tokenize = useTokenizeCreditCard();
  const toast = useToast();

  useEffect(() => {
    if (tokenize.error) {
      // useTokenizeCreditCard onError temp fix
      toast.danger(getTokenisationErrorMessage(tokenize.error.ResultCode));
    }
  }, [tokenize.isError]);

  const completeApplePay = (applePaySession, isSuccess) => {
    applePaySession.completePayment({
      status: isSuccess
        ? window.ApplePaySession.STATUS_SUCCESS
        : window.ApplePaySession.STATUS_FAILURE,
    });
  };

  const mutation = useMutation(async (values) => {
    try {
      let result;
      if (
        values.cardNumber &&
        !getPaymentMethodConfig(values.payment_method)?.external
      ) {
        await tokenize.reset();
        const data = await tokenize.mutateAsync({
          cardNumber: values.cardNumber,
          cardExpirationDate: values.cardExpirationDate,
          cardCvx: values.cardCvx,
          cardType: getCreditCardType(values.cardNumber),
        });
        result = await queryFunction({
          ...values,
          token: data.CardId,
          payment_method: getCreditCardType(values.cardNumber),
        });
      } else {
        result = await queryFunction(values);
      }
      if (values.applePaySession)
        completeApplePay(values.applePaySession, true);

      return result;
    } catch (error) {
      if (values.applePaySession)
        completeApplePay(values.applePaySession, false);
      throw error;
    }
  }, options);

  return mutation;
};
