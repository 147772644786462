import React, { Component } from 'react';
import polyglot from '../../../../../utils/polyglot';
import WizardRadioPrice from '../../../../../components/Wizard/WizardRadioPrice';

const GiftCardAmount = ({
  errors,
  isValid,
  touched,
  values,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  currency,
  radioCustomIsSelected,
  handleInputChange,
  nextOnEnter
}) => {
  return (
    <div className="wizard-input">
      <label className="wizard-input-label">
        {polyglot.t('gift_cards.new.amount.label')}
      </label>
      <div className="d-flex flex-wrap wizard-radio-inputs">
        <WizardRadioPrice
          currency={currency}
          value={25}
          name="amount"
          onBlur={handleBlur}
          checked={values.amount === 25 && !values.customAmount}
          tabIndex={101}
          onChange={event => {
            setFieldValue('amount', 25);
            setFieldTouched('amount', true);
            handleInputChange(event);
          }}
          onFocus={event => {
            setFieldValue('amount', 25);
            setFieldTouched('amount', true);
            handleInputChange(event);
            setFieldValue('customAmount', false);
          }}
          onKeyPress={event => nextOnEnter(event)}
        />
        <WizardRadioPrice
          currency={currency}
          value={50}
          name="amount"
          onBlur={handleBlur}
          checked={values.amount === 50 && !values.customAmount}
          tabIndex={102}
          onChange={event => {
            setFieldValue('amount', 50);
            setFieldTouched('amount', true);
            handleInputChange(event);
          }}
          onFocus={event => {
            setFieldValue('amount', 50);
            setFieldTouched('amount', true);
            handleInputChange(event);
            setFieldValue('customAmount', false);
          }}
          onKeyPress={event => nextOnEnter(event)}
        />
        <WizardRadioPrice
          currency={currency}
          value={100}
          name="amount"
          onBlur={handleBlur}
          checked={values.amount === 100 && !values.customAmount}
          tabIndex={103}
          onChange={event => {
            setFieldValue('amount', 100);
            setFieldTouched('amount', true);
            handleInputChange(event);
          }}
          onFocus={event => {
            setFieldValue('amount', 100);
            setFieldTouched('amount', true);
            handleInputChange(event);
            setFieldValue('customAmount', false);
          }}
          onKeyPress={event => nextOnEnter(event)}
        />
        <WizardRadioPrice
          currency={currency}
          type="number"
          name="customAmountInput"
          focus={radioCustomIsSelected()}
          guide={false}
          tabIndex={104}
          errors={errors}
          values={values}
          label={polyglot.t('gift_cards.new.free_amount')}
          onClick={event =>
            event.currentTarget.childNodes[0]
              ? event.currentTarget.childNodes[0].classList.add('is-focused')
              : null
          }
          onBlur={event =>
            event.currentTarget.childNodes[0]
              ? event.currentTarget.childNodes[0].classList.remove('is-focused')
              : null
          }
          onChange={event => {
            handleInputChange(event, amount);
          }}
          onFocus={() => {
            // setFieldValue('amount', '');
            handleInputChange(event, 'amount');
            setFieldValue('customAmount', true);
            setTimeout(() => setFieldError('amount', ''), 0);
          }}
          onKeyUp={event => {
            handleInputChange(event, 'amount');
          }}
          onKeyPress={event => nextOnEnter(event)}
          mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
        />
      </div>
      {errors.amount && values['customAmount'] ? (
        <div className="input-error">
          <div className="input-error-content">
            <i className="icon-exclamation-circle" />
            <p>{errors.amount}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default GiftCardAmount;
