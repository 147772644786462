import React from "react";
import { ThemeProvider } from "styled-components";
import * as themes from "../assets/themes";
import LostTimeSimulator from "../pages/LostTimeSimulator";
import ErrorBoundary from "../utils/ErrorBoundary";

const LostTimeSimulatorApp = (props, rails) => () =>
  (
    <ThemeProvider theme={themes}>
      <ErrorBoundary>
        <LostTimeSimulator {...props} rails={rails} />
      </ErrorBoundary>
    </ThemeProvider>
  );

export default LostTimeSimulatorApp;
