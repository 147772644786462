import { FastField } from "formik";
import { useState } from "react";
import * as yup from "yup";
import { colors } from "../../../assets/themes";
import { Block, Body14, Button, Icon, List, Modal } from "../../../components";
import { BUTTON, LIST } from "../../../components/Styles/variants";
import polyglot from "../../../utils/polyglot";
import {
  FormLargeStepper,
  FormSelectables,
  StyledFormControl,
} from "../common/fields";
import StepTitle from "../common/StepTitle";
import { INFORMATIONS_PATH } from "../constants";
import { FREQUENCY_OPTIONS } from "../defaults";

const APPLIANCE = "appliance";
const WINDOW = "window";
const IRONING = "ironing";

const config = { forceSummaryColumn: true, IsNbHoursReadOnly: true };

export function useCategory4001() {
  const version = 1.1;

  const formatInitialValues = (arr) => {
    if (arr.options?.length > 0) {
      const newArr = { ...arr };
      newArr.options = { option: newArr.options?.map((item) => item.option) };
      return newArr;
    }
    return arr;
  };

  const getNbHours = (values) => {
    let res = 0;
    if (values.options?.option) {
      if (values.options?.option.includes(0)) {
        res += 0.5;
      }
      if (values.options?.option.includes(2)) {
        res += 1;
      }
      if (values.options?.option.includes(3)) {
        res += 1;
      }
    }
    return res + values.nh_specific;
  };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.surface_cleaned"),
      options: [
        { value: 1.0, label: "< 35 m²" },
        { value: 1.5, label: "35 m²" },
        { value: 2.0, label: "45 m²" },
        { value: 2.5, label: "55 m²" },
        { value: 3.0, label: "65 m²" },
        { value: 3.5, label: "75 m²" },
        { value: 4.0, label: "85 m²" },
        { value: 4.5, label: "95 m²" },
        { value: 5.0, label: "105 m²" },
        { value: 5.5, label: "115 m²" },
        { value: 6.0, label: "125 m²" },
        { value: 6.5, label: "135 m²" },
        { value: 7.0, label: "145 m²" },
        { value: 7.5, label: "155 m²" },
        { value: 8.0, label: "165 m²" },
        { value: 8.5, label: "175 m²" },
        { value: 9.0, label: "185 m²" },
        { value: 9.5, label: "195 m²" },
        { value: 10.0, label: "> 195 m²" },
      ],
    },
    "options.option": {
      label: polyglot.t("categories.additionnal_needs"),
      options: [
        {
          value: APPLIANCE,
          label: polyglot.t("categories.appliance_cleaning"),
        },
        { value: WINDOW, label: polyglot.t("categories.window_cleaning") },
        { value: IRONING, label: polyglot.t("categories.ironing") },
      ],
    },
  };

  const maxNhNbHours =
    nhConfig.nh_specific.options[nhConfig.nh_specific.options.length - 1].value;
  const minNhNbHours = nhConfig.nh_specific.options[0].value;

  const initialValues = {
    nh_specific: "",
    nb_hours: 2,
    options: { option: [] },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: polyglot.t("categories.how_many_hours_of_cleaning_need"),
      validationSchema: yup.object().shape({
        nb_hours: yup.number().required(),
      }),
      View: () => {
        const [modalIsOpen, setModalIsOpen] = useState(false);

        const handleClose = () => {
          setModalIsOpen(false);
        };

        return (
          <>
            <StyledFormControl>
              <FormLargeStepper
                divider={false}
                name="nb_hours"
                max={maxNhNbHours}
                min={minNhNbHours}
                step={0.5}
                FieldChildren={(field) =>
                  polyglot.toDuration(field.value, true)
                }
              />
              <Block display="flex" justifyContent="center">
                <Button.Link
                  onClick={() => setModalIsOpen(true)}
                  RightComponent={() => (
                    <Icon.Medium name="info-circle-solid" />
                  )}
                >
                  {polyglot.t("categories.unknow_nb_hours_needed")}
                </Button.Link>
              </Block>
              <Modal.XSmall onClose={handleClose} isOpen={modalIsOpen}>
                <Modal.Item.Header
                  title={polyglot.t("categories.average_nb_hours_per_surface")}
                  onClose={handleClose}
                />
                <FastField name="nh_specific">
                  {({ _, form }) =>
                    nhConfig.nh_specific.options.map(({ label, value }) => (
                      <List.Item
                        key={`nh-list-helper-${value}`}
                        withGutters
                        chevron
                        RightComponent={() => (
                          <Body14 color={colors.muted}>
                            {polyglot.toDuration(value, true)}
                          </Body14>
                        )}
                        size={LIST.SIZE.COMPACT}
                        onClick={() => {
                          form.setFieldValue("nb_hours", value);
                          handleClose();
                        }}
                      >
                        <Body14>{label}</Body14>
                      </List.Item>
                    ))
                  }
                </FastField>
                <Modal.Item.Footer column>
                  <Button.Large block kind={BUTTON.KIND.SECONDARY}>
                    {polyglot.t("common.close")}
                  </Button.Large>
                </Modal.Item.Footer>
              </Modal.XSmall>
            </StyledFormControl>
          </>
        );
      },
    },
    {
      path: "frequency",
      validationSchema: yup.object().shape({
        frequency: yup.string().required(),
      }),
      initialValues: { frequency: "" },
      View: () => (
        <>
          <StepTitle>
            {polyglot.t("categories.what_frequency_needed")}
          </StepTitle>
          <StyledFormControl>
            <FormSelectables
              isWrap
              options={FREQUENCY_OPTIONS}
              name="frequency"
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    getNbHours,
    formatInitialValues,
    config,
    version,
  };
}
