import { useEffect, useState } from "react";
import { colors, sizes, spacings } from "@/assets/themes";
import {
  Block,
  Body14,
  Container,
  Divider,
  H2,
  InfiniteScrollEnhancer,
  NoResult,
  Skeleton,
} from "@/components";
import { SKELETON } from "@/components/Styles/variants";
import { useDocumentTitle } from "@/modules/hooks";
import { useGetJobbersSearch } from "@/modules/routes/jobber-search-routes";
import JobberItem from "@/pages/dashboard/home/Blocks/JobberItem";
import { JOBBER_SEARCH_DISTANCE_MAX, JOBBER_SEARCH_PRICE_MAX } from "@/utils";
import polyglot from "@/utils/polyglot";
import JobbersSeoSearchBar from "../JobbersSeoSearchBar";
import JobberSearchSkeleton from "./JobberSearchSkeleton";
import SearchFilters from "./SearchFilters";

const DEFAULT_VALUES = {
  city_id: null,
  top_jobber: false,
  radius: JOBBER_SEARCH_DISTANCE_MAX,
  price_per_hour: JOBBER_SEARCH_PRICE_MAX,
};

const JobberSearch = () => {
  const [prevData, setPrevData] = useState(null);
  const documentTitle = useDocumentTitle();
  const getQueryParam = (param, defaultValue = null) => {
    const params = new URLSearchParams(window.location.search);
    return params.get(param) || defaultValue;
  };

  const [searchParams, setSearchParams] = useState({
    role: getQueryParam("role"),
    city_id: getQueryParam("city_id", DEFAULT_VALUES.city_id),
    top_jobber: JSON.parse(
      getQueryParam("top_jobber", DEFAULT_VALUES.top_jobber)
    ),
    radius: JSON.parse(getQueryParam("radius", DEFAULT_VALUES.radius)),
    price_per_hour: JSON.parse(
      getQueryParam("price_per_hour", DEFAULT_VALUES.price_per_hour)
    ),
  });

  const formatParams = () => {
    const filteredParams = {};
    Object.keys(searchParams).forEach((key) => {
      const value = searchParams[key];
      if (value !== DEFAULT_VALUES[key] && value !== null && value >= 0) {
        filteredParams[key] = value;
      }
    });
    return filteredParams;
  };

  const jobberSearch = useGetJobbersSearch({
    role: searchParams.role,
    ...formatParams(searchParams),
  });

  const updateUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    Object.keys(searchParams).forEach((key) => {
      if (searchParams[key] && searchParams[key] !== DEFAULT_VALUES[key]) {
        urlParams.set(key, searchParams[key]);
      } else {
        urlParams.delete(key);
      }
    });
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  };

  const handleFilterChange = (values) => {
    const newVal = { ...values };
    if ("city_id" in newVal && newVal.city_id === null) {
      // reset distance filter if remove city
      newVal.radius = JOBBER_SEARCH_DISTANCE_MAX;
    }
    setSearchParams((prevParams) => ({ ...prevParams, ...newVal }));
  };

  const renderTitle = ({ total_count, city }) => {
    const roleString = polyglot.t(`roles.${searchParams.role}`, {
      count: total_count,
    });
    return city
      ? polyglot.t("search.role_at_city", { role: roleString, city })
      : polyglot.t("search.role_at_proximity", { role: roleString });
  };

  const updateDocumentTitle = () => {
    documentTitle.set(
      renderTitle({
        total_count: 2,
        city: jobberSearch.data?.pages[0].data?.city,
      })
    );
  };

  useEffect(() => {
    updateUrlParams();
    jobberSearch.refetch();
  }, [searchParams]);

  useEffect(() => {
    updateDocumentTitle();
  }, [searchParams.role, jobberSearch.data?.pages[0].data?.city]);

  useEffect(() => {
    if (jobberSearch.isSuccess) {
      setPrevData(jobberSearch.data?.pages[0].data);
    }
  }, [jobberSearch.isSuccess]);

  return (
    <Block paddingBottom={spacings.ml}>
      <Container.XLarge>
        <Block display="flex" justifyContent="center" paddingY={spacings.ml}>
          <Block maxWidth="500px" width="100%">
            {prevData ? (
              <JobbersSeoSearchBar
                submitOnChange
                onSubmit={handleFilterChange}
                initialValues={{
                  role: searchParams.role,
                  city_id: searchParams.city_id,
                  city: searchParams.city_id
                    ? `${jobberSearch.data?.pages[0].data?.zip_code} ${jobberSearch.data?.pages[0].data?.city}`
                    : null,
                }}
              />
            ) : (
              <Skeleton.Group>
                <Skeleton
                  height={sizes.size54}
                  width="100%"
                  shape={SKELETON.SHAPE.CIRCLE}
                />
              </Skeleton.Group>
            )}
          </Block>
        </Block>
      </Container.XLarge>
      <Block marginBottom={spacings.ml}>
        <Divider.Cell />
      </Block>
      <Container.XLarge>
        <InfiniteScrollEnhancer query={jobberSearch} unionBy="jobbers">
          {({ isLoading, data }) =>
            prevData ? (
              <>
                {prevData && (
                  <Block marginBottom={spacings.m} spaceY={spacings.s}>
                    <Block>
                      <H2>{renderTitle(data || prevData)}</H2>
                      <Body14 color={colors.muted}>
                        <span className="text-lowercase">{`${
                          (data || prevData).total_count
                        } ${polyglot.t("common.jobbers", {
                          count: (data || prevData).total_count,
                        })}`}</span>
                      </Body14>
                    </Block>
                    <SearchFilters
                      showDistance={searchParams.city_id}
                      onChange={handleFilterChange}
                      initialValues={searchParams}
                    />
                  </Block>
                )}
                {!isLoading ? (
                  <>
                    {data?.jobbers?.length === 0 ? (
                      <NoResult
                        title={polyglot.t("search.no_result_title")}
                        subtitle={polyglot.t("search.no_result_subtitle")}
                      />
                    ) : (
                      <Block
                        display="grid"
                        gap={{
                          xs: spacings.m,
                          sm: spacings.s,
                          md: spacings.m,
                          lg: spacings.ml,
                        }}
                        gridCols={{ xs: 1, sm: 2, md: 3, lg: 4, xl: 5 }}
                      >
                        {data?.jobbers?.map((jobber) => (
                          <JobberItem
                            key={jobber.id}
                            {...jobber}
                            distance={data.city ? jobber.distance : undefined}
                          />
                        ))}
                      </Block>
                    )}
                  </>
                ) : (
                  <JobberSearchSkeleton jobberOnly />
                )}
              </>
            ) : (
              <JobberSearchSkeleton />
            )
          }
        </InfiniteScrollEnhancer>
      </Container.XLarge>
    </Block>
  );
};

export default JobberSearch;
