import React, { useEffect, useState } from "react";
import { colors, spacings } from "../../../../assets/themes";
import { Block, Icon, Input, Link } from "../../../../components";
import { useDebounce } from "../../../../modules/hooks";
import { useCheckJobId } from "../../../../modules/routes/category-forms-routes";

const MIN_TERM_LENGTH = 5;

const JobIdValidationInput = ({
  name = "sav_job_id",
  error,
  onChange,
  onError,
  value = "",
  placeholder = "Veuillez entrer l'ID de la demande",
}) => {
  const [term, setTerm] = useState(value);
  const [selectedJob, setSelectedJob] = useState(null);
  const checkJobId = useCheckJobId();
  const debouncedTerm = useDebounce(term);

  useEffect(() => {
    if (value) {
      checkJobId.mutate({ id: value });
    }
  }, []);

  useEffect(() => {
    if (checkJobId.isSuccess) {
      setSelectedJob(checkJobId.data.data);
      if (onChange) onChange(checkJobId.variables.id);
    }
  }, [checkJobId.isSuccess]);

  useEffect(() => {
    if (checkJobId.isError) {
      onError(checkJobId.error);
    }
  }, [checkJobId.isError]);

  const handleInputChange = (e) => {
    const val = e.target.value;
    if (selectedJob) {
      setSelectedJob(null);
      if (onChange) onChange("");
    }
    setTerm(val);
  };

  useEffect(() => {
    if (debouncedTerm?.length >= MIN_TERM_LENGTH)
      checkJobId.mutate({ id: debouncedTerm });
  }, [debouncedTerm]);

  return (
    <div>
      <Input
        onChange={handleInputChange}
        placeholder={placeholder}
        name={name}
        error={error}
        value={term}
        isLoading={
          checkJobId.isLoading ||
          (term !== debouncedTerm && term?.length >= MIN_TERM_LENGTH)
        }
      />
      {selectedJob && !checkJobId.isLoading && (
        <Block marginTop={spacings.s} display="flex" alignItems="center">
          <Icon.Large
            name="check-circle-solid"
            color={colors.success}
            css={`
              margin-right: ${spacings.s};
            `}
          />
          <Link
            href={selectedJob.url}
            target="_blank"
            css={`
              display: inline-block;
            `}
          >
            {selectedJob.title}, {selectedJob.city} <Icon.Medium name="link" />
          </Link>
        </Block>
      )}
    </div>
  );
};

export default JobIdValidationInput;
