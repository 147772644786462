import styled, { css } from "styled-components";
import { borderWidth, colors, spacings } from "../assets/themes";
import { DIVIDER } from "./Styles/variants";
import { Body14 } from "./Text";

const setSize = ({ size }) => {
  if (size === DIVIDER.SIZE.GROUP) {
    return borderWidth.m;
  }
  if (size === DIVIDER.SIZE.SECTION) {
    return borderWidth.xxl;
  }
  return borderWidth.s;
};

const getColor = ({ size }) => {
  if (size === DIVIDER.SIZE.SECTION) {
    return colors.border;
  }
  return colors.border;
};

const setOrientation = ({ orientation, size }) => {
  if (orientation === DIVIDER.ORIENTATION.HORIZONTAL) {
    return css`
      border-width: 0 0 ${setSize({ size })};
      margin: 0;
    `;
  }
  return css`
    margin: 0;
    border-width: 0 ${setSize({ size })} 0 0;
    height: auto;
    align-self: stretch;
  `;
};

const StyledDivider = styled.hr`
  ${({ orientation, size }) => css`
    ${setOrientation({ orientation, size })};
    flex: 1;
    border-style: solid;
    border-color: ${getColor({ size })};
  `}
`;

const StyledDividerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ orientation }) =>
    orientation === DIVIDER.ORIENTATION.HORIZONTAL ? "column" : "row"};
`;

const Divider = ({
  orientation = DIVIDER.ORIENTATION.HORIZONTAL,
  size,
  children,
  ...rest
}) => (
  <StyledDividerWrapper>
    <StyledDivider size={size} orientation={orientation} {...rest} />
    {children && (
      <>
        <Body14
          color={colors.mutedLight}
          strong
          style={{ marginLeft: spacings.s, marginRight: spacings.s }}
        >
          {children}
        </Body14>
        <StyledDivider size={size} orientation={orientation} {...rest} />
      </>
    )}
  </StyledDividerWrapper>
);

const Cell = ({ ...rest }) => <Divider {...rest} size={DIVIDER.SIZE.CELL} />;
const Section = ({ ...rest }) => (
  <Divider {...rest} size={DIVIDER.SIZE.SECTION} />
);
const Semi = ({ ...rest }) => <Divider {...rest} size={DIVIDER.SIZE.SEMI} />;
const Group = ({ ...rest }) => <Divider {...rest} size={DIVIDER.SIZE.GROUP} />;

export default { Cell, Section, Semi, Group };
