import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import { radius, sizes } from "../assets/themes";
import { BUTTON } from "./Styles/variants";
import { useGoogleAnalytics } from "../modules/hooks";
import { useFetchApplePayData } from "../modules/routes/apple-pay-routes";
import { getApplePayButtonPressEvent } from "../utils/analytics-events";
import polyglot from "../utils/polyglot";

const setHeight = ({ size }) => {
  switch (size) {
    case BUTTON.SIZE.LARGE:
      return sizes.size54;
    case BUTTON.SIZE.MEDIUM:
      return sizes.size48;
    default:
      return sizes.size36;
  }
};

const setShape = ({ shape }) => {
  switch (shape) {
    case BUTTON.SHAPE.CIRCLE:
      return radius.circle;
    case BUTTON.SHAPE.PILL:
      return radius.circle;
    default:
      return radius.m;
  }
};

const setPadding = ({ size }) => {
  switch (size) {
    case BUTTON.SIZE.LARGE:
      return "6px";
    case BUTTON.SIZE.MEDIUM:
      return "4px";
    default:
      return "2px";
  }
};

const StyledApplePayWrapper = styled("div")``;

const REQUEST = {
  countryCode: polyglot.country.toUpperCase(),
  currencyCode: polyglot.currencyLong,
  merchantCapabilities: ["supports3DS"],
  supportedNetworks: ["visa", "masterCard"],
};

const ApplePayButton = ({
  block,
  textType = "pay",
  shape,
  size,
  label = polyglot.t("common.brand"),
  amount,
  lineItems = [],
  disabled,
  onPaymentAuthorized,
}) => {
  const { sendEvent } = useGoogleAnalytics();
  const paymentData = { type: "final", label, amount };
  const fetchData = useFetchApplePayData();

  const buttonRef = useRef(null);

  const handleClick = useCallback(
    async (e) => {
      sendEvent(getApplePayButtonPressEvent());
      e.preventDefault();
      if (!window.ApplePaySession) {
        return;
      }

      const session = new window.ApplePaySession(3, {
        ...REQUEST,
        total: paymentData,
        lineItems,
      });

      session.onvalidatemerchant = async () => {
        fetchData.mutate(null, {
          onSuccess: ({ data }) => {
            session.completeMerchantValidation(data.data);
          },
        });
      };

      session.onpaymentmethodselected = () => {
        const update = {
          newTotal: paymentData,
          newLineItems: lineItems,
        };
        session.completePaymentMethodSelection(update);
      };

      session.onshippingmethodselected = () => {
        const update = {};
        session.completeShippingMethodSelection(update);
      };

      session.onshippingcontactselected = () => {
        const update = {};
        session.completeShippingContactSelection(update);
      };

      session.onpaymentauthorized = async (event) => {
        if (onPaymentAuthorized)
          onPaymentAuthorized({
            token: event.payment.token,
            applePaySession: session,
          });
      };

      session.oncancel = () => {};

      session.begin();
    },
    [paymentData, lineItems]
  );

  useEffect(() => {
    const button = buttonRef.current;
    if (button && !disabled) {
      button.addEventListener("click", handleClick);
      return () => {
        button.removeEventListener("click", handleClick);
      };
    }
    button.removeEventListener("click", handleClick);
    return null;
  }, [handleClick, disabled, buttonRef.current]);

  return (
    <StyledApplePayWrapper
      css={`
        width: ${block ? "100%" : null};
        apple-pay-button {
          display: ${block ? "block" : "inline-block"};
          --apple-pay-button-width: ${block ? "100%" : null};
          --apple-pay-button-height: ${setHeight({ size })};
          --apple-pay-button-border-radius: ${setShape({ shape })}!important;
          // Adjust font size according to our design system button font size //
          --apple-pay-button-padding: ${setPadding({ size })} 0px;
          // END //
          --apple-pay-button-box-sizing: border-box;
        }
      `}
    >
      <apple-pay-button
        ref={buttonRef}
        role="button"
        lang={polyglot.country}
        type={textType}
      />
    </StyledApplePayWrapper>
  );
};

const Small = (props) => <ApplePayButton size={BUTTON.SIZE.SMALL} {...props} />;
const Medium = (props) => (
  <ApplePayButton size={BUTTON.SIZE.MEDIUM} {...props} />
);
const Large = (props) => <ApplePayButton size={BUTTON.SIZE.LARGE} {...props} />;

export default { Small, Medium, Large };
