import Fuse from "fuse.js";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { colors, spacings } from "../../assets/themes";
import { PREFERED_COUNTRIES } from "../../utils";
import Block from "../Block";
import Divider from "../Divider";
import Input from "../Input";
import Select from "../Select";
import { INPUT } from "../Styles/variants";
import { Body16 } from "../Text";
import data from "./Countries";

const sortOptions = (arr, sortables) => {
  const enhancedSortables = [];
  sortables.forEach((preferedCountry) => {
    enhancedSortables.push(arr.find((e) => e?.code === preferedCountry));
  });
  const res = [
    ...enhancedSortables,
    ...arr.filter((e) => !sortables.includes(e?.code)),
  ];
  return res;
};

const CountriesSelect = ({ value: defaultCountry, size, onChange }) => {
  const [term, setTerm] = useState("");
  const searchRef = useRef();
  const defaultOptions = useMemo(
    () =>
      sortOptions(data, PREFERED_COUNTRIES).map((country) => ({
        label: `${country.name}`,
        value: country.code,
        dialCode: country.dialCode,
        emoji: country.emoji,
        RightComponent: () => (
          <Body16 color={colors.muted}>{country.dialCode}</Body16>
        ),
        // perf issues with emojis
        // LeftComponent: () => (
        //   <span role="img" aria-label={country.name}>
        //     {country.emoji}
        //   </span>
        // ),
      })),
    []
  );
  const [options, setOptions] = useState(defaultOptions);

  const fuse = new Fuse(defaultOptions, {
    keys: ["label", "dialCode"],
  });

  const handleKeyDown = () => {
    searchRef?.current?.focus();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleChange = (option) => {
    if (onChange) onChange(option.value);
  };

  const handleClose = () => {
    setTerm("");
    setOptions(defaultOptions);
  };

  const handleSearch = (e) => {
    const { value } = e?.target;
    setTerm(value);
    if (value) {
      const res = fuse.search(value);
      setOptions(res.map((r) => r.item));
    } else {
      setOptions(defaultOptions);
    }
  };

  return (
    <Select
      options={options}
      dropdownWidth={290}
      onChange={handleChange}
      displayValue={(o) => o.emoji}
      onClose={handleClose}
      value={defaultCountry}
      size={size}
      dropdownRender={(menu) => (
        <Block position="relative">
          <Block
            marginTop={`calc(${spacings.xs} * -1)`}
            position="sticky"
            top={`calc(${spacings.xs} * -1)`}
            right={0}
            left={0}
            zIndex="9"
            backgroundColor={colors.background}
          >
            <Block padding={spacings.s}>
              <Input
                type="search"
                value={term}
                size={INPUT.SIZE.SMALL}
                onChange={handleSearch}
                ref={searchRef}
                placeholder={
                  data.find((country) => country.code === defaultCountry).name
                }
              />
            </Block>
            <Divider.Cell />
          </Block>
          {menu}
        </Block>
      )}
      css={`
        width: ${73}px;
        input:first-child {
          font-size: var(--font-size-body18);
        }
      `}
    />
  );
};

export default CountriesSelect;
