import { borderWidth, colors, radius, spacings } from "@/assets/themes";
import { Block, Caption, Divider, H4, Icon } from "@/components";
import { getRate } from "@/components/Rating";
import { DIVIDER } from "@/components/Styles/variants";
import { getMemberSince } from "./utils";
import polyglot from "@/utils/polyglot";

const MainInformations = ({
  rates_count,
  rate,
  member_since,
  is_top_jobber,
}) => (
  <Block
    borderRadius={radius.l}
    border={`solid ${borderWidth.s} ${colors.border}`}
    backgroundColor={colors.backgroundBlur}
    backdropBlur={20}
    paddingY={spacings.sm}
    paddingX={spacings.s}
    display="flex"
  >
    <Block display="flex" flexDirection="column" alignItems="stretch" flex="1">
      <H4 align="center">{rates_count}</H4>
      <Caption strong align="center">
        {polyglot.t("jobber_profile.ratings", { count: rates_count })}
      </Caption>
    </Block>
    <Divider.Cell orientation={DIVIDER.ORIENTATION.VERTICAL} />
    <Block
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      flex="1"
      color={is_top_jobber ? colors.purple : colors.body}
    >
      <Block
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap={spacings.xs}
      >
        <H4 color={is_top_jobber ? colors.purple : colors.body}>
          {getRate(rate)}
        </H4>
        {is_top_jobber ? (
          <Icon.Medium name="love-solid" />
        ) : (
          <Icon.Medium name="star-solid" />
        )}
      </Block>
      <Caption strong align="center">
        {polyglot.t("jobber_profile.max_rate")}
      </Caption>
    </Block>
    <Divider.Cell orientation={DIVIDER.ORIENTATION.VERTICAL} />
    <Block display="flex" flexDirection="column" alignItems="stretch" flex="1">
      <H4 align="center">{getMemberSince(member_since)?.value}</H4>
      <Caption strong align="center">
        {getMemberSince(member_since)?.label}
      </Caption>
    </Block>
  </Block>
);
export default MainInformations;
