const config = {
  withFrequency: true,
  isCustom: true,
  initialTitleIsEmpty: true,
};

export function useCategory7099() {
  const getNbHours = () => 2;

  const nhConfig = {};

  return {
    nhConfig,
    getNbHours,
    config,
  };
}
