import styled from "styled-components";
import { spacings } from "../assets/themes";
import { BREAKPOINTS } from "../utils/constants";

const StyledContainer = styled.div`
  width: 100%;
  max-width: ${({ size }) => `var(--container-${size})`};
  margin-right: auto;
  margin-left: auto;
  padding: 0 ${spacings.xxl};
  position: relative;
  @media screen and (min-width: ${BREAKPOINTS.xs}px) {
    padding: 0 ${spacings.m};
  }
  @media screen and (min-width: ${BREAKPOINTS.sm}px) {
    padding: 0 ${spacings.ml};
  }
  @media screen and (min-width: ${BREAKPOINTS.lg}px) {
    padding: 0 ${spacings.l};
  }
`;

const Medium = (props) => <StyledContainer size="m" {...props} />;
const Small = (props) => <StyledContainer size="s" {...props} />;
const XSmall = (props) => <StyledContainer size="xs" {...props} />;
const Large = (props) => <StyledContainer size="l" {...props} />;
const XLarge = (props) => <StyledContainer size="xl" {...props} />;

export default { XSmall, Small, Medium, Large, XLarge };
