import React from "react";
import { borderWidth, colors, radius, spacings } from "../../assets/themes";
import { Button } from "../../components";
import Block from "../../components/Block";
import { Body16, Body18 } from "../../components/Text";
import polyglot from "@/utils/polyglot";

const ActionJobberHasCanceled = ({ discussable_url, user }) => (
  <Block
    borderRadius={radius.ml}
    padding={spacings.m}
    marginTop={spacings.m}
    border={`solid ${borderWidth.m} ${colors.border}`}
  >
    <Body18
      strong
      css={`
        margin-bottom: ${spacings.xs};
      `}
    >
      {polyglot.t("chat.first_name_has_canceled", {
        first_name: user?.first_name,
      })}
    </Body18>
    <Body16
      css={`
        margin-bottom: ${spacings.sm};
      `}
    >
      {polyglot.t("chat.we_already_refund")}
    </Body16>
    <Block marginTop={spacings.m}>
      <Button.Medium block href={discussable_url}>
        {polyglot.t("chat.book_another_jobber")}
      </Button.Medium>
    </Block>
  </Block>
);

export default ActionJobberHasCanceled;
