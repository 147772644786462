import BaseApp from "../modules/BaseApp";
import SeoZipCodeJobsAvailability from "../pages/SeoZipCodeJobsAvailability";

const SeoZipCodeJobsAvailabilityApp = (props, rails) => () =>
  (
    <BaseApp>
      <SeoZipCodeJobsAvailability />
    </BaseApp>
  );

export default SeoZipCodeJobsAvailabilityApp;
