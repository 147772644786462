import { ADD_BADGE, REMOVE_BADGE } from "../../utils/types";

const IMPORTANT_BADGES = [
  "messages_count",
  "notifications_count",
  "tickets_count",
];

const mustShowBadge = (data) =>
  data &&
  Object.keys(data).filter(
    (key) => data[key] > 0 && IMPORTANT_BADGES.includes(key)
  )?.length > 0;

export const updateFavicon = (data) => {
  const favicons = document.querySelectorAll("[data-attr='favicon']");
  if (data) {
    if (mustShowBadge(data)) {
      favicons.forEach((elem) => {
        elem.href = `${elem.href
          .replace("-badge", "")
          .replace(/(\.[\w\d_-]+)$/i, "-badge$1")}`;
      });
    }
  } else {
    favicons.forEach((elem) => {
      elem.href = `${elem.href.replace("-badge", "")}`;
    });
    document.getElementById("favicon").href = `/favicon.ico`;
  }
};

export default function BadgesReducer(state = {}, action) {
  switch (action.type) {
    case ADD_BADGE:
      updateFavicon(action.payload);
      return { ...action.payload, hasBadge: mustShowBadge(action.payload) };
    case REMOVE_BADGE: {
      let badgeCount = "";
      if (action.payload.n) {
        const newVal = state[action.payload.target] - action.payload.n;
        badgeCount = newVal >= 0 ? newVal : 0;
      } else {
        badgeCount = 0;
      }
      const newArr = { ...state, [action.payload?.target]: badgeCount };
      if (!mustShowBadge(newArr)) updateFavicon();
      return { ...newArr, hasBadge: mustShowBadge(newArr) };
    }
    default:
      break;
  }
  return state;
}
