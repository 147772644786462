export const formatCardNumber = (value) => {
  const number = value?.replace(/\s/g, "");
  if (number) {
    return `•••• ${number.slice(number.length - 4)}`;
  }
  return value;
};
export const formatIBAN = (value = "") => {
  const number = value?.replace(/\s/g, "");
  if (number) {
    return `${number.slice(0, 4)} •••• ${number.slice(number.length - 4)}`;
  }
  return value;
};
