import BaseApp from "@/modules/BaseApp";
import TaxCreditSimulator from "../pages/TaxCreditSimulator";

const TaxCreditSimulatorApp = (props, rails) => () =>
  (
    <BaseApp>
      <TaxCreditSimulator {...props} rails={rails} />
    </BaseApp>
  );

export default TaxCreditSimulatorApp;
