import BaseApp from "../modules/BaseApp";
import TruckVolumeSimulator from "../pages/TruckVolumeSimulator";

const TruckVolumeSimulatorApp = (props, rails) => () =>
  (
    <BaseApp exclude={["ReactQueryDevtools"]}>
      <TruckVolumeSimulator {...props} />
    </BaseApp>
  );

export default TruckVolumeSimulatorApp;
