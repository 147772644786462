import React, { Component, Fragment } from 'react';
import { Form } from 'formik';
import GiftCard from '../../../../components/GiftCard';
import WizardInput from '../../../../components/Wizard/WizardInput';
import polyglot from '../../../../utils/polyglot';

class GiftCardAddFormInputs extends Component {
  componentDidUpdate(prevProps) {
    if (
      prevProps.values.code !== this.props.values.code &&
      this.props.values.code.replace('_', '').length >= 8
    ) {
      this.props.submitCode(this.props.values);
    }
    if (prevProps.redirectUrl !== this.props.redirectUrl && this.props.redirectUrl) {
      this.props.resetForm();
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }}>
        <div className="gift-card-wrapper">
          <GiftCard demoCard="true" className="gift-card-size-medium" />
        </div>
        <div className="gift-card-use-form bg-white border-top pt-3">
          <div className="">
            <Form className="my-3">
              <WizardInput
                name="code"
                customError={this.props.alert.type === 'danger' ? this.props.alert.text : false}
                {...this.props}
                customClass={{
                  label: '',
                  input: 'uppercase digit-input'
                }}
                mask={[
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/,
                  /[0-9a-zA-Z]/
                ]}
                label={polyglot.t('gift_cards.add.label')}
                submitOnValid
                disabled={this.props.submitted}
                placeholder={polyglot.t('gift_cards.add.placeholder')}
              />
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default GiftCardAddFormInputs;
