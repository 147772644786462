import { useState, cloneElement } from "react";
import { useSelector } from "react-redux";
import { useLocalStorage } from "../../modules/hooks";
import { Body16, Button, Checkbox, H3, Modal } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import polyglot from "@/utils/polyglot";

const JobHasBeenDoneEnhancer = ({ children }) => {
  const [checked, setChecked] = useState(false);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [value, setItem] = useLocalStorage("firstTimePaymentModal", false);
  const { current_admin } = useSelector((state) => state.rails);

  const shouldShowModal = !current_admin && value === false;

  const handleClose = () => setModalIsVisible(false);

  const handleSubmit = () => {
    if (checked) {
      setItem(true);
    }
    setModalIsVisible(false);
    if (children.props && children.props.href) {
      window.location.href = children.props.href;
    }
  };

  const handleLinkClick = (e) => {
    if (shouldShowModal) {
      e.preventDefault();
      setModalIsVisible(true);
    }
  };

  return (
    <>
      {cloneElement(children, { onClick: handleLinkClick })}
      {shouldShowModal && (
        <Modal.XSmall isOpen={modalIsVisible} onClose={handleClose}>
          <Modal.Item.Header onClose={handleClose}>
            <Modal.Item.Title as={H3}>
              {polyglot.t("job.job_has_been_done_alert")}
            </Modal.Item.Title>
            <Modal.Item.Subtitle>
              {polyglot.t("job.job_has_been_done_alert_description")}
            </Modal.Item.Subtitle>
          </Modal.Item.Header>
          <Modal.Item.Wrapper>
            <Checkbox
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
            >
              <Body16 strong>{polyglot.t("common.dont_ask_me_again")}</Body16>
            </Checkbox>
          </Modal.Item.Wrapper>
          <Modal.Item.Footer>
            <Button.Medium kind={BUTTON.KIND.MINIMAL} onClick={handleClose}>
              {polyglot.t("common.no")}
            </Button.Medium>
            <Button.Medium onClick={handleSubmit}>
              {polyglot.t("common.yes")}
            </Button.Medium>
          </Modal.Item.Footer>
        </Modal.XSmall>
      )}
    </>
  );
};

export default JobHasBeenDoneEnhancer;
