// import cat20 from 'images/categories/20.svg';
// import cat21 from 'images/categories/21.svg';
// import cat22 from 'images/categories/22.svg';
// import cat23 from 'images/categories/23.svg';
// import cat24 from 'images/categories/24.svg';
// import cat25 from 'images/categories/25.svg';
// import cat26 from 'images/categories/26.svg';
// import cat28 from 'images/categories/28.svg';
// import cat29 from 'images/categories/29.svg';

// const artwork = {
//   20: cat20,
//   21: cat21,
//   22: cat22,
//   23: cat23,
//   24: cat24,
//   25: cat25,
//   26: cat26,
//   28: cat28,
//   29: cat29,
// };

function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    images[item.replace(/\.[^/.]+$/, "").replace("./", "")] = r(item);
  });
  images = {
    ...images,
    10001: images[291],
    10002: images[291],
    10003: images[291],
    10004: images[291],
    10005: images[291],
    10006: images[291],
    10007: images[291],
    10008: images[291],
    10009: images[291],
    10010: images[291],
    10011: images[291],
    10012: images[291],
    10013: images[291],
    10014: images[291],
    10015: images[291],
    10016: images[291],
    10017: images[291],
    10018: images[291],
    10019: images[291],
    10020: images[291],
    10021: images[291],
    10022: images[291],
  };
  return images;
}

const artwork = importAll(
  require.context("images/categories/", false, /\.(png|jpe?g|svg)$/)
);

export { artwork };
