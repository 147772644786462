import React from "react";

const Group = ({ children }) => {
  const validChildren = React.Children.toArray(children).filter(
    React.isValidElement
  );
  return (
    React.Children.map(children, (child, index) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          ...child.props,
          divider: index < validChildren.length - 1,
        });
      }
      return null;
    }) || null
  );
};

export default Group;
