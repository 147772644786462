import { Field } from "formik";
import { List, Radio } from "../../../../components";

const FormRadios = ({ name, options, children, onChange }) => (
  <>
    {options.map(({ value, label, subtitle }) => (
      <Field
        name={name}
        type="radio"
        value={value}
        key={`option-key-${name}-${value}`}
      >
        {({ field, form }) => (
          <List.Item
            divider
            onClick={() => {
              if (onChange) onChange(value, form);
              form.setFieldValue(name, value);
              form.setFieldTouched(name, true);
            }}
            RightComponent={() => (
              <Radio
                {...field}
                css={`
                  pointer-events: none;
                `}
              />
            )}
          >
            <List.Elem.Title strong>{label}</List.Elem.Title>
            <List.Elem.Subtitle>{subtitle}</List.Elem.Subtitle>
            {children}
          </List.Item>
        )}
      </Field>
    ))}
  </>
);

export default FormRadios;
