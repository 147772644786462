import { borderWidth, colors, radius, sizes } from "../../assets/themes";
import { Block, Body16, List } from "../../components";
import { StyledStepper } from "./Styles";

const PropItem = ({
  onChange,
  value,
  label,
  imageSize = sizes.size48,
  strong = true,
  image,
}) => (
  <List.Item
    withGutters
    LeftComponent={() => (
      <Block
        width={imageSize}
        height={imageSize}
        backgroundColor={colors.background}
        border={`solid ${borderWidth.m} ${colors.border}`}
        borderRadius={radius.m}
      >
        {typeof image === "function" ? (
          image()
        ) : (
          <img
            src={image}
            alt={label}
            css={`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
          />
        )}
      </Block>
    )}
    RightComponent={() => (
      <StyledStepper min={0} max={100} onChange={onChange} value={value} />
    )}
  >
    <Body16
      strong={strong}
      numberOfLines={2}
      css={`
        word-break: break-word;
      `}
    >
      {label}
    </Body16>
  </List.Item>
);
export default PropItem;
