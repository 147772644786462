// eslint-disable-next-line import/no-unresolved
import AppIcon from "images/brand/customer-app-icon.svg";
import StoreBadge from "../../../components/StoreBadge";
import polyglot from "../../../utils/polyglot";
import { sizes, spacings } from "../../../assets/themes";
import { Block, Body16, Col, H3, Modal, Row } from "../../../components";

const DownloadAppScan = ({
  isOpen,
  onClose,
  app_store_url,
  google_play_url,
}) => (
  <Modal.XSmall isOpen={isOpen} onClose={onClose}>
    <Modal.Item.Header onClose={onClose} />
    <Modal.Item.Wrapper>
      <Block display="flex" alignItems="center" flexDirection="column">
        <img
          src={AppIcon}
          alt=""
          css={`
            width: ${sizes.size96};
            height: auto;
          `}
        />
        <Block marginBottom={spacings.sm} marginTop={spacings.m}>
          <H3 align="center">{polyglot.t("scanWithAppModal.title")}</H3>
        </Block>
        <Block marginBottom={spacings.m}>
          <Body16 align="center">{polyglot.t("scanWithAppModal.body")}</Body16>
        </Block>
        <Row
          gutter={[spacings.m]}
          css={`
            width: 100%;
          `}
        >
          <Col size={{ xs: 12, sm: 6 }}>
            <StoreBadge appleStore url={app_store_url} height={48} />
          </Col>
          <Col size={{ xs: 12, sm: 6 }}>
            <StoreBadge googlePlay url={google_play_url} height={48} />
          </Col>
        </Row>
      </Block>
    </Modal.Item.Wrapper>
  </Modal.XSmall>
);

export default DownloadAppScan;
