import React, { useState } from "react";
import { MemoryRouter } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import { useListenToTargetClick } from "../modules/hooks";
import TransactionDetailsModal from "../pages/dashboard/account/TransactionDetailsModal";

const ModalEnhancer = ({ selector, id }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  useListenToTargetClick(selector, () => setModalIsOpen(true));
  return (
    <TransactionDetailsModal
      isOpen={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
      pathname={`/account/wallet/${id}/details_offer`}
    />
  );
};

const TransactionDetailsApp = (props, rails) => () =>
  (
    <BaseApp exclude={["AskService"]} {...props}>
      <MemoryRouter>
        <ModalEnhancer selector={props.selector} id={props.id} />
      </MemoryRouter>
    </BaseApp>
  );

export default TransactionDetailsApp;
