import React from "react";
import styled, { css } from "styled-components";
import { radius, spacings } from "../assets/themes";
import Block from "./Block";
import Icon from "./Icon";

const renderIconColor = ({ kind, theme, component }) => {
  switch (kind) {
    case "warning":
      return theme.colors.warning900;
    case "danger":
      return component === "high" ? theme.colors.onColor : theme.colors.danger;
    case "success":
      return component === "high" ? theme.colors.onColor : theme.colors.success;
    default:
      return component === "high" ? theme.colors.onColor : theme.colors.primary;
  }
};

const StyledAlert = styled.div.attrs(({ className }) => {
  className;
})`
  ${({ theme, kind, component }) =>
    css`
      background-color: ${theme.colors[
        `${kind}${component === "high" ? "" : "Light"}`
      ]};
      border-radius: ${radius.ml};
      color: ${theme.colors[
        `${component === "high" && kind !== "warning" ? "onColor" : "body"}`
      ]};
      i {
        color: ${renderIconColor({ kind, theme, component })};
      }
    `}
`;

const getIconKind = (kind) => {
  switch (kind) {
    case "danger":
      return "minus-circle-solid";
    case "warning":
      return "exclamation-circle-solid";
    case "success":
      return "check-circle-solid";
    default:
      return "question-circle-solid";
  }
};

const Main = ({
  children,
  title,
  kind = "primary",
  component = "high",
  renderIcon,
  className,
}) => (
  <StyledAlert component={component} kind={kind} className={`${className}`}>
    <div
      className="flex-fill px-3 py-3 d-flex align-items-center"
      style={{ backgroundColor: "none" }}
    >
      <Block marginRight={spacings.m}>
        {renderIcon ? renderIcon() : <Icon.XLarge name={getIconKind(kind)} />}
      </Block>
      <div>{children || title}</div>
    </div>
  </StyledAlert>
);

const High = (props) => <Main component="high" {...props} />;
const Low = (props) => <Main component="Low" {...props} />;

export default { High, Low };
