import React, { useState } from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Group = ({ children, onChange }) => {
  const [active, setActive] = useState(null);

  const handleClick = (index) => {
    setActive((s) => (s === index ? null : index));
    if (onChange) onChange(index);
  };

  return (
    <StyledWrapper>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child, {
          ...child.props,
          onClick: () => handleClick(index),
          active: active === index,
        })
      )}
    </StyledWrapper>
  );
};

export default Group;
