import { colors, spacings } from "../../assets/themes";
import { Block, Body14, Body16, Button, Icon, List } from "../../components";
import { BUTTON } from "../../components/Styles/variants";
import { SUPPORT_LINES, formatPhone } from "../../utils";
import polyglot from "../../utils/polyglot";
import { useSupportPhoneNumber } from "../../modules/hooks";

const AssistanceBlock = ({ mobile }) => {
  const { phone } = useSupportPhoneNumber(SUPPORT_LINES.BOOKING);
  return (
    <>
      {!mobile ? (
        <Body16 strong>{polyglot.t("booking.assistance_title")}</Body16>
      ) : (
        <List.Header>{polyglot.t("booking.assistance_title")}</List.Header>
      )}
      {!mobile ? (
        <Body14 color={colors.muted}>
          {polyglot.t("common.phone_assistance_schedule_no_weekend")}
        </Body14>
      ) : (
        <Body16>
          {polyglot.t("common.phone_assistance_schedule_no_weekend")}
        </Body16>
      )}
      <Block marginTop={spacings.s}>
        <Button.Small
          shape={BUTTON.SHAPE.PILL}
          kind={BUTTON.KIND.SECONDARY}
          href={`tel:${phone}`}
          LeftComponent={() => <Icon.Medium name="phone" />}
        >
          {formatPhone(phone)}
        </Button.Small>
      </Block>
    </>
  );
};

export default AssistanceBlock;
