import Progress from "@/components/progress";
import { colors, sizes } from "../../../assets/themes";
import {
  Avatar,
  Badge,
  Block,
  ConditionnalWrapper,
  Icon,
  List,
} from "../../../components";
import { BADGE, ICON } from "../../../components/Styles/variants";
import { getReferralMenuClickEvent } from "../../../utils/analytics-events";
import polyglot from "../../../utils/polyglot";
import Account from "../Account";
import Home from "../Home";
import Inboxes from "../Inboxes";
import Jobs from "../Jobs";
import { Content, Full } from "../Layouts";
import Referral from "../Referral";
import Tickets from "../Tickets";
import {
  ACCOUNT_PATH,
  DASHBOARD_PATH,
  HOME_PATH,
  INBOXES_PATH,
  REFERRAL_PATH,
  TICKETS_PATH,
} from "./routes";
import { cssVarToNumber } from "@/components/Styles/Helper";

const getAvatarSizeByIconSize = (size) => {
  if (size === ICON.SIZE.XLARGE) {
    return sizes.size24;
  }
  if (size === ICON.SIZE.LARGE) {
    return sizes.size20;
  }
  if (size === ICON.SIZE.MEDIUM) {
    return sizes.size16;
  }
  if (size === ICON.SIZE.SMALL) {
    return sizes.size12;
  }
  return size;
};

export const GET_DASHBOARD_MAIN_MENU = ({
  data = {},
  iconSize = ICON.SIZE.XLARGE,
}) => [
  {
    path: HOME_PATH,
    title: polyglot.t("routes.home"),
    exact: true,
    LeftComponent: (
      <Badge
        position={BADGE.POSITION.TOP_RIGHT}
        count={data?.notifications_count}
      >
        <Icon.XLarge size={iconSize} name="home" color={colors.primary} />
      </Badge>
    ),
    View: Home,
    Layout: Full,
  },
  {
    path: DASHBOARD_PATH,
    title: polyglot.t("routes.jobs"),
    exact: true,
    LeftComponent: (
      <Icon.XLarge size={iconSize} name="bookmark" color={colors.primary} />
    ),
    View: Jobs,
    RightComponent: !!data?.jobs_count && data?.jobs_count > 0 && (
      <List.Elem.Label>
        {polyglot.t("jobs.n_active_plural", { count: data.jobs_count })}
      </List.Elem.Label>
    ),
    Layout: Content,
  },
  {
    path: INBOXES_PATH,
    title: polyglot.t("routes.conversations"),
    exact: false,
    LeftComponent: (
      <Badge position={BADGE.POSITION.TOP_RIGHT} count={data?.messages_count}>
        <Icon.XLarge size={iconSize} name="chat" color={colors.primary} />
      </Badge>
    ),
    View: Inboxes,
    Layout: Full,
  },
  {
    path: ACCOUNT_PATH,
    title: polyglot.t("routes.account"),
    exact: true,
    LeftComponent: (
      <Icon.XLarge size={iconSize} name="user" color={colors.primary} />
    ),
    RightComponent: !!data?.wallet_amount &&
      !Number.isNaN(data?.wallet_amount) && (
        <List.Elem.Label>
          {polyglot.toSmartCurrency(data?.wallet_amount || 0)}
        </List.Elem.Label>
      ),
    View: Account,
    Layout: Content,
  },
];

export const GET_REFFERAL_MENU_ITEM = ({ iconSize = ICON.SIZE.XLARGE }) => ({
  path: REFERRAL_PATH,
  title: polyglot.t("routes.referral"),
  LeftComponent: (
    <Icon.XLarge size={iconSize} name="user-plus" color={colors.primary} />
  ),
  subtitle: polyglot.t("routes.referral_description"),
  exact: true,
  event: getReferralMenuClickEvent(),
  View: Referral,
  Layout: Content,
});

export const GET_DASHBOARD_ALT_MENU = ({ public_pages = {}, data = {} }) => [
  {
    path: TICKETS_PATH,
    title: polyglot.t("routes.tickets"),
    exact: false,
    RightComponent: <Badge count={data?.tickets_count} />,
    View: Tickets,
    Layout: Full,
  },
  {
    href: public_pages?.faq,
    title: polyglot.t("routes.help_center"),
  },
];
