import React from "react";
import { colors, radius, spacings } from "../../assets/themes";
import { Block, Body14, Body18 } from "../../components";

const PreviewElemJobHead = ({ cover, title, datetime, url }) => (
  <>
    <Block paddingX={spacings.m} paddingBottom={spacings.m}>
      <a
        href={url}
        target="_blank"
        css={`
          display: block;
          text-decoration: none !important;
          color: ${colors.body} !important;
        `}
        rel="noreferrer"
      >
        <Block
          width="100%"
          borderRadius={radius.m}
          maxHeight={120}
          minHeight={80}
          backgroundImage={`url(${cover})`}
          backgroundRepeat="no-repeat"
          backgroundSize="cover"
          backgroundPosition="bottom right"
          display="block"
          backgroundColor={colors.gray100}
          paddingBottom="30%"
          marginY={spacings.m}
        />
        <Block display="flex" justifyContent="space-between">
          <Body18 strong numberOfLines={1}>
            {title}
          </Body18>
        </Block>
        <Body14 color={colors.muted}>{datetime}</Body14>
      </a>
    </Block>
  </>
);

export default PreviewElemJobHead;
