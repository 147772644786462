import { artwork } from "../../assets/categories";
import { spacings } from "../../assets/themes";
import { Button, List, Modal, Shape } from "../../components";
import { SHAPE } from "../../components/Styles/variants";
import { getCategoryFormUrlById } from "../../utils";
import capitalize from "../../utils/capitalize";
import polyglot from "../../utils/polyglot";
import { getUrlParamsForPrivate } from "./utils";

const getCategoriesCustom = (categories) => {
  const allCategories = categories
    .map((path) => {
      const firstLvlCustomCat = path.children?.find((c) => c.isCustom);
      if (firstLvlCustomCat) {
        return {
          label: path.id,
          value: firstLvlCustomCat.id,
          eligible_private: firstLvlCustomCat.eligible_private,
        };
      }
      return path.children.map((subCat) => {
        const customCat = subCat.children?.find((c) => c.isCustom);
        return {
          label: subCat.id,
          value: customCat?.id,
          eligible_private: subCat?.eligible_private,
        };
      });
    })
    .flat()
    .filter((el) => typeof el.value !== "undefined");

  return {
    privateCategories: allCategories.filter((cat) => cat.eligible_private),
    publicCategories: allCategories.filter((cat) => !cat.eligible_private),
  };
};

const CategoryItem = ({ cat, urlParams }) => (
  <List.Item
    withGutters
    key={cat.value}
    LeftComponent={() => (
      <Shape.Small
        shape={SHAPE.SHAPE.ROUND}
        backgroundColor="transparent"
        css={`
          overflow: hidden;
          margin: ${spacings.xs} 0;
        `}
      >
        <img
          src={artwork[cat.label]}
          alt={capitalize(polyglot.t(`categories.${cat.label}.name`))}
          css={`
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: right bottom;
          `}
        />
      </Shape.Small>
    )}
    href={getCategoryFormUrlById(cat.value, urlParams)}
    chevron
  >
    <List.Elem.Title className="font-size-sm-4">
      {capitalize(polyglot.t(`categories.${cat.label}.name`))}
    </List.Elem.Title>
  </List.Item>
);

const SelectCategoryModal = ({
  isOpen,
  onClose,
  urlParams,
  formatedCategories,
  user,
}) => {
  const handleClose = () => {
    if (onClose) onClose();
  };

  const { privateCategories, publicCategories } =
    getCategoriesCustom(formatedCategories);
  return (
    <Modal.Small isOpen={isOpen} onClose={handleClose} fullScreenOnMobile>
      <Modal.Item.Header onClose={handleClose}>
        <Modal.Item.Title>
          {polyglot.t("categories_modal.select_category")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      {privateCategories.length > 0 && (
        <>
          <List.Header withGutters>
            {polyglot.t("categories_modal.first_name_services", {
              first_name: user?.first_name,
            })}
          </List.Header>
          {privateCategories.map((cat) => (
            <CategoryItem
              key={cat.value}
              cat={cat}
              urlParams={getUrlParamsForPrivate(urlParams, {
                category: cat,
                user,
              })}
            />
          ))}
          {publicCategories.length > 0 && (
            <List.Header withGutters>
              {polyglot.t("categories_modal.other_services")}
            </List.Header>
          )}
        </>
      )}
      {publicCategories.length > 0 &&
        publicCategories.map((cat) => (
          <CategoryItem key={cat.value} cat={cat} urlParams={urlParams} />
        ))}
      <Modal.Item.Wrapper>
        <Button.Large kind="secondary" className="mt-3" onClick={handleClose}>
          {polyglot.t("common.cancel")}
        </Button.Large>
      </Modal.Item.Wrapper>
    </Modal.Small>
  );
};

export default SelectCategoryModal;
