import { useFormikContext } from "formik";
import React from "react";
import { spacings } from "../../../assets/themes";
import {
  Block,
  Container,
  DatePicker,
  Flow,
  FormControl,
  List,
  Stepper,
  Toggle,
} from "../../../components";

const ContractDetails = ({ goNext, isLoading, goBack }) => {
  const { isValid, values, setFieldValue, initialValues, handleSubmit } =
    useFormikContext();
  return (
    <Container.Small>
      <FormControl label="Date de début du contrat">
        <DatePicker.Calendar
          name="start_date"
          value={values.start_date}
          format="YYYY/MM/DD"
          onChange={(date) => setFieldValue("start_date", date)}
        />
      </FormControl>
      <List.Header title="Pérode d'essai" />
      <List.Item
        onClick={() => {
          setFieldValue("probation", !values.probation);
          setFieldValue("probation_weeks", initialValues.probation_weeks);
        }}
        RightComponent={() => (
          <Toggle name="probation" readOnly checked={values.probation} />
        )}
      >
        <List.Elem.Title className="font-weight-medium">
          Activer la période d'essai
        </List.Elem.Title>
        <List.Elem.Subtitle>
          Periode de 8 semaines maximum, renouvelable si elle est inférieure à 1
          mois.
        </List.Elem.Subtitle>
      </List.Item>
      {values.probation === true && (
        <List.Item
          RightComponent={() => (
            <Block marginY={spacings.s}>
              <Stepper
                min={1}
                max={initialValues.probation_weeks}
                hint="Semaines"
                name="probation_weeks"
                value={values.probation_weeks}
                onChange={(v) => setFieldValue("probation_weeks", v)}
              />
            </Block>
          )}
        >
          <List.Elem.Title strong>
            Durée en semaine de la periode d’essai
          </List.Elem.Title>
        </List.Item>
      )}
      <Flow.Item.Navigation
        isValid={isValid}
        goBack={goBack}
        goNext={values.new_contract ? handleSubmit : goNext}
        isLoading={isLoading}
        nextLabel={
          values.new_contract ? "Créer le contrat de travail" : undefined
        }
      />
    </Container.Small>
  );
};

export default ContractDetails;
