import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import GiftCardNewForm from '../pages/GiftCard/GiftCardNew/GiftCardNewForm';
import store from '../store';
import ErrorBoundary from '../utils/ErrorBoundary';

const App = (props, rails) => () => (
  <ErrorBoundary>
    <Router>
      <Route
        render={location => (
          <Provider store={store}>
            <Switch>
              <Route
                path="/gift_cards/wizard"
                render={() => <GiftCardNewForm {...props} location={location} />}
              />
            </Switch>
          </Provider>
        )}
      />
    </Router>
  </ErrorBoundary>
);

export default App;
