import polyglot from "../../../../utils/polyglot";
import { SUPPORTED_ERROR_CODES } from "../constants";

export function getTokenisationErrorMessage(code = "") {
  return polyglot.t(
    `mangopay.errors.${
      SUPPORTED_ERROR_CODES.find((c) => c === code) || "default"
    }`
  );
}
