import { useEffect } from "react";
import { withRouter } from "react-router-dom";

function HistoryScrollToTop({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!history?.location?.state?.background) {
        window.scrollTo(0, 0);
        const modalView = document.querySelectorAll("[data-content='modal']");
        if (modalView[modalView.length - 1]) {
          modalView[modalView.length - 1].scrollTo(0, 0);
        }
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(HistoryScrollToTop);
