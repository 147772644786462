import { colors, radius, spacings } from "../../assets/themes";
import Block from "../Block";
import Rating from "../Rating";
import { Body14, Body16 } from "../Text";

const OfferLastRate = ({ body, firstName, createdAt, rate }) => (
  <Block
    backgroundColor={colors.backgroundLight}
    padding={spacings.sm}
    borderRadius={radius.ml}
  >
    <Body16
      css={`
        word-wrap: break-word;
        word-break: break-word;
      `}
    >
      "{body}"
    </Body16>
    <Block display="flex" alignItems="center" marginTop={spacings.xs}>
      <Block marginRight={spacings.xxs}>
        <Rating rate={rate} />
      </Block>

      <Body14
        color={colors.muted}
        css={`
          word-break: break-word;
          white-space: break-spaces;
        `}
      >
        {firstName} • {createdAt}
      </Body14>
    </Block>
  </Block>
);

export default OfferLastRate;
