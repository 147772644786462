import { useFormikContext } from "formik";
import React, { useState } from "react";
import Textarea from "./Textarea";

const AutoSizeTextarea = React.forwardRef(
  (
    {
      placeholder,
      rows: defaultRows = 1,
      maxRows = 5,
      minRows = 1,
      onChange,
      formRef,
      RightComponent,
      children,
      ...rest
    },
    ref
  ) => {
    const [rows, setRows] = useState(defaultRows);
    const { submitForm } = useFormikContext();
    const lineHeight = 32; // ?
    const handleKeyDown = (e) => {
      const keyCode = e.which || e.keyCode;
      if (keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        submitForm();
        setRows(defaultRows);
      }
    };

    const handleChange = (event) => {
      const e = event;
      const previousRows = e.target.rows;
      e.target.rows = minRows;
      const currentRows = Math.floor(e.target.scrollHeight / lineHeight);
      if (currentRows === previousRows) {
        e.target.rows = currentRows;
      }
      if (currentRows >= maxRows) {
        e.target.rows = maxRows;
        e.target.scrollTop = e.target.scrollHeight;
      }
      setRows(currentRows < maxRows ? currentRows : maxRows);
      onChange(event);
    };

    return (
      <Textarea
        rows={rows}
        ref={ref}
        style={{
          lineHeight: `${lineHeight}px`,
        }}
        onChange={handleChange}
        placeholder={placeholder}
        onKeyDown={handleKeyDown}
        RightComponent={RightComponent}
        {...rest}
      >
        {children}
      </Textarea>
    );
  }
);

export default AutoSizeTextarea;
