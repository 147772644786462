import React from "react";
import BaseApp from "../modules/BaseApp";
import LoginOrRegisterDispatch from "../pages/loginOrRegister/LoginOrRegisterDispatch";

const LoginOrRegister = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <LoginOrRegisterDispatch {...props} />
    </BaseApp>
  );

export default LoginOrRegister;
