import { borderWidth, colors, sizes, spacings } from "@/assets/themes";
import Block from "../Block";
import Button from "../Button";
import { BUTTON, MOBILE_HEADER } from "../Styles/variants";
import Icon from "../Icon";
import { Body18 } from "../Text";

const MobileHeader = ({
  RightComponent,
  LeftComponent,
  actionButtons = [],
  onBack,
  kind = MOBILE_HEADER.KIND.DEFAULT,
  title,
}) => (
  <Block
    position="relative"
    minHeight={sizes.size48}
    display="grid"
    gridCols="auto 1fr auto"
    gridRows={sizes.size48}
    alignItems="center"
    backgroundColor={
      kind === MOBILE_HEADER.KIND.FLOATING ? undefined : colors.background
    }
    borderBottom={
      kind === MOBILE_HEADER.KIND.FLOATING
        ? undefined
        : `solid ${borderWidth.s} ${colors.border}`
    }
  >
    <Block
      marginLeft={spacings.m}
      display="flex"
      alignItems="center"
      gap={spacings.s}
    >
      {onBack && (
        <Button.Small
          kind={BUTTON.KIND.MINIMAL}
          shape={BUTTON.SHAPE.CIRCLE}
          onClick={onBack}
        >
          <Icon.Large name="arrow-left" />
        </Button.Small>
      )}
      {LeftComponent && LeftComponent()}
    </Block>
    <Block>{title && <Body18 numberOfLines={1}>{title}</Body18>}</Block>
    <Block
      marginRight={spacings.m}
      display="flex"
      alignItems="center"
      gap={spacings.s}
    >
      {RightComponent ? RightComponent() : null}
      {actionButtons.map((action) => (
        <Button.Small
          key={`mobile-header-action-buttons-${action.icon}`}
          kind={BUTTON.KIND.MINIMAL}
          shape={BUTTON.SHAPE.CIRCLE}
          onClick={action.onClick}
        >
          <Icon.Large name={action.icon} />
        </Button.Small>
      ))}
    </Block>
  </Block>
);

export default MobileHeader;
