import { useFormikContext } from "formik";
import { useCallback } from "react";
import { useCategoryForm } from "../../modules/hooks";
import { DEFAULT_CATEGORY_NB_HOURS } from "../../utils";
import { MAX_NB_HOURS } from "./constants";
import { getFloatingRate } from "./utils";

function useCategoryValue() {
  const {
    config,
    pricing,
    getCategoryNbHours,
    getCategoryPricePerHour,
    getCategoryNbJobbers,
    _propsInitialValues,
  } = useCategoryForm();
  const { values, initialValues } = useFormikContext();

  // add initial nb_hours as default nb_hours (work for custom cat only) (used here for calc if change nb_jobber).
  const categoryNhHours = getCategoryNbHours
    ? getCategoryNbHours(values)
    : DEFAULT_CATEGORY_NB_HOURS;

  const getPricePerHour = useCallback(
    () =>
      getCategoryPricePerHour
        ? getFloatingRate(getCategoryPricePerHour(values), values.date)
        : getFloatingRate(initialValues.price_per_hour, values.date),
    [values.date, categoryNhHours]
  );

  const getRecommendedPricePerHour = useCallback(
    () =>
      getFloatingRate(
        (!!getCategoryPricePerHour && getCategoryPricePerHour(values)) ||
          pricing.recommended,
        values.date
      ),
    [values.date]
  );

  const getNbJobbers = useCallback(
    () => (getCategoryNbJobbers ? getCategoryNbJobbers(values) : 1),
    [categoryNhHours, initialValues.nb_jobbers]
  );

  const getNbHours = useCallback(() => {
    const tmpNbHours = categoryNhHours;
    let nbHours;
    if (_propsInitialValues.nb_hours > 0) return initialValues.nb_hours;
    if (typeof categoryNhHours !== "number") return null;
    nbHours =
      Math.round(categoryNhHours * 2) / 2 < 1
        ? 1
        : Math.round(categoryNhHours * 2) / 2;
    nbHours =
      Math.round(tmpNbHours * 2) / 2 < 1 ? 1 : Math.round(tmpNbHours * 2) / 2;
    if (nbHours >= MAX_NB_HOURS) {
      nbHours = MAX_NB_HOURS;
    }
    return nbHours;
  }, [categoryNhHours, initialValues.nb_hours]);

  return {
    getNbHours,
    getRecommendedPricePerHour,
    getPricePerHour,
    getNbJobbers,
    categoryNhHours,
  };
}

export default useCategoryValue;
