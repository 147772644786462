import { Field, Form, Formik, useFormikContext } from "formik";
import { colors, sizes, spacings } from "@/assets/themes";
import {
  Block,
  Body16,
  Button,
  CardSelectable,
  Carousel,
  Icon,
  Modal,
  Rating,
} from "@/components";
import { useCategoryForm } from "@/modules/hooks";
import polyglot from "@/utils/polyglot";

const JobberSelectableItem = ({
  avatar,
  first_name,
  rates_count,
  rate,
  onClick,
  checked,
  ...rest
}) => (
  <CardSelectable
    {...rest}
    checked={checked}
    role="radio"
    onClick={onClick}
    image={() => (
      <Block
        width="100%"
        height="100%"
        backgroundImage={`url(${avatar})`}
        css={`
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        `}
      />
    )}
  >
    <Body16 strong numberOfLines={1}>
      {first_name}
    </Body16>
    <Rating rate={rate} count={rates_count} type="compact" />
  </CardSelectable>
);

const PrivateJobberModal = ({ isOpen, onClose }) => {
  const { jobbers } = useCategoryForm();
  const { setFieldValue, values } = useFormikContext();
  const name = "private_jobber_id";

  const handleSubmit = (v) => {
    if (v[name]) {
      setFieldValue(name, v[name]);
    } else {
      setFieldValue(name, null);
    }
    onClose();
  };

  return (
    <Modal.Small
      isOpen={isOpen}
      onClose={onClose}
      shouldCloseOnOverlayClick={false}
    >
      <Modal.Item.Header onClose={onClose}>
        <Modal.Item.Title>
          {polyglot.t("categories.would_you_like_to_ask_jobber_again")}
        </Modal.Item.Title>
      </Modal.Item.Header>
      <Formik
        initialValues={{ [name]: values[name] }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form>
          <Carousel
            slideToShow={{ xs: 2.5, md: 3 }}
            gutter={spacings.m}
            paddingX={spacings.m}
            draggable
          >
            <Block width="100%" marginTop={spacings.s}>
              <Field name={name} value={null} type="button" role="radio">
                {({ field, form }) => (
                  <CardSelectable
                    {...field}
                    checked={form.values[name] === null}
                    onClick={() => form.setFieldValue(name, null)}
                    role="radio"
                    image={() => (
                      <Block
                        height="100%"
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        backgroundColor={colors.mutedLight}
                      >
                        <Icon.XLarge
                          name="users-solid"
                          size={sizes.size72}
                          color={colors.onColor}
                        />
                      </Block>
                    )}
                  >
                    <Body16 strong numberOfLines={1}>
                      {polyglot.t("common.no")}
                    </Body16>
                  </CardSelectable>
                )}
              </Field>
            </Block>
            {jobbers.map((jobber) => (
              <Block marginTop={spacings.s} key={`private-jobber-${jobber.id}`}>
                <Field name={name} value={null} type="button" role="radio">
                  {({ field, form }) => (
                    <JobberSelectableItem
                      {...field}
                      onClick={() => form.setFieldValue(name, jobber.id)}
                      checked={form.values[name] === jobber.id}
                      {...jobber}
                      key={`private-jobber-selection-${jobber.id}`}
                    />
                  )}
                </Field>
              </Block>
            ))}
          </Carousel>
          <Block css={{ marginTop: `calc(${spacings.m} * -1)` }}>
            <Modal.Item.Footer column>
              <Button.Large block type="submit">
                {polyglot.t("common.confirm")}
              </Button.Large>
            </Modal.Item.Footer>
          </Block>
        </Form>
      </Formik>
    </Modal.Small>
  );
};

export default PrivateJobberModal;
