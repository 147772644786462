import moment from "moment";
import { isToday, isTomorrow } from "../../utils/moment";
import polyglot from "../../utils/polyglot";
import {
  APARTMENT,
  CATEGORY_DATE_FORMAT,
  HOUSE,
  NO_MATCH_VERSION_ALLOWED_FIELDS,
  STORAGE,
  URGENT_CH_PRICE_PER_HOUR_RATE,
  URGENT_FR_PRICE_PER_HOUR_RATE,
} from "./constants";
// eslint-disable-next-line import/no-cycle
import {
  APARTMENT_PAGES,
  DESTINATION_APARTMENT_PAGES,
  DESTINATION_HOUSE_PAGES,
  DESTINATION_STORAGE_PAGES,
  HOUSE_PAGES,
  STORAGE_PAGES,
} from "./defaults";

export const getFieldExist = (v) =>
  v > 0 || (typeof v === "string" && v.length > 0) || v === false || v === true;

export const getCategoryFormQueryId = ({ id }) => ["CATEGORY_FORM", id];

export const getHourDiff = (start, end) => {
  let res = end - start;
  if (res <= 0) {
    res = 24 + res;
  }
  return res;
};

const getPricePerHourRate = () => {
  if (polyglot.country === "ch") {
    return URGENT_CH_PRICE_PER_HOUR_RATE;
  }
  return URGENT_FR_PRICE_PER_HOUR_RATE;
};

export const getAgeFromDate = (birthDate, format) => {
  const currentDate = moment();
  const tmp = moment(birthDate, format);
  const diff = currentDate.diff(tmp, "years");

  if (diff === 0) {
    const newDiff = currentDate.diff(tmp, "month");

    if (newDiff === 0) {
      return polyglot.t("common.month_old", {
        age: 1,
      });
    }
    return polyglot.t("common.month_old", {
      age: currentDate.diff(tmp, "month"),
    });
  }
  return polyglot.t("common.years_old_plural", {
    count: diff,
  });
};

export const getNbHoursFromDistance = (distance) => {
  if (distance <= 25) {
    return 0.5;
  }
  if (distance <= 50) {
    return 1;
  }
  if (distance <= 100) {
    return 2;
  }
  return Math.round(distance / 100 + 1);
};

export const getFloatingRate = (pricePerHour, date) => {
  if (
    isToday(date, CATEGORY_DATE_FORMAT) ||
    isTomorrow(date, CATEGORY_DATE_FORMAT)
  ) {
    return Math.round(pricePerHour * getPricePerHourRate());
  }
  return pricePerHour;
};

export const getValuesByVersion = (previous_job, category_version) => {
  if (previous_job.version !== category_version) {
    return Object.keys(previous_job)
      .filter((key) => NO_MATCH_VERSION_ALLOWED_FIELDS.includes(key))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = previous_job[key];
        return obj;
      }, {});
  }
  return previous_job;
};

export const moveIndexPosition = (array, initialIndex, finalIndex) => {
  array.splice(finalIndex, 0, array.splice(initialIndex, 1)[0]);
  return array;
};

export const formatRequestValues = (obj, initialValues) => {
  const newObj = { ...obj };
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const key in obj) {
    if (newObj[key] === null) {
      newObj[key] = initialValues[key] || "";
    }
    if (Array.isArray(newObj[key]) && newObj[key].length === 0) {
      // usefull for options:[option] for some form -> prevent formik unable to update field if intialValue options:[]
      newObj[key] = initialValues[key] || [];
    }
  }
  return newObj;
};

export const addObjectAtIndex = (array, obj, index) =>
  array.slice(0, index + 1).concat(obj, array.slice(index + 1));

export const addPrefixToPath = (arr, prefix) =>
  arr.map((a) => {
    // eslint-disable-next-line no-param-reassign
    a.path = `${prefix}${a.path}`;
    // eslint-disable-next-line no-param-reassign
    a.customProps = { name_prefix: prefix };
    return a;
  });

export const getAddressDetailsPageByValue = (value) => {
  if (value === APARTMENT) {
    return APARTMENT_PAGES;
  }
  if (value === HOUSE) {
    return HOUSE_PAGES;
  }
  if (value === STORAGE) {
    return STORAGE_PAGES;
  }
  return [];
};

export const getDestinationAddressDetailsPageByValue = (value) => {
  if (value === APARTMENT) {
    return DESTINATION_APARTMENT_PAGES;
  }
  if (value === HOUSE) {
    return DESTINATION_HOUSE_PAGES;
  }
  if (value === STORAGE) {
    return DESTINATION_STORAGE_PAGES;
  }
  return [];
};
