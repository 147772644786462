import WalletAvailableBannerImg from "images/wallet-available-banner/book_with_wallet.png";
import { colors } from "../../assets/themes";
import { Body14, Body16, H4, H5, List } from "../../components";
import { LIST } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";
import { useBreakpoints } from "../../modules/hooks";

const JobWalletBanner = ({ amount }) => {
  const breakpoints = useBreakpoints();
  return (
    <List.Item
      divider={false}
      shape={LIST.SHAPE.ROUND}
      withGutters
      css={`
        background-color: ${colors.background};
      `}
      LeftComponent={() => (
        <img
          src={WalletAvailableBannerImg}
          css="width:48px; height: auto;"
          alt=""
        />
      )}
    >
      <H5>
        {polyglot.t("job.wallet_amount_available", {
          wallet_amount: polyglot.toSmartCurrency(amount, {
            noDecimal: true,
          }),
        })}
      </H5>
      <Body14 color={colors.muted}>
        {polyglot.t("job.book_with_wallet_description")}
      </Body14>
    </List.Item>
  );
};
export default JobWalletBanner;
