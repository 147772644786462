import React, { useState } from 'react';
import { Provider } from 'react-redux';
import DlAppModal from '../pages/DlAppModal';
import store from '../store';
import ErrorBoundary from '../utils/ErrorBoundary';

const DlApp = (props, rails) => () => (
  <ErrorBoundary>
    <Provider store={store}>
      <DlAppModal {...props} />
    </Provider>
  </ErrorBoundary>
);

export default DlApp;
