import React from "react";
import { MemoryRouter } from "react-router-dom";
import BaseApp from "../modules/BaseApp";
import JobberReview from "../pages/jobberReview/JobberReview";

const JobberReviewApp = (props, rails) => () =>
  (
    <React.StrictMode>
      <BaseApp {...props}>
        <MemoryRouter>
          <JobberReview {...props} rails={rails} />
        </MemoryRouter>
      </BaseApp>
    </React.StrictMode>
  );

export default JobberReviewApp;
