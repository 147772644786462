import { motion } from "framer-motion";
import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { colors, radius, shadows, spacings } from "../assets/themes";
import useToast from "../modules/hooks/useToast";
import Progress from "./progress";
import { ButtonBase } from "./Styles/Base";
import { Body16 } from "./Text";

const TOAST_WIDTH = 290;
const MIN_TOAST_DESTROY_DELAY = 3000;

const AnimatedWrapper = styled(motion.div)`
  position: relative;
  overflow: hidden;
`;

const StyledContentWrapper = styled.div`
  width: ${TOAST_WIDTH}px;
  max-width: 100%;
  margin-right: ${spacings.m};
  margin-top: ${spacings.m};
  border-radius: ${radius.m};
  box-shadow: ${shadows.s};
  background: ${colors.background};
  overflow: hidden;
`;

const StyledContent = styled.div`
  ${({ kind }) => css`
    display: flex;
    align-items: center;
    padding: ${spacings.m};
    background: ${colors[kind]};
    color: ${colors.onColor};
    justify-content: space-between;
  `}
`;

const Toast = ({ children, id, kind }) => {
  const toast = useToast();
  const [value, setValue] = React.useState(100);

  const toastDelay = MIN_TOAST_DESTROY_DELAY + children?.length * 45;

  useEffect(() => {
    const timer = setTimeout(() => {
      toast.remove(id);
    }, toastDelay);
    setTimeout(() => setValue(0), 0);
    return () => {
      clearTimeout(timer);
    };
  }, [id, toast.remove]);

  return (
    <AnimatedWrapper
      initial={{
        scale: 0.7,
        opacity: 0.5,
        height: "auto",
      }}
      transition={{ ease: "easeInOut", duration: 0.1 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      exit={{ scale: 0.7, opacity: 0, height: 0 }}
    >
      <StyledContentWrapper>
        <StyledContent kind={kind}>
          <Body16>{children}</Body16>
          <ButtonBase
            css={`
              background: transparent;
              margin-left: ${spacings.s};
              color: ${colors.onColor};
            `}
            onClick={() => toast.remove(id)}
          >
            <Body16 strong>OK</Body16>
          </ButtonBase>
        </StyledContent>
        <Progress.Bar
          value={value}
          css={`
            opacity: 0.5;
            width: 100%;
            &:before {
              background-color: ${colors[kind]};
              transition-timing-function: linear;
              transition-duration: ${toastDelay}ms;
            }
          `}
        />
      </StyledContentWrapper>
    </AnimatedWrapper>
  );
};

export default Toast;
