import { Field, Form, Formik } from "formik";
import { useState } from "react";
import { colors, spacings } from "../assets/themes";
import {
  Block,
  Body14,
  Button,
  Col,
  H3,
  Icon,
  Input,
  Modal,
  Row,
  Shape,
} from "../components";
import { INPUT, SHAPE } from "../components/Styles/variants";
import { useAskService, useBreakpoints } from "../modules/hooks";
import polyglot from "../utils/polyglot";
import { AddressModal } from "./common";

const SeoZipCodeJobsAvailability = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const { show } = useAskService();
  const breakpoints = useBreakpoints();
  return (
    <div>
      <Formik initialValues={{ address: "" }}>
        {({ values }) => (
          <Form>
            <Row gutter={[spacings.s]}>
              <Col size={{ xs: 12, md: 8 }}>
                <AddressModal
                  isOpen={addressModalIsOpen}
                  onClose={() => setAddressModalIsOpen(false)}
                />
                <Field name="address">
                  {({ field }) => (
                    <Input
                      onClick={() => setAddressModalIsOpen(true)}
                      value={field.value}
                      size={INPUT.SIZE.LARGE}
                      placeholder={polyglot.t("common.search_address")}
                      RightComponent={() => <Icon.Medium name="dropdown" />}
                    />
                  )}
                </Field>
              </Col>
              <Col size={{ xs: 12, md: 4 }}>
                <Button.Large
                  disabled={!values.address}
                  block={breakpoints.get({ xs: true, md: false })}
                  onClick={setModalIsOpen}
                >
                  {polyglot.t("common.letsgo")} !
                </Button.Large>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
      <Modal.Small isOpen={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <Modal.Item.Header onClose={() => setModalIsOpen(false)} />
        <Modal.Item.Wrapper>
          <Block display="flex" alignItems="center" flexDirection="column">
            <Shape.Large
              shape={SHAPE.SHAPE.CIRCLE}
              backgroundColor={colors.success}
              color={colors.onColor}
            >
              <Icon.XLarge name="check" />
            </Shape.Large>
            <Block marginTop={spacings.s}>
              <H3 align="center">
                {polyglot.t("jobber_zip_code.jobbers_available_near_you")}
              </H3>
            </Block>
          </Block>

          <Modal.Item.Footer column>
            <Button.Large block onClick={show}>
              {polyglot.t("jobber_zip_code.precise_your_needs")}
            </Button.Large>
            <Body14 align="center" color={colors.muted}>
              {polyglot.t("common.free_without_engagement")}
            </Body14>
          </Modal.Item.Footer>
        </Modal.Item.Wrapper>
      </Modal.Small>
    </div>
  );
};
export default SeoZipCodeJobsAvailability;
