import { useFormikContext } from "formik";
import { useBreakpoints } from "@/modules/hooks";
import { spacings } from "@/assets/themes";
import { Block, Checkbox, FormControl, H3, H4, Textarea } from "@/components";
import polyglot from "@/utils/polyglot";
import ExtraRatingInput from "./ExtraRatingInput";

const problemsValues = [
  { name: "forces_payment_confirmation", value: false },
  { name: "asks_more_money", value: false },
  { name: "forces_furnitures_payment", value: false },
  { name: "innapropriate_behavior", value: false },
  { name: "not_focused", value: false },
  { name: "uses_drugs", value: false },
  { name: "unskilled", value: false },
  { name: "no_stuff", value: false },
  { name: "sloppy_job_done", value: false },
  { name: "no_cleanup", value: false },
  { name: "other_boolean", value: false },
];

const ExtraRatingProblemPage = ({ title, index }) => {
  const { values, setFieldValue, setValues } = useFormikContext();
  const breakpoints = useBreakpoints();

  return (
    <Block>
      <Block marginBottom={spacings.m}>
        {breakpoints.get({
          xs: <H4>{title}</H4>,
          md: <H3>{title}</H3>,
        })}
      </Block>
      <Block display="flex" flexDirection="column" gap={spacings.sm}>
        <ExtraRatingInput
          onChange={() => {
            const initialValues = problemsValues.reduce((acc, current) => {
              acc[current.name] = current.value;
              return acc;
            }, {});
            setValues((v) => ({
              ...v,
              ...initialValues,
              other_report: "",
              has_problem: false,
            }));
          }}
          checked={values.has_problem === false}
          name="showProblems"
          title={polyglot.t("extra_ratings.problems.no")}
          subtitle={polyglot.t("extra_ratings.problems.no_description")}
        />
        <ExtraRatingInput
          onChange={() => {
            setFieldValue("has_problem", true);
          }}
          checked={values.has_problem === true}
          name="showProblems"
          title={polyglot.t("extra_ratings.problems.yes")}
          subtitle={polyglot.t("extra_ratings.problems.yes_description")}
        />
      </Block>
      <>
        {values.has_problem && (
          <Block marginTop={spacings.ml}>
            <FormControl
              label={polyglot.t("extra_rating.problems.what_was_your_problems")}
            >
              <Block display="flex" flexDirection="column" gap={spacings.sm}>
                {problemsValues.map((field) => (
                  <ExtraRatingInput
                    key={`${field.name}-extra-rate-flow-${index}`}
                    onChange={() => {
                      setFieldValue(field.name, !values[field.name]);
                    }}
                    InputComponent={Checkbox}
                    checked={values[field.name]}
                    name={field.name}
                    title={polyglot.t(`extra_rating.problems.${field.name}`)}
                  />
                ))}
              </Block>
            </FormControl>
            {values.other_boolean && (
              <FormControl
                label={polyglot.t("extra_rating.problems.other_label")}
              >
                <Textarea
                  value={values.other_report}
                  onChange={(e) =>
                    setFieldValue("other_report", e.target.value)
                  }
                />
              </FormControl>
            )}
          </Block>
        )}
      </>
    </Block>
  );
};
export default ExtraRatingProblemPage;
