import React from "react";
import { spacings } from "../../assets/themes";
import { Block, Body18, H3 } from "../../components";

const JSTStep = ({ img, title, text }) => (
  <Block
    display="flex"
    alignItems="center"
    flexDirection="column"
    marginBottom={spacings.ml}
  >
    <img
      src={img}
      alt={title}
      css={`
        width: 100%;
        max-height: 350px;
        height: 30vh;
      `}
    />
    <Block marginX={spacings.l} marginTop={spacings.m}>
      <H3 align="center">{title}</H3>
      <Block marginTop={spacings.s}>
        <Body18 align="center">{text}</Body18>
      </Block>
    </Block>
  </Block>
);

export default JSTStep;
