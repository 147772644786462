import styled, { css } from "styled-components";
import { colors, radius, sizes, spacings } from "../assets/themes";
import { SELECTABLE } from "./Styles/variants";
import { Body16, Body18, Body20 } from "./Text";

const setSizeStyle = ({ size }) => {
  switch (size) {
    case SELECTABLE.SIZE.SMALL:
      return css`
        min-height: ${sizes.size36};
      `;
    case SELECTABLE.SIZE.MEDIUM:
      return css`
        min-height: ${sizes.size48};
      `;
    default:
      return css`
        min-height: ${sizes.size54};
      `;
  }
};

const setRadius = ({ shape }) => {
  switch (shape) {
    case SELECTABLE.SHAPE.CIRCLE:
      return css`
        border-radius: ${radius.circle};
      `;
    default:
      return css`
        border-radius: ${radius.m};
      `;
  }
};

export const StyledSelectable = styled.div`
  ${({ disabled, size, shape }) =>
    css`
      .ks-cboxtags {
        height: 100%;
        width: 100%;
        position: relative;
      }
      .ks-cboxtags .ks-cboxtags-wrapper {
        opacity: ${disabled && "0.5"};
      }
      .ks-cboxtags .ks-cboxtags-wrapper {
        display: inline;
      }
      .ks-cboxtags .ks-cboxtags-wrapper label {
        ${setRadius({ shape })};
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        text-align: center;
        background-color: ${colors.backgroundGray};
        border: solid 2px transparent;
        color: ${colors.body};
        white-space: nowrap;
        user-select: none;
        transition: all 0.2s;
        margin: 0;
        padding: ${spacings.xs} ${spacings.sm};
        cursor: pointer;
        &:hover {
          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: transparent;
          }
          &:active {
            position: relative;
          }
        }
        i {
          color: var(--color-body);
        }
        ${setSizeStyle({ size })}
      }
      .ks-cboxtags .ks-cboxtags-wrapper input:checked + label {
        border: 2px solid ${colors.primary};
        background-color: ${colors.primaryLight};
        color: ${colors.primary};
        i {
          color: ${colors.primary};
        }
      }
      .ks-cboxtags .ks-cboxtags-wrapper input {
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 100%;
        cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
      }
    `}
  ${({ disabled }) =>
    !disabled &&
    css`
      .ks-cboxtags .ks-cboxtags-wrapper label {
        position: relative;
        &:hover {
          &:after {
            background-color: rgba(0, 0, 0, 0.05);
          }
          &:active {
            &:after {
              background-color: rgba(0, 0, 0, 0.08);
            }
          }
        }
      }
    `}
`;

const Selectable = ({
  checked = false,
  id = `selectable${Math.random()}`,
  title,
  icon,
  value,
  disabled = false,
  name = "selectables",
  type = "checkbox",
  LeftComponent,
  RightComponent,
  onChange = () => {},
  children,
  className,
  shape = SELECTABLE.SHAPE.DEFAULT,
  size = SELECTABLE.SIZE.MEDIUM,
  ...rest
}) => (
  <StyledSelectable
    disabled={disabled}
    size={size}
    className={className}
    shape={shape}
  >
    <div className="ks-cboxtags">
      <div className="ks-cboxtags-wrapper">
        <input
          name={name}
          id={id}
          type={type}
          checked={checked}
          autoComplete="off"
          onChange={(e) => !disabled && onChange(e)}
          {...rest}
        />
        {title && (
          <label htmlFor={id} aria-checked={checked}>
            {icon && <i className={`${icon} mr-1`} />}
            {typeof title === "string" ? title : title()}
          </label>
        )}
        {children && !title && (
          <label htmlFor={id} aria-checked={checked}>
            {LeftComponent && (
              <div
                css={`
                  margin-right: ${spacings.xs};
                `}
              >
                {LeftComponent()}
              </div>
            )}
            {children}
            {RightComponent && (
              <div
                css={`
                  margin-left: ${spacings.xs};
                `}
              >
                {RightComponent()}
              </div>
            )}
          </label>
        )}
      </div>
    </div>
  </StyledSelectable>
);

const Small = ({ children, ...rest }) => (
  <Selectable {...rest} size={SELECTABLE.SIZE.SMALL}>
    <Body16 strong>{children}</Body16>
  </Selectable>
);
const Medium = ({ children, ...rest }) => (
  <Selectable {...rest} size={SELECTABLE.SIZE.MEDIUM}>
    <Body18 strong>{children}</Body18>
  </Selectable>
);
const Large = ({ children, ...rest }) => (
  <Selectable {...rest} size={SELECTABLE.SIZE.LARGE}>
    <Body20 strong>{children}</Body20>
  </Selectable>
);

export default { Large, Medium, Small };
