import { useState } from "react";
import * as yup from "yup";
import { spacings } from "../../../assets/themes";
import { Block, Button, Divider, Icon } from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import {
  INSTANT_GO_NEXT_DELAY,
  setDestinationAddressInitialValues,
} from "../../../utils";
import polyglot from "../../../utils/polyglot";
import {
  FormCardSelectables,
  FormLargeStepper,
  FormSelectables,
  StyledFormControl,
} from "../common/fields";
import StepTitle from "../common/StepTitle";
import TruckSurfaceCalulatorModal from "../common/TruckSurfaceCalulatorModal";
import {
  DESTINATION_PROPERTY_TYPE_PATH,
  INFORMATIONS_PATH,
  MAX_NB_HOURS,
  PROPERTY_TYPE_PATH,
  TRUCKS,
} from "../constants";

const config = {
  addressDetails: true,
  IsNbHoursReadOnly: true,
  renderPages: (pages) => {
    const newPages = [...pages];
    const startPropertyTypeIndex = pages.findIndex(
      (p) => p.path === PROPERTY_TYPE_PATH
    );
    const destinationPropertyTypeIndex = pages.findIndex(
      (p) => p.path === DESTINATION_PROPERTY_TYPE_PATH
    );

    newPages[startPropertyTypeIndex].title = polyglot.t(
      "categories.what_is_job_start_address"
    );
    newPages[destinationPropertyTypeIndex].title = polyglot.t(
      "categories.what_is_job_destination_address"
    );
    return newPages;
  },
};

const validationSchema = yup.object().shape({
  vehicles: yup.string().required(),
});

export function useCategory3020() {
  const initialValues = {
    vehicles: null,
    nh_specific: null,
    ...setDestinationAddressInitialValues(),
  };

  const getNbHours = () => 2;

  const nhConfig = {
    vehicles: {
      label: polyglot.t("categories.what_truck_needed"),
      options: TRUCKS,
    },
    nh_specific: {
      label: polyglot.t("categories.driver_help_load_and_unload"),
      options: [
        { value: 0, label: polyglot.t("common.no") },
        { value: 1, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: INFORMATIONS_PATH,
      title: nhConfig.vehicles.label,
      validationSchema,
      View: ({ goNext }) => {
        const [modalIsOpen, setModalIsOpen] = useState(false);

        return (
          <>
            <StyledFormControl>
              <FormCardSelectables
                onChange={() =>
                  setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)
                }
                options={nhConfig.vehicles.options}
                name="vehicles"
              />
              <Block marginY={spacings.ml}>
                <Divider.Cell>{polyglot.t("common.or")}</Divider.Cell>
              </Block>
              <Button.Medium
                kind={BUTTON.KIND.SECONDARY}
                block
                onClick={() => setModalIsOpen(true)}
                LeftComponent={() => <Icon.Large name="surface-small" />}
              >
                {polyglot.t("categories.calculate_ideal_truck_size")}
              </Button.Medium>
              <TruckSurfaceCalulatorModal
                isOpen={modalIsOpen}
                onClose={() => setModalIsOpen(false)}
              />
            </StyledFormControl>
          </>
        );
      },
    },
    {
      path: "driver_help",
      validationSchema: yup.object().shape({
        nh_specific: yup.number().required(),
      }),
      View: ({ goNext }) => (
        <>
          <StepTitle>
            {polyglot.t("categories.driver_help_load_and_unload")}
          </StepTitle>
          <StyledFormControl>
            <FormSelectables
              onChange={() => setTimeout(() => goNext(), INSTANT_GO_NEXT_DELAY)}
              options={nhConfig.nh_specific.options}
              name="nh_specific"
            />
          </StyledFormControl>
        </>
      ),
    },
    {
      path: "rent_nb_hours",
      validationSchema: yup.object().shape({
        nb_hours: yup.number().required(),
      }),
      View: () => (
        <>
          <StepTitle>
            {polyglot.t("categories.how_long_rent_truck_with_driver")}
          </StepTitle>
          <StyledFormControl>
            <FormLargeStepper
              divider={false}
              name="nb_hours"
              max={MAX_NB_HOURS}
              min={1}
              step={0.5}
              FieldChildren={(field) => polyglot.toDuration(field.value, true)}
            />
          </StyledFormControl>
        </>
      ),
    },
  ];

  return {
    pages,
    initialValues,
    nhConfig,
    config,
    getNbHours,
  };
}
