import { useFormikContext } from "formik";
import { useEffect } from "react";
import * as yup from "yup";
import { useCategoryForm } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import StepTitle from "../common/StepTitle";
import { FormSelectables, StyledFormControl } from "../common/fields";
import { NB_HOURS_PATH } from "../constants";
import { NB_HOURS_SELECTION_PAGE } from "../defaults";

export function useCategory2028() {
  const DEFAULT_NB_HOURS_OPTIONS = [1.5, 3, 4.5];
  const TILLER_NB_HOURS_OPTIONS = [1, 2, 3];

  const initialValues = { nb_hours: DEFAULT_NB_HOURS_OPTIONS[1] };

  const nhConfig = {
    nh_specific: {
      label: polyglot.t("categories.did_you_have_tiller"),
      options: [
        { value: 0, label: polyglot.t("common.no") },
        { value: 1, label: polyglot.t("common.yes") },
      ],
    },
  };

  const pages = [
    {
      path: "tiller",
      validationSchema: yup.object().shape({
        nh_specific: yup.number().required(),
      }),
      initialValues: { nh_specific: "" },
      View: () => {
        const { setFieldValue } = useFormikContext();
        const { pages, setPages, initialValues } = useCategoryForm();

        const getNbHoursValue = (value) => {
          let options;
          if (value === 1) {
            setFieldValue("nb_hours", TILLER_NB_HOURS_OPTIONS[1]);
            options = TILLER_NB_HOURS_OPTIONS;
          } else {
            setFieldValue("nb_hours", DEFAULT_NB_HOURS_OPTIONS[1]);
            options = DEFAULT_NB_HOURS_OPTIONS;
          }
          return [
            {
              label: polyglot.t("categories.small_surface"),
              value: options[0],
              subtitle: polyglot.t("categories.small_plow_surface_exemple"),
            },
            {
              label: polyglot.t("categories.medium_surface"),
              value: options[1],
              subtitle: polyglot.t("categories.medium_plow_surface_exemple"),
              popular: true,
            },
            {
              label: polyglot.t("categories.large_surface"),
              value: options[2],
              subtitle: polyglot.t("categories.large_plow_surface_exemple"),
            },
          ];
        };

        const updateNbHoursPageOptions = (value) => {
          const newPages = [...pages];
          const nbHoursIndex = pages.findIndex((p) => p.path === NB_HOURS_PATH);
          newPages[nbHoursIndex].customProps = {
            ...newPages[nbHoursIndex].customProps,
            options: getNbHoursValue(value),
          };
          setPages(newPages);
        };

        useEffect(() => {
          if (initialValues.nh_specific?.toString()) {
            updateNbHoursPageOptions(initialValues.nh_specific);
          }
        }, [initialValues.nb_hours]);

        return (
          <>
            <StepTitle>{nhConfig.nh_specific.label}</StepTitle>
            <StyledFormControl>
              <FormSelectables
                options={nhConfig.nh_specific.options}
                name="nh_specific"
                onChange={updateNbHoursPageOptions}
              />
            </StyledFormControl>
          </>
        );
      },
    },
    NB_HOURS_SELECTION_PAGE,
  ];
  return {
    pages,
    version: 1.1,
    initialValues,
    config: {
      IsNbHoursReadOnly: true,
    },
  };
}
