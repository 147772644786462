import React, { useState } from "react";
import { useCookies } from "react-cookie";
import moment from "moment";
import AppIcon from "images/brand/customer-app-icon.svg";
import BottomModal from "../components/BottomModal";
import polyglot from "../utils/polyglot";
import { sizes } from "../assets/themes";

const DlAppModal = ({
  auto_open,
  app_store_url,
  google_play_url,
  is_ios,
  is_mobile,
}) => {
  const [cookies, setCookies] = useCookies(["dlAppModal"]);
  const [modalIsOpen, setModalIsOpen] = useState(cookies.hasPop !== "true");
  const redirectUrl = is_ios ? app_store_url : google_play_url;

  const updateCookies = () => {
    setCookies("hasPop", true, {
      expires: moment().add(1, "M").toDate(),
      SameSite: "Lax",
    });
    setModalIsOpen(false);
  };
  const handleRedirection = () => {
    updateCookies();
    window.open(redirectUrl);
  };

  return (
    <BottomModal
      render={() => (
        <div className="d-flex flex-column align-items-center">
          <img
            src={AppIcon}
            alt={polyglot.t("downloadAppModal.IconAlt")}
            css={`
              width: ${sizes.size64};
              height: ${sizes.size64};
              object-fit: cover;
            `}
            className="mb-3"
          />
          <span className="h5 text-center mb-2">
            {polyglot.t("downloadAppModal.title")}
          </span>
          <p className="mb-3 text-center">
            {polyglot.t("downloadAppModal.body")}
          </p>
          <button
            type="button"
            className="btn btn-lg btn-primary btn-block"
            onClick={handleRedirection}
          >
            {polyglot.t("downloadAppModal.cta")}
          </button>
        </div>
      )}
      isOpen={is_mobile && modalIsOpen ? auto_open : false}
      hasCloseButton
      shouldCloseOnOverlayClick={false}
      onClose={updateCookies}
    />
  );
};

export default DlAppModal;
