import {
  borderWidth,
  colors,
  radius,
  shadows,
  sizes,
  spacings,
} from "@/assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Col,
  ConditionnalWrapper,
  H5,
  Link,
  Row,
  Tag,
} from "@/components";
import { TAG } from "@/components/Styles/variants";
import { useBreakpoints } from "@/modules/hooks";
import UserPreview from "@/pages-admin/UserPreview/UserPreview";
import { JOB } from "@/pages-admin/UserPreview/config";
import polyglot from "@/utils/polyglot";

const JobsItem = ({
  id,
  RightComponent,
  title,
  subtitle,
  artwork,
  children,
  private_jobber,
  href,
  disableAdminPreview,
}) => {
  const breakpoints = useBreakpoints();
  return (
    <Block
      borderRadius={radius.ml}
      boxShadow={shadows.xs}
      backgroundColor={colors.background}
      border={`solid ${borderWidth.s} ${colors.border}`}
      padding={{ xs: spacings.m, md: spacings.l }}
      marginBottom={spacings.l}
    >
      <Row gutter={spacings.m}>
        <Col size={{ xs: 12, xl: 6 }}>
          <ConditionnalWrapper
            condition={!disableAdminPreview}
            wrapper={(children) => (
              <UserPreview id={id} kind={JOB}>
                {children}
              </UserPreview>
            )}
          >
            <Link href={href}>
              <Block
                width="100%"
                borderRadius={radius.m}
                maxHeight={180}
                minHeight={130}
                backgroundImage={`url(${artwork})`}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundPosition="bottom right"
                display="block"
                backgroundColor={colors.gray100}
                paddingBottom="40%"
                marginBottom={spacings.m}
              />
            </Link>
            <Block display="flex" alignItems="flex-start">
              <a
                href={href}
                css={`
                  display: block;
                  flex-grow: 1;
                  text-decoration: none !important;
                  color: ${colors.body} !important;
                `}
              >
                <Block display="flex" justifyContent="space-between">
                  <H5 numberOfLines={1}>{title}</H5>
                </Block>
                <Body14 color={colors.muted}>{subtitle}</Body14>
              </a>
              {private_jobber && (
                <>
                  {breakpoints.get({
                    xs: (
                      <Tag.Small
                        kind={TAG.KIND.WHITE}
                        css={`
                          margin-left: ${spacings.s};
                          padding-left: ${spacings.xs};
                          padding-right: ${spacings.s};
                        `}
                        LeftComponent={() => (
                          <Avatar
                            size={sizes.size16}
                            src={private_jobber?.avatar}
                            name={private_jobber?.first_name}
                          />
                        )}
                      >
                        {polyglot.t("common.private")}
                      </Tag.Small>
                    ),
                    md: (
                      <Tag.Medium
                        kind={TAG.KIND.WHITE}
                        css={`
                          margin-left: ${spacings.s};
                          padding-left: ${spacings.xs};
                          padding-right: ${spacings.s};
                        `}
                        LeftComponent={() => (
                          <Avatar
                            size={sizes.size24}
                            src={private_jobber?.avatar}
                            name={private_jobber?.first_name}
                          />
                        )}
                      >
                        {polyglot.t("common.private")}
                      </Tag.Medium>
                    ),
                  })}
                </>
              )}
            </Block>
          </ConditionnalWrapper>

          {breakpoints.get({ xs: true, xl: false }) && (
            <Block marginTop={spacings.m}>{RightComponent()}</Block>
          )}
          {children && (
            <Block marginTop={{ xs: 0, xl: spacings.m }}>{children}</Block>
          )}
        </Col>
        {RightComponent && (
          <Col size={{ xs: 12, xl: 6 }} display={{ xs: "none", xl: "block" }}>
            {breakpoints.get({ xs: false, xl: true }) && RightComponent()}
          </Col>
        )}
      </Row>
    </Block>
  );
};

export default JobsItem;
