import React, { useEffect, useRef } from "react";
import { colors, sizes, spacings } from "../../../assets/themes";
import {
  Avatar,
  Block,
  Body14,
  Body16,
  Icon,
  List,
  Shape,
} from "../../../components";
import { LIST, SHAPE } from "../../../components/Styles/variants";
import { useWindowSize } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import scrollToBottom from "../../../utils/scroll-to-bottom";
import TicketMessage from "./TicketMessage";

const areEqual = (p, n) => p.avatar === n.avatar && p.name === n.name;
const AgentItem = React.memo(
  ({ avatar, name }) => (
    <List.Item
      shape={LIST.SHAPE.ROUND}
      style={{ backgroundColor: colors.primary50 }}
      divider={false}
      withGutters
      LeftComponent={() => (
        <Avatar src={avatar} size={sizes.size48} name={name} />
      )}
    >
      <Body16>
        {polyglot.t("tickets.agent_ticket", { first_name: name })}
      </Body16>
      {/* <Body14 color={colors.muted}>
        {polyglot.t("tickets.response_delay")}
      </Body14> */}
    </List.Item>
  ),
  areEqual
);

const TicketMessages = ({ comments, agent }) => {
  const scrollChildRef = useRef();
  const { height: windowHeight } = useWindowSize();

  useEffect(() => {
    scrollToBottom(scrollChildRef);
  }, []);

  useEffect(() => {
    scrollToBottom(scrollChildRef);
  }, [comments.length, windowHeight]);

  return (
    <Block overflowY="auto" paddingTop={spacings.m} flex="1">
      <div ref={scrollChildRef}>
        <Block paddingX={spacings.m}>
          {agent ? (
            <AgentItem avatar={agent.avatar} name={agent.name} />
          ) : (
            <List.Item
              shape={LIST.SHAPE.ROUND}
              style={{ backgroundColor: colors.primary50 }}
              divider={false}
              withGutters
              LeftComponent={() => (
                <Shape.Medium
                  shape={SHAPE.SHAPE.CIRCLE}
                  color={colors.primary}
                  backgroundColor={colors.primary50}
                >
                  <Icon.XLarge name="support-solid" />
                </Shape.Medium>
              )}
            >
              <Body16>{polyglot.t("tickets.agent_soon")}</Body16>
              {/* <Body14 color={colors.muted}>
                {polyglot.t("tickets.response_delay")}
              </Body14> */}
            </List.Item>
          )}
        </Block>
        {comments.length > 0 && (
          <Block flexDirection="column-reverse" display="flex">
            {comments.map((comment, i) => (
              <TicketMessage
                {...comment}
                isLast={i === 0}
                key={`comment-ticket-${comment.id}`}
              />
            ))}
          </Block>
        )}
      </div>
    </Block>
  );
};

export default TicketMessages;
