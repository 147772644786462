import BaseApp from "../modules/BaseApp";
import AdministratorsFilters from "../pages-admin/AdministratorsFilters";

const AdministratorsFiltersApp = (props, rails) => () =>
  (
    <BaseApp exclude={["ReactQueryDevtools"]}>
      <AdministratorsFilters {...props} />
    </BaseApp>
  );

export default AdministratorsFiltersApp;
