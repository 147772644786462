import React from "react";
import { ThemeProvider } from "styled-components";
import CategoriesNavigationModal from "../pages/CategoriesNavigationModal";
import * as themes from "../assets/themes";
import ErrorBoundary from "../utils/ErrorBoundary";

const CategoriesNavigationModalApp = (props, rails) => () =>
  (
    <ThemeProvider theme={themes}>
      <ErrorBoundary>
        <CategoriesNavigationModal {...props} rails={rails} />
      </ErrorBoundary>
    </ThemeProvider>
  );

export default CategoriesNavigationModalApp;
