const config = { isCustom: true, initialTitleIsEmpty: true };

export function useCategory6099() {
  const nhConfig = {};

  return {
    nhConfig,
    config,
  };
}
