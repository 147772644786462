import React from 'react';
import { colors } from '../../../assets/themes';

const Label = ({ children, className }) => (
  <p
    style={{ color: colors.muted }}
    className={`mb-0 d-flex align-items-center justify-content-end text-right ${className}`}>
    {children}
  </p>
);

export default Label;
