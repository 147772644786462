import { Field, Form, Formik } from "formik";
import { spacings } from "../../../assets/themes";
import {
  Block,
  Body16,
  Body18,
  Breadcrumb,
  Button,
  FormControl,
  H1,
  Link,
  Select,
  Spinner,
  Textarea,
} from "../../../components";
import { BUTTON } from "../../../components/Styles/variants";
import {
  useDeleteAccount,
  useGetDeleteAccount,
} from "../../../modules/routes/dashboard-routes";
import { DASHBOARD_SMALL_CONTAINER } from "../../../utils";
import polyglot from "../../../utils/polyglot";
import { ACCOUNT_INFORMATIONS_PATH, ACCOUNT_PATH } from "../config/routes";

const getReasonsOptions = (reasons) =>
  reasons.map((r) => ({
    label: r.key,
    value: r.value,
    comment: r.comment,
  }));

const DeleteAccount = () => {
  const { data, isLoading } = useGetDeleteAccount();
  const deleteAccount = useDeleteAccount();

  const handleSubmit = (values) => {
    deleteAccount.mutate({
      ...values,
      delete_account_reason: values.delete_account_reason.value,
    });
  };

  return (
    <Block maxWidth={DASHBOARD_SMALL_CONTAINER} width="100%">
      <Block marginBottom={spacings.ml}>
        <Breadcrumb>
          <Link to={ACCOUNT_PATH}>{polyglot.t("routes.account")}</Link>
          <Link to={ACCOUNT_INFORMATIONS_PATH}>
            {polyglot.t("routes.personal_informations")}
          </Link>
          <Block as="span">{polyglot.t("routes.delete_account")}</Block>
        </Breadcrumb>
      </Block>
      <H1>{polyglot.t("routes.delete_account")}</H1>
      {!isLoading ? (
        <>
          <Block marginTop={spacings.m} marginBottom={spacings.s}>
            <Body18 strong>
              {polyglot.t("delete_account.title", {
                first_name: data.first_name,
              })}
            </Body18>
          </Block>
          <Body16>{polyglot.t("delete_account.body")}</Body16>

          <Formik
            initialValues={{
              delete_account_reason: {},
              delete_account_comment: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, submitForm }) => (
              <Form>
                <FormControl
                  label={polyglot.t("common.reason")}
                  hint={polyglot.t("delete_account.hint")}
                >
                  <Select
                    name="delete_account_reason"
                    onChange={(v) => setFieldValue("delete_account_reason", v)}
                    value={values.delete_account_reason?.value}
                    options={getReasonsOptions(data.reasons)}
                    placeholder={polyglot.t("common.placeholder.reason")}
                  />
                </FormControl>
                {values.delete_account_reason?.comment && (
                  <FormControl label={polyglot.t("common.comment")}>
                    <Field name="delete_account_comment">
                      {({ field }) => (
                        <Textarea
                          placeholder={polyglot.t(
                            "common.placeholder.textarea"
                          )}
                          {...field}
                        />
                      )}
                    </Field>
                  </FormControl>
                )}
                <Button.Large
                  accentColor={BUTTON.ACCENT_COLOR.DANGER}
                  block
                  disabled={
                    (values.delete_account_reason?.comment &&
                      !values.delete_account_comment) ||
                    !values.delete_account_reason?.value
                  }
                  isLoading={
                    deleteAccount
                      ? deleteAccount.isLoading || deleteAccount.isSuccess
                      : false
                  }
                  onClick={() => submitForm()}
                >
                  {polyglot.t("routes.delete_account")}
                </Button.Large>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <Block margin={spacings.xl} display="flex" justifyContent="center">
          <Spinner.Medium />
        </Block>
      )}
    </Block>
  );
};

export default DeleteAccount;
