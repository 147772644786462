import { AnimatePresence, motion } from "framer-motion";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { colors, radius, sizes, spacings } from "../assets/themes";
import {
  Block,
  Body14,
  Body16,
  CardSelectable,
  FormControl,
  H4,
  H5,
  Slider,
} from "../components";
import { useBreakpoints } from "../modules/hooks";
import {
  FURNITURE_QUANTITIES,
  getTruckByValue,
  SURFACE_OPTIONS,
} from "./categoryForms/constants";
import polyglot from "@/utils/polyglot";

const StyledCardSelectable = styled(CardSelectable)`
  flex: 1;
  margin-right: ${spacings.m};
  &:last-of-type {
    margin-right: 0;
  }
`;

const TruckVolumeSimulator = () => {
  const breakpoints = useBreakpoints();
  const [surface, setSurface] = useState(60);
  const [furnitureQuantities, setFurnitureQuantities] = useState(
    FURNITURE_QUANTITIES[1].nbHoursAccumulator
  );
  const min = SURFACE_OPTIONS[0];

  const max = useMemo(() => {
    const filtered = SURFACE_OPTIONS.filter((s) => s.value <= 120);
    return filtered[filtered.length - 1];
  }, []);

  const result = useMemo(
    () => getTruckByValue(surface * furnitureQuantities),
    [surface, furnitureQuantities]
  );

  const handleChange = (value) => {
    setSurface(value);
  };
  return (
    <Block
      backgroundColor={colors.backgroundLight}
      borderRadius={radius.xl}
      maxWidth={500}
      width="100%"
      overflow="hidden"
      display="flex"
      flexDirection="column"
    >
      <Block
        backgroundColor={colors.background}
        borderRadius={radius.l}
        marginX={spacings.m}
        marginTop={spacings.m}
        paddingX={spacings.m}
        paddingTop={spacings.s}
      >
        <FormControl label={polyglot.t("categories.what_surface_of_your_home")}>
          <Block
            marginTop={`calc(${spacings.ml} + ${sizes.size20} + ${spacings.s})`}
          >
            <Slider
              min={min.value}
              max={max.value}
              step={5}
              persisentThumb
              onChange={handleChange}
              value={surface}
              ticks={[min.label, `${max.label}+`]}
              formatValue={(value) => `${Math.round(value)} m²`}
            />
          </Block>
        </FormControl>
        <FormControl
          label={polyglot.t("categories.what_quantity_furniture_at_your_home")}
        >
          <Block
            marginX={`calc(${spacings.m} * -1)`}
            overflowX="auto"
            paddingTop={spacings.s}
          >
            <Block marginX={spacings.m} display="inline-flex">
              {FURNITURE_QUANTITIES.map((furniture, index) => (
                <StyledCardSelectable
                  key={`truck-size-calculator-furnitures-${index}`}
                  name="furniture_quantities"
                  css={`
                    min-width: ${breakpoints.get({
                      xs: "150px",
                      md: "inherit",
                    })};
                  `}
                  image={furniture.image}
                  checked={furnitureQuantities === furniture.nbHoursAccumulator}
                  onClick={() =>
                    setFurnitureQuantities(furniture.nbHoursAccumulator)
                  }
                >
                  <Body16 strong color={colors.body}>
                    {furniture.label}
                  </Body16>
                  <Body14 color={colors.muted} style={{ fontWeight: 400 }}>
                    {furniture.subtitle}
                  </Body14>
                </StyledCardSelectable>
              ))}
            </Block>
          </Block>
        </FormControl>
      </Block>
      {surface && furnitureQuantities && (
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          padding={spacings.m}
        >
          <Block
            flex="1"
            css={`
              height: ${breakpoints.get({ xs: "100px", md: "120px" })};
              overflow: hidden;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
          >
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={result.value}
                initial={{ y: "50%", opacity: 0 }}
                animate={{ y: "0%", opacity: 1 }}
                exit={{ y: "-50%", opacity: 0 }}
                transition={{ duration: 0.2, ease: "easeInOut" }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  alt={result.label}
                  src={result.image}
                  css={`
                    height: ${breakpoints.get({ xs: "120px", md: "170px" })};
                    width: auto;
                  `}
                />
              </motion.div>
            </AnimatePresence>
          </Block>
          <Block flex="1">
            {breakpoints.get({
              xs: <H5>{result.subtitle}</H5>,
              md: <H4>{result.subtitle}</H4>,
            })}
            <Body16 color={colors.muted}>{result.label}</Body16>
          </Block>
        </Block>
      )}
    </Block>
  );
};
export default TruckVolumeSimulator;
