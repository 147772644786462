import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { wizardStepNavigation } from '../../../../modules/actions/wizard-actions';
import polyglot from '../../../../utils/polyglot';

class WizardNavigation extends Component {
  navigate = value => {
    const { stepIsValid, wizardStepNavigation, history } = this.props;
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (value === 1) {
      stepIsValid ? wizardStepNavigation({ step: value }, 'gift_cards', history) : false;
    } else {
      wizardStepNavigation({ step: value }, 'gift_cards', history);
    }
  };

  render() {
    const { submit, currStep, stepIsValid, submitted } = this.props;
    return (
      <div className="wizard-form-navigation">
        <div className="wizard-form-navigation-content">
          {!currStep <= 0 ? (
            <button
              className="btn btn-link"
              onClick={() => this.navigate(-1, currStep)}
              disabled={currStep <= 0 || submitted}
              type="button">
              {polyglot.t('common.navigation.back')}
            </button>
          ) : (
            <a className="btn btn-link" href={this.props.backUrl}>
              {polyglot.t('common.actions.cancel')}
            </a>
          )}
          {!submit ? (
            <button
              className="btn btn-primary"
              id="btn-wizard-next"
              onClick={() => this.navigate(1)}
              type="button"
              disabled={!stepIsValid}>
              {polyglot.t('common.navigation.next')}
            </button>
          ) : (
            <button
              className={`btn btn-primary ${submitted ? 'is-loading' : ''}`}
              type="submit"
              disabled={!stepIsValid}>
              {`${polyglot.t('common.actions.pay')} ${submit.payAmount}${polyglot.currency}`}
            </button>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  wizard: state.wizard,
});

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({ wizardStepNavigation }, dispatch),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(WizardNavigation),
);
