import { useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { sizes, spacings } from "../../../assets/themes";
import { Block, Skeleton } from "../../../components";
import { useCategoryForm } from "../../../modules/hooks";
import polyglot from "../../../utils/polyglot";
import PhotosUpload from "../../editJob/PhotosUpload";
import { StyledFormControl } from "./fields";
import StepTitle from "./StepTitle";

let to;
const MAX_JOB_PHOTOS = 3;

const Photos = ({ title }) => {
  const { setFieldValue, values } = useFormikContext();
  const { flowControl } = useCategoryForm();
  const [animationComplete, setAnimationComplete] = useState(
    values.uploads?.length === 0
  );

  const handleChange = ({ uploads, tokens }) => {
    setFieldValue("uploads", uploads);
    setFieldValue("photos", tokens);
  };

  useEffect(() => {
    to = setTimeout(() => {
      setAnimationComplete(true);
      // prevent performance problems when router is animation pages in same time, blob transform to url
    }, 200);
    return () => {
      clearTimeout(to);
    };
  }, []);

  useEffect(() => {
    if (values.photos?.length > 0) {
      flowControl.setDefaultNextLabel();
    } else {
      flowControl.setNextLabel(polyglot.t("categories.continue_without_photo"));
    }
    return () => {
      flowControl.setDefaultNextLabel();
    };
  }, [values.photos]);

  return (
    <>
      <StepTitle>{title}</StepTitle>
      {animationComplete ? (
        <StyledFormControl>
          <PhotosUpload
            size={sizes.size136}
            onChange={handleChange}
            tokens={values.photos}
            value={values.uploads}
          />
        </StyledFormControl>
      ) : (
        <Skeleton.Group>
          <StyledFormControl>
            <Block display="flex" flexWrap="wrap">
              {new Array(MAX_JOB_PHOTOS).fill("").map((_, index) => (
                <Block
                  marginRight={spacings.s}
                  key={`skeleton-placeholder-photo-${index}`}
                >
                  <Skeleton width={sizes.size136} height={sizes.size136} />
                </Block>
              ))}
            </Block>
          </StyledFormControl>
        </Skeleton.Group>
      )}
    </>
  );
};

export default Photos;
