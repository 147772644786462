import UseSmartphone from "./cesuButtons/UseSmartphone";
import ManualInput from "./cesuButtons/ManualInput";
import ECesu from "./cesuButtons/ECesu";
import PageTitle from "../dashboard/PageTitle";
import { Alert, Block, Body16, Col, Row } from "../../components";
import { spacings } from "../../assets/themes";
import { ALERT } from "../../components/Styles/variants";
import polyglot from "../../utils/polyglot";

const CesuButtons = ({ app_store_url, google_play_url, currentUser }) => (
  <>
    <Block
      marginTop={{ xs: spacings.s, md: spacings.m }}
      marginBottom={spacings.m}
    >
      <PageTitle>{polyglot.t("cesu.add")}</PageTitle>
    </Block>
    <Block paddingY={spacings.m}>
      <Row gutter={[spacings.m]}>
        <Col size={{ xs: 12, sm: 6, md: 4 }}>
          <UseSmartphone
            app_store_url={app_store_url}
            google_play_url={google_play_url}
          />
        </Col>
        <Col size={{ xs: 12, sm: 6, md: 4 }}>
          <ManualInput />
        </Col>
        <Col size={{ xs: 12, md: 4 }}>
          <ECesu reference={currentUser.reference} />
        </Col>
      </Row>
    </Block>
    <Alert.Low kind={ALERT.KIND.WARNING}>
      <Body16 strong>{polyglot.t("cesu.new.important_informations")}</Body16>
      <ul
        css={`
          padding-left: 8px;
          margin-bottom: 0;
        `}
      >
        <li>{polyglot.t("cesu.new.deadline_ticket_utilisation")}</li>
        <li>{polyglot.t("cesu.ineligible_payment_jobbers_pro")}</li>
        <li>{polyglot.t("cesu.allowed_services_list")}</li>
      </ul>
    </Alert.Low>
  </>
);

export default CesuButtons;
