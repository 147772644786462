import React from 'react';

const Wrapper = ({ children, className }) => (
  <div className={`d-flex align-items-center ${className}`}>{children}</div>
);

const Large = ({ className, icon, title, children, LeftComponent }) => (
  <Wrapper className={`d-flex align-items-center ${className}`}>
    <div className="mr-2 d-flex align-items-center">
      {LeftComponent ? LeftComponent() : <i className={`${icon}`} />}
    </div>
    <p className="mb-0 font-size-4 text-left">{title || children}</p>
  </Wrapper>
);

const Medium = ({ className, icon, title, LeftComponent, children }) => (
  <Wrapper className={className}>
    <div className="mr-2 d-flex align-items-center">
      {LeftComponent ? LeftComponent() : <i className={`${icon}`} />}
    </div>
    <p className="mb-0 text-left">{title || children}</p>
  </Wrapper>
);

const Small = ({ className, icon, title, LeftComponent, children }) => (
  <Wrapper className={className}>
    <div className="mr-2 d-flex align-items-center">
      {LeftComponent ? LeftComponent() : <i className={`${icon}`} />}
    </div>
    <p className="mb-0 font-size-2 text-left">{title || children}</p>
  </Wrapper>
);

export default { Large, Medium, Small };
