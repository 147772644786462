import BaseApp from "../modules/BaseApp";
import MovingBoxesCalculator from "../pages/MovingBoxesCalculator";

const MovingBoxesCalculatorApp = (props, rails) => () =>
  (
    <BaseApp {...props}>
      <MovingBoxesCalculator {...props} />
    </BaseApp>
  );

export default MovingBoxesCalculatorApp;
