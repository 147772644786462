import { useEffect } from "react";
import Button from "../../Button";

const Navigation = ({
  goNext,
  goBack,
  isValid,
  nextLabel = "Continuer",
  isLoading,
  className,
}) => {
  const onEnter = (e) => {
    if ((e.charCode || e.keyCode) === 13) {
      e.preventDefault();
      e.stopPropagation();
      if (isValid) {
        goNext();
      }
    }
  };

  useEffect(() => {
    const elem = document.getElementById("flow-router");
    elem?.addEventListener("keydown", onEnter);
    return () => {
      elem?.removeEventListener("keydown", onEnter);
    };
  }, [isValid, goNext]);

  return (
    <div
      className={`${className} d-flex align-items-center justify-content-between w-100 my-3`}
    >
      {goBack && (
        <Button.Large kind="secondary" onClick={goBack}>
          Retour
        </Button.Large>
      )}
      {goNext && (
        <Button.Large
          className={`${goBack ? "ml-3" : ""} px-3`}
          onClick={goNext}
          disabled={!isValid}
          isLoading={isLoading}
        >
          {nextLabel}
        </Button.Large>
      )}
    </div>
  );
};

export default Navigation;
