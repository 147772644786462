import { Field, useField } from "formik";
import React, { useCallback } from "react";
import { List, Stepper } from "../../../../components";

const FormStepper = ({
  name,
  title,
  subtitle,
  divider,
  max,
  min,
  hint,
  suffix,
  prefix,
  step,
  children,
  FieldChildren,
  onChange,
  options,
  disabled,
  size,
}) => {
  const [field] = useField(name);

  const handleChange = (v, form) => {
    if (onChange) onChange(v, form);
    if (options) form.setFieldValue(field.name, options[v]?.value);
    else form.setFieldValue(field.name, v);
    form.setFieldTouched(field.name, true);
  };

  const getValue = useCallback(() => {
    if (options) return options.findIndex((opt) => opt.value === field.value);
    return field.value;
  }, [field.value]);

  const renderChild = () => {
    if (options) return options[getValue()]?.label;
    return FieldChildren;
  };

  return (
    <List.Item
      divider={divider}
      size={size}
      RightComponent={() => (
        <Field name={name}>
          {({ form }) => (
            <Stepper
              value={getValue()}
              onChange={(v) => handleChange(v, form)}
              max={options?.length - 1 || max}
              min={min}
              hint={hint}
              suffix={suffix}
              prefix={prefix}
              step={step}
              disabled={disabled}
            >
              {renderChild()}
            </Stepper>
          )}
        </Field>
      )}
    >
      {title && <List.Elem.Title strong>{title}</List.Elem.Title>}
      {subtitle && <List.Elem.Subtitle>{subtitle}</List.Elem.Subtitle>}
      {children}
    </List.Item>
  );
};

export default FormStepper;
